import studioPagesStore from "@/store/studioPagesStore"
import { observer } from "mobx-react-lite"
import ModalSelectUI from "./ModalSelectUI"

const ModalSelect = () => {
  const { model: modelId, modelVersion: modelVersionId } = studioPagesStore.store.get()
  const setModel = (model: string) => {
    studioPagesStore.setStore({
      model,
    })
  }

  const setModelVersion = (modelVersion: string) => {
    studioPagesStore.setStore({
      modelVersion,
    })
  }

  return (
    <ModalSelectUI
      modelId={modelId}
      modelVersionId={modelVersionId}
      onClick={setModel}
      modelVersionClick={setModelVersion}
    />
  )
  // return (
  //   <div className="max-h-none bg-none p-0 rounded grid-cols-2 gap-[5px] md:bg-[#161623] md:p-[10px] grid md:grid-cols-3 md:gap-[10px] md:max-h-[380px] overflow-y-auto scrollbar-hidden">
  //     {filterModels()?.map((item, index) => {
  //       const isActive = item.id === modelId || (item.id === DEFAULT_MODEL.id && !modelId)
  //       return (
  //         <ModelItem
  //           key={item.id}
  //           modal={item}
  //           active={isActive}
  //           onClick={() => {
  //             setModel(item.id)
  //           }}
  //         />
  //       )
  //     })}
  //   </div>
  // )
}

export default observer(ModalSelect)
