import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

interface DateRowProps {
  date: Date
  children?: React.ReactNode
}
const DateRow = ({ date, children }: DateRowProps) => {
  const { t } = useTranslation()
  let formattedDate = dayjs(date).format("YYYY年 MM月D日")
  const today = dayjs()
  const yesterday = dayjs().subtract(1, "day")
  if (dayjs(date).isSame(today, "day")) {
    formattedDate = t("worksCenter.today")
  } else if (dayjs(date).isSame(yesterday, "day")) {
    formattedDate = t("worksCenter.yesterday")
  }

  return (
    <div>
      <div className="pt-[30px] pb-2.5 text-white text-opacity-50">{formattedDate}</div>
      <div>{children}</div>
    </div>
  )
}

export default DateRow
