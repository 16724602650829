import { useEffect, useMemo, useRef, useState } from "react"
import "./index.less"
import { toastError, toastSuccess } from "@/common/Toast"
import { Modal, Popover, Slider } from "@arco-design/web-react"
import { ReactComponent as CloseIcon } from "@/assets/fork.svg"
import { ReactComponent as RetrunIcon } from "@/assets/return.svg"
import { ReactComponent as DarkChangeIcon } from "@/assets/studio/change_dark_bg.svg"
import { ReactComponent as LightChangeIcon } from "@/assets/studio/change_light_bg.svg"
import { ReactComponent as PrevIcon } from "@/assets/studio/prev.svg"
import { ReactComponent as EraserIcon } from "@/assets/studio/eraser.svg"
import { ReactComponent as PencilIcon } from "@/assets/studio/pencil.svg"
import { fabric } from "fabric"
import { observer } from "mobx-react-lite"
import { createTemplate, getTemplateCategories } from "@/services/editor"
import { isMobileOnly } from "react-device-detect"
import { useTranslation } from "react-i18next"
export interface categories {
  label: string
  value: string
}
export default observer(function ({
  onClose,
  width = 400,
  height = 400,
  index,
  handleSumit,
}: {
  onClose: () => void
  width: number
  height: number
  index: number
  // 本地的临时图片url
  handleSumit: (url: string) => void
}) {
  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const [pencilColor, setPencilColor] = useState<string>("#252525")
  const [canvasBgColor, setCanvasBgColor] = useState<"dark" | "light">("dark")
  const [pencilWidth, setPencilWidth] = useState<number>(12)
  const [active, setActive] = useState("pencil")
  useEffect(() => {
    const canvas = new fabric.Canvas(canvasRef.current, {
      backgroundColor: "#FFf",
      width: width,
      height: height,
      isDrawingMode: true,
    })
    // canvas.add(new fabric.Rect({ width: 100, height: 100, fill: "red" }))
    // 切换颜色
    const bgColorBtn = document.getElementById("bgColorBtn")
    bgColorBtn?.addEventListener("click", function (event) {
      const color = bgColorBtn.getAttribute("data-name") === "light" ? "#fff" : "#000"
      canvas.setBackgroundColor(color, canvas.renderAll.bind(canvas))
      // 画笔颜色与画布颜色相反
      canvas.freeDrawingBrush.color =
        bgColorBtn.getAttribute("data-name") === "light" ? "#000" : "#fff"
      // 清空画布内容
      canvas.remove(...canvas.getObjects())
    })
    // 颜色选择器
    const colorPicker = document.getElementById("colorPicker")
    colorPicker?.addEventListener("change", function (event) {
      const color = (event?.target as HTMLInputElement)?.value || "#fff"
      canvas.setBackgroundColor(color, canvas.renderAll.bind(canvas))
    })
    //  选中画笔 自动切换颜色
    const pencilColor = document.getElementById("pencilBtn")
    pencilColor?.addEventListener("click", function (event) {
      canvas.isDrawingMode = true
      const color = pencilColor.getAttribute("data-name") === "dark" ? "#000" : "#fff"
      canvas.freeDrawingBrush.color = color
    })
    // 画笔颜色(本期未使用)
    const changeColor = document.getElementById("changeColor")
    changeColor?.addEventListener("click", function () {
      canvas.freeDrawingBrush.color = "red"
    })
    // 画笔粗细
    const pencilWidthRange = document.getElementById("pencilWidthRange")
    pencilWidthRange?.addEventListener("change", function () {
      canvas.freeDrawingBrush.width = Number((pencilWidthRange as HTMLInputElement)?.value)
    })
    // 橡皮(切换画笔颜色为画布颜色)
    const eraser = document.getElementById("eraserBtn")
    eraser?.addEventListener("click", function () {
      canvas.isDrawingMode = true
      const color = eraser.getAttribute("data-name") === "light" ? "#000" : "#fff"
      canvas.freeDrawingBrush.color = color
    })

    //  监听文字按钮 画布状态isDrawingMode变为 false
    const textBtn = document.getElementById("textBtn")
    textBtn?.addEventListener("click", function () {
      canvas.isDrawingMode = false
    })
    // 监听鼠标点击事件
    canvas.on("mouse:down", function (options) {
      //  "text" 即为 非画板模式
      const noDrawingMode =
        document.getElementById("textBtn")?.getAttribute("data-active") === "text"
      // 不是画板模式才可以添加输入框
      if (noDrawingMode) {
        const pointer = canvas.getPointer(options.e)
        const fontSize = document.getElementById("textBtn")?.getAttribute("data-size") || 12

        const fontColor =
          document.getElementById("textBtn")?.getAttribute("data-color") === "light"
            ? "#fff"
            : "#000"
        const backgroundColor =
          document.getElementById("textBtn")?.getAttribute("data-color") === "light"
            ? "#000"
            : "#fff"
        // 创建一个输入框
        const input = document.createElement("textarea")
        input.style.position = "absolute"
        input.style.left = pointer.x + "px"
        input.style.top = pointer.y + "px"
        input.style.color = fontColor
        input.style.fontSize = String(Number(fontSize) * 2)
        input.style.background = backgroundColor
        input.style.zIndex = "1008"
        // input.style.border = "2px solid #4C1FFF"
        // input.style.borderRadius = "4px"
        // input.style.padding = "4px"
        // input.style.height = "auto"
        input.style.resize = "none"
        input.style.caretColor = "#4C1FFF"
        // 将输入框添加到画布上
        document.getElementsByClassName("canvas-container")[0]?.appendChild(input)
        // 光标移动到输入框(不加 setTimeout input 元素创建后没来得及加入光标)
        setTimeout(() => {
          input.focus()
        })
        const textObjConfig = {
          left: pointer.x,
          top: pointer.y,
          fontSize: Number(fontSize),
          fill: fontColor,
        }
        // 监听输入框的键盘按下事件
        // input.addEventListener("keydown", function (e) {
        //   if (e.key === "Enter") {
        //     const text = new fabric.Text(input.value, {
        //       left: pointer.x,
        //       top: pointer.y,
        //       fontSize: Number(fontSize),
        //       fill: fontColor,
        //     })
        //     // 将文本对象添加到画布中
        //     canvas.add(text)
        //     // 移除输入框
        //     document.getElementsByClassName("canvas-container")[0]?.removeChild(input)
        //   }
        // })
        // 监听失去焦点
        input.addEventListener("blur", function (e) {
          const text = new fabric.Text(input.value, {
            left: pointer.x,
            top: pointer.y,
            fontSize: Number(fontSize),
            fill: fontColor,
            fontFamily: "STKaiti",
          })
          // 将文本对象添加到画布中
          canvas.add(text)
          // 移除输入框
          document.getElementsByClassName("canvas-container")[0]?.removeChild(input)
        })
      }
    })
    // 上一步
    const prevBtn = document.getElementById("prevBtn")
    prevBtn?.addEventListener("click", function () {
      const objects = canvas.getObjects()
      canvas.remove(objects[objects.length - 1])
    })
    //  移除所有
    const removeAllButton = document.getElementById("removeAllButton")
    removeAllButton?.addEventListener("click", function () {
      canvas.remove(...canvas.getObjects())
    })
    // 关闭弹框
    const cancelBtn = document.getElementById(`cancel-${index}`)
    cancelBtn?.addEventListener("click", function () {
      canvas.remove(...canvas.getObjects())
      onClose()
    })
    // 返回
    const returnIcon = document.getElementById("return_icon")
    returnIcon?.addEventListener("click", function () {
      canvas.remove(...canvas.getObjects())
      onClose()
    })
    //  关闭Icon
    const closeIcon = document.getElementById("close_icon")
    closeIcon?.addEventListener("click", function () {
      canvas.remove(...canvas.getObjects())
      onClose()
    })
    //  确定
    const confirmBtn = document.getElementById(`confirm-${index}`)
    confirmBtn?.addEventListener("click", function () {
      const canvasCurrent = canvasRef.current
      //
      // console.log(canvasCurrent?.toDataURL())
      canvasCurrent?.toBlob((blob) => {
        //  创建临时URL，可以是 blob 或者file
        const lcoalUrl = URL.createObjectURL(blob as Blob)
        handleSumit(lcoalUrl)
      })

      canvas.remove(...canvas.getObjects())
      onClose()
    })
    //  保存
    // const confirm = document.getElementById("confirm")
    // confirm?.addEventListener("click", function () {
    //   console.log(111)

    //   const png =  URL.createObjectURL(canvas.toDataURL())
    //   // console.log(png, "png")
    // })
    // const pencilBrush = new fabric.PencilBrush(canvas)
    // pencilBrush.width = 10
    // pencilBrush.color = pencilColor
    // canvas.freeDrawingBrush = pencilBrush
    // canvas.add(pencilBrush)

    return () => {
      // 在组件卸载时，记得清理canvas
      canvas.dispose()
    }
  }, [canvasRef])

  return (
    <div className={`drawing-board-mask ${isMobileOnly ? "" : "w-[100%] h-[100%]"}`}>
      <div
        className={`drawing-board-container  ${isMobileOnly ? "h-[100vh]" : ""}`}
        style={{ width: isMobileOnly ? "100vw" : "" }}
      >
        <div className="drawing-board-header flex items-center">
          {isMobileOnly && <RetrunIcon id="return_icon" />}
          <span className="text-[18px] md:text-[24px]">手绘画板</span>
          <CloseIcon id="close_icon" className="close-icon" />
        </div>
        {/*  画布 */}
        <div className="canvas-main justify-center ">
          <canvas ref={canvasRef} id="canvas"></canvas>
        </div>
        <div className="drawing-board-footer md:min-w-[600px]">
          <div className="drawing-board-footer-left relative">
            {/* 切换背景色 */}
            <div
              className={canvasBgColor === "dark" ? "icon-bg-dark" : "icon-bg-light"}
              onClick={() => {
                let color = canvasBgColor === "dark" ? "light" : "dark"
                setCanvasBgColor(color as "dark" | "light")
              }}
              id="bgColorBtn"
              data-name={canvasBgColor}
            >
              <div className="change-icon">
                {canvasBgColor === "dark" ? <DarkChangeIcon /> : <LightChangeIcon />}
              </div>
            </div>
            {/* 返回上一步 */}
            <div className="relative return-btn" id="prevBtn">
              <PrevIcon className="cursor-pointer" />
              <div className="popover">返回上一步</div>
            </div>
            {/* 橡皮擦 */}
            <button
              className={`normal_btn ${active === "eraser" ? "active" : ""}`}
              onClick={() => setActive("eraser")}
              id="eraserBtn"
              data-name={canvasBgColor}
            >
              <EraserIcon />
            </button>
            {/*  画笔 */}
            <button
              className={`normal_btn ${active === "pencil" ? "active" : ""}`}
              onClick={() => setActive("pencil")}
              data-name={canvasBgColor}
              id="pencilBtn"
            >
              <PencilIcon />
            </button>

            <button
              id="textBtn"
              className={`normal_btn ${active === "text" ? "active" : ""}`}
              onClick={() => setActive("text")}
              data-name={canvasBgColor}
              data-active={active}
              data-size={pencilWidth}
              data-color={canvasBgColor}
            >
              T
            </button>

            {/*  画笔粗细 */}
            {!isMobileOnly && (
              <div className="relative return-btn">
                <input
                  type="range"
                  min="1"
                  max="50"
                  id="pencilWidthRange"
                  value={pencilWidth}
                  step="1"
                  onChange={(e) => {
                    setPencilWidth(Number(e?.target.value))
                  }}
                ></input>
                <div className="popover">画笔粗细</div>
              </div>
            )}
            {/* 插入文字 */}
          </div>
          <div className="drawing-board-footer-right">
            <button id="removeAllButton" className="reset-btn">
              清除画布
            </button>
          </div>
        </div>
        {isMobileOnly && (
          <div className="moble-pencil-width-input flex items-center">
            {/*  画笔粗细 */}
            <input
              type="range"
              min="1"
              max="50"
              id="pencilWidthRange"
              value={pencilWidth}
              step="1"
              onChange={(e) => {
                setPencilWidth(Number(e?.target.value))
              }}
            ></input>
            <div className="ml-[10px]"> 字号</div>
          </div>
        )}

        <div className="drawing-board-btn-container">
          <button
            className="cancel btn"
            id={`cancel-${index}`}
            // onClick={() => {
            //   onClose()
            // }}
          >
            取消
          </button>
          <button
            id={`confirm-${index}`}
            className="confirm btn"
            // onClick={() => {

            // }}
          >
            确定
          </button>
        </div>
        {/* <button
          onClick={() => {
            console.log(canvasRef)
            console.log(canvasRef?.current?.attributes)
          }}
        >
          画笔
        </button>
        颜色选择器
        <input type="color" id="colorPicker" />
        <button id="changeColor">切换颜色</button>
        <button id="undoButton">上一步</button>
        <button id="removeAllButton">清除所有</button>
        <Slider value={value} onChange={()=>{setValue}} style={{ width: 200 }}/> */}
      </div>
    </div>
  )
})
