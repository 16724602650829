import { Masonry } from "masonic"
import { useState, useMemo, useEffect } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { useDarkMode, useWindowWidth, getColumnCount, featureDeprecatedAlert } from "@/utils"
import { useTranslation } from "react-i18next"
import { ReactComponent as EmptyIcon } from "@/assets/empty.svg"

import { ReactComponent as LoadingPuffIcon } from "@/assets/loading_puff.svg"
import HomeCard from "@/pages/Works/HomeCard"
import CardModal from "@/common/ImgDetailModal/Modal"
import { SearchGroup, ISearchParams } from "./SearchGroup"

import { api } from "@/services/api"
import { toastError, toastSuccess } from "@/common/Toast"

import type { V1Task, V1Work } from "@/generated/api/data-contracts"

import "./visitor.less"
import "@/styles/reset-arco.less"
import { ErrorCode } from "@/i18next"
import * as store from "@/store"
export function VisitorCreate({
  userId,
  setActiveTab,
  search,
}: {
  userId: string
  setActiveTab?: (activeTab: string) => void
  search?: string
}) {
  useDarkMode()
  const { t } = useTranslation(["translation", "errorMessage"])
  const [currentSearchParams, setCurrentSearchParams] = useState({
    sort: "newest",
    query: "",
  })

  useEffect(() => {
    return () => {
      store.clearHasChangedWorks()
    }
  }, [])
  const [modalVisiable, setModalVisiable] = useState<boolean>(false)
  const columnCount = getColumnCount(useWindowWidth())
  const [pages, setPages] = useState<V1Task[][]>([])
  const [total, setTotal] = useState(-1)
  const [currentTaskIndex, setCurrentTaskIndex] = useState<number>(-1)
  const [currentTask, setCurrentTask] = useState<V1Task>()
  const [masonryKeyIndex, setMasonryKeyIndex] = useState(1)
  const totalItems = useMemo(() => {
    return pages.reduce((result, list) => {
      return result.concat(list)
    }, [])
  }, [pages])
  const cardCount = useMemo(() => {
    return totalItems?.length
  }, [totalItems])

  const hasMore = useMemo(() => {
    if (total == -1) {
      return true
    }
    return total > cardCount
  }, [cardCount, total])
  const [taskChangeDisable, setTaskChangeDisable] = useState<"prev" | "next" | "none">("none")
  const handleTaskChange = (direction: "prev" | "next") => {
    // 违法情况
    if (direction == "prev" && currentTaskIndex <= 1) {
      setTaskChangeDisable("prev")
      if (currentTaskIndex == 0) return
    } else if (direction == "next" && currentTaskIndex >= totalItems.length - 2) {
      setTaskChangeDisable("next")
      if (currentTaskIndex == totalItems?.length - 1) return
    } else {
      setTaskChangeDisable("none")
    }
    const newIndex = currentTaskIndex + (direction == "prev" ? -1 : 1)
    const task = totalItems[newIndex]
    setCurrentTaskIndex(newIndex)
    setCurrentTask(task)
  }

  async function loadPage(pageNo: number, p = currentSearchParams, cb?: () => void) {
    try {
      const params = {
        current: pageNo + 1,
        pageSize: 50,
        ...(p ?? {}),
      }

      const { data } = await api.listTasksByUserId(userId, params)
      if (data.success) {
        cb && cb()
        setPages((pre) => {
          if (pageNo !== 0) {
            pre[pageNo] = data.data?.list ?? []
            return [...pre]
          } else {
            return [data.data?.list ?? []]
          }
        })
        setTotal(data.data.total ?? 0)
      } else {
        switch (data.errorCode) {
          case "10003":
            toastError(t("errorMessage:10003"))
            break
          default:
            toastError(t("NetworkError"))
        }
      }
    } catch (e) {
      console.error("error", e)
    }
  }

  const likeOrUnlike = async (liked: boolean, id: string) => {
    featureDeprecatedAlert()
    // const { data } = await(
    //   liked
    //     ? api.unlikeWork(id, { collectionUID: "JTQltwGbPEG3equutKwrX" })
    //     : api.likeWork(id, { collectionUID: "JTQltwGbPEG3equutKwrX" })
    // )
    // if (data.success) {
    //   toastSuccess("操作成功")
    //   // refresh()
    //   // const keys = Object.keys(pages)
    //   // keys.forEach((index) => {
    //   //   const newPageItem = pages?.[+index].map((task) => {
    //   //     const works = task.works?.map((work) => {
    //   //       if (work.id === id) {
    //   //         work.liked = !liked
    //   //       }
    //   //       return work
    //   //     })
    //   //     return {
    //   //       ...task,
    //   //       works,
    //   //     }
    //   //   })
    //   //   pages[+index] = newPageItem
    //   // })
    //   // setPages([...pages])
    //   return true
    // } else {
    //   toastError(t(`errorMessage:${data.errorCode as ErrorCode}`))
    //   return false
    // }
  }

  const resetSearchCondition = () => {
    setMasonryKeyIndex(masonryKeyIndex + 1)
    setTotal(-1)
    setPages([])
  }
  async function searchTask(params: ISearchParams) {
    setCurrentSearchParams(params)
    //  置空total和
    resetSearchCondition()
    loadPage(0, params, () => {
      setMasonryKeyIndex(masonryKeyIndex + 1)
    })
  }
  function hasWork(target: typeof pages) {
    const result: number = target.reduce((sum, list) => {
      return sum + (Array.isArray(list) ? list.length : 0)
    }, 0)
    return result
  }

  const masonryItemList = useMemo(() => {
    const result = hasWork(pages)
    // ? pages?.flatMap((list) => {
    //     return list
    //       ?.filter((task) => task?.works?.length)
    //       ?.map((task) => ({ task, work: task.works?.[0] }))
    //   })
    // : []
    const masonryItems: {
      task: V1Task
      work: V1Work
    }[] = []
    pages.map((item) => {
      item?.map((task) => {
        const work_1 = task.works?.[0]
        if (work_1) {
          masonryItems.push({
            task,
            work: work_1,
          })
        }
      })
    })
    return masonryItems
  }, [pages])

  // useEffect(() => {
  //   loadPage(0, { ...currentSearchParams, query: search || "" })
  // }, [search])

  return (
    <>
      <div className="mt-[20px] md:px-[50px] px-5">
        <SearchGroup defaultTab="create" onSearch={searchTask} onChangeTab={setActiveTab} />
      </div>
      <div className="mt-[20px] md:px-[50px] px-5">
        <InfiniteScroll
          key={masonryKeyIndex}
          pageStart={-1}
          loadMore={(e) => {
            loadPage(e)
          }}
          useWindow={false}
          hasMore={hasMore}
          loader={
            <LoadingPuffIcon
              key={"loading"}
              className="my-20 mx-auto"
              stroke="currentColor"
            ></LoadingPuffIcon>
          }
        >
          <Masonry
            key={masonryKeyIndex}
            items={masonryItemList || []}
            render={({ data }) => {
              const { task, work } = data
              return task ? (
                <div
                  style={{ aspectRatio: task?.ratio.replace(":", "/") }}
                  onClick={() => {
                    setCurrentTask(task)
                    const result = totalItems.findIndex((item) => {
                      return item.id == task?.id
                    })

                    if (result == 0) {
                      setTaskChangeDisable("prev")
                    } else if (result == totalItems.length - 1) {
                      setTaskChangeDisable("next")
                    } else {
                      setTaskChangeDisable("none")
                    }
                    setCurrentTaskIndex(result)
                    setModalVisiable(true)
                  }}
                >
                  <HomeCard task={task} work={work as V1Work} showRegen refJump showOption />
                </div>
              ) : null
            }}
            columnCount={columnCount}
            columnGutter={10}
          />

          {cardCount && !hasMore ? (
            <section className="mt-[22px] flex justify-center items-center">
              <span className="inline-flex items-center text-[#6F7176] text-sm before:mr-[26px] before:bg-[#C9CBD14D]/30 before:w-[138px] before:h-[1px] after:ml-[26px] after:bg-[#C9CBD14D]/30 after:w-[138px] after:h-[1px]">
                没有更多了
              </span>
            </section>
          ) : null}
          {/* 空白作品列表 */}
          {!cardCount && !hasMore && (
            <>
              <EmptyIcon className="mt-20 mx-auto"></EmptyIcon>
              <div className="mt-2.5 text-center text-sm">暂无作品</div>
            </>
          )}
        </InfiniteScroll>
      </div>
      {currentTask?.works && (
        <CardModal
          open={modalVisiable}
          onClose={() => setModalVisiable(false)}
          task={currentTask}
          work={currentTask?.works?.[0]}
          handleTaskChange={handleTaskChange}
          taskChangeDisable={taskChangeDisable}
          fromHomePage={true}
        />
      )}
    </>
  )
}
