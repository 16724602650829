import { ReactComponent as AddIcon } from "@/assets/add.svg"
import { ReactComponent as MinuIcon } from "@/assets/minu.svg"
import { ReactComponent as CopyToRIghtIcon } from "@/assets/copy_to_right.svg"
import { ReactComponent as CopyToLeftIcon } from "@/assets/copy_to_left.svg"
import { toastError } from "@/common/Toast"
import { MutableRefObject, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react"
import { Modal } from "@arco-design/web-react"
import { V1ControlNetSettings } from "@/generated/api/data-contracts"
import "./ControlNet.less"
import { observer } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import CheckAuth from "@/components/common/CheckAuth"
import studioPagesStore from "@/store/studioPagesStore"
import { ControlNetInit, ControlNetUpload, useImperativeHandleProps } from "./ControlNetUpload"
import { isMobileOnly } from "react-device-detect"
import ImageSize from "../StudioOptions/ImageSize"
import { ReactComponent as PaintIcon } from "@/assets/paint.svg"
import ModalHeader from "./ModalHeader"
import ConfirmModal from "@/common/ConfirmModal"
import { filterControlNetModel } from "@/utils"

interface ControlNetValide {
  [key: string]: string
}
export const ControlNetInStudioPage = observer(
  ({
    sourceImage,
    buttonContent,
    onChange,
    event,
  }: {
    sourceImage?: string
    buttonContent?: React.ReactNode
    event?: MutableRefObject<{ onOpen: (mode?: Array<string> | []) => void }>
    onChange?: (_: {
      type: "controlNet"
      preViewUrl: string
      payload: { controlNet: V1ControlNetSettings }
    }) => void
  }) => {
    const { t } = useTranslation(["translation", "errorMessage"])
    const referencelIamgeData = studioPagesStore.store.get().referencelIamgeData
    const modelVersion = studioPagesStore.store.get().modelVersion
    const controlNetUploadRef = useRef<useImperativeHandleProps>(null)
    const controlNetUploadRef2 = useRef<useImperativeHandleProps>(null)
    const [visible, setVisible] = useState(false)
    const [sourceImageUrl, setSourceImage] = useState("")
    const [imgStatus, setImgStatus] = useState<0 | 1 | 2 | 3>(() => 0) // 0 待选择图 1 上传中 2 上传失败, 3 上传成功
    // 设置 ratio ControlNet 与图片尺寸联动
    const setRatio = (ratio: string) => studioPagesStore.setStore({ ratio })
    const ratio = studioPagesStore.store.get().ratio
    // 裁切组件需要的值
    const [aspectRatio, setAspectRatio] = useState<number>(1) // 裁剪区域的比例
    //  当前选中的rotio
    const [checkedRatio, setCheckedRatio] = useState(ratio || "1:1")
    // 按钮状态           确认 重新上传
    const [btnStatus, setBtnStatus] = useState(1)
    const [contolNet1Init, setControlNet1Init] = useState<ControlNetInit>({
      module: "",
      weight: 0.8,
    })
    const [contolNet2Init, setControlNet2Init] = useState<ControlNetInit>({
      module: "",
      weight: 0.8,
    })

    const [controlNet2Visible, setControlNet2Visible] = useState(false)
    const [currentControlNet, setCurrentControlNet] = useState(1)
    //  移动端 paint 画板点击图标
    const [drawingBoardVisible, setDrawingBoardVisible] = useState(false)
    // 复制图片 确认
    const [copyConfirmVisible, setCopyConfirmVisible] = useState(false)
    const [confirmMessage, setConfirmMessage] = useState("")
    const [copyImgObj, setCopyImgObj] = useState({
      imgSrc: "",
      currentIndex: 1,
      message: "",
    })
    const controlNetList = [
      { label: "参考1", key: 1 },
      {
        label: "参考2",
        key: 2,
      },
    ]
    const textMap = new Map([
      ["module", "模型类型"],
      ["inputImageURL", " 参考图"],
      ["weight", "权重"],
    ])
    // 取缓存数据
    useEffect(() => {
      // 图片尺寸与 controlNet 参考图联动
      setCheckedRatio(ratio)
      // aspectRatio裁切组件使用比例
      rotateTo(ratio)
      // 作品中心过来的有参考图
      if (sourceImage) {
        setControlNet1Init({
          ...contolNet1Init,
          weight: 0.8,
          inputImageURL: sourceImage,
          // module:
        })
        setSourceImage(sourceImage)
      } else {
        if (
          referencelIamgeData?.type === "controlNet" &&
          referencelIamgeData.payload.controlNet.units?.[0]
        ) {
          const t = referencelIamgeData.payload.controlNet.units
          setControlNet1Init(t[0])
          t?.[1] && setControlNet2Init(t[1])
        } else {
          setControlNet1Init({
            ...contolNet1Init,
            weight: 0.8,
            inputImageURL: "",
          })
          setControlNet2Init({
            ...contolNet2Init,
            weight: 0.8,
            inputImageURL: "",
          })
        }
      }
    }, [JSON.stringify(referencelIamgeData), visible])
    const closeModal = () => {
      if (imgStatus === 1) {
        return
      }
      setCurrentControlNet(1)
      setVisible(false)
      setControlNet2Visible(false)
    }
    //  打开窗口触发 接收 mode 值
    useImperativeHandle(event, () => ({
      onOpen: (mode = []) => {
        setVisible(true)
        mode.length > 1 ? setControlNet2Visible(true) : setControlNet2Visible(false)
        // 初始状态下  移动端没有左侧的模型
        let contolNet1InitTemp = isMobileOnly
          ? contolNet1Init
          : { ...contolNet1Init, module: mode[0] }
        setControlNet1Init(contolNet1InitTemp)
        if (isMobileOnly) {
          setControlNet2Init({ ...contolNet2Init })
        } else {
          const otherControlNetModel = filterControlNetModel(modelVersion, mode[0])

          if (mode?.length > 1) {
            setControlNet2Init({
              weight: 0.8,
              module: modelVersion === "TIMv5" ? otherControlNetModel[0]?.value : mode[1],
            })
          } else {
            setControlNet2Init({
              weight: 0.8,
              module: modelVersion === "TIMv5" ? otherControlNetModel[0]?.value : mode[0],
            })
          }
        }
      },
    }))
    const onKeyDownCtrlN = (oEvent: KeyboardEvent) => {
      const nKeyCode = oEvent.keyCode
      const bCtrlKeyCode = oEvent.ctrlKey || oEvent.metaKey
      if (nKeyCode === 78 && bCtrlKeyCode) {
        setVisible(true)
      }
    }
    const rotateTo = (item: string) => {
      const [w, h] = item.split(":")
      const rotate = +w / +h
      setAspectRatio(rotate)
    }
    // add or minus controlNet2 是否展示
    const addControlNet2 = () => {
      setCurrentControlNet(2)
      setControlNet2Visible(true)
    }
    const removeControlNet2 = () => {
      //  重置 controlNet2 数据
      setControlNet2Init({
        module: "",
        weight: 0.8,
      })
      setControlNet2Visible(false)
    }
    // 校验
    const validate = (params: Array<ControlNetValide>) => {
      //  数据第一层 为 controlNet 对象
      for (let i = 0; i < params.length; i++) {
        // 遍历controlNet的属性
        for (let j = 0; j < Object.keys(params[i]).length; j++) {
          if (!params[i][Object.keys(params[i])[j]]) {
            //  返回 controlNet1或controlNet2 具体属性
            return [i + 1, Object.keys(params[i])[j] || ""]
          }
        }
      }
      return []
    }
    // 复制图片
    const copyImage = () => {
      // 从 controlNet1 复制到 controlNet2  打开controlNet2并复制图片
      if (copyImgObj?.currentIndex === 1) {
        setControlNet2Visible(true)
        setControlNet2Init({
          ...contolNet2Init,
          inputImageURL: copyImgObj?.imgSrc,
        })
        //  移动端
        setCurrentControlNet(2)
      } else {
        setControlNet1Init({
          ...contolNet1Init,
          inputImageURL: copyImgObj.imgSrc,
        })
        //  移动端
        setCurrentControlNet(1)
      }
    }
    const saveConfirmObj = (imgSrc: string, currentIndex: number) => {
      setCopyImgObj({
        imgSrc,
        currentIndex,
        message: `请确认是否复制图片到参考${currentIndex === 1 ? "2" : "1"}?`,
      })
      setCopyConfirmVisible(true)
    }
    // 提交数据前 在子组件调用图片上传
    const submit = async () => {
      // 点击确认按钮 收集剪切图片
      const fetchControlNet1 = controlNetUploadRef?.current
        ?.uploadCropImage as () => Promise<string>
      const fetchControlNet2 = controlNetUploadRef2?.current
        ?.uploadCropImage as () => Promise<string>
      //  单 controlNet 还是双 controlNet
      const all = Promise.all([fetchControlNet1(), controlNet2Visible && fetchControlNet2()])
        .then(async (res) => {
          //  单 controlNet 还是双 controlNet
          let tempInitData = controlNet2Visible
            ? [contolNet1Init, contolNet2Init] || []
            : [contolNet1Init]
          tempInitData[0].inputImageURL = res[0]
          // 双 controlNet 再添加图片地址
          if (tempInitData[1]) {
            tempInitData[1].inputImageURL = res[1] || ""
          }
          // 进行校验
          const validStatus = validate(tempInitData as Array<ControlNetValide>)
          if (validStatus?.length && typeof validStatus[1] === "string") {
            toastError(`ControlNet${validStatus[0]}的${textMap.get(validStatus[1] || "")}不能为空`)
            setBtnStatus(2)
            return false
          }
          // 改变按钮状态为”确认“
          setBtnStatus(1)
          // 把数据上交到上层
          onChange?.({
            type: "controlNet",
            preViewUrl: "" || "",
            payload: {
              controlNet: {
                units: tempInitData,
              },
            },
          })
          closeModal()
        })
        .catch((err) => {
          toastError(err)
          err != "请上传图片" && setBtnStatus(2)
        })
    }
    useEffect(() => {
      document.addEventListener("keydown", onKeyDownCtrlN)
      return () => {
        document.removeEventListener("keydown", onKeyDownCtrlN)
      }
    }, [])
    // 移动端和PC 端是否展示
    const controlNet1ClassName = useMemo(() => {
      return isMobileOnly ? (currentControlNet === 1 ? "block" : "hidden") : "block"
    }, [isMobileOnly, currentControlNet])
    const controlNet2ClassName = useMemo(() => {
      return isMobileOnly ? (currentControlNet === 2 ? "block" : "hidden") : "block"
    }, [isMobileOnly, currentControlNet])

    return (
      <>
        <CheckAuth className="flex items-center  text-sm" onClick={() => setVisible(true)}>
          {buttonContent}
        </CheckAuth>
        {/* 确认弹框 */}
        {/* {copyConfirmVisible && ( */}
        <ConfirmModal
          visible={copyConfirmVisible}
          message={copyImgObj?.message}
          handleClose={(isConfirm) => {
            isConfirm && copyImage()
            setCopyConfirmVisible(false)
          }}
        />
        {/* )} */}
        <Modal
          maskStyle={{
            backgroundColor: "#000000ba",
          }}
          className="w-full"
          unmountOnExit={true}
          visible={visible}
          onCancel={closeModal}
          title={<div style={{ textAlign: "left" }}></div>}
          modalRender={() => {
            return (
              <div className="relative mx-auto top-0 inline-block align-middle w-full h-full md:w-auto md:h-auto bg-[#212134] rounded md:overflow-hidden  overflow-scroll">
                <ModalHeader
                  controlNet2Visible={controlNet2Visible}
                  handleClose={closeModal}
                  changeControlNet={(key) => setCurrentControlNet(key)}
                  currentControlNet={currentControlNet}
                  addOrMinus={(status: string) => {
                    if (status === "add") {
                      addControlNet2()
                    } else {
                      removeControlNet2()
                      // 将currentControlNet变为currentControlNet1
                      setCurrentControlNet(1)
                    }
                  }}
                />
                <div className="md:px-[40px] px-[20px]">
                  <div className="flex justify-center gap-[20px]">
                    <ControlNetUpload
                      index={1}
                      // ratio={aspectRatio}
                      handleChange={(data) => {
                        setControlNet1Init(data)
                      }}
                      mobilePaintVisible={drawingBoardVisible}
                      ref={controlNetUploadRef}
                      init={contolNet1Init || {}}
                      contolNet2Init={contolNet2Init || {}}
                      button={
                        !controlNet2Visible ? (
                          <div
                            onClick={addControlNet2}
                            className="ml-[10px] bg-[#161623] rounded-[4px] w-[36px] h-[36px] flex justify-center items-center cursor-pointer"
                          >
                            <AddIcon />
                          </div>
                        ) : (
                          <div></div>
                        )
                      }
                      handleClosePaint={() => setDrawingBoardVisible(false)}
                      visible={visible}
                      className={controlNet1ClassName}
                      copyImage={(imgSrc: string, currentIndex: number) => {
                        !imgSrc ? toastError("请先上传图片") : saveConfirmObj(imgSrc, currentIndex)
                      }}
                    />
                    {/* controlNet2Visible 为false则不渲染controlNet2的组件  */}
                    {controlNet2Visible && (
                      <ControlNetUpload
                        index={2}
                        handleClosePaint={() => setDrawingBoardVisible(false)}
                        handleChange={(data) => {
                          setControlNet2Init(data)
                        }}
                        // ratio={aspectRatio}
                        init={contolNet2Init || {}}
                        contolNet1Init={contolNet1Init || {}}
                        mobilePaintVisible={drawingBoardVisible}
                        button={
                          controlNet2Visible ? (
                            <div
                              onClick={removeControlNet2}
                              className="ml-[10px] bg-[#161623] rounded-[4px] w-[36px] h-[36px] flex justify-center items-center cursor-pointer"
                            >
                              <MinuIcon />
                            </div>
                          ) : (
                            <div></div>
                          )
                        }
                        ref={controlNetUploadRef2}
                        visible={visible}
                        className={controlNet2ClassName}
                        copyImage={(imgSrc: string, currentIndex: number) => {
                          !imgSrc
                            ? toastError("请先上传图片")
                            : saveConfirmObj(imgSrc, currentIndex)
                        }}
                      />
                    )}
                  </div>
                  {/* 图片比例 */}
                  <ImageSize
                    className={`${isMobileOnly ? "" : "flex justify-center gap-[20px]"}`}
                  />
                  <div className="mt-[20px] pb-8  flex justify-between md:justify-center">
                    {/* index 为 0时 controlNet1 复制图片到 controlNet2 */}
                    <div className="flex gap-[10px] p-[5px] rounded-[4px]">
                      <div
                        className="rounded-[4px] w-[36px] h-[36px] border-[1px] border-[gray] justify-center items-center flex md:hidden"
                        onClick={() => {
                          let currentImg =
                            currentControlNet == 1
                              ? controlNetUploadRef?.current?.imgUrl
                              : controlNetUploadRef2?.current?.imgUrl
                          // 图片不存在不能复制
                          !currentImg
                            ? toastError("请先上传图片")
                            : saveConfirmObj(currentImg || "", currentControlNet)
                        }}
                      >
                        {currentControlNet === 1 ? <CopyToRIghtIcon /> : <CopyToLeftIcon />}
                      </div>
                      <div
                        className="rounded-[4px] w-[36px] h-[36px] border-[1px] border-[gray] flex justify-center items-center cursor-pointer md:hidden"
                        onClick={() => {
                          setDrawingBoardVisible(true)
                        }}
                      >
                        <PaintIcon />
                      </div>
                    </div>
                    <div
                      className={`flex gap-[10px] w-full ${
                        controlNet2Visible ? "justify-end" : "justify-center"
                      }`}
                    >
                      <button
                        disabled={imgStatus === 1}
                        className="w-[120px] text-sm rounded border py-2 border-[#c9cbd14d]"
                        onClick={() => {
                          if (imgStatus === 2) {
                            return false
                          } else {
                            closeModal()
                          }
                        }}
                      >
                        {imgStatus === 3 ? "重新上传" : imgStatus === 2 ? "重试" : "取消"}
                      </button>
                      <button
                        disabled={imgStatus === 1}
                        className="text-sm rounded  bg-[#4C1FFF] w-[120px] py-2"
                        onClick={submit}
                      >
                        {btnStatus === 1 ? "确认" : "重新上传"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )
          }}
        />
      </>
    )
  }
)

export const ControlNetInOtherPage = observer(
  ({ sourceImage, buttonContent }: { sourceImage: string; buttonContent: React.ReactNode }) => {
    const navigate = useNavigate()
    const onChange = (referencelIamgeData: {
      type: "controlNet"
      preViewUrl: string
      payload: { controlNet: V1ControlNetSettings }
    }) => {
      studioPagesStore.init(({ taskHistory, runingTasks }) => ({
        taskHistory,
        runingTasks,
        referencelIamgeData,
      }))
      navigate({
        pathname: "/studio",
      })
    }

    return (
      <div style={{ zIndex: "10098" }}>
        <ControlNetInStudioPage
          buttonContent={buttonContent}
          onChange={onChange}
          sourceImage={sourceImage}
        />
      </div>
    )
  }
)
