import { V1TicketTheme } from "@/generated/api/data-contracts"
import cutomizePng from "@/assets/subscription_enterpirse_customize.png"
import collabPng from "@/assets/subscription_enterpirse_collab.png"
import e1 from "@/assets/enterprise_1.png"
import e2 from "@/assets/enterprise_2.png"
import e3 from "@/assets/enterprise_3.png"
import e4 from "@/assets/enterprise_4.png"

export const ReportReasonList: {
  label: string
  value: V1TicketTheme
}[] = [
  {
    value: V1TicketTheme.TicketThemePornographicVulgar,
    label: "色情低俗",
  },
  {
    value: V1TicketTheme.TicketThemeViolationOfLawsAndRegulations,
    label: "违法违规",
  },
  {
    value: V1TicketTheme.TicketThemeEndangerNationalSecurity,
    label: "危害国家安全",
  },
  {
    value: V1TicketTheme.TicketThemeFalseInformation,
    label: "不实信息",
  },
  {
    value: V1TicketTheme.TicketThemeDiscomfort,
    label: "引人不适",
  },
  {
    value: V1TicketTheme.TicketThemeBadValueGuide,
    label: "不良价值引导",
  },
  {
    value: V1TicketTheme.TicketThemeAbuseOfMinors,
    label: "侵害未成年",
  },
  {
    value: V1TicketTheme.TicketThemeViolationofRightsAndInterests,
    label: "侵犯权益",
  },
  {
    value: V1TicketTheme.TicketThemeOther,
    label: "其他",
  },
]

export const CUSTOMIZATIONDESCRIBE = [
  [
    {
      title: "模型定制",
      desc: "根据您的具体需求定制模型，助力您的业务发展",
      imgURL: cutomizePng,
    },
    {
      title: "企业协调",
      desc: "高效、快捷的服务于企业内部的项目协调工作",
      imgURL: collabPng,
    },
  ],
  [
    {
      title: "专属界面",
      desc: "定制您的专属界面",
      imgURL: e1,
    },
    {
      title: "共享图库",
      desc: `您和您的同事共享图库<br/>快速协作`,
      imgURL: e2,
    },
    {
      title: "储存空间增加",
      desc: "您能获得更大的存储空间",
      imgURL: e3,
    },
    {
      title: "高级商业条款",
      desc: `根据您的团队规模<br/>定制商业权利`,
      imgURL: e4,
    },
  ],
]

export const COMMONQUESTIONS = [
  {
    title: "什么是快速模式",
    detail: `我们有两种图像生成模式，“快速模式”和“标准模式”。在快速模式下，我们将立即为您提供算力服务，并具有最高优先级。而在标准模式下，您的生成任务的优先级将排在他人之后。您可以自由选择开启哪种模式，以适应不同阶段您的创作需求。
    快速模式下，一小时大约是 300 次图像任务。`,
  },
  {
    title: `什么是标准模式？`,
    detail: `标准和进阶方案包括标准模式，可以在创作界面打开和关闭。在标准模式下，您的图像生成不会消耗快速生成的时间。
    请注意：入门计划不包括标准模式。`,
  },
  {
    title: `如果我不想让我的图像出现在图库中怎么办？`,
    detail: `我们是一个默认开放的社区，希望您能享受集体探索的乐趣。如果您需要选择退出并默认为私有，您可以订阅进阶方案并在创作界面打开「隐私模式」来让下一张图像不开放在社区。`,
  },
  {
    title: `我可以取消我的订阅方案吗？`,
    detail: `您可以随时取消订阅，但取消将在当前结算周期结束时生效。如果您改变主意，您可以在周期结束前取消您的计划。`,
  },
  {
    title: `我可以更改我的订阅方案吗？`,
    detail: `您可以随时更改您的订阅，立即生效。任何剩余的使用量或时间都将计入您的新计划。`,
  },
]
