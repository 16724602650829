import * as store from "@/store"
import { V1Task, V1Work } from "@/generated/api/data-contracts"
import AlbumSelect from "../../../components/AlbumSelect"
import Options from "../Options"
import { observer } from "mobx-react-lite"

interface OptionsLayerProps {
  work: V1Work
  task: V1Task
  isPopoverOpen: boolean
  setIsPopoverOpen: (visible: boolean) => void
  isAlbumSelectOpen: boolean
  setIsAlbumSelectOpen: (visible: boolean) => void
  togglePrivacyStatus: () => Promise<void>
  isVisited?: boolean
  onTaskUpdated?: (task: V1Task) => void
}
const OptionsLayer = observer(
  ({
    work,
    task,
    isPopoverOpen,
    setIsPopoverOpen,
    isAlbumSelectOpen,
    setIsAlbumSelectOpen,
    togglePrivacyStatus,
    isVisited,
    onTaskUpdated,
  }: OptionsLayerProps) => {
    const actionLayerDisplayCls =
      isPopoverOpen || isAlbumSelectOpen ? "block" : "hidden group-hover:block"
    const user = store.user.get()

    const privacyOptionHidden = !user.canPrivateGeneration || isVisited

    const handleWorkDeleted = (workId: string) => {
      onTaskUpdated?.({
        ...task,
        works: task.works?.filter((work) => work.id !== workId),
      })
    }

    return (
      <div className={`absolute inset-0 ${actionLayerDisplayCls}`}>
        <div className="absolute top-2.5 right-2.5">
          <AlbumSelect
            work={work}
            className={isAlbumSelectOpen ? "bg-white bg-opacity-10" : ""}
            onSelectVisibleChange={(visible) => setIsAlbumSelectOpen(visible)}
          />
        </div>

        <div
          className={`absolute bottom-0 left-0 right-0 px-2.5 pb-2.5 transital  duration-150 ease-in-out transform`}
        >
          <Options
            work={work}
            task={task}
            hiddenConfig={{
              publicOrPrivate: privacyOptionHidden,
              linkOrUnlike: true,
              usedFor: isVisited,
              deleteTask: isVisited,
              imageRepainting: isVisited,
              menuCopyBtn: isVisited,
            }}
            directCopyBtnVisible={isVisited}
            operationHandlers={{
              publicOrPrivate: () => togglePrivacyStatus(),
              onWorkDeleted: handleWorkDeleted,
            }}
            onPopoverVisibleChange={(visible) => {
              setIsPopoverOpen(visible)
            }}
          />
        </div>
      </div>
    )
  }
)

export default OptionsLayer
