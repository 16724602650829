import { useState, useEffect } from "react"

import { ReactComponent as CloseIcon } from "@/assets/close_black.svg"
import { ReactComponent as DownloadWhiteIcon } from "@/assets/download-white.svg"
import { ReactComponent as ScoreIcon } from "@/assets/score2.svg"
import { ReactComponent as WorksLoadingIcon } from "@/assets/works-loading.svg"
import { ReactComponent as DarkModelIcon } from "@/assets/dark-model.svg"
import { ReactComponent as UnknownModelIcon } from "@/assets/unknown-model.svg"
import { ReactComponent as ShortLineIcon } from "@/assets/short-line.svg"
import { ReactComponent as LockIcon } from "@/assets/lock.svg"
import { ReactComponent as CopyIcon } from "@/assets/copy.svg"
import { ReactComponent as HeartWhiteIcon } from "@/assets/heart-white.svg"
import { ReactComponent as HeartRedIcon } from "@/assets/heart-red.svg"
import { ReactComponent as RightIcon } from "@/assets/right.svg"
import { ReactComponent as DangerIcon } from "@/assets/danger.svg"
import { Modal, Tooltip } from "@arco-design/web-react"
import { CopyToClipboard } from "react-copy-to-clipboard"

import {
  getImageURL,
  computeWorkRatio,
  handleDownload,
  isPortraitWork,
  featureDeprecatedAlert,
} from "@/utils"
import { toastInfo } from "@/common/Toast"
import { V1Task, V1Work } from "@/generated/api/data-contracts"
import { api } from "@/services/api"
import { MODELS_MAP, OTHER_MODEL } from "@/constants"
import { runInAction } from "mobx"
import { observer } from "mobx-react-lite"
import { isMobileOnly } from "react-device-detect"
import Report from "@/common/Report"
import GenerateAgainTask from "@/Studio/components/GenerateAgainTask"

export default observer(function ({
  open,
  onClose,
  task,
  work,
}: {
  open: boolean
  onClose: () => void
  task: V1Task
  work: V1Work
}) {
  useEffect(() => {
    // console.log(work, task.works)

    if (open) {
      document.body.classList.add("overflow-hidden")
    } else {
      document.body.classList.remove("overflow-hidden")
    }
  }, [open])

  const [selectedWorkIndex, setSelectedWorkIndex] = useState(
    () => task.works?.findIndex((w) => w.id === work.id) || 0
  )
  const current = task.works?.[selectedWorkIndex] || {}
  const currentSize = current.size || work.size
  const [currLike, setCurrLike] = useState(false)
  useEffect(() => {
    console.log()
    setCurrLike(!!current?.liked)
  }, [current])

  const worksLength = task.works?.length || 0
  const lastWorkIndex = worksLength - 1

  const [selectedTab, setSelectedTab] = useState("textPrompt")
  const currentPrompt =
    (selectedTab === "textPrompt" ? task.textPrompt : task.negativeTextPrompt) || ""

  const privatePrompt = !!task.privatePrompt
  const model = MODELS_MAP.get(task.model || "")

  // const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [loadedImgList, setLoadedImgList] = useState<string[]>()

  return (
    <Modal
      visible={open}
      footer={null}
      closeIcon={null}
      // className="w-[980px]"
      maskStyle={{
        background: "#f8f8f8f0",
        // background: "rgba(248, 248, 248, 0.94);",
      }}
      // wrapClassName="bg-[#f8f8f8]"
      onCancel={() => onClose()}
      maskClosable
      style={{
        width: "auto",
        padding: "0",
      }}
    >
      <div className="pr-[20px] pl-[24px] bg-white rounded relative">
        <div className="flex md:flex-row flex-col-reverse gap-[16px]">
          <div className="md:w-[338px] flex-shrink-0">
            <div className="flex items-end">
              <span className="text-5xl flex text-[#5A14F0] font-[num]">
                {current.score?.toFixed ? current.score?.toFixed(2) : "暂无"}
              </span>
              <ScoreIcon className="mb-2" />
            </div>
            {privatePrompt ? (
              <>
                <section className="h-[178px] rounded overflow-hidden">
                  <section className="flex justify-between pt-[12px] px-[12px] h-[34px] bg-[#F5F8F9]">
                    <span className="bg-[#f6f8f9] font-bold text-[#101010] text-sm">
                      图片描述语
                    </span>
                    <Tooltip content="联系我们定制图像">
                      <section>
                        <LockIcon />
                      </section>
                    </Tooltip>
                  </section>
                  <section className="h-[144px] relative overflow-hidden">
                    <section className="flex justify-center items-center absolute left-0 right-0 top-0 bottom-0 w-full h-full backdrop-blur-sm bg-[#F3F6F8CC]/80 text-[#6F7176] text-sm leading-5 text-center">
                      本图像由内测平台生成
                      <br />
                      如有需要，请联系我们
                    </section>
                    <section className="p-4">
                      本图像由内测平台生成，如有需要，请联系我们。本图像由内测平台生成，如有需要，请联系我们。
                    </section>
                  </section>
                </section>
                <a
                  href="https://wenjuan.feishu.cn/m?t=sPd5AQJUXHKi-vxxz"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex justify-center items-center w-full h-[40px] mt-[14px] px-8 py-1 border border-[#5A14F0] text-white bg-gradient-to-r from-[#734AFF] to-[#5C16F1] rounded text-sm"
                >
                  联系我们
                </a>
              </>
            ) : (
              <>
                <section className="mt-[12px] md:h-max-[254px] overflow-hidden">
                  {task.negativeTextPrompt && (
                    <>
                      <section className="flex rounded-t h-[34px] bg-[#D9DFE1] text-[#101010] text-sm">
                        <button
                          onClick={() => {
                            setSelectedTab("textPrompt")
                          }}
                          aria-checked={selectedTab === "textPrompt"}
                          className="w-[50%] text-center aria-checked:bg-[#f6f8f9] aria-checked:font-bold rounded-l"
                        >
                          图片描述语
                        </button>
                        {/* {task.negativeTextPrompt ? ( */}
                        <button
                          onClick={() => {
                            setSelectedTab("negativeTextPrompt")
                          }}
                          aria-checked={selectedTab === "negativeTextPrompt"}
                          className="w-[50%] text-center aria-checked:bg-[#f6f8f9] aria-checked:font-bold rounded-r"
                        >
                          不需要元素
                        </button>
                        {/* ) : null} */}
                      </section>
                    </>
                  )}
                  <section
                    aria-disabled={privatePrompt}
                    className="relative pt-[12px] pb-[48px] md:h-max-[224px] rounded-b text-sm bg-[#f6f8f9] aria-disabled:backdrop-blur-sm"
                  >
                    <section className="h-full px-[12px] overflow-y-auto">
                      <section>{currentPrompt || "暂无描述"}</section>
                    </section>

                    <CopyToClipboard
                      text={currentPrompt}
                      onCopy={() => {
                        toastInfo("已复制")
                      }}
                    >
                      <button className="absolute bottom-[8px] right-[12px] px-[12px] py-[4px] flex items-center gap-[4px] bg-transparent hover:bg-[#C9CBD1]/30 rounded">
                        <CopyIcon />
                        <span className="text-xs text-[#6F7176]">复制文字</span>
                      </button>
                    </CopyToClipboard>
                  </section>
                </section>

                <section className="mt-[14px] flex justify-between items-center gap-[18px] w-full">
                  <a
                    href="https://wenjuan.feishu.cn/m?t=sPd5AQJUXHKi-vxxz"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex-1 px-8 py-1 border bg-transparent border-[#5A14F0] text-[#5A14F0] text-center rounded"
                  >
                    联系我们
                  </a>
                  <GenerateAgainTask
                    task={task}
                    buttonContent={
                      <div className="flex-1 px-8 py-1 border border-[#5A14F0] text-white text-center bg-gradient-to-r from-[#734AFF] to-[#5C16F1] rounded">
                        再次生成
                      </div>
                    }
                  />
                </section>
              </>
            )}
            <section className="mt-[30px] flex md:flex-col flex-row md:justify-start justify-between">
              <section className="flex items-center gap-[4px]">
                <span className="text-sm text-[#6F7176] leading-[20px]">模型</span>
                {!model ? <LockIcon width={22} height={14} /> : null}
              </section>
              <section className="md:mt-[4px] flex items-center gap-[4px]">
                {!model ? (
                  <UnknownModelIcon className="fill-[#010101] dark:fill-[#fff]" />
                ) : (
                  <DarkModelIcon className="fill-[#010101] dark:fill-[#fff]" />
                )}
                <span className="text-sm text-[#101010] leading-[20px]">
                  {model?.name || OTHER_MODEL}
                </span>
              </section>
              {isMobileOnly ? <></> : <ShortLineIcon className="mt-[16px]" />}
              <section className="md:mt-[12px] text-sm text-[#6F7176] leading-[20px]">
                图片尺寸
              </section>
              <section className="md:mt-[4px] text-sm text-[#101010] leading-[20px]">
                {currentSize || "-"}
              </section>
              {isMobileOnly ? <></> : <ShortLineIcon className="mt-[16px]" />}
              <div className="inline-flex items-center md:mt-[12px] ">
                <Report
                  id={current.id}
                  element={
                    <button className="flex items-center cursor-pointer text-sm text-[#6F7176] leading-[20px] hover:text-[#5A14F0]">
                      <DangerIcon />
                      <span className="text-sm ml-1 leading-[20px]">举报</span>
                    </button>
                  }
                />
              </div>
            </section>
          </div>
          <section>
            <section className="flex items-center">
              <button
                className="mr-[8px] flex-shrink-0 w-[38px] h-[38px] flex justify-center items-center bg-[#e0e0e0] disabled:opacity-50 rounded"
                onClick={() => {
                  setSelectedWorkIndex(selectedWorkIndex - 1)
                  // setIsLoaded(false)
                }}
                disabled={worksLength < 1 || selectedWorkIndex === 0}
              >
                <RightIcon width={26} height={26} fill="#6F7176" className="rotate-180" />
              </button>
              <div className="relative">
                <section
                  className={`relative ${(() => {
                    // 横图和方图展示宽度为 600px
                    // 竖图展示高度为 660px
                    // 详细逻辑见：https://github.com/TiamatAI/xica-docs/issues/160#issuecomment-1483978692
                    if (!currentSize) return "md:w-[600px] md:min-h-[175px]"
                    const ratio = computeWorkRatio(currentSize)
                    if (isPortraitWork(currentSize)) {
                      return `md:w-[${660 * ratio}px] md:h-[660px]`
                    } else {
                      return `md:w-[600px] md:h-[${600 / ratio}px]`
                    }
                  })()}`}
                >
                  <img
                    className="w-full h-full"
                    style={{
                      visibility:
                        current.image && loadedImgList?.includes(current.image)
                          ? "visible"
                          : "hidden",
                    }}
                    onLoad={(e) => {
                      current.image && setLoadedImgList([current.image].concat(loadedImgList || []))
                      // FIXME 接口清理脏数据后可以移除
                      // 接口返回的 size 和 ratio 可能与实际图片信息不一致，导致显示比例不正确
                      // https://github.com/TiamatAI/xica-docs/issues/235#issuecomment-1495304268
                      runInAction(() => {
                        const w = (e.target as HTMLImageElement).naturalWidth
                        const h = (e.target as HTMLImageElement).naturalHeight
                        current.size = `${w}x${h}`
                        // 这里ratio导致再次生成比例错误 + 代码暂未用到，所以注释了
                        // current.ratio = `1:${(h / w).toFixed(2)}`
                      })
                    }}
                    src={getImageURL(current.image)}
                    alt=""
                  />
                  <button
                    className="absolute right-[8px] top-[8px] w-[28px] h-[28px] flex justify-center items-center bg-[#000000]/50 rounded hover:bg-[#000000]/80 transition-colors"
                    onClick={() => {
                      featureDeprecatedAlert()
                      // currLike
                      //   ? api
                      //       .unlikeWork(current.id!, { collectionUID: "JTQltwGbPEG3equutKwrX" })
                      //       .then(() => {
                      //         setCurrLike(!currLike)
                      //         runInAction(() => (current.liked = !currLike))
                      //       })
                      //   : api.likeWork(current.id!, {}).then(() => {
                      //       setCurrLike(!currLike)
                      //       runInAction(() => (current.liked = !currLike))
                      //     })
                    }}
                  >
                    {currLike ? <HeartRedIcon /> : <HeartWhiteIcon />}
                  </button>
                  <button
                    className="absolute right-[8px] bottom-[8px] w-[28px] h-[28px] flex justify-center items-center bg-[#000000]/50 rounded hover:bg-[#000000]/80 transition-colors"
                    onClick={() => handleDownload(current.image!)}
                  >
                    <DownloadWhiteIcon width={18} height={18} />
                  </button>
                </section>
                <div
                  className="absolute z-10 top-0  bg-white right-0 h-full w-full flex flex-col justify-center items-center"
                  style={{
                    display:
                      current.image && loadedImgList?.includes(current.image) ? "none" : "flex",
                  }}
                >
                  <WorksLoadingIcon />
                  <p className="text-xl leading-[31px] mt-[4px] text-[#000000]">作品加载中～</p>
                </div>
              </div>
              <button
                className="ml-[8px] flex-shrink-0 w-[38px] h-[38px] flex justify-center items-center bg-[#e0e0e0] disabled:opacity-50 rounded"
                onClick={() => {
                  setSelectedWorkIndex(selectedWorkIndex + 1)
                }}
                disabled={worksLength < 1 || selectedWorkIndex === lastWorkIndex}
              >
                <RightIcon width={26} height={26} fill="#6F7176" className="rotate-0" />
              </button>
            </section>
            {/* 查看大图小头像部分 */}
            {/* 最多展示四个小图宽度为 212px = (50px x 4) + (4px x 3) */}
            <section className="mt-[10px] mx-auto max-w-[212px] overflow-hidden">
              <section
                className="flex gap-[4px] transition-transform transform-gpu"
                style={{
                  transform: `translateX(${(() => {
                    // 超过 4 图时的偏移逻辑： https://github.com/TiamatAI/xica-docs/issues/91#issuecomment-1481324991
                    if (worksLength <= 4) return 0
                    /** 一个缩略图的宽度加上间隙宽度 */
                    const d = 54
                    /** 选中最后两张缩略图的偏移量 */
                    if (lastWorkIndex - selectedWorkIndex < 2) return -((worksLength - 4) * d)
                    /** 选中超过第二张缩略图的偏移量 */
                    if (selectedWorkIndex > 1) return -((selectedWorkIndex - 1) * d)
                    return 0
                  })()}px)`,
                }}
              >
                {task.works?.map((work, i) => (
                  <button
                    key={work.id}
                    className="flex-shrink-0 bg-black group"
                    aria-checked={selectedWorkIndex === i}
                    onClick={() => {
                      setSelectedWorkIndex(i)
                    }}
                  >
                    <img
                      className="h-[50px] w-[50px] object-cover opacity-40 group-aria-checked:opacity-100"
                      src={`${work.image}`}
                    />
                  </button>
                ))}
              </section>
            </section>
          </section>
        </div>
        <button
          className="absolute md:-right-16 right-5 -top-4 hover:brightness-125"
          onClick={onClose}
        >
          <CloseIcon />
        </button>
      </div>
    </Modal>
  )
})
