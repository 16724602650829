import type { ReactNode } from "react"
import Question from "@/components/common/Question"
import Header from "../userCenter/Header"
import { BackTopButton } from "@/common/BackTopButton"
interface Props {
  hasHeader?: boolean
  children?: ReactNode
  className?: string
}
function LayoutGeneral({ children, hasHeader = false, className }: Props) {
  return (
    <div>
      {hasHeader && <Header />}
      <div className={`${className || ""}`}>
        {children}
        <BackTopButton
          style={{
            bottom: "50px",
          }}
        />
        <div className="fixed md:bottom-[10px]  bottom-[70px] right-[24px] ">
          <Question />
        </div>
      </div>
    </div>
  )
}

export default LayoutGeneral
