import { useState, useEffect, useMemo, useRef } from "react"
import { ReactComponent as ScoreIcon } from "@/assets/score2.svg"
import { toastInfo } from "@/common/Toast"
import { ReactComponent as WorksLoadingIcon } from "@/assets/works-loading.svg"
import { ReactComponent as LockIcon } from "@/assets/lock.svg"
import { ReactComponent as CopyIcon } from "@/assets/copy2.svg"
import { ReactComponent as MagicIcon } from "@/assets/magic2.svg"
import DefaultAvatarSvg from "@/assets/login_default_avatar.svg"
import * as store from "@/store"
import { Link, useNavigate } from "react-router-dom"
export type TaskChangeDisableType = "prev" | "next" | "none" | "both"
import dayjs from "dayjs"
import { Modal, Tooltip } from "@arco-design/web-react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { computeWorkRatio, handleDownload, isPortraitWork, useDarkMode, getImageURL } from "@/utils"
import { V1Task, V1Work } from "@/generated/api/data-contracts"
import { MODELS_MAP, OTHER_MODEL } from "@/constants"
import { runInAction } from "mobx"
import GenerateAgainTask from "@/Studio/components/GenerateAgainTask"
import PictureOption from "@/common/PictureOption"
import AlbumSelect from "../../WorksTab/components/AlbumSelect"
import { getUserPath } from "@/router/constants"
const AlbumImageDetail = ({ work, tasks }: { work: V1Work; tasks?: V1Task }) => {
  useDarkMode()

  const currentSize = useMemo(() => {
    return work?.size
  }, [work?.size])

  const imgDetailRef = useRef<any>(null)
  const [currLike, setCurrLike] = useState(false)
  const [task, setTask] = useState<V1Task>()
  useEffect(() => {
    setCurrLike(!!work?.liked)
    work && setTask(work.task)
  }, [work])

  const [isAlbumSelectOpen, setIsAlbumSelectOpen] = useState(false)
  const [selectedTab, setSelectedTab] = useState("textPrompt")

  const privatePrompt = !!task?.privatePrompt
  const worksWithChangedLiked = store.hasChangedLikedWorks.get()

  let works = task?.works || []
  works = works.filter((w) => {
    return w && !w.illegal
  })
  const fromHomePage = false
  const [selectedWorkIndex, setSelectedWorkIndex] = useState(0)

  const navigate = useNavigate()
  const current = works?.[selectedWorkIndex] || {}

  const showReCreate = useMemo(() => {
    return !!task?.textPrompt
  }, [task])

  const currentPrompt =
    (selectedTab === "textPrompt" ? task?.textPrompt : task?.negativeTextPrompt) || ""

  const model = MODELS_MAP.get(task?.model || "")

  const [loadedImgList, setLoadedImgList] = useState<string[]>()
  // const [currLike, setCurrLike] = useState<boolean>(work.liked!)

  // const handleLike = async () => {
  //   return true
  // const isSuccess = await likeOrUnlike?.(
  //   isLiked || false,
  //   current.id || "",
  //   selectedWorkIndex,
  //   current
  // )
  // if (isSuccess) {
  //   if (fromHomePage) {
  //     store.changeHasChangedLikedWorks(current.id!)
  //   }
  // }
  // }
  const linkToVisitor = (id: string) => {
    navigate(getUserPath(id))
  }

  const likedStatusChanged = useMemo(() => {
    if (!fromHomePage) {
      return false
    }

    return worksWithChangedLiked?.some((x: string) => current?.id == x)
  }, [worksWithChangedLiked, fromHomePage, current])

  const isLiked = likedStatusChanged ? !current?.liked : current?.liked

  useEffect(() => {
    let index = () => works?.findIndex((w) => w.id === work?.id) || 0
    setSelectedWorkIndex(index)
  }, [work])
  const currentUser = null
  const userInfo = useMemo(() => {
    return (task as unknown as V1Task)?.user ?? currentUser ?? {}
  }, [task, currentUser])

  return (
    <div
      style={{ maxHeight: "calc(100vh - 100px)" }}
      className="pr-[20px] flex-1 pl-[24px] bg-white dark:bg-[#161623]  overflow-scroll rounded relative "
    >
      {work && (
        <div className="flex flex-col items-center gap-[16px]">
          {/* 展示图片 */}
          <div className="flex relative ">
            <section className="flex">
              <section className="flex items-center">
                <section
                // className={`relative ${(() => {
                //   // 横图和方图展示宽度为 600px
                //   // 竖图展示高度为 660px
                //   // 详细逻辑见：https://github.com/TiamatAI/xica-docs/issues/160#issuecomment-1483978692
                //   // 新版v2: 更改为700px
                //   if (!currentSize) return "md:w-[700px] md:min-h-[175px]"
                //   const ratio = computeWorkRatio(currentSize)
                //   if (isPortraitWork(currentSize)) {
                //     return `md:w-[${700 * ratio}px] md:h-[700px]`
                //   } else {
                //     return `md:w-[700px] md:h-[${700 / ratio}px] ml-[20px]`
                //   }
                // })()}`}
                >
                  {imgDetailRef?.current?.clientHeight && (
                    <img
                      className="w-full h-full object-contain"
                      style={{
                        visibility:
                          work.image && loadedImgList?.includes(work.image) ? "visible" : "hidden",
                        maxHeight: `calc(100vh - ${imgDetailRef?.current?.clientHeight + 100}px)`,
                      }}
                      onLoad={(e) => {
                        work.image && setLoadedImgList([work.image].concat(loadedImgList || []))
                        // FIXME 接口清理脏数据后可以移除
                        // 接口返回的 size 和 ratio 可能与实际图片信息不一致，导致显示比例不正确
                        // https://github.com/TiamatAI/xica-docs/issues/235#issuecomment-1495304268
                        runInAction(() => {
                          const w = (e.target as HTMLImageElement).naturalWidth
                          const h = (e.target as HTMLImageElement).naturalHeight
                          work.size = `${w}x${h}`
                          // 这里ratio导致再次生成比例错误 + 代码暂未用到，所以注释了
                          // work.ratio = `1:${(h / w).toFixed(2)}`
                        })
                      }}
                      src={getImageURL(work.image)}
                      alt=""
                    />
                  )}
                  <div className="absolute right-[6px] top-[6px] flex gap-[6px] ">
                    <div className="flex justify-center items-center bg-[#000000]/50 rounded hover:bg-[#000000]/80 transition-colors">
                      <AlbumSelect
                        work={work}
                        onSelectVisibleChange={(visible) => setIsAlbumSelectOpen(visible)}
                      />
                    </div>
                    <div className="flex justify-center items-center bg-[#000000]/50 rounded hover:bg-[#000000]/80 transition-colors">
                      <PictureOption
                        iconStyleCls="w-[36px] h-[36px]"
                        task={task as unknown as V1Task}
                        current={work as unknown as V1Work}
                        showReCreate={showReCreate}
                        currLike={!!isLiked}
                        // handleLike={handleLike}
                      />
                    </div>
                  </div>
                </section>
              </section>
            </section>
            <div
              className="absolute z-10 top-0  bg-white right-0 h-full w-full flex flex-col justify-center items-center"
              style={{
                display: work.image && loadedImgList?.includes(work.image) ? "none" : "flex",
              }}
            >
              <WorksLoadingIcon />
              <p className="text-xl leading-[31px] mt-[4px] text-[#000000]">作品加载中～</p>
            </div>
          </div>

          <div
            className="w-full flex items-center justify-between gap-[40px] absolute  bottom-0"
            ref={imgDetailRef}
          >
            {/* 展示信息  左 */}
            <div className="w-full">
              <div className="flex items-end">
                <span className="text-5xl flex text-[#5A14F0] font-[num]">
                  {work.score?.toFixed ? work.score?.toFixed(2) : "暂无"}
                </span>
                <ScoreIcon className="mb-2" />
              </div>
              <div className="flex justify-between items-center py-6 cursor-pointer">
                <div
                  className="flex items-center justify-center"
                  onClick={() => {
                    linkToVisitor(userInfo?.id || "")
                  }}
                >
                  <img
                    className="h-10 w-10 border border-[#FFFFFF33] rounded-full object-contain"
                    src={userInfo?.avatar ? userInfo.avatar : DefaultAvatarSvg}
                  />
                  <div className="pl-2  flex flex-col">
                    <label className="text-lg cursor-pointer">
                      {userInfo?.nick && userInfo.nick.length > 10
                        ? userInfo?.nick?.slice(0, 10) + "..."
                        : userInfo?.nick}
                    </label>
                    {/* <label className="text-[#FFFFFF80] text-sm">9867人关注</label> */}
                  </div>
                </div>
                {/* <div className="text-white border border-[#FFFFFF80] w-[80px] py-[6px] flex justify-center items-center rounded">
                关注
              </div> */}
              </div>

              <section className="mt-[14px] flex justify-between items-center gap-[18px] w-full">
                <CopyToClipboard
                  text={currentPrompt}
                  onCopy={() => {
                    toastInfo("已复制")
                  }}
                >
                  <button className="flex-1 w-full border border-[#FFFFFF80] py-[6px] px-[2px] flex justify-center items-center bg-transparent rounded">
                    <CopyIcon /> 复制描述语
                  </button>
                </CopyToClipboard>
                <div className="flex-1 w-full">
                  <GenerateAgainTask
                    task={task}
                    buttonContent={
                      <div className="flex-1 w-full flex justify-center items-center py-[6px] px-[2px]  border border-[#5A14F0] text-white text-center bg-[#4C1FFF] rounded">
                        <MagicIcon />
                        立即生成
                      </div>
                    }
                  />
                </div>
              </section>
            </div>
            {/* 展示信息  右 */}
            <div className="w-full flex flex-col">
              <section className="mt-[10px] bg-white dark:bg-[#0C0C1A] px-3 py-2 rounded flex ">
                <section className="w-1/4">
                  <section className="text-sm text-[#FFFFFF80] leading-[20px]">
                    <span className="text-xs  leading-[20px]">模型</span>
                    {/* {!model ? <LockIcon width={22} height={14} /> : null} */}
                  </section>
                  <section className="lg:mt-[4px] text-sm text-[#101010] dark:text-white leading-[20px]">
                    {/* {!model ? <UnknownModelIcon /> : <DarkModelIcon />} */}
                    <span className="text-sm  leading-[20px]">{model?.name || OTHER_MODEL}</span>
                  </section>
                </section>
                <section className="w-1/4">
                  <section className="text-xs text-[#FFFFFF80] leading-[20px]">尺寸</section>
                  <section className="lg:mt-[4px] text-sm text-[#101010] dark:text-white leading-[20px]">
                    {currentSize || "-"}
                  </section>
                </section>
                <section className="w-1/4">
                  <section className="text-xs text-[#FFFFFF80] leading-[20px]">日期</section>
                  <section className="lg:mt-[4px] text-sm text-[#101010] dark:text-white leading-[20px]">
                    {dayjs((task as unknown as V1Task)?.createdAt ?? "").format("YYYY.MM.DD")}
                  </section>
                </section>
                <section className="w-1/4">
                  {work.seed && work.seed !== -1 && work.seed !== 0 ? (
                    <>
                      <section className="text-xs text-[#FFFFFF80] leading-[20px]">编号</section>
                      <section className="lg:mt-[4px] text-sm text-[#101010] dark:text-white leading-[20px]">
                        {work.seed}
                      </section>
                    </>
                  ) : null}
                </section>
              </section>
              {privatePrompt ? (
                <>
                  <section className="h-[178px] rounded px-2 pb-2 dark:bg-[#0C0C1A] overflow-hidden">
                    <section className="flex justify-between pt-[12px] px-[12px] h-[34px] bg-[#F5F8F9] dark:bg-[#161623]">
                      <span className="bg-[#f6f8f9] dark:bg-[#0C0C1A] font-bold text-[#101010] dark:text-white text-sm">
                        图片描述：
                      </span>
                      <Tooltip content="联系我们定制图像">
                        <section>
                          <LockIcon />
                        </section>
                      </Tooltip>
                    </section>
                    <section className="h-[130px] relative overflow-hidden">
                      <section className="flex justify-center items-center absolute left-0 right-0 top-0 bottom-0 w-full h-full backdrop-blur-sm bg-[#F3F6F8CC]/80 dark:bg-[#000000CC] text-[#6F7176] dark:text-white text-sm rounded leading-5 text-center">
                        本图像由内测平台生成
                        <br />
                        如有需要，请联系我们
                      </section>
                      <section className="p-4">
                        本图像由内测平台生成，如有需要，请联系我们。本图像由内测平台生成，如有需要，请联系我们。
                      </section>
                    </section>
                  </section>
                </>
              ) : (
                <>
                  <section className="mt-[12px] lg:h-max-[254px] overflow-hidden">
                    <section
                      aria-disabled={privatePrompt}
                      className="relative pt-[12px] pb-[12px] lg:h-max-[224px] rounded-b text-sm bg-[#f6f8f9] dark:bg-[#0C0C1A] rounded aria-disabled:backdrop-blur-sm"
                    >
                      <section className="h-full px-[12px]  overflow-y-auto dark:text-white">
                        <section>图片描述：</section>
                        <section className="text-[rgba(255,255,255,0.80)] text-three-line">
                          {currentPrompt || "暂无描述"}
                        </section>
                      </section>
                    </section>
                  </section>
                  {task?.negativeTextPrompt ? (
                    <section className="mt-[10px] mx-auto w-full px-3 py-2 bg-white dark:bg-[#161623] dark:text-[#FFFFFF80] rounded overflow-hidden">
                      <section> 不需要元素：</section>
                      <section className="text-three-line">{task?.negativeTextPrompt}</section>
                    </section>
                  ) : null}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default AlbumImageDetail
