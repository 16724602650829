import { useState, useEffect, useMemo, useRef } from "react"

import { ReactComponent as CloseIcon } from "@/assets/close.svg"
import { ReactComponent as DownloadIcon2 } from "@/assets/download2.svg"
import { ReactComponent as ScoreIcon } from "@/assets/score2.svg"
import { toastError, toastInfo, toastSuccess } from "@/common/Toast"
import { ReactComponent as WorksLoadingIcon } from "@/assets/works-loading.svg"
import { ReactComponent as LockIcon } from "@/assets/lock.svg"
import { ReactComponent as CopyIcon } from "@/assets/copy2.svg"
import { ReactComponent as HeartWhiteIcon } from "@/assets/heart-white-2.svg"
import { ReactComponent as HeartRedIcon } from "@/assets/heart-red-2.svg"
import { ReactComponent as RightIcon } from "@/assets/right-black-inactive.svg"
// import { ReactComponent as RightIconActive } from "../assets/right-black-active.svg"
import "./index.less"
import { Modal, Tooltip } from "@arco-design/web-react"
import { CopyToClipboard } from "react-copy-to-clipboard"

import { getImageURL, computeWorkRatio, handleDownload, isPortraitWork } from "@/utils"
import { V1Task, V1Work } from "@/generated/api/data-contracts"
import { MODELS_MAP, OTHER_MODEL } from "@/constants"
import { runInAction } from "mobx"
import { observer } from "mobx-react-lite"
import { ReactComponent as MagicIcon } from "@/assets/magic2.svg"
import DefaultAvatarSvg from "@/assets/login_default_avatar.svg"
import dayjs from "dayjs"
import * as store from "@/store"
import PictureOption from "../PictureOption"
import GenerateAgainTask from "@/Studio/components/GenerateAgainTask"
import { Link, useNavigate } from "react-router-dom"
import { isMobileOnly } from "react-device-detect"
import { ErrorCode } from "@/i18next"
import { api } from "@/services/api"
import { t } from "i18next"
import { useTasksDispatch } from "@/pages/Works/WorksTab/WorksContext"
import { getUserPath } from "@/router/constants"
import AlbumSelect from "@/pages/Works/WorksTab/components/AlbumSelect"
export type TaskChangeDisableType = "prev" | "next" | "none" | "both"

const ImgDetailModal = observer(function ({
  open,
  onClose,
  task,
  work, // 初始化work，default work
  handleTaskChange,
  currentUser,
  taskChangeDisable = "none",
  likeOrUnlike,
  fromHomePage = false,
}: {
  likeOrUnlike?: (linked: boolean, id: string, workIndex: number, work: V1Work) => Promise<boolean>
  open: boolean
  onClose: () => void
  task: V1Task
  currentUser?: {
    avatar: string
    nick: string
    id: string
  }
  work: V1Work
  handleTaskChange?: (direction: "next" | "prev") => void
  taskChangeDisable?: TaskChangeDisableType
  fromHomePage?: boolean
}) {
  const worksWithChangedLiked = store.hasChangedLikedWorks.get()

  let works = task.works || []
  works = works.filter((w) => {
    return w && !w.illegal && w.image
  })

  const [selectedWorkIndex, setSelectedWorkIndex] = useState(0)
  const navigate = useNavigate()
  const current = works?.[selectedWorkIndex] || {}
  const currentSize = current?.size || work?.size
  const myTasksDispatch = useTasksDispatch()
  const modalRef = useRef(null)
  const showReCreate = useMemo(() => {
    return !!task.textPrompt
  }, [task])

  const worksLength = works?.length || 0
  const lastWorkIndex = worksLength - 1

  const [selectedTab] = useState("textPrompt")
  const currentPrompt =
    (selectedTab === "textPrompt" ? task.textPrompt : task.negativeTextPrompt) || ""

  const privatePrompt = !!task.privatePrompt
  const model = MODELS_MAP.get(task.model || "")

  const [loadedImgList, setLoadedImgList] = useState<string[]>()
  const [isAlbumSelectOpen, setIsAlbumSelectOpen] = useState(true)
  // const [currLike, setCurrLike] = useState<boolean>(work.liked!)

  const handleLike = async () => {
    const isSuccess = await likeOrUnlike?.(
      isLiked || false,
      current.id || "",
      selectedWorkIndex,
      current
    )
    if (isSuccess) {
      if (fromHomePage) {
        store.changeHasChangedLikedWorks(current.id!)
      }
    }
    // const { data } = await (current.liked
    //   ? api.unlikeWork(current.id || "")
    //   : api.likeWork(current.id || ""))
    // if (data.success) {
    //   toastSuccess("操作成功")
    // } else {
    //   toastError(t(`errorMessage:${data.errorCode as ErrorCode}`))
    // }
  }
  const linkToVisitor = (id: string) => {
    navigate(getUserPath(id))
  }
  const userInfo = useMemo(() => {
    return (task as V1Task)?.user ?? currentUser ?? {}
  }, [task, currentUser])

  const likedStatusChanged = useMemo(() => {
    if (!fromHomePage) {
      return false
    }

    return worksWithChangedLiked?.some((x: string) => current?.id == x)
  }, [worksWithChangedLiked, fromHomePage, current])

  const isLiked = likedStatusChanged ? !current?.liked : current?.liked

  const togglePrivacyStatus = async () => {
    const workId = work?.id
    if (!workId) {
      toastError("作品id不存在")
      return
    }

    const isPrivate = work?.isPrivate

    // Update the privacy status locally
    // myTasksDispatch({
    //   type: "UPDATE_WORK",
    //   payload: {
    //     ...work,
    //     isPrivate: !isPrivate,
    //   },
    // })

    // Update the privacy status on the server
    try {
      const { data } = await api.setWorkIsPrivate(workId ?? "", {
        isPrivate: !isPrivate,
      })
      if (data.success) {
        toastSuccess("切换成功")
      } else {
        toastError(t(`errorMessage:${data.errorCode as ErrorCode}`))
      }
    } catch (e) {
      toastError("操作失败")
    }
  }
  const handleClick = (event: React.MouseEvent) => {
    // console.log(modalRef.current, event.target, " console.log(modalRef)")
    // const targetElement = event.target
    if (modalRef.current == event.target) {
      onClose()
    }
  }
  useEffect(() => {
    let index = () => works?.findIndex((w) => w.id === work?.id) || 0
    setSelectedWorkIndex(index)
  }, [work])

  return (
    <Modal
      visible={open}
      footer={null}
      closeIcon={null}
      wrapClassName="img_detail_modal"
      focusLock
      // className="w-[980px]"
      maskStyle={{
        // background: "#f8f8f8f0",
        // background: "#020203",
        background: "rgba(10, 9, 9, 0.9)",
        backdropFilter: "blur(5px)",
      }}
      unmountOnExit={true}
      onCancel={() => onClose()}
      maskClosable
      style={{
        width: "auto",
        padding: "0",
        backgroundColor: "#161623",
      }}
      modalRender={(_) => (
        <div
          className="w-full h-[100vh] flex justify-center items-center overflow-hidden"
          onClick={handleClick}
          ref={modalRef}
          id="container"
        >
          <div className=" dark:bg-[#161623] rounded relative " id="modal">
            <div className="flex lg:flex-row  flex-col-reverse gap-[16px]">
              <section className="flex">
                <section className="flex items-center">
                  <div className="relative">
                    <section
                      className={`relative ${(() => {
                        // 横图和方图展示宽度为 600px
                        // 竖图展示高度为 660px
                        // 详细逻辑见：https://github.com/TiamatAI/xica-docs/issues/160#issuecomment-1483978692
                        // 新版v2: 更改为700px
                        if (!currentSize) return "md:w-[700px] md:min-h-[175px]"
                        const ratio = computeWorkRatio(currentSize)
                        if (isPortraitWork(currentSize)) {
                          return `md:w-[${700 * ratio}px] md:h-[700px]`
                        } else {
                          return `md:w-[700px] md:h-[${700 / ratio}px] ${
                            ratio == 1 ? "" : "ml-[20px]"
                          }`
                        }
                      })()}`}
                    >
                      <img
                        className={`${(() => {
                          if (!currentSize) return "w-full"
                          if (isPortraitWork(currentSize)) {
                            return "w-auto h-auto object-contain md:h-full"
                          } else {
                            return "w-full h-full"
                          }
                        })()} rounded`}
                        style={{
                          visibility:
                            current.image && loadedImgList?.includes(current.image)
                              ? "visible"
                              : "hidden",
                        }}
                        onLoad={(e) => {
                          current.image &&
                            setLoadedImgList([current.image].concat(loadedImgList || []))
                          // FIXME 接口清理脏数据后可以移除
                          // 接口返回的 size 和 ratio 可能与实际图片信息不一致，导致显示比例不正确
                          // https://github.com/TiamatAI/xica-docs/issues/235#issuecomment-1495304268
                          runInAction(() => {
                            const w = (e.target as HTMLImageElement).naturalWidth
                            const h = (e.target as HTMLImageElement).naturalHeight

                            current.size = `${w}x${h}`
                            // 这里ratio导致再次生成比例错误 + 代码暂未用到，所以注释了
                            // current.ratio = `1:${(h / w).toFixed(2)}`
                          })
                        }}
                        src={getImageURL(current.image, true)}
                        alt=""
                      />
                    </section>
                    <div
                      className="absolute z-10 top-0  bg-white right-0 h-full w-full flex flex-col justify-center items-center"
                      style={{
                        display:
                          current.image && loadedImgList?.includes(current.image) ? "none" : "flex",
                      }}
                    >
                      <WorksLoadingIcon />
                      <p className="text-xl leading-[31px] mt-[4px] text-[#000000]">作品加载中～</p>
                    </div>
                  </div>
                </section>
              </section>
              <div
                className={`${
                  isMobileOnly ? "" : "w-[520px]"
                } flex-shrink-0 dark:text-white p-[30px] flex flex-col justify-between`}
              >
                <div>
                  <div className="flex justify-between w-full h-[38px]">
                    <div className="flex items-end">
                      <span className="text-[44px] leading-[30px] mr-2 flex  font-[num]">
                        {current.score?.toFixed ? current.score?.toFixed(2) : "暂无"}
                      </span>
                      <ScoreIcon />
                    </div>
                    <div className="flex h-[36px]">
                      <AlbumSelect
                        work={current}
                        style={{ background: "rgba(255,255,255,0.05)" }}
                        onSelectVisibleChange={(visible) => setIsAlbumSelectOpen(visible)}
                      />
                      <div
                        className="home-modal-more flex items-end  text-white relative ml-[10px]"
                        id="PictureOptionCon"
                      >
                        <PictureOption
                          task={task as V1Task}
                          current={current}
                          showReCreate={showReCreate}
                          currLike={!!isLiked}
                          handleLike={handleLike}
                          iconStyleCls="w-[36px] h-[36px]"
                        />

                        {/* <Tooltip content={isLiked ? "取消收藏" : "收藏"}>
                      <button
                        type="button"
                        className="hover:bg-[#FFFFFF1A] transition rounded-sm"
                        onClick={handleLike}
                      >
                        {isLiked ? <HeartRedIcon /> : <HeartWhiteIcon />}
                      </button>
                    </Tooltip>
                    {showReCreate && (
                      <Tooltip content="再次生成">
                        <div>
                          <GenerateAgainTask
                            task={task}
                            buttonContent={
                              <div className="hover:bg-[#FFFFFF1A] transition rounded-sm">
                                <MagicIcon />
                              </div>
                            }
                          />
                        </div>
                      </Tooltip>
                    )}
                    <Tooltip content="下载图像">
                      <button
                        onClick={() => handleDownload(current.image!)}
                        className="hover:bg-[#FFFFFF1A] transition rounded-sm"
                      >
                        <DownloadIcon2 />
                      </button>
                    </Tooltip> */}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between items-center py-6 cursor-pointer">
                    <div
                      className="flex items-center justify-center"
                      onClick={() => {
                        linkToVisitor(userInfo?.id || "")
                      }}
                    >
                      <img
                        className="h-10 w-10 border border-[#FFFFFF33] rounded-full object-contain"
                        src={userInfo?.avatar ? userInfo.avatar : DefaultAvatarSvg}
                      />
                      <label className="pl-2 text-lg cursor-pointer">
                        {userInfo?.nick && userInfo.nick.length > 10
                          ? userInfo?.nick?.slice(0, 10) + "..."
                          : userInfo?.nick}
                      </label>
                    </div>
                    {/* </Link> */}
                  </div>
                  {privatePrompt ? (
                    <>
                      <section className="h-[178px] rounded px-2 pb-2 dark:bg-[#0C0C1A] overflow-hidden">
                        <section className="flex justify-between pt-[12px] px-[12px] h-[34px] bg-[#F5F8F9] dark:bg-[#0C0C1A]">
                          <span className="bg-[#f6f8f9] dark:bg-[#0C0C1A] font-bold text-[#101010] dark:text-white text-sm">
                            图片描述：
                          </span>
                          <Tooltip content="联系我们定制图像">
                            <section>
                              <LockIcon />
                            </section>
                          </Tooltip>
                        </section>
                        <section className="h-[130px] relative overflow-hidden">
                          <section className="flex justify-center items-center absolute left-0 right-0 top-0 bottom-0 w-full h-full backdrop-blur-sm bg-[#F3F6F8CC]/80 dark:bg-[#000000CC] text-[#6F7176] dark:text-white text-sm rounded leading-5 text-center">
                            本图像由内测平台生成
                            <br />
                            如有需要，请联系我们
                          </section>
                          <section className="p-4">
                            本图像由内测平台生成，如有需要，请联系我们。本图像由内测平台生成，如有需要，请联系我们。
                          </section>
                        </section>
                      </section>
                    </>
                  ) : (
                    <>
                      <section className="mt-[10px] bg-white dark:bg-[#0C0C1A] px-3 py-2 rounded flex gap-[20px]">
                        <section>
                          <section className="text-sm text-[#FFFFFF80] leading-[20px]">
                            <span className="text-xs  leading-[20px]">模型</span>
                            {/* {!model ? <LockIcon width={22} height={14} /> : null} */}
                          </section>
                          <section className="lg:mt-[4px] text-sm text-[#101010] dark:text-white leading-[20px]">
                            {/* {!model ? <UnknownModelIcon /> : <DarkModelIcon />} */}
                            <span className="text-sm  leading-[20px]">
                              {model?.name || OTHER_MODEL}
                            </span>
                          </section>
                        </section>
                        <section>
                          <section className="text-xs text-[#FFFFFF80] leading-[20px]">
                            尺寸
                          </section>
                          <section className="lg:mt-[4px] text-sm text-[#101010] dark:text-white leading-[20px]">
                            {currentSize || "-"}
                          </section>
                        </section>
                        <section>
                          <section className="text-xs text-[#FFFFFF80] leading-[20px]">
                            日期
                          </section>
                          <section className="lg:mt-[4px] text-sm text-[#101010] dark:text-white leading-[20px]">
                            {dayjs((task as V1Task)?.createdAt ?? "").format("YYYY.MM.DD")}
                          </section>
                        </section>
                        <section>
                          {current.seed && current.seed !== -1 && current.seed !== 0 ? (
                            <>
                              <section className="text-xs text-[#FFFFFF80] leading-[20px]">
                                编号
                              </section>
                              <section className="lg:mt-[4px] text-sm text-[#101010] dark:text-white leading-[20px]">
                                {current.seed}
                              </section>
                            </>
                          ) : null}
                        </section>
                      </section>
                      <section className="mt-[12px] lg:h-max-[254px] overflow-hidden ">
                        <section
                          aria-disabled={privatePrompt}
                          className="relative pt-[12px] pb-[48px] lg:h-max-[224px] rounded-b text-sm bg-[#f6f8f9] dark:bg-[#0C0C1A] rounded aria-disabled:backdrop-blur-sm"
                        >
                          <section className=" px-[12px] overflow-y-auto dark:text-white">
                            <section className="w-full justify-start flex">图片描述：</section>
                            <div className="text-[#FFFFFFCC] whitespace-pre-wrap overflow-y-scroll flex justify-start w-full max-h-[224px] text-left">
                              {currentPrompt || "暂无描述"}
                            </div>
                          </section>
                        </section>
                      </section>
                      {task.negativeTextPrompt ? (
                        <section className="mt-[10px] mx-auto w-full px-3 py-2 bg-white dark:bg-[#0C0C1A] dark:text-[#FFFFFF80] rounded overflow-hidden">
                          <section> 不需要元素：</section>
                          <section className="text-three-line">{task.negativeTextPrompt}</section>
                        </section>
                      ) : null}
                    </>
                  )}{" "}
                  {/* 查看大图小头像部分 */}
                  {/* 最多展示四个小图宽度为 212px = (50px x 4) + (4px x 3) */}
                  {works?.length! > 1 && (
                    <section className="mt-[10px] mx-auto w-full px-3 py-2 bg-white dark:bg-[#0C0C1A] rounded overflow-hidden">
                      <section
                        className="flex gap-[12px] transition-transform transform-gpu"
                        style={{
                          transform: `translateX(${(() => {
                            // 超过 4 图时的偏移逻辑： https://github.com/TiamatAI/xica-docs/issues/91#issuecomment-1481324991
                            if (worksLength <= 4) return 0
                            /** 一个缩略图的宽度加上间隙宽度 */
                            const d = 54
                            /** 选中最后两张缩略图的偏移量 */
                            if (lastWorkIndex - selectedWorkIndex < 2)
                              return -((worksLength - 4) * d)
                            /** 选中超过第二张缩略图的偏移量 */
                            if (selectedWorkIndex > 1) return -((selectedWorkIndex - 1) * d)
                            return 0
                          })()}px)`,
                        }}
                      >
                        {works?.map((work, i) => (
                          <button
                            className={`flex-shrink-0 bg-black group space-x-3 rounded
                    ${selectedWorkIndex === i ? "border-main-color" : ""}
                  `}
                            key={work.id}
                            aria-checked={selectedWorkIndex === i}
                            onClick={() => {
                              setSelectedWorkIndex(i)
                            }}
                          >
                            <img
                              className="h-[100px] w-[100px] object-cover rounded opacity-40 group-aria-checked:opacity-100"
                              src={getImageURL(work.image, true)}
                            />
                          </button>
                        ))}
                      </section>
                    </section>
                  )}
                </div>
                {/* <div className="bg-[#0C0C1A] rounded-[4px] p-[15px]">
                  <div className="mb-[10px] w-full flex justify-start">相关相册：</div>
                  <div className="w-full">
                    <div className="rounded-[4px] p-[4px] flex w-[174px] bg-[#2E2E4A]">
                      <img className="rounded-[4px] w-[50px] h-[50px] object-cover"></img>
                      <div className="flex-1 ml-[10px] mr-[9px] flex  items-center">
                        二次元帅哥合辑
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="w-[460px] flex gap-[20px] mt-[40px]">
                  <CopyToClipboard
                    text={currentPrompt}
                    onCopy={() => {
                      toastInfo("已复制")
                    }}
                  >
                    <button className="py-[3px] px-[2px] flex flex-1 justify-center items-center bg-transparent hover:bg-[#ffffff1a] rounded border-[1px] border-[gray] cursor-pointer">
                      <CopyIcon />
                      复制描述词
                    </button>
                  </CopyToClipboard>
                  <div className="flex-1">
                    <GenerateAgainTask
                      task={task}
                      buttonContent={
                        <div className="bg-[#4C1FFF] py-[3px] px-[2px] rounded-[4px] text-[14px] flex-1 flex justify-center items-center h-full cursor-pointer">
                          <MagicIcon />
                          立即生成
                        </div>
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            {taskChangeDisable === "prev" || taskChangeDisable === "both" ? null : (
              <button
                className="lg:flex hidden absolute -left-20 bottom-1/2  translate-y-4 mr-[8px] flex-shrink-0 w-[38px] h-[38px] justify-center items-center hover:bg-[#FFFFFF1A] rounded"
                onClick={() => {
                  handleTaskChange && handleTaskChange("prev")
                }}
                // disabled={taskChangeDisable == "prev"}
              >
                <RightIcon className="rotate-180 " />
              </button>
            )}
            {taskChangeDisable === "next" || taskChangeDisable === "both" ? null : (
              <button
                className="lg:flex hidden absolute -right-20 bottom-1/2 translate-y-4 ml-[8px] flex-shrink-0 w-[38px] h-[38px] justify-center items-center hover:bg-[#FFFFFF1A] rounded"
                onClick={() => {
                  handleTaskChange && handleTaskChange("next")
                }}
                // disabled={taskChangeDisable == "next"}
              >
                <RightIcon />
              </button>
            )}
          </div>
          <button
            className="absolute right-[20px] top-[20px] hover:brightness-125"
            onClick={onClose}
          >
            <CloseIcon />
          </button>
        </div>
      )}
    ></Modal>
  )
})

export default ImgDetailModal
