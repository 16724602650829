import { useEffect, useState } from "react"
import { ReactComponent as CloseIcon } from "@/assets/creation/close.svg"
import { ReactComponent as ArrowLeft } from "@/assets/profileEditor/left-arrow.svg"
import { toastSuccess } from "@/common/Toast"
import { Modal } from "@arco-design/web-react"
import { observer } from "mobx-react-lite"
import { isMobileOnly } from "react-device-detect"
import shopModalStore from "@/store/shopModalStore"
import {
  getSubscriptionList,
  getListCoinRedeems,
  getUserCoinInfo,
  getRedeemCoin,
} from "@/services/subscription"
import { SubscribePay } from "@/components/bussiness/subscription/pay"
import { V1Bonus, V1ProductInfo, V1CoinRedeemInfo } from "@/generated/api/data-contracts"
import ShopProductList from "./ShopProductList"
import ShopConversionList from "./ShopConversionList"
import * as store from "@/store"
import "./index.less"

interface ShopConversionData {
  hasSuccessPaidOrder?: boolean
  redeemBonus?: V1CoinRedeemInfo[]
}

interface ShopProductData {
  [subscribeType: number]: V1ProductInfo
}

interface LoadObj {
  [key: number]: boolean
}

type ShopType = "product" | "conversion"

const ShopModal = ({ id: userId }: { id?: string }) => {
  const { shopModalIsOpen } = shopModalStore.store.get()

  const [shopProductData, setShopProductData] = useState<ShopProductData>({})
  const [shopConversionData, setShopConversionData] = useState<ShopConversionData>({})
  const [showPayModal, setShowPayModal] = useState(false)
  const [proudctDetail, setProudctDetail] = useState<V1ProductInfo>({})
  const [shopContentType, setShopContentType] = useState<ShopType>("product")

  const [loadObj, setLoadObj] = useState<LoadObj>({})

  useEffect(() => {
    getProductData()
    getConversionData()
  }, [])

  // 查询产品
  const getProductData = async () => {
    const res = await getSubscriptionList()
    const newShopProductData: any = {}
    res?.map((item) => {
      newShopProductData[item.subscribeType || 0] = item
    })
    setShopProductData(newShopProductData)
  }

  // 查询兑换权益 (登录 ｜ 未登录)
  const getConversionData = async () => {
    let data
    if (userId) {
      const res = await getUserCoinInfo()
      data = res || {}
    } else {
      const res = await getListCoinRedeems()
      data = {
        redeemBonus: res?.map((item) => ({
          redeemInfo: item,
        })),
      }
    }

    setShopConversionData(data)
  }

  // 使用灵石兑换权益
  const getConversion = async (id: number) => {
    setLoadObj((prevLoad) => {
      const newLoad = { ...prevLoad }
      newLoad[id] = true
      return { ...newLoad }
    })

    const res = await getRedeemCoin(String(id))

    if (res?.success) {
      toastSuccess("兑换成功")
      getConversionData()
      store.refreshUser()
      setLoadObj((prevLoad) => {
        const updatedLoad = { ...prevLoad }
        updatedLoad[id] = false
        return { ...updatedLoad }
      })
    }
  }

  const handleProudctDetail = (v: V1ProductInfo) => {
    setProudctDetail(v)
  }

  const onChange = (v: ShopType) => {
    setShopContentType(v)
  }

  const shopContentDom = {
    product: (
      <ShopProductList
        userId={userId}
        shopProductData={shopProductData}
        openPayModal={setShowPayModal}
        cb={handleProudctDetail}
        hasSuccessPaidOrder={shopConversionData?.hasSuccessPaidOrder}
      />
    ),
    conversion: (
      <ShopConversionList
        userId={userId}
        loadObj={loadObj}
        shopConversionListData={shopConversionData?.redeemBonus || []}
        cb={(id) => {
          getConversion(id)
        }}
      />
    ),
  }
  return (
    <>
      <Modal
        visible={shopModalIsOpen}
        footer={null}
        closeIcon={
          <button
            className="md:absolute md:right-[-10px] md:top-[-8px] hover:brightness-125"
            onClick={() => shopModalStore?.closeShopModal()}
          >
            <CloseIcon className="hidden md:block" />
          </button>
        }
        className={isMobileOnly ? "shop-modal" : ""}
        maskStyle={{
          background: "rgba(10, 9, 9, 0.9)",
          backdropFilter: "blur(5px)",
        }}
        style={{
          width: isMobileOnly ? "100%" : "auto",
          height: isMobileOnly ? "100%" : "750px",
          backgroundColor: "#212134",
        }}
      >
        <div className="bg-white dark:bg-[#212134] h-auto  rounded relative text-white md:w-[860px]  md:px-[20px]  md:pb-[20px]">
          {isMobileOnly ? (
            <div>
              <div className="bg-[#0C0C1A] w-full py-5 pl-4 pr-5 flex items-center">
                <ArrowLeft
                  className="w-6 h-6  cursor-pointer"
                  onClick={() => shopModalStore?.closeShopModal()}
                />
                <span className="ml-1 text-base">Tiamat商城</span>
              </div>

              <div className="relative md:max-w-7xl w-[100%] md:w-auto mx-auto px-[20px] sm:px-6 md:px-8 flex mt-8 mb-4 flex-col ">
                <div className="p-2 bg-[#0C0C1A] rounded-[6px] flex w-[100%] md:w-auto">
                  <div
                    onClick={() => onChange("product")}
                    className={`${
                      shopContentType === "product" ? "text-inherit bg-[#212134] shadow" : ""
                    } hover:cursor-pointer md:px-20 md:py-3 py-[8px] text-base text-[#6F7176] rounded flex-1 text-center`}
                  >
                    购买水晶
                  </div>
                  <div
                    onClick={() => onChange("conversion")}
                    className={`${
                      shopContentType === "conversion" ? "text-inherit bg-[#212134] shadow" : ""
                    } hover:cursor-pointer md:px-20 md:py-3 py-[8px] text-base text-[#6F7176] rounded flex-1 text-center`}
                  >
                    兑换商品
                  </div>
                </div>
              </div>

              <div className="px-5 pb-5">{shopContentDom[shopContentType]}</div>
            </div>
          ) : (
            <>
              <div className="flex flex-row justify-center md:justify-start mb-[20px]">
                <span className="text-[32px] md:text-2xl ">Tiamat商城</span>
              </div>
              <div>
                <div className="bg-[#161623] py-2 pl-3 rounded mb-5">购买灵石</div>
                {shopContentDom["product"]}

                <div className="bg-[#161623] py-2 pl-3 rounded mt-10 mb-5 ">兑换商品</div>
                {shopContentDom["conversion"]}
              </div>
            </>
          )}
        </div>
      </Modal>
      {showPayModal && (
        <SubscribePay
          productId={proudctDetail?.id || 0}
          type={"none"}
          onCancel={() => setShowPayModal(false)}
          cb={getConversionData}
          title={proudctDetail?.title}
        />
      )}
    </>
  )
}

export default observer(ShopModal)
