import { useState } from "react"
import { Password } from "@/components/bussiness/Login/password"
import { Qrcode } from "@/components/bussiness/Login/qrcode"
import bg from "@/assets/login_bg.jpg"
import { ReactComponent as LogoIcon } from "@/assets/logo.svg"
import { isMobileOnly } from "react-device-detect"
import { getRecordNumberByDomain } from "@/constants"
const LoginPage = () => {
  const [isQrPage, setIsQrPage] = useState(false)
  //  按钮状态
  const [currentBtn, setCurrentBtn] = useState<"register" | "login" | "">("")
  const toggle = () => {
    setIsQrPage(!isQrPage)
  }
  return (
    <div className="fixed inset-0">
      <div
        className="absolute inset-0 bg-blend-multiply bg-cover bg-no-repeat -z-10"
        style={{
          background: `url(${bg}) rgba(0,0,0,0.5) center center`,
          backgroundSize: "cover",
        }}
      ></div>
      {/*  移动端注册和登录页面的遮罩层 */}
      {isMobileOnly && currentBtn && (
        <div className="fixed w-[100%] h-[100%] bg-[rgba(0,0,0,0.5)]"></div>
      )}
      <div className="max-w-7xl mx-auto md:overflow-y-auto h-full md:px-8 flex md:flex-row flex-col md:items-center md:justify-center">
        <div className="md:flex-1 ml-2 mr-4 md:mt-0 mt-20 md:mb-0 mb-10 flex md:block flex-col justify-center items-center fill-black">
          <LogoIcon width={220} height={42} />
          {/* <h1 className="text-[3.25rem] text-white font-medium">
            Hi,欢迎回来
            尊贵的企业魔法师
          </h1> */}
          <h2 className="mt-7 text-[18px] font-semibold text-white flex items-center">
            <span>Spark Your Imagination</span>
          </h2>
        </div>

        {/* 登录框 */}
        {isQrPage ? (
          <Qrcode onClickToggle={toggle} />
        ) : // 移动端下先展示注册登录按钮 currentBtn 有三种状态
        isMobileOnly ? (
          currentBtn ? (
            <Password
              onClickToggle={toggle}
              pageStatu={currentBtn}
              changeStatu={(statu) => {
                setCurrentBtn(statu)
              }}
            />
          ) : (
            <div className="flex flex-col absolute bottom-[100px] px-[42.5px] w-full">
              <button
                className="bg-[#6663FF] flex rounded-[4px] text-2xl text-white justify-center py-[0.5rem] mb-[0.625rem]"
                onClick={() => {
                  setCurrentBtn("register")
                }}
              >
                注册
              </button>
              <button
                className="bg-[#4C1FFF] flex rounded-[4px] text-2xl text-white justify-center py-[0.5rem]"
                onClick={() => {
                  setCurrentBtn("login")
                }}
              >
                登录
              </button>
            </div>
          )
        ) : (
          <Password onClickToggle={toggle} />
        )}
      </div>
      {/* pc 端显示备案号 */}
      {!isMobileOnly && (
        <div className="absolute bottom-0 w-full flex justify-center text-xs text-white mt-10 mb-2 items-center cursor-pointer">
          <a href="https://beian.miit.gov.cn/#/Integrated/index">{getRecordNumberByDomain()}</a>
          <a
            className="flex  justify-center items-center"
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502020083"
          >
            <img className="pl-2 " src="/assets/beian.png" />
            <p className="pl-2 ">沪公网安备 31011502020083号</p>
          </a>
        </div>
      )}
    </div>
  )
}
export default LoginPage
