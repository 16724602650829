import { useEffect, useRef, useState } from "react"
import NormalInput from "./NormalInput.js"
import TemplatePromptInput from "./TemplatePromptInput.tsx"

const InputTip = ({ intro }: { intro?: string }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [moreButtonVisible, setMoreButtonVisible] = useState(false)
  const textElement = useRef<HTMLDivElement>(null)

  // Check if it is necessary to show the "More" button
  useEffect(() => {
    const textElementWidth = textElement.current?.clientWidth
    const textElementScrollWidth = textElement.current?.scrollWidth
    const isOverflow =
      textElementWidth && textElementScrollWidth && textElementScrollWidth > textElementWidth
    setMoreButtonVisible(!!isOverflow)
  }, [textElement])

  const config = {
    expanded: {
      truncateClass: "",
      moreButtonText: "收起",
    },
    collapsed: {
      truncateClass: "truncate",
      moreButtonText: "更多",
    },
  }
  const activeConfig = isExpanded ? config.expanded : config.collapsed

  return (
    <div className="w-full mt-[10px] px-[10px] pb-[10px] flex items-end text-xs">
      {/* Intro */}
      <div
        ref={textElement}
        className={`${activeConfig.truncateClass} flex-1 min-w-0 text-white text-opacity-20`}
      >
        {intro || "当前模板暂无使用技巧"}
      </div>

      {/* More Button */}
      {moreButtonVisible && (
        <div
          className="text-[#6562FF] cursor-pointer hover:brightness-110 ml-1"
          onClick={() => {
            setIsExpanded(!isExpanded)
          }}
        >
          {activeConfig.moreButtonText}
        </div>
      )}
    </div>
  )
}

interface InputElementProps {
  usingTemplatePromptInput: boolean
  value: string
  onChange?: (text: string) => void
  templatePrompt?: string
  placeholder?: string
  templateIntro?: string
}

const InputElement = ({
  usingTemplatePromptInput,
  value,
  onChange,
  templatePrompt,
  placeholder,
  templateIntro,
}: InputElementProps) => {
  const inputTipVisible = templatePrompt !== undefined

  return (
    <div className="flex flex-col bg-[#0C0C1A]">
      {/* Input */}
      <div className="h-[120px] overflow-y-auto md:h-[100px]">
        {usingTemplatePromptInput ? (
          <TemplatePromptInput
            prompt={templatePrompt}
            onChange={(val) => {
              onChange?.(val)
            }}
          />
        ) : (
          <NormalInput
            value={value}
            onChange={(val) => {
              onChange?.(val)
            }}
            placeholder={placeholder}
          />
        )}
      </div>

      {/* Tip */}
      {inputTipVisible && <InputTip intro={templateIntro} />}
    </div>
  )
}

export default InputElement
