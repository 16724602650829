import { ReactComponent as RemoveIcon } from "@/assets/studio/remove.svg"
import { getImageURL } from "@/utils"
import { ReactComponent as EditorIcon } from "@/assets/editor.svg"
interface ReferenceImageProps {
  imageSrc?: string
  onRemove?: () => void
  onImageClick?: (isEditor: boolean) => void
}
const ReferenceImage = ({ imageSrc, onImageClick, onRemove }: ReferenceImageProps) => {
  return (
    <div className="w-[340px] max-w-full h-[190px] rounded bg-black relative">
      <img className="w-full h-full object-contain" src={getImageURL(imageSrc || "")} alt="" />
      <div className="absolute right-[6px] bottom-[6px] flex z-10 gap-[10px]">
        <div
          className="flex items-center cursor-pointer  bg-black bg-opacity-50 rounded px-2 py-1 hover:bg-opacity-70"
          onClick={() => {
            onImageClick?.(true)
          }}
        >
          <EditorIcon className="mr-[5px]" />
          <div className="text-[14px] leading-5 text-white opacity-80">再次编辑</div>
        </div>
        <div
          className="flex items-center cursor-pointer  bg-black bg-opacity-50 rounded px-2 py-1 hover:bg-opacity-70"
          onClick={() => {
            onRemove?.()
          }}
        >
          <RemoveIcon className="mr-[5px]" />
          <div className="text-[14px] leading-5 text-white opacity-80">移除参考图</div>
        </div>
      </div>
    </div>
  )
}

export default ReferenceImage
