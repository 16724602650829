import Navbar from "@/Navbar"
import { useDarkMode } from "@/utils"
import { Input, Menu, Tooltip } from "@arco-design/web-react"
import { IconLeft, IconRight } from "@arco-design/web-react/icon"
import { useNavigate } from "react-router-dom"
import { ReactComponent as MenuIcon } from "@/assets/menu.svg"
const MenuItem = Menu.Item
const SubMenu = Menu.SubMenu
type MenuListType = {
  label: string
  key: string
  markdownPath?: string
  link?: boolean
  children?: MenuListType[]
}
import ReactDOMServer from "react-dom/server"
import { marked, Renderer } from "marked"
import { useEffect, useRef, useState } from "react"
import "./index.less"
import React from "react"
import { useSearchParams } from "react-router-dom"
import LayoutGeneral from "@/components/layout/general"
import MobileHeaderMenu from "@/components/layout/temp/MobileHeaderMenu"
import { MOBILE_CONTAINER_CLS } from "@/store/menuStore"
import { isMobileOnly } from "react-device-detect"

// 特殊字符转义
const unescape = (unescapedString: string) => {
  const entities: { [_: string]: string } = {
    "&amp;": "&",
    "&lt;": "<",
    "&gt;": ">",
    "&quot;": '"',
    "&#039;": "'",
  }

  for (const key in entities) {
    const regex = new RegExp(key, "g")
    unescapedString = unescapedString.replace(regex, entities[key])
  }
  return unescapedString
}

//获取markdown 传递的data
const getImageData = (text: string) => {
  const loadData: { [_: string]: string } = {
    type: "image",
  }
  try {
    unescape(text)
      .split("&")
      .map((i) => {
        const [key, val] = i.split("=")
        loadData[key] = val
      })
  } catch (error) {
    loadData["title"] = text
  }
  return loadData
}

// 模型组件需要的图片组件
const ModalImage = ({ src, title }: { src: string; title: string }) => {
  return (
    <div className="group md:w-[250px] md:h-[250px] 2xl:w-[300px] 2xl:h-[300px]  mb-6 relative">
      <img src={src} className="md:w-full h-full object-cover rounded-md w-[250px]" />
      <div className="md:w-[238px] 2xl:w-[288px] cursor-pointer group-hover:block hidden absolute bottom-0 bg-[#0c0c1ae5] rounded m-[6px] p-2  text-[#ffffff80] z-10">
        {title}
      </div>
    </div>
  )
}
const renderer: Renderer = new marked.Renderer()
renderer.image = (href, title, text) => {
  const imageData = getImageData(text)
  const src = href?.replace(/^\.\//g, "/assets/user-guide//") || ""
  if (imageData["type"] === "modalImage") {
    return ReactDOMServer.renderToString(<ModalImage src={src} title={imageData["title"]} />)
  }
  if (imageData["type"] === "icon") {
    return ReactDOMServer.renderToString(
      <div className="border-[2px] border-gray-500 rounded-[4px] p-[20px] w-full flex items-center gap-[10px]">
        <img src={src} className="w-[36px] h-[36px]" />
        <span className="text-[18px]">{imageData["title"]}</span>
      </div>
    )
  }
  const imageElement = React.createElement("img", {
    src,
    alt: imageData["title"],
    title,
    className: `img`,
    style: {
      height: isMobileOnly ? "auto" : imageData["height"] || "auto",
      width: imageData["width"] || "auto",
    },
  })
  return ReactDOMServer.renderToString(imageElement)
}

// 左侧菜单临时配置，后期改到接口处
const menuConfig: MenuListType[] = [
  {
    label: "使用入门",
    key: "Introduction",
    children: [
      {
        label: "快速开始",
        key: "quick-start",
        markdownPath: "/assets/user-guide/quick-start.md",
      },
      {
        label: "社区准则",
        key: "community-rule",
        markdownPath: "/assets/user-guide/community-rule.md",
      },
      {
        label: "描述指南",
        key: "description-guide",
        markdownPath: "/assets/user-guide/description-guide.md",
      },
    ],
  },
  {
    label: "用户指南",
    key: "用户指南",
    children: [
      {
        label: "模型版本",
        key: "model-version",
        markdownPath: "/assets/user-guide/model-version.md",
      },
      {
        label: "图像比例",
        key: "image-proportion",
        markdownPath: "/assets/user-guide/image-proportion.md",
      },
      {
        label: "参考图使用",
        key: "reference-image",
        markdownPath: "/assets/user-guide/reference-image.md",
      },
      {
        label: "ControlNet",
        key: "controlnet",
        markdownPath: "/assets/user-guide/controlnet.md",
      },
    ],
  },
  {
    label: "订阅方案",
    key: "subscription",
    children: [
      {
        label: "订阅计划",
        key: "subscription-plan",
        markdownPath: "/assets/user-guide/subscription-plan.md",
      },
      {
        label: "作品默认不公开",
        key: "privacy-mode",
        markdownPath: "/assets/user-guide/privacy-mode.md",
      },
      {
        label: "快速模式和标准模式",
        key: "fast-slow-mode",
        markdownPath: "/assets/user-guide/fast-slow-mode.md",
      },
    ],
  },
  {
    label: "服务条款",
    key: "服务条款",
    children: [
      {
        label: "服务条款",
        key: "terms-service",
        link: true,
        markdownPath: "/assets/user-guide/terms-service.md",
      },
      {
        label: "隐私协议",
        key: "privacy-policy",
        link: true,
        markdownPath: "/assets/user-guide/privacy-policy.md",
      },
      {
        label: "灵石充值协议",
        key: "spirit-stone-policy",
        link: true,
        markdownPath: "/assets/user-guide/spirit-stone-policy.md",
      },
      {
        label: "会员服务协议",
        key: "vip-policy",
        link: true,
        markdownPath: "/assets/user-guide/vip-policy.md",
      },
    ],
  },
  {
    label: "关于我们",
    key: "关于我们",
    children: [
      {
        label: "品牌介绍",
        key: "brand-introduction",
        link: true,
        markdownPath: "/assets/user-guide/brand-introduction.md",
      },
      {
        label: "联系方式",
        key: "contact-information ",
        link: true,
        markdownPath: "/assets/user-guide/contact-information.md",
      },
    ],
  },
]

interface AgreementObject {
  [key: string]: string
}

const agreementObj: AgreementObject = {
  "terms-service":
    "https://cos-xica-prod.tiamat.world/assets/Tiamat%E7%94%A8%E6%88%B7%E5%8D%8F%E8%AE%AE.pdf",
  "privacy-policy":
    "https://cos-xica-prod.tiamat.world/assets/Tiamat%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96-20240123-V1.pdf",
  "spirit-stone-policy":
    "https://cos-xica-prod.tiamat.world/assets/Tiamat%E7%81%B5%E7%9F%B3%E5%85%85%E5%80%BC%E5%8D%8F%E8%AE%AE-20231120-V1.pdf",
  "vip-policy":
    "https://cos-xica-prod.tiamat.world/assets/Tiamat%E4%BC%9A%E5%91%98%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE-20231120-V1.pdf",
}

interface SecondMenus {
  label?: string
  key?: string
  index?: number
}
const UserGuide = () => {
  useDarkMode()
  const [menuList, setMenuList] = useState<MenuListType[]>([])
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedKeys, setSelectedKeys] = useState<string[]>([])
  const [openKeys, setOpenKeys] = useState<string[]>([])
  const [search, setSearch] = useState("")
  const [index, setIndex] = useState(-1)
  const [html, setHtml] = useState("")
  const navigate = useNavigate()
  // 移动端使用
  const [currentTab, setCurrentTab] = useState("Introduction")
  useEffect(() => {
    getMarkdownFile()
  }, [index])

  const getMarkdownFile = (i = index) => {
    const path = menuList[i]?.markdownPath
    if (!path) {
      return
    }

    fetch(path)
      .then(function (response) {
        return response.text()
      })
      .then(function (data) {
        setHtml(marked(data, { renderer }))
      })
  }

  const getMenuList = (list: MenuListType[]) => {
    const l: MenuListType[] = []
    list.map((item) => {
      if (item.children && item.children.length) {
        l.push(...getMenuList(item.children))
        return
      }
      return l.push(item)
    })
    return l
  }

  useEffect(() => {
    const key = searchParams.get("key")
    const newMenuList = getMenuList(menuConfig)
    let newIndex = newMenuList.findIndex((item) => item.key === key)
    newIndex = newIndex > 0 ? newIndex : 0
    setIndex(newIndex)
    setMenuList(newMenuList)
    setSelectedKeys([newMenuList[newIndex].key])
    setCurrentTab("Introduction")
  }, [])

  const gitOpenkeys = (key: string, search?: string) => {
    const newOpenKeys: string[] = []
    const hasOpen = (children?: MenuListType[]) => {
      if (!children) {
        return false
      }
      const index = children?.findIndex((i) => {
        return i.key === key || (search && i.label.includes(search))
      })

      return index >= 0
    }
    menuConfig.forEach((item) => {
      const has = hasOpen(item.children)
      if (has) {
        newOpenKeys.push(item.key)
      }
    })

    return newOpenKeys
  }
  const getParentKey = (key: string) => {
    let parentKey = ""
    menuConfig.forEach((item) => {
      if (item.children) {
        const activeChild = item.children.find((i) => i.key === key)
        if (activeChild) {
          parentKey = item.key
        }
      }
    })
    return parentKey
  }
  const getParentChildren = (key: string) => {
    let parentChildren: MenuListType[] = []
    menuConfig.forEach((item) => {
      if (item.children) {
        const activeChild = item.children.find((i) => i.key === key)
        if (activeChild) {
          parentChildren = item.children
        }
      }
    })
    return parentChildren
  }

  const renderMenuItem = (item: MenuListType) => {
    if (item.children && item.children.length) {
      return (
        <SubMenu key={item.key} title={item.label}>
          {item.children.map((item) => renderMenuItem(item))}
        </SubMenu>
      )
    }

    const labelHtml = item.label.replace(
      new RegExp(`${search}`, "g"),
      `<span class="action-text">${search}</span>`
    )
    return (
      <MenuItem key={item.key} className={search && item.label.includes(search) ? "action" : ""}>
        <span dangerouslySetInnerHTML={{ __html: labelHtml }} />
      </MenuItem>
    )
  }

  return (
    <LayoutGeneral>
      <Navbar />
      <div
        className={`${MOBILE_CONTAINER_CLS} h-[100vh] md:pt-[80px] pb-[10px] w-full bg-[#161623]`}
      >
        <MobileHeaderMenu
          // 头部菜单
          extra={
            <div className="flex gap-[5px]">
              {menuConfig.map((item: any, index) => (
                <div
                  className="flex flex-col items-center"
                  onClick={() => {
                    setCurrentTab(item?.key)
                    const list =
                      item?.children?.map((child: MenuListType) => ({
                        key: child?.key,
                        label: child?.label,
                      })) || []
                    //  跳转
                    navigate(`/user-guide/?key=${item?.children[0]?.key}`, { replace: true }) // 替换当前历史记录条目
                    // 为了获取菜单索引(中间正文内容是通过索引对应的 md 文件)
                    const newIndex = menuList.findIndex(
                      (menu) => menu.key === item?.children[0]?.key
                    )
                    setIndex(newIndex)
                  }}
                >
                  <div className={currentTab === item.key ? "text-white" : "text-gray-500"}>
                    {item?.label}
                  </div>
                  <div
                    className={`w-[40px] h-[4px] bg-[#6663FF] mt-[5px] ${
                      currentTab === item.key ? "" : "hidden"
                    }`}
                  ></div>
                </div>
              ))}
            </div>
          }
        />
        <div className=" pt-3 md:pt-0 max-w-[1920px] h-full mx-auto px-[0px] sm:px-6 md:px-8">
          <div className="w-full h-full flex">
            <div className="lg:w-[280px] w-40 flex-shrink-0 pr-[10px] overflow-y-auto h-full border-r border-[#2a2a36] hidden md:block">
              <div className="search-input">
                <Input
                  placeholder="搜索内容"
                  allowClear
                  onChange={(value: string) => {
                    setOpenKeys(gitOpenkeys("", value))
                    setSearch(value)
                  }}
                />
              </div>
              <Menu
                autoOpen={true}
                className="user-guide-menu"
                selectedKeys={selectedKeys}
                openKeys={openKeys}
                onClickSubMenu={(_, openKeys) => {
                  setOpenKeys(openKeys)
                }}
                onClickMenuItem={(_1, _, keyPath) => {
                  // 如果是服务条款，需要新窗口打开pdf

                  if (agreementObj[_1]) {
                    window.open(agreementObj[_1])
                    return
                  }

                  // if (_1 == "terms-service") {
                  //   window.open(
                  //     "https://cos-xica-prod.tiamat.world/assets/Tiamat%E7%94%A8%E6%88%B7%E5%8D%8F%E8%AE%AE-20230821-V3.pdf"
                  //   )
                  //   return
                  // } else if (_1 == "privacy-policy") {
                  //   window.open(
                  //     "https://cos-xica-prod.tiamat.world/assets/Tiamat%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96-20230821-V3.pdf"
                  //   )
                  //   return
                  // }
                  const [key] = keyPath
                  setSearchParams({ key })
                  setSelectedKeys(keyPath)
                  const newIndex = menuList.findIndex((item) => item.key === key)
                  if (newIndex > 0) {
                    setIndex(newIndex)
                  } else {
                    setIndex(0)
                  }
                }}
              >
                {menuConfig.map((item) => renderMenuItem(item))}
              </Menu>
            </div>
            <div className="w-full h-full md:pl-[10px] relative">
              <div className="h-full overflow-y-auto pb-[100px]">
                <div
                  className="border-[#2a2a36]"
                  id="mark-down"
                  //  移动端
                  style={{ paddingRight: isMobileOnly ? "15px" : "" }}
                >
                  <div dangerouslySetInnerHTML={{ __html: html }} />
                </div>
              </div>
              <div className="h-[60px] pt-3 w-full border-t border-[#2a2a36] bg-[#161623] fixed md:absolute bottom-0 flex justify-between items-center">
                <div
                  className={`${
                    index === 0 ? "text-[#ababab] cursor-no-drop md:hidden" : ""
                  } h-full md:w-[120px] flex justify-between items-center hover:text-[#ababab] cursor-pointer select-none`}
                  onClick={() => {
                    if (index === 0) {
                      return
                    }
                    const nextKey = menuList[index - 1].key
                    setSearchParams({ key: nextKey })
                    setIndex(index - 1)
                    setSelectedKeys([...gitOpenkeys(nextKey), nextKey])
                    setOpenKeys(gitOpenkeys(nextKey))
                    setCurrentTab(getParentKey(nextKey))
                  }}
                >
                  <IconLeft fontSize={16} />
                  <div className=" text-right">
                    <span className="text-xs">上一篇</span>
                    {!isMobileOnly && (
                      <h2 className="text-sm">
                        {index > 1 ? menuList[index - 1]?.label : menuList[0]?.label}
                      </h2>
                    )}
                  </div>
                </div>
                {isMobileOnly ? (
                  <div className="flex justify-center items-center">
                    <Tooltip
                      position="top"
                      className="user-tooltip rounded-md overflow-hidden shadow-xl"
                      content={
                        <>
                          {getParentChildren(menuList[index]?.key)?.map((item, index) => (
                            <li
                              onClick={() => {
                                const newIndex = menuList.findIndex(
                                  (menu) => menu.key === item?.key
                                )

                                if (agreementObj[item.key]) {
                                  window.open(agreementObj[item.key])
                                  return
                                }
                                // if (item.key == "terms-service") {
                                //   window.open(
                                //     "https://cos-xica-prod.tiamat.world/assets/Tiamat%E7%94%A8%E6%88%B7%E5%8D%8F%E8%AE%AE-20230821-V3.pdf"
                                //   )
                                //   return
                                // } else if (item?.key == "privacy-policy") {
                                //   window.open(
                                //     "https://cos-xica-prod.tiamat.world/assets/Tiamat%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96-20230821-V3.pdf"
                                //   )
                                //   return
                                // }
                                setIndex(newIndex)
                              }}
                            >
                              {item?.label}
                            </li>
                          ))}
                        </>
                      }
                    >
                      <MenuIcon />
                    </Tooltip>
                    <span className="ml-[10px]">{menuList[index]?.label}</span>
                  </div>
                ) : (
                  <div className="w-[1px]  h-full bg-[#2a2a36] mx-4" />
                )}

                <div
                  className={`${
                    index === menuList.length - 1 ? "text-[#ababab] cursor-no-drop md:hidden" : ""
                  } h-full md:w-[120px] flex justify-between items-center hover:text-[#ababab] cursor-pointer select-none`}
                  onClick={() => {
                    if (index === menuList.length - 1) {
                      return
                    }
                    const nextKey = menuList[index + 1].key
                    setSearchParams({ key: nextKey })
                    setIndex(index + 1)
                    setSelectedKeys([...gitOpenkeys(nextKey), nextKey])
                    setOpenKeys(gitOpenkeys(nextKey))
                    setCurrentTab(getParentKey(nextKey))
                  }}
                >
                  <div className="text-left">
                    <span className="text-xs">下一篇</span>
                    {!isMobileOnly && (
                      <h2 className="text-sm">
                        {index < menuList.length - 1
                          ? menuList[index + 1]?.label
                          : menuList[menuList.length - 1]?.label}
                      </h2>
                    )}
                  </div>
                  <IconRight />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutGeneral>
  )
}
export default UserGuide
