import { V1Task } from "@/generated/api/data-contracts"
import { IllegalWork } from "@/common/IllegalWork"
import { ErrorImage } from "@/common/ErrorImage"

import { ReactComponent as IconRepaint } from "@/assets/repaint.svg"
import { ReactComponent as IconHeard } from "@/assets/heart-white.svg"
import { ReactComponent as CheckmarkIcon } from "@/assets/checkmark.svg"
import { ReactComponent as IconEye } from "@/assets/eye.svg"
import { ReactComponent as IconEyeClose } from "@/assets/eye-close.svg"

import { useEffect, useRef, useState } from "react"
import { getImageURL } from "@/utils"
import { Tooltip } from "@arco-design/web-react"
import ImageRepainting from "@/Studio/components/ImageRepainting"
import CardOneOption from "./CardOneOption"

type PropsType = {
  task: V1Task
  taskId?: string
  workId?: string
  onSelect?: (list: string[], id?: string) => void
  selectList?: string[]
  hiddenOperation?: boolean
  hiddenConfig?: Partial<{
    deleteTask?: boolean // 删除task
    publicOrPrivate?: boolean // 私有、公开
    linkOrUnlike?: boolean // 喜欢不喜欢
    imageRepainting?: boolean // 图片重绘
    more?: boolean // 更多。。。
    usedForAvatar?: boolean // 用于图像
    usedForBackground?: boolean // 用户背景
    generateAgain?: boolean // 再次生成
    controlNet?: boolean //controlNet
    // ImageSpeak?: boolean // 图片会说话
    referenceImage?: boolean
  }>
  operationHandlers?: {
    publicOrPrivate?: (isPrivate: boolean, workId: string) => void
    likeOrUnlike?: (liked: boolean, workId: string) => void
    viewBigImage?: () => void
  }
}

const CardOne = ({
  operationHandlers,
  hiddenConfig = {},
  selectList,
  onSelect,
  workId,
  task,
}: PropsType) => {
  const work = task.works?.find((work) => workId === work.id) || {}

  const [popVisible, setPopVisible] = useState(false)
  const [isErrorImage, setIsErrorImage] = useState(false)
  const [loadedImgList, setLoadedImgList] = useState<string[]>()
  const [ready, setReady] = useState(false)

  const wrapRef = useRef<HTMLDivElement>(null)
  // 懒加载card
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setTimeout(() => {
            setReady(true)
          }, 250)
          observer.unobserve(entry.target) // 停止观察
        }
      })
    })

    if (wrapRef.current) {
      observer.observe(wrapRef.current)
    }

    return () => {
      if (wrapRef.current) {
        observer.unobserve(wrapRef.current)
      }
    }
  }, [])

  return (
    <div className="w-full h-full" ref={wrapRef}>
      {ready ? (
        <>
          {work.illegal ? (
            // 违法图片
            <IllegalWork id={work.id} URL={work.image} taskId={task?.id} />
          ) : isErrorImage ? (
            <ErrorImage id={work.id} URL={work.image} taskId={task?.id} />
          ) : (
            // 合法图片
            <div
              className={`relative h-full bg-[#676565] rounded ${
                popVisible
                  ? " before:content-[''] before:absolute before:inset-0 before:bg-[#000000]/40 before:rounded"
                  : ""
              }`}
              // style={{ minHeight: "10vh", minWidth: "10vh" }}   移动端给了minWidth会留白
              style={{ minHeight: "10vh" }}
              onMouseEnter={() => {
                setPopVisible(true)
              }}
              onMouseLeave={() => {
                setPopVisible(false)
              }}
              onClick={() => {
                if (onSelect) {
                  onSelect?.([], work.id)
                } else {
                  operationHandlers?.viewBigImage?.()
                }
              }}
            >
              <img
                className="w-auto h-full rounded select-none object-cover"
                src={getImageURL(work.image || "", true)}
                alt=""
                style={{
                  visibility:
                    work.image && loadedImgList?.includes(work.image) ? "visible" : "hidden",
                }}
                onLoad={() => {
                  work.image && setLoadedImgList([work.image].concat(loadedImgList || []))
                }}
                onError={() => setIsErrorImage(true)}
              />
              <div
                className="absolute loading-indicator-primary z-5 top-0  right-0 h-full w-full flex flex-col justify-center items-center"
                style={{
                  display: work.image && loadedImgList?.includes(work.image) ? "none" : "flex",
                }}
              >
                <div
                  className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-neutral-100 motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status"
                >
                  <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                    Loading...
                  </span>
                </div>
              </div>

              {onSelect && (
                <div className="absolute top-2.5 right-2.5">
                  {selectList?.includes(work.id || "") ? (
                    <label className="w-5 h-5 items-center justify-center cursor-pointer bg-[#5A14F0] rounded flex">
                      <CheckmarkIcon />
                    </label>
                  ) : (
                    <div className="w-5 h-5 cursor-pointer bg-[#C9CBD1]/70 rounded" />
                  )}
                </div>
              )}

              <div hidden={!!onSelect}>
                {/* <div className="hidden md:block text-white text-[24px] font-[num] absolute top-[10px] left-[20px]">
                  {work.score}
                </div> */}

                <div
                  className={`absolute bottom-[20px] left-[20px] right-[20px] justify-between rounded-sm p-[10px] ${
                    popVisible ? "flex" : "hidden"
                  } text-white items-center bg-[#0c0c1ae6]`}
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                >
                  <Tooltip content={work.isPrivate ? "切换为公开" : "切换为不公开"}>
                    <div>
                      {hiddenConfig?.publicOrPrivate ? null : (
                        <button
                          className="hover:bg-[#FFFFFF1A] transition  mr-[10px] rounded-sm cursor-pointer stroke-gray-400"
                          onClick={() =>
                            operationHandlers?.publicOrPrivate?.(!!work.isPrivate, work.id ?? "")
                          }
                        >
                          {work.isPrivate ? <IconEyeClose /> : <IconEye />}
                        </button>
                      )}
                    </div>
                  </Tooltip>

                  <div className="flex gap-2">
                    <Tooltip content={work.liked ? "切换为不喜欢" : "切换为喜欢"}>
                      <div className="h-[28px] w-[28px] hover:bg-[#FFFFFF1A] rounded-sm flex items-center justify-center ">
                        <IconHeard
                          fill={work.liked ? "#E93247" : "white"}
                          className="transition  cursor-pointer"
                          onClick={() =>
                            operationHandlers?.likeOrUnlike?.(!!work.liked, work.id ?? "")
                          }
                        ></IconHeard>
                      </div>
                    </Tooltip>

                    <Tooltip content={"图像重绘"}>
                      <div>
                        {hiddenConfig?.imageRepainting ? null : (
                          <>
                            {work.seed && work.seed !== -1 && (
                              <ImageRepainting
                                buttonContent={
                                  <IconRepaint
                                    className="hover:bg-[#FFFFFF1A] rounded-sm transition cursor-pointer "
                                    fill={"white"}
                                  ></IconRepaint>
                                }
                                work={work}
                              />
                            )}
                          </>
                        )}
                      </div>
                    </Tooltip>

                    {hiddenConfig.more ? null : (
                      <CardOneOption
                        hiddenConfig={{
                          deleteTask: hiddenConfig.deleteTask,
                          publicOrPrivate: hiddenConfig.publicOrPrivate,
                        }}
                        work={work}
                        task={task as V1Task}
                        handlers={{
                          publicOrPrivate: operationHandlers?.publicOrPrivate,
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="bg-[#ffffff2b] w-full h-full flex justify-center items-center rounded-md">
          <div className=" text-center text-[#ffffff33]">
            <h1>Tiamat</h1>
            <p>loading</p>
          </div>
        </div>
      )}
    </div>
  )
}

export default CardOne
