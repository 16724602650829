/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface RepoOrder {
  codeUrl?: string
  createdAt?: string
  creatorUserId?: string
  description?: string
  discountCode?: string
  id?: number
  isUpgrade?: boolean
  orderId?: string
  orderStatus?: number
  orderTitle?: string
  paymentType?: number
  productAmount?: number
  productId?: number
  productPrice?: number
  productTitle?: string
  qrImage?: string
  sn?: string
  totalFee?: number
  updatedAt?: string
}

export interface ResponseResponseWrapper {
  data?: any
  errorCode?: string
  errorMessage?: string
  host?: string
  showType?: ResponseShowType
  success?: boolean
  traceId?: string
}

export enum ResponseShowType {
  ShowTypeSilent = 0,
  ShowTypeWarn = 1,
  ShowTypeError = 2,
  ShowTypeNotification = 4,
  ShowTypePage = 9,
}

export interface V1AbuseReportSubmitReq {
  description?: string
  images?: string[]
  /** ReasonTag 与 TicketTheme 一致 */
  reasonTag?: V1TicketTheme
  workId?: string
}

export interface V1Activity {
  /** BannerImages 活动横幅图片链接; */
  bannerImages?: string[]
  /** Bonus 活动奖励; */
  bonus?: V1Bonus
  /** CreateAt 活动创建时间; */
  createAt?: number
  /** Creator 活动创建者; */
  creator?: string
  /** DisplayAt 活动显示时间; */
  displayAt?: number
  /** EndAt 活动结束时间; */
  endAt?: number
  /** Long 活动详细介绍; */
  long?: string
  /** OpData 活动数据; */
  opData?: V1ActivityOpData
  /** RedirectURL 活动跳转链接; */
  redirectURL?: string
  /** SetTopAt 活动置顶时间; */
  setTopAt?: number
  /** Short 活动简介; */
  short?: string
  /** StartAt 活动开始时间; */
  startAt?: number
  /** State 活动状态; */
  state?: V1ActivityState
  /** Title 活动标题; */
  title?: string
  /** Type 活动类型; */
  type?: string
  /** UID 活动唯一 ID; */
  uid?: string
}

export interface V1ActivityOpData {
  /** AvgWorkCount 平均生成张数/人; */
  avgWorkCount?: number
  /** FreqCount 活动参与次数; */
  freqCount?: number
  /** MedianWorkCount 中位生成张数/人; */
  medianWorkCount?: number
  /** UserCount 活动参与人数; */
  userCount?: number
}

export enum V1ActivityState {
  ActivityStateNotStarted = "NotStarted",
  ActivityStateOngoing = "Ongoing",
  ActivityStateEnded = "Ended",
}

export interface V1ActivitySubmissionBeiyingRequest {
  /**
   * ActivityUID 活动 UID;
   * +Required;
   */
  activityUID?: string
  /**
   * ContactInformation 联系方式;
   * +Required;
   */
  contactInformation?: string
  /**
   * CreationInfo 作品信息;
   * +Required;
   */
  creationInfo?: string
  /**
   * CreationName 作品名称;
   * +Required;
   */
  creationName?: string
  /**
   * Username 上传图片;
   * +Required;
   */
  uploadImages?: string
  /**
   * Username 你的名字;
   * +Required;
   */
  username?: string
}

export type V1ActivitySubmissionBeiyingResponse = object

export interface V1ActivityTemplateForAdmin {
  /**
   * AbstractModel 运营编辑生图模型;
   * +Optional;
   */
  abstractModel?: string
  /**
   * ActivityTemplateUID 当前活动类别ID
   * +Required
   */
  activityTemplateUID?: string
  createAt?: number
  /**
   * Creator 创建或修改管理员ID;
   * +Required;
   */
  creator?: string
  /**
   * EnableAbstractModel 梅花村活动页前端是否允许用户编辑生图模型;
   * +Required;
   */
  enableAbstractModel?: boolean
  /**
   * EnableCustom 梅花村活动页前端是否对用户显示controlNet入口;
   * +Required;
   */
  enableCustom?: boolean
  /**
   * EnableCustom 梅花村活动页前端是否允许用户编辑Prompt;
   * +Required;
   */
  enablePrompt?: boolean
  /**
   * EnableRatio 梅花村活动页前端是否允许用户编辑生图比例;
   * +Required;
   */
  enableRatio?: boolean
  /**
   * FixedPrompt 运营输入的固定Prompt，生图任务中固定拼接，长度小于4096;
   * +Optional;
   */
  fixedPrompt?: string
  /**
   * OfficialControlNet 官方示意图关联的controlNet;
   * +Optional;
   */
  officialControlNet?: V1ControlNetSettings
  /**
   * OfficialControlNet 官方示意图关联的controlNet;
   * +Required;
   */
  officialImageURLs?: string[]
  /**
   * RandomPrompts 运营输入的随机Prompt数组,文本总长度小于4096
   * +Required;
   */
  randomPrompts?: string[]
  /**
   * Ratio 运营配置的生图比例;
   * +Optional;
   */
  ratio?: string
  /**
   * Title 活动类别名称，长度小于10;
   * +Required;
   */
  title?: string
  updateAt?: number
  /**
   * UserControlNet 用户上传图片预设的controlNet，不包含inputImageUrl；
   * +Optional;
   */
  userControlNet?: V1ControlNetSettings
}

export interface V1ActivityTemplateForUser {
  /**
   * AbstractModel 运营编辑生图模型;
   * +Optional;
   */
  abstractModel?: string
  /**
   * ActivityTemplateUID 当前活动类别ID
   * +Required
   */
  activityTemplateUID?: string
  createAt?: number
  /**
   * EnableAbstractModel 梅花村活动页前端是否允许用户编辑生图模型;
   * +Required;
   */
  enableAbstractModel?: boolean
  /**
   * EnableCustom 梅花村活动页前端是否对用户显示controlNet入口;
   * +Required;
   */
  enableCustom?: boolean
  /**
   * EnableCustom 梅花村活动页前端是否允许用户编辑Prompt;
   * +Required;
   */
  enablePrompt?: boolean
  /**
   * EnableRatio 梅花村活动页前端是否允许用户编辑生图比例;
   * +Required;
   */
  enableRatio?: boolean
  /**
   * OfficialControlNet 官方示意图关联的controlNet;
   * +Optional;
   */
  officialControlNet?: V1ControlNetSettings
  /**
   * OfficialControlNet 官方示意图关联的controlNet;
   * +Required;
   */
  officialImageURLs?: string[]
  /**
   * Ratio 运营配置的生图比例;
   * +Optional;
   */
  ratio?: string
  /**
   * Title 活动类别名称，长度小于10;
   * +Required;
   */
  title?: string
  /**
   * UserControlNet 用户上传图片预设的controlNet，不包含inputImageUrl；
   * +Optional;
   */
  userControlNet?: V1ControlNetSettings
}

export interface V1AdminListTasksResponse {
  current?: number
  list?: V1Task[]
  pageSize?: number
  total?: number
}

export interface V1AdminListTemplatesResponse {
  templates?: V1TemplateData[]
  total?: number
}

export interface V1AdminListTicketsResponse {
  current?: number
  list?: V1AdminTicket[]
  pageSize?: number
  total?: number
}

export interface V1AdminRecommandTemplateRequest {
  /**
   * IsRecommand 是否编辑为推荐;
   * +Required;
   */
  isRecommand?: boolean
}

export type V1AdminRecommandTemplateResponse = object

export interface V1AdminRecommendWorkRequest {
  /**
   * RecommendWeight 推荐权重值,为0表示取消推荐;
   * +Required;
   */
  recommendWeight?: number
}

export type V1AdminRecommendWorkResponse = object

export interface V1AdminReleaseTemplateRequest {
  /**
   * ReleaseState 发布状态;
   * +Required;
   */
  releaseState?: V1TemplateReleaseState
}

export type V1AdminReleaseTemplateResponse = object

export interface V1AdminReviewTemplateRequest {
  /**
   * AutoRelease 是否自动发布;
   * +Optional; Default is false;
   */
  autoRelease?: boolean
  /**
   * DisableReward 是否禁止奖励;
   * +Optional; Default is false;
   */
  disableReward?: boolean
  /**
   * RejectReason 拒绝理由;
   * +Required when reviewState is reject;
   */
  rejectReason?: string
  /**
   * ReviewState 审核状态;
   * +Required;
   */
  reviewState?: V1TemplateReviewState
}

export type V1AdminReviewTemplateResponse = object

export interface V1AdminTicket {
  assigneeId?: string
  category?: V1TicketCategory
  completedAt?: number
  createdAt?: number
  description?: string
  images?: string[]
  owner?: V1AdminTicketOwner
  ownerId?: string
  result?: string
  status?: V1TicketStatus
  theme?: string
  uid?: string
}

export interface V1AdminTicketOwner {
  id?: string
  nick?: string
  phone?: string
}

export interface V1AdminUpdateTemplateRequest {
  /**
   * Intro 模板介绍;
   * +Optional;
   */
  intro?: string
  /**
   * Name 模板名称;
   * +Optional; 全局唯一;
   */
  name?: string
}

export interface V1AdminUpdateTemplateResponse {
  template?: V1TemplateData
}

export interface V1AdminUpdateTicketReq {
  assigneeId?: string
  result?: string
  status?: V1TicketStatus
}

export interface V1ApplyInviteCodeRequest {
  email: string
  job: string
  scene: string
  socialLink?: string
  /** enum: sub */
  source?: string
}

export interface V1BatchDeleteCollectionRequest {
  /**
   * CollectionUIDs 相册UID;
   * +Required;
   */
  collectionUIDs?: string[]
}

export type V1BatchDeleteCollectionResponse = object

export interface V1BatchLikeWorkRequest {
  /**
   * CollectionUID 相册UID;
   * +Required;
   */
  collectionUID?: string
  /**
   * WorkIDs  作品ID列表;
   * +Required;
   */
  workIDs?: string[]
}

export interface V1BatchSetIsPrivateRequest {
  /**
   * IsPrivate
   * + Required;
   */
  isPrivate: boolean
  /**
   * TaskIDs
   * + Required;
   */
  taskIDs: string[]
}

export interface V1BatchSetWorksIsPrivateRequest {
  /**
   * IsPrivate
   * + Required;
   */
  isPrivate?: boolean
  /**
   * WorkIDs
   * + Required;
   */
  workIDs?: string[]
}

export interface V1BatchUnLikeWorkRequest {
  /**
   * CollectionUID 相册UID;
   * +Required;
   */
  collectionUID?: string
  /**
   * WorkIDs  作品ID列表;
   * +Required;
   */
  workIDs?: string[]
}

export interface V1BindCategoryRequest {
  /**
   * categoryUIDs 分类UID数组;
   * + Required;
   */
  categoryUIDs: string[]
}

export interface V1Bonus {
  /** AddCurrentSubscriptionExpires 添加当前订阅的过期时间; */
  addCurrentSubscriptionExpires?: V1BonusAddCurrentSubscriptionExpires
  /** AddPrivateImageExpires 添加作品私密的过期时间; */
  addPrivateImageExpires?: V1BonusAddPrivateImageExpires
  /** AddUnlimitedFastGenerationExpires 添加无限制快速生成过期时间 */
  addUnlimitedFastGenerationExpires?: V1BonusAddUnlimitedFastGenerationExpires
  /** AddUnlimitedSlowGenerationExpires 添加无限制慢速生成过期时间; */
  addUnlimitedSlowGenerationExpires?: V1BonusAddUnlimitedSlowGenerationExpires
  /** CoinCount 金币数量 */
  coinCount?: V1BonusCoinCount
  /** Dynamic 动态奖励; */
  dynamic?: boolean
  /** FastGenerationCountQuota 快速生成次数限制; */
  fastGenerationCountQuota?: V1BonusFastGenerationCountQuota
  /**
   * Deprecated: FastGenerationTime 快速生成时间; 废弃此字段,使用快速生成次数(FastGenerationCountQuota)代替
   * 按照 10s 等于 1次进行换算;
   */
  fastGenerationTime?: V1BonusFastGenerationTime
  /** GenerationCountQuota 免费生成次数限制; */
  generationCountQuota?: V1BonusGenerationCountQuota
  /** SubscriptionDiscount 订阅优惠奖励; */
  subscriptionDiscount?: V1BonusSubscriptionDiscount
  /** 活动定制的固定包年套餐奖励; */
  yearlyVIPXPackage?: V1BonusVIPXPackage
}

export interface V1BonusAddCurrentSubscriptionExpires {
  /** Seconds 添加的时间; */
  seconds?: number
  /** UsedCountLimit 使用次数限制; */
  usedCountLimit?: number
  /** ValidSecondsAfterReceived 领取后的有效时长; */
  validSecondsAfterReceived?: number
  /** ValidSecondsBeforeReceived 领取前的有效时长; */
  validSecondsBeforeReceived?: number
}

export interface V1BonusAddPrivateImageExpires {
  /** Seconds 添加的时间; */
  seconds?: number
  /** UsedCountLimit 使用次数限制; */
  usedCountLimit?: number
  /** ValidSecondsAfterReceived 领取后的有效时长; */
  validSecondsAfterReceived?: number
  /** ValidSecondsBeforeReceived 领取前的有效时长; */
  validSecondsBeforeReceived?: number
}

export interface V1BonusAddUnlimitedFastGenerationExpires {
  /** Seconds 添加的时间; */
  seconds?: number
  /** UsedCountLimit 使用次数限制; */
  usedCountLimit?: number
  /** ValidSecondsAfterReceived 领取后的有效时长; */
  validSecondsAfterReceived?: number
  /** ValidSecondsBeforeReceived 领取前的有效时长; */
  validSecondsBeforeReceived?: number
}

export interface V1BonusAddUnlimitedSlowGenerationExpires {
  /** Seconds 添加的时间; */
  seconds?: number
  /** UsedCountLimit 使用次数限制; */
  usedCountLimit?: number
  /** ValidSecondsAfterReceived 领取后的有效时长; */
  validSecondsAfterReceived?: number
  /** ValidSecondsBeforeReceived 领取前的有效时长; */
  validSecondsBeforeReceived?: number
}

export interface V1BonusCoinCount {
  /** Count 金币数量 */
  count?: number
  /** UsedCountLimit 使用次数限制; */
  usedCountLimit?: number
  /** ValidSecondsAfterReceived 领取后的有效时长; */
  validSecondsAfterReceived?: number
  /** ValidSecondsBeforeReceived 领取前的有效时长; */
  validSecondsBeforeReceived?: number
}

export interface V1BonusFastGenerationCountQuota {
  /** Count 快速生成次数; */
  count?: number
  /** UsedCountLimit 使用次数限制; */
  usedCountLimit?: number
  /** ValidSecondsAfterReceived 领取后的有效时长; */
  validSecondsAfterReceived?: number
  /** ValidSecondsBeforeReceived 领取前的有效时长; */
  validSecondsBeforeReceived?: number
}

export interface V1BonusFastGenerationTime {
  /** Seconds 快速生成时间; */
  seconds?: number
  /** UsedCountLimit 使用次数限制; */
  usedCountLimit?: number
  /** ValidSecondsAfterReceived 领取后的有效时长; */
  validSecondsAfterReceived?: number
  /** ValidSecondsBeforeReceived 领取前的有效时长; */
  validSecondsBeforeReceived?: number
}

export interface V1BonusGenerationCountQuota {
  /** Count 免费生成次数; */
  count?: number
  /** UsedCountLimit 使用次数限制; */
  usedCountLimit?: number
  /** ValidSecondsAfterReceived 领取后的有效时长; */
  validSecondsAfterReceived?: number
  /** ValidSecondsBeforeReceived 领取前的有效时长; */
  validSecondsBeforeReceived?: number
}

export interface V1BonusSubscriptionDiscount {
  /**
   * Discount 订阅优惠;
   * 取值范围 (0, 1);
   */
  discount?: number
  /** UsedCountLimit 使用次数限制; */
  usedCountLimit?: number
  /** ValidSecondsAfterReceived 领取后的有效时长; */
  validSecondsAfterReceived?: number
  /** ValidSecondsBeforeReceived 领取前的有效时长; */
  validSecondsBeforeReceived?: number
}

export interface V1BonusVIPXPackage {
  /** Count 每月快速生成次数; */
  count?: number
  /** UsedCountLimit 使用次数限制; */
  usedCountLimit?: number
  /** ValidSecondsAfterReceived 领取后的有效时长; */
  validSecondsAfterReceived?: number
  /** ValidSecondsBeforeReceived 领取前的有效时长; */
  validSecondsBeforeReceived?: number
}

export interface V1Category {
  cover?: string
  hideInScore?: boolean
  id?: string
  images?: string[]
  intro?: string
  name?: string
  uid?: string
}

export interface V1CheckGuestCreatedTaskResponse {
  /** 是否已创建过任务 */
  created?: boolean
}

export interface V1CheckLoginResponse {
  isLogin?: boolean
}

export interface V1CoinRedeemDesc {
  /** CoinCountLimit 兑换该权益所需要的金币数量 */
  coinCountLimit?: number
  /** ID */
  id?: number
  /** RedeemBonus 权益详情 */
  redeemBonus?: V1Bonus
}

export interface V1CoinRedeemInfo {
  /** CanReceive 当前用户是否可以兑换该权益 */
  canReceive?: boolean
  /** RedeemInfo 权益详情 */
  redeemInfo?: V1CoinRedeemDesc
}

export interface V1Collection {
  /** CoverImage 封面图 */
  coverImage?: string
  /** IncludeSpecificWork 相册中是否包含指定work */
  includeSpecificWork?: boolean
  /** Intro 相册描述 */
  intro?: string
  /** IsDefault 是否是默认相册 */
  isDefault?: boolean
  /** IsDeleted 相册是否被删除 */
  isDeleted?: boolean
  /** isMine 是否是当前用户创建的相册 */
  isMine?: boolean
  /** IsPrivate 是否私密 */
  isPrivate?: boolean
  /** Like 当前用户是否收藏了该相册 */
  like?: boolean
  /** LikeCount 相册收藏数量 */
  likeCount?: number
  /** LikeWorkAt 最近一张work收藏进相册的时间 */
  likeWorkAt?: number
  /** Title 相册名称 */
  title?: string
  /** UID 相册唯一 ID */
  uid?: string
  /** User 相册创建者信息; */
  user?: V1TaskUserInfo
  /** UserUID 相册创建者id */
  userUID?: string
  /** WorkCount 相册中作品数量 */
  workCount?: number
  /** Works 相册中收藏work */
  works?: V1CollectionWork[]
}

export interface V1CollectionWork {
  abstractModel?: string
  id?: string
  /** 是否包含违禁内容，当包含违禁内容时，Image字段为空 */
  illegal?: boolean
  image?: string
  inline?: V1GenreScore
  /** IsDeleted 是否被删除 */
  isDeleted?: boolean
  /** IsPrivate 仅自己可见 */
  isPrivate?: boolean
  /** 收藏数量 */
  likeCount?: number
  /** 当前用户是否喜欢该作品 */
  liked?: boolean
  ratio?: string
  recommendAt?: number
  score?: number
  /** 为-1表示没有seed，不能用于图像重绘 */
  seed?: number
  size?: string
  task?: V1Task
  taskId?: string
  taskStatus?: V1TaskStatus
}

export interface V1ComfyExecArtifact {
  /** ElapseInMilliseconds 产出该产物的耗时, 单位: 毫秒; */
  elapseInMilliseconds?: number
  /**
   * Error 错误信息;
   * 如果正常生成, 则 Error 为 "";
   */
  error?: string
  /** Images 产出的图片; */
  images?: string[]
}

export interface V1ComfyExecCallback {
  /**
   * Artifacts 产物;
   * 与 AIJSONPayload 一一对应;
   * +Required;
   */
  artifacts?: V1ComfyExecArtifact[]
  /**
   * ElapseInMilliseconds 耗时, 单位: 毫秒;
   * +Optional;
   */
  elapseInMilliseconds?: number
  /**
   * RequestID 请求 ID;
   * +Required;
   */
  requestID?: string
  /**
   * TaskID 数据库中的任务 ID;
   * +Required;
   */
  taskID?: string
}

export enum V1ConsumePriority {
  ConsumePrioritySubscribe = "subscribe",
  ConsumePriorityOneTimeSupplement = "oneTimeSupplement",
  ConsumePriorityReward = "reward",
}

export interface V1ControlNetSettings {
  units?: V1ControlNetUnit[]
}

export interface V1ControlNetUnit {
  /**
   * ArgOverride 参数覆盖;
   * 如果有设置此参数，将会被设置到 alwayson_scripts.controlnet.args[N]下
   *  特殊情况：因为 ImageURL 在 webui 里解码，所以会通过 InputImageURL 往下游传，不在 argOverride 里
   * + Optional;
   */
  argOverride?: Record<string, any>
  /**
   * InputImageURL 输入图片的 URL;
   * + Required;
   */
  inputImageURL?: string
  /**
   * Module 模块;
   * + Required;
   */
  module?: string
  /**
   * Weight 权重;
   * + Required;
   */
  weight?: number
}

export interface V1CreateActivityRequest {
  /**
   * BannerImages 活动横幅图片链接;
   * +Required;
   */
  bannerImages?: string[]
  /**
   * Bonus 活动奖励;
   * + Optional;
   */
  bonus?: V1Bonus
  /**
   * DisplayAt 活动显示时间;
   * +Required;
   */
  displayAt?: number
  /**
   * EndAt 活动结束时间;
   * +Required;
   */
  endAt?: number
  /**
   * Long 活动详细介绍;
   * +Required;
   */
  long?: string
  /**
   * RedirectURL 活动跳转链接;
   * + Optional;
   */
  redirectURL?: string
  /**
   * Short 活动简介;
   * +Required;
   */
  short?: string
  /**
   * StartAt 活动开始时间;
   * +Required;
   */
  startAt?: number
  /**
   * Title 活动标题;
   * +Required;
   */
  title?: string
  /**
   * Type 活动类型:template(模板类型)|task(图片类型);
   * +Required;
   */
  type?: string
}

export interface V1CreateActivityResponse {
  activity?: V1Activity
}

export interface V1CreateCollectionRequest {
  /**
   * CoverImage 封面图;
   * + Optional;
   */
  coverImage?: string
  /**
   * Intro 相册介绍;
   * +Optional;
   */
  intro?: string
  /**
   * IsPrivate 是否私密;
   * + Optional;
   * + Default: false;
   */
  isPrivate?: boolean
  /**
   * Title 相册名称;
   * +Required;
   */
  title?: string
}

export interface V1CreateCollectionResponse {
  collection?: V1Collection
}

export interface V1CreateDiscountCodeResponse {
  code?: string
}

export interface V1CreateImagePromptForGuestRequest {
  /**
   * InputImageURL 上传的参考图链接;
   * + Required;
   */
  inputImageURL: string
  /**
   * 是否是私有任务(备用)
   * + Optional;
   * + Default: false;
   */
  isPrivate?: boolean
  /**
   * 任务优先级，提交后会根据用户权益校验权限，如果权限不足则会返回错误
   * + Optional;
   * + Enum: low, normal, high;
   * + Default: low;
   */
  priority?: V1TaskPriority
}

export interface V1CreateImagePromptRequest {
  /**
   * InputImageURL 上传的参考图链接;
   * + Required;
   */
  inputImageURL: string
  /**
   * 是否是私有任务(备用)
   * + Optional;
   * + Default: false;
   */
  isPrivate?: boolean
  /**
   * 任务优先级，提交后会根据用户权益校验权限，如果权限不足则会返回错误
   * + Optional;
   * + Enum: low, normal, high;
   * + Default: low;
   */
  priority?: V1TaskPriority
}

export interface V1CreateImagePromptResponse {
  id?: string
}

export interface V1CreateNotificationReq {
  /**
   * 通知内容
   * @maxLength 4000
   */
  content: string
  /** 封面图 */
  cover?: string
  /** 缩略图 */
  thumb?: string
  /**
   * 通知标题
   * @maxLength 255
   */
  title: string
  /** 1:公告, 2:用户消息 */
  type: number
  /** 如果是发送给单个用户的消息，这里填写用户id */
  userId?: string
}

export interface V1CreateOrderResp {
  /** 创建成功的订单ID */
  orderId?: string
  /** 支付二维码图片base64字符串 */
  qrImage?: string
  /** 订单总价格,人民币分 */
  totalFee?: number
}

export interface V1CreateRedeemCodeRequest {
  /**
   * Bonus
   * + Required;
   */
  bonus?: V1Bonus
  /**
   * CodePrefix 兑换码前缀;
   * + Optional;
   */
  codePrefix?: string
  /**
   * UsedCountLimit 折扣码使用次数限制;
   * + Required;
   */
  usedCountLimit?: number
  /**
   * ValidFrom 兑换码有效期开始时间;
   * + Required;
   */
  validFrom?: number
  /**
   * ValidTo 兑换码有效期结束时间;
   * + Required;
   */
  validTo?: number
}

export interface V1CreateRedeemCodeResponse {
  code?: string
}

export interface V1CreateSensitiveWordReq {
  word: string
}

export interface V1CreateTaskForGuestRequest {
  /**
   * ControlNet设置
   * + Optional;
   */
  controlNet?: V1ControlNetSettings
  /**
   * ImageCount 控制一个任务出图的数量
   * + Optional;
   * + Default: 4;
   */
  imageCount?: number
  /**
   * 是否是私有任务，私有任务生成的图片，不会公开展示在首页
   * + Optional;
   * + Default: false;
   */
  isPrivate?: boolean
  /** Model 模型,前端和后端约定好的id */
  model?: string
  /**
   * NegativeTextPrompt 用户负向咒语;
   * + Optional;
   */
  negativeTextPrompt?: string
  /**
   * 任务优先级，提交后会根据用户权益校验权限，如果权限不足则会返回错误
   * + Optional;
   * + Enum: low, normal, high;
   * + Default: low;
   */
  priority?: V1TaskPriority
  /**
   * 图片比例
   * + Required;
   * + Enum: 1:1, 4:3, 16:9, 3:4, 9:16;
   */
  ratio: string
  /**
   * ReferPower 参考图影响力; [0,100]
   * + Optional;
   * + Required when the ReferencedImage is not empty;
   */
  referPower?: number
  /**
   * ReferencedImage 参考图链接;
   * + Optional;
   */
  referencedImage?: string
  /**
   * TemplateID 模板 ID;
   * +Optional;
   */
  templateID?: number
  /** TextPrompts 用户正向咒语; */
  textPrompt?: string
}

export interface V1CreateTaskRequest {
  /**
   * ActivityUID 参与的活动 UID;
   * Note: 后续活动模板上线之后，该字段废弃；
   * +Optional;
   */
  activityUID?: string
  /**
   * ControlNet设置
   * + Optional;
   */
  controlNet?: V1ControlNetSettings
  /**
   * ImageCount 控制一个任务出图的数量
   * + Optional;
   * + Default: 4;
   */
  imageCount?: number
  /**
   * 是否是私有任务，私有任务生成的图片，不会公开展示在首页
   * + Optional;
   * + Default: false;
   */
  isPrivate?: boolean
  /** Model 模型,前端和后端约定好的id */
  model?: string
  /**
   * NegativeTextPrompt 用户负向咒语;
   * + Optional;
   */
  negativeTextPrompt?: string
  /**
   * 任务优先级，提交后会根据用户权益校验权限，如果权限不足则会返回错误
   * + Optional;
   * + Enum: low, normal, high;
   * + Default: low;
   */
  priority?: V1TaskPriority
  /**
   * 图片比例
   * + Required;
   * + Enum: 1:1, 4:3, 16:9, 3:4, 9:16;
   */
  ratio: string
  /**
   * ReferPower 参考图影响力; [0,100]
   * + Optional;
   * + Required when the ReferencedImage is not empty;
   */
  referPower?: number
  /**
   * ReferencedImage 参考图链接;
   * + Optional;
   */
  referencedImage?: string
  /**
   * 如果是从模板创建的任务，需要传入模板ID
   * +Optional;
   */
  templateID?: number
  /** TextPrompts 用户正向咒语; */
  textPrompt?: string
  /**
   * ThirdPartyChannel 第三方渠道;
   * +Optional;
   */
  thirdPartyChannel?: string
}

export interface V1CreateTaskResponse {
  id?: string
}

export interface V1CreateTaskWithActivityTemplateRequest {
  /**
   * ActivityUID 参与的活动类别 UID;
   * +Required;
   */
  activityTemplateUID?: string
  /**
   * ActivityUID 参与的活动 UID;
   * +Required;
   */
  activityUID?: string
  /**
   * Model 模型,前端和后端约定好的id
   * + Optional;
   */
  model?: string
  /**
   * 图片比例
   * + Optional;
   * + Enum: 1:1, 4:3, 16:9, 3:4, 9:16;
   */
  ratio?: string
  /**
   * ReferencedImageURL 用户上传参考图链接，用于拼接活动类别中的userControlNet;
   * + Optional;
   */
  referencedImageURL?: string
  /**
   * TextPrompt 用户正向咒语;
   * +Optional;
   */
  textPrompt?: string
  /**
   * ThirdPartyChannel 第三方渠道;
   * +Optional;
   */
  thirdPartyChannel?: string
}

export interface V1CreateTaskWithActivityTemplateResponse {
  id?: string
}

export interface V1CreateTemplateRequest {
  /**
   * AspectRatio 图片宽高比;
   * +Required;
   */
  aspectRatio?: string
  /**
   * Category 模板分类;
   * +Required;
   */
  category?: string
  /**
   * ControlNet ControlNet 设置;
   * +Optional;
   */
  controlNet?: V1ControlNetSettings
  /**
   * CoverImage 封面图URL;
   * +Optional;
   */
  coverImage?: string
  /**
   * ExampleWorkIDs 示例作品ID.
   * +Required;
   */
  exampleWorkIDs?: string[]
  /**
   * Intro 模板介绍;
   * +Required;
   */
  intro?: string
  /**
   * Model 图片模型;
   * +Required;
   */
  model?: string
  /**
   * Name 模板名称;
   * +Required; 全局唯一;
   */
  name?: string
  /**
   * NegativePrompt 负面描述;
   * +Optional;
   */
  negativePrompt?: string
  /**
   * PrivatePrompt 私密描述;
   * +Optional;
   */
  privatePrompt?: string
  /**
   * Prompt 描述;
   * +Required;
   */
  prompt?: string
}

export interface V1CreateTemplateResponse {
  template?: V1TemplateData
}

export interface V1CreateThirdPartyUserRequest {
  /** ThirdPartyUserUID 渠道方的用户 ID */
  thirdPartyUserUID?: string
}

export interface V1CreateTicketReq {
  /** Category 帐号问题/BUG反馈/产品建议/内容举报 */
  category?: V1TicketCategory
  description?: string
  /** Images 图片链接 */
  images?: string[]
  /**
   * Theme
   * 帐号相关/订阅相关/创作相关/内容举报的举报理由...。
   */
  theme?: V1TicketTheme
  userID?: string
  /** 关联的作品 ID */
  workId?: string
}

export interface V1CurrentUserInfo {
  avatar?: string
  /** CanPrivateGeneration 是否可以使用隐私模式, 来自于账户数据; */
  canPrivateGeneration?: boolean
  /** CanSlowUnlimitedGeneration 是否可以使用慢速无限制生成, 来自于账户数据; */
  canSlowUnlimitedGeneration?: boolean
  /** CoinRemainingCount 剩余金币数量; */
  coinRemainingCount?: number
  /** CoinTotalCount 金币总数量; */
  coinTotalCount?: number
  /** ConsumePriority 优先消耗的余额类型; */
  consumePriority?: V1ConsumePriority
  cover?: string
  createdAt?: string
  /** FastGenerationRemainingCount 快速生成剩余次数; */
  fastGenerationRemainingCount?: number
  /**
   * FastGenerationTotalCount 快速生成总次数;
   * Note: 这里不会把加量包的次数算到这里;
   */
  fastGenerationTotalCount?: number
  /** FreeGenerationRemainingCount 免费生成次数; */
  freeGenerationRemainingCount?: number
  /** FreeGenerationTotalCount 免费生成总次数; */
  freeGenerationTotalCount?: number
  id?: string
  /** 是否为大家保险创作者 */
  isDaJiaBaoXianUser?: boolean
  /** IsTiamatUser 是否是 tiamat 公司内用户 */
  isTiamatUser?: boolean
  /** IsZhangjiangActor 是否为张江单页演示者 */
  isZhangjiangActor?: boolean
  /** IsZhangjiangUser 是否为张江单页用户 */
  isZhangjiangUser?: boolean
  /** 是否为第二版张江单页演示者 */
  isZhangjiangV2Actor?: boolean
  /** 是否为第二版张江单页用户 */
  isZhangjiangV2User?: boolean
  nick?: string
  /** 加量包剩余快速次数 */
  oneTimeSupplementRemainingCount?: number
  /** 加量包总快速次数 */
  oneTimeSupplementTotalCount?: number
  phone?: string
  /** 奖励剩余快速次数; */
  rewardRemainingCount?: number
  /** 奖励总快速次数; */
  rewardTotalCount?: number
  /** 已订阅的product type, 前端定制套餐列表按钮时可以使用 */
  subscribedProduct?: V1SubscribedProduct
}

export interface V1DailyCheckInRewardDesc {
  /** CanReceiv 是否可以领取每日签到奖励; */
  canReceive?: boolean
  /** DailyBonus 每日签到奖励; */
  dailyBonus?: V1Bonus
}

export interface V1DecreaseMPNotificationCountRequest {
  /**
   * MessageTemplateID 微信订阅消息模板ID;
   * +Required;
   */
  messageTemplateID?: V1MPNotificationTemplate
  /**
   * Platform 区分平台渠道，微信小程序:1,抖音小程序:2
   * +Required;
   */
  platform?: number
  /**
   * UserUID 用户唯一ID;
   * +Required;
   */
  userID?: string
}

export type V1DecreaseMPNotificationCountResponse = object

export type V1DeleteActivityTemplateResponse = object

export enum V1DiscountCodeType {
  DiscountCodeTypeUserShareSubscription = "UserShareSubscription",
  DiscountCodeTypeBusinessOperation = "BusinessOperation",
}

export interface V1DouyinLoginRequest {
  /**
   * AnonymousCode 匿名登录凭证
   * +Optional;
   */
  anonymousCode?: string
  /**
   * Code 登录凭证
   * +Required;
   */
  code?: string
}

export interface V1DouyinLoginResponse {
  currentUserInfo?: V1CurrentUserInfo
  openID?: string
}

export interface V1DuoBaAnRedeemCode {
  bonus?: V1Bonus
  code?: string
}

export type V1GenerateCaptchaRequest = object

export interface V1GenerateCaptchaResponse {
  /** 验证码ID */
  captchaID?: string
  /** 验证码图片base64 */
  captchaImage?: string
}

export interface V1GenreScore {
  /** 色彩 */
  colorScore?: number
  /** 构图 */
  compositionScore?: number
  /** 完整性 */
  intergrityScore?: number
  /** 明暗层次 */
  lightShadowScore?: number
  /** 整体 */
  overallScore?: number
  /** 锐化 */
  sharpnessScore?: number
}

export interface V1GetActivityForAdminResponse {
  activity?: V1Activity
}

export interface V1GetActivityResponse {
  activity?: V1Activity
}

export interface V1GetActivityTemplateForAdminResponse {
  /**
   * AbstractModel 运营编辑生图模型;
   * +Optional;
   */
  abstractModel?: string
  /**
   * ActivityTemplateUID 当前活动类别ID
   * +Required
   */
  activityTemplateUID?: string
  createAt?: number
  /**
   * Creator 创建或修改管理员ID;
   * +Required;
   */
  creator?: string
  /**
   * EnableAbstractModel 梅花村活动页前端是否允许用户编辑生图模型;
   * +Required;
   */
  enableAbstractModel?: boolean
  /**
   * EnableCustom 梅花村活动页前端是否对用户显示controlNet入口;
   * +Required;
   */
  enableCustom?: boolean
  /**
   * EnableCustom 梅花村活动页前端是否允许用户编辑Prompt;
   * +Required;
   */
  enablePrompt?: boolean
  /**
   * EnableRatio 梅花村活动页前端是否允许用户编辑生图比例;
   * +Required;
   */
  enableRatio?: boolean
  /**
   * FixedPrompt 运营输入的固定Prompt，生图任务中固定拼接，长度小于4096;
   * +Optional;
   */
  fixedPrompt?: string
  /**
   * OfficialControlNet 官方示意图关联的controlNet;
   * +Optional;
   */
  officialControlNet?: V1ControlNetSettings
  /**
   * OfficialControlNet 官方示意图关联的controlNet;
   * +Required;
   */
  officialImageURLs?: string[]
  /**
   * RandomPrompts 运营输入的随机Prompt数组,文本总长度小于4096
   * +Required;
   */
  randomPrompts?: string[]
  /**
   * Ratio 运营配置的生图比例;
   * +Optional;
   */
  ratio?: string
  /**
   * Title 活动类别名称，长度小于10;
   * +Required;
   */
  title?: string
  updateAt?: number
  /**
   * UserControlNet 用户上传图片预设的controlNet，不包含inputImageUrl；
   * +Optional;
   */
  userControlNet?: V1ControlNetSettings
}

export interface V1GetActivityTemplateForUserResponse {
  /**
   * AbstractModel 运营编辑生图模型;
   * +Optional;
   */
  abstractModel?: string
  /**
   * ActivityTemplateUID 当前活动类别ID
   * +Required
   */
  activityTemplateUID?: string
  createAt?: number
  /**
   * EnableAbstractModel 梅花村活动页前端是否允许用户编辑生图模型;
   * +Required;
   */
  enableAbstractModel?: boolean
  /**
   * EnableCustom 梅花村活动页前端是否对用户显示controlNet入口;
   * +Required;
   */
  enableCustom?: boolean
  /**
   * EnableCustom 梅花村活动页前端是否允许用户编辑Prompt;
   * +Required;
   */
  enablePrompt?: boolean
  /**
   * EnableRatio 梅花村活动页前端是否允许用户编辑生图比例;
   * +Required;
   */
  enableRatio?: boolean
  /**
   * OfficialControlNet 官方示意图关联的controlNet;
   * +Optional;
   */
  officialControlNet?: V1ControlNetSettings
  /**
   * OfficialControlNet 官方示意图关联的controlNet;
   * +Required;
   */
  officialImageURLs?: string[]
  /**
   * Ratio 运营配置的生图比例;
   * +Optional;
   */
  ratio?: string
  /**
   * Title 活动类别名称，长度小于10;
   * +Required;
   */
  title?: string
  /**
   * UserControlNet 用户上传图片预设的controlNet，不包含inputImageUrl；
   * +Optional;
   */
  userControlNet?: V1ControlNetSettings
}

export interface V1GetActivityUserStatusResponse {
  /** ActivityUserStatus 用户的活动状态; */
  activityUserStatus?: string
}

export interface V1GetCollectionByUserIDResponse {
  collection?: V1Collection
  current?: number
  pageSize?: number
  total?: number
}

export interface V1GetCollectionResponse {
  collection?: V1Collection
  current?: number
  pageSize?: number
  total?: number
}

export interface V1GetLoginVerificationCode {
  /** 短信验证码 */
  verificationCode?: string
}

export interface V1GetLoginVerificationCodeRequest {
  captchaID?: string
  imageCode?: string
  /** 手机号 */
  phone?: string
}

export interface V1GetMyTemplateResponse {
  template?: V1TemplateData
}

export interface V1GetReleasedTemplateResponse {
  template?: V1TemplateData
}

export interface V1GetStudioSettingsResponse {
  /** FastGenEditable 快速生成是否可编辑; */
  fastGenEditable?: boolean
  /** FastGenEnabled 快速生成是否开启; */
  fastGenEnabled?: boolean
  /** PrivateGenEditable 私有生成是否编辑; */
  privateGenEditable?: boolean
  /** PrivateGenEnabled 私有生成是否开启; */
  privateGenEnabled?: boolean
}

export type V1GetSubscriptionDiscountRequest = object

export interface V1GetURLSchemeRequest {
  /**
   * Params
   * + optional
   */
  params?: string
  /**
   * Path
   * + optional
   */
  path?: string
}

export interface V1GetURLSchemeResponse {
  errcode?: number
  errmsg?: string
  openlink?: string
}

export interface V1GetUnlimitedQRCodeRequest {
  /**
   * Page 小程序页面，默认主页
   * + optional
   */
  page?: string
  /**
   * Scene
   * + optional
   */
  scene?: string
}

export interface V1GetUnlimitedQRCodeResponse {
  buffer?: number[]
  errcode?: number
  errmsg?: string
}

export interface V1GetUserAnalyticsResponse {
  items?: V1UserAnalyticsItem[]
}

export interface V1GetUserBasicInfoResponse {
  avatar?: string
  cover?: string
  createdAt?: string
  id?: string
  /** 是否为大家保险创作者 */
  isDaJiaBaoXianUser?: boolean
  /** IsNoWatermarkUser 是否是无水印用户 */
  isNoWatermarkUser?: boolean
  /** IsTiamatUser 是否是 tiamat 公司内用户 */
  isTiamatUser?: boolean
  /** IsZhangjiangActor 是否为张江单页演示者 */
  isZhangjiangActor?: boolean
  /** IsZhangjiangUser 是否为张江单页用户 */
  isZhangjiangUser?: boolean
  /** 是否为第二版张江单页演示者 */
  isZhangjiangV2Actor?: boolean
  /** 是否为第二版张江单页用户 */
  isZhangjiangV2User?: boolean
  nick?: string
  /** 已订阅的product type, 前端定制套餐列表按钮时可以使用 */
  subscribedProduct?: V1SubscribedProduct
}

export interface V1GetUserByThirdPartyUserUIDResponse {
  /** DailyGenerationCountRemaining 今日剩余生成次数; */
  dailyGenerationCountRemaining?: number
  /**
   * GenerationCountRemainingTotal 剩余生成次数总计;
   * 如果为 -1 表示不限次数;
   */
  generationCountRemainingTotal?: number
  /**
   * PaidGenerationCountRemaining 付费剩余生成次数;
   * 如果为 -1 表示不限次数;
   */
  paidGenerationCountRemaining?: number
  /** PremiumExpiredAt 包月套餐过期时间. 秒级时间戳; */
  premiumExpiredAt?: number
  /**
   * 第三方渠道用户在 xica 中的唯一用户ID
   * Note: 渠道方使用接口时，无需传递该字段，而是使用渠道方的用户UID即可
   */
  userUID?: string
}

export interface V1GetUserCoinInfoResponse {
  /** CoinRemainingCount 剩余金币数量 */
  coinRemainingCount?: number
  /** CoinTotalCount 金币总数量 */
  coinTotalCount?: number
  /** HasSuccessPaidOrder 当前用户是否有支付成功的消费订单 */
  hasSuccessPaidOrder?: boolean
  /** CoinRedeemList 兑换权益列表 */
  redeemBonus?: V1CoinRedeemInfo[]
}

export interface V1GetUserProfileResponse {
  avatar?: string
  cover?: string
  createdAt?: string
  id?: string
  nick?: string
  workCount?: number
}

export interface V1ImageMeta {
  format?: string
  height?: number
  width?: number
}

export interface V1ImagePromptResp {
  createdAt?: string
  id?: string
  priority?: V1TaskPriority
  /** enum: pending, running, success, failed; if failed, error message will be stored in statusMessage(与图片生成任务的状态一样) */
  status?: V1TaskStatus
  textPrompt?: string
}

export type V1IsTemplateNameAvailableResponse = object

export type V1LikeCollectionResponse = object

export interface V1LikeWorkRequest {
  /**
   * CollectionUID 相册UID;
   * +Required;
   */
  collectionUID?: string
}

export interface V1ListActivitiesForAdminResponse {
  activities?: V1Activity[]
}

export interface V1ListActivitiesForHomePageResponse {
  activities?: V1Activity[]
}

export interface V1ListActivityTemplateForAdminResponse {
  activityTemplates?: V1ActivityTemplateForAdmin[]
  total?: number
}

export interface V1ListActivityTemplateForUserResponse {
  activityTemplates?: V1ActivityTemplateForUser[]
  total?: number
}

export interface V1ListAllCollectionResponse {
  current?: number
  list?: V1Collection[]
  pageSize?: number
  total?: number
}

export interface V1ListBusyTaskResponse {
  /** 当前用户正在pending 或 running 的任务数量 */
  busyTaskCount?: number
  /** 当前用户正在pending 或 running 的任务列表 */
  list?: V1Task[]
}

export interface V1ListCategoryResponse {
  current?: number
  list?: V1Category[]
  pageSize?: number
  total?: number
}

export interface V1ListCollectionsByUserIDResponse {
  current?: number
  list?: V1Collection[]
  pageSize?: number
  total?: number
}

export interface V1ListLikeWorksByUserIDResponse {
  current?: number
  list?: V1Work[]
  pageSize?: number
  total?: number
}

export interface V1ListMyCollectionResponse {
  current?: number
  list?: V1Collection[]
  pageSize?: number
  total?: number
}

export interface V1ListMyTaskGroupsResponse {
  current?: number
  list?: V1TaskGroup[]
  pageSize?: number
  total?: number
}

export interface V1ListMyTasksResponse {
  current?: number
  list?: V1Task[]
  pageSize?: number
  total?: number
}

export interface V1ListMyTemplatesResponse {
  templates?: V1TemplateData[]
  total?: number
}

export interface V1ListNotificationResponse {
  current?: number
  list?: V1Notification[]
  pageSize?: number
  total?: number
}

export interface V1ListOrdersResponse {
  current?: number
  list?: V1Order[]
  pageSize?: number
  total?: number
}

export interface V1ListReceivedDailyCheckInUsersResponse {
  /** UserIDs 用户ID; */
  userIDs?: string[]
}

export interface V1ListReleasedTemplatesResponse {
  templates?: V1TemplateData[]
}

export interface V1ListRewardRecordsResponse {
  current?: number
  list?: V1RewardRecord[]
  pageSize?: number
  total?: number
}

export interface V1ListRewardsResponse {
  /** DailyCheckInReward 每日签到奖励; */
  dailyCheckInReward?: V1DailyCheckInRewardDesc
  /** RegistInvitationReward 注册邀请奖励; */
  registInvitationReward?: V1RegistInvitationRewardDesc
  /** SubscribeInvitationReward 订阅邀请奖励; */
  subscribeInvitationReward?: V1SubscribeInvitationRewardDesc
  /** TemplateHeatReachTargetReward 模板热度达标奖励; */
  templateHeatReachTargetReward?: V1ReachTargetRewardDesc
  /** TemplateSubmissionCountReachTargetReward 模板提交数量达标奖励; */
  templateSubmissionCountReachTargetReward?: V1ReachTargetRewardDesc
  /** TemplateSubmissionReward 模板提交奖励; */
  templateSubmissionReward?: V1TemplateSubmissionRewardDesc
  /** UserGenerationCountReachTargetReward 用户生成数量达标奖励; */
  userGenerationCountReachTargetReward?: V1ReachTargetRewardDesc
}

export interface V1ListSceneResponse {
  current?: number
  list?: V1Scene[]
  pageSize?: number
  total?: number
}

export interface V1ListSensitiveWordResponse {
  words?: string[]
}

export interface V1ListTasksByUserIDResponse {
  current?: number
  list?: V1Task[]
  pageSize?: number
  total?: number
}

export interface V1ListTasksResponse {
  current?: number
  list?: V1Task[]
  pageSize?: number
  total?: number
}

export interface V1ListTemplateCategoriesResponse {
  categories?: V1TemplateCategoryMeta[]
}

export interface V1ListTicketsResponse {
  current?: number
  list?: V1Ticket[]
  pageSize?: number
  total?: number
}

export interface V1ListUnscoredWorkResponse {
  current?: number
  list?: V1UnScoredWork[]
  pageSize?: number
  total?: number
}

export interface V1ListUserDiscountCodesResponse {
  items?: V1UserDiscountCodeItem[]
}

export interface V1ListUserInviteCodesResponse {
  items?: V1UserInviteCodeItem[]
}

export interface V1ListUsersForMPNotificationResponse {
  userinfo?: V1MPNotificationUserinfo[]
}

export interface V1ListWorkResponse {
  current?: number
  list?: V1Work[]
  pageSize?: number
  total?: number
}

export interface V1LoginByPhone {
  phone: string
  verificationCode: string
}

export interface V1LoginByPhoneQCRequest {
  /**
   * ChannelUserID qc渠道用户id
   * + required
   */
  channelUserID?: string
  /**
   * Phone 用户的手机号码
   * + required
   */
  phone?: string
  /**
   * VerificationCode 用户的验证码
   * + required
   */
  verificationCode?: string
}

export interface V1LoginByPhoneWithInviteCodeRequest {
  /**
   * 邀请码，需要md5加密
   * var CryptoJS = require("crypto-js");
   * let code = "0000"
   * let hash =  CryptoJS.MD5(code);
   * CryptoJS.enc.Base64.stringify(hash)
   */
  inviteCode: string
  phone: string
  verificationCode: string
}

export enum V1MPNotificationTemplate {
  ActivityStartTemplate = "VG-X0SY9DiXva52G2hbeGAFTrzZQiWv6bV2EsnM_8qo",
  ActivityEndTemplate = "vdR-KyDlbFKOrM0izI7upuDJRbEd-QsFiPrG01xup3g",
  DailyCheckInTemplate = "AhazXhJj45gt3zgr64spIjiFMW4wsvBNBYZPA3IMR_E",
  AISystemUpdateTemplate = "WCuerLKHcjVI4CBwgvgyX444zHtx1XbGZuYfzdX0ruY",
}

export interface V1MPNotificationUserinfo {
  /** OaOpenID 微信小程序唯一ID; */
  oaOpenID?: string
  /** UserUID 用户唯一ID; */
  userID?: string
}

export interface V1MPResetNotificationCountRequest {
  /**
   * MessageTemplateID 微信订阅消息模板ID;
   * +Required;
   */
  messageTemplateID?: V1MPNotificationTemplate
  /**
   * Platform 区分平台渠道，微信小程序:1,抖音小程序:2
   * +Required;
   */
  platform?: number
  /**
   * UserUID 用户唯一ID;
   * +Required;
   */
  userID?: string
}

export type V1MPResetNotificationCountResponse = object

export interface V1MPUserSubscribeNotificationRequest {
  /**
   * Platform 区分平台渠道，微信小程序:1,抖音小程序:2
   * +Required;
   */
  platform?: number
  /**
   * TemplateIDs 消息订阅模版ID;
   * + Required;
   */
  templateIDs?: V1MPNotificationTemplate[]
  /**
   * UserUID 用户唯一ID;
   * +Required;
   */
  userID?: string
}

export interface V1MPUserSubscribeNotificationResponse {
  result?: string
}

export type V1ManualOfflineActivityResponse = object

export interface V1Notification {
  content?: string
  cover?: string
  createdAt?: number
  id?: number
  isRead?: boolean
  thumb?: string
  title?: string
  type?: number
}

export interface V1Order {
  description?: string
  /** -1 表示永不过期 */
  expiredAt?: number
  orderId?: string
  orderTitle?: string
  /** 支付方式，enum: 1:默认,微信native扫码; */
  paymentType?: number
  productAmount?: number
  productId?: number
  productPrice?: number
  productTitle?: string
  startAt?: number
  totalFee?: number
}

export interface V1ProductInfo {
  id?: number
  /** 商品价格, 人民币分 */
  price?: number
  subscribeCategory?: string
  /** 商品订阅类型. 10入门单月 11入门包年 12入门连续包月 20标准包月 21标准包年 22标准连续包月 30进阶包月 31进阶包年 32进阶连续包月 40联名模型单月" */
  subscribeType?: number
  title?: string
}

export interface V1ReGenerateRequest {
  /**
   * UserUID 用户UID;
   * + Required;
   */
  userUID?: string
  /**
   * workId 作品ID;
   * + Required;
   */
  workId?: string
}

export interface V1ReGenerateRequestForGuest {
  /**
   * workId 作品ID;
   * + Required;
   */
  workId?: string
}

export interface V1ReachTargetRewardDesc {
  /** Current 当前数据; */
  current?: number
  /** NextTarget 下一个目标; */
  nextTarget?: V1RewardTargetDesc
  /** ReceivableTargets 可领取的目标; */
  receivableTargets?: V1RewardTargetDesc[]
}

export interface V1ReceiveActivityRewardRequest {
  /**
   * ActivityUID 活动 UID;
   * +Required;
   */
  activityUID?: string
}

export interface V1ReceiveActivityRewardResponse {
  /** Bonus 奖励内容; */
  bonus?: V1Bonus
}

export interface V1ReceiveDailyCheckInRewardResponse {
  /** Bonus 奖励内容; */
  bonus?: V1Bonus
}

export interface V1ReceiveRegistInvitationRewardResponse {
  /** Bonus 奖励内容; */
  bonus?: V1Bonus
}

export interface V1ReceiveSubscribeInvitationRewardResponse {
  /** Bonus 奖励内容; */
  bonus?: V1Bonus
}

export interface V1ReceiveTemplateHeatTargetRewardResponse {
  /** 领取的达标的奖励内容; */
  targets?: V1RewardTargetDesc[]
}

export interface V1ReceiveTemplateSubmissionCountTargetRewardResponse {
  /** 领取的达标的奖励内容; */
  targets?: V1RewardTargetDesc[]
}

export interface V1ReceiveTemplateSubmissionRewardResponse {
  /** Bonus 奖励内容; */
  bonus?: V1Bonus
}

export interface V1ReceiveUserGenerationCountTargetRewardResponse {
  /** 领取的达标的奖励内容; */
  targets?: V1RewardTargetDesc[]
}

export interface V1RedeemCodeToRewardResponse {
  /** Bonus 奖励内容; */
  bonus?: V1Bonus
}

export type V1RedeemCoinResponse = object

export interface V1RegistInvitationRewardDesc {
  /** CurrentCount 当前邀请人数; */
  currentCount?: number
  /** MaxCount 用户邀请奖励最大领取次数; */
  maxCount?: number
  /** PerBonus 单个邀请注册的奖励; */
  perBonus?: V1Bonus
  /** ReceivableCount 可领取的邀请奖励次数; */
  receivableCount?: number
}

export enum V1RegistSourceV2 {
  RegistSourceV2Unknown = "",
  RegistSourceV2Self = "self",
  RegistSourceV2InviteCode = "inviteCode",
  RegistSourceV2Metro = "metro",
  RegistSourceV2Baidu = "baidu",
  RegistSourceV2XHS = "xhs",
  RegistSourceV2DY = "dy",
  RegistSourceV2QQ1 = "qq1",
  RegistSourceV2MiniProgram = "miniProgram",
}

export interface V1RegisterByPhone {
  inviteCode?: string
  phone: string
  /**
   * SourceV2 注册渠道;
   * + Optional;
   */
  sourceV2?: V1RegistSourceV2
  verificationCode: string
}

export enum V1RewardKind {
  RewardKindSubscribeBasicMonthly = "SubscribeBasicMonthly",
  RewardKindSubscribeBasicYearly = "SubscribeBasicYearly",
  RewardKindSubscribeStandardMonthly = "SubscribeStandardMonthly",
  RewardKindSubscribeStandardYearly = "SubscribeStandardYearly",
  RewardKindVIP200Yearly = "VIP200Yearly",
  RewardKindVIP400Yearly = "VIP400Yearly",
  RewardKindSubscribePremiumMonthly = "SubscribePremiumMonthly",
  RewardKindSubscribePremiumYearly = "SubscribePremiumYearly",
  RewardKindSubscribeCompensation = "SubscribeCompensation",
  RewardKindBuyOneTimeSupplement = "BuyOneTimeSupplement",
  RewardKindDailyCheckIn = "DailyCheckIn",
  RewardKindRegistInvitation = "RegistInvitation",
  RewardKindSubscribeInvitation = "SubscribeInvitation",
  RewardKindTemplateSubmission = "TemplateSubmission",
  RewardKindTemplateSubmissionReach1 = "TemplateSubmissionReach1",
  RewardKindTemplateSubmissionReach5 = "TemplateSubmissionReach5",
  RewardKindTemplateSubmissionReach10 = "TemplateSubmissionReach10",
  RewardKindTemplateSubmissionReach50 = "TemplateSubmissionReach50",
  RewardKindTemplateSubmissionReach100 = "TemplateSubmissionReach100",
  RewardKindTemplateHeatReach1K = "TemplateHeatReach1k",
  RewardKindTemplateHeatReach10K = "TemplateHeatReach10k",
  RewardKindTemplateHeatReach30K = "TemplateHeatReach30k",
  RewardKindTemplateHeatReach100K = "TemplateHeatReach100k",
  RewardKindTemplateHeatReach200K = "TemplateHeatReach200k",
  RewardKindUserGenCountReach100 = "UserGenCountReach100",
  RewardKindUserGenCountReach1K = "UserGenCountReach1k",
  RewardKindUserGenCountReach2K = "UserGenCountReach2k",
  RewardKindUserGenCountReach5K = "UserGenCountReach5k",
  RewardKindUserGenCountReach10K = "UserGenCountReach10k",
  RewardKindUserGenCountReach50K = "UserGenCountReach50k",
  RewardKindUserGenCountReach100K = "UserGenCountReach100k",
  RewardKindCompensation = "Compensation",
  RewardKindFreeGift = "FreeGift",
  RewardKindRedeemCode = "RedeemCode",
  RewardKindActivity = "Activity",
  RewardKindBuyNormalCoinCount80 = "BuyNormalCoinCount80",
  RewardKindBuyNormalCoinCount200 = "BuyNormalCoinCount200",
  RewardKindBuyNormalCoinCount500 = "BuyNormalCoinCount500",
  RewardKindBuyNormalCoinCount1200 = "BuyNormalCoinCount1200",
  RewardKindBuySpecialCoinCount60 = "BuySpecialCoinCount60",
  RewardKindRedeemCoin = "RedeemCoin",
}

export interface V1RewardRecord {
  /** Bonus 奖励内容; */
  bonus?: V1Bonus
  /** Kind 奖励类型; */
  kind?: V1RewardKind
  /** ReceivedAt 领取时间 */
  receivedAt?: number
}

export interface V1RewardTargetDesc {
  /** Bonus 达标的奖励内容; */
  bonus?: V1Bonus
  /** Target 目标值; */
  target?: number
}

export interface V1SDExecArtifact {
  /** ElapseInMilliseconds 产出该产物的耗时, 单位: 毫秒; */
  elapseInMilliseconds?: number
  /**
   * Error 错误信息;
   * 如果正常生成, 则 Error 为 "";
   */
  error?: string
  /** Images 产出的图片; */
  images?: string[]
  /** PayloadID 该产物对应的 payload ID; */
  payloadID?: string
  /** Seed 跑图种子; */
  seed?: number
}

export interface V1SDExecCallback {
  /**
   * Artifacts 产物;
   * +Required;
   */
  artifacts?: V1SDExecArtifact[]
  /**
   * ElapseInMilliseconds 耗时, 单位: 毫秒;
   * +Optional;
   */
  elapseInMilliseconds?: number
  /**
   * RequestID 请求 ID;
   * +Required;
   */
  requestID?: string
  /**
   * TaskID 数据库中的任务 ID;
   * +Required;
   */
  taskID?: string
}

export interface V1Scene {
  id?: string
  name?: string
}

export interface V1Scores {
  colorScore?: number
  compositionScore?: number
  intergrityScore?: number
  lightShadowScore?: number
  overallScore?: number
  sharpnessScore?: number
}

export interface V1SetIsPrivateRequest {
  /**
   * IsPrivate
   * + Required;
   */
  isPrivate: boolean
}

export interface V1SetWorkIsPrivateRequest {
  /**
   * IsPrivate
   * + Required;
   */
  isPrivate?: boolean
}

export interface V1SubscribeInvitationRewardDesc {
  /** CurrentCount 当前邀请人数; */
  currentCount?: number
  /** MaxCount 用户邀请奖励最大领取次数; */
  maxCount?: number
  /** PerBonus 单个邀请订阅的奖励; */
  perBonus?: V1Bonus
  /** ReceivableCount 可领取的邀请奖励次数; */
  receivableCount?: number
}

export interface V1SubscribeProductRequest {
  /** 优惠码 */
  discountCode?: string
  /** 商品数量，一般为1，加量包可以大于1 */
  productAmount?: number
}

export interface V1SubscribedProduct {
  /** 当前订阅的结束时间，-1 表示永不过期 */
  expiredAt?: number
  id?: number
  /** 商品价格, 人民币分 */
  price?: number
  /** 当前订阅的开始时间 */
  startAt?: number
  subscribeCategory?: string
  /** 商品订阅类型. 10入门单月 11入门包年 12入门连续包月 20标准包月 21标准包年 22标准连续包月 30进阶包月 31进阶包年 32进阶连续包月 40联名模型单月" */
  subscribeType?: number
  title?: string
}

export interface V1Task {
  /** 任务参与的活动ID */
  activityUID?: string
  /** ControlNet */
  controlNet?: V1ControlNetSettings
  createdAt?: string
  /** 任务耗时，单位毫秒 */
  elapseMillseconds?: number
  genreScore?: V1GenreScore
  id?: string
  /** 创建任务时的提交的图片数量 */
  imageCount?: number
  /** 是否为屠龙群用户数据，屠龙群task Prompt 为空 */
  isDragon?: boolean
  /** 是否为当前活动的第一个成功任务 */
  isFirstSuccessTaskForActivity?: boolean
  /** 是否是当前用户的任务 */
  isMine?: boolean
  /** 创建任务时，是否设置了仅自己可见 */
  isPrivate?: boolean
  /** 仅首页有数据 */
  likeCount?: number
  /** 如果model 字段为空，前端显示内测模型 */
  model?: string
  negativeTextPrompt?: string
  /** 任务优先级 */
  priority?: V1TaskPriority
  privatePrompt?: boolean
  ratio: string
  referPower?: number
  referencedImage?: string
  /** 仅首页有数据 */
  score?: number
  /** enum: pending, running, success, failed; if failed, error message will be stored in statusMessage */
  status?: V1TaskStatus
  /** 任务使用的模板ID */
  templateID?: number
  textPrompt: string
  /** 第三方渠道 */
  thirdPartyChannel?: string
  user?: V1TaskUserInfo
  /** StatusMessage      string          `json:"statusMessage"` */
  works?: V1Work[]
}

export interface V1TaskGroup {
  /** ControlNet 设置 */
  controlNet?: V1ControlNetSettings
  createdAt?: string
  /** 是否为屠龙群用户数据，屠龙群task Prompt 为空 */
  isDragon?: boolean
  md5?: string
  model?: string
  models?: string[]
  negativeTextPrompt?: string
  privatePrompt?: boolean
  referencedImage?: string
  score?: number
  templateID?: number
  textPrompt?: string
  user?: V1TaskUserInfo
  works?: V1Work[]
}

export enum V1TaskPriority {
  TaskPriorityHigh = "high",
  TaskPriorityNormal = "normal",
  TaskPriorityLow = "low",
}

export enum V1TaskStatus {
  TaskStatusPending = "pending",
  TaskStatusRunning = "running",
  TaskStatusSuccess = "success",
  TaskStatusFailed = "failed",
}

export interface V1TaskUserInfo {
  avatar?: string
  id?: string
  nick?: string
}

export enum V1TemplateCategory {
  TemplateCategoryUnknown = "",
  TemplateCategoryCharacter = "character",
  TemplateCategoryStyle = "style",
  TemplateCategoryBuilding = "building",
  TemplateCategoryAnimal = "animal",
  TemplateCategoryPlant = "plant",
  TemplateCategoryScene = "scene",
  TemplateCategoryObject = "object",
  TemplateCategoryOthers = "others",
}

export interface V1TemplateCategoryMeta {
  category?: V1TemplateCategory
  displayName?: string
}

export interface V1TemplateData {
  /** AspectRatio 图片宽高比; */
  aspectRatio?: string
  /** Category 模板分类; */
  category?: string
  /** ControlNet ControlNet 设置; */
  controlNet?: V1ControlNetSettings
  /** CoverImage 封面图URL; */
  coverImage?: string
  /** CreatedAt 创建时间; */
  createdAt?: number
  /** CumUsedCount 累计使用次数; */
  cumUsedCount?: number
  /** CumUsedUserCount 累计使用用户数; */
  cumUsedUserCount?: number
  /** ExampleImages 示例作品图. */
  exampleImages?: string[]
  /** HeatValue 热度值; */
  heatValue?: number
  /** ID 模板ID; */
  id?: number
  /** Intro 模板介绍; */
  intro?: string
  /** IsRecommand 是否为推荐模板; */
  isRecommand?: boolean
  /** Model 图片模型; */
  model?: string
  /** Name 模板名称; */
  name?: string
  /** NegativePrompt 负面描述; */
  negativePrompt?: string
  /** PrivatePrompt 私密描述; */
  privatePrompt?: string
  /** Prompt 描述; */
  prompt?: string
  /** RecommandAt 推荐编辑时间; */
  recommandAt?: number
  /** ReleaseState 模板发布状态; */
  releaseState?: V1TemplateReleaseState
  /** ReviewRejectReason 审核拒绝原因; */
  reviewRejectReason?: string
  /** ReviewState 模板审核状态; */
  reviewState?: V1TemplateReviewState
  /** ReviewedAt 审核时间; */
  reviewedAt?: number
  /** Reviewer 审核人; */
  reviewer?: string
  /** UserAvatar 用户头像; */
  userAvatar?: string
  /** UserNickName 用户昵称; */
  userNickName?: string
  /** UserUID 用户UID; */
  userUID?: string
}

export enum V1TemplateReleaseState {
  TemplateReleaseStateReleased = "released",
  TemplateReleaseStateUnreleased = "unreleased",
}

export enum V1TemplateReviewState {
  TemplateReviewStatePending = "pending",
  TemplateReviewStatePass = "pass",
  TemplateReviewStateReject = "reject",
}

export interface V1TemplateSubmissionRewardDesc {
  /** CurrentCount 当前提交次数; */
  currentCount?: number
  /** MaxCount 用户提交奖励最大领取次数; */
  maxCount?: number
  /** PerBonus 单个提交奖励; */
  perBonus?: V1Bonus
  /** ReceivableCount 可领取的提交奖励次数; */
  receivableCount?: number
}

export interface V1ThirdPartyReceiveDailyCheckInRewardRequest {
  /**
   * Bonus
   * + Required;
   */
  bonus?: V1Bonus
  /**
   * UserUID 梅花村用户 ID;
   * + Required;
   */
  userUID?: string
}

export interface V1ThirdPartyReceiveDailyCheckInRewardResponse {
  bonus?: V1Bonus
}

export interface V1ThirdPartyReceiveFreeGiftRewardRequest {
  /**
   * Bonus
   * + Required;
   */
  bonus?: V1Bonus
  /**
   * Comment 备注;
   * + Require;
   */
  comment?: string
  /**
   * OperationID 操作ID;
   * + Required;
   */
  operationID?: string
  /**
   * UserUID 梅花村用户 ID;
   * + Required;
   */
  userUID?: string
}

export interface V1ThirdPartyReceiveFreeGiftRewardResponse {
  bonus?: V1Bonus
}

export interface V1ThirdPartyReceiveOneTimeSupplementRequest {
  /**
   * Bonus
   * + Required;
   */
  bonus?: V1Bonus
  /**
   * OrderID 订单号;
   * + Required;
   */
  orderID?: string
  /**
   * UserUID 梅花村用户 ID;
   * + Required;
   */
  userUID?: string
}

export interface V1ThirdPartyReceiveOneTimeSupplementResponse {
  bonus?: V1Bonus
}

export interface V1ThirdPartyReceiveSubscriptionRequest {
  /**
   * Bonus
   * + Required;
   */
  bonus?: V1Bonus
  /**
   * OrderID 订单号;
   * + Required;
   */
  orderID?: string
  /**
   * userUID 梅花村用户 ID;
   * + Required;
   */
  userUID?: string
}

export interface V1ThirdPartyReceiveSubscriptionResponse {
  bonus?: V1Bonus
}

export interface V1Ticket {
  category?: V1TicketCategory
  completedAt?: number
  createdAt?: number
  description?: string
  images?: string[]
  ownerId?: string
  status?: V1TicketStatus
  theme?: string
  uid?: string
}

export enum V1TicketCategory {
  TicketCategoryAccount = "account",
  TicketCategoryBug = "bug",
  TicketCategorySuggestion = "suggestion",
  TicketCategoryReport = "report",
}

export enum V1TicketStatus {
  TicketStatusPending = "pending",
  TicketStatusProcessing = "processing",
  TicketStatusCompleted = "completed",
}

export enum V1TicketTheme {
  TicketThemeAccountRelated = "accountRelated",
  TicketThemeSubscribeRelated = "subscribeRelated",
  TicketThemeComplaintRelated = "complaintRelated",
  TicketThemeAdviceRelated = "adviceRelated",
  TicketThemeCreationRelated = "creationRelated",
  TicketThemeDataRelated = "dataRelated",
  TicketThemeSystemRelated = "systemRelated",
  TicketThemePornographicVulgar = "pornographicVulgar",
  TicketThemeViolationOfLawsAndRegulations = "violationOfLawsAndRegulations",
  TicketThemeEndangerNationalSecurity = "endangerNationalSecurity",
  TicketThemeFalseInformation = "falseInformation",
  TicketThemeDiscomfort = "discomfort",
  TicketThemeBadValueGuide = "badValueGuide",
  TicketThemeAbuseOfMinors = "abuseOfMinors",
  TicketThemeViolationofRightsAndInterests = "violationofRightsAndInterests",
  TicketThemeOther = "other",
}

export type V1TopActivityResponse = object

export type V1UnLikeCollectionResponse = object

export interface V1UnLikeWorkRequest {
  /**
   * CollectionUID 相册UID;
   * +Required;
   */
  collectionUID?: string
}

export interface V1UnScoredWork {
  id?: string
  image?: string
}

export interface V1UpdateActivityRequest {
  /**
   * ActivityUID 活动 UID;
   * +Required;
   */
  activityUID?: string
  /**
   * BannerImages 活动横幅图片链接;
   * +Optional;
   */
  bannerImages?: string[]
  /**
   * Bonus 活动奖励;
   * + Optional;
   */
  bonus?: V1Bonus
  /**
   * DisplayAt 活动显示时间;
   * +Optional;
   */
  displayAt?: number
  /**
   * EndAt 活动结束时间;
   * +Optional;
   */
  endAt?: number
  /**
   * Long 活动详细介绍;
   * +Optional;
   */
  long?: string
  /**
   * RedirectURL 活动跳转链接;
   * +Optional;
   */
  redirectURL?: string
  /**
   * Short 活动简介;
   * +Optional;
   */
  short?: string
  /**
   * StartAt 活动开始时间;
   * +Optional;
   */
  startAt?: number
  /**
   * Title 活动标题;
   * +Optional;
   */
  title?: string
  /**
   * Type 活动类型:template(模板类型)|task(图片类型);
   * +Optional;
   */
  type?: string
}

export interface V1UpdateActivityResponse {
  activity?: V1Activity
}

export interface V1UpdateCollectionRequest {
  /**
   * CollectionUID 相册UID;
   * +Required;
   */
  collectionUID?: string
  /**
   * CoverImage 封面图;
   * + Optional;
   */
  coverImage?: string
  /**
   * Intro 相册介绍;
   * +Optional;
   */
  intro?: string
  /**
   * IsPrivate 是否私密;
   * + Optional;
   */
  isPrivate?: boolean
  /**
   * Title 相册名称;
   * +Optional;
   */
  title?: string
}

export interface V1UpdateCollectionResponse {
  collection?: V1Collection
}

export interface V1UpdateConsumePriorityRequest {
  /**
   * ConsumePriority 消耗余额优先级;
   * + Required;
   */
  consumePriority?: V1ConsumePriority
}

export type V1UpdateConsumePriorityResponse = object

export interface V1UpdateStudioSettingsRequest {
  /**
   * EnableFastGen 快速生成是否开启;
   * + Optional;
   */
  enableFastGen?: boolean
  /**
   * EnablePrivateGen 私有生成是否开启;
   * + Optional;
   */
  enablePrivateGen?: boolean
}

export interface V1UpdateStudioSettingsResponse {
  /** FastGenEditable 快速生成是否可编辑; */
  fastGenEditable?: boolean
  /** FastGenEnabled 快速生成是否开启; */
  fastGenEnabled?: boolean
  /** PrivateGenEditable 私有生成是否编辑; */
  privateGenEditable?: boolean
  /** PrivateGenEnabled 私有生成是否开启; */
  privateGenEnabled?: boolean
}

export interface V1UpdateUserInfoRequest {
  avatar?: string
  cover?: string
  nick?: string
}

export interface V1UpdateWorkScoreRequest {
  id?: string
  scores?: V1Scores
}

export interface V1UpgradePlanInfo {
  /** 当前的产品信息 */
  currentProduct?: V1ProductInfo
  /** 可抵扣的金额,人民币分 */
  deductibleAmount?: number
  /** 准备升级的产品信息 */
  newProduct?: V1ProductInfo
  /** 需要支付的金额,人民币分 */
  shouldPayAmount?: number
}

export interface V1UpgradeSubscriptionRequest {
  /**
   * DiscountCode 优惠码;
   * + Optional
   */
  discountCode?: string
}

export interface V1UploadFileResponse {
  fileName?: string
  fileUrl?: string
  imageMeta?: V1ImageMeta
}

export interface V1UpsertActivityTemplateRequest {
  /**
   * AbstractModel 运营编辑生图模型;
   * +Optional;
   */
  abstractModel?: string
  /**
   * UID 活动类别唯一ID,只在更新请求中需要;
   * +Optional;
   */
  activityTemplateUID?: string
  /**
   * ActivityUID 活动唯一ID;
   * +Required;
   */
  activityUID?: string
  /**
   * EnableAbstractModel 梅花村活动页前端是否允许用户编辑生图模型;
   * +Required;
   */
  enableAbstractModel?: boolean
  /**
   * EnableCustom 梅花村活动页前端是否对用户显示controlNet入口;
   * +Required;
   */
  enableCustom?: boolean
  /**
   * EnableCustom 梅花村活动页前端是否允许用户编辑Prompt;
   * +Required;
   */
  enablePrompt?: boolean
  /**
   * EnableRatio 梅花村活动页前端是否允许用户编辑生图比例;
   * +Required;
   */
  enableRatio?: boolean
  /**
   * FixedPrompt 运营输入的固定Prompt，生图任务中固定拼接，长度小于4096;
   * +Optional;
   */
  fixedPrompt?: string
  /**
   * OfficialControlNet 官方示意图关联的controlNet;
   * +Optional;
   */
  officialControlNet?: V1ControlNetSettings
  /**
   * OfficialControlNet 官方示意图关联的controlNet;
   * +Required;
   */
  officialImageURLs?: string[]
  /**
   * RandomPrompts 运营输入的随机Prompt数组,文本总长度小于4096;
   * +Required;
   */
  randomPrompts?: string[]
  /**
   * Ratio 运营配置的生图比例;
   * +Optional;
   */
  ratio?: string
  /**
   * Title 活动类别名称，长度小于10;
   * +Required;
   */
  title?: string
  /**
   * UserControlNet 用户上传图片预设的controlNet，不包含inputImageUrl；
   * +Optional;
   */
  userControlNet?: V1ControlNetSettings
}

export type V1UpsertActivityTemplateResponse = object

export interface V1UserAnalyticsItem {
  /** 生成活跃天数; 用户在每日的 24 hr 内至少生成一次图片，即为活跃天数+1 */
  activeDays?: number
  /** 每日平均生成次数 */
  avgGeneratedPerDay?: number
  /** 注册时间 */
  createdAt?: number
  /** 多巴胺兑换码 */
  duoBaAnRedeemCodes?: V1DuoBaAnRedeemCode[]
  /** 图片生成总次数 */
  generatedTotal?: number
  /** 邀请码 */
  inviteCode?: string
  /** 邀请人 */
  invitor?: string
  /** 是否付费 */
  isPaid?: boolean
  /** 最后生成时间 */
  lastGeneratedAt?: number
  /** 最后到访时间 */
  lastVisitedAt?: number
  /** 手机号 */
  phone?: string
  /** 来源渠道 */
  source?: string[]
  tasks?: V1UserTask[]
  /** 累计付费金额；单位：分 */
  totalPaidAmount?: number
  /** 用户UID */
  userUID?: string
}

export interface V1UserDiscountCodeItem {
  /** Bonus 优惠码奖励细节; */
  bonus?: V1Bonus
  /** Code 优惠码; */
  code?: string
  /** Type 优惠码类型; */
  type?: V1DiscountCodeType
  /**
   * UsedCountLimit 使用次数限制
   * 取值范围 (0, 10000]
   */
  usedCountLimit?: number
  /** ValidFrom 有效期开始秒级时间戳; */
  validFrom?: number
  /** ValidTo 有效期结束秒级时间戳; */
  validTo?: number
}

export interface V1UserInviteCodeItem {
  /** Code 邀请码明文; */
  code?: string
  /** UsedAt 邀请码被使用的秒级时间戳; */
  usedAt?: number
}

export interface V1UserTask {
  model?: string
  negativePrompt?: string
  prompt?: string
  taskUID?: string
  works?: V1UserWork[]
}

export interface V1UserWork {
  imageURL?: string
  workUID?: string
}

export interface V1VerifyInviteCodeRequest {
  /**
   * 邀请码，需要md5加密, 且需要base64编码, base64编码方式采用 StdEncoding.
   * var CryptoJS = require("crypto-js");
   * let code = "0000"
   * let hash =  CryptoJS.MD5(code);
   * CryptoJS.enc.Base64.stringify(hash)
   */
  inviteCode: string
}

export interface V1WechatLoginRequest {
  loginCode?: string
  phoneCode?: string
}

export interface V1WechatLoginResponse {
  currentUserInfo?: V1CurrentUserInfo
  openID?: string
}

export interface V1Work {
  abstractModel?: string
  id?: string
  /** 是否包含违禁内容，当包含违禁内容时，Image字段为空 */
  illegal?: boolean
  image?: string
  inline?: V1GenreScore
  /** IsPrivate 仅自己可见 */
  isPrivate?: boolean
  /** 收藏数量 */
  likeCount?: number
  /** 当前用户是否喜欢该作品 */
  liked?: boolean
  ratio?: string
  recommendAt?: number
  score?: number
  /** 为-1表示没有seed，不能用于图像重绘 */
  seed?: number
  size?: string
  task?: V1Task
  taskId?: string
  taskStatus?: V1TaskStatus
}

export interface V2CreateTaskRequest {
  /**
   * ControlNet设置
   * + Optional;
   */
  controlNet?: V1ControlNetSettings
  /**
   * ImageCount 控制一个任务出图的数量
   * + Optional;
   * + Default: 4;
   */
  imageCount?: number
  /**
   * 是否是私有任务，私有任务生成的图片，不会公开展示在首页
   * + Optional;
   * + Default: false;
   */
  isPrivate?: boolean
  /** Model 模型,前端和后端约定好的id */
  model?: string
  /**
   * NegativeTextPrompt 用户负向咒语;
   * + Optional;
   */
  negativeTextPrompt?: string
  /**
   * 任务优先级，提交后会根据用户权益校验权限，如果权限不足则会返回错误
   * + Optional;
   * + Enum: low, normal, high;
   * + Default: low;
   */
  priority?: V1TaskPriority
  /**
   * 图片比例
   * + Required;
   * + Enum: 1:1, 4:3, 16:9, 3:4, 9:16;
   */
  ratio: string
  /**
   * ReferPower 参考图影响力; [0,100]
   * + Optional;
   * + Required when the ReferencedImage is not empty;
   */
  referPower?: number
  /**
   * ReferencedImage 参考图链接;
   * + Optional;
   */
  referencedImage?: string
  /**
   * 如果是从模板创建的任务，需要传入模板ID
   * +Optional;
   */
  templateID?: number
  /** TextPrompts 用户正向咒语; */
  textPrompt?: string
  /**
   * ThirdPartyChannel 第三方渠道;
   * +Optional;
   */
  thirdPartyChannel?: string
}

export interface V2CreateTaskResponse {
  /** ID 任务ID, 后续前端用这个 ID 去轮询任务状态; */
  id?: string
}
