import Navbar from "@/Navbar"
import { useDarkMode } from "@/utils"

function Header() {
  useDarkMode()
  return (
    <>
      <Navbar></Navbar>
      <div className="h-16 hidden md:block"></div>
    </>
  )
}

export default Header
