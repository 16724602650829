import { V1GenreScore } from "@/generated/api/data-contracts"

const template = {
  excellent: [
    "经小t系统检测，主人您目前的出图质量超级好！也许选择以下的一些词汇能让您的画面感觉更加丰富哦〜",
    "哇哦，这图是怎么做到这么好的？感觉小t已经很难用词形容了！是否需要再加一些以下的咒语让画面更加丰富呢？",
    "不行了！这张生成的的太好,小t已经不知道该说什么了！不知道下面的咒语对您有更多的联想方向吗？",
  ],
  good: [
    "经小t系统检测，作品的{genre}也许可以优化下哦！使用以下咒语",
    "报告，当前图像在小t的检测中有良好的表现，不妨试试下列咒语让图像的{genre}有进一步发挥？",
    "可以的可以的！这张非常不错，小t觉得这几张已经很棒啦！下面提升{genre}的咒语能给您带来一些进步的方向吗？",
  ],
  bad: [
    "经小t严格仔细的检查，发现目前的图像的{genre}通过以下咒语进行较好的优化哦！",
    "呀！关于{genre}部分的咒语是不是疏忽了，导致出来的图效果不符合预期？可以用这些来补全一下效果哦！",
    "生成的图{genre}效果不好？也许是输入的内容出错了或者是内心还没有规划好。试试下面的一些词让生成的更加美好吧！",
  ],
}

const tags: {
  [key in keyof Omit<V1GenreScore, "overallScore">]: {
    name: string
    list: string[]
  }
} = {
  /** 明暗层次 */
  lightShadowScore: {
    name: "光影",
    list: [
      "情绪照明",
      "明亮",
      "柔和照明",
      "柔和的灯光",
      "闪烁的光线",
      "黄昏射线",
      "生物发光",
      "伦勃朗照明",
      "背光",
      "体积光",
      "暖照明",
      "戏剧性灯光",
      "自然采光",
    ],
  },
  /** 完整性 */
  intergrityScore: {
    name: "完整性",
    list: [
      "比例协调的",
      "线条流畅的",
      "图案丰富的",
      "纹理清晰的",
      "空间感强的",
      "透视正确的",
      "层次分明的",
      "色彩搭配协调的",
      "物体形状自然的",
      "细节精致的",
    ],
  },
  /** 色彩 */
  colorScore: {
    name: "色彩",
    list: [
      "鲜艳的",
      "暗淡的",
      "柔和的",
      "富有对比的",
      "高饱和度的",
      "柔和的",
      "冷色调的",
      "冷色调的",
      "暖色调的",
      "单色调的",
      "多彩的",
      "渐变的",
      "明亮的",
      "光滑的",
      "粗糙的",
      "暗色调的",
      "明亮的",
      "极彩的",
    ],
  },
  /** 构图 */
  compositionScore: {
    name: "构图",
    list: [
      "对称构图",
      "中心构图",
      "广角",
      "半身像",
      "视线指向",
      "秩序感",
      "三分法",
      "黄金分割",
      "线性构图",
      "分层构图",
      "故事分镜",
      "俯瞰视角",
      "鸟瞰视角",
      "对角线构图",
      "放射状构图",
      "向心构图",
      "看似无序但有规律的构图",
      "逆光",
      "俯视",
      "仰视",
      "低角度",
      "高角度",
      "鸟瞰",
      "面对面",
      "侧面",
      "正面",
      "斜视",
      "大远景",
      "远景",
      "全景",
      "中景",
      "近景",
      "特写",
    ],
  },
  // 质感
  sharpnessScore: {
    name: "质感",
    list: [
      "错综复杂",
      "高细节",
      "超高质量",
      "高分辨率",
      "1080P",
      "2K",
      "4K",
      "8K",
      "8k光滑",
      "虚幻引擎",
      "MaxonCinema 4D，3dMax，  Maya",
      "建筑可视化/建筑渲染",
      "Quixel Megascans渲染",
      "V-Ray",
    ],
  },
}

const getScoringSkills = (genreScore: V1GenreScore) => {
  const { overallScore = 0, ...other } = genreScore
  let temp = ""
  let tagType = ""
  if (overallScore >= 8) {
    const templateList = template["excellent"]
    const [num] = getRandomNum(0, templateList.length - 1, 1)
    temp = templateList[num]
    tagType = getMaxKey(other)
  } else if (overallScore >= 5) {
    const templateList = template["good"]
    const [num] = getRandomNum(0, templateList.length - 1, 1)
    temp = templateList[num]
    tagType = getMinKey(other)
  } else {
    const templateList = template["bad"]
    const [num] = getRandomNum(0, templateList.length - 1, 1)
    temp = templateList[num]
    tagType = getMinKey(other)
  }

  const { name, list } = getRandomTags(2, tagType as keyof Omit<V1GenreScore, "overallScore">)

  return {
    temp,
    tags: list,
    render: () => {
      let tagIndex = 0
      const domList = temp.split(/{|}/).filter(Boolean)

      return (
        <>
          {domList.map((str, index) => {
            if (str === "genre") {
              return (
                <span key={index} className="mx-1 font-[700]">
                  {name}
                </span>
              )
            } else if (str === "tag") {
              return (
                <span key={index} className="mx-1 font-[700]">
                  {list[tagIndex++]}
                </span>
              )
            }
            return str
          })}
        </>
      )
    },
  }
}

const getMaxKey = (obj: any) => {
  return Object.keys(obj).reduce((a, b) => (obj[a] > obj[b] ? a : b))
}

const getMinKey = (obj: any) => {
  return Object.keys(obj).reduce((a, b) => (obj[a] < obj[b] ? a : b))
}

// 获取随机tags
const getRandomTags = (count: number, tagType: keyof Omit<V1GenreScore, "overallScore">) => {
  const min = 0
  const { name = "", list = [] } = tags[tagType] || {}
  const max = list.length - 1
  let numbers = getRandomNum(min, max, count)

  return {
    name,
    list: numbers.map((index) => list[index]),
  }
}

const getRandomNum = (min: number, max: number, count: number) => {
  let numbers: number[] = []
  while (numbers.length < count) {
    let randomNum = Math.floor(Math.random() * (max - min + 1)) + min
    if (!numbers.includes(randomNum)) {
      numbers.push(randomNum)
    }
  }
  return numbers
}

export default getScoringSkills

// const a = getScoringSkills({
//   score: 5,
//   genreScore: {
//     lightShadowScore: 9,
//     intergrityScore: 9,
//     colorScore: 9,
//     compositionScore: 9,
//     sharpnessScore: 9,
//   },
// })
// console.log(a.render())