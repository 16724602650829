import MobileBottomModal from "@/Studio/components/MobileBottomModal"
import TemplatePromptInput from "@/Studio/components/PromptBox/components/TemplatePromptInput.tsx"
import templateStore from "@/store/templateStore"
import { Input } from "@arco-design/web-react"
import { useEffect, useRef, useState } from "react"

const Title = ({ onClose }: { onClose?: () => void }) => {
  return (
    <div className="relative">
      <div
        onClick={() => {
          onClose?.()
        }}
        className="absolute left-0 leading-[30px] rounded-full px-5 bg-[#2E2E4A] cursor-pointer"
      >
        取消
      </div>
      <div className="text-center leading-[30px]">不要的元素</div>
    </div>
  )
}

interface MobileUnwantedModalProps {
  negativePrompt?: string
  onClose?: () => void
  onConfirm?: () => void
  onChange?: (text: string) => void
  templateId?: string
}
const MobileUnwantedModal = ({
  negativePrompt,
  onClose,
  onConfirm,
  onChange,
  templateId,
}: MobileUnwantedModalProps) => {
  const [templatePromptInputVisible, setTemplatePromptInputVisible] = useState(false)
  const textareaRef = useRef<any>(null)
  const template = templateStore.getTemplateById(templateId)
  const isUsingTemplate = !!template

  useEffect(() => {
    textareaRef.current?.focus()
  }, [])

  useEffect(() => {
    if (isUsingTemplate) {
      setTemplatePromptInputVisible(true)
    }
  }, [isUsingTemplate])

  return (
    <MobileBottomModal
      titleHidden
      modalStyle={{
        padding: "10px",
        backgroundColor: "#212134",
      }}
    >
      <Title onClose={onClose} />
      <div className="mt-5">
        {templatePromptInputVisible ? (
          <TemplatePromptInput
            prompt={template?.negativePrompt || ""}
            onChange={(val) => {
              onChange?.(val)
            }}
            onModifyAllClick={() => {
              setTemplatePromptInputVisible(false)
            }}
            style={{
              backgroundColor: "transparent",
              flexWrap: "wrap",
              height: "300px",
            }}
          />
        ) : (
          <Input.TextArea
            onChange={onChange}
            ref={textareaRef}
            autoSize
            style={{
              backgroundColor: "transparent",
              border: "none",
              resize: "none",
              color: "#FFFFFF",
              maxHeight: "300px",
              overflowY: "auto",
              paddingLeft: "0px",
              minHeight: "200px",
            }}
            value={negativePrompt}
          />
        )}
      </div>
      <div className="mt-[30px] flex justify-end">
        <button className="bg-[#4C1FFF] h-9 px-5 rounded cursor-pointer" onClick={onConfirm}>
          确定
        </button>
      </div>
    </MobileBottomModal>
  )
}

export default MobileUnwantedModal
