import { useEffect, useState } from "react"
import bg from "./assets/login_bg.jpg"

import { ReactComponent as WechatIcon } from "./assets/wechat2.svg"
import { ReactComponent as HatIcon } from "./assets/upkeep_hat.svg"
import { ReactComponent as RightIcon } from "./assets/right_arrow_white.svg"
import { ReactComponent as QrcodeIcon } from "./assets/qrcode_wechatgroup.svg"
import QrcodeJPEGIcon from "./assets/qrcode_wechatgroup.jpeg"
import { ReactComponent as LogoIcon } from "./assets/logo.svg"
import { getRecordNumberByDomain } from "./constants"
export default function () {
  const [show, setShow] = useState<boolean>(false)
  return (
    <div className="h-[100vh] w-[100vw]">
      <div
        className="absolute inset-0 bg-blend-multiply bg-cover bg-no-repeat -z-10"
        style={{
          background: `url(${bg}) rgba(0,0,0,0.5) center center`,
          backgroundSize: "cover",
        }}
      ></div>
      <div className="h-full w-full relative">
        <div className="text-white absolute left-[50%] -translate-x-1/2 top-10">
          <div className="mx-auto w-full flex justify-center">
            <LogoIcon width={220} height={42} />
          </div>
          <h2 className="mt-2 text-xs font-semibold text-white flex justify-center items-center">
            <span>Spark Your Imagination</span>
          </h2>
        </div>
        <div className="absolute text-white left-[50%] top-[50%] mx-auto text-center -translate-x-1/2 -translate-y-1/2">
          <div className="flex justify-center mt-2">
            <HatIcon />
          </div>
          <div className="font-bold text-2xl">系统维护升级中</div>
          <div className="text-sm mt-2 text-opacity-60 text-white">
            我们正在加入更多新功能，稍后回来～
          </div>
          <div className="text-sm mt-2 text-opacity-60 text-white">
            升级完成后会发放补偿至已订阅的用户
          </div>
          <div
            id="my-hover"
            onClick={() => setShow(!show)}
            className="relative cursor-pointer mx-auto w-[17.5rem] h-14 flex justify-center items-center mt-24 rounded hover:bg-[#cac7dc33]"
          >
            <div
              id="concat"
              className={`absolute left-[50%] z-50 -translate-x-1/2 top-16 ${show ? "" : "hidden"}`}
            >
              {/* <QrcodeIcon /> */}
              <img src={QrcodeJPEGIcon} />
            </div>
            <WechatIcon />
            <span className="text-[1rem] p-2">加入最棒的AI生成社区</span>
            <RightIcon className="text-white" />
          </div>
        </div>
        <div className="absolute bottom-0 left-0 w-full flex justify-center text-xs mt-10 mb-2 text-white items-center cursor-pointer">
          <a href="https://beian.miit.gov.cn/#/Integrated/index">{getRecordNumberByDomain()}</a>
          <a
            className="flex  justify-center items-center"
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502020083"
          >
            <img className="pl-2 " src="/assets/beian.png" />
            <p className="pl-2 ">沪公网安备 31011502020083号</p>
          </a>
        </div>
      </div>
    </div>
  )
}
