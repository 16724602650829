import { Tabs } from "@arco-design/web-react"
import "./index.less"
import DescribeImage from "./DescribeImage"
import UnwantedElements from "./UnwantedElements"
import Actions from "./Actions"
import TemplateName from "./TemplateName"
import { useEffect, useState } from "react"
import { V1TemplateData } from "@/generated/api/data-contracts"
import templateStore from "@/store/templateStore"
import { observer } from "mobx-react-lite"

interface PromptBoxProps {
  prompt: string
  negativePrompt: string
  onPromptChange: (text: string) => void
  onNegativePromptChange: (text: string) => void
  onRandomPromptClick?: () => void
  privacyDisabled?: boolean
  privacyDisabledMessage?: string
  isPrivate?: boolean
  onPrivacyChange?: () => void
  onPatternChange?: () => void
  speedDisabled?: boolean
  isFast?: boolean
  onSpeedChange?: () => void
  onGenerateClick?: () => void
  generateButtonDisabled?: boolean
  templateId?: string
  removeTemplate?: () => void
  onActivityChange: (uid: string) => void
  activityId?: string
}

const tabKeys = {
  DESCRIPTION: "description",
  UNWANTED: "unwanted",
}

const PromptBox = observer(
  ({
    prompt,
    negativePrompt,
    onPromptChange,
    onNegativePromptChange,
    onRandomPromptClick,
    privacyDisabled,
    privacyDisabledMessage,
    isPrivate,
    onPrivacyChange,
    onPatternChange,
    speedDisabled,
    isFast,
    onSpeedChange,
    onGenerateClick,
    generateButtonDisabled,
    templateId,
    removeTemplate,
    onActivityChange,
    activityId,
  }: PromptBoxProps) => {
    const [modifyAllTemplatePrompt, setModifyAllTemplatePrompt] = useState(false)
    const [activeTabKey, setActiveTabKey] = useState(tabKeys.DESCRIPTION)
    const { templates } = templateStore.store.get()
    const template = templates.find((item: V1TemplateData) => item.id === Number(templateId))

    useEffect(() => {
      setModifyAllTemplatePrompt(false)
    }, [template])

    const usingTemplatePromptInput = !!(template?.prompt && !modifyAllTemplatePrompt)

    // if template is selected, or unwanted elements tab is selected, hide random prompt button
    const randomPromptHidden = !!template || activeTabKey === tabKeys.UNWANTED

    return (
      <div className="py-3 px-0 md:py-5 md:px-[30px] relative">
        {/* Template Name */}
        {template && (
          <div className="relative">
            <TemplateName
              name={template.name}
              onClose={() => {
                removeTemplate?.()
              }}
            />
          </div>
        )}

        {/* Prompt Inputs */}
        <Tabs
          className="studio_prompt_box"
          onChange={(k) => {
            setActiveTabKey(k)
          }}
        >
          <Tabs.TabPane title="描述图片" key={tabKeys.DESCRIPTION}>
            <DescribeImage
              value={prompt}
              onChange={onPromptChange}
              template={template}
              usingTemplatePromptInput={usingTemplatePromptInput}
            />
          </Tabs.TabPane>
          <Tabs.TabPane title="不要的元素" key={tabKeys.UNWANTED}>
            <UnwantedElements
              value={negativePrompt}
              onChange={onNegativePromptChange}
              template={template}
              usingTemplatePromptInput={usingTemplatePromptInput}
            />
          </Tabs.TabPane>
        </Tabs>

        {/* Prompt Actions */}
        <Actions
          randomPromptHidden={randomPromptHidden}
          onRandomPromptClick={() => {
            onRandomPromptClick?.()
          }}
          onPrivacyChange={onPrivacyChange}
          onPatternChange={onPatternChange}
          isPrivate={isPrivate}
          privacyDisabled={privacyDisabled}
          privacyDisabledMessage={privacyDisabledMessage}
          onSpeedChange={onSpeedChange}
          isFast={isFast}
          speedDisabled={speedDisabled}
          onGenerateClick={onGenerateClick}
          generateButtonDisabled={generateButtonDisabled}
          template={template}
          modifyAllTemplatePrompt={modifyAllTemplatePrompt}
          toggleModifyControl={() => {
            setModifyAllTemplatePrompt(!modifyAllTemplatePrompt)
          }}
          onActivityChange={onActivityChange}
          activityId={activityId}
        />
      </div>
    )
  }
)

export default PromptBox
