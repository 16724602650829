import { getAllTasks } from "@/services/editor"
import worksPagesStore from "@/store/worksPagesStore"
import { Button, Modal, Popover } from "@arco-design/web-react"
import { useEffect, useMemo, useRef, useState } from "react"
import { getColumnCount, useWindowWidth } from "@/utils"
import * as store from "@/store"
import { ReactComponent as LoadingPuffIcon } from "@/assets/loading_puff.svg"
import { useTranslation } from "react-i18next"
import { toastError, toastInfo, toastSuccess } from "@/common/Toast"
import { ErrorCode } from "@/i18next"
import { observer } from "mobx-react-lite"
import { getMyTemplates } from "@/services/personal"
import { V1TemplateData } from "@/generated/api/data-contracts"
import TemplateItem from "@/common/TemplateItem"
import { Masonry } from "masonic"
import templateStore, { formatTemplatesModel } from "@/store/templateStore"
import TemplateDetailModal from "@/common/TemplateDetailModal"
import ContentSwitcher from "../components/ContentSwitcher"
import SortButton from "../components/SortButton"
import SelectButton from "../components/SelectButton"
import { SortEnum } from "../components/SortButton/constant"
import InfiniteScroll from "@/components/common/InfiniteScroll"
const Template = () => {
  const { t } = useTranslation(["translation", "errorMessage"])
  const [loadingTemplate, setLoadingTemplate] = useState(false)
  const [templates, setTemplates] = useState<V1TemplateData[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [total, setTotal] = useState(0)
  const hasMore = templates.length < total
  console.log(hasMore, "hasMore")
  const Loader = () => {
    return (
      <div className="my-20 flex items-center justify-center">
        <LoadingPuffIcon stroke="currentColor" />
      </div>
    )
  }
  const NoMore = () => {
    const { t } = useTranslation()
    return (
      <section className="py-10 flex justify-center items-center">
        <span className="inline-flex items-center text-[#6F7176] text-sm before:mr-[26px] before:bg-[#C9CBD14D]/30 before:w-[138px] before:h-[1px] after:ml-[26px] after:bg-[#C9CBD14D]/30 after:w-[138px] after:h-[1px]">
          {t("worksCenter.noMore")}
        </span>
      </section>
    )
  }
  const columnCount = getColumnCount(useWindowWidth(), true)
  //   // 分页配置
  //   const [pageConfig, setPageConfig] = useState<PageConfigType>({
  //     page: 0,
  //     search: "",
  //     sortType: SortEnum.newest,
  //     total: 0,
  //   })

  useEffect(() => {
    // templateStore.fetchTemplates()
    return () => {
      worksPagesStore.init()
    }
  }, [])
  const loadMore = () => {
    setCurrentPage(currentPage + 1)
    getTemplates(currentPage + 1)
  }
  useEffect(() => {
    getTemplates(1)
    // 查看作品
  }, [])

  //  我的模板
  const getTemplates = async (current: number) => {
    setLoadingTemplate(true)
    try {
      const { data, success, errorCode } = await getMyTemplates({
        page: current,
        pageSize: 10,
      })
      const formatedList = formatTemplatesModel(data?.templates || []) || []
      if (current === 1) {
        setTemplates(formatedList)
      } else {
        setTemplates([...templates, ...formatedList])
      }
      setTotal(data?.total || 0)
      if (!success) {
        toastError(t(`errorMessage:${errorCode}` as any))
      }
    } catch (e) {
      toastError("Error" + e)
    }
    setLoadingTemplate(false)
  }

  const [taskIndex, setTaskIndex] = useState<number | null>(null)
  const templateModalVisiable = taskIndex !== null
  const openModal = (i: number) => {
    setTaskIndex(i)
  }
  const closeModal = () => {
    setTaskIndex(null)
  }
  // const handleSortTypeChange = (sortType: SortEnum) => {
  //   // setSortType(sortType)
  //   // myTasksDispatch({
  //   //   type: "RESET",
  //   // })
  //   setCurrentPage(1)
  //   setTotalSize(0)
  // }
  // const exitManageMode = () => {
  //   setIsManaging(false)
  // }
  return (
    <>
      <ContentSwitcher
        actionComponent={
          <>
            {/* Sort Button */}
            {/* <SortButton value={sortType} onChange={handleSortTypeChange} /> */}

            {/* Image Manage Button */}
            {/* <SelectButton
              manageBtnText={t("worksCenter.imageManage")}
              isManaging={isManaging}
              onManageClick={() => {
                setIsManaging(true)
              }}
              onExitClick={exitManageMode}
            /> */}
          </>
        }
      />

      <div className="px-[20px]">
        {/* <Masonry
          items={templates}
          render={({ index, data }) => {
            return (
              <div
                onClick={() => {
                  openModal(index)
                }}
              >
                <TemplateItem
                  key={data.id}
                  template={data}
                  className="group overflow-hidden h-full aspect-[1/1] items-center rounded-[4px] flex !w-full"
                  showAvator={false}
                  fromWork={true}
                />
              </div>
            )
          }}
          columnCount={columnCount}
          columnGutter={20}
        /> */}
        <InfiniteScroll
          hasMore={hasMore}
          isLoading={loadingTemplate}
          loadMore={() => {
            loadMore()
          }}
          loader={<Loader />}
          noMoreElement={<NoMore />}
        >
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-[20px]">
            {templates.map((item, index) => (
              <div
                onClick={() => {
                  openModal(index)
                }}
              >
                <TemplateItem
                  key={item.id}
                  template={item}
                  className="group overflow-hidden h-full aspect-[1/1] items-center rounded-[4px] !w-full "
                  showAvator={false}
                  fromWork={true}
                />
              </div>
            ))}
          </div>
        </InfiniteScroll>
      </div>

      {templateModalVisiable && (
        <TemplateDetailModal
          defaultTemplateIndex={taskIndex}
          onClose={closeModal}
          templatesCenter={templates}
        />
      )}
    </>
  )
}

export default observer(Template)
