import { V1Task, V1Work } from "@/generated/api/data-contracts"
import { observer } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"
import { apiListBusyTasks } from "@/services/editor"
import { toastInfo } from "@/common/Toast"
import CheckAuth from "@/components/common/CheckAuth"
import studioPagesStore from "@/store/studioPagesStore"

const GenerateAgainTask = observer(function ({
  buttonContent,
  task,
}: {
  buttonContent: React.ReactNode
  work?: V1Work
  task?: Partial<V1Task>
}) {
  const navigate = useNavigate()

  const onClick = async () => {
    const { busyTaskCount } = await apiListBusyTasks()
    const TIMv5Model = [
      "auto-tianime-default",
      "auto-tiality-default",
      "auto-tianime-art",
      "auto-tiality-portrait",
    ]
    const modelVersion = TIMv5Model.includes(task?.model || "") ? "TIMv5" : "TIMv4"
    if (busyTaskCount) {
      return toastInfo("当前有任务正在进行中，请稍后再试～")
    }

    const { modelPattern } = studioPagesStore.store.get()
    studioPagesStore.init(({ taskHistory, runingTasks }) => ({ taskHistory, runingTasks }))
    studioPagesStore.setStore({
      prompt: task?.textPrompt || "",
      negativeTextPrompt: task?.negativeTextPrompt || "",
      model: task?.model || "",
      ratio: task?.ratio || "1:1",
      autogeneration: true,
      modelVersion: modelVersion,
      referPower: task?.referPower || 50,
      referencedImage: task?.referencedImage || "",
      modelPattern: {
        ...modelPattern,
      },
      referencelIamgeData: {
        type: "controlNet",
        preViewUrl: "",
        payload: { controlNet: task?.controlNet || "" } as any,
      },
    } as any)
    if (location.pathname !== "/studio") {
      navigate({
        pathname: "/studio",
      })
    }
  }

  return task?.textPrompt ? <CheckAuth onClick={onClick}>{buttonContent}</CheckAuth> : null
})

export default GenerateAgainTask
