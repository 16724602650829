import { SIDER_MENU_TAILWIND_CLS } from "@/Navbar"
import { observable } from "mobx"

export const MOBILE_CONTAINER_CLS = "mobile_content_container"
const toggleContainer = (isOpen: boolean) => {
  const content = document.querySelector(`.${MOBILE_CONTAINER_CLS}`)
  if (content) {
    content.classList[isOpen ? "add" : "remove"](SIDER_MENU_TAILWIND_CLS.translateX)
  }
}

const store = observable.box<{
  siderMenuIsOpen: boolean
}>({
  siderMenuIsOpen: false,
})

const menuStore = {
  store,
  // 初始化store
  init: () => {
    // 默认参数放在这里
    store.set({
      siderMenuIsOpen: false,
    })
  },
  openMenu: () => {
    store.set({
      siderMenuIsOpen: true,
    })
    document.documentElement.style.overflow = "hidden"
    // move content to right
    toggleContainer(true)
  },
  closeMenu: () => {
    store.set({
      siderMenuIsOpen: false,
    })
    document.documentElement.style.overflow = "auto"
    // move content to left
    toggleContainer(false)
  },
}

export default menuStore
