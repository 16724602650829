import { Modal, Slider } from "@arco-design/web-react"
import { IconClose } from "@arco-design/web-react/icon"

import { ReactComponent as LoadingPuffIcon } from "@/assets/loading_puff.svg"
import Cropper, { ReactCropperElement } from "react-cropper"
import "cropperjs/dist/cropper.css"
import "./UploadreferenceImage.less"

function fileToBlob(file: File) {
  return new Promise((resolve) => {
    const reader = new FileReader()
    let blob = null
    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (typeof e.target?.result === "object" && e.target?.result) {
        blob = new Blob([e.target?.result])
      } else {
        blob = e.target?.result
      }
      resolve(blob)
    }
    reader.readAsArrayBuffer(file)
  })
}

import {
  MutableRefObject,
  createRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react"
import { V1ControlNetSettings } from "@/generated/api/data-contracts"
import { observer } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"
import CheckAuth from "@/components/common/CheckAuth"
import { uploadFile } from "@/services/editor"
import studioPagesStore from "@/store/studioPagesStore"
import { toastError } from "@/common/Toast"
import { ErrorCode } from "@/i18next"
import { useTranslation } from "react-i18next"
import { isMobileOnly } from "react-device-detect"
import MobileModalHeader from "./MobileModalHeader"
const ratioList = ["1:1", "4:3", "3:4", "16:9", "9:16"]
const ratioPx = [
  [100, 100],
  [100, 75],
  [75, 100],
  [100, 56],
  [56, 100],
]

export const UploadReferenceImageInStudioPage = observer(
  ({
    tempUrl,
    uploadButton,
    onChange,
    event,
  }: {
    tempUrl: string
    uploadButton?: React.ReactNode
    event: MutableRefObject<{ onOpen: () => void }>
    onChange: (
      _:
        | {
            type: "referencelImage"
            preViewUrl: string
            payload: {
              referPower: number
              referencedImage: string
            }
          }
        | {
            type: "controlNet"
            preViewUrl: string
            payload: { controlNet: V1ControlNetSettings }
          }
    ) => void
  }) => {
    const referencelIamgeData = studioPagesStore.store.get().referencelIamgeData
    const { t } = useTranslation(["translation", "errorMessage"])
    //  图片尺寸联动
    const storeRatio = studioPagesStore.store.get().ratio
    const setStoreRatio = (ratio: string) => studioPagesStore.setStore({ ratio })
    const [ratio, setRatio] = useState("1:1") // 选中的尺寸
    const [imgStatus, setImgStatus] = useState<0 | 1 | 2 | 3 | 4>(0) // 0 待上传 1 裁剪完  2 上传中 3 上传失败，4 上传成功

    const inputRef = useRef<HTMLInputElement>(null)
    const [visible, setVisible] = useState(false) // modal
    const cropperRef = createRef<ReactCropperElement>() // cropper ref
    const [aspectRatio, setAspectRatio] = useState<number>() // 裁剪区域的比例
    const [showBase64, setShowBase64] = useState<string>() // 裁剪区域展示的图
    const [cropperBase64, setCropperBase64] = useState<string>() // 裁剪出来的图
    const [uploadBlobFile, setUploadBlobFile] = useState<Blob>() // 上传失败和原图上传用 省去base64转file
    const [referenceWeights, setReferenceWeights] = useState(0.5) // 参考比例

    useImperativeHandle(event, () => ({
      onOpen: () => {
        setVisible(true)
      },
      init: () => {
        inputRef.current?.click()
      },
      editor: () => {
        transNetImg()
      },
    }))

    // 去缓存数据
    useEffect(() => {
      if (referencelIamgeData?.type === "referencelImage") {
        const t = referencelIamgeData.payload
        tempUrl = t.referencedImage
        fetch(tempUrl)
          .then((response) => response.blob())
          .then((blob) => {
            setUploadBlobFile(blob)
            // setReferencedImage(tempUrl)
            const url = URL.createObjectURL(blob)
            setShowBase64(url)
            setCropperBase64(url)
            setImgStatus(4)
            setCropperBase64(url)
            setReferenceWeights(referencelIamgeData.payload.referPower / 100)
            onChange({
              type: "referencelImage",
              preViewUrl: tempUrl,
              payload: {
                referencedImage: tempUrl,
                referPower: Math.round(referenceWeights * 100) || 0,
              },
            })
          })
      }
      setRatio(storeRatio || "1:1")
      rotateTo(storeRatio)
    }, [JSON.stringify(referencelIamgeData), visible])

    const intConfig = () => {
      setImgStatus(0)
      // setReferencedImage("")
      // setRatio("1:1")
      setAspectRatio(1)
      setCropperBase64(undefined)
      setReferenceWeights(0.5)
    }
    //  将网址图片转为本地 blob
    const transNetImg = () => {
      let url: string = referencelIamgeData?.preViewUrl || ""
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const url = URL.createObjectURL(blob)
          setUploadBlobFile(blob)
          setShowBase64(url)
          showImgModal()
        })
    }
    // 选中图片转为base64
    const selecedImage = async (input: HTMLInputElement) => {
      const file = input.files![0]
      if (file?.size / 1048576 > 5) {
        return toastError("单个文件不能超过5M")
      }
      input.value = ""
      const url = URL.createObjectURL(file)
      const blob = (await fileToBlob(file)) as Blob
      setUploadBlobFile(blob)
      setShowBase64(url)
      showImgModal()
      intConfig()
    }

    // 上传到服务器获取url
    const getFileUrl = async (blob?: Blob) => {
      if (!blob) {
        return
      }
      setImgStatus(2)
      const newFile = new File([blob], "image", {
        type: "image/*",
      })
      try {
        const fd = new FormData()
        fd.append("file", newFile)
        const { data } = await uploadFile({ file: newFile }, { format: "json" })

        if (data.success && data.data.fileUrl) {
          // setReferencedImage(data.fileUrl)
          setImgStatus(4)
          onChange({
            type: "referencelImage",
            preViewUrl: data.data.fileUrl,
            payload: {
              referencedImage: data.data.fileUrl,
              referPower: Math.round(referenceWeights * 100) || 0,
            },
          })
          closeModal()
        } else {
          toastError(t(`errorMessage:${data.errorCode as ErrorCode}`))
          setImgStatus(3)
        }
      } catch (error) {
        setImgStatus(3)
      }
    }

    // 关闭弹窗
    const closeModal = () => {
      setVisible(false)
    }

    // 跳过裁剪
    const jumpCropper = () => {
      setImgStatus(1)
      setCropperBase64(showBase64)
    }

    // 跳过裁剪 原图上传

    // 裁剪上传
    const upload = async (uploadBlobFile: Blob) => {
      console.log(uploadBlobFile, "uploadBlobFile")
      await getFileUrl(uploadBlobFile)
    }

    const getCropperBase64 = () => {
      setImgStatus(1)
      const img = cropperRef.current?.cropper.getCroppedCanvas().toDataURL("image/jpeg")
      setCropperBase64(img)
      cropperRef.current?.cropper.getCroppedCanvas().toBlob(
        async (blob) => {
          if (blob) {
            console.log(blob, "blobblobblob")
            upload(blob)
          }
        },
        "image/jpeg",
        0.8
      )
    }

    const showImgModal = () => {
      setVisible(true)
    }

    const rotateTo = (item: string) => {
      const [w, h] = item.split(":")
      const rotate = +w / +h
      setAspectRatio(rotate)
    }

    const onKeyDownCtrlK = (oEvent: KeyboardEvent) => {
      const nKeyCode = oEvent.keyCode
      const bCtrlKeyCode = oEvent.ctrlKey || oEvent.metaKey
      if (nKeyCode === 75 && bCtrlKeyCode) {
        //TODO 未登录且已经创建过禁用
        inputRef.current?.click()
      }
    }

    useEffect(() => {
      document.addEventListener("keydown", onKeyDownCtrlK)
      return () => {
        document.removeEventListener("keydown", onKeyDownCtrlK)
      }
    }, [])

    const corpperStyle = {
      width: "100%",
      height: isMobileOnly ? "370px" : "480px",
    }

    return (
      <>
        <CheckAuth
          onClick={() => {
            inputRef.current?.click()
          }}
        >
          {uploadButton}
        </CheckAuth>

        <input
          ref={inputRef}
          className="hidden"
          type="file"
          accept="image/*"
          id="ref-image"
          onChange={(evt) => selecedImage(evt.target)}
        />
        {/* <label className="w-full" htmlFor="ref-image">
        </label> */}
        <Modal
          unmountOnExit={false}
          visible={visible}
          modalRender={() => {
            return (
              <div className="w-screen min-h-screen md:min-h-fit md:w-[488px] relative mx-auto top-0 inline-block align-middle  bg-[#212134] rounded overflow-hidden">
                {/* Modal header */}
                {isMobileOnly ? (
                  <MobileModalHeader title="上传参考图" onGoBack={closeModal} />
                ) : (
                  <div className="flex justify-between pl-[28px] bg-[#161623] pr-3 py-3">
                    <h1 className=" text-left text-base">上传参考图</h1>
                    <div className="flex text-lg justify-end items-start ">
                      <IconClose className="cursor-pointer" onClick={() => closeModal()} />
                    </div>
                  </div>
                )}

                {
                  // imgStatus === 0 ? (
                  <>
                    <div className="px-[10px] md:px-0 bg-black relative">
                      <Cropper
                        key={`${imgStatus}-${visible}-${aspectRatio}`}
                        wheelZoomRatio={1}
                        ref={cropperRef}
                        style={corpperStyle}
                        zoomable={false} // 图缩放
                        aspectRatio={aspectRatio}
                        preview=".img-preview"
                        src={showBase64}
                        viewMode={1}
                        dragMode="none"
                        minCropBoxHeight={100}
                        minCropBoxWidth={100}
                        background={false}
                        responsive={true}
                        autoCropArea={1}
                        checkOrientation={false}
                        guides={true}
                        // cropBoxMovable={imgStatus === 0}
                        // cropBoxResizable={imgStatus === 0}
                      />
                      {imgStatus === 2 && (
                        <div className="absolute inset-0 rounded bg-black/80 flex items-center justify-center">
                          <LoadingPuffIcon />
                        </div>
                      )}
                    </div>
                  </>
                  // ) : (
                  //   <>
                  //     <div>
                  //       <div className="px-[4px] md:w-[488px] md:h-[480px] relative">
                  //         <Cropper
                  //           key={`${imgStatus}-${visible}`}
                  //           wheelZoomRatio={1}
                  //           style={corpperStyle}
                  //           zoomable={false} // 图缩放
                  //           // aspectRatio={aspectRatio}
                  //           preview=".img-preview"
                  //           src={cropperBase64}
                  //           viewMode={1}
                  //           dragMode="none"
                  //           minCropBoxHeight={100}
                  //           minCropBoxWidth={100}
                  //           background={false}
                  //           responsive={true}
                  //           autoCropArea={1}
                  //           checkOrientation={false}
                  //           guides={true}
                  //           cropBoxMovable={false}
                  //           cropBoxResizable={false}
                  //         />
                  //         {imgStatus === 2 && (
                  //           <div className="absolute inset-0 rounded bg-black/80 flex items-center justify-center">
                  //             <LoadingPuffIcon />
                  //           </div>
                  //         )}
                  //       </div>
                  //     </div>
                  //   </>
                  // )
                }
                <div className="p-[20px]">
                  <h2 className="text-left  text-[#FFFFFFCC]">裁切比例</h2>
                  <div className="flex mt-2.5 gap-x-2 justify-between">
                    {ratioList.map((item, i) => (
                      <button
                        key={i}
                        onClick={() => {
                          rotateTo(item)
                          setRatio(item)
                          setStoreRatio(item)
                        }}
                        aria-checked={ratio === item}
                        className="p-2 flex-1 flex flex-col items-center text-white/60 bg-[#1C1C28] rounded outline-[#724AFF] outline-1 aria-checked:outline aria-checked:text-[#724AFF]"
                      >
                        <div className="flex items-center justify-center w-6 h-6">
                          <div
                            style={{ width: `${ratioPx[i][0]}%`, height: `${ratioPx[i][1]}%` }}
                            className={`rounded border border-current`}
                          ></div>
                        </div>
                        <div className="mt-2 text-center text-sm md:leading-none leading-5">
                          {item}
                        </div>
                      </button>
                    ))}
                  </div>
                </div>
                <div className="px-4 mb-[20px]">
                  <div className="text-sm text-left text-white/80">
                    <span>参考权重</span>
                  </div>
                  <div className="flex items-center">
                    <Slider
                      style={{ flex: 1 }}
                      step={0.01}
                      value={referenceWeights}
                      onChange={(v) => {
                        setReferenceWeights(v as number)
                      }}
                      min={0.01}
                      max={1}
                    />
                    <div className="text-[#ffffff99] ml-3 w-[2rem]">{referenceWeights}</div>
                  </div>
                </div>
                <div className=" justify-end flex mb-[20px] pr-[20px]">
                  {/* <button
                    className="mr-0 w-auto flex-1 md:flex-initial md:w-[120px] text-sm rounded border py-2 border-[#c9cbd14d] md:mr-6"
                    onClick={() => {
                      // sourceImgUpload()
                      setImgStatus(0)
                    }}
                  >
                    重新裁剪
                  </button> */}
                  <button
                    className="w-auto md:flex-initial text-sm rounded  bg-[#4C1FFF] md:w-[120px] py-2 px-[10px]"
                    onClick={getCropperBase64}
                  >
                    确认上传
                  </button>
                </div>
                {/* <div className="py-3">
                  {imgStatus === 0 ? (
                    <>
                      <div className="px-5 md:px-0 justify-between gap-x-2.5 flex md:justify-center">
                        <button
                          className="w-auto mr-0 flex-1 md:flex-initial md:w-[120px] text-sm rounded border py-2 border-[#c9cbd14d] md:mr-6"
                          onClick={() => {
                            jumpCropper()
                          }}
                        >
                          跳过裁剪
                        </button>
                        <button
                          className="w-auto flex-1 md:flex-initial text-sm rounded  bg-[#4C1FFF] md:w-[120px] py-2"
                          onClick={getCropperBase64}
                        >
                          确认裁剪
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="px-5 md:px-0 gap-x-2.5 justify-between flex md:justify-center">
                        <button
                          className="mr-0 w-auto flex-1 md:flex-initial md:w-[120px] text-sm rounded border py-2 border-[#c9cbd14d] md:mr-6"
                          onClick={() => {
                            // sourceImgUpload()
                            setImgStatus(0)
                          }}
                        >
                          重新裁剪
                        </button>
                        <button
                          className="w-auto flex-1 md:flex-initial text-sm rounded  bg-[#4C1FFF] md:w-[120px] py-2"
                          onClick={upload}
                        >
                          确认上传
                        </button>
                      </div>
                    </>
                  )}
                </div> */}
              </div>
            )
          }}
        />
      </>
    )
  }
)

//  临时先直接跳转 下期加弹窗
export const UploadReferenceImageInOtherPage = observer(
  ({ sourceImage, buttonContent }: { sourceImage: string; buttonContent: React.ReactNode }) => {
    const navigate = useNavigate()

    const onChange = () => {
      studioPagesStore.setStore({
        referencelIamgeData: {
          type: "referencelImage",
          preViewUrl: sourceImage,
          payload: {
            referPower: 50,
            referencedImage: sourceImage,
          },
        },
      })

      navigate({
        pathname: "/studio",
      })
    }

    return <CheckAuth onClick={onChange}>{buttonContent}</CheckAuth>
  }
)
