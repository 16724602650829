import { V1Task, V1Work } from "@/generated/api/data-contracts"
import { useState } from "react"
import { api } from "@/services/api"
import { toastError, toastSuccess } from "@/common/Toast"
import { useTranslation } from "react-i18next"
import { ErrorCode } from "@/i18next"
import ModalScaleImg from "@/common/ImgDetailModal/Modal"
import { observer } from "mobx-react-lite"
import OptionsLayer from "./OptionsLayer"
import SelectLayer from "./SelectLayer"
import { getImageURL } from "@/utils"

interface ImageItemProps {
  work: V1Work
  task: V1Task
  onWorkUpdated?: (work: V1Work) => void
  isManaging?: boolean
  isSelected?: boolean
  onSelectLayerClick?: () => void
  imageClickDisable?: boolean
  isVisited?: boolean
  onTaskUpdated?: (task: V1Task) => void
}
const ImageItem = ({
  work,
  task,
  onWorkUpdated,
  isManaging = false,
  isSelected = false,
  onSelectLayerClick,
  imageClickDisable = false,
  isVisited = false,
  onTaskUpdated,
}: ImageItemProps) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [isAlbumSelectOpen, setIsAlbumSelectOpen] = useState(false)
  const [currentWork, setCurrentWork] = useState({})
  const { t } = useTranslation(["errorMessage"])

  const togglePrivacyStatus = async () => {
    const workId = work?.id
    if (!workId) {
      toastError("作品id不存在")
      return
    }

    const isPrivate = work?.isPrivate

    onWorkUpdated?.({
      ...work,
      isPrivate: !isPrivate,
    })

    // Update the privacy status on the server
    try {
      const { data } = await api.setWorkIsPrivate(workId ?? "", {
        isPrivate: !isPrivate,
      })
      if (data.success) {
        toastSuccess("切换成功")
      } else {
        toastError(t(`errorMessage:${data.errorCode as ErrorCode}`))
      }
    } catch (e) {
      toastError("操作失败")
    }
  }

  const handleImageClick = () => {
    if (imageClickDisable) {
      return
    }
    setModalOpen(true)
    setCurrentWork(work)
  }

  // The URL of the image for the work.
  const imageUrl = work?.image ?? ""

  return (
    <>
      <div
        className="image-item absolute inset-0 bg-cover bg-center bg-no-repeat group"
        style={{
          backgroundImage: `url(${getImageURL(imageUrl)})`,
        }}
        onClick={handleImageClick}
      >
        {isManaging ? (
          <SelectLayer checked={isSelected} onClick={onSelectLayerClick} />
        ) : (
          <OptionsLayer
            work={work}
            task={task}
            isPopoverOpen={isPopoverOpen}
            setIsPopoverOpen={setIsPopoverOpen}
            isAlbumSelectOpen={isAlbumSelectOpen}
            setIsAlbumSelectOpen={setIsAlbumSelectOpen}
            togglePrivacyStatus={togglePrivacyStatus}
            isVisited={isVisited}
            onTaskUpdated={onTaskUpdated}
          />
        )}
      </div>

      <ModalScaleImg
        open={modalOpen}
        task={task}
        work={currentWork}
        taskChangeDisable="both"
        onClose={() => {
          setModalOpen(false)
        }}
      />
    </>
  )
}

export default observer(ImageItem)
