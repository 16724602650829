import { ReactComponent as MagicIcon } from "@/assets/magic2.svg"
import GenerateAgainTask from "@/Studio/components/GenerateAgainTask"
import { V1Task } from "@/generated/api/data-contracts"
import { Tooltip } from "@arco-design/web-react"

interface RegenerateBtnProps {
  task: V1Task
}

const RegenerateBtn = ({ task }: RegenerateBtnProps) => {
  return (
    <Tooltip content="再次生成">
      <div>
        <GenerateAgainTask
          task={task}
          buttonContent={
            <>
              <div className="hover:bg-[#FFFFFF1A] transition rounded-sm cursor-pointer">
                <MagicIcon />
              </div>
            </>
          }
        />
      </div>
    </Tooltip>
  )
}

export default RegenerateBtn
