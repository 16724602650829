import { useState, ReactNode, useEffect, useContext } from "react"
import { WorkContext } from "@/pages/Works/Provider"
import ModalScaleImg from "@/common/ImgDetailModal/Modal"
import { ReactComponent as IconEye } from "@/assets/eye.svg"
import { ReactComponent as IconEyeClose } from "@/assets/eye-close.svg"
import { ReactComponent as IconHeard } from "@/assets/heart-white.svg"
import { ReactComponent as IconRepaint } from "@/assets/repaint.svg"
import { ReactComponent as CheckmarkIcon } from "@/assets/checkmark.svg"
import { ReactComponent as EnlargeIcon } from "@/assets/studio/mobile/enlarge.svg"
import CardOption from "@/components/common/CardOption"
import DefaultModal from "./Modal"
import { getImageURL } from "@/utils"
import { V1Task, V1Work } from "@/generated/api/data-contracts"
import { api } from "@/services/api"
import { runInAction } from "mobx"
import { IllegalWork } from "@/common/IllegalWork"
import { ErrorImage } from "@/common/ErrorImage"
import { toastError, toastSuccess } from "@/common/Toast"
import { Tooltip } from "@arco-design/web-react"
import ImageRepainting from "@/Studio/components/ImageRepainting"
import * as store from "@/store"
import { useTranslation } from "react-i18next"
import { ErrorCode } from "@/i18next"
import { observer } from "mobx-react-lite"
import worksPagesStore from "@/store/worksPagesStore"
import { isMobileOnly } from "react-device-detect"
import TemplateDetailModal from "@/common/TemplateDetailModal"
import AlbumSelect from "./WorksTab/components/AlbumSelect"

export interface RegenOptions {
  textPrompt: string
  negativeTextPrompt: string
  model: string
  ratio: string
}

const Card = observer(
  ({
    work,
    task,
    onToggle,
    Modal = DefaultModal,
    showCreate = true,
    setModalVisible,
    ModalContent,
    showEye,
    showDelete,
    likeOrUnlike,
    workIndex,
    onToggleDelete,
  }: {
    workIndex: number
    likeOrUnlike?: (linked: boolean, id: string, workIndex: number, work: V1Work) => void
    showCreate?: boolean
    showEye?: boolean
    showDelete?: boolean
    task: V1Task
    work: V1Work
    onToggle?: (list: string[], workId?: string) => void
    setModalVisible?: (visible: boolean) => void
    Modal?: typeof DefaultModal
    ModalContent?: ReactNode
    onToggleDelete?: (flag: boolean) => void
  }) => {
    const { t } = useTranslation(["translation", "errorMessage", "randomPrompt"])
    const selectList = worksPagesStore.store.get().selectList
    const user = store.user.get()
    const [isOpenImage, setIsOpenImage] = useState(!work?.isPrivate) // 表示作品当前公开/隐藏状态，按钮则要相反
    const [isErrorImage, setIsErrorImage] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [currLike, setCurrLike] = useState(work.liked)
    const [loadedImgList, setLoadedImgList] = useState<string[]>()
    const [isImageModalVisible, setIsImageModalVisible] = useState(false)
    const { refresh } = useContext(WorkContext)

    const [popVisible, setPopVisible] = useState(false)
    const [optionVisiable, setOptionVisiable] = useState(false)

    const handleOptionEnterChange = (visible: boolean) => {
      setOptionVisiable(visible)
      if (!visible) {
        setPopVisible(false)
      }
    }

    useEffect(() => {
      if (work.liked !== currLike) {
        setCurrLike(work.liked)
      }
      if (work?.isPrivate === isOpenImage) {
        setIsOpenImage(!work.isPrivate)
      }
    }, [work])
    async function handleOpenImage(isOpen: boolean) {
      try {
        const { data } = await api.setWorkIsPrivate(work.id ?? "", {
          isPrivate: !isOpen,
        })
        if (data.success) {
          toastSuccess("切换成功")
          setIsOpenImage(isOpen)
          refresh()
        } else {
          toastError(t(`errorMessage:${data.errorCode as ErrorCode}`))
        }
      } catch (e) {
        toastError("操作失败")
      }
    }

    const rounedClsMap = new Map<number, string>([
      [0, "rounded-tl"],
      [1, "rounded-tr"],
      [2, "rounded-bl"],
      [3, "rounded-br"],
    ])
    const mobileRoundedCls = rounedClsMap.get(workIndex % 4) || "rounded"
    const roundedCls = isMobileOnly ? mobileRoundedCls : "rounded"

    return (
      <>
        {isImageModalVisible && (
          <div
            onClick={() => {
              setIsImageModalVisible(false)
            }}
            className="z-[10001] fixed top-0 left-0 w-full bottom-0 bg-black p-2.5 flex items-center justify-center"
          >
            <img src={getImageURL(work.image || "")} alt="" className="object-contain" />
          </div>
        )}

        {work.illegal ? (
          // 违法图片
          <IllegalWork id={work.id} URL={work.image} taskId={task?.id} />
        ) : isErrorImage ? (
          <ErrorImage id={work.id} URL={work.image} taskId={task?.id} />
        ) : (
          // 合法图片
          <div
            className={`relative h-full bg-[#676565] cursor-pointer ${roundedCls}`}
            style={{ minHeight: "10vh" }}
            onMouseEnter={() => {
              setPopVisible(true)
            }}
            onMouseLeave={() => {
              !optionVisiable && setPopVisible(false)
            }}
            onClick={() => {
              onToggle?.([], work.id)
            }}
          >
            {/* Enlarge Icon for Mobile */}
            {isMobileOnly && (
              <EnlargeIcon className="w-4 h-4 object-contain absolute bottom-[5px] right-[5px]" />
            )}

            <img
              className={`w-auto object-cover select-none ${roundedCls}`}
              src={getImageURL(work.image || "")}
              alt=""
              style={{
                visibility:
                  work.image && loadedImgList?.includes(work.image) ? "visible" : "hidden",
              }}
              onLoad={(e) => {
                work.image && setLoadedImgList([work.image].concat(loadedImgList || []))
                // FIXME 接口清理脏数据后可以移除
                // 接口返回的 size 和 ratio 可能与实际图片信息不一致，导致显示比例不正确
                // https://github.com/TiamatAI/xica-docs/issues/235#issuecomment-1495304268
                runInAction(() => {
                  const w = (e.target as HTMLImageElement).naturalWidth
                  const h = (e.target as HTMLImageElement).naturalHeight
                  work.size = `${w}x${h}`
                  // 这里ratio导致再次生成比例错误 + 代码暂未用到，所以注释了
                  // work.ratio = `1:${(h / w).toFixed(2)}`
                })
              }}
              onError={() => setIsErrorImage(true)}
            />
            <div
              className="absolute loading-indicator-primary z-5 top-0  right-0 h-full w-full flex flex-col justify-center items-center"
              style={{
                display: work.image && loadedImgList?.includes(work.image) ? "none" : "flex",
              }}
            >
              <div
                className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-neutral-100 motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status"
              >
                <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                  Loading...
                </span>
              </div>
              {/* <p className="md:text-xl text-sm leading-[31px] mt-[4px] text-[#000000]">作品加载中～</p>  */}
            </div>

            {onToggle && (
              <div className="absolute top-2.5 right-2.5">
                {selectList.includes(work.id || "") ? (
                  <label className="w-5 h-5 items-center justify-center cursor-pointer bg-[#5A14F0] rounded flex">
                    <CheckmarkIcon />
                  </label>
                ) : (
                  <div className="w-5 h-5 cursor-pointer bg-[#C9CBD1]/70 rounded" />
                )}
              </div>
            )}

            <div hidden={!!onToggle || !user.id}>
              {/* Clickable Area */}
              <div
                className="absolute inset-0 cursor-pointer visible"
                onClick={() => {
                  // Just enlarge image on mobile
                  if (isMobileOnly) {
                    setIsImageModalVisible(true)
                    return
                  }

                  if (setModalVisible) {
                    setModalVisible(true)
                  } else {
                    setModalOpen(true)
                  }
                }}
              ></div>

              <div className={`absolute top-2.5 right-2.5 ${popVisible ? "block" : "hidden"}`}>
                <AlbumSelect work={work} />
              </div>

              {/* Options Layer */}
              <div
                className={`
                  absolute bottom-2.5 right-2.5 justify-end rounded-sm flex text-white items-center max-w-full
                  ${popVisible ? "visible" : "invisible"}
                `}
              >
                <div className="flex gap-1 flex-wrap">
                  {/* Privacy Button */}
                  <Tooltip content={isOpenImage ? "切换为不公开" : "切换为公开"}>
                    <div>
                      {showEye && (
                        <button className="h-[28px] w-[28px] flex justify-center items-center bg-black bg-opacity-50 transition rounded-sm cursor-pointer stroke-gray-400">
                          {isOpenImage ? (
                            // 需要隐藏操作
                            <IconEye onClick={() => handleOpenImage(false)} />
                          ) : (
                            // 需要公开操作
                            <IconEyeClose onClick={() => handleOpenImage(true)} />
                          )}
                        </button>
                      )}
                    </div>
                  </Tooltip>

                  {/* Like Button */}
                  {/* <Tooltip content={currLike ? "切换为不喜欢" : "切换为喜欢"}>
                    <div className="h-[28px] w-[28px] bg-black bg-opacity-50 rounded-sm flex items-center justify-center ">
                      <IconHeard
                        fill={currLike ? "#E93247" : "white"}
                        className="transition  cursor-pointer"
                        onClick={() =>
                          likeOrUnlike(currLike || false, work.id || "", workIndex, work)
                        }
                      ></IconHeard>
                    </div>
                  </Tooltip> */}

                  {/* Repaint Button */}
                  <Tooltip content={"图像重绘"}>
                    <div>
                      {work.seed && work.seed !== -1 && (
                        <ImageRepainting
                          buttonContent={
                            <IconRepaint
                              className="bg-black bg-opacity-50 rounded-sm transition cursor-pointer "
                              fill={"white"}
                            ></IconRepaint>
                          }
                          work={work}
                        />
                      )}
                    </div>
                  </Tooltip>

                  {/* Other Options */}
                  <CardOption
                    showEye={showEye}
                    showDelete={showDelete}
                    showCreate={showCreate}
                    isOpenImage={isOpenImage}
                    work={work}
                    task={task as V1Task}
                    handleOptionEnterChange={handleOptionEnterChange}
                    handleOpenImage={handleOpenImage}
                    onToggleDelete={onToggleDelete}
                    iconClassName="flex-1"
                  ></CardOption>
                </div>
              </div>
            </div>

            {/* 如果modalContent为空的话，则使用这里的默认Modal值，如果为其他的比如<></>就不使用默认Modal */}
            {ModalContent ?? (
              <ModalScaleImg
                open={modalOpen}
                task={task}
                work={work}
                taskChangeDisable="both"
                onClose={() => {
                  setModalOpen(false)
                }}
              />

              // <Modal
              //   open={modalOpen}
              //   task={task}
              //   work={work}
              //   onClose={() => {
              //     setModalOpen(false)
              //   }}
              // />
            )}
          </div>
        )}
      </>
    )
  }
)

export default Card
