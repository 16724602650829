import { toastError } from "@/common/Toast"
import { isMobileOnly } from "react-device-detect"

interface NormalInputProps {
  value: string
  onChange?: (text: string) => void
  placeholder?: string
}
const NormalInput = ({ value, onChange, placeholder }: NormalInputProps) => {
  return (
    <textarea
      className="bg-[#0C0C1A] rounded w-full p-[10px] resize-none h-full align-middle app_scrollbar"
      name="description"
      placeholder={placeholder}
      maxLength={1000}
      value={value}
      onCompositionEnd={async () => {
        if (isMobileOnly) {
          onChange?.(value)
        }
      }}
      onChange={async ({ target }) => {
        onChange?.(target.value)
        if (target.value.length >= target.maxLength) {
          toastError(`输入已达上限！请输入${target.maxLength}以内的字符`)
        }
      }}
    ></textarea>
  )
}

export default NormalInput
