import { inSensitivePeriod } from "@/utils/date"
import { makeAutoObservable } from "mobx"

class Profile {
  profileEditorVisible = false
  constructor() {
    makeAutoObservable(this)
  }
  toggleProfileEditorVisible() {
    if (inSensitivePeriod(true)) {
      return
    }
    this.profileEditorVisible = !this.profileEditorVisible
  }
}

export default new Profile()
