const bilicmReportData = () => {
  try {
    ;(window as any)?.bilicm?.mbase?.report_data()
  } catch (error) {
    console.log(error)
  }
}

const baiduTrack = {
  submitSuccess: () => {
    try {
      ;(window as any)._agl && (window as any)._agl.push(["track", ["success", { t: 3 }]])
    } catch (error) {
      console.log(error)
    }
  },
}

export { bilicmReportData, baiduTrack }
