import { V1TemplateData } from "@/generated/api/data-contracts"
import InputElement from "./components/InputElement"

interface DescribeImageProps {
  value: string
  onChange?: (text: string) => void
  usingTemplatePromptInput?: boolean
  template?: V1TemplateData
}
const DescribeImage = ({
  value,
  onChange,
  usingTemplatePromptInput,
  template,
}: DescribeImageProps) => {
  const templatePrompt = template?.prompt
  const templateIntro = template?.intro

  return (
    <InputElement
      usingTemplatePromptInput={usingTemplatePromptInput || false}
      value={value}
      onChange={onChange}
      templatePrompt={templatePrompt}
      placeholder="请输入图片描述"
      templateIntro={templateIntro}
    />
  )
}

export default DescribeImage
