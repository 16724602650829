import { computed, observable, runInAction } from "mobx"
import { StudioPagesStoreType } from "../types"
import { V1Task, V1TaskStatus } from "@/generated/api/data-contracts"
import _ from "lodash"
import { api } from "@/services/api"

const studioPagesStoreInitData: StudioPagesStoreType = {
  prompt: "",
  model: "",
  originalModel: "",
  modelVersion: "TIMv5",
  modelPattern: {
    portrait: false,
    art: false,
  },
  ratio: "1:1",
  negativeTextPrompt: "",
  isPrivate: false,
  isHigh: false,
  currentTaskIsHigh: false, // 这个值应该是锁死的，当前正在运行/运行结束的任务的优先级
  referPower: "",
  referencedImage: "",
  workId: "", // 用于图片再次生成
  autogeneration: false, //! 默认为false，当送其他页面跳转过来，需要立即创建的时候需要用到，创建后需要立马关闭掉
  runingTasks: [],
  taskHistory: [],
  privateGenEditable: false,
  fastGenEditable: false,
  settingsDataReady: false,
}

// 创作中心的缓存数据，要求不刷新切换页面后回显
const store = observable.box<StudioPagesStoreType>(studioPagesStoreInitData)

const isProcessing = computed(() => {
  const { runingTasks } = store.get()
  return runingTasks.some((item) => {
    const isRunning = item.status === V1TaskStatus.TaskStatusRunning
    const isPending = item.status === V1TaskStatus.TaskStatusPending
    return isRunning || isPending
  })
})

const changeTaskHistory = (dataOrFn: ((_: V1Task[]) => V1Task[]) | V1Task[]) => {
  const { taskHistory, ...other } = store.get()
  runInAction(() => {
    store.set({
      ...other,
      taskHistory: _.isFunction(dataOrFn) ? dataOrFn?.(taskHistory) : dataOrFn,
    })
  })
}

const changeTaskHistoryOfId = (id: string, task: V1Task) => {
  const { taskHistory, ...other } = store.get()
  const index = _.findIndex(taskHistory, { id })
  if (index >= 0) {
    taskHistory[index] = task
  }

  runInAction(() => {
    store.set({
      ...other,
      taskHistory,
    })
  })
}

const addRuningTask = (data: V1Task) => {
  const { runingTasks, taskHistory, ...other } = store.get()

  const newRuningTasks: V1Task[] = []
  const success: V1Task[] = []

  runingTasks.map((item) => {
    if (
      item?.status &&
      [V1TaskStatus.TaskStatusSuccess, V1TaskStatus.TaskStatusFailed].includes(item?.status)
    ) {
      success.push(item)
    } else {
      newRuningTasks.push(item)
    }
  })
  newRuningTasks.unshift(data)

  runInAction(() => {
    store.set({
      ...other,
      taskHistory: [...success, ...taskHistory],
      runingTasks: newRuningTasks,
    })
  })
}

const changeRuningTasks = (dataOrFn: ((_: V1Task[]) => V1Task[]) | V1Task[]) => {
  const { runingTasks, ...other } = store.get()
  runInAction(() => {
    store.set({
      ...other,
      runingTasks: _.isFunction(dataOrFn) ? dataOrFn?.(runingTasks) : dataOrFn,
    })
  })
}

const changeRuningTasksOfId = (id: string, task: V1Task) => {
  const { runingTasks, taskHistory, ...other } = store.get()
  const index = _.findIndex(runingTasks, { id })
  if (index >= 0) {
    runingTasks[index] = task
  }

  store.set({
    ...other,
    taskHistory,
    runingTasks,
  })
}

const updateSettings = async () => {
  const { data } = await api.getStudioSettings()
  const { fastGenEditable, fastGenEnabled, privateGenEditable, privateGenEnabled } =
    data?.data || {}
  store.set({
    ...store.get(),
    privateGenEditable,
    fastGenEditable,
    isPrivate: privateGenEnabled,
    isHigh: fastGenEnabled,
    settingsDataReady: true,
  })
}

//更新store 接受函数回调或值
const setStore = (
  dataOrFn: ((_: StudioPagesStoreType) => StudioPagesStoreType) | Partial<StudioPagesStoreType>
) => {
  if (_.isFunction(dataOrFn)) {
    store.set({
      ...dataOrFn?.(store.get()),
    })
  } else {
    store.set({
      ...store.get(),
      ...dataOrFn,
    })
  }
}

// 初始化store
const init = async (
  dataOrFn:
    | ((_: StudioPagesStoreType) => Partial<StudioPagesStoreType>)
    | Partial<StudioPagesStoreType> = {}
) => {
  // 默认参数放在这里
  runInAction(() => {
    store.set({
      ...studioPagesStoreInitData,
      ...(_.isFunction(dataOrFn) ? dataOrFn?.(store.get()) : dataOrFn),
    })
    updateSettings() // 更新isHigh isPrivate
  })
}

const studioPagesStore = {
  store,
  setStore,
  init,
  changeTaskHistory,
  changeTaskHistoryOfId,
  changeRuningTasks,
  changeRuningTasksOfId,
  addRuningTask,
  updateSettings,
  isProcessing,
}

export default studioPagesStore
