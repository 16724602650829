import illegalworkURL from "@/assets/illegalwork.png"
import illegalworkbgURL from "@/assets/illegalworkbg.png"
import LongPressCopy from "./LongPressCopy"

export function ErrorImage(props: ErrorImageProps) {
  const { className, style, id, URL, taskId = "" } = props
  return (
    <section
      data-id={id}
      data-url={URL}
      className={
        "flex flex-col justify-center items-center h-full min-h-[100px] " + (className || "")
      }
      style={{ background: `url(${illegalworkbgURL}) center`, ...style }}
    >
      <LongPressCopy text={taskId}>
        <img className="w-[55px] h-[55px]" src={illegalworkURL} />
        <p className="mt-[4px] text-white text-xs whitespace-nowrap">加载失败，请刷新一下～</p>
      </LongPressCopy>
    </section>
  )
}

export interface ErrorImageProps {
  className?: string
  style?: React.CSSProperties
  id?: string
  URL?: string
  taskId?: string
}
