import Categories from "./Categories"

interface ContentSwitcherProps {
  actionComponent?: React.ReactNode
}
const ContentSwitcher = ({ actionComponent }: ContentSwitcherProps) => {
  return (
    <div className="py-5 flex justify-between flex-wrap">
      <Categories />

      {actionComponent && (
        <div className="mt-4 md:mt-0 flex items-center gap-x-5">{actionComponent}</div>
      )}
    </div>
  )
}

export default ContentSwitcher
