import { ReactNode, createContext } from "react"
const store = {
  refresh: () => {},
  refreshUserInfo: () => {},
}
export const WorkContext = createContext(store)
interface Props {
  children: ReactNode
  refresh: () => void
  refreshUserInfo?: () => void
}
export function WorkProvider({ children, refresh, refreshUserInfo }: Props) {
  return (
    <WorkContext.Provider value={{ refresh, refreshUserInfo: refreshUserInfo ?? (() => {}) }}>
      {children}
    </WorkContext.Provider>
  )
}
