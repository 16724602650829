import { observable } from "mobx"

const store = observable.box<{
  shopModalIsOpen: boolean
}>({
  shopModalIsOpen: false,
})

const shopModalStore = {
  store,
  // 初始化store
  init: () => {
    // 默认参数放在这里
    store.set({
      shopModalIsOpen: true,
    })
  },
  openShopModal: () => {
    store.set({
      shopModalIsOpen: true,
    })
  },
  closeShopModal: () => {
    store.set({
      shopModalIsOpen: false,
    })
  },
}

export default shopModalStore
