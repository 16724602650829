import { V1TemplateCategoryMeta, V1TemplateData } from "@/generated/api/data-contracts"
import { getTemplateCategories, getTemplatesForHomePage } from "@/services/home"
import { observable } from "mobx"
import { TEMPLATESTYPE, V5_MODELS } from "@/constants"
import { isMobileOnly } from "react-device-detect"
import { toastInfo } from "@/common/Toast"
import _ from "lodash"

const store = observable.box<{
  templates: V1TemplateData[]
  templatesCenter: Record<TEMPLATESTYPE, V1TemplateData[]>
  templateCategories: V1TemplateCategoryMeta[]
  templatesStudio: V1TemplateData[]
  searchTemplates: V1TemplateData[]
  templateModel: string
}>({
  templates: [],
  templatesCenter: {
    [TEMPLATESTYPE.Recommand]: [],
    [TEMPLATESTYPE.Hot]: [],
    [TEMPLATESTYPE.Character]: [],
    [TEMPLATESTYPE.Other]: [],
  },
  templateCategories: [],
  templatesStudio: [],
  searchTemplates: [],
  templateModel: "auto-tianime-default",
})

export const formatTemplatesModel = (data?: V1TemplateData[]) => {
  const xieshiModles = [
    "liujin-chahua-2",
    "tongyong-chatu",
    "pingmian-chahua",
    "shouban-sheji",
    "tongyong-jichu",
    "GuoFengGuoChao",
    "guangying-zengqiang",
    "TongYongXieShi2",
  ]

  // const dongmanModles = ["KeAiXianTiao", "gainian-changjing"]
  const formatedData = data?.map((item: V1TemplateData) => {
    const itemModal = item?.model
    if (itemModal && !V5_MODELS.includes(itemModal)) {
      if (itemModal && xieshiModles.includes(itemModal)) {
        item = {
          ...item,
          model: "TongYongXieShi2",
        }
      } else {
        item = {
          ...item,
          model: "DongManFengGe",
        }
      }
    }
    return item
  })
  return formatedData
}

const filterTemplateData = (data?: V1TemplateData[]) => {
  if (data?.length === 0) return []
  const filteredData = data
    ?.map((item: V1TemplateData) => {
      // 过滤空字符串的exampleImages
      if (Array.isArray(item.exampleImages)) {
        item.exampleImages = item.exampleImages.filter(
          (img: string) => typeof img === "string" && img.trim() !== ""
        )
      }
      // 如果coverImage是空字符串，则设置为空
      if (item.coverImage && item.coverImage.trim() === "") {
        item.coverImage = ""
      }
      return item
    })
    .filter(
      (item: V1TemplateData) =>
        (item.exampleImages && item.exampleImages.length > 0) ||
        (item.coverImage && item.coverImage !== "")
    )
  return formatTemplatesModel(filteredData)
}

const templateStore = {
  store,
  fetchTemplates: async () => {
    const { templates } = store.get()
    if (templates?.length) {
      return
    }
    try {
      const { data, success, errorCode } = await getTemplatesForHomePage({
        page: 1,
        pageSize: 99999,
      })

      if (success && data?.templates) {
        store.set({
          ...store.get(),
          templates: filterTemplateData(data?.templates) || [],
        })
      } else {
        throw new Error(errorCode)
      }
    } catch (error) {
      console.log(error, "error")
    }
  },
  fetchTemplatesCenter: async ({ params, type }: any) => {
    try {
      const { data, success, errorCode } = await getTemplatesForHomePage({
        page: 1,
        pageSize: type === "recommand" ? 12 : 99999,
        ...params,
      })

      if (success && data?.templates) {
        let templatesData = filterTemplateData(data?.templates)

        // 其他分类下排除模板类型为空数据
        if (type === "other") {
          templatesData = templatesData?.filter((item) => {
            if (item.category) return item
          })
        }

        store.set({
          ...store.get(),
          templatesCenter: {
            ...store.get().templatesCenter,
            [type]: templatesData,
          },
        })
      } else {
        throw new Error(errorCode)
      }
    } catch (error) {
      console.log(error, "error")
    }
  },
  fetchTemplateCategories: async () => {
    try {
      const { data, success } = await getTemplateCategories()

      if (success && data?.categories) {
        store.set({
          ...store.get(),
          templateCategories: data?.categories,
        })
      } else {
        throw new Error("fetchTemplateCategories error")
      }
    } catch (error) {
      console.log(error, "error")
    }
  },
  getTemplateById: (id: number | undefined | string) => {
    if (!id) return undefined

    if (typeof id === "string") {
      id = Number(id)
    }

    const { templates } = store.get()
    return templates.find((item) => item.id === id)
  },
  //  创作页面模板推荐
  fetchStudoRecommendTemplates: async (params: any = {}, shouldFetchRecommand = true) => {
    try {
      const fetchRecommand = shouldFetchRecommand
        ? getTemplatesForHomePage({
            page: 1,
            pageSize: 12,
            isRecommand: true,
            model: params?.model,
          })
        : Promise.resolve({ data: { templates: [] } })
      const fetchHotList = await getTemplatesForHomePage({
        page: 1,
        pageSize: isMobileOnly ? 99999 : 50,
        ...params,
      })
      Promise.all([fetchRecommand, fetchHotList]).then(([resp1, resp2]) => {
        let storeTemplatesStudio = store.get()?.templatesStudio
        const templateModel = store.get()?.templateModel

        if (templateModel !== params?.model) {
          storeTemplatesStudio = []
        }
        let arrResp1 = resp1?.data?.templates || []
        let arrResp2 = resp2?.data?.templates || []
        let arr: V1TemplateData[] = [...arrResp1, ...arrResp2]
        arr = formatTemplatesModel(arr) || []

        let uniqueArr =
          Array.from(new Set(arr?.map((item) => item?.id)))
            ?.map((id) => {
              return arr?.find((item) => item.id === id)
            })
            .filter((item) => !!item) || []

        const templatesStudio = [...storeTemplatesStudio, ...(uniqueArr as any)]
        const uniqTemplatesStudio = _.uniqBy(templatesStudio, "id")

        store.set({
          ...store.get(),
          templateModel: params?.model,
          templatesStudio: uniqTemplatesStudio,
        })
      })
    } catch (error) {
      console.log(error, "error")
    }
  },

  // 模板搜索
  fetchSearchTemplates: async (params?: any) => {
    try {
      const { data, success, errorCode } = await getTemplatesForHomePage({
        page: 1,
        pageSize: 99999,
        ...params,
      })

      if (!params?.name) {
        store.set({
          ...store.get(),
          searchTemplates: [],
        })
        toastInfo("查询模板为空")
        return
      }
      if (success && data?.templates) {
        store.set({
          ...store.get(),
          searchTemplates: filterTemplateData(data?.templates) || [],
        })
      } else {
        toastInfo("查询模板为空")
        throw new Error(errorCode)
      }
    } catch (error) {
      console.log(error, "error")
    }
  },
}

export default templateStore
