import PrivateIcon from "@/assets/studio/mobile/privacy_private_m.svg"
import PublicIcon from "@/assets/studio/mobile/privacy_public_m.svg"
import ActionIcon from "./components/ActionIcon"

interface PrivacyStatusProps {
  isPrivate: boolean
  onClick?: () => void
  disabled?: boolean
  disabledMessage?: string
}
const PrivacyStatus = ({ isPrivate, onClick, disabled, disabledMessage }: PrivacyStatusProps) => {
  const iconSrc = isPrivate ? PrivateIcon : PublicIcon
  return (
    <ActionIcon
      src={iconSrc}
      onClick={() => onClick?.()}
      disabled={disabled}
      disabledMessage={disabledMessage}
    />
  )
}

export default PrivacyStatus
