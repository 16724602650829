import { ReactComponent as CheckmarkIcon } from "@/assets/checkmark.svg"

const Checkbox = ({ checked, onClick }: { checked: boolean; onClick?: () => void }) => {
  const bgCls = checked ? "bg-[#5A14F0]" : "bg-[#C9CBD1]/70"
  return (
    <div
      className={`w-5 h-5 cursor-pointer rounded flex items-center justify-center ${bgCls}`}
      onClick={onClick}
    >
      {checked ? <CheckmarkIcon /> : null}
    </div>
  )
}

interface SelectLayerProps {
  checked: boolean
  onClick?: () => void
}
const SelectLayer = ({ checked, onClick }: SelectLayerProps) => {
  return (
    <div
      className="absolute inset-0 bg-black bg-opacity-50"
      onClick={(e) => {
        e.stopPropagation()
        onClick?.()
      }}
    >
      <div className="absolute top-2.5 right-2.5">
        <Checkbox checked={checked} />
      </div>
    </div>
  )
}

export default SelectLayer
