import MobileBottomModal from "@/Studio/components/MobileBottomModal"
import { Input } from "@arco-design/web-react"
import { useEffect, useRef } from "react"
import GenerateButton from "../GenerateButton"

interface ModifyAllModalProps {
  onClose?: () => void
  value?: string
  onChange?: (text: string) => void
  onGenerateClick?: () => void
}
const ModifyAllModal = ({ onClose, value, onChange, onGenerateClick }: ModifyAllModalProps) => {
  const textareaRef = useRef<any>(null)

  // Focus
  useEffect(() => {
    textareaRef.current?.focus()
  }, [])

  const handleInputChange = (val: string) => {
    onChange?.(val)
  }

  return (
    <MobileBottomModal
      titleHidden
      backgroundCloseDisabled
      modalStyle={{
        backgroundColor: "#212134",
      }}
    >
      {/* Cancel Button */}
      <div
        className="inline-flex items-center justify-center leading-[30px] px-5 bg-[#2E2E4A] rounded-full"
        onClick={onClose}
      >
        取消
      </div>

      {/* Input */}
      <div className="flex items-center mt-2.5">
        {/* Textarea */}
        <Input.TextArea
          onChange={handleInputChange}
          ref={textareaRef}
          autoSize
          style={{
            backgroundColor: "transparent",
            border: "none",
            resize: "none",
            color: "#FFFFFF",
            maxHeight: "300px",
            overflowY: "auto",
            paddingLeft: "0px",
            minHeight: "200px",
          }}
          value={value}
        />

        {/* Generate Button */}
        <div className="self-end">
          <GenerateButton
            onClick={() => {
              onGenerateClick?.()
            }}
          />
        </div>
      </div>
    </MobileBottomModal>
  )
}

export default ModifyAllModal
