import copyIcon from "@/assets/studio/copy.svg"
import { toastInfo } from "@/common/Toast"
import CopyToClipboard from "react-copy-to-clipboard"

interface TaskPromptProps {
  prompt: string
  onCopy?: () => void
}
const TaskPrompt = ({ prompt, onCopy }: TaskPromptProps) => {
  return (
    <div className="mt-5 md:mt-[10px] p-0 md:p-[10px] rounded bg-white bg-opacity-0 md:bg-opacity-5 flex">
      {/* Prompt Text */}
      <div className="text-white text-opacity-50 md:text-opacity-80 flex-1 text-[14px] leading-[22px]">
        {prompt}
      </div>

      {/* Copy Button */}
      <CopyToClipboard
        text={prompt}
        onCopy={() => {
          toastInfo("已复制")
        }}
      >
        <img
          src={copyIcon}
          onClick={() => onCopy?.()}
          alt=""
          className="hidden md:block w-[18px] h-[22px] object-contain cursor-pointer ml-5 self-end"
        />
      </CopyToClipboard>
    </div>
  )
}

export default TaskPrompt
