import { V1CoinRedeemInfo } from "@/generated/api/data-contracts"
import comingSoon from "@/assets/coming_soon.png"
import redeemgoods1 from "@/assets/shopModal/redeemgoods1.png"
import redeemgoods2 from "@/assets/shopModal/redeemgoods2.png"
import redeemgoods3 from "@/assets/shopModal/redeemgoods3.png"
import redeemgoods4 from "@/assets/shopModal/redeemgoods4.png"
import { ReactComponent as IconUploadLoading } from "@/assets/loading-dash.svg"
import CheckAuth from "@/components/common/CheckAuth"
import { isMobileOnly } from "react-device-detect"
import _ from "lodash"

interface LoadObj {
  [key: number]: boolean
}

const ShopConversionList = ({
  userId,
  shopConversionListData,
  cb,
  loadObj,
}: {
  userId?: string
  shopConversionListData: V1CoinRedeemInfo[] | string[]
  cb: (id: number) => void
  loadObj: LoadObj
}) => {
  let newShopConversionListData = [...shopConversionListData]

  if (!isMobileOnly) {
    newShopConversionListData = [...shopConversionListData, "comingSoon"]
  }
  const imgObj: any = {
    10: redeemgoods1,
    11: redeemgoods2,
    12: redeemgoods3,
    13: redeemgoods4,
  }
  return (
    <div className={`grid gap-4  ${isMobileOnly ? "grid-cols-2" : "grid-cols-5"}`}>
      {newShopConversionListData?.map((item) => {
        const { redeemInfo = {}, canReceive } = (item as V1CoinRedeemInfo) || {}
        const expirationDate =
          redeemInfo?.redeemBonus?.generationCountQuota?.validSecondsAfterReceived
        const expirationDay = expirationDate ? expirationDate / 24 / 3600 : ""

        return (
          <div
            key={redeemInfo?.id}
            className="bg-[#0D031B] flex flex-col items-center w-full rounded justify-center"
          >
            {item === "comingSoon" ? (
              <>
                <img src={comingSoon}></img>
                <div className="text-center font-normal text-2xl  font-weight-400 opacity-25">
                  敬请期待
                </div>
              </>
            ) : (
              <>
                <img src={imgObj[redeemInfo?.id as number]}></img>
                <div className="bg-[#161623] px-[10px] py-[10px] w-full rounded-b">
                  <div className="text-base font-normal">
                    标准生成 {redeemInfo?.redeemBonus?.generationCountQuota?.count || 0} 次
                  </div>
                  <div className="text-xs font-normal opacity-50 mt-2.5">{`(有效期 ${expirationDay} 天)`}</div>

                  <CheckAuth
                    needLogin={true}
                    onClick={_.debounce(() => {
                      if (!canReceive) return
                      if (redeemInfo?.id !== undefined) {
                        cb && cb(redeemInfo.id)
                      }
                    }, 300)}
                  >
                    <button
                      disabled={
                        (redeemInfo?.id !== undefined && loadObj?.[redeemInfo?.id]) ||
                        (userId ? !canReceive : false)
                      }
                      className="bg-[#4C1FFF] flex w-full justify-center items-center  py-1.5 rounded mt-3.5 cursor-pointer disabled:cursor-not-allowed disabled:brightness-50"
                    >
                      {redeemInfo?.id !== undefined && loadObj?.[redeemInfo?.id] ? (
                        <IconUploadLoading className="animate-spin mr-2" />
                      ) : (
                        ""
                      )}
                      <span>{redeemInfo?.coinCountLimit} 灵石</span>
                    </button>
                  </CheckAuth>
                </div>
              </>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default ShopConversionList
