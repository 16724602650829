import { ReactNode } from "react"
import { ReactComponent as TootipIcon } from "@/assets/creation/tooltip.svg"
import { Tooltip } from "@arco-design/web-react"
export function TagInput(props: TagInputrProps) {
  const {
    tagsList,
    // 图标
    icon,
    // 提示语
    tipInfo,
    //  图片描述和 不公开的描述间切换
    removeHandle = () => {},
    //  是否能编辑
    editableHandle = () => {},
    //  是否是不公开的描述
    isPrivate = false,
  } = props
  return (
    <div className="md:bg-[#161623] min-h-[40px] md:p-[5px] mt-[10px] md:mt-[0px] text-white w-[100%] rounded-[4px] flex">
      <div className="flex flex-1 flex-wrap">
        {tagsList?.map((item, index) => (
          <div
            className={`${
              item?.editable == true && !isPrivate
                ? "bg-[#4c1fff]"
                : "border-[1px] border-zinc-500 md:border-[0]"
            } ${"rounded-[4px] color-white flex pl-2 leading-[30px] mr-[5px] min-h-[30px] justify-center mb-1 items-center cursor-pointer"} `}
            key={item.label}
          >
            <span
              onClick={() => {
                let temp = [...tagsList]
                temp[index].editable = !temp[index].editable
                editableHandle(temp)
              }}
              className="break-words"
            >
              <p>{item.label}</p>
            </span>
            <span
              onClick={() => {
                const newTagList = tagsList.filter((t) => t.label != item?.label)
                removeHandle(newTagList, item)
              }}
            >
              {icon}
            </span>
          </div>
        ))}
      </div>
      <div className="w-[20px] h-full bg-white ml-[15px]">
        <Tooltip position="tr" trigger="hover" content={tipInfo}>
          <TootipIcon className="absolute md:right-[5px] md:top-[10px] hidden md:block" />
        </Tooltip>
      </div>
    </div>
  )
}

export interface TagInputrProps {
  tagsList: Array<CheckObj>
  associationStr?: string
  icon: ReactNode
  tipInfo: string
  isPrivate?: boolean
  removeHandle?: (newTags: Array<CheckObj>, associationTags: CheckObj) => void
  editableHandle?: (newTags: Array<CheckObj>) => void
}

export interface CheckObj {
  label: string
  editable: boolean
}
