import { useState, useEffect, useRef } from "react"
import RecommendationHeader from "./RecommendationHeader"
import RecommendationList from "./RecommendationList"
import { isMobileOnly } from "react-device-detect"
import templateStore from "@/store/templateStore"
import { ReactComponent as IconLoading } from "@/assets/loading.svg"
import { findModelPattern } from "@/utils"

interface LoadParams {
  page: number
  model?: string
}
interface TemplateRecommendationProps {
  model?: string
  modelPattern?: {
    portrait: boolean
    art: boolean
  }
}

const TemplateRecommendation = (props: TemplateRecommendationProps) => {
  const { model, modelPattern } = props

  const [isOpen, setIsOpen] = useState(isMobileOnly ? false : true)
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)

  const handleHeaderClick = () => {
    setIsOpen(!isOpen)
  }
  const templateRecommendRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (templateRecommendRef.current) {
      templateRecommendRef.current.addEventListener("scroll", handleScroll)
    }
    return () => {
      if (templateRecommendRef.current) {
        templateRecommendRef.current.removeEventListener("scroll", handleScroll)
      }
    }
  }, [currentPage, model, modelPattern])

  const scrollLoad = async (params: LoadParams) => {
    setIsLoading(true)
    // （推荐+hot)合并接口, hot模板只有第一页查询时需要 + 推荐
    const shouldFetchRecommand = params?.page === 1
    await templateStore.fetchStudoRecommendTemplates(params, shouldFetchRecommand)
  }

  const handleScroll = () => {
    if (!isLoading && templateRecommendRef.current) {
      const container = templateRecommendRef.current
      if (container.scrollHeight - container.scrollTop === container.clientHeight) {
        const nextPage = currentPage + 1
        const newModel = findModelPattern(model, modelPattern)
        scrollLoad({ page: nextPage, model: newModel })
        setIsLoading(false)
        setCurrentPage(nextPage)
      }
    }
  }

  const widthCls = isMobileOnly ? "w-full" : isOpen ? "w-56" : "w-[68px]"
  const headerIconOnly = isMobileOnly ? false : !isOpen

  const Load = ({ isLoading }: { isLoading: boolean }) => {
    if (!isLoading) return <></>
    return (
      <div className="flex justify-center pt-3">
        <IconLoading className="animate-spin" />
      </div>
    )
  }

  return (
    <div
      className={`p-2 max-h-[300px] md:max-h-fit ${widthCls} md:p-5 bg-[#161623] flex flex-col transition-[width] duration-300`}
    >
      {/* Header */}
      <RecommendationHeader
        isExpanded={isOpen}
        onClick={handleHeaderClick}
        iconOnly={headerIconOnly}
      />

      {/* List */}
      {isOpen && (
        <div
          ref={templateRecommendRef}
          className="flex-1 mt-[14px] overflow-y-auto scrollbar-hidden"
        >
          <RecommendationList
            model={model}
            modelPattern={modelPattern}
            onTemplateConfirm={() => {
              // In mobile, when confirmed to use a template, close the recommendation list
              if (isMobileOnly) setIsOpen(false)
            }}
          />
          <Load isLoading={isLoading} />
        </div>
      )}
    </div>
  )
}

export default TemplateRecommendation
