import { observer } from "mobx-react-lite"
import { Link, NavLink, useNavigate } from "react-router-dom"
import * as store from "./store"
import { logout } from "./services/login"
import { ReactComponent as LogoIcon } from "./assets/logo.svg"
import { ReactComponent as MoreIcon } from "./assets/home/more.svg"
import { ReactComponent as SpiritStonesIcon } from "@/assets/spirit-stones.svg"
import { LogoutComponent } from "./common/Logout"
import { Avatar } from "./common/Avatar"
import ShopModal from "./common/ShopModal"

import { V1ProductInfo } from "./generated/api/data-contracts"
import { Tooltip } from "@arco-design/web-react"
import icon1URL from "@/assets/icon1.png"
import homeIcon from "@/assets/home/home.svg"

import studioIcon from "@/assets/home/studio.svg"
import worksIcon from "@/assets/home/works.svg"
import magicIcon from "@/assets/magic.svg"
import subscriptionIcon from "@/assets/home/subscription.svg"
import userguideIcon from "@/assets/userguide.svg"
import "./Navbar.less"
import studioPagesStore from "./store/studioPagesStore"
import worksPagesStore from "./store/worksPagesStore"
import Cookies from "js-cookie"
import menuStore from "./store/menuStore"
import shopModalStore from "@/store/shopModalStore"
import { formatSpiritStonesNum } from "@/utils"
import { isMobile } from "react-device-detect"

// import { useNavigate } from "react-router-dom"

const links = [
  {
    to: "/",
    iconSrc: homeIcon,
    label: "首页",
  },
  {
    to: "/studio",
    iconSrc: studioIcon,
    label: "创作页面",
  },

  {
    to: "/templateCenter",
    iconSrc: magicIcon,
    label: "模版中心",
  },
  {
    to: "/works",
    iconSrc: worksIcon,
    label: "作品中心",
  },

  {
    to: "/subscription",
    iconSrc: subscriptionIcon,
    label: "订阅方案",
  },
  {
    to: "/user-guide",
    iconSrc: userguideIcon,
    label: "用户指南",
  },
]
type LinkItemProps = {
  to: string
  iconSrc: string
  label: string
  active?: boolean
}
const handleLinkClick = () => {
  document.documentElement.style.overflow = "auto"
  menuStore.closeMenu()
}

const LinkItem = ({ to, iconSrc, label }: LinkItemProps) => {
  return (
    <NavLink
      className={({ isActive }) => {
        return `${isActive ? "font-medium bg-[#6663FF]" : ""}
        flex items-center p-[10px] md:p-0 rounded-[4px] text-base relative group md:bg-transparent`
      }}
      to={to}
      aria-current
      onClick={handleLinkClick}
    >
      <img className=" w-5 h-5 mr-[10px] md:hidden" src={iconSrc} />
      {label}
      <div className="absolute -bottom-2 left-1/2 ml-[-7px] w-3.5 h-0.5 bg-[#4C1FFF] rounded-full hidden md:group-aria-[current]:block"></div>
    </NavLink>
  )
}

export const SIDER_MENU_TAILWIND_CLS = {
  width: `w-[250px]`,
  translateX: `translate-x-[250px]`,
}

type NavbarProps = {}
export default observer(function Navbar({}: NavbarProps) {
  const navigate = useNavigate()
  const { nick, avatar, id, subscribedProduct, coinRemainingCount } = store.user.get()
  // const drawBalance = accounts.find((a) => a.type === "draw")?.balance || 0
  const handleLogout = async () => {
    Cookies.remove("xica_token")
    await logout()
    store.refreshUser()
    studioPagesStore.init()
    worksPagesStore.init()
    location.replace("/")
  }

  const { siderMenuIsOpen } = menuStore.store.get()

  const isOpen = siderMenuIsOpen

  const { shopModalIsOpen } = shopModalStore.store.get()

  const Shop = () => {
    return (
      <div
        onClick={() => {
          shopModalStore.openShopModal()
        }}
        className="bg-[#6663FF] md:mb-0 mb-5 md:mr-4 h-8 rounded-[50px] flex justify-between items-center px-3 font-sans text-base font-normal cursor-pointer min-w-[55px]"
      >
        <SpiritStonesIcon />
        <span className="pl-1.5">
          {coinRemainingCount ? formatSpiritStonesNum(coinRemainingCount) : 0}
        </span>
      </div>
    )
  }
  // translatex for home_main element

  const handleLogoClick = () => {
    navigate("/")
  }

  return (
    <>
      <div
        className={`${
          isOpen ? "block" : "hidden"
        } fixed left-0 top-0 z-[800] w-full h-full bg-black bg-opacity-70 transition-all ease-out`}
        onClick={() => {
          menuStore.closeMenu()
        }}
      ></div>
      <div
        id="navbar"
        className={`
          ${isOpen ? "translate-x-0" : "-translate-x-full"}
          bg-gray-800 fixed left-0 top-0 h-full max-h-screen md:h-auto ${
            SIDER_MENU_TAILWIND_CLS.width
          } p-[20px] md:p-0 transition-all duration-300 md:translate-x-0 md:border-b md:border-[#f5f5f7] md:bg-[#ffffff] dark:bg-[#0C0C1A] dark:border-black md:w-full z-[999]
          `}
      >
        {/* <UrgentNotice /> */}
        <div className="flex h-full max-w-[1920px]  mx-auto  sm:px-6 lg:px-8 md:flex-row flex-col md:h-[60px] md:flex md:items-center">
          <div className="my-5 w-full justify-start md:mt-0 md:mb-0 md:w-auto md:justify-center flex items-center">
            <LogoIcon fill="currentColor" className="cursor-pointer" onClick={handleLogoClick} />
          </div>
          <nav className=" py-[10px] md:py-0 px-0 space-y-5 mt-[10px] md:mt-0 flex-col md:flex-row lg:ml-20 ml-0 md:px-4 lg:px-0 flex text-base lg:space-x-8 md:space-x-4 md:space-y-0">
            {links.map((link) => (
              <LinkItem
                key={link.to}
                to={link.to}
                iconSrc={link.iconSrc}
                label={link.label}
                // active={link.active}
              />
            ))}
            {/* <NavLink className="relative group aria-[current]:font-medium" to="/" aria-current>
              首页
              <div className="absolute -bottom-2 left-1/2 ml-[-7px] w-3.5 h-0.5 bg-[#4C1FFF] rounded-full hidden group-aria-[current]:block"></div>
            </NavLink>
            <NavLink className="relative group aria-[current]:font-medium" to="/studio" aria-current>
              创作页面
              <div className="absolute -bottom-2 left-1/2 ml-[-7px] w-3.5 h-0.5 bg-[#4C1FFF] rounded-full hidden group-aria-[current]:block"></div>
            </NavLink>
            <NavLink className="relative group aria-[current]:font-medium" to="/works" aria-current>
              作品中心
              <div className="absolute -bottom-2 left-1/2 ml-[-7px] w-3.5 h-0.5 bg-[#4C1FFF] rounded-full hidden group-aria-[current]:block"></div>
            </NavLink>
            <NavLink
              className="relative group aria-[current]:font-medium"
              to="/subscription"
              aria-current
            >
              订阅方案
              <div className="absolute -bottom-2 left-1/2 ml-[-7px] w-3.5 h-0.5 bg-[#4C1FFF] rounded-full hidden group-aria-[current]:block"></div>
            </NavLink>
            <NavLink
              className="relative group aria-[current]:font-medium"
              to="/user-guide"
              aria-current
            >
              用户指南
              <div className="absolute -bottom-2 left-1/2 ml-[-7px] w-3.5 h-0.5 bg-[#4C1FFF] rounded-full hidden group-aria-[current]:block"></div>
            </NavLink> */}
          </nav>
          <div className=" items-end flex-col md:flex-row mt-auto w-full md:ml-auto ml-0 md:mt-0 md:w-auto md:justify-center flex md:items-center ">
            {id ? (
              <>
                {!isMobile && (
                  <Link
                    to="/subscription"
                    onClick={() => {
                      handleLinkClick()
                    }}
                    className={`${
                      !subscribedProduct ? "hidden md:flex" : "hidden"
                    } mr-[16px] relative items-center pl-[40px] w-[192px] h-[32px] rounded-full bg-gradient-to-r from-[#EDEEFC] to-[#DFD3F4]`}
                  >
                    <img className="absolute left-[-16px] top-[-6px] w-[59px]" src={icon1URL} />
                    <span className="text-[14px] text-[#5A14F0]">开通会员畅享千张生成</span>
                  </Link>
                )}

                {/* 消息通知暂时隐藏，下个版本上 */}
                {/* <Notification /> */}

                <Shop />
                <Tooltip
                  position="br"
                  className="user-tooltip rounded-md overflow-hidden shadow-xl"
                  content={
                    <>
                      <LogoutComponent
                        subscriptionPlan={
                          store.user.get().subscribedProduct as V1ProductInfo | null
                        }
                        onLogout={handleLogout}
                        avatar={avatar}
                        nick={nick}
                        id={id}
                      />
                    </>
                  }
                >
                  <span className="flex items-center w-full md:w-auto">
                    <Avatar URL={avatar} className=" w-8 h-8" />
                    <div className=" w-32 ml-2 truncate text-[16px] md:hidden">{nick}</div>
                    <MoreIcon className=" md:hidden w-4 ml-auto justify-self-end" />
                  </span>
                </Tooltip>
                {/* <div className="ml-4 flex flex-wrap w-[100px]"> */}
                {/* <span className=" py-[2px] inline-flex leading-3 ">
                <span className="text-xs w-14 ">画力值</span>
                <BalanceDrawIcon className="ml-2" fill="currentColor" />
                <span className="ml-2 text-xs font-[num]">{drawBalance}</span>
              </span>
              <span className=" py-[2px]  inline-flex leading-3">
                <span className="text-xs w-14">灵感结晶</span>
                <AfflatusIcon className="ml-2" fill="currentColor" />
                <span className="ml-2 text-xs font-[num]">{drawBalance}</span>
              </span> */}
                {/* </div> */}
              </>
            ) : (
              <>
                {/* <Link to="/login/apply">
                  <button className="border rounded py-1.5 px-4 hover:opacity-90 active:opacity-80 mr-2">
                    资格申请
                  </button>
                </Link> */}
                <Shop />
                <Link to="/login">
                  <button className="border rounded py-1.5 px-4 hover:opacity-90 active:opacity-80 dark:bg-[#4C1FFF] border-[#4C1FFF] dark:text-[#fff] text-[#4C1FFF]">
                    注册 / 登录
                  </button>
                </Link>
              </>
            )}
            {shopModalIsOpen ? <ShopModal id={id} /> : ""}
          </div>
        </div>
      </div>
    </>
  )
})
