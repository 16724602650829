import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import errorMessageEN from "./en/error-message.json"
import errorMessageZH from "./zh/error-message.json"
import randomPromptEN from "./en/random-prompt.json"
import translationEN from "./en/translation.json"
import translationZH from "./zh/translation.json"
import randomPromptZH from "./zh/random-propmt.json"

export const resources = {
  en: {
    translation: translationEN,
    errorMessage: errorMessageEN,
    randomPrompt: randomPromptEN,
  },
  zh: {
    translation: translationZH,
    errorMessage: errorMessageZH,
    randomPrompt: randomPromptZH,
  },
}

export const defaultNS = "translation"

const languageDetector = new LanguageDetector(null, {
  order: [
    "querystring",
    "cookie",
    "localStorage",
    "sessionStorage",
    "navigator",
    "htmlTag",
    "path",
    "subdomain",
  ],
  lookupQuerystring: "lang",
})

i18n.use(languageDetector).use(initReactI18next).init({
  debug: true,
  resources,
  defaultNS,
  fallbackLng: "en",
})

export { i18n }
