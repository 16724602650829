import { useState } from "react"
import Avatar from "./Avatar"
import Name from "./Name"
import Tips from "./Tips"
import { profileSceneType } from "./types"
import { Spin } from "@arco-design/web-react"
import Footer from "./Footer"
import { api } from "@/services/api"
import { toastError } from "@/common/Toast"
import { useTranslation } from "react-i18next"
import "./LoginProfile.less"
import { observer } from "mobx-react-lite"

const Header = () => {
  return (
    <div className="py-5 md:py-[6px] pl-0 md:pl-5 pr-2.5">
      <div className="text-base leading-[22px] my-0 md:my-2.5 font-sans text-black text-opacity-80">
        编辑资料
      </div>
    </div>
  )
}

const scene: profileSceneType = "login"

const LoginProfile = observer(() => {
  const [name, setName] = useState<string | undefined>(undefined)
  const [nameError, setNameError] = useState<string | boolean>(false)
  const [avatarUrl, setAvatarUrl] = useState<string | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()

  const handleClose = () => {
    location.replace("/")
  }

  const handlConfirmClick = async () => {
    setIsLoading(true)
    try {
      const resp = await api.updateUserInfo({ nick: name, avatar: avatarUrl })
      if (resp.data.success) {
        handleClose()
      } else {
        const errorCode = resp.data?.errorCode
        if (errorCode) {
          toastError(t(`errorMessage:${errorCode}` as any))
        } else {
          toastError("修改失败")
        }
      }
    } catch (error) {
      console.error(error)
    } finally {
      setTimeout(() => {
        setIsLoading(false)
      }, 1000)
    }
  }

  const confirmButtonDisabled = !!nameError || !avatarUrl

  return (
    <div className="w-full pb-10 login_profile_container">
      <Spin loading={isLoading} block className="modal_spin">
        {/* Form Header */}
        <Header />

        <div className="px-0 md:px-10">
          {/* User Avatar */}
          <Avatar scene={scene} onChange={(avatarUrl) => setAvatarUrl(avatarUrl)} />

          {/* User Name */}
          <Name
            scene={scene}
            onChange={(name) => {
              setName(name)
            }}
            onInputError={(error) => {
              setNameError(error)
            }}
          />

          {/* Tips */}
          <Tips scene={scene} />

          <Footer
            onCancel={handleClose}
            cancelText="稍后再说"
            onConfirm={handlConfirmClick}
            confirmButtonDisabled={confirmButtonDisabled}
          />
        </div>
      </Spin>
    </div>
  )
})

export default LoginProfile
