import { observer } from "mobx-react-lite"
export default observer(function ({
  total,
  current,
  color = "bg-[#6663FF]",
  bufferColor = "bg-[#161623]",
  height = "18px",
}: {
  total: string | number
  current: string | number
  color?: string
  bufferColor?: string
  height?: string | number
  onClick?: () => void
}) {
  const width = ((Number(current) / Number(total)) * 100).toFixed(2) + "%"
  return (
    <div className="flex flex-col">
      {/*  进度条 */}
      <div className="mt-[10px] md:mt-[6px] flex">
        {/*  进度条背景色 */}
        <div className={`flex-1 md:w-[480px] ${bufferColor} rounded-[4px]`} style={{ height }}>
          {/* 进度条的实际内容 */}
          <div className={`${color} h-[100%] rounded-[4px]`} style={{ width }}></div>
        </div>
        <span className="ml-[20px] text-[10px] md:text-[14px] text-zinc-500 leading-[18px] h-[18px]">{`${current} / ${total}`}</span>
      </div>
    </div>
  )
})
