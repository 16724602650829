import { useState, useContext } from "react"
import { WorkContext } from "@/pages/Works/Provider"
import { useTranslation } from "react-i18next"
import { ReactComponent as ImageIcon } from "@/assets/image.svg"
import { ReactComponent as DeleteIcon } from "@/assets/delete.svg"
import { ReactComponent as RepaintIcon } from "@/assets/repaint.svg"
import { ReactComponent as ControlNetIcon } from "@/assets/control-net.svg"
import { ReactComponent as CopyIcon } from "@/assets/copy3.svg"
import { ReactComponent as IconEye } from "@/assets/eye.svg"
import { ReactComponent as IconEyeClose } from "@/assets/eye-close.svg"
import { ReactComponent as IconAvatar } from "@/assets/avatar.svg"
import { ReactComponent as IconWallpaper } from "@/assets/wallpaper.svg"
// import { ReactComponent as IconChaofen } from "@/assets/detail.svg"

import { ReactComponent as DangerIcon } from "@/assets/danger.svg"
import { toastInfo, toastSuccess, toastError } from "@/common/Toast"

import { handleDownload } from "@/utils"
import { V1Task, V1Work } from "@/generated/api/data-contracts"
import Report from "@/common/Report"
import { Popover, Modal, Button } from "@arco-design/web-react"
import { ReactComponent as DownloadIcon } from "@/assets/download.svg"
import { ReactComponent as OptionsIcon } from "@/assets/options.svg"
import { ReactComponent as MagicIcon } from "@/assets/magic2.svg"
import { ReactComponent as PicturesSeakIcon } from "@/assets/picture-speak.svg"
import { ImageSpeakInOtherPage } from "@/Studio/components/ImageSpeak"
import ImageRepainting from "@/Studio/components/ImageRepainting"
import { ControlNetInOtherPage } from "@/Studio/components/ControlNet/ControlNet"
import { UploadReferenceImageInOtherPage } from "@/Studio/components/UploadreferenceImage"
import GenerateAgainTask from "@/Studio/components/GenerateAgainTask"
import CopyToClipboard from "react-copy-to-clipboard"
import { api } from "@/services/api"
import "@/styles/reset-arco.less"
import { IconRight } from "@arco-design/web-react/icon"
import * as store from "@/store"

export default function CardOptiion({
  work,
  task,
  isOpenImage,
  handleOpenImage,
  iconClassName = "",
  showCreate = true,
  handleOptionEnterChange,
  showEye,
  showDelete = true,
  onToggleDelete,
}: {
  work: V1Work
  task: V1Task
  showCreate?: boolean
  showEye?: boolean
  showDelete?: boolean
  isOpenImage: boolean
  handleOpenImage: (flag: boolean) => void
  iconClassName?: string
  handleOptionEnterChange?: (_: boolean) => void
  onToggleDelete?: (flag: boolean) => void
}) {
  const [selectedOption, setSelectedOption] = useState("")
  const [selectedIcon, setSelectedIcon] = useState("")
  const { refresh, refreshUserInfo } = useContext(WorkContext)
  const { t } = useTranslation(["translation", "errorMessage"])
  const userInfo = store.user.get()
  function handleDetele(workId: string) {
    const modal = Modal.info({
      modalRender: () => {
        return (
          <div className="fixed top-0 bottom-0 left-0 right-0">
            <div className="bg-black text-white p-[16px] rounded absolute left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%] w-[360px]">
              <DeleteIcon className="inline-block w-[38px] h-[38px] mb-[10px]" />
              <h3 className="text-base leading-[20px] my-[8px] ">作品将被删除~</h3>
              <p className="text-sm leading-[20px]">此操作不能撤销</p>
              <div className="mt-[28px] flex justify-between">
                <Button type="outline" className={"flex-1 mr-[8px]"} onClick={() => modal.close()}>
                  关闭
                </Button>
                <Button
                  type="primary"
                  className={"flex-1 ml-[8px]"}
                  onClick={() => {
                    api
                      .batchDeleteWorks([workId])
                      .then(({ data }) => {
                        if (data.success) {
                          toastInfo("删除成功")
                          onToggleDelete?.(true) // 通知夫组件删除成功
                          modal.close()
                          refresh()
                          refreshUserInfo && refreshUserInfo()
                        } else {
                          toastError(t(`errorMessage:${data.errorCode}` as any))
                        }
                      })
                      .catch(() => {
                        toastError("删除失败")
                      })
                  }}
                >
                  删除
                </Button>
              </div>
            </div>
          </div>
        )
      },
    })
  }
  async function updateUserInfo(type: "avatar" | "cover") {
    try {
      const params = { [type]: work.image }
      const { data } = await api.updateUserInfo(params)
      if (data.success) {
        store.refreshUser()
        refreshUserInfo && refreshUserInfo()
        toastSuccess("操作成功")
      } else {
        toastError("操作失败")
      }
    } catch (e) {
      toastError("操作失败")
    }
  }
  return (
    <Popover
      trigger="click"
      className="home-modal-popover z-50 home-modal-position"
      position="rt"
      getPopupContainer={() => {
        return document.body
      }}
      onVisibleChange={(visible: boolean) => {
        if (!visible) {
          setSelectedIcon("")
          setSelectedOption("")
          handleOptionEnterChange && handleOptionEnterChange(false)
        }
      }}
      content={
        <div
          className="w-[150px] flex flex-col p-2 rounded bg-[#212134] text-white"
          onClick={() => handleOptionEnterChange && handleOptionEnterChange(true)}
        >
          {/* Used for */}
          <Popover
            trigger="click"
            className="home-modal-popover home-modal-second"
            position="rt"
            getPopupContainer={() => {
              return document.body
            }}
            content={
              <div className="flex flex-col p-2 rounded bg-[#212134] text-white">
                <div
                  className=" cursor-pointer whitespace-nowrap flex py-0.5 items-center w-full  justify-between p-[7px] leading-[28px] ml-auto hover:bg-[#FFFFFF1A] rounded-sm"
                  onClick={() => {
                    updateUserInfo("avatar")
                  }}
                >
                  用于个人头像
                  <IconAvatar className="ml-[20px]" />
                </div>
                <div
                  onClick={() => {
                    updateUserInfo("cover")
                  }}
                  className=" cursor-pointer whitespace-nowrap flex py-0.5 items-center w-full  justify-between p-[7px] leading-[28px] ml-auto hover:bg-[#FFFFFF1A] rounded-sm"
                >
                  用于简介壁纸
                  <IconWallpaper className="ml-[20px]" />
                </div>
              </div>
            }
          >
            <button
              type="button"
              onClick={() => setSelectedOption("usefor")}
              className={`flex w-full justify-between p-[7px] leading-[20px] items-center ml-auto hover:bg-[#FFFFFF1A] transition rounded-sm
                            ${selectedOption == "usefor" ? "bg-[#FFFFFF1A]" : ""}
                          `}
            >
              用于
              <IconRight className="text-[18px]" />
            </button>
          </Popover>

          {/* Repaint */}
          <Popover
            trigger="click"
            className="home-modal-popover home-modal-second"
            position="rt"
            getPopupContainer={() => {
              return document.body
            }}
            content={
              <div className="w-[150px] flex flex-col p-2 rounded bg-[#212134] text-white">
                {work.seed && work.seed !== -1 ? (
                  <ImageRepainting
                    work={work}
                    buttonContent={
                      <div className=" cursor-pointer flex py-0.5 items-center w-full  justify-between p-[7px] leading-[20px] ml-auto hover:bg-[#FFFFFF1A] rounded-sm">
                        图像重绘
                        <RepaintIcon />
                      </div>
                    }
                  />
                ) : null}

                {showCreate && (
                  <GenerateAgainTask
                    task={task}
                    buttonContent={
                      <div className="cursor-pointer flex w-full py-0.5 justify-between items-center p-[7px] leading-[20px] ml-auto hover:bg-[#FFFFFF1A] transition rounded-sm">
                        再次生成
                        <MagicIcon />
                      </div>
                    }
                  />
                )}
                <ControlNetInOtherPage
                  sourceImage={work.image || ""}
                  buttonContent={
                    <>
                      <div className=" cursor-pointer flex py-0.5 items-center w-full  justify-between p-[7px] leading-[20px] ml-auto hover:bg-[#FFFFFF1A] rounded-sm">
                        ControlNet
                        <ControlNetIcon />
                      </div>
                    </>
                  }
                />
                {/* <ImageSpeakInOtherPage
                  sourceUrl={work.image || ""}
                  buttonContent={
                    <>
                      <div className="cursor-pointer flex w-full p-[7px] justify-between items-center leading-[20px] ml-auto hover:bg-[#FFFFFF1A] transition rounded-sm">
                        图像会说话
                        <PicturesSeakIcon />
                      </div>
                    </>
                  }
                /> */}
                <UploadReferenceImageInOtherPage
                  sourceImage={work.image || ""}
                  buttonContent={
                    <>
                      <div className=" cursor-pointer flex w-full justify-between items-center p-[7px] leading-[20px] ml-auto hover:bg-[#FFFFFF1A] transition rounded-sm">
                        作为参考图
                        <ImageIcon className="w-[27px]" />
                      </div>
                    </>
                  }
                />
              </div>
            }
          >
            <button
              type="button"
              onClick={() => setSelectedOption("reuse")}
              className={`flex w-full justify-between p-[7px] leading-[20px] items-center ml-auto hover:bg-[#FFFFFF1A] transition rounded-sm
                            ${selectedOption == "reuse" ? "bg-[#FFFFFF1A]" : ""}
                          `}
            >
              再次使用
              <IconRight className="text-[18px]" />
            </button>
          </Popover>

          {/* Copy */}
          <CopyToClipboard
            text={task.textPrompt}
            onCopy={() => {
              toastInfo("描述语已复制")
            }}
          >
            <button
              type="button"
              onClick={() => {
                setSelectedOption("copy")
              }}
              className={`flex w-full items-center justify-between p-[7px] leading-[20px] ml-auto hover:bg-[#FFFFFF1A] transition rounded-sm
                  ${selectedOption == "copy" ? "bg-[#FFFFFF1A]" : ""}
                        `}
            >
              复制描述语
              <CopyIcon fill="white" />
            </button>
          </CopyToClipboard>

          {/* Privacy */}
          {showEye && (
            <button
              type="button"
              className={`flex w-full justify-between items-center p-[7px] leading-[20px] ml-auto hover:bg-[#FFFFFF1A] transition rounded-sm
                          ${selectedOption == "open" ? "bg-[#FFFFFF1A]" : ""}
                        `}
              onClick={() => {
                handleOpenImage(!isOpenImage)
                setSelectedOption("open")
              }}
            >
              <span>{isOpenImage ? "隐藏作品" : "公开作品"}</span>
              {isOpenImage ? (
                <IconEyeClose className="stroke-white" />
              ) : (
                <IconEye className="stroke-white" />
              )}
            </button>
          )}

          {/* Save */}
          <button
            type="button"
            onClick={() => {
              handleDownload(work.image ?? "")
              setSelectedOption("save")
            }}
            className={`flex w-full justify-between p-[7px] leading-[20px] ml-auto hover:bg-[#FFFFFF1A] transition rounded-sm
                        ${selectedOption == "save" ? "bg-[#FFFFFF1A]" : ""}
                        `}
          >
            保存图像
            <DownloadIcon className="mr-0.5" />
          </button>
          {userInfo?.isNoWatermarkUser && (
            <>
              <button
                type="button"
                onClick={() => {
                  handleDownload(work.image!, false)
                  setSelectedOption("saveHD")
                }}
                className={`flex w-full mr-4 justify-between p-1.5 ml-auto hover:bg-[#FFFFFF1A] transition rounded-sm
                        ${selectedOption == "saveHD" ? "bg-[#FFFFFF1A]" : ""}
                        `}
              >
                保存高清图像
                <DownloadIcon />
              </button>
            </>
          )}
          <hr className="border-[#FFFFFF1A] my-2" />

          {/* Report */}
          <Report
            id={work.id}
            element={
              <button
                type="button"
                onClick={() => {
                  setSelectedOption("report")
                }}
                className={`flex w-full justify-between p-[7px] leading-[20px] ml-auto hover:bg-[#E93247] transition rounded-sm 
                            ${selectedOption == "report" ? "bg-[#E93247]" : ""}
                            `}
              >
                举报内容 <DangerIcon />
              </button>
            }
          />

          {/* Delete */}
          {showDelete && (
            <button
              type="button"
              onClick={() => {
                handleDetele(work.id ?? "")
                setSelectedOption("delete")
              }}
              className={`flex w-full justify-between p-[7px] leading-[20px] ml-auto hover:bg-[#E93247] transition rounded-sm
                        ${selectedOption == "delete" ? "bg-[#E93247]" : ""}
                        `}
            >
              删除作品
              <DeleteIcon />
            </button>
          )}
        </div>
      }
    >
      <button
        type="button"
        onClick={() => setSelectedIcon("option")}
        className={`bg-black bg-opacity-50 transition rounded-sm
                    ${selectedIcon == "option" ? "bg-[#FFFFFF1A]" : ""}
                    `}
      >
        <OptionsIcon className={iconClassName} />
      </button>
    </Popover>
  )
}
