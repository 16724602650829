import downloadIcon from "../../../../assets/works/managementToolBar/download.svg"
import trashIcon from "../../../../assets/works/managementToolBar/trash.svg"
import publicIcon from "../../../../assets/works/managementToolBar/public.svg"
import privateIcon from "../../../../assets/works/managementToolBar/private.svg"
import checkedIcon from "../../../../assets/works/managementToolBar/checked.svg"
import exitIcon from "../../../../assets/works/managementToolBar/exit.svg"
import ToolBarButton from "./components/TooBarButton"
import LikeButton from "./LikeButton"

const PrivacyButton = ({ isPrivate, onClick }: { isPrivate: boolean; onClick: () => void }) => {
  return (
    <ToolBarButton
      icon={isPrivate ? publicIcon : privateIcon}
      text={isPrivate ? "公开" : "取消公开"}
      onClick={onClick}
    />
  )
}

const SelectAllButton = ({
  isAllSelected,
  onClick,
  selectedCount,
}: {
  isAllSelected: boolean
  onClick: () => void
  selectedCount?: number
}) => {
  const countStr = selectedCount ? `(${selectedCount})` : ""
  const btnText = isAllSelected ? `取消全选${countStr}` : `全选${countStr}`
  return (
    <ToolBarButton
      icon={isAllSelected ? checkedIcon : checkedIcon}
      text={btnText}
      onClick={onClick}
    />
  )
}

interface MangementToolBarProps {
  onDownloadClick?: () => void
  onLikeClick?: (albumId: string) => void
  onUnLikeClick?: () => void
  isLiked?: boolean
  onSetPublicClick?: () => void
  onSetPrivateClick?: () => void
  isPrivate?: boolean
  onDeleteClick?: () => void
  onSelectAllClick?: () => void
  selectedCount?: number
  onClearClick?: () => void
  onExitClick?: () => void
  allSelected?: boolean
  isDownloadBtnHidden?: boolean
  isLikeBtnHidden?: boolean
  isPrivacyBtnHidden?: boolean
}

const MangementToolBar = ({
  onDownloadClick,
  onLikeClick,
  onUnLikeClick,
  isLiked,
  onSetPublicClick,
  onSetPrivateClick,
  isPrivate,
  onDeleteClick,
  onSelectAllClick,
  selectedCount,
  onClearClick,
  onExitClick,
  allSelected,
  isDownloadBtnHidden,
  isLikeBtnHidden,
  isPrivacyBtnHidden,
}: MangementToolBarProps) => {
  const isLikedVal = isLiked ?? false
  const isPrivateVal = isPrivate ?? false
  const isAllSelectedVal = allSelected ?? false

  const handlePrivacyBtnClick = () => {
    if (isPrivateVal) {
      onSetPublicClick?.()
    } else {
      onSetPrivateClick?.()
    }
  }

  const handleAllSelectedBtnClick = () => {
    if (isAllSelectedVal) {
      onClearClick?.()
    } else {
      onSelectAllClick?.()
    }
  }

  return (
    <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 px-3 py-1 bg-black bg-opacity-80 rounded-md flex items-center flex-col md:flex-row">
      {/* Data Options */}
      <div className="flex items-center gap-x-1">
        {/* Download */}
        {!isDownloadBtnHidden && (
          <ToolBarButton icon={downloadIcon} text="下载" onClick={onDownloadClick} />
        )}

        {/* Like */}
        {!isLikeBtnHidden && (
          <LikeButton
            isLiked={isLikedVal}
            onLikeClick={onLikeClick}
            onUnLikeClick={onUnLikeClick}
          />
        )}

        {/* Delete */}
        <ToolBarButton icon={trashIcon} text="删除" onClick={onDeleteClick} />

        {/* Privacy */}
        {!isPrivacyBtnHidden && (
          <PrivacyButton isPrivate={isPrivateVal} onClick={handlePrivacyBtnClick} />
        )}
      </div>

      {/* Divider */}
      <div className="hidden md:block h-8 w-[1px] bg-white bg-opacity-20 mx-3"></div>

      {/* Manage Options */}
      <div className="flex items-center gap-x-1">
        {/* Select All */}
        <SelectAllButton
          isAllSelected={isAllSelectedVal}
          onClick={handleAllSelectedBtnClick}
          selectedCount={selectedCount}
        />

        {/* Exit */}
        <ToolBarButton icon={exitIcon} text="退出" onClick={onExitClick} />
      </div>
    </div>
  )
}

export default MangementToolBar
