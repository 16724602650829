import { Message } from "@arco-design/web-react"
import "./index.less"
import { useImperativeHandle, useState } from "react"
interface CopyRightCheckboxProps {
  // copyrightRef: React.RefObject<CopyRightCheckboxObj>
  onChange?: (checked: boolean) => void
}
const CopyRightCheckbox: React.FC<CopyRightCheckboxProps> = ({ onChange }) => {
  // const [isChecked, setIsChecked] = useState<boolean>(false)
  // const { t } = useTranslation(["translation", "errorMessage"])
  // useImperativeHandle(copyrightRef, () => ({
  //   isChecked: isChecked,
  //   validate: () => {
  //     if (isChecked) {
  //       return true
  //     } else {
  //       Message.error("请勾选版权声明")
  //       return false
  //     }
  //   },
  // }))
  return (
    <div className="w-full">
      <div className="flex flex-col">
        <label className="custom-checkbox">
          <input
            type="checkbox"
            onChange={(e) => {
              // setIsChecked(e.target.checked)
              console.log("checkbox changed", e.target.checked)
              onChange?.(e.target.checked)
            }}
          />
          <div className="text-[#8F87AD] text-[12px]">
            阅读并同意
            <a
              target="_blank"
              className="text-[#6663FF]"
              href="https://cos-xica-prod.tiamat.world/static/activity/shangyingyuan/活动规则条款.pdf"
              rel="noreferrer"
            >
              《活动规则条款》
            </a>
          </div>
          <span className="checkmark"></span>
        </label>
      </div>
    </div>
  )
}

export default CopyRightCheckbox
