import { V1Activity } from "@/generated/api/data-contracts"
import { getActivityList, getActivityDetail, getActivityStatus } from "@/services/home"
import { observable } from "mobx"
import { isMobileOnly } from "react-device-detect"
import PcBanner from "@/assets/activity_pc_banner.png"
import MobileBanner from "@/assets/activity_mobile_banner.png"
import { newActivityStartTime } from "@/constants"

const store = observable.box<{
  activities: V1Activity[]
  carouselImages: string[]
  onGoingActivities: V1Activity[]
  activityDetail: V1Activity & { isShow?: boolean }
  activityUserStatus: string
}>({
  activities: [],
  carouselImages: [],
  onGoingActivities: [],
  activityDetail: {},
  activityUserStatus: "NotEngaged",
})

const activityStore = {
  store,
  getActivityList: async () => {
    try {
      const { data, success, errorCode } = await getActivityList()

      const activitiesData = data?.activities

      const onGoingActivitiesData =
        data?.activities?.filter((item: V1Activity) => {
          const createDate = item.createAt || 0

          // 进行中，没有跳转链接，创建时间在2023-12-07之前  旧的活动兼容展示

          return item.state === "Ongoing" && !item.redirectURL && newActivityStartTime > createDate
        }) || []

      if (success && activitiesData) {
        let carouselImages = []
        if (!activitiesData?.length) {
          carouselImages = [isMobileOnly ? MobileBanner : PcBanner]
        } else {
          carouselImages =
            activitiesData.map((item: V1Activity) =>
              item?.bannerImages?.[0] ? item?.bannerImages?.[isMobileOnly ? 1 : 0] : ""
            ) || []
        }
        store.set({
          ...store.get(),
          activities: activitiesData,
          onGoingActivities: onGoingActivitiesData,
          carouselImages,
        })
      } else {
        throw new Error(errorCode)
      }
    } catch (error) {
      console.log(error, "error")
    }
  },
  // getActivityOngoingList: async () => {
  //   try {
  //     const { data, success, errorCode } = await getActivityList()

  //     const activitiesData = data?.activities?.filter(
  //       (item: V1Activity) => item.state === "Ongoing"
  //     )

  //     if (success && activitiesData) {
  //       store.set({
  //         ...store.get(),
  //         activities: activitiesData,
  //         carouselImages:
  //           activitiesData.map((item: V1Activity) =>
  //             item?.bannerImages?.[0] ? item?.bannerImages?.[isMobileOnly ? 1 : 0] : ""
  //           ) || [],
  //       })
  //     } else {
  //       throw new Error(errorCode)
  //     }
  //   } catch (error) {
  //     console.log(error, "error")
  //   }
  // },
  getActivityDetail: async (id: string) => {
    try {
      const { data, success, errorCode } = await getActivityDetail(id)
      if (success && data?.activity) {
        store.set({
          ...store.get(),
          activityDetail: {
            ...data?.activity,
            isShow: newActivityStartTime < (data?.activity?.createAt || 0),
          },
        })
      } else {
        throw new Error(errorCode)
      }
    } catch (error) {
      console.log(error, "error")
    }
  },
  getActivityStatus: async (id: string) => {
    try {
      const { data, success, errorCode } = await getActivityStatus(id)
      if (success && data?.activityUserStatus) {
        store.set({
          ...store.get(),
          activityUserStatus: data?.activityUserStatus,
        })
      } else {
        throw new Error(errorCode)
      }
    } catch (error) {
      console.log(error, "error")
    }
  },
  removeActivityDetail: async () => {
    store.set({
      ...store.get(),
      activityDetail: {},
    })
  },
}

export default activityStore
