import studioPagesStore from "@/store/studioPagesStore"
import RatioItem from "./RatioItem"
import ImageSizeUI from "./ImageSizeUI"

const ratios = [
  {
    width: 1,
    height: 1,
  },
  {
    width: 4,
    height: 3,
  },
  {
    width: 3,
    height: 4,
  },
  {
    width: 16,
    height: 9,
  },
  {
    width: 9,
    height: 16,
  },
]

const ImageSize = ({ className }: { className?: string }) => {
  const setRatio = (ratio: string) => studioPagesStore.setStore({ ratio })
  const { ratio: selectedRatio } = studioPagesStore.store.get()

  return <ImageSizeUI onClick={setRatio} selectedRatio={selectedRatio} className={className} />
  // return (
  //   <div
  //     className={`  ${
  //       className ? className : "flex justify-between items-start gap-x-[5px] overflow-x-auto"
  //     } `}
  //   >
  //     {ratios.map((ratio) => {
  //       const ratioStr = `${ratio.width}:${ratio.height}`
  //       return (
  //         <RatioItem
  //           onClick={() => {
  //             setRatio(ratioStr)
  //           }}
  //           width={ratio.width}
  //           height={ratio.height}
  //           key={ratio.width}
  //           active={ratioStr === selectedRatio}
  //         />
  //       )
  //     })}
  //   </div>
  // )
}

export default ImageSize
