import { ReactComponent as CloseIcon } from "@/assets/studio/close.svg"

interface TemplateNameProps {
  onClose?: () => void
  name?: string
}
const TemplateName = ({ onClose, name }: TemplateNameProps) => {
  return (
    <div className="absolute right-0 top-4 z-10 flex items-center text-[12px] leading-7">
      <div>当前模板:&nbsp;</div>
      <div className="text-[#6663FF]">{name}</div>
      <CloseIcon
        className="w-7 h-7 cursor-pointer"
        onClick={() => {
          onClose?.()
        }}
      />
    </div>
  )
}

export default TemplateName
