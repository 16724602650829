import { flow, observable } from "mobx"
import { observer } from "mobx-react-lite"

import { ReactComponent as SuccessIcon } from "@/assets/success.svg"
import { ReactComponent as ErrorIcon } from "@/assets/error.svg"
import { ReactComponent as InfoIcon } from "@/assets/info.svg"
import { ReactComponent as LoadingIcon } from "@/assets/loading.svg"
import { Message } from "@arco-design/web-react"
import { ReactNode } from "react"

type ToastData = {
  id: string
  type: "error" | "success" | "info" | "loading"
  content: string
  createdAt: number
}

let gid = 0
const toasts = observable.array([] as ToastData[], { deep: false })
export const toast = flow(function* (
  type: ToastData["type"],
  content: string,
  id: string = `_${gid++}`
) {
  const data: ToastData = {
    id,
    type,
    content,
    createdAt: Date.now(),
  }

  toasts.push(data)
  yield new Promise((r) => setTimeout(r, 2000))
  toasts.remove(data)
})

export const toastSuccess = (content: ReactNode | string) =>
  Message.normal({
    position: "bottom",
    icon: <SuccessIcon className="mr-2" />,
    content: <>{content}</>,
  })
export const toastError = (content: string) =>
  Message.normal({
    position: "bottom",
    icon: <ErrorIcon className="mr-2" />,
    content: <>{content}</>,
  })
export const toastInfo = (content: string) =>
  Message.normal({
    position: "bottom",
    icon: <InfoIcon className="mr-2" />,
    content: <>{content}</>,
  })
export const toastLoading = (content: string, id?: string) => toast("loading", content, id)

const Toast = observer(({ data }: { data: ToastData }) => {
  const { content, type } = data

  return (
    <div className="mt-4 px-4 pr-6 py-3 flex items-center bg-black rounded-md">
      {type === "success" && <SuccessIcon className="mr-2" />}
      {type === "error" && <ErrorIcon className="mr-2" />}
      {type === "info" && <InfoIcon className="mr-2" />}
      {type === "loading" && <LoadingIcon className="mr-2" />}
      <span className="text-sm text-white">{content}</span>
    </div>
  )
})

export const ToastContainer = observer(() => {
  return (
    <div className="fixed inset-x-0 bottom-7 mx-auto w-fit z-[100]">
      {toasts.map((data) => (
        <Toast key={data.id} data={data} />
      ))}
    </div>
  )
})
