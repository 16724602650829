import InfiniteScroll from "@/components/common/InfiniteScroll"
import { ReactComponent as LoadingPuffIcon } from "@/assets/loading_puff.svg"
import { ReactComponent as HeartFilledIcon } from "@/assets/works/heart_filled.svg"
import _ from "lodash"
import Masonry from "@mui/lab/Masonry"
import DefaultAvatarSvg from "@/assets/login_default_avatar.svg"
import { Link, useNavigate, useParams } from "react-router-dom"
import { ReactComponent as HeartIcon } from "@/assets/heart_hollow.svg"
import { ReactComponent as DownloadImageIcon } from "@/assets/download_image.svg"
import { ReactComponent as SortingIcon } from "@/assets/sorting.svg"
import { ReactComponent as ArrowIcon } from "@/assets/arrow_left_dark.svg"
import { ReactComponent as IconCheckCircle } from "@/assets/check-circle.svg"
import { ReactComponent as IconLougout } from "@/assets/logout_dark.svg"
import { toastError, toastInfo, toastSuccess } from "@/common/Toast"
import ".././index.less"
import { useEffect, useMemo, useRef, useState } from "react"
import CardOne from "@/components/bussiness/work/generated/CardOne"
import { useTranslation } from "react-i18next"
import { ErrorCode } from "@/i18next"
import { api } from "@/services/api"
import { getColumnCount, handleDownloadImgs, useWindowWidth } from "@/utils"
import { observer } from "mobx-react-lite"
import { V1Task, V1Work } from "@/generated/api/data-contracts"
import worksPagesStore from "@/store/worksPagesStore"
import { ReactComponent as DownloadIcon } from "@/assets/download.svg"
import * as store from "@/store"
import { AlbumCancelLike, Albumlike, getAlbumDetails, guestGetAlbumDetails } from "@/services/album"
import { ReactComponent as HeartHollowIcon } from "@/assets/heart_hollow.svg"
import { Popover } from "@arco-design/web-react"
import ImageItem from "../../WorksTab/ImageGroup/ImageItem"
import { getUserPath } from "@/router/constants"
import ImageUnavailable from "../ImageUnavailable"
import { useTasksManagement } from "../../WorksTab/WorksContext"
import albumStore from "@/store/albumStore"
enum SortEnum {
  newest = "newest",
  popular = "popular",
  highest = "highest",
  myFav = "myFav",
}
const PAGE_SIZE = 30

const sortTypeList: { value: SortEnum; label: string }[] = [
  { value: SortEnum.newest, label: "最新" },
  // { value: SortEnum.highest, label: "最高分" },
  { value: SortEnum.popular, label: "最受欢迎" },
]

type PageConfigType = {
  page: number
  search: string
  sortType: SortEnum
  total: number
}

const WaterFall = observer(
  ({
    onGetWork,
    onLoadingAll,
  }: // onGetTask,
  {
    onGetWork: (info: any) => void
    onLoadingAll: (bool: boolean) => void
    // onGetTask: (info: any) => void
  }) => {
    const navigate = useNavigate()
    const { id: currentUserId } = store.user.get()
    const { albumId: albumId, userId: userId } = useParams()
    const isVisitor = userId ? Boolean(userId !== currentUserId) : false
    const linkToVisitor = (id: string) => {
      navigate(getUserPath(id))
    }
    const backToAlbums = () => {
      history.go(-1)
      // console.log(isVisitor,'111 isvisitor')
      // isVisitor ? navigate(`/users/${userId}/works/albums`) : navigate(`/works/albums`)
    }

    const { t } = useTranslation(["translation", "errorMessage"])
    const [albumDetail, setAlbumsDetail] = useState<any>()
    const [hasMore, setHasMore] = useState(true)

    const [loading, setLoading] = useState(false)

    const tasks = worksPagesStore.store.get().taskList
    // 列表为空  / 有数据
    const loadingAll = !loading && tasks.length == 0 ? false : tasks.length > 0 ? false : true

    useEffect(() => {
      onLoadingAll(loadingAll)
      if (tasks && tasks[0] && tasks[0].works) {
        onGetWork(tasks[0].works[0]!)
      }
    }, [loadingAll])

    const selectedWorks = worksPagesStore.store.get().selectList

    const cancelBatch = () => {
      setShowBatchBar(false)
    }

    // 分页配置
    const [pageConfig, setPageConfig] = useState<PageConfigType>({
      page: 0,
      search: "",
      sortType: SortEnum.newest,
      total: 0,
    })
    const sortText = useMemo(() => {
      const target = sortTypeList.find((item) => item.value === pageConfig.sortType)
      if (target) {
        return target.label
      }
      return ""
    }, [pageConfig.sortType])

    const getData = async (
      page = pageConfig.page,
      search = pageConfig.search,
      sortType = pageConfig.sortType
    ) => {
      try {
        setLoading(true)
        const res: any = isVisitor
          ? // 访客获取
            await guestGetAlbumDetails(albumId!, userId!, {
              current: page,
              collectionUID: albumId!,
              pageSize: PAGE_SIZE,
              sort: sortType,
            }).finally(() => {
              setLoading(false)
            })
          : // 本人获取
            await getAlbumDetails(albumId!, {
              current: page,
              collectionUID: albumId!,
              pageSize: PAGE_SIZE,
              sort: sortType,
            }).finally(() => {
              setLoading(false)
            })
        if (res.success) {
          const { data } = res
          setAlbumsDetail(data.collection)
          setHasMore(data.collection.works.length + PAGE_SIZE * page < data.total)
          // 使用接口判断，不再前端区分
          // const _filter = data.collection.works?.filter((item: V1Work) => {
          // 图像被设为私有，并且当前用户不是原创作用户, 则应该不可见, 过滤的数组为可见数组
          // const hidden = item.isPrivate && item.task?.user?.id !== currentUserId
          // return !hidden
          // })
          // tasks是自己构造的，并且  解构了task， 列表不会重复
          const tasks = ((data.collection.works as V1Work[]) || []).map((work) => ({
            ...work.task,
            works: [work],
          }))
          if (page === 1) {
            worksPagesStore.pushTaskItems(() => tasks as V1Task[])
          } else {
            // 构造数据
            worksPagesStore.pushTaskItems((list: V1Task[]) => [...list, ...(tasks as V1Task[])])
          }

          setPageConfig({
            page,
            search,
            sortType,
            total: data?.total || 0,
          })
        } else {
          setHasMore(false)
          toastError(t(`errorMessage:${res.errorCode as ErrorCode}`))
        }
      } catch (e) {
        // error
      }
    }

    const worksCount = () => tasks?.reduce((r, task) => r + (task?.works?.length || 0), 0)

    const selectAllWorks = (all: boolean) => {
      if (loading) {
        return
      }
      const newSelectedWorks: string[] = []
      if (all) {
        tasks?.forEach(({ works }) => {
          works?.forEach(({ id }) => newSelectedWorks.push(id || ""))
        })
      }

      worksPagesStore.changeSelectList(newSelectedWorks)
    }

    const downloadSelectedWorks = async () => {
      if (loading || !checkselectedWorksNumber()) {
        return
      }
      const urls: string[] = []
      tasks?.forEach(({ works }: any) => {
        works?.forEach(({ image, id }: any) => selectedWorks?.includes(id!) && urls.push(image!))
      })
      await handleDownloadImgs(urls)
      setShowBatchBar(false)
    }

    // unlike
    const batchUnlike = async () => {
      const resp = await api.batchUnlikeWorks({
        workIDs: selectedWorks || [],
        collectionUID: albumId,
      })
      if (resp.data.success) {
        toastSuccess("操作成功")
        worksPagesStore.changeTask((tasks) => {
          const _ = tasks.filter((task) => {
            const newWorks = [...(task?.works || [])]
            task.works?.map((work) => {
              if (selectedWorks.includes(work.id ?? "")) {
                const index = newWorks.findIndex(({ id }) => id === work.id)
                newWorks.splice(index, 1)
              }
            })
            task.works = newWorks || []
            if (newWorks.length > 0) {
              return true
            }
            return false
          })
          return [..._]
        })

        cancelBatch()
      } else {
        toastError(t(`errorMessage:${resp.data.errorCode as ErrorCode}`))
      }
    }
    const handleWorkUpdate = (newWork: any) => {
      const _ = tasks.filter((task) => {
        const newWorkList = task.works?.map((work) => {
          if (work.id === newWork.id) {
            return newWork
          }
          return work
        })
        task.works = newWorkList
      })
      return [..._]
    }

    const checkselectedWorksNumber = () => {
      if (selectedWorks?.length == 0) {
        toastInfo("请先选择作品")
        return false
      }
      return true
    }
    useEffect(() => {
      worksPagesStore.init()
      return () => {
        worksPagesStore.init()
      }
    }, [])
    const [showBatchBar, setShowBatchBar] = useState(false)

    const cancelCollect = async (collectionUIDs: string[]) => {
      const collectionUID = collectionUIDs[0]
      const { success, errorCode } = await AlbumCancelLike(collectionUID)
      if (success) {
        setAlbumsDetail({ ...albumDetail, like: false, likeCount: albumDetail.likeCount - 1 })
        toastSuccess("取消收藏成功")
      } else {
        toastError(t(`errorMessage:${errorCode}` as any))
      }
    }
    const addCollect = async (collectionUID: string) => {
      const { success, errorCode } = await Albumlike(collectionUID)
      if (success) {
        setAlbumsDetail({ ...albumDetail, like: true, likeCount: albumDetail.likeCount + 1 })
        toastSuccess("收藏成功")
      } else {
        toastError(t(`errorMessage:${errorCode}` as any))
      }
    }
    const likedAlbum = albumDetail?.like
    return (
      <div className="max-w-[670px] min-w-[540px]">
        <div className={`max-w-[670px] min-w-[540px] w-full flex-shrink-0 dark:text-white`}>
          <div className="flex items-center gap-[6px] cursor-pointer" onClick={backToAlbums}>
            <ArrowIcon className="w-[14px] h-[14px]" />
            相册列表
          </div>
          {/* 模版名称 */}
          <div className="flex items-end">
            <span className="font-sans leading-[4.5rem] text-center text-4xl truncate">
              {albumDetail?.title}
            </span>
          </div>
          <div
            className="flex justify-between items-center pt-5 pb-1 cursor-pointer"
            onClick={() => linkToVisitor(albumDetail?.user?.id || "")}
          >
            <div className="flex items-center">
              <img
                className="h-8 w-8 border border-[#FFFFFF33] rounded-full object-contain"
                src={albumDetail?.user?.avatar ? albumDetail?.user?.avatar : DefaultAvatarSvg}
              />
              <label className="pl-2 text-sm cursor-pointer">
                {albumDetail?.user?.nick && albumDetail?.user?.nick.length > 10
                  ? albumDetail?.user?.nick?.slice(0, 10) + "..."
                  : albumDetail?.user?.nick || "默认用户名"}
              </label>
            </div>
          </div>
          <div className="flex justify-between w-full mb-[20px] pr-[20px]">
            <div className="flex gap-[6px] items-center ">
              {/* <HeartIcon /> {albumDetail?.likeCount || 0} */}
              {!isVisitor ? (
                <>
                  <HeartIcon /> {albumDetail?.likeCount || 0}
                </>
              ) : (
                <>
                  <span
                    onClick={() => {
                      likedAlbum ? cancelCollect([albumId || ""]) : addCollect(albumId!)
                    }}
                    className="w-[18px] cursor-pointer object-contain"
                  >
                    {likedAlbum ? <HeartFilledIcon fill={"#E93247"} /> : <HeartIcon />}
                  </span>
                  {albumDetail?.likeCount || 0}
                </>
              )}
            </div>
            <div className="flex justify-between items-center">
              <Popover
                position="bl"
                trigger={"hover"}
                color={"#212134"}
                className="work-popover-wrapper"
                content={
                  <ul className="p-[5px] bg-[#212134]">
                    {sortTypeList.map((item) => {
                      return (
                        <li
                          key={item.value}
                          className="cursor-pointer  text-white px-[10px] py-[5px] bg-transparent hover:bg-[#272739]"
                          onClick={() => {
                            worksPagesStore.init()
                            getData(1, pageConfig.search, item.value)
                          }}
                        >
                          {item.label}
                        </li>
                      )
                    })}
                  </ul>
                }
              >
                <button className="mr-2 py-2 px-2  flex items-center text-sm bg-[#212134] hover:bg-[#4c1fff] rounded ">
                  <SortingIcon className="hidden md:block mr-2" />
                  {/* {sortText} */}
                </button>
              </Popover>
              {/* 批量操作区域 */}
              {showBatchBar && (
                <div className="fixed flex items-center bottom-4 left-1/2 bg-[#000000cc] px-3 py-1 text-white rounded-md -translate-x-1/2 z-[100] ">
                  <button
                    className="bg-[#00000000] grid justify-items-center hover:bg-[#ffffff1a] text-cente text-xs py-2 px-3 rounded "
                    onClick={downloadSelectedWorks}
                  >
                    <div className="h-[28px] w-[28px] flex justify-center items-center">
                      <DownloadIcon />
                    </div>
                    <span className="mt-1">下载</span>
                  </button>
                  <button
                    className="bg-[#00000000] grid justify-items-center hover:bg-[#ffffff1a] text-cente text-xs py-2 px-3 rounded "
                    onClick={() => {
                      batchUnlike()
                    }}
                  >
                    <div className="h-[28px] w-[28px] flex justify-center items-center">
                      <HeartHollowIcon />
                    </div>
                    <span className="mt-1">{"取消收藏"}</span>
                  </button>
                  <div className="w-px h-8 bg-[#FFFFFF33] mx-3"></div>
                  <button
                    className="bg-[#00000000] grid justify-items-center hover:bg-[#ffffff1a] text-cente text-xs py-2 px-3 rounded "
                    onClick={() => {
                      if (worksCount() === selectedWorks.length) {
                        return
                      }
                      selectAllWorks(true)
                    }}
                  >
                    <IconCheckCircle />
                    <span className="mt-1">全选({selectedWorks?.length})</span>
                  </button>
                  <button
                    className="bg-[#00000000] grid justify-items-center hover:bg-[#ffffff1a] text-cente text-xs py-2 px-3 rounded "
                    onClick={cancelBatch}
                  >
                    <IconLougout />
                    <span className="mt-1">退出</span>
                  </button>
                </div>
              )}
              {showBatchBar ? (
                <>
                  <button
                    className="py-2 px-3.5 w-20 text-center text-xs bg-[#111124] rounded hover:bg-[#60676f]"
                    onClick={cancelBatch}
                  >
                    <span>退出</span>
                  </button>
                  <button
                    className="py-2 px-3.5 w-20 text-center text-xs  bg-[#111124] hover:bg-[#60676f] rounded"
                    onClick={() => selectAllWorks(worksCount() !== selectedWorks.length)}
                  >
                    <span>{worksCount() !== selectedWorks.length ? "全选" : "取消全部 "}</span>
                  </button>
                </>
              ) : (
                <>
                  {isVisitor ? (
                    <></>
                  ) : (
                    <button
                      className="py-2 px-3.5 flex items-center text-sm bg-[#212134] hover:bg-[#4c1fff] rounded "
                      onClick={() => setShowBatchBar(true)}
                    >
                      <DownloadImageIcon className="mr-1" stroke="white" />
                      <span>相册管理</span>
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div
          style={{ height: "calc(100vh - 300px)" }}
          className="max-w-[670px] min-w-[540px] w-full flex flex-wrap gap-[20px]  overflow-scroll"
        >
          <InfiniteScroll
            hasMore={hasMore}
            isLoading={loading}
            loadMore={() => {
              if (loading || !hasMore) {
                return
              }
              getData(pageConfig.page + 1)
            }}
            loaderClassName={"w-full"}
            loader={
              <LoadingPuffIcon
                key={"loading"}
                className="my-20 mx-auto justify-center"
                stroke="currentColor"
              ></LoadingPuffIcon>
            }
            noMoreElement={<NoMore />}
          >
            {tasks && tasks.length > 0 ? (
              <Masonry columns={{ xs: 3, sm: 3, lg: 3 }} spacing={2} className="">
                {tasks &&
                  tasks[0] &&
                  tasks.map((item: any, index) => {
                    return (
                      <>
                        {(item?.works?.[0].isPrivate || item?.works?.[0]?.isDeleted) &&
                        item.user?.id !== currentUserId ? (
                          <div className="aspect-square">
                            <ImageUnavailable />
                          </div>
                        ) : (
                          <div
                            className="aspect-square"
                            key={index}
                            onClick={(e) => {
                              !showBatchBar ? onGetWork(item?.works?.[0]) : undefined // worksPagesStore.togglesSelect(item.works?.[0].id!)
                            }}
                          >
                            <div className="absolute inset-0">
                              <ImageItem
                                onWorkUpdated={handleWorkUpdate}
                                task={item}
                                work={item?.works?.[0]!}
                                imageClickDisable
                                isSelected={worksPagesStore.store
                                  .get()
                                  .selectList?.includes(item?.works?.[0]!.id || "")}
                                onSelectLayerClick={() =>
                                  worksPagesStore.togglesSelect(item.works?.[0].id!)
                                }
                                isManaging={showBatchBar}
                                key={item.id}
                                // 如果是访客, 全部不能删除 / 如果自己相册，不是自己的图不能删除
                                isVisited={isVisitor || !item.isMine}
                              />
                            </div>
                          </div>
                        )}
                      </>
                    )
                  })}
              </Masonry>
            ) : (
              <></>
            )}
          </InfiniteScroll>
        </div>
      </div>
    )
  }
)

// const Loader = () => {
//   return (
//     <div className="max-w-[660px] min-w-[520px] my-20 flex items-center justify-center">
//       <LoadingPuffIcon stroke="currentColor" />
//     </div>
//   )
// }
const NoMore = () => {
  return (
    <section className="max-w-[670px] min-w-[540px] py-10 flex justify-center items-center">
      <span className="inline-flex items-center text-[#6F7176] text-sm before:mr-[26px] before:bg-[#C9CBD14D]/30 before:w-[138px] before:h-[1px] after:ml-[26px] after:bg-[#C9CBD14D]/30 after:w-[138px] after:h-[1px]">
        没有更多了
      </span>
    </section>
  )
}
export default WaterFall
