import {
  V1CreateTemplateRequest,
  V1ListMyTemplatesResponse,
  V1ReceiveDailyCheckInRewardResponse,
  V1ReceiveTemplateHeatTargetRewardResponse,
  V1ReceiveTemplateSubmissionCountTargetRewardResponse,
  V1ReceiveTemplateSubmissionRewardResponse,
  V1ReceiveUserGenerationCountTargetRewardResponse,
} from "@/generated/api/data-contracts"
import { api } from "./api"
import { RequestParams } from "@/generated/api/http-client"
interface baseResponse {
  errorCode?: string
  errorMessage?: string
  host?: string
  showType?: number
  success?: boolean
  traceId?: string
}
interface V1ReceiveTemplateHeatTargetRewardCustomResponse extends baseResponse {
  data?: V1ReceiveTemplateHeatTargetRewardResponse
}
interface V1ReceiveUserGenerationCountTargetRewardCustomResponse extends baseResponse {
  data?: V1ReceiveUserGenerationCountTargetRewardResponse
}
interface V1ReceiveTemplateSubmissionCountTargetRewardCustomResponse extends baseResponse {
  data?: V1ReceiveTemplateSubmissionCountTargetRewardResponse
}
interface V1ReceiveTemplateSubmissionRewardCustomResponse extends baseResponse {
  data?: V1ReceiveTemplateSubmissionRewardResponse
}
interface V1ReceiveDailyCheckInRewardCustomResponse extends baseResponse {
  data?: V1ReceiveDailyCheckInRewardResponse
}
interface MyTemplatesResponse extends baseResponse {
  data?: V1ListMyTemplatesResponse
}
// 每日签到
export const receiveDailyCheckInReward = async (
  params: RequestParams = {}
): Promise<V1ReceiveDailyCheckInRewardCustomResponse> => {
  const res = await api.receiveDailyCheckInReward({})
  return res.data
}
// 领取模板提交奖励
export const receiveTemplateSubmissionReward = async (
  params: RequestParams = {}
): Promise<V1ReceiveTemplateSubmissionRewardCustomResponse> => {
  const res = await api.receiveTemplateSubmissionReward({})
  return res.data
}
//  领取模板熟练度奖励
export const receiveTemplateHeatTargetReward = async (
  params: RequestParams = {}
): Promise<V1ReceiveTemplateHeatTargetRewardCustomResponse> => {
  const res = await api.receiveTemplateHeatTargetReward({})
  return res.data
}
//  模板数量
export const receiveTemplateSubmissionCountTargetReward = async (
  params: RequestParams = {}
): Promise<V1ReceiveTemplateSubmissionCountTargetRewardCustomResponse> => {
  const res = await api.receiveTemplateSubmissionCountTargetReward({})
  return res.data
}
// 领取生成次数达标奖励
export const receiveUserGenerationCountTargetReward = async (
  params: RequestParams = {}
): Promise<V1ReceiveUserGenerationCountTargetRewardCustomResponse> => {
  const res = await api.receiveUserGenerationCountTargetReward({})
  return res.data
}
// 我的模板
export const getMyTemplates = async (params: {
  page: number
  pageSize: number
}): Promise<MyTemplatesResponse> => {
  const res = await api.listMyTemplates(params, { format: "json" })
  return res.data
}
