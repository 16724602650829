import InfiniteScroll from "@/components/common/InfiniteScroll"
import DateRow from "./DateRow"
import ImageGroup from "./ImageGroup"
import { useContext, useEffect, useMemo, useState } from "react"
import { getMyWorks } from "@/services/myWorks"
import { V1Task, V1Work } from "@/generated/api/data-contracts"
import { WorksProvider, useTasksDispatch, useTasksManagement, useTasksState } from "./WorksContext"
import { useTranslation } from "react-i18next"
import VisitStatusContext from "../VisitStatusContext"
import ContentSwitcher from "../components/ContentSwitcher"
import SortButton from "../components/SortButton"
import SelectButton from "../components/SelectButton"
import { SortEnum } from "../components/SortButton/constant"
import MangementToolBar from "../components/MangementToolBar"
import { handleDownloadImgs } from "@/utils"
import albumStore from "@/store/albumStore"
import { observer } from "mobx-react-lite"
import { api } from "@/services/api"
import { toastError, toastInfo, toastSuccess } from "@/common/Toast"
import { ErrorCode } from "@/i18next"
import DeleteConfirm from "./components/DeleteConfirm"
import * as store from "@/store"
import { Modal } from "@arco-design/web-react"
import Loader from "./components/Loader"
import NoMore from "./components/NoMore"

const PAGE_SIZE = 10

interface ITasksOfDate {
  [date: string]: V1Task[]
}
interface IRowItem {
  date: string
  tasks: V1Task[]
}

const WorksTabComponent = observer(() => {
  const { t } = useTranslation(["translation", "errorMessage"])
  const [isLoading, setIsLoading] = useState(false)
  const myTasks = useTasksState()
  const myTasksDispatch = useTasksDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const [totalSize, setTotalSize] = useState(0)
  const { isVisted, userId } = useContext(VisitStatusContext)
  const [sortType, setSortType] = useState(SortEnum.newest)
  const [deleteCofirmVisible, setDeleteCofirmVisible] = useState(false)
  const { isManaging, setIsManaging, selectedWorkIdsDispatch, selectedWorkIds } =
    useTasksManagement()
  const { canPrivateGeneration, id } = store.user.get()

  const fetchData = async ({
    pageNumber = 1,
    sortTypeParam,
  }: {
    pageNumber?: number
    sortTypeParam?: SortEnum
  }) => {
    setIsLoading(true)
    const sortTypeVal = sortTypeParam || sortType
    const query = {
      current: pageNumber,
      pageSize: PAGE_SIZE,
      sort: sortTypeVal,
    }
    try {
      let resp
      if (isVisted) {
        const isCurrentUser = id === userId
        const targetUesrResp = await api.listTasksByUserId(userId, {
          ...query,
          isCurrentUser,
        })
        resp = targetUesrResp.data.data
      } else {
        resp = await getMyWorks(query)
      }

      // Update total size
      const respTotal = resp?.total
      if (respTotal) {
        setTotalSize(respTotal)
      }

      // Update tasks
      const respList = resp?.list
      if (respList) {
        myTasksDispatch({ type: "ADD_TASKS", payload: respList })
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const loadMoreHandler = () => {
    const pageNumber = currentPage + 1
    fetchData({
      pageNumber,
    })
      .catch(() => {
        console.error("error")
      })
      .finally(() => {
        setIsLoading(false)
        setCurrentPage(pageNumber)
      })
  }

  // Fetch data on mount or when sort type changes
  useEffect(() => {
    fetchData({
      pageNumber: 1,
    })
  }, [])

  const listByDate = useMemo(() => {
    const dateMapTasks = myTasks.reduce((acc, cur) => {
      const date = cur.createdAt?.split("T")[0]
      if (!date) {
        return acc
      }
      if (acc[date]) {
        acc[date].push(cur)
      } else {
        acc[date] = [cur]
      }
      return acc
    }, {} as ITasksOfDate)

    const items = Object.keys(dateMapTasks).map((date) => {
      return {
        date,
        tasks: dateMapTasks[date],
      } as IRowItem
    })

    return items
  }, [myTasks])

  const hasMore = myTasks.length < totalSize

  const handleSortTypeChange = (sortType: SortEnum) => {
    setSortType(sortType)
    myTasksDispatch({
      type: "RESET",
    })
    setCurrentPage(1)
    setTotalSize(0)

    // Fetch data for new sort type
    fetchData({
      pageNumber: 1,
      sortTypeParam: sortType,
    })
  }

  const selectedWorks = useMemo(() => {
    const works = myTasks.reduce((acc, cur) => {
      const works = cur.works || []
      const worksOfSelected = works.filter((work) => selectedWorkIds.includes(work.id ?? ""))
      return [...acc, ...worksOfSelected]
    }, [] as V1Work[])

    return works
  }, [selectedWorkIds, myTasks])

  const clearSelected = () => {
    selectedWorkIdsDispatch({
      type: "RESET",
    })
  }

  const exitManageMode = () => {
    setIsManaging(false)
    clearSelected()
  }

  const handleSelectAll = () => {
    const allWorkIds = myTasks.map((task) => task.works?.map((work) => work.id)).flat()
    selectedWorkIdsDispatch({
      type: "SET",
      payload: allWorkIds,
    })
  }

  const selectedCount = selectedWorkIds.length

  const allSelected = useMemo(() => {
    const allWorkIds = myTasks.map((task) => task.works?.map((work) => work.id)).flat()
    return allWorkIds.every((id) => selectedWorkIds.includes(id ?? ""))
  }, [selectedWorkIds, myTasks])

  const handleDownloadClick = () => {
    if (selectedCount === 0) return

    const imgUrlsOfSelectedWorks = selectedWorks.map((work) => work.image ?? "")
    console.log(imgUrlsOfSelectedWorks)
    handleDownloadImgs(imgUrlsOfSelectedWorks)
    exitManageMode()
  }

  const handleLikeClick = async (albumUid: string) => {
    const workIds = selectedWorks.map((work) => work.id ?? "")
    const resp = await api.batchLikeWorks({
      workIDs: workIds,
      collectionUID: albumUid,
    })
    console.log("resp", resp)
    if (resp.data.success) {
      toastSuccess("操作成功")
      albumStore.getMyAlbums()
      exitManageMode()
    } else {
      toastError(t(`errorMessage:${resp.data.errorCode as ErrorCode}`))
    }
  }

  const confirmDelete = () => {
    api
      .batchDeleteWorks(selectedWorkIds)
      .then(({ data }) => {
        if (data.success) {
          toastInfo("操作成功")
          myTasksDispatch({
            type: "REMOVE_WORKS",
            payload: selectedWorkIds,
          })
          exitManageMode()
        } else {
          toastError(t(`errorMessage:${data.errorCode}` as any))
        }
      })
      .catch(() => {
        toastInfo("操作失败")
      })
      .finally(() => {
        setDeleteCofirmVisible(false)
      })
  }

  const handleDeleteClick = () => {
    if (selectedCount === 0) return
    setDeleteCofirmVisible(true)
  }

  const isMajoritySelectedPrivate = useMemo(() => {
    const selectedPrivateWorks = selectedWorks.filter((work) => work.isPrivate)
    return selectedPrivateWorks.length > selectedWorks.length / 2
  }, [selectedWorks])

  const handleBatchTogglePrivacy = (isPrivate: boolean) => {
    Modal.confirm({
      title: `提示`,
      content: `已选中的所有作品将${isPrivate ? "隐藏" : "公开"}`,
      wrapClassName: "arco-modal-dark-wrapper",
      style: { width: "350px" },
      okButtonProps: {
        status: "warning",
      },
      onOk: () => {
        api
          .batchSetWorksIsPrivate({
            isPrivate: isPrivate,
            workIDs: selectedWorkIds,
          })
          .then(({ data }) => {
            if (data.success) {
              toastInfo("操作成功")
              myTasksDispatch({
                type: "UPDATE_WORKS",
                payload: [...selectedWorks].map((work) => ({
                  ...work,
                  isPrivate: isPrivate,
                })) as V1Work[],
              })
              exitManageMode()
            } else {
              toastError(t(`errorMessage:${data.errorCode as ErrorCode}`))
            }
          })
          .catch(() => {
            toastInfo("操作失败")
          })
      },
    })
  }

  return (
    <>
      {/* Sub Routes & Options */}
      <ContentSwitcher
        actionComponent={
          <>
            {/* Sort Button */}
            <SortButton value={sortType} onChange={handleSortTypeChange} />

            {/* Image Manage Button */}
            {!isVisted && (
              <SelectButton
                manageBtnText={t("worksCenter.imageManage")}
                isManaging={isManaging}
                onManageClick={() => {
                  setIsManaging(true)
                }}
                onExitClick={exitManageMode}
                onSelectAllClick={handleSelectAll}
                allSelected={allSelected}
                onClearClick={clearSelected}
              />
            )}
          </>
        }
      />

      {/* Image List */}
      <InfiniteScroll
        hasMore={hasMore}
        isLoading={isLoading}
        loadMore={loadMoreHandler}
        loader={<Loader />}
        noMoreElement={<NoMore />}
      >
        {listByDate.map((item, index) => {
          const dateObj = new Date(item.date)
          return (
            <DateRow date={dateObj} key={item.date}>
              <div className="grid grid-cols-[repeat(auto-fill,minmax(300px,1fr))] md:grid-cols-[repeat(auto-fill,minmax(400px,1fr))] gap-4">
                {item.tasks.map((t, index) => {
                  // if (t?.type === "rs") {
                  //   return <SuperResolutionImage key={group.id} />
                  // }
                  return <ImageGroup works={t.works} key={t.id} task={t} />
                })}
              </div>
            </DateRow>
          )
        })}
      </InfiniteScroll>

      {/* Image Manage Toolbar */}
      {isManaging && (
        <MangementToolBar
          onExitClick={exitManageMode}
          allSelected={allSelected}
          onClearClick={clearSelected}
          selectedCount={selectedCount}
          onSelectAllClick={handleSelectAll}
          onDownloadClick={handleDownloadClick}
          isLiked={false}
          onLikeClick={handleLikeClick}
          onDeleteClick={handleDeleteClick}
          isPrivacyBtnHidden={!canPrivateGeneration}
          isPrivate={isMajoritySelectedPrivate}
          onSetPublicClick={() => handleBatchTogglePrivacy(false)}
          onSetPrivateClick={() => handleBatchTogglePrivacy(true)}
        />
      )}

      {deleteCofirmVisible && (
        <DeleteConfirm
          onOk={confirmDelete}
          onCancel={() => setDeleteCofirmVisible(false)}
          visible={deleteCofirmVisible}
        />
      )}
    </>
  )
})

const WorksTab = () => {
  return (
    <WorksProvider>
      <WorksTabComponent />
    </WorksProvider>
  )
}
export default WorksTab
