import { ReactComponent as RemoveIcon } from "@/assets/studio/remove.svg"
import { ReactComponent as EditorIcon } from "@/assets/editor.svg"
interface ReferenceImageProps {
  onImageClick?: () => void
  onRemoveClick: (index: number) => void
  imageSrc?: string
  index?: number
}
const ReferenceImage = ({ onImageClick, onRemoveClick, imageSrc, index }: ReferenceImageProps) => {
  return (
    <div className="w-full h-[200px] relative">
      <img
        src={imageSrc}
        alt="ControlNet 参考图"
        className="w-full h-full object-cover rounded cursor-pointer"
      />
      <div className="absolute bottom-[6px] right-[6px] flex gap-[10px]">
        <div
          className="py-1 px-2 rounded bg-black bg-opacity-80 flex items-center cursor-pointer hover:bg-opacity-70"
          onClick={onImageClick}
        >
          <EditorIcon />
          <div className="text-sm ml-[5px] text-white text-opacity-80">再次编辑</div>
        </div>
        <div
          className=" py-1 px-2 rounded bg-black bg-opacity-80 flex items-center cursor-pointer hover:bg-opacity-70"
          onClick={() => {
            onRemoveClick(index || 0)
          }}
        >
          <RemoveIcon />
          <div className="text-sm ml-[5px] text-white text-opacity-80">移除参考</div>
        </div>
      </div>
    </div>
  )
}

export default ReferenceImage
