import { useEffect, useState } from "react"
import PromptElement from "./PromptElement"
import { isMobileOnly } from "react-device-detect"
import { convertTemplatePromptArrayToNormalPrompt, getTemplatePromptArray } from "@/utils/format"
import { TemplatePromptItem } from "@/types"

interface TemplatePromptInputProps {
  prompt?: string
  onChange?: (text: string) => void
  onModifyAllClick?: () => void
  style?: React.CSSProperties
}

const TemplatePromptInput = ({
  prompt = "",
  onChange,
  onModifyAllClick,
  style,
}: TemplatePromptInputProps) => {
  const promptItems = getTemplatePromptArray(prompt)

  useEffect(() => {
    setPromptItemsState(promptItems)
  }, [prompt])

  const [promptItemsState, setPromptItemsState] = useState(promptItems)

  const handleInputChange = (promptItems: TemplatePromptItem[]) => {
    const promptStr = convertTemplatePromptArrayToNormalPrompt(promptItems)
    onChange?.(promptStr)
  }

  const handleElementChange = (index: number, text: string) => {
    const newPromptItemsState = [...promptItemsState]
    newPromptItemsState[index] = { ...newPromptItemsState[index], label: text }
    setPromptItemsState(newPromptItemsState)
    handleInputChange(newPromptItemsState)
  }

  return (
    <div
      className="bg-[#0C0C1A] rounded p-0 md:p-[10px] h-full flex flex-nowrap md:flex-wrap gap-[10px] content-start overflow-y-auto app_scrollbar"
      style={style}
    >
      {promptItems.map((item: any, index: number) => {
        const itemState = promptItemsState[index]

        return (
          <PromptElement
            onChange={(text) => {
              handleElementChange(index, text)
            }}
            label={itemState?.label}
            editable={item.editable}
            key={item.label}
          />
        )
      })}

      {isMobileOnly && (
        <PromptElement
          label="编辑全部"
          editable
          onButtonClick={() => {
            onModifyAllClick?.()
          }}
        />
      )}
    </div>
  )
}

export default TemplatePromptInput
