import { ReactComponent as LoadingPuffIcon } from "@/assets/loading_puff.svg"
import { ReactComponent as EmptyIcon } from "@/assets/empty.svg"
import InfiniteScroll from "react-infinite-scroller"
import { ReactNode } from "react"

const ButtonLoading = ({
  loading,
  hasMore,
  total,
  getData,
  children,
}: {
  loading: boolean
  hasMore: boolean
  total: number
  getData: () => void
  children: ReactNode
}) => {
  return (
    <>
      <InfiniteScroll
        pageStart={1}
        loadMore={() => {
          if (loading || !hasMore) {
            return
          }
          getData?.()
        }}
        hasMore={hasMore}
        loader={
          <LoadingPuffIcon
            key={"loading"}
            className="my-20 mx-auto"
            stroke="currentColor"
          ></LoadingPuffIcon>
        }
      >
        {children}

        {!total && !loading ? (
          <>
            <EmptyIcon className="mt-20 mx-auto"></EmptyIcon>
            <div className="mt-2.5 text-center text-sm">暂无作品</div>
          </>
        ) : null}
        {total && !hasMore ? (
          <section className="mt-[22px] flex justify-center items-center">
            <span className="inline-flex items-center text-[#6F7176] text-sm before:mr-[26px] before:bg-[#C9CBD14D]/30 before:w-[138px] before:h-[1px] after:ml-[26px] after:bg-[#C9CBD14D]/30 after:w-[138px] after:h-[1px]">
              没有更多了
            </span>
          </section>
        ) : null}
      </InfiniteScroll>
    </>
  )
}

export default ButtonLoading
