import { Tooltip } from "@arco-design/web-react"
import { ReactComponent as IconEye } from "@/assets/eye.svg"
import { ReactComponent as IconEyeClose } from "@/assets/eye-close.svg"

interface PrivacyButtonProps {
  isPublic: boolean
  onClick: () => void
}
const PrivacyButton = ({ isPublic, onClick }: PrivacyButtonProps) => {
  return (
    <Tooltip content={!isPublic ? "切换为公开" : "切换为不公开"}>
      <div>
        <button
          className="hover:bg-[#FFFFFF1A] w-7 h-7 flex justify-center items-center transition rounded-sm cursor-pointer stroke-gray-400"
          onClick={onClick}
        >
          {isPublic ? <IconEye /> : <IconEyeClose />}
        </button>
      </div>
    </Tooltip>
  )
}

export default PrivacyButton
