import illegalworkURL from "@/assets/illegalwork.png"
import illegalworkbgURL from "@/assets/illegalworkbg.png"
import LongPressCopy from "./LongPressCopy"

export function IllegalWork(props: IllegalWorkProps) {
  const { className, style, id, URL, taskId = "" } = props
  return (
    <section
      data-id={id}
      data-url={URL}
      className={
        "h-full w-full flex flex-col justify-center items-center bg-no-repeat min-h-[100px] bg-cover p-2 rounded" +
        (className || "")
      }
      style={{ backgroundImage: `url(${illegalworkbgURL})`, ...style }}
    >
      <LongPressCopy text={taskId}>
        <img className="w-[55px] h-[55px]" src={illegalworkURL} />
        <p className="mt-[4px] text-xs whitespace-nowrap">图片违规咯，再试试别的词吧！</p>
      </LongPressCopy>
    </section>
  )
}

export interface IllegalWorkProps {
  className?: string
  style?: React.CSSProperties
  id?: string
  URL?: string
  taskId?: string
}
