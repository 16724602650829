import randomIcon from "@/assets/studio/mobile/random_m.svg"

export default ({ onClick }: { onClick?: () => void }) => {
  return (
    <div
      className="w-10 h-10 rounded-full bg-[#0C0C1A] flex items-center justify-center cursor-pointer"
      onClick={() => {
        onClick?.()
      }}
    >
      <img src={randomIcon} className="w-6 h-6" alt="random" />
    </div>
  )
}
