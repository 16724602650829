import { useEffect, useRef } from "react"
import MessageForm from "./MessageForm"
import studioPagesStore from "@/store/studioPagesStore"

interface MobileContentProps {
  onPromptChange?: (prompt: string) => void
  prompt?: string
  onRandomPromptClick?: () => void
  historyComponent?: JSX.Element
  onGenerateClick?: () => void
  templateId?: string
  onTemplateRemoveClick?: () => void
}
const MobileContent = ({
  historyComponent,
  onPromptChange,
  prompt,
  onRandomPromptClick,
  onGenerateClick,
  templateId,
  onTemplateRemoveClick,
}: MobileContentProps) => {
  const historyContainerRef = useRef<HTMLDivElement>(null)
  const scrollToBottom = () => {
    const historyContainer = historyContainerRef.current
    if (historyContainer) {
      historyContainer.scrollTop = historyContainer.scrollHeight
    }
  }
  const isProcessing = studioPagesStore.isProcessing.get()

  useEffect(() => {
    if (!isProcessing) {
      scrollToBottom()
    }
  }, [isProcessing])

  return (
    <div className="flex flex-col h-full">
      <div ref={historyContainerRef} className="flex-1 flex overflow-y-auto min-w-0">
        {historyComponent}
      </div>
      <MessageForm
        onPromptChange={onPromptChange}
        prompt={prompt}
        onRandomClick={() => {
          onRandomPromptClick?.()
        }}
        onGenerateClick={() => {
          onGenerateClick?.()
        }}
        templateId={templateId}
        onTemplateRemoveClick={onTemplateRemoveClick}
      />
    </div>
  )
}

export default MobileContent
