import { V1Task, V1Work } from "@/generated/api/data-contracts"
import { observer } from "mobx-react-lite"
import CheckAuth from "@/components/common/CheckAuth"
import { useState } from "react"
import TemplatModal from "@/common/TemplatModal/index"
const GenerateSubmitTemplate = observer(function ({
  buttonContent,
  task,
}: {
  buttonContent: React.ReactNode
  work?: V1Work
  task?: V1Task
}) {
  const [visible, setVisible] = useState(false)
  const onClick = async () => {
    setVisible(true)
  }
  return task?.textPrompt ? (
    <>
      <CheckAuth onClick={onClick}>{buttonContent}</CheckAuth>
      {task && (
        <TemplatModal
          open={visible}
          onClose={() => {
            setVisible(false)
          }}
          task={task}
        />
      )}
    </>
  ) : null
})

export default GenerateSubmitTemplate
