import { useEffect, useState } from "react"
import { V1ProductInfo } from "@/generated/api/data-contracts"
import { getSubscriptionList } from "@/services/subscription"
import Navbar from "@/Navbar"
import { useDarkMode } from "@/utils"
import { ReactComponent as ValueIcon } from "@/assets/subscription_value.svg"
import { ReactComponent as HotIcon } from "@/assets/subscription_hot.svg"
import { MONTHSUBSCRIPTIONS, YEARSUBSCRIPTIONS, getRecordNumberByDomain } from "@/constants"
import { ScrollToTopOnMount } from "@/common/ScrollToTopOnMount"
import { SpecialistWechatButton } from "@/common/SpecialistWechatButton"
import PlanCard from "@/components//bussiness/subscription/planCard"
import { EnterpriceComponent } from "@/components//bussiness/subscription/enterprice"
import RemainingAmount from "@/components/bussiness/subscription/remainingAmount"
import BuyHistory from "@/components/bussiness/subscription/buyHistory"
import LayoutGeneral from "@/components/layout/general"
import { MOBILE_CONTAINER_CLS } from "@/store/menuStore"
import MobileHeaderMenu from "@/components/layout/temp/MobileHeaderMenu"
import { isMobileOnly } from "react-device-detect"

const SubscriptionPage: React.FC = () => {
  useDarkMode()
  const [planType, setPlanType] = useState(0)
  const [productDetails, setProductDetails] = useState<{
    [key: number]: V1ProductInfo
  }>({})

  const getProductDetails = async () => {
    const res = await getSubscriptionList()
    const newProductDetail: {
      [key: number]: V1ProductInfo
    } = {}
    res?.map((item) => {
      newProductDetail[item.subscribeType || 0] = item
    })
    setProductDetails(newProductDetail)
  }
  useEffect(() => {
    getProductDetails()
  }, [])

  return (
    <LayoutGeneral>
      <ScrollToTopOnMount />
      <Navbar />

      <div
        className={`${MOBILE_CONTAINER_CLS} flex mx-auto flex-col justify-center items-center md:mt-[84px]`}
      >
        <MobileHeaderMenu />
        <div className=" mt-3 md:mt-0 md:max-w-7xl  mx-auto  sm:px-6 md:px-8 w-full relative">
          <div className="md:absolute right-6 md:top-4 cursor-pointer flex w-32 items-center absolute top-[-60px]">
            {/*  购买记录按钮加弹框 */}
            <BuyHistory />
          </div>
          <h1 className="text-[48px] text-center mt-10 mb-1">订阅方案</h1>
        </div>
        <div className="text-[#6F7176] text-base ">选择适合您的订阅方案</div>
        <RemainingAmount
          addPackagePrice={productDetails[40]?.price}
          onChange={(val) => {
            setPlanType(val === "month" ? 0 : 1)
          }}
        />
        <div className="flex flex-row w-[100%] md:w-auto">
          <div className="flex md:flex-row flex-col md:mt-[19px] mt-[5px] w-full md:w-auto">
            {(planType === 0 ? MONTHSUBSCRIPTIONS : YEARSUBSCRIPTIONS).map((i) => {
              const { id, price } = productDetails[i.subscribeType] || {}
              return (
                <PlanCard
                  key={id}
                  id={id}
                  price={price || 0}
                  subscribeType={i.subscribeType}
                  isMonthly={planType % 10 === 0}
                  badge={
                    <div className="flex items-center ">
                      <HotIcon className="mr-[6px]" />
                      限时特惠
                    </div>
                    // (Math.floor(i.subscribeType / 10) == 2 ? (
                    //   <div className="flex items-center ">
                    //     <HotIcon className="mr-[6px]" />
                    //     最受欢迎
                    //   </div>
                    // ) : undefined) ||
                    // (Math.floor(i.subscribeType / 10) == 3 ? (
                    //   <div className="flex items-center ">
                    //     <ValueIcon className="mr-[6px]" />
                    //     超值选择
                    //   </div>
                    // ) : undefined)
                  }
                />
              )
            })}
          </div>
        </div>
        <SpecialistWechatButton />
      </div>
      <EnterpriceComponent />
      <div className="w-full flex justify-center text-xs mt-10 mb-2 items-center cursor-pointer">
        <a href="https://beian.miit.gov.cn/#/Integrated/index">{getRecordNumberByDomain()}</a>
        <a
          className="flex  justify-center items-center"
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502020083"
        >
          <img className="pl-2 " src="/assets/beian.png" />
          <p className="pl-2 ">沪公网安备 31011502020083号</p>
        </a>
      </div>
    </LayoutGeneral>
  )
}

export default SubscriptionPage
