import "@arco-design/web-react/dist/css/arco.css"
import { useEffect, useState } from "react"
import { Carousel, Input, Modal, StepProps } from "@arco-design/web-react"
import { useDarkMode } from "@/utils"
import TaskProgress from "./task-progress"
import { apiListBusyTasks } from "@/services/editor"
import { V1Task, V1TaskStatus } from "@/generated/api/data-contracts"
import { ReactComponent as BackIcon } from "@/assets/arrow_left_dark.svg"

import { api } from "@/services/api"
import { useTranslation } from "react-i18next"
import { toastError } from "@/common/Toast"
import { ErrorCode } from "@/i18next"
import StyleModels from "./style-models"
import PreviewPage from "./preview-page"
import Animation from "./animation"
import "./animation.less"

export default () => {
  const [step, setStep] = useState<number>(0)
  const [textPrompt, setTextPrompt] = useState<string>("")
  const imageList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => {
    return `assets/dajiaInsurance/画板-${index + 1}.jpg`
  })

  const handleAddStep = () => {
    setStep(step + 1)
  }
  const handleMinusStep = () => {
    setStep(step - 1)
  }
  const handleDone = () => {
    setTask(undefined)
    setStep(0)
    setTextPrompt("")
    setUsername("")
  }
  useDarkMode()
  const { t } = useTranslation(["translation", "errorMessage"])

  const [task, setTask] = useState<V1Task>()
  const [loading, setLoading] = useState(false)
  const [username, setUsername] = useState("")
  const [budyLoading, setBusyLoading] = useState(true)

  const resultImage = task?.works?.[0].image
  const createIsDone = (task?.status as any) === V1TaskStatus.TaskStatusSuccess

  const [templateData, setTemplateData] = useState<{
    model: "string"
    ratio: string
    textPrompt: string[] // 用户输入中间插入，所以需要数组，传递2个
    referencedImage: string
    negtiveTextPrompt?: string
  }>()

  useEffect(() => {
    checkBusy()
  }, [])
  const checkBusy = async () => {
    const data = await apiListBusyTasks()
    if (data.list?.length) {
      setStep(4)
      loopTask(data.list[0].id || "")
    } else {
      setTimeout(() => {
        setBusyLoading(false)
      }, 500)
    }
  }

  const loopTask = (id: string) => {
    const timer = setInterval(async () => {
      try {
        const { data } = await api.getTask(id, { format: "json" })
        if (data.success) {
          setBusyLoading(false)
          setTask(data.data)
          if (data.data.status === V1TaskStatus.TaskStatusSuccess) {
            clearInterval(timer)
            setLoading(false)
          }
        } else {
          toastError(t(`errorMessage:${data.errorCode as ErrorCode}`))
        }
      } catch (error) {
        //
      }
    }, 1000) // 轮询时间
  }

  const create = async () => {
    if (!templateData || loading) {
      return
    }
    setLoading(true)
    const params = {
      ratio: "16:9",
      model: templateData.model,
      textPrompt: templateData.textPrompt?.[0]
        .concat(textPrompt)
        .concat(templateData.textPrompt?.[1] || ""),
      negativeTextPrompt: templateData.negtiveTextPrompt,
      // referencedImage: templateData.referencedImage,
    }
    try {
      const { data: res } = await api.createTask(params, { format: "json" })
      if (res.success) {
        const { data } = res
        data?.id && loopTask(data?.id)
        handleAddStep()
      } else {
        toastError(t(`errorMessage:${res.errorCode as ErrorCode}`))
      }
    } catch (error) {
      console.log(error, "error")
    }
  }

  return (
    <div>
      <div
        className={`relative flex flex-col overflow-hidden justify-center items-center bg-black w-screen h-screen`}
      >
        {createIsDone ? (
          <>
            <div
              className="absolute cursor-pointer rounded-[4vh] px-[3vh] py-[0.5vh] top-10 left-10 z-10 flex justify-center gap-2"
              style={{ border: "0.3vh solid white" }}
              onClick={handleDone}
            >
              <div className="text-white text-[2.4vh]">完成绘画</div>
            </div>
            <PreviewPage
              username={username}
              textPrompt={textPrompt}
              task={task!}
              resultImage={resultImage!}
              // "https://cos-xica-dev.tiamat.world/user/8iMCG8Le2kijX4bq5Z8lS/createdimage/yrXnpgnAh1eJFClY7gdL6.png"
              // }
              handleDone={handleDone}
            />
          </>
        ) : (
          <>
            {step != 0 && step != 1 && (
              <div
                className="absolute cursor-pointer top-[3vh] left-[3vh] z-[100] flex items-center justify-center gap-2"
                onClick={handleMinusStep}
              >
                <BackIcon className="text-[#ffffff] h-[3vh] w-[3vh]" />
                <button className="text-white text-[2.4vh]">返回上一步</button>
              </div>
            )}

            <Carousel
              style={{ objectFit: "cover", height: "100vh", width: "100vw", zIndex: "5" }}
              autoPlay={{
                interval: 10000,
                hoverToPause: true,
              }}
              moveSpeed={2000}
              animation="fade"
              timingFunc={"cubic-bezier(0.2, 0.6, 0.8, 0.9)"}
              indicatorType="never"
              showArrow="never"
            >
              {imageList.map((src) => (
                <div key={src} className="relative">
                  <img src={src} className="w-full object-contain max-w-full" />
                </div>
              ))}
            </Carousel>
            <div className="top-0 left-0 absolute w-full h-full bg-[rgba(0,0,0,0.1)]  z-40 "></div>

            <Animation animationStop={createIsDone} />
            {/* 水印  */}
            <img
              className={`h-[5vh] bottom-4 absolute right-4 z-20 `}
              src="/assets/dajiaInsurance/markable.png"
            />
            <div className="text-white  z-50 w-full h-screen absolute flex flex-col items-center justify-center">
              {step == 0 ? (
                <Layout
                  content={
                    <>
                      <div className="flex justify-center items-center flex-col">
                        <div>
                          <img
                            className="w-[35vh] h-[35vh]"
                            src="assets/dajiaInsurance/qrcode.png"
                          />
                        </div>
                        <div className="font-light  text-center text-[3vh] py-5 mb-6">
                          微信扫一扫，关注大家保险公众号
                        </div>
                      </div>
                      <SpecialButtonPart onClick={handleAddStep} content="下一步" />
                    </>
                  }
                />
              ) : step == 1 ? (
                <Layout
                  content={
                    <>
                      <div>
                        <div className="h-16"></div>
                        <SpeciakText content="未来的你会在哪里? 享受怎样的生活? " />
                        <SpeciakText content="用AI绘画出你的理想生活" />
                      </div>
                      <SpecialButtonPart onClick={handleAddStep} content="下一步" />
                    </>
                  }
                />
              ) : step == 2 ? (
                <Layout
                  content={
                    <>
                      <div className="w-[70vw]">
                        <div className="h-16"></div>
                        <SpeciakText isSmall content="请输入您的昵称" />
                        <div className="h-10"></div>
                        <input
                          className="h-[10vh] p-[2vh] w-1/2 rounded-[1.5vh] font-light bg-[rgba(0,0,0,0.5)] backdrop-blur-sm text-[4vh] text-white outline-none border-none  placeholder:text-[rgba(255,255,255,0.50)]"
                          value={username}
                          maxLength={8}
                          onCompositionEnd={async () => {
                            if (username.length >= 8) {
                              setUsername(username.slice(0, 8))
                              toastError("最多输入8个字符哦～")
                            }
                          }}
                          onChange={({ target }) => {
                            setUsername(target.value)
                          }}
                          placeholder="例如：唐伯虎点秋香"
                        />
                      </div>
                      <SpecialButtonPart
                        disable={username === ""}
                        onClick={handleAddStep}
                        content="下一步"
                      />
                    </>
                  }
                />
              ) : step == 3 ? (
                <>
                  <Layout
                    content={
                      <>
                        <div>
                          <SpeciakText isSmall content="请输入一句话描述未来您想在哪里生活?" />
                          <div className="h-[5vh]"></div>
                          <textarea
                            className="h-[20vh] w-[60vw] rounded-[1.5vh] p-[2vh]  bg-[rgba(0,0,0,0.5)] backdrop-blur-sm font-light text-[4vh] text-white outline-none border-none  placeholder:text-[rgba(255,255,255,0.50)]"
                            style={{ letterSpacing: "3px" }}
                            name="description"
                            placeholder="例如: 一座风景优美的海岛,岸边童话般梦幻的木屋"
                            rows={5}
                            maxLength={1000}
                            value={textPrompt}
                            onChange={async ({ target }) => {
                              await setTextPrompt(target.value)
                              if (target.value.length >= target.maxLength) {
                                toastError(`输入已达上限！请输入${target.maxLength}以内的字符`)
                              }
                            }}
                          />
                        </div>
                        <SpecialButtonPart
                          disable={textPrompt === ""}
                          onClick={handleAddStep}
                          content="下一步"
                        />
                      </>
                    }
                  />
                </>
              ) : step == 4 ? (
                <>
                  <Layout
                    content={
                      <>
                        <div>
                          <SpeciakText isSmall content="选择您喜欢的风格" />
                          <div className="h-[5vh]"></div>
                          <StyleModels
                            onChange={(data) => {
                              setTemplateData(data.payload)
                            }}
                          />
                        </div>
                        <SpecialButtonPart onClick={create} content="开始造梦" />
                      </>
                    }
                  />
                </>
              ) : (
                <>
                  <SpeciakText content="大家的家助力实现您的梦想" isBig />
                  <div
                    className={`h-[5vh] font-light text-[3.2vh] text-center overflow-hidden`}
                    style={{ letterSpacing: "5px" }}
                  >
                    <div className="text-animation-wrapper">
                      <div className="w-screen h-[5vh] ">临近医疗、亲近子女、更近人间烟火</div>
                      <div className="w-screen h-[5vh] ">离红尘不远，在山水之间</div>
                      <div className="w-screen h-[5vh] ">精准服务,嵌入式照护</div>
                    </div>
                  </div>
                  <div className="h-[10vh]"></div>
                  <div className="font-light text-[2.4vh] py-[2vh]">
                    请稍等，AI算力加载生成中...
                  </div>
                  <TaskProgress task={task!} />
                </>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

const SpecialButtonPart = ({
  content,
  onClick,
  disable,
}: {
  content: React.ReactNode
  onClick: () => void
  disable?: boolean
}) => {
  return (
    <button
      className={`z-[100] text-[4vh] text-center rounded-[4vh] py-[2vh] px-[8vh]  ${
        disable ? "bg-[#d80c2471]" : "bg-[#D80C24]"
      }  `}
      style={{ boxShadow: "0px 10px 20px 0px rgba(216, 12, 36, 0.20)" }}
      onClick={onClick}
      disabled={disable}
    >
      {content}
    </button>
  )
}

const SpeciakText = ({
  content,
  isBig = false,
  isSmall = false,
}: {
  content: React.ReactNode
  isBig?: boolean
  isSmall?: boolean
}) => {
  return (
    <div
      className={`font-light ${isBig ? "text-[7vh]" : isSmall ? "text-[5vh]" : "text-[6vh]"} py-1`}
      style={{ letterSpacing: isSmall ? "4px" : "8px" }}
    >
      {content}
    </div>
  )
}

const Layout = ({ content }: { content: React.ReactNode }) => {
  // pb-[40%]
  return (
    <>
      <div className="h-[5%] w-full"></div>
      <div className="h-[60%] text-center flex flex-col items-center justify-between">
        {content}
      </div>
      <div className="h-[15%] w-full"></div>
    </>
  )
}
