import { toastError } from "@/common/Toast"
import { V1Task, V1TaskStatus } from "@/generated/api/data-contracts"
import { Carousel } from "@arco-design/web-react"
import { useEffect } from "react"
import { Link, redirect } from "react-router-dom"
import Animation from "./animation"

const DajiaPreviewPage = () => {
  const imageList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => {
    return `assets/dajiaInsurance/画板-${index + 1}.jpg`
  })
  return (
    <>
      <div className="relative h-screen w-full overflow-hidden  flex flex-col justify-between items-start ">
        {/* <img
          className=" absolute left-0 top-0 -z-10 object-cover h-[150vh]"
          src="/assets/dajiaInsurance/画板-1.jpg"
        /> */}
        <Carousel
          style={{
            position: "absolute",
            left: "0",
            top: "0",
            objectFit: "cover",
            height: "100vh",
            zIndex: "-10",
          }}
          autoPlay={{
            interval: 10000,
            hoverToPause: true,
          }}
          moveSpeed={2000}
          animation="fade"
          timingFunc={"cubic-bezier(0.2, 0.6, 0.8, 0.9)"}
          indicatorType="never"
          showArrow="never"
        >
          {imageList.map((src) => (
            <div key={src} className="relative">
              <img src={src} className=" object-cover h-full-important" />
            </div>
          ))}
        </Carousel>
        <div className="w-full h-full flex text-center items-center text-white flex-col justify-between ">
          <div className="mt-20 ">
            <img className="w-2/3 mx-auto" src="/assets/dajiaInsurance/markable.png" />
          </div>
          <div className="text-[1.8rem] font-bold leading-20">
            <div>AI画出理想家</div>
            <div>大家助力实现您的梦想</div>
            <Link to="/studio">
              <button className="text-xl px-6 py-1 rounded-3xl mt-10 bg-[#D7AD76] text-[rgba(130,70,27,1)]">
                开始创作
              </button>
            </Link>
          </div>
          <div className="w-[200vw]">
            <img src="/assets/dajiaInsurance/animation-stop.png" />
          </div>
          {/* <div>
            <Animation isMobileOnly />
          </div> */}
        </div>
      </div>
    </>
  )
}

export default DajiaPreviewPage
