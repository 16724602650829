import { Button } from "@arco-design/web-react"
import { profileSceneType } from "../types"

interface FooterProps {
  onCancel: () => void
  onConfirm: () => void
  confirmButtonDisabled?: boolean
  cancelText?: string
  scene?: profileSceneType
}
const Footer = ({
  onCancel,
  onConfirm,
  confirmButtonDisabled = false,
  cancelText,
}: FooterProps) => {
  return (
    <div className="pt-[74px] md:pt-10 px-5 md:px-10 flex justify-center gap-2.5">
      <Button className="cancel_btn w-[162px] md:w-[120px] text-sm font-sans" onClick={onCancel}>
        {cancelText || "取消"}
      </Button>
      <Button
        className="confirm_btn w-[162px] md:w-[120px] text-sm font-sans"
        type="primary"
        onClick={onConfirm}
        disabled={confirmButtonDisabled}
      >
        确定
      </Button>
    </div>
  )
}

export default Footer
