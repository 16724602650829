import studioPagesStore from "@/store/studioPagesStore"
import ControlNetItem from "./ControlNetItem"
import { filterControlNetModel } from "@/utils"
import CheckAuth from "@/components/common/CheckAuth"

interface ControlNetProps {
  onUploadClick?: (mode: Array<string>) => void
}
const ControlNet = ({ onUploadClick }: ControlNetProps) => {
  const modelVersion = studioPagesStore.store.get().modelVersion

  return (
    <div className="flex flex-col gap-y-[14px]">
      {filterControlNetModel(modelVersion).map((item) => {
        return (
          <CheckAuth
            needLogin
            key={item.value}
            onClick={() => {
              onUploadClick?.(typeof item?.value === "string" ? [item.value] : item.value)
            }}
          >
            <ControlNetItem
              key={item.value}
              title={item.label}
              description={item.description}
              imgSrc={item.img}
            />
          </CheckAuth>
        )
      })}
    </div>
  )
}

export default ControlNet
