import TemporaryLayout from "@/components/layout/temp"
import SecondCreationImage from "@/components/zhangjiang-v2/second-creation-image"
import StyleModels from "@/components/zhangjiang-v2/style-models"
import { useDarkMode } from "@/utils"
import { useEffect, useState } from "react"
import ZhangjiangV2Preview from "../Preview"
import TaskProgress from "@/components/zhangjiang-v2/task-progress"
import { apiListBusyTasks } from "@/services/editor"
import { V1Task, V1TaskStatus } from "@/generated/api/data-contracts"
import { api } from "@/services/api"
import { useTranslation } from "react-i18next"
import { toastError } from "@/common/Toast"
import { ErrorCode } from "@/i18next"

const ZhangjiangV2Studio = () => {
  useDarkMode()
  const { t } = useTranslation(["translation", "errorMessage"])

  const [task, setTask] = useState<V1Task>()
  const [img, setImg] = useState("")
  const [loading, setLoading] = useState(false)
  const [budyLoading, setBusyLoading] = useState(true)
  img
  const resultImage = task?.works?.[0].image

  const [templateData, setModel] = useState<{
    model: "string"
    ratio: string
    textPrompt: string
    controlNetUnit1: {
      module: string
      weight: number
    }
  }>()

  useEffect(() => {
    checkBusy()
  }, [])
  const checkBusy = async () => {
    const data = await apiListBusyTasks()
    if (data.list?.length) {
      // setTask(data.list[0])
      loopTask(data.list[0].id || "")
    } else {
      setTimeout(() => {
        setBusyLoading(false)
      }, 500)
    }
  }

  const loopTask = (id: string) => {
    const timer = setInterval(async () => {
      try {
        const { data } = await api.getTask(id, { format: "json" })
        if (data.success) {
          setBusyLoading(false)

          setTask(data.data)
          if (data.data.status === V1TaskStatus.TaskStatusSuccess) {
            clearInterval(timer)
            setLoading(false)
          }
        } else {
          toastError(t(`errorMessage:${data.errorCode as ErrorCode}`))
        }
      } catch (error) {
        //
      }
    }, 1000)
  }

  const create = async () => {
    if (!templateData || loading) {
      return
    }
    setLoading(true)
    const params = {
      ratio: "16:9",
      model: templateData.model,
      textPrompt: templateData.textPrompt,
      controlNet: {
        units: [
          {
            inputImageURL: location.origin + img,
            module: templateData.controlNetUnit1.module,
            weight: 0.8,
          },
        ],
      },
    }
    try {
      const { data: res } = await api.createTask(params, { format: "json" })
      if (res.success) {
        const { data } = res
        data?.id && loopTask(data?.id)
      } else {
        toastError(t(`errorMessage:${res.errorCode as ErrorCode}`))
      }
    } catch (error) {
      //
    }
  }

  return (
    <>
      <TemporaryLayout>
        <div className="w-full h-full">
          {budyLoading ? (
            <div className="h-[80vh]"></div>
          ) : (
            <>
              {!task ? (
                <div className="w-full h-full flex flex-col justify-around">
                  <div className="mt-10">
                    <h1 className="text-2xl m-10">
                      <i>1.</i> 选择二创图像
                    </h1>
                    <div>
                      <SecondCreationImage
                        onChange={(data) => {
                          setImg(data.path)
                        }}
                      />
                    </div>
                  </div>
                  <div className="mt-16">
                    <h1 className="text-2xl m-10">
                      <i>2.</i> 选择绘图风格
                    </h1>
                    <div>
                      <StyleModels
                        onChange={(data) => {
                          setModel(data.payload)
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-full h-full mb-4 flex items-end justify-center px-[100px]">
                    <button
                      onClick={create}
                      className={`${
                        loading ? "bg-[#744aff98] cursor-wait" : ""
                      } bg-[#734AFF] rounded-full w-2/5 h-20 py-3 font-bold text-[28px]
                      `}
                    >
                      生成
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  {(task?.status as any) === V1TaskStatus.TaskStatusSuccess ? (
                    <>
                      {resultImage ? (
                        <div className="h-[80vh] flex justify-center items-center">
                          <div>
                            <img
                              className="mt-[18rem]"
                              src={`${resultImage}/zhangjiang_watermark`}
                              alt=""
                            />
                            <div className="w-full h-full flex justify-center mt-12 px-[100px]">
                              <button
                                onClick={() => {
                                  //
                                  setTask(undefined)
                                }}
                                className={`mt-20 bg-[#734AFF] rounded-full w-2/5 h-20 py-3 font-bold text-3xl`}
                              >
                                完成
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>loading</>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="h-full w-full flex flex-col flex-end">
                        <TaskProgress task={task} />
                        <div className="h-[50vh]">
                          <ZhangjiangV2Preview onlyContent={true} />
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </TemporaryLayout>
    </>
  )
}

export default ZhangjiangV2Studio
