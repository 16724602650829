import modelSeletIcon from "@/assets/studio/model_select.svg"
import sizeIcon from "@/assets/studio/size.svg"
import imageTalkIcon from "@/assets/studio/image_talk.svg"
import uploadIcon from "@/assets/studio/upload.svg"
import controlNetIcon from "@/assets/studio/control_net.svg"
import Option from "./Option"
import { useState } from "react"
import ModalSelectUI from "./ModalSelect/ModalSelectUI"
import { observer } from "mobx-react-lite"
import studioPagesStore from "@/store/studioPagesStore"
import ControlNet from "./ControlNet"
import { ImageSpeakInStudioPage } from "../ImageSpeak"
import * as store from "@/store"
import { V1TaskPriority } from "@/generated/api/data-contracts"
import ImageSize from "./ImageSize"
import CheckAuth from "@/components/common/CheckAuth"
import ReferenceImage from "./ReferenceImage"
import ControlNetResult from "./ControlNet/Result"
import { isMobileOnly } from "react-device-detect"
import { toJS } from "mobx"
import { Modal } from "@arco-design/web-react"
import { findConrtolNetModelLabel } from "@/utils"

interface ActionButtonProps {
  text: string
  onClick?: () => void
}
export const ActionButton = ({ text, onClick }: ActionButtonProps) => {
  return (
    <div
      onClick={() => {
        onClick?.()
      }}
      className="bg-[#6663FF] h-[28px] px-4 text-white text-sm font-normal leading-tight flex items-center rounded cursor-pointer"
    >
      {text}
    </div>
  )
}

interface StudioOptionsProps {
  onPromptGenerated?: (prompt: string) => void
  onReferenceImageUpload?: (isEditor: boolean) => void
  onControlNetUpload?: (mode: Array<string>) => void
}
const StudioOptions = ({
  onPromptGenerated,
  onReferenceImageUpload,
  onControlNetUpload,
}: StudioOptionsProps) => {
  const { isHigh, referencelIamgeData, modelVersion, model } = studioPagesStore.store.get()
  const { id: userId } = store.user.get()

  const TIMv5ControlNetModel = ["canny", "depth", "openpose"]

  const [expandedOptionId, setExpandedOptionId] = useState<string | null>(
    isMobileOnly ? null : "modelSelect"
  )

  const [switchModelVisabled, setSwitchModelVisabled] = useState<boolean>(false)
  const [switchModel, setSwitchModel] = useState("")
  const [switchModelTitle, setSwitchModelTitle] = useState(
    "<p>V5模型暂不支持两个相同的controlnet使用</p> 确认使用会清除参考二部分"
  )

  const handleOptionSelected = (id: string) => {
    if (id === expandedOptionId) {
      setExpandedOptionId(null)
    } else {
      setExpandedOptionId(id)
    }
  }

  const referenceImageSrc =
    referencelIamgeData?.type === "referencelImage" ? referencelIamgeData?.preViewUrl : undefined
  const controlNetResultVisible = referencelIamgeData?.type === "controlNet"

  const referencelImageDataControlNet =
    controlNetResultVisible && referencelIamgeData?.payload?.controlNet

  const removeReferenceImage = () => {
    studioPagesStore.setStore({
      referencelIamgeData: undefined,
    })
  }

  const setModel = (model: string) => {
    studioPagesStore.setStore({
      model,
    })
  }

  const setSwitchModelInfo = (title: string, visabled: boolean, modelVersion: string) => {
    setSwitchModelTitle(title)
    setSwitchModelVisabled(visabled)
    setSwitchModel(modelVersion)
  }

  const setModelVersion = (modelVersion: string, isModalConfirm?: boolean) => {
    const controlNetList =
      (controlNetResultVisible && toJS(referencelIamgeData)?.payload?.controlNet?.units) || []
    let copyReferencelIamgeData: any = referencelIamgeData

    if (controlNetResultVisible) {
      if (controlNetList.length === 2 && modelVersion === "TIMv5") {
        const [ControlNet1, ControlNet2] = controlNetList
        const isSame = ControlNet1?.module === ControlNet2?.module
        const isV5ControlNet1 =
          ControlNet1?.module && TIMv5ControlNetModel.includes(ControlNet1?.module)
        const isV5ControlNet2 =
          ControlNet2?.module && TIMv5ControlNetModel.includes(ControlNet2?.module)

        if (isV5ControlNet1 && isV5ControlNet2 && isSame) {
          setSwitchModelInfo(
            "<p>V5模型暂不支持两个相同的controlnet使用</p> 确认使用会清除参考二部分",
            true,
            modelVersion
          )
          if (isModalConfirm) {
            copyReferencelIamgeData?.payload?.controlNet?.units?.splice(1, 1)
            setSwitchModelVisabled(false)
            studioPagesStore.setStore({
              modelVersion: switchModel,
              referencelIamgeData: toJS(copyReferencelIamgeData),
            })
          }
          return
        }

        if (!isV5ControlNet1 || !isV5ControlNet2) {
          let holdOnModule = ""

          if (isSame && !isV5ControlNet1) {
            holdOnModule += findConrtolNetModelLabel(ControlNet1?.module)
          } else {
            if (!isV5ControlNet1) holdOnModule += findConrtolNetModelLabel(ControlNet1?.module)
            if (!isV5ControlNet1 && !isV5ControlNet2) {
              holdOnModule += ","
            }
            if (!isV5ControlNet2) holdOnModule += findConrtolNetModelLabel(ControlNet2?.module)
          }

          setSwitchModelInfo(
            `<p>V5模型暂不支持【${holdOnModule}】</p> 确认使用会清除【${holdOnModule}】`,
            true,
            modelVersion
          )

          if (isModalConfirm) {
            copyReferencelIamgeData = {
              ...copyReferencelIamgeData,
              payload: {
                controlNet: {
                  units: copyReferencelIamgeData?.payload?.controlNet?.units?.filter((item: any) =>
                    TIMv5ControlNetModel.includes(item?.module)
                  ),
                },
              },
            }

            setSwitchModelVisabled(false)
            studioPagesStore.setStore({
              modelVersion: switchModel,
              referencelIamgeData:
                isSame && !isV5ControlNet1 ? null : toJS(copyReferencelIamgeData),
            })
          }
          return
        }
      }

      if (controlNetList.length === 1 && modelVersion === "TIMv5") {
        const [ControlNet1] = controlNetList
        const isV5ControlNet1 =
          ControlNet1?.module && TIMv5ControlNetModel.includes(ControlNet1?.module)

        if (!isV5ControlNet1) {
          let holdOnModule = ""
          holdOnModule += findConrtolNetModelLabel(ControlNet1?.module)

          setSwitchModelInfo(
            `<p>V5模型暂不支持【${holdOnModule}】</p> 确认使用会清除【${holdOnModule}】`,
            true,
            modelVersion
          )

          if (isModalConfirm) {
            setSwitchModelVisabled(false)
            studioPagesStore.setStore({
              modelVersion: switchModel,
              referencelIamgeData: undefined,
            })
          }
          return
        }
      }
    }
    studioPagesStore.setStore({ modelVersion })
  }
  const options = [
    {
      id: "modelSelect",
      icon: modelSeletIcon,
      title: "模型选择",
      content: (
        <ModalSelectUI
          modelId={model}
          modelVersionId={modelVersion}
          onClick={setModel}
          modelVersionClick={setModelVersion}
        />
      ),
    },
    {
      id: "upload",
      icon: uploadIcon,
      title: "上传参考图",
      actionButton: (
        <CheckAuth
          onClick={() => {
            onReferenceImageUpload?.(false)
          }}
        >
          <ActionButton text="上传" />
        </CheckAuth>
      ),
      resultContent: referenceImageSrc ? (
        <ReferenceImage
          imageSrc={referenceImageSrc}
          onRemove={removeReferenceImage}
          onImageClick={(isEditor: boolean) => {
            onReferenceImageUpload && onReferenceImageUpload(isEditor)
          }}
        />
      ) : undefined,
    },
    {
      id: "controlNet",
      icon: controlNetIcon,
      title: "ControlNet",
      content: <ControlNet onUploadClick={onControlNetUpload} />,
      resultContent:
        controlNetResultVisible && referencelImageDataControlNet ? (
          <ControlNetResult
            onUpload={onControlNetUpload}
            onRemove={(index) => {
              let copyReferencelIamgeData = referencelIamgeData
              let length = copyReferencelIamgeData?.payload?.controlNet?.units?.length || 0
              //  两个移除其中一个
              if (length > 1) {
                copyReferencelIamgeData?.payload?.controlNet?.units?.splice(index, 1)
                studioPagesStore.setStore({
                  referencelIamgeData: toJS(copyReferencelIamgeData),
                })
              } else {
                removeReferenceImage()
              }
            }}
          />
        ) : undefined,
    },
    // {
    //   id: "imageTalk",
    //   icon: imageTalkIcon,
    //   title: "图片会说话",
    //   actionButton: (
    //     <ImageSpeakInStudioPage
    //       priority={
    //         !userId
    //           ? V1TaskPriority.TaskPriorityLow
    //           : isHigh
    //           ? V1TaskPriority.TaskPriorityHigh
    //           : V1TaskPriority.TaskPriorityLow
    //       }
    //       addImageSpeakPrompt={onPromptGenerated}
    //       buttonContent={<ActionButton text="上传" />}
    //     />
    //   ),
    // },
    {
      id: "size",
      icon: sizeIcon,
      title: "图片尺寸",
      content: <ImageSize />,
    },
  ]

  const filterOptions = options.filter((item) => {
    if (modelVersion === "TIMv5") {
      if (["modelSelect", "size", "controlNet", "upload"].includes(item.id)) {
        return item
      }
    } else {
      return item
    }
  })

  return (
    <div className="mt-1 md:mt-0 gap-y-1 w-full p-0 md:w-[400px] min-w-[300px] md:p-5 bg-[#161623] flex flex-col md:gap-y-[14px] overflow-y-auto scrollbar-hidden">
      {filterOptions.map((item) => {
        return (
          <Option
            key={item.id}
            iconSrc={item.icon}
            label={item.title}
            expanded={item.id === expandedOptionId}
            onSelect={() => {
              handleOptionSelected(item.id)
            }}
            actionButton={item.actionButton}
            resultContent={item.resultContent}
          >
            {item.content}
          </Option>
        )
      })}

      <Modal
        onCancel={() => {
          setSwitchModelVisabled(false)
        }}
        visible={switchModelVisabled}
        modalRender={() => (
          <>
            <div className="w-[400px] py-10 relative mx-auto top-0 inline-block align-middle  bg-[#212134] rounded overflow-hidden">
              <div dangerouslySetInnerHTML={{ __html: switchModelTitle }} />
              <div className="pt-8 ">
                <span
                  onClick={() => setSwitchModelVisabled(false)}
                  className="border-[#FFFFFFCC] border-[1px] rounded-[4px] text-[white]  px-7 py-3"
                >
                  <span className="cursor-pointer">取消</span>
                </span>
                <span
                  onClick={() => {
                    setModelVersion(switchModel, true)
                    // if (controlNetResultVisible) {
                    //   let copyReferencelIamgeData: any = referencelIamgeData
                    //   // 删除第二个controlnet
                    //   copyReferencelIamgeData?.payload?.controlNet?.units?.splice(1, 1)
                    //   //
                    //   copyReferencelIamgeData =
                    //     copyReferencelIamgeData?.payload?.controlNet?.units?.map((item: any) => {
                    //       if (TIMv5ControlNetModel.includes(item?.module)) return item
                    //     })

                    //   studioPagesStore.setStore({
                    //     modelVersion: switchModel,
                    //     referencelIamgeData: toJS(copyReferencelIamgeData),
                    //   })
                    // }
                  }}
                  className="bg-[#4C1FFF] rounded px-7 py-3 ml-3"
                >
                  <span className="cursor-pointer">确认</span>
                </span>
              </div>
            </div>
          </>
        )}
      />
    </div>
  )
}

export default observer(StudioOptions)
