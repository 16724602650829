import { Tooltip } from "@arco-design/web-react"

interface ActionButtonProps {
  iconSrc?: string
  text: string
  onClick?: () => void
  buttonBorderColorCls?: string
  buttonBgColorCls?: string
  textColorCls?: string
  disabled?: boolean
  tooltipMessage?: string
  icon?: React.ReactNode
}
const ActionButton = ({
  iconSrc,
  text,
  onClick,
  buttonBorderColorCls = "border-[#fff]",
  textColorCls = "text-[#fff]",
  buttonBgColorCls = "bg-transparent",
  disabled = false,
  tooltipMessage,
  icon,
}: ActionButtonProps) => {
  const paddingLeftCls = iconSrc ? "" : "pl-3"

  return (
    <button
      className={`
        flex items-center rounded-full border pl-1 pr-3 py-[1px] min-w-fit self-stretch
        ${buttonBorderColorCls}
        ${buttonBgColorCls}
        disabled:cursor-not-allowed
        ${paddingLeftCls}
      `}
      onClick={() => {
        onClick?.()
      }}
      disabled={disabled}
    >
      {/* Button Icon */}
      {icon ? icon : iconSrc && <img src={iconSrc} alt="" className="w-7 h-7 object-cover" />}

      <Tooltip content={tooltipMessage} disabled={!tooltipMessage}>
        {/* Button Text */}
        <span
          className={`
          text-[14px] leading-5
          ${textColorCls}
        `}
        >
          {text}
        </span>
      </Tooltip>
    </button>
  )
}

export default ActionButton
