import { ReactComponent as LoadingPuffIcon } from "@/assets/loading_puff.svg"
import { ReactComponent as CloudUploadIcon } from "@/assets/cloud-upload.svg"
import { ReactComponent as CloseIcon } from "@/assets/closeIcon.svg"
import { toastError } from "@/common/Toast"
import { createRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import { Upload } from "@arco-design/web-react"
import "./ControlNet.less"
import Cropper, { ReactCropperElement } from "react-cropper"
import { observer } from "mobx-react-lite"
import { useTranslation } from "react-i18next"
import { uploadFile } from "@/services/editor"
import { ErrorCode } from "@/i18next"
import * as store from "@/store"

type handleChangValue = {
  inputImageURL: string
}

interface ActivityUploadImageProps {
  ratio: string
  //  数据改变触发
  handleChange: ({ inputImageURL }: handleChangValue) => void
  className?: string
  init?: {
    inputImageURL?: string
  }
}
export interface useImperativeHandleProps {
  uploadCropImage: () => Promise<any>
  controlNetUploadRef: HTMLInputElement
  imgUrl?: string
}
export const ActivityUploadImage = observer(
  ({ handleChange, className, ratio = "1:1", init }: ActivityUploadImageProps, ref: any) => {
    const user = store.user.get()
    const { t } = useTranslation(["translation", "errorMessage"])
    const [imgStatus, setImgStatus] = useState<0 | 1 | 2>(() => 0) // 0 待选择图 1 上传中 2 上传成功
    const controlNetUploadRef = useRef(null)
    const cropperRef = createRef<ReactCropperElement>() // cropper ref
    const [aspectRatio, setAspectRatio] = useState(1)
    const rotateTo = (item: string) => {
      const [w, h] = item.split(":")
      const rotateNum = +w / +h
      setAspectRatio(rotateNum)
    }
    // const ratio = studioPagesStore.store.get().ratio
    // 选择完图片进行上传 loading时展示图片
    const [imageUrl, setImageUrl] = useState<string>()

    useImperativeHandle(ref, () => ({
      // 上传裁剪后的图片
      uploadCropImage: cropImage,
      controlNetUploadRef: controlNetUploadRef.current as ReactCropperElement | null,
      imgUrl: imageUrl,
      // 重置裁剪图
    }))

    //  将网址图片转为本地 blob
    const transNetImg = (url: string) => {
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const url = URL.createObjectURL(blob)
          setImageUrl(url)
        })
    }
    useEffect(() => {
      // 已有图片
      if (init?.inputImageURL) {
        transNetImg(init?.inputImageURL)
        setImgStatus(2)
      }
    }, [init?.inputImageURL])

    //  选择图片
    const selectImg = (file?: File) => {
      if (!file) {
        return
      }
      if (file.size / 1048576 > 5) {
        return toastError("单个文件不能超过5M")
      }
      const url = URL.createObjectURL(file)
      setImageUrl(url)
      handleChange({ inputImageURL: url })
      setImgStatus(2)
    }
    //  清空图片：清空url、cropper销毁图片、改变上传状态
    const removeImage = () => {
      setImageUrl("")
      cropperRef.current?.cropper?.destroy()
      handleChange({ inputImageURL: "" })
      setImgStatus(0)
    }
    //  创建Promise对象
    const cropImage = () => {
      return new Promise((resolve, reject) => {
        const canvas = cropperRef.current?.cropper.getCroppedCanvas({
          imageSmoothingQuality: "low",
        })
        if (canvas) {
          canvas?.toBlob(
            async (blob) => {
              if (!blob) {
                return
              }
              const file = new File([blob], "image", {
                type: "image/jpeg",
              })
              setImgStatus(1)
              try {
                const { data } = await uploadFile({ file })
                if (data.success && data.data.fileUrl) {
                  resolve(data.data.fileUrl)
                } else {
                  // data.errorCode
                  toastError(t(`errorMessage:${data.errorCode as ErrorCode}`))
                  resolve("error")
                  // reject("上传失败请重试")
                }
              } catch (error) {
                reject("上传失败请重试")
              }
              setImgStatus(2)
            },
            "image/jpeg",
            0.8
          )
        } else {
          reject("请上传图片")
        }
      })
    }

    useEffect(() => {
      rotateTo(ratio)
    }, [ratio])

    return (
      <div className={`relative ${className}`}>
        <div className="pb-4 relative">
          {imgStatus === 2 && (
            <div className="absolute right-[-10px] top-[-10px] z-10" onClick={removeImage}>
              <CloseIcon />
            </div>
          )}
          {imgStatus === 0 && (
            <div className="relative w-auto max-w-[260px] max-h-[260px]">
              <Upload
                className="w-full h-full"
                accept="image/*"
                disabled={!user.id}
                showUploadList={false}
                onChange={(fileList, file) => {
                  selectImg(file.originFile)
                }}
              >
                <div className="trigger max-w-[260px] h-[260px] pt-24 rounded border border-[#373747] border-dashed text-center">
                  <div className="flex justify-center my-1">
                    <CloudUploadIcon className="md:w-[24px] w-[72px] md:h-[24px] h-[72px] fill-white" />
                  </div>
                  <h2>点击/拖拽上传</h2>
                  <p className="text-[#ffffff4d] text-xs">（单个文件不能超过5M）</p>
                </div>
              </Upload>
            </div>
          )}
          {imgStatus !== 0 && (
            <div className="group  max-w-[260px] max-h-[260px]  relative overflow-hidden rounded-md">
              <Cropper
                key={`${aspectRatio}`}
                wheelZoomRatio={1}
                ref={cropperRef}
                style={{
                  height: 260,
                  width: 260,
                }}
                zoomable={false} // 图缩放
                aspectRatio={aspectRatio}
                preview=".img-preview"
                src={imageUrl}
                viewMode={1}
                dragMode="none"
                minCropBoxHeight={100}
                minCropBoxWidth={100}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                guides={true}

                // 拖动裁切框触发
                // cropend={cropImage}
                // cropBoxMovable={imgStatus === 0}
                // cropBoxResizable={imgStatus === 0}
              />
              {imgStatus === 1 && (
                <div className="absolute inset-0 rounded bg-black/80 flex items-center justify-center">
                  <LoadingPuffIcon />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    )
  },
  { forwardRef: true }
)
