import { useRef, useState, useMemo } from "react"
import { Form, Input, Upload, Radio, Modal, Button } from "@arco-design/web-react"
import { toastError, toastSuccess } from "@/common/Toast"
import { useTranslation } from "react-i18next"
import { api } from "@/services/api"
import type { FormInstance } from "@arco-design/web-react"
import { ReactComponent as IconPlus } from "@/assets/plus.svg"
import { CategoryThemeMap } from "../constants"
import type { UploadItem } from "@arco-design/web-react/lib/Upload"
import { ReactComponent as IconLoading } from "@/assets/loading.svg"

import "./form.less"
import { LogicError } from "@/types"
import { V1CreateTicketReq, V1TicketCategory, V1TicketTheme } from "@/generated/api/data-contracts"

const FormItem = Form.Item

const formItemLayout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 20,
  },
}

interface Props {
  pageType: "account" | "bug" | "suggestion"
}

export default function AccountForm({ pageType = "account" }: Props) {
  const { t } = useTranslation(["translation", "errorMessage"])

  const [loadingUpload, setLoadingUpload] = useState(false)
  const [fileList, setFileList] = useState<UploadItem[]>([])
  const fileLimit = 4

  const formRef = useRef<FormInstance>(null)
  const [type, setType] = useState<V1TicketTheme>()
  const [desc, setDesc] = useState("")

  const formItemInfo = useMemo(() => {
    return {
      label: pageType === "suggestion" ? "建议类型" : "问题类型",
      radioOptions: CategoryThemeMap[pageType]?.themes ?? [],
    }
  }, [pageType])

  function clearFormData() {
    setType(undefined)
    setDesc("")
    setFileList([])
  }

  const [loading, setLoading] = useState(false)
  async function submitTicket() {
    try {
      setLoading(true)
      const params: V1CreateTicketReq = {
        category: pageType as V1TicketCategory,
        theme: type as V1TicketTheme,
      }
      if (desc) {
        params.description = desc
      }
      if (fileList.length) {
        params.images = fileList.map((item) => item.url ?? "")
      }
      const { data } = await api.createTicket(params)
      if (data.success) {
        toastSuccess("提交成功")
        clearFormData()
        formRef.current?.clearFields()
      } else {
        toastSuccess("提交失败，请稍后再试")
      }
    } catch (error) {
      if (error instanceof LogicError) {
        toastError(t(`errorMessage:${error.code}` as any))
      }
    } finally {
      setLoading(false)
    }
  }
  async function handleBeforeUpload(file?: File) {
    if (!file) {
      return false
    }
    if (file.size / 1048576 > 2) {
      toastError("单个文件不能超过2M")
      return false
    }

    return true
  }

  async function handleUploadFile(file: File) {
    if (!file) {
      return
    }
    const fd = new FormData()
    fd.append("file", file)
    try {
      setLoadingUpload(true)
      const { data } = await api.uploadImageForTicket({ file })
      if (data.success && data.data.fileUrl) {
        const uploadFile = {
          ...file,
          uid: String(Date.now()),
          url: data.data.fileUrl,
        } as UploadItem

        setFileList((pre) => {
          if (pre) {
            return [...pre, uploadFile]
          } else {
            return [uploadFile]
          }
        })
      } else {
        toastError("上传失败，请稍后再试或更换图片")
      }
    } catch (error) {
      if (error instanceof LogicError) {
        toastError(t(`errorMessage:${error.code}` as any))
      }
    } finally {
      setLoadingUpload(false)
    }
  }

  return (
    <div className="account-form-wrapper w-full">
      <Form
        ref={formRef}
        autoComplete="off"
        {...formItemLayout}
        scrollToFirstError
        layout="vertical"
      >
        <FormItem
          label={formItemInfo.label}
          field="type"
          rules={[{ required: true, message: "请选择问题类型" }]}
        >
          <Radio.Group value={type} onChange={(val) => setType(val)}>
            {formItemInfo.radioOptions.map((item) => {
              return (
                <Radio key={item.value} value={item.value}>
                  {item.label}
                </Radio>
              )
            })}
          </Radio.Group>
        </FormItem>
        <FormItem
          label="问题描述"
          field="desc"
          rules={[{ required: true, message: "请输入问题描述" }]}
        >
          <Input.TextArea
            autoSize={{ minRows: 3, maxRows: 6 }}
            onChange={(val) => setDesc(val)}
            placeholder="您的反馈对我们很重要..."
          />
        </FormItem>

        <Form.Item label="图片上传" field="image" triggerPropName="fileList">
          <Upload
            listType="picture-card"
            multiple
            accept="image/jpg,image/png,image/jpeg"
            className={"feedback-form-uploader"}
            name="files"
            limit={fileLimit}
            fileList={fileList}
            onRemove={(file) => {
              setFileList((preList) => {
                return preList?.filter((item) => item.uid !== file.uid)
              })
            }}
            onExceedLimit={() => toastError(`超过数量限制，请上传少于${fileLimit}张图片`)}
            beforeUpload={handleBeforeUpload}
            customRequest={(options) => handleUploadFile(options.file)}
            onPreview={(file) => {
              Modal.info({
                title: "Preview",
                content: (
                  <img
                    src={file.url || (file?.originFile && URL.createObjectURL(file.originFile))}
                    style={{
                      maxWidth: "100%",
                    }}
                  ></img>
                ),
              })
            }}
          >
            <>
              {fileList.length < fileLimit ? (
                <div className="upload-card-style">
                  <div className="w-36 h-36 flex justify-center items-center border border-dashed border-slate-500 rounded">
                    {loadingUpload ? (
                      <IconLoading className="ml-2 inline-block align-middle animate-spin" />
                    ) : (
                      <div className="mt-3">
                        <IconPlus></IconPlus>
                        <p className="text-gray-400 mt-2">{`${fileList.length}/${fileLimit}`}</p>
                      </div>
                    )}
                  </div>
                  <p className="text-gray-400 mt-2">{`只能上传jpg/png文件，且不超过2M，最多上传${fileLimit}张`}</p>
                </div>
              ) : (
                <span></span>
              )}
            </>
          </Upload>
        </Form.Item>
      </Form>
      <div className="text-right mt-3">
        <Button
          style={{ background: "#6663ff" }}
          loading={loading}
          onClick={async () => {
            if (formRef.current) {
              try {
                await formRef.current.validate()
                submitTicket()
              } catch (_) {
                console.log(formRef.current.getFieldsError())
                toastError("校验失败，请检查字段！")
              }
            }
          }}
          type="primary"
        >
          提交
        </Button>
      </div>
    </div>
  )
}
