import {
  V1BatchDeleteCollectionRequest,
  V1BatchDeleteCollectionResponse,
  V1CreateCollectionRequest,
  V1CreateTemplateRequest,
  V1GetCollectionResponse,
  V1ListAllCollectionResponse,
  V1ListCollectionsByUserIDResponse,
  V1ListMyCollectionResponse,
  V1ListMyTemplatesResponse,
  V1ReceiveDailyCheckInRewardResponse,
  V1ReceiveTemplateHeatTargetRewardResponse,
  V1ReceiveTemplateSubmissionCountTargetRewardResponse,
  V1ReceiveTemplateSubmissionRewardResponse,
  V1ReceiveUserGenerationCountTargetRewardResponse,
  V1UnLikeCollectionResponse,
  V1UpdateCollectionRequest,
  V1UpdateCollectionResponse,
} from "@/generated/api/data-contracts"
import { api } from "./api"
import { useContext } from "react"
import VisitStatusContext from "@/pages/Works/VisitStatusContext"
interface baseResponse {
  errorCode?: string
  errorMessage?: string
  host?: string
  showType?: number
  success?: boolean
  traceId?: string
  data?: any
}
interface V1ListMyCollectionRequest {
  /** likeCount:收藏数量｜likeWorkAt:更新时间｜workCount:作品数量 */
  sort: string
  /** current */
  current: number
  /** pageSize */
  pageSize: number
  /** work id */
  workID?: string
}

interface V1ListAllCollectionRes extends baseResponse {
  data?: V1ListAllCollectionResponse
}
interface V1ListMyCollectionRes extends baseResponse {
  data?: V1ListMyCollectionResponse
}
interface V1BatchDeleteCollectionRes extends baseResponse {
  data?: V1BatchDeleteCollectionResponse
}

interface V1CreateCollectionResponse extends baseResponse {
  data?: V1CreateCollectionResponse
}
interface V1UpdateCollectionRes extends baseResponse {
  data?: V1UpdateCollectionResponse
}
interface V1UnLikeCollectionRes extends baseResponse {
  data?: V1UnLikeCollectionResponse
}
interface GuestGetAllListRequest {
  current: number
  pageSize: number
  // "likeWorkAt|workCount|likeCount"
  sort: string
}
interface GetAlbumDetailsRequest {
  /** highest | newest | popular */
  sort: string
  /** current */
  current: number
  /** pageSize */
  pageSize: number
  /** collection uid */
  collectionUID: string
}
interface V1GetCollectionRes extends baseResponse {
  data?: V1GetCollectionResponse
}
interface V1ListCollectionsByUserIDRes extends baseResponse {
  data?: V1ListCollectionsByUserIDResponse
}
interface V1UnLikeCollectionRes extends baseResponse {
  data?: V1UnLikeCollectionResponse
}
// 创建默认相册
// export const getUserCollectionInfo = async (params: {}): Promise<UserCollectionInfoResp> => {
//   const res = await api.getUserCollectionInfo()
//   return res.data
// }

//  获取所有相册列表(作品中心相册包含收藏的相册)
export const getAllListCollection = async (q: {
  current: number
  pageSize: number
  sort: string
}): Promise<V1ListAllCollectionRes> => {
  const res = await api.listAllCollection({ q: JSON.stringify(q) })
  return res.data
}
// 查询当前用户创建的相册列表(相册详情下拉选择相册使用)
export const getListMyCollection = async (
  params: V1ListMyCollectionRequest
): Promise<V1ListMyCollectionRes> => {
  const res = await api.listMyCollection({ q: JSON.stringify(params) })
  return res.data
}
// 创建相册
export const creatAlbum = async (
  params: V1CreateCollectionRequest
): Promise<V1CreateCollectionResponse> => {
  const res = await api.createCollection(params, { format: "json" })
  return res?.data
}
// 更新相册
export const updateAlbum = async (
  params: V1UpdateCollectionRequest
): Promise<V1UpdateCollectionRes> => {
  const res = await api.updateCollection(params.collectionUID || "", params, { format: "json" })
  return res.data
}

//  批量删除相册
export const batchDeleteCollection = async (
  params: V1BatchDeleteCollectionRequest
): Promise<baseResponse> => {
  const res = await api.batchDeleteCollection(params, { format: "json" })
  return res.data
}
// 收藏相册
export const Albumlike = async (collectionUid: string): Promise<V1UnLikeCollectionRes> => {
  const res = await api.likeCollection(collectionUid, { format: "json" })
  return res.data
}

// 取消收藏
export const AlbumCancelLike = async (collectionUid: string): Promise<V1UnLikeCollectionRes> => {
  const res = await api.unlikeCollection(collectionUid, { format: "json" })
  return res.data
}
//  查询相册中work详情
export const getAlbumDetails = async (
  collectionUid: string,
  params: GetAlbumDetailsRequest
): Promise<V1GetCollectionRes> => {
  const res = await api.getCollection(
    collectionUid,
    { q: JSON.stringify(params) },
    { format: "json" }
  )
  return res.data
}

//  访客查询相册中work详情
export const guestGetAlbumDetails = async (
  collectionUid: string,
  targetUserId: string,
  params: GetAlbumDetailsRequest
): Promise<V1GetCollectionRes> => {
  const res = await api.getCollectionByUserId(
    collectionUid,
    targetUserId,
    { q: JSON.stringify(params) },
    { format: "json" }
  )
  return res.data
}

//  访客相关
export const guestGetAllList = async (
  targetUserId: string,
  params: GuestGetAllListRequest
): Promise<V1ListCollectionsByUserIDRes> => {
  const res = await api.listCollectionsByUserId(
    targetUserId,
    { q: JSON.stringify(params) },
    { format: "json" }
  )
  return res.data
}



