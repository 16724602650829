import { observer } from "mobx-react-lite"
import TemplateItem from "./TemplateItem"
import templateStore from "@/store/templateStore"
import { V1TemplateData } from "@/generated/api/data-contracts"
import { useEffect, useState } from "react"
import TemplateDetailModal from "@/common/TemplateDetailModal"
import { findModelPattern } from "@/utils"
import { isMobileOnly } from "react-device-detect"
import { toJS } from "mobx"

interface RecommendationListProps {
  onTemplateConfirm?: () => void
  model?: string
  modelPattern?: {
    portrait: boolean
    art: boolean
  }
}
const RecommendationList = observer(
  ({ onTemplateConfirm, model, modelPattern }: RecommendationListProps) => {
    const { templatesStudio, templateCategories } = templateStore.store.get()
    const [selectedTemplateIndex, setSelectedTemplateIndex] = useState<number | null>(null)

    useEffect(() => {
      if (isMobileOnly) {
        templateStore.fetchStudoRecommendTemplates()
      } else {
        if (model) {
          const newModel = findModelPattern(model, modelPattern)
          templateStore.fetchStudoRecommendTemplates({ model: newModel })
        }
      }
      if (!templateCategories.length) {
        templateStore.fetchTemplateCategories()
      }
    }, [model, modelPattern])

    const handleModalClose = () => {
      setSelectedTemplateIndex(null)
    }

    const templateModalVisiable = selectedTemplateIndex !== null

    return (
      <div className="grid grid-cols-2 gap-x-[5px] md:gap-x-0 md:flex flex-col gap-y-[5px] md:gap-y-[14px]">
        {templatesStudio.map((item: V1TemplateData, index) => {
          const background = item?.coverImage || item.exampleImages?.[0]
          return (
            <TemplateItem
              key={index}
              background={background || ""}
              title={item.name || ""}
              avatar={item.userAvatar || ""}
              nick={item.userNickName || ""}
              likes={item.heatValue || 0}
              liked={false}
              onClick={() => {
                setSelectedTemplateIndex(index)
              }}
            />
          )
        })}

        {templateModalVisiable && (
          <TemplateDetailModal
            onClose={handleModalClose}
            defaultTemplateIndex={selectedTemplateIndex}
            onConfirm={onTemplateConfirm}
            templatesCenter={templatesStudio}
          />
        )}
      </div>
    )
  }
)

export default RecommendationList
