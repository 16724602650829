import { ReactComponent as IconRepaint } from "@/assets/repaint.svg"
import ImageRepainting from "@/Studio/components/ImageRepainting"
import { V1Work } from "@/generated/api/data-contracts"
import { Tooltip } from "@arco-design/web-react"

interface ImageRepaintingProps {
  work: V1Work
}
const ImageRepaint = ({ work }: ImageRepaintingProps) => {
  return (
    <Tooltip content={"图像重绘"}>
      <div>
        {work.seed && work.seed !== -1 && (
          <ImageRepainting
            buttonContent={
              <IconRepaint
                className="hover:bg-[#FFFFFF1A] rounded-sm transition cursor-pointer "
                fill={"white"}
              ></IconRepaint>
            }
            work={work}
          />
        )}
      </div>
    </Tooltip>
  )
}

export default ImageRepaint
