import Navbar from "@/Navbar"
import {
  useComputed,
  useDarkMode,
  useMobxSearchParams,
  useWindowWidth,
  getColumnCount,
} from "@/utils"
import { ReactComponent as SortingIcon } from "@/assets/sorting.svg"
import { ReactComponent as MagicIcon } from "@/assets/magic.svg"
import { ReactComponent as HomeMenu } from "@/assets/home/menu.svg"
import { ReactComponent as HomeSortIcon } from "@/assets/home/sort.svg"
import { ReactComponent as ArrowRight } from "@/assets/home/arrow_right.svg"
import { ReactComponent as LoadingPuffIcon } from "@/assets/loading_puff.svg"
import { Link, useNavigate } from "react-router-dom"
import { Categories } from "./Categories"
import { useEffect, useMemo, useState } from "react"
import { observable, runInAction } from "mobx"
import { V1ListTasksResponse, V1Task, V1Work } from "@/generated/api/data-contracts"
import InfiniteScroll from "react-infinite-scroller"
import { Masonry } from "masonic"
import HomeCard from "@/pages/Works/HomeCard"
import { observer } from "mobx-react-lite"
import { DropDownSelect } from "@/common/DropDownSelect"
import Modal from "@/common/ImgDetailModal/Modal"
import * as store from "@/store"
import { getListAllTasks } from "@/services/home"
import { api } from "@/services/api"
import { toastError, toastSuccess } from "@/common/Toast"
import { ErrorCode } from "@/i18next"
import { useTranslation } from "react-i18next"
import { isMobileOnly } from "react-device-detect"
import menuStore from "@/store/menuStore"
import ActivityCarousel from "./ActivityCarousel"
import TemplateScroll from "@/common/TemplateScroll"
import { Search } from "./Search"
import templateStore from "@/store/templateStore"
import { getRecordNumberByDomain } from "@/constants"
// const homeVideoUrl = "https://cos-xica-prod.tiamat.world/static/home_video.mp4"

export const HomeV2 = observer(() => {
  const user = store.user.get()

  useDarkMode()
  const searchParams = useMobxSearchParams()
  const columnCount = getColumnCount(useWindowWidth(), true)
  const navigate = useNavigate()
  const { templateCategories, templatesCenter } = templateStore.store.get()

  // 最受欢迎 popular 最新 newest 最高分 highest
  const sort = useMemo(() => observable.box("popular"), [])
  const sortOptions = [
    { title: "最新", value: "newest" },
    // { title: "最高分", value: "highest" },
    { title: "最受欢迎", value: "popular" },
  ].reduce((pv, o) => {
    pv[o.value] = o
    return pv
  }, {} as Record<string, { title: string; value: string }>)
  const { t } = useTranslation(["translation", "errorMessage"])

  const category = useMemo(() => observable.box("OFmVJRyFuPIYrRkhhEgpE"), [])
  const isSearching = !!searchParams.get().get("q")
  const [pages, loadPage, uid, list, hasMore] = useComputed(() => {
    const list = observable.array([] as any)
    const query = searchParams.get().get("q") || "" // reload pages if query update
    const _category = category.get()
    const _sort = sort.get()
    const pages = observable.array([] as V1ListTasksResponse[])
    const hasMore = observable.box(true)
    const loadPage = async (page: number) => {
      const params = {
        current: page + 1,
        pageSize: 50,
        query,
        sort: _sort,
        category: _category,
      }
      try {
        const data = await getListAllTasks(params)
        // if (data.success) {
        const res = data as V1ListTasksResponse
        hasMore.set(
          ((res.current || 0) - 1) * (res.pageSize || 0) + (res.list?.length || 0) <
            (res.total || 0)
        )

        runInAction(() => {
          pages[page] = data || []
          // 组合成展开的list列表
          const recList: any = []
          pages?.map((page) => {
            page?.list?.map((item) => {
              recList.push(item)
            })
          })
          list[0] = recList
        })
      } catch (error) {
        //
      }
      // } else {
      //   switch (data.errorCode) {
      //     case "10003":
      //       toastError(t("errorMessage:10003"))
      //       break
      //     default:
      //       toastError(t("NetworkError"))
      //   }
      // }
      // data.success?
    }

    return [pages, loadPage, Date.now(), list, hasMore] as const
  }).get()

  const [modalVisiable, setModalVisiable] = useState<boolean>(false)
  const cardCount = pages?.reduce((r, i) => r + (i?.list?.length || 0), 0)

  // TODO use page?
  const [taskChangeDisable, setTaskChangeDisable] = useState<"prev" | "next" | "none">("none")
  const handleTaskChange = (direction: "prev" | "next") => {
    // 违法情况
    if (direction == "prev" && currentTaskIndex! <= 1) {
      setTaskChangeDisable("prev")
      if (currentTaskIndex == 0) return
    } else if (direction == "next" && currentTaskIndex! >= list[0]?.length - 2) {
      setTaskChangeDisable("next")
      if (currentTaskIndex == list[0]?.length - 1) return
    } else {
      setTaskChangeDisable("none")
    }
    const newIndex = currentTaskIndex! + (direction == "prev" ? -1 : 1)
    const result = list[0]?.[newIndex!]
    setCurrentTaskIndex(newIndex!)
    setCurrentTask(result)
  }
  const [currentTaskIndex, setCurrentTaskIndex] = useState<number>()
  const [currentTask, setCurrentTask] = useState<V1Task>()

  const getMasonryItems = () => {
    const masonryItems: {
      task: V1Task
      work: V1Work
    }[] = []

    pages.map((item) => {
      item?.list?.map((task) => {
        const work_1 = task.works?.[0]
        if (work_1) {
          masonryItems.push({
            task,
            work: work_1,
          })
        }
      })
    })
    return masonryItems
  }

  useEffect(() => {
    localStorage.setItem("userUID", user.id)
  }, [])

  useEffect(() => {
    return () => {
      store.clearHasChangedWorks()
    }
  }, [])

  useEffect(() => {
    // Fetch templates data
    if (!templatesCenter["recommand"]?.length) {
      templateStore?.fetchTemplatesCenter({
        params: { isRecommand: true },
        type: "recommand",
      })
    }

    // Fetch template categories data
    if (!templateCategories.length) {
      templateStore?.fetchTemplateCategories()
    }
  }, [])

  return (
    <section className="relative dark:text-[#fff]">
      <Navbar />
      <Link to="studio">
        <div className="w-full flex justify-center items-center ">
          <div className="flex md:text-base justify-center items-center shadow-md cursor-pointer hover:bg-[#5736df] active:bg-[#5e3ce8] px-7 py-3 md:py-4 fixed md:left-[50%] md:translate-x-[-50%] md:mt-8 mb-16 bg-[#4C1FFF] md:bg-[#5531e7] md:rounded-full rounded-[4px]  bottom-0 z-[99]">
            {/* 点击这里，立刻开始你的第一个图像创作 */}
            立刻开启你的图像创作
            <MagicIcon className="ml-2 w-6 h-6" />
          </div>
        </div>
      </Link>
      <div className={`mobile_content_container `}>
        {/* <section
          data-i="home-bg"
          className=" h-[450px] mt-0 absolute overflow-hidden md:mt-[60px] left-0 top-0 z-[-1] w-full md:h-[700px] after:absolute after:inset-0 after:bg-gradient-to-t after:from-[#161623] after:to-transparent"
        >
          <video
            autoPlay={isMobileOnly ? false : true}
            loop
            disablePictureInPicture={true}
            controls={false}
            muted={true}
            src={homeVideo}
            poster={homePoster}
            className="w-auto h-auto min-h-full min-w-full object-cover object-center"
          ></video> */}
        {/* <img src={homeURL } className="w-full"  /> */}
        {/* </section> */}
        <section
          data-i="home-container"
          className=" px-0 pt-0 mx-auto md:pt-[20px] sm:px-[50px] w-full max-w-[1920px]"
        >
          {/* Mobile head */}
          <section className="absolute px-5 pt-5 flex items-center md:hidden z-10">
            <HomeMenu
              className="w-5 h-5 mr-5"
              onClick={() => {
                menuStore.openMenu()
              }}
            />

            {/* <Search /> */}
          </section>
          <ActivityCarousel />
          {/* <LogoIcon className=" mt-[85px] mx-auto w-[256px] h-[48px] object-contain md:hidden" /> */}
          {/* <section
            data-i="home-top-title"
            className=" mt-1 text-xl sm:text-[30px] leading-[40px] md:text-[60px] sm:leading-[100px] text-white font-light text-center"
          >
            Spark Your Imagination
          </section> */}
          {/* <Search className={`${isMobileOnly ? "mt-[48px]" : "mt-[128px]"} mx-auto hidden md:flex`} /> */}

          <section>
            <div
              className="flex items-center"
              style={{
                justifyContent: isMobileOnly ? "space-between" : "",
              }}
            >
              <div className="flex font-sans font-normal min-w-max md:text-[32px] text-base leading-7 text-[#ffffff] ml-[20px] sm:ml-[0px] mt-5">
                <div>模版推荐</div>
              </div>
              {isMobileOnly ? (
                <div
                  onClick={() => {
                    navigate("./templateCenter")
                  }}
                  className="mr-[20px] sm:ml-[0px] mt-5 items-center font-open-sans text-[12px] text-white text-opacity-50 font-normal"
                >
                  查看更多
                </div>
              ) : (
                <div
                  onClick={() => {
                    navigate("./templateCenter")
                  }}
                  className="flex items-center mt-8 ml-5 cursor-pointer"
                >
                  <span>更多</span>
                  <ArrowRight />
                </div>
              )}
            </div>

            <TemplateScroll
              className="md:mt-[20px] mt-[10px] mb-[20px] ml-[20px] sm:ml-[0px]"
              templates={templatesCenter["recommand"]}
            />
          </section>

          <div className="bg-[#0C0C1A]">
            {/* Categories Section */}
            <div className="text-[#ffffff] font-sans font-normal text-[32px] hidden md:flex ">
              社区图片
            </div>
            <Categories category={category} className={`mt-[10px] mx-auto w-full`} />

            {/* Sorting Section */}
            <section data-i="sorting" className=" mt-[4px] md:mt-[20px] px-4 md:px-0">
              <DropDownSelect
                current={sort}
                options={sortOptions}
                icon={() => (
                  <>
                    <HomeSortIcon className="md:hidden w-3 h-3" />
                    <SortingIcon className="hidden md:block" />
                  </>
                )}
              ></DropDownSelect>
            </section>

            {/* Works Section */}
            <div className={`mt-[10px] md:mx-auto mx-5`}>
              <InfiniteScroll
                key={uid}
                pageStart={-1}
                loadMore={loadPage}
                hasMore={hasMore.get()}
                loader={
                  <LoadingPuffIcon
                    key={"loading"}
                    className="my-20 mx-auto"
                    stroke="currentColor"
                  ></LoadingPuffIcon>
                }
              >
                <Masonry
                  items={getMasonryItems()}
                  render={({ data: { task, work } }) => {
                    return (
                      <div
                        style={{ aspectRatio: task.ratio.replace(":", "/") }}
                        onClick={() => {
                          setCurrentTask(task)
                          const result = list[0].findIndex((item: any) => {
                            return item.id == task.id
                          })
                          if (result == 0) {
                            setTaskChangeDisable("prev")
                          } else if (result == list[0].length - 1) {
                            setTaskChangeDisable("next")
                          } else {
                            setTaskChangeDisable("none")
                          }
                          setCurrentTaskIndex(result)
                          setModalVisiable(true)
                        }}
                      >
                        <HomeCard task={task} work={work} showRegen refJump showOption />
                      </div>
                    )
                  }}
                  columnCount={columnCount}
                  columnGutter={20}
                />
                {cardCount && !hasMore.get() ? (
                  <section className="mt-[22px] flex justify-center items-center">
                    <span className="inline-flex items-center text-[#6F7176] text-sm before:mr-[26px] before:bg-[#C9CBD14D]/30 before:w-[138px] before:h-[1px] after:ml-[26px] after:bg-[#C9CBD14D]/30 after:w-[138px] after:h-[1px]">
                      没有更多了
                    </span>
                  </section>
                ) : null}
              </InfiniteScroll>

              {!cardCount && (
                <section className="mt-20 flex flex-col items-center">
                  <p className="my-2.5 text-center text-sm">
                    {isSearching ? "暂未搜索到内容" : "暂无更多作品"}
                  </p>
                </section>
              )}
              <div className="w-full flex justify-center text-xs mt-10 mb-2 items-center cursor-pointer">
                <a href="https://beian.miit.gov.cn/#/Integrated/index">
                  {getRecordNumberByDomain()}
                </a>
                <a
                  className="flex  justify-center items-center"
                  target="_blank"
                  href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502020083"
                >
                  <img className="pl-2 " src="/assets/beian.png" />
                  <p className="pl-2 ">沪公网安备 31011502020083号</p>
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>

      {currentTask?.works && (
        <Modal
          open={modalVisiable}
          onClose={() => setModalVisiable(false)}
          task={currentTask}
          work={currentTask.works[0]}
          handleTaskChange={handleTaskChange}
          taskChangeDisable={taskChangeDisable}
          fromHomePage={true}
        />
      )}
    </section>
  )
})
