import WorkCover from "@/components/bussiness/work/WorkCover"
import LayoutGeneral from "@/components/layout/general"
import { MOBILE_CONTAINER_CLS } from "@/store/menuStore"
import { useOutlet, useParams } from "react-router-dom"
import VisitStatusContext from "./VisitStatusContext"
import { isMobileOnly } from "react-device-detect"
import TempMobile from "./TempMobile"

const WorksPageComponent = () => {
  const outlet = useOutlet()
  const { userId } = useParams()
  const isVisted = Boolean(userId)

  return (
    <VisitStatusContext.Provider
      value={{
        isVisted,
        userId: userId as string,
      }}
    >
      <div>
        {/* Works Center Header */}
        <WorkCover />

        {/* Route outlet */}
        <div className="px-3 md:px-10 lg:px-[70px]">
          <div>{outlet}</div>
        </div>
      </div>
    </VisitStatusContext.Provider>
  )
}

const WorksPage = () => {
  // If it's mobile, show the temporary mobile page
  if (isMobileOnly) {
    return <TempMobile tip="移动端作品中心升级中" />
  }

  return (
    <LayoutGeneral hasHeader={true} className={MOBILE_CONTAINER_CLS}>
      <WorksPageComponent />
    </LayoutGeneral>
  )
}
export default WorksPage
