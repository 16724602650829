import arrowDownIcon from "../../../../../../assets/works/arrow_down.svg"
import { Popover } from "@arco-design/web-react"
import albumStore from "@/store/albumStore"
import { useRef, useState } from "react"
import { observer } from "mobx-react-lite"

interface AlbumSelectProps {
  onChange: (albumId: string) => void
  selectedAlbumId: string
  onPopoverVisibleChange?: (visible: boolean) => void
}
const AlbumLabel = observer(
  ({ onChange, selectedAlbumId, onPopoverVisibleChange }: AlbumSelectProps) => {
    const [popoverVisible, setPopoverVisible] = useState(false)
    const popoverContainer = useRef<HTMLDivElement>(null)

    const albums = albumStore.myAlbums || []
    const selectedAlbum = albums.find((item) => item.uid === selectedAlbumId)
    const selectedAlbumTitle = selectedAlbum?.title || ""

    const updatePopoverVisible = (visible: boolean) => {
      setPopoverVisible(visible)
      onPopoverVisibleChange?.(visible)
    }

    return (
      <Popover
        getPopupContainer={() => popoverContainer.current as HTMLElement}
        unmountOnExit
        position="br"
        trigger={"click"}
        popupVisible={popoverVisible}
        onVisibleChange={(visible) => {
          updatePopoverVisible(visible)
        }}
        color={"#212134"}
        className="work-popover-wrapper"
        content={
          <ul className="p-[5px] bg-[#212134]">
            {albums.map((item) => {
              return (
                <li
                  key={item.uid}
                  className="cursor-pointer  text-white px-[10px] py-[5px] bg-transparent hover:bg-[#272739]"
                  onClick={() => {
                    const uid = item.uid
                    if (uid) {
                      // setSelectedAlbumId(uid)
                      onChange?.(uid)
                    }
                    updatePopoverVisible(false)
                  }}
                >
                  {item.title}
                </li>
              )
            })}
          </ul>
        }
      >
        <div className="pl-2.5 flex items-center cursor-pointer" ref={popoverContainer}>
          <div className="w-10 truncate">{selectedAlbumTitle}</div>
          <img className="w-7 object-contain" src={arrowDownIcon} alt="" />
        </div>
      </Popover>
    )
  }
)

export default AlbumLabel
