import { V1Task, V1Work } from "@/generated/api/data-contracts"
import { createContext, useContext, useReducer, useState } from "react"

const TasksContext = createContext<V1Task[]>([])
const WotksDispatchContext = createContext<React.Dispatch<any>>(() => {})
const ManagementContext = createContext<{
  isManaging: boolean
  setIsManaging: React.Dispatch<React.SetStateAction<boolean>>
  selectedWorkIds: string[]
  selectedWorkIdsDispatch: React.Dispatch<any>
}>({
  isManaging: false,
  setIsManaging: () => {},
  selectedWorkIds: [],
  selectedWorkIdsDispatch: () => {},
})

const filterTasksWithWorks = (tasks: V1Task[]) => {
  return tasks.filter((task) => {
    return task.works?.length
  })
}

// Works Reducer
function worksReducer(state: V1Task[], action: any) {
  switch (action.type) {
    case "ADD_TASKS":
      const tasksToAdd = filterTasksWithWorks(action.payload)
      return [...state, ...tasksToAdd]
    case "REMOVE_TASK":
      return state.filter((task) => task.id !== action.payload)
    case "UPDATE_TASK":
      const updatedTasks = state.map((task) =>
        task.id === action.payload.id ? action.payload : task
      )
      return filterTasksWithWorks(updatedTasks)
    case "UPDATE_WORK":
      const workId = action.payload.id
      return state.map((task) => {
        if (!task.works) return task

        const workIndex = task.works.findIndex((work) => work.id === workId)
        if (workIndex === -1) return task

        const updatedWorks = [...task.works]
        updatedWorks[workIndex] = action.payload
        return {
          ...task,
          works: updatedWorks,
        }
      })
    case "UPDATE_WORKS":
      const works = action.payload
      return state.map((task) => {
        if (!task.works) return task

        const updatedWorks = task.works.map((work) => {
          const updatedWork = works.find((w: V1Work) => w.id === work.id)
          return updatedWork ?? work
        })
        return {
          ...task,
          works: updatedWorks,
        }
      })
    case "REMOVE_WORKS":
      const workIds = action.payload
      const tasksWithoutRemovedWorks = state.map((task) => {
        if (!task.works) return task

        const updatedWorks = task.works.filter((work) => !workIds.includes(work.id ?? ""))
        return {
          ...task,
          works: updatedWorks,
        }
      })
      return filterTasksWithWorks(tasksWithoutRemovedWorks)
    case "RESET":
      return []
  }
  throw new Error(`Unhandled action type: ${action.type}`)
}
const initialState: V1Task[] = []

// Selected Work Ids Reducer
function selectedWorkIdsReducer(state: string[], action: any) {
  switch (action.type) {
    case "ADD":
      return [...state, action.payload]
    case "REMOVE":
      return state.filter((id) => id !== action.payload)
    case "RESET":
      return []
    case "SET":
      return action.payload
  }
  throw new Error(`Unhandled action type: ${action.type}`)
}
const initialSelectedWorkIdsState: string[] = []

interface WorksContextProps {
  children: React.ReactNode
}
export const WorksProvider = ({ children }: WorksContextProps) => {
  const [tasks, dispatch] = useReducer(worksReducer, initialState)
  const [isManaging, setIsManaging] = useState(false)
  const [selectedWorkIds, selectedWorkIdsDispatch] = useReducer(
    selectedWorkIdsReducer,
    initialSelectedWorkIdsState
  )

  return (
    <TasksContext.Provider value={tasks}>
      <WotksDispatchContext.Provider value={dispatch}>
        <ManagementContext.Provider
          value={{ isManaging, setIsManaging, selectedWorkIds, selectedWorkIdsDispatch }}
        >
          {children}
        </ManagementContext.Provider>
      </WotksDispatchContext.Provider>
    </TasksContext.Provider>
  )
}

export function useTasksState() {
  return useContext(TasksContext)
}

export function useTasksDispatch() {
  return useContext(WotksDispatchContext)
}

export function useTasksManagement() {
  return useContext(ManagementContext)
}
