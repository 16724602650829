import bg from "@/assets/login_bg.jpg"
import { ReactComponent as LogoIcon } from "@/assets/logo.svg"
import { ReactComponent as BackIcon } from "@/assets/login_back.svg"
import SuccessURL from "@/assets/subscription_pay_success.png"

import { useTranslation } from "react-i18next"
import { Link, useLocation } from "react-router-dom"
import { ChangeEvent, useState } from "react"
import { V1ApplyInviteCodeRequest } from "@/generated/api/data-contracts"
import { applyInviteCode } from "@/services/login"
import { LogicError } from "@/types"
import { ErrorCode } from "@/i18next"
import { getRecordNumberByDomain } from "@/constants"

const LoginApplay = () => {
  const { t } = useTranslation(["translation", "errorMessage"])

  return (
    <div className="fixed inset-0">
      <div
        className="absolute inset-0 bg-blend-multiply bg-cover bg-no-repeat -z-10"
        style={{
          background: `url(${bg}) rgba(0,0,0,0.5) center center`,
          backgroundSize: "cover",
        }}
      ></div>
      <div className="max-w-7xl mx-auto overflow-y-auto  h-full md:px-8 md:flex md:flex-row flex-col items-center justify-center">
        <div className="md:flex-1 ml-2 mr-4 md:mt-0 mt-20 md:mb-0 mb-10">
          <LogoIcon width={220} height={42} />
          <h2 className="mt-7 text-[18px] font-semibold text-white flex items-center">
            <span>Spark Your Imagination</span>
          </h2>
        </div>
        <div className="flex flex-col items-center relative flex-1 max-w-[400px] md:pt-[69px] pt-8 md:pb-[6px] pb-8 bg-white rounded-lg px-4 md:px-0 ">
          <Link to={"/login"} replace>
            <div className="absolute flex justify-center items-center md:left-[14px] left-2 md:top-[14px] top-8 h-[44px] w-[44px] hover:cursor-pointer hover:bg-[rgb(201,203,209)]/30 rounded-[4px]">
              <BackIcon />
            </div>
          </Link>
          <div className="flex justify-center text-[#101010] text-[26px] md:mb-[42px] mb-3">
            {t("LogonTitle")}
          </div>
          <ApplyInviteCodeComponent onSuccess={() => {}} />
        </div>
        {/* <Password onClickToggle={() => {}} /> */}
      </div>
      <div className="absolute bottom-0 w-full flex justify-center text-xs text-white mt-10 mb-2 items-center cursor-pointer">
        <a href="https://beian.miit.gov.cn/#/Integrated/index">{getRecordNumberByDomain()}</a>
        <a
          className="flex  justify-center items-center"
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502020083"
        >
          <img className="pl-2 " src="/assets/beian.png" />
          <p className="pl-2 ">沪公网安备 31011502020083号</p>
        </a>
      </div>
    </div>
  )
}

const ApplyInviteCodeComponent = ({ onSuccess }: { onSuccess: (tip: string) => void }) => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  const { t } = useTranslation(["translation", "errorMessage"])
  const [applySuccess, setApplySuccess] = useState(false)
  const [formData, setFormData] = useState<V1ApplyInviteCodeRequest>(() => ({
    email: "",
    job: "",
    scene: "",
    socialLink: "",
    source: params.get("source") || "",
  }))

  const [errorMap, setErrorMap] = useState({
    email: "",
    job: "",
    scene: "",
    socialLink: "",
    source: "",
  })

  const inputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const key = e.target.name
    const value = e.target.value
    setFormData({
      ...formData,
      [key]: value,
    })
  }
  const checkValue = () => {
    const newErrorMap = { ...errorMap }
    let hasErr = false
    if (
      !/^[.\-_A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(?:\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/.test(
        formData.email
      )
    ) {
      newErrorMap.email = "请确认邮箱格式"
      hasErr = true
    }
    if (!formData.job) {
      newErrorMap.job = "请写点什么吧～"
      hasErr = true
    }
    if (!formData.scene) {
      newErrorMap.scene = "请写点什么吧～"
      hasErr = true
    }
    setErrorMap(newErrorMap)
    return !hasErr
  }

  const submit = async () => {
    if (!checkValue()) {
      return
    }

    try {
      await applyInviteCode(formData)
      onSuccess(
        `非常感谢您的提交，我们会尽快通过邮箱 ${formData.email} 和您进行联系，Tiamat 魔法世界的入场券已经在路上啦～`
      )
      setApplySuccess(true)
    } catch (e) {
      if (e instanceof LogicError) {
        switch (e.code) {
          case "11009":
            setErrorMap({
              ...errorMap,
              email: t("errorMessage:11009"),
            })
            break
          default:
            setErrorMap({
              ...errorMap,
              email: t(`errorMessage:${e.code as ErrorCode}`) || t("NetworkError"),
            })
        }
      }
    }
  }

  const formItem: {
    title: string
    key: keyof V1ApplyInviteCodeRequest
    required: boolean
    placeholder: string
    tip: string
  }[] = [
    {
      title: "联系邮箱",
      key: "email",
      required: true,
      placeholder: "请输入您的邮箱地址",
      tip: "当您的申请通过时，我们会发送专属邀请码",
    },
    {
      title: "您的职业方向",
      key: "job",
      required: true,
      placeholder: "请说明",
      tip: "让我们更好的了解您！",
    },
    {
      title: "预期使用场景",
      key: "scene",
      required: true,
      placeholder: "请大致说明",
      tip: "希望用Tiamat完成什么创作呢～",
    },
    {
      title: "社交网络链接",
      key: "socialLink",
      required: false,
      placeholder: "请输入链接",
      tip: "填写此项会大大增加获取概率！",
    },
  ]

  return (
    <>
      {applySuccess ? (
        <>
          <img className="w-40" src={SuccessURL} alt="" />
          <h1 className="text-[#6222F5] text-2xl font-semibold">邀请码申请成功</h1>
          <p className="md:px-16 text-sm font-medium text-center">{`非常感谢您的提交，我们会尽快通过邮箱 ${formData.email} 和您进行联系，Tiamat 魔法世界的入场券已经在路上啦～`}</p>
          <Link to={"/"}>
            <button className="text-white mt-[42px] mb-4 block md:w-80 w-full px-8 py-2.5 text-base bg-gradient-to-r from-[#734AFF] to-[#5C16F1] rounded disabled:opacity-50 active:brightness-110">
              返回首页看看
            </button>
          </Link>
        </>
      ) : (
        <>
          <div className="text-[#101010] text-[16px]">获取邀请码</div>
          {formItem.map((item) => (
            <div className="w-full md:pl-10" key={item.key}>
              <div className="self-starttext-3 text-sm ">
                <span
                  className={`${item.required ? "text-[#E93247]" : "text-[#FFFFFF]"}  select-none`}
                >
                  *
                </span>
                {item.title}
              </div>
              <label className="h-11 self-start w-full md:w-80 mt-1.5 px-3 flex items-center rounded border  focus-within:border-[#724AFF]">
                <input
                  className="ml-2 flex-1 py-2 text-sm text-black outline-none bg-transparent placeholder:text-[#C9CBD1]"
                  placeholder={item.placeholder}
                  value={formData[item.key]}
                  name={item.key}
                  onChange={inputChange}
                />
              </label>
              <div
                className={`self-start text-3 mt-1.5 text-xs mb-1 ${
                  errorMap[item.key] ? "text-[#E93247]" : "text-[#A8ABB2]"
                } `}
              >
                {errorMap[item.key] || item.tip}
              </div>
            </div>
          ))}

          <button
            className="text-white mt-[42px] mb-4 block md:w-80 w-full px-8 py-2.5 text-base bg-gradient-to-r from-[#734AFF] to-[#5C16F1] rounded disabled:opacity-50 active:brightness-110"
            onClick={submit}
          >
            立即申请
          </button>
        </>
      )}
    </>
  )
}

export default LoginApplay
