import { Trigger } from "@arco-design/web-react"
import { IObservableValue } from "mobx"
import { useState } from "react"

export function DropDownSelect(props: DropDownProps) {
  const [visible, setVisible] = useState(false)
  return (
    <Trigger
      popupVisible={visible}
      position="bl"
      popupAlign={{ bottom: 4 }}
      popup={() => (
        <section className="flex flex-col gap-[5px] p-[5px] bg-[#212134] rounded">
          {Object.keys(props.options).map((k) => {
            const option = props.options[k]
            return (
              <button
                key={option.value}
                onClick={() => {
                  props.current.set(option.value)
                  setVisible(false)
                }}
                className="block px-[10px] py-[5px] text-sm leading-[20px] text-left rounded hover:bg-white/10"
              >
                {option.title}
              </button>
            )
          })}
        </section>
      )}
      trigger="click"
      onVisibleChange={setVisible}
    >
      <button
        onClick={() => setVisible(true)}
        className="flex items-center gap-[6px] pl-1 md:pl-[16px] pr-[10px] py-[8px] bg-transparent md:bg-[#4C1FFF] md:hover:bg-[#6663FF] rounded"
      >
        {props.icon ? <props.icon /> : null}
        <span className="leading-[20px] text-sm">{props.options[props.current.get()].title}</span>
      </button>
    </Trigger>
  )
}
export interface DropDownProps {
  icon?: () => JSX.Element
  current: IObservableValue<string>
  options: Record<
    string,
    {
      title: string
      value: string
    }
  >
}
