import Editor from "./Editor"
import { ScrollToTopOnMount } from "@/common/ScrollToTopOnMount"
import { useDarkMode } from "@/utils"
import { useEffect } from "react"
import { useTourHooks } from "@/components/tour"
export default function StudioPage() {
  const { openTour } = useTourHooks({ key: "tour-studio" })
  useDarkMode()
  useEffect(() => {
    openTour()
  }, [])
  return (
    <>
      <ScrollToTopOnMount />
      <Editor />
    </>
  )
}
