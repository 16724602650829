import React, { useEffect, useState } from "react"
import { Table, TableColumnProps } from "@arco-design/web-react"
import "../../AwardCollectionRecord/award.less"
import { api } from "@/services/api"
import { toastError, toastSuccess } from "@/common/Toast"
import { LogicError } from "@/types"
import { useTranslation } from "react-i18next"
import type { V1Ticket } from "@/generated/api/data-contracts"
import dayjs from "dayjs"
import "./table.less"
import { CategoryThemeMap, StatusMap } from "../constants"

const columns: TableColumnProps[] = [
  {
    title: "反馈时间",
    dataIndex: "createdAt",
    align: "center",
    render: (col, record: V1Ticket) =>
      record?.createdAt && dayjs(Number(record.createdAt) * 1000).format("YYYY-MM-DD HH:mm:ss"),
  },
  {
    title: "反馈类别",
    dataIndex: "salary",
    align: "center",
    render: (col, record: V1Ticket) => {
      if (record.category) {
        const target = CategoryThemeMap[record.category]
        if (target) {
          const { name, themes } = target
          const theme = Array.isArray(themes) && themes.find((item) => item.value === record.theme)
          return `${name ?? ""} - ${theme ? theme?.label : record.theme}`
        } else {
          return record.category
        }
      }

      return "error"
    },
  },
  {
    title: "反馈状态",
    align: "center",
    dataIndex: "status",
    render(col, item: V1Ticket) {
      return item.status ? StatusMap[item?.status] ?? "" : ""
    },
  },
]
interface Props {
  activeTab: string
}
export default function FeedbackTable({ activeTab }: Props) {
  const { t } = useTranslation(["translation", "errorMessage"])

  const [tableData, setTableData] = useState<V1Ticket[]>([])

  const [pagination, setPagination] = useState({
    total: 0,
    pageSize: 10,
    current: 1,
    pageSizeChangeResetCurrent: true,
  })
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (activeTab === "log") {
      getTableData({ current: 1, pageSize: 10 })
    }
  }, [activeTab])

  async function getTableData({
    current = 1,
    pageSize = 1,
  }: {
    current: number | undefined
    pageSize: number | undefined
  }) {
    try {
      const params = {
        current: current,
        pageSize: pageSize,
      }
      const { data } = await api.listTickets(params)
      if (data.success) {
        setTableData(data.data?.list ?? [])
        setPagination((pagination) => ({
          ...pagination,
          total: data.data?.total ?? 0,
          current,
          pageSize,
        }))
      } else {
        toastSuccess("提交失败，请稍后再试")
      }
    } catch (error) {
      if (error instanceof LogicError) {
        toastError(t(`errorMessage:${error.code}` as any))
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="award-wrapper feedback-table-wrapper">
      <Table
        columns={columns}
        loading={loading}
        onChange={({ current, pageSize }) => getTableData({ current, pageSize })}
        pagePosition="bottomCenter"
        pagination={pagination.total <= 10 ? false : pagination}
        data={tableData}
        border={false}
        className="award-table-col"
      />
    </div>
  )
}
