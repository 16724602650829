import "./animation.less"
const Animation = ({
  animationStop = false,
  isMobileOnly = false,
}: {
  animationStop?: boolean
  isMobileOnly?: boolean
}) => {
  return (
    <div className="w-full absolute bottom-0 z-10">
      {animationStop ? (
        <img
          className={`absolute bottom-0 left-0 w-full`}
          src="assets/dajiaInsurance/animation-stop.png"
        />
      ) : (
        <>
          <ul className="banner_con">
            <li>
              <img src="assets/dajiaInsurance/animation-left.png" />
            </li>
            <li>
              <img src="assets/dajiaInsurance/animation-left.png" />
            </li>
            <li>
              <img src="assets/dajiaInsurance/animation-left.png" />
            </li>
            <li>
              <img src="assets/dajiaInsurance/animation-left.png" />
            </li>
            <li>
              <img src="assets/dajiaInsurance/animation-left.png" />
            </li>
            <li>
              <img src="assets/dajiaInsurance/animation-left.png" />
            </li>
          </ul>

          <ul className="banner_con2">
            <li>
              <img src="assets/dajiaInsurance/animation-right.png" />
            </li>
            <li>
              <img src="assets/dajiaInsurance/animation-right.png" />
            </li>
            <li>
              <img src="assets/dajiaInsurance/animation-right.png" />
            </li>
            <li>
              <img src="assets/dajiaInsurance/animation-right.png" />
            </li>
            <li>
              <img src="assets/dajiaInsurance/animation-right.png" />
            </li>
            <li>
              <img src="assets/dajiaInsurance/animation-right.png" />
            </li>
          </ul>
          {/* <Carousel
            style={{
              objectFit: "cover",
              width: "100vw",
              zIndex: "5",
              position: "absolute",
              bottom: "0",
              left: "0",
              rotate: "180deg"
            }}
            autoPlay={{
              interval: 1,
              hoverToPause: false,
            }}
            miniRender={false}
            moveSpeed={10000}
            animation="slide"
            timingFunc={"linear"}
            indicatorType="never"
            showArrow="never"
          >
            <img
              className={`absolute bottom-0 wave-left`}
              src="assets/dajiaInsurance/animation-left-1.png"
            />
            <img
              className={`absolute bottom-0  left-full wave-left`}
              src="assets/dajiaInsurance/animation-left-1.png"
            />
          </Carousel> */}
          {/* <img
            className={`absolute  bg-green-300 bottom-0 wave-left`}
            src="assets/dajiaInsurance/animation-left.png"
          />
          <img
            className={`absolute bg-blue-200 bottom-0  left-full wave-left`}
            src="assets/dajiaInsurance/animation-left.png"
          />
          <img
            className={`absolute bg-green-300 bottom-0 opacity-80 right-full wave-right`}
            src="assets/dajiaInsurance/animation-right.png"
          />
          <img
            className={`absolute bg-blue-200 bottom-0 opacity-80 wave-right`}
            src="assets/dajiaInsurance/animation-right.png"
          /> */}
          {/* <Carousel
            style={{ objectFit: "cover", width: "100vw", zIndex: "5" }}
            autoPlay={{
              interval: 1,
              hoverToPause: false,
            }}
            miniRender={false}
            moveSpeed={10000}
            animation="slide"
            timingFunc={"linear"}
            indicatorType="never"
            showArrow="never"
          >
            <img
              className={`absolute bottom-0 opacity-80 right-full wave-right`}
              src="assets/dajiaInsurance/animation-right.png"
            />
            <img
              className={`absolute bottom-0 opacity-80 wave-right`}
              src="assets/dajiaInsurance/animation-right.png"
            />
          </Carousel> */}
        </>
      )}
    </div>
  )
}

export default Animation
