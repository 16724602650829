import { V1ListMyTasksResponse } from "@/generated/api/data-contracts"
import { api } from "./api"

export const getMyWorks = async (query: {
  current?: number
  pageSize?: number
  query?: string
  sort?: string
}): Promise<V1ListMyTasksResponse | undefined> => {
  const res = await api.listMyTasks(query, { format: "json" })
  return res.data.data
}
