/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ResponseResponseWrapper,
  V2CreateTaskRequest,
  V2CreateTaskResponse,
} from "./data-contracts"
import { ContentType, HttpClient, RequestParams } from "./http-client"

export class V2<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description 免费用户:创建任务优先级使用 high;  付费用户，创建任务优先级可以选择 high | low; 可以根据 userInfo.accounts 判断用户的权益余额。
   *
   * @tags task
   * @name V2TaskCreate
   * @summary createTask
   * @request POST:/v2/task
   */
  v2TaskCreate = (body: V2CreateTaskRequest, params: RequestParams = {}) =>
    this.request<
      ResponseResponseWrapper & {
        data?: V2CreateTaskResponse
      },
      any
    >({
      path: `/v2/task`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    })
}
