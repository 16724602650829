import { useState } from "react"
import { QRCodeSVG } from "qrcode.react"

import pwdPng from "@/assets/login_pwd.png"
export interface QrcodeProps {
  onClickToggle?: () => void
}

export const Qrcode: React.FC<QrcodeProps> = (props) => {
  // 邀请码
  const [invite, setInvite] = useState("")
  const [inviteErr, setInviteErr] = useState("")
  //
  const [loading] = useState(false)
  //
  const handleClickLogin = () => {}
  const handleChangeInvite = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (value === "") {
      setInviteErr("请输入正确邀请码")
    } else {
      setInviteErr("")
    }
    setInvite(value)
  }
  return (
    <div className="relative flex-1 max-w-[25rem] pt-[94px] pb-[58px] pr-10 pl-10 bg-white  rounded-lg corner">
      <div className="absolute corner_icon top-2 right-2" onClick={props.onClickToggle}>
        <img src={pwdPng} />
      </div>
      <div className="flex justify-center text-[#101010] text-[26px]">登录</div>
      <div className="flex justify-center text-[#101010] text-[14px] mt-2 mb-4">
        扫码后点击“关注”即可登录
      </div>
      <div className="flex justify-center align-center">
        <QRCodeSVG size={150} value="https://reactjs.org/" />,
      </div>
      <div>输入邀请码</div>
      <label className="h-11 w-80 mt-4 px-3 flex items-center rounded border  focus-within:border-[#724AFF]">
        <input
          className="flex-1 py-2 text-sm text-black outline-none bg-transparent placeholder:text-[#C9CBD1]"
          placeholder="请输入邀请码"
          value={invite}
          onChange={handleChangeInvite}
        ></input>
      </label>
      {inviteErr ? <div className="text-3 mt-[6px] text-sm text-[#E93247]">{inviteErr}</div> : null}
      <button
        className="text-white mt-6 mb-2 block w-full px-8 py-2.5 bg-gradient-to-r from-[#734AFF] to-[#5C16F1] rounded disabled:opacity-50 active:brightness-110"
        disabled={loading || inviteErr !== "" || invite === ""}
        onClick={handleClickLogin}
      >
        {loading ? "登录中" : "开始创作"}
      </button>
    </div>
  )
}
