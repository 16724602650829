import { JumpToWeChat, useDarkMode } from "@/utils"
import qrCode from "@/assets/works/mp_qrcode_from_web.jpeg"

interface TempMobileProps {
  tip?: string
  className?: string
}
const TempMobile = ({ tip, className }: TempMobileProps) => {
  useDarkMode()

  const tipText = tip || "移动端功能升级中"

  return (
    <div
      className={
        className
          ? className
          : "h-screen w-screen flex flex-col items-center justify-center gap-y-8"
      }
    >
      <div className="flex flex-col items-center gap-y-1">
        <div className="text-lg font-sans">{tipText}</div>
        <div className="text-lg font-sans">请用电脑或微信小程序打开</div>
      </div>
      <div>
        <img src={qrCode} alt="" className="w-[180px] h-[180px] rounded" />
      </div>
      <div
        onClick={() => {
          JumpToWeChat()
        }}
        className="h-9 min-w-fit px-[14px] bg-white bg-opacity-5 flex items-center justify-center cursor-pointer hover:bg-opacity-10 rounded-r"
      >
        打开小程序
      </div>
    </div>
  )
}

export default TempMobile
