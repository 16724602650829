import { useState } from "react"

import { PasswordQC } from "@/components/bussiness/Login/password-qc"
import { Qrcode } from "@/components/bussiness/Login/qrcode"
import bg from "@/assets/login_bg.jpg"
import { ReactComponent as LogoIcon } from "@/assets/logo.svg"
import { ReactComponent as LogoQcIcon } from "@/assets/device-loginQC.svg"

import { Link } from "react-router-dom"
import deviceurl from "@/assets/device-loginQC.png"
import { getRecordNumberByDomain } from "@/constants"
const LoginPage = () => {
  const [isLoginSuccess, setIsloginSuccess] = useState(false)

  const handleLoginSuccess = () => {
    setIsloginSuccess(true)
  }
  return (
    <div className="fixed inset-0">
      <div
        className="absolute inset-0 bg-blend-multiply bg-cover bg-no-repeat -z-10"
        style={{
          background: `url(${bg}) rgba(0,0,0,0.5) center center`,
          backgroundSize: "cover",
        }}
      ></div>
      <div className="max-w-7xl mx-auto overflow-y-auto  h-full md:px-8 md:flex md:flex-row flex-col items-center justify-center">
        {isLoginSuccess ? (
          <div className="flex w-full relative mt-4 mb-10">
            <div className="z-10 mt-6  w-1/2 text-white flex flex-col items-center justify-center text-center">
              <LogoIcon width={130} />
              <div className="text-lg" style={{ fontWeight: "600" }}>
                释放你的想象力
              </div>
              <div className="h-2"></div>
              <div style={{ fontWeight: "600" }}>更多功能前往tiamat.com</div>

              <Link
                to="/"
                className="text-white text-center mt-[4px] mb-4 block  px-2 py-1 text-base bg-gradient-to-r from-[#734AFF] to-[#5C16F1] rounded disabled:opacity-50 active:brightness-110"
              >
                前往官网
              </Link>
            </div>
            <div className="z-10 mt-6  w-1/2">
              <img src={deviceurl} />
            </div>
            <LogoQcIcon className="absolute top-0 left-0 w-full -z-0" />
          </div>
        ) : (
          <div className="md:flex-1 ml-2 mr-4 md:mt-0 mt-20 md:mb-0 mb-10">
            <LogoIcon width={220} height={42} />

            <h2 className="mt-7 text-[18px] font-semibold text-white flex items-center">
              <span>Spark Your Imagination</span>
            </h2>
          </div>
        )}
        {/* 登录框 */}
        <PasswordQC handleLoginSuccess={handleLoginSuccess} />
      </div>
      <div className="absolute bottom-0 w-full flex justify-center text-xs text-white mt-10 mb-2 items-center cursor-pointer">
        <a href="https://beian.miit.gov.cn/#/Integrated/index">{getRecordNumberByDomain()}</a>
      </div>
    </div>
  )
}
export default LoginPage
