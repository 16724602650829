import { api } from "@/services/api"
import { IObservableValue } from "mobx"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import pingmianChahuaURL from "@/assets/models/pingmian-chahua.jpg"
import { ReactComponent as ArrowLeftDarkIcon } from "@/assets/arrow_left_dark.svg"
import { V1Category } from "@/generated/api/data-contracts"
import * as store from "@/store"
import "./categories.less"
import { getImageJpg } from "@/utils"

type CategoryItemProps = {
  category: V1Category
  categoryState: IObservableValue<string>
  categoryWidth?: number
  className?: string
}
const CategoryItem = ({
  category: c,
  categoryState,
  categoryWidth = 150,
  className,
}: CategoryItemProps) => {
  return (
    <section
      onClick={() => {
        store.clearHasChangedWorks()
        categoryState.set(c.uid || "")
      }}
      className={` group flex-shrink-0 relative w-[${categoryWidth}px] h-[56px] rounded-[4px] overflow-hidden ${
        className || ""
      }`}
    >
      <img
        className="w-full h-full object-cover group-hover:scale-[1.2] transition-transform transform-gpuansform duration-700"
        src={c?.id === "all" ? c?.cover : getImageJpg(c?.cover)}
      />
      <div
        className={`absolute inset-0 flex justify-center items-center text-[14px] text-[#ffffff] cursor-pointer ${
          categoryState.get() === c.uid ? "bg-[#4C1FFF]/80" : "bg-[#000000]/70"
        }`}
      >
        <div className="relative w-full h-full top-0 group-hover:-top-[100%] transition-all duration-500">
          <div className="w-full h-full flex items-center justify-center">{c.name}</div>
          <div className="w-full h-full flex items-center justify-center">{c.name}</div>
        </div>
      </div>
    </section>
  )
}

const CategoryItemMobile = ({ category: c, categoryState, className }: CategoryItemProps) => {
  const isActive = categoryState.get() === c.uid

  return (
    <section
      onClick={() => {
        store.clearHasChangedWorks()
        categoryState.set(c.uid || "")
      }}
      className={`px-[20px] ${
        className || ""
      } pt-[10px] md:pt-0 flex flex-col items-center justify-center min-w-max`}
    >
      <div
        className={` min-w-max text-base leading-7 ${
          isActive ? "text-[#ffffff]" : "text-[#ffffff]/50"
        }`}
      >
        {c.name}
      </div>
      <div className={` mt-1 w-8 h-[3px]  ${isActive ? "bg-[#4C1FFF]" : "transparent"}`}></div>
    </section>
  )
}

export function Categories(props: CategoriesProps) {
  const allCategory: V1Category = {
    cover: pingmianChahuaURL,
    id: "all",
    uid: "",
    name: "所有频道",
  }
  const [categories, setCategories] = useState([allCategory])
  useEffect(() => {
    api.listCategorys({ pageSize: 99999 }).then((res) => {
      const list: V1Category[] = res.data?.data?.list || []
      // 默认选中的分类
      props.category.set(list.find((c) => c.name === "所有频道")?.uid || "")
      const newCategories = [allCategory, ...list.filter((c) => c.name !== "无限狂想")]
      setCategories(newCategories)
    })
  }, [])

  const containerRef = useRef<HTMLElement>(null)
  const scrollRef = useRef<HTMLElement>(null)
  const [scrollX, setScrollX] = useState(0)
  const categoryWidth = 150
  const categoryGap = 8
  const mdGapCls = "md:gap-[8px]"
  const scrollDistance = (categoryWidth + categoryGap) * 2
  const minScrollX = 0
  const [maxScrollX, setMaxScrollX] = useState(0)
  useEffect(() => {
    setNextScrollX(0)
  }, [maxScrollX])
  const setNextScrollX = (offset: number) => {
    let nextScrollX = scrollX + offset
    if (nextScrollX < minScrollX) nextScrollX = minScrollX
    if (nextScrollX > maxScrollX) nextScrollX = maxScrollX
    if (scrollRef.current) {
      scrollRef.current.scrollTo(nextScrollX, 0)
    }
  }
  const refresh = useCallback(() => {
    const nextMaxScrollX =
      (categoryWidth + categoryGap) * categories.length -
      categoryGap -
      (containerRef.current?.clientWidth || 0)

    setMaxScrollX(nextMaxScrollX)
  }, [categories])
  useLayoutEffect(() => {
    refresh()
    window.addEventListener("resize", refresh)
    window.addEventListener("orientationchange", refresh)
    const resizeObserver = new ResizeObserver(refresh)
    if (containerRef.current) resizeObserver.observe(containerRef.current)
    if (scrollRef.current) {
      scrollRef.current.addEventListener("scroll", () => {
        if (scrollRef.current) setScrollX(Math.ceil(scrollRef.current.scrollLeft))
      })
    }
    return () => {
      window.removeEventListener("resize", refresh)
      window.removeEventListener("orientationchange", refresh)
      resizeObserver.disconnect()
    }
  }, [refresh])

  return (
    <section ref={containerRef} className={`relative ${props.className || ""}`}>
      <div className="overflow-hidden h-[56px]">
        <section
          ref={scrollRef}
          className="categories-container pb-0 md:bg-inherit md:pb-[20px] w-full overflow-hidden overflow-x-auto border-b border-white border-opacity-10"
        >
          <section className={`flex gap-0 ${mdGapCls} transition-transform transform-gpu`}>
            {categories.map((c) => (
              <div key={c.id} className="min-w-max">
                <CategoryItem
                  category={c}
                  categoryState={props.category}
                  categoryWidth={categoryWidth}
                  className="hidden md:flex"
                />
                <CategoryItemMobile
                  category={c}
                  categoryState={props.category}
                  className="flex md:hidden"
                />
              </div>
            ))}
          </section>
        </section>
      </div>
      {scrollX <= minScrollX ? null : (
        <button
          onClick={() => {
            setNextScrollX(-scrollDistance)
          }}
          className=" hidden md:block absolute z-20 top-0 left-[-50px] w-[90px] h-full bg-gradient-to-r from-[#161623] to-transparent"
        >
          <ArrowLeftDarkIcon className="ml-[32px]" />
        </button>
      )}
      {scrollX >= maxScrollX ? null : (
        <button
          onClick={() => {
            setNextScrollX(scrollDistance)
          }}
          className=" hidden md:block absolute z-20 top-0 right-[-50px] w-[90px] h-full bg-gradient-to-l from-[#161623] to-transparent"
        >
          <ArrowLeftDarkIcon className="rotate-180 ml-[40px]" />
        </button>
      )}
    </section>
  )
}

export interface CategoriesProps {
  category: IObservableValue<string>
  className?: string
}
