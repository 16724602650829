import React from "react"
import { useDarkMode } from "@/utils"
import "./Activity.less"
import activityStore from "@/store/activityStore"

const ActivityDescription: React.FC = () => {
  useDarkMode()

  const { activityDetail } = activityStore.store.get()
  const { title, short, long } = activityDetail || {}

  return (
    <div className="activity-description flex flex-col items-start pb-[80px]">
      <h1>
        <strong>{title}</strong>
      </h1>
      <div className="pt-5">{short}</div>
      <div dangerouslySetInnerHTML={{ __html: long || "" }}></div>
    </div>
  )
}

export default ActivityDescription
