import { useState } from "react"
import ActionIcon from "./components/ActionIcon"
import templateIcon from "@/assets/studio/mobile/template_m.svg"
import MobileBottomModal from "../components/MobileBottomModal"
import RecommendationList from "../components/TemplateRecommendation/RecommendationList"

const TemplateButton = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  return (
    <div>
      {/* Template Modal */}
      {isModalVisible && (
        <MobileBottomModal
          iconSrc={templateIcon}
          title="模板选择"
          onClose={() => {
            setIsModalVisible(false)
          }}
        >
          <RecommendationList
            onTemplateConfirm={() => {
              setIsModalVisible(false)
            }}
          />
        </MobileBottomModal>
      )}

      {/* Template Button */}
      <ActionIcon
        src={templateIcon}
        onClick={() => {
          setIsModalVisible(true)
        }}
      />
    </div>
  )
}

export default TemplateButton
