import RatioItem from "./RatioItem"

const ratios = [
  {
    width: 1,
    height: 1,
  },
  {
    width: 4,
    height: 3,
  },
  {
    width: 3,
    height: 4,
  },
  {
    width: 16,
    height: 9,
  },
  {
    width: 9,
    height: 16,
  },
]
interface ImageSizeUIProps {
  className?: string
  onClick?: (ratio: string) => void
  selectedRatio?: string
}

const ImageSizeUI = (props: ImageSizeUIProps) => {
  const { className, onClick, selectedRatio } = props || {}

  return (
    <div
      className={`  ${
        className ? className : "flex justify-between items-start gap-x-[5px] overflow-x-auto"
      } `}
    >
      {ratios.map((ratio) => {
        const ratioStr = `${ratio.width}:${ratio.height}`
        return (
          <RatioItem
            onClick={() => {
              onClick && onClick?.(ratioStr)
            }}
            width={ratio.width}
            height={ratio.height}
            key={ratio.width}
            active={ratioStr === selectedRatio}
          />
        )
      })}
    </div>
  )
}

export default ImageSizeUI
