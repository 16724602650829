import { useState } from "react"
import ActionIcon from "./components/ActionIcon"
import modelIcon from "@/assets/studio/mobile/model_m.svg"
import MobileBottomModal from "../components/MobileBottomModal"
import ModalSelect from "../components/StudioOptions/ModalSelect"

const ModelButton = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  return (
    <div>
      {/* Model Select Modal */}
      {isModalVisible && (
        <MobileBottomModal
          iconSrc={modelIcon}
          title="模型选择"
          onClose={() => {
            setIsModalVisible(false)
          }}
        >
          <ModalSelect />
        </MobileBottomModal>
      )}

      {/* Model Select Button */}
      <ActionIcon
        src={modelIcon}
        onClick={() => {
          setIsModalVisible(true)
        }}
      />
    </div>
  )
}

export default ModelButton
