import { ReactComponent as LoadingPuffIcon } from "@/assets/loading_puff.svg"

const Loader = () => {
  return (
    <div className="my-20 flex items-center justify-center">
      <LoadingPuffIcon stroke="currentColor" />
    </div>
  )
}

export default Loader
