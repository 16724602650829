interface RatioItemProps {
  width: number
  height: number
  onClick?: () => void
  active?: boolean
}
const RatioItem = ({ width, height, onClick, active }: RatioItemProps) => {
  let widthStyle = "100%"
  let heightStyle = "100%"
  if (width > height) {
    heightStyle = `${(height / width) * 100}%`
  } else {
    widthStyle = `${(width / height) * 100}%`
  }

  return (
    <div
      className={`
         flex-1 max-w-[62px] py-[20px] md:p-[10px] rounded cursor-pointer ${
           active
             ? "bg-[#6562FF]"
             : "bg-white bg-opacity-5 md:bg-[#161623] md:bg-opacity-100 hover:bg-[#6562FF]"
         }
      `}
      onClick={() => {
        onClick?.()
      }}
    >
      <div className="w-auto md:w-10">
        <div className="flex justify-center">
          <div className="w-8 h-8 flex justify-center items-center">
            <div
              className={`
                rounded w-full h-full border border-white bg-transparent ${
                  active ? "border-opacity-100" : "border-opacity-50"
                }
              `}
              style={{
                width: widthStyle,
                height: heightStyle,
              }}
            ></div>
          </div>
        </div>
        <div
          className={`
          mt-[15px] md:mt-[8px] text-center text-white ${
            active ? "text-opacity-100" : "text-opacity-50"
          }
          text-base md:text-sm
        `}
        >
          {width}:{height}
        </div>
      </div>
    </div>
  )
}

export default RatioItem
