import { profileSceneType } from "../types"

interface TipsProps {
  scene?: profileSceneType
}
const Tips = ({ scene = "modal" }: TipsProps) => {
  const styleConfig = {
    modal: {
      titleTextColor: "text-white",
      titleTextOpacity: "text-opacity-50",
      tipValueTextColor: "text-white",
    },
    login: {
      titleTextColor: "text-black",
      titleTextOpacity: "text-opacity-80",
      tipValueTextColor: "text-black",
    },
  }

  const activeStyle = styleConfig[scene]

  return (
    <div className="mt-5">
      <div
        className={`${activeStyle.titleTextColor} ${activeStyle.titleTextOpacity} font-normal font-sans text-sm`}
      >
        说明:
      </div>
      <div className="mt-2.5">
        <div className={`${activeStyle.tipValueTextColor} text-opacity-50 font-sans text-sm`}>
          用户可免费修改1次昵称
        </div>
        <div className={`${activeStyle.tipValueTextColor} text-opacity-50 font-sans text-sm`}>
          每30天免费更换1次头像
        </div>
      </div>
    </div>
  )
}

export default Tips
