import { BackTop, Button } from "@arco-design/web-react"
import { CSSProperties } from "react"
import { ReactComponent as IconBackToTop } from "@/assets/back-to-top.svg"

export function BackTopButton({ style }: { style?: CSSProperties }) {
  return (
    <BackTop style={style ?? {}}>
      <Button
        type="secondary"
        style={{
          backgroundColor: "#111124",
          color: "#FFF",
          height: "30px",
          width: "30px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        icon={<IconBackToTop color="#fff" />}
      ></Button>
    </BackTop>
  )
}
