import { ReactComponent as EditIcon } from "@/assets/studio/edit.svg"
import { useEffect, useRef, useState } from "react"
import { isMobileOnly } from "react-device-detect"

interface ElementInputProps {
  value: string
  onChange?: (text: string) => void
  width?: number
  visible?: boolean
}
const ElementInput = ({ value, onChange, width, visible }: ElementInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [inputValue, setInputValue] = useState(value)

  useEffect(() => {
    setInputValue(value)
  }, [value])

  useEffect(() => {
    if (visible) {
      inputRef.current?.focus()
    }
  }, [visible])

  const displayCls = visible ? "" : "hidden"

  const confirmChange = () => {
    onChange?.(inputValue)
  }

  return (
    <input
      contentEditable
      ref={inputRef}
      className={`${displayCls} bg-transparent text-[14px] leading-5 outline-none pr-[16px] caret-white`}
      style={{ width }}
      value={inputValue}
      onChange={(e) => {
        setInputValue(e.currentTarget.value)
      }}
      onBlur={(e) => {
        confirmChange()
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          confirmChange()
        }
      }}
    />
  )
}

interface PromptElementProps {
  label: string
  editable?: boolean
  onChange?: (text: string) => void
  onButtonClick?: () => void
}
const PromptElement = ({ label, editable, onChange, onButtonClick }: PromptElementProps) => {
  const bgCls = editable ? "bg-[#6663FF]" : "bg-[#2E2E4A]"
  const paddingRightCls = isMobileOnly ? "pr-[6px]" : editable ? "pr-0" : "pr-[5px]"
  const cursorCls = editable ? "cursor-pointer" : ""
  const [inputWidth, setInputWidth] = useState(120)
  const labelElement = useRef<HTMLDivElement>(null)

  const editIconWidth = 16
  const editIconWidthCls = "w-[16px]"

  // Make sure the input width is the same as the label width
  useEffect(() => {
    if (labelElement.current?.clientWidth) {
      const labelElementWidth = labelElement.current?.clientWidth
      setInputWidth(labelElementWidth + editIconWidth)
    }
  }, [labelElement])

  // Observe the width change of the label
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const labelElementWidth = entry.contentRect.width
        if (labelElementWidth) {
          setInputWidth(labelElementWidth + editIconWidth)
        }
      }
    })
    if (labelElement.current) {
      resizeObserver.observe(labelElement.current)
    }
    return () => {
      if (labelElement.current) {
        resizeObserver.unobserve(labelElement.current)
      }
    }
  }, [])

  const [isEditing, setIsEditing] = useState(false)

  const handleElementChange = (text: string) => {
    onChange?.(text)
    setIsEditing(false)
  }

  const labelDisplayCls = isEditing ? "hidden" : "flex"

  return (
    <div
      className={`
        min-w-fit
        hover:brightness-110 transition duration-200 ease-in-out md:pl-[5px] pl-2.5
        rounded-full md:rounded ${bgCls} flex items-center ${paddingRightCls} ${cursorCls}
      `}
      onClick={() => {
        if (onButtonClick) {
          onButtonClick?.()
          return
        }

        if (editable) {
          setIsEditing(true)
        }
      }}
    >
      {/* Prompt element input */}
      <ElementInput
        visible={isEditing}
        value={label}
        onChange={(text) => {
          handleElementChange(text)
        }}
        width={inputWidth}
      />

      {/* Prompt element label */}
      <div className={`${labelDisplayCls} items-center`}>
        <div
          ref={labelElement}
          className="whitespace-nowrap md:whitespace-normal leading-[30px] flex-1 text-[14px] md:leading-5"
        >
          {label}
        </div>
        {editable && <EditIcon className={`${editIconWidthCls} h-4`} fill="#fff" />}
      </div>
    </div>
  )
}

export default PromptElement
