import "@arco-design/web-react/dist/css/arco.css"
import { useEffect, useState } from "react"
import { Carousel } from "@arco-design/web-react"
import TemporaryLayout from "@/components/layout/temp"
import { useDarkMode } from "@/utils"
import ZhangjiangImage from "@/assets/images/zhangjiang-tiamat.png"
import { getAllTasksOfZhangjiangV2 } from "@/services/editor"

interface ListItem {
  works: workItem[]
}
interface workItem {
  image: string
  score: number
}
type ImageList = string[]

function removeDuplicate(originList: ImageList, targetList: ImageList) {
  return targetList.reduce<ImageList>((result, item) => {
    if (!originList.some((ori) => ori === item)) {
      result.push(item)
    }
    return result
  }, [])
}
function getImageFromData(list: ListItem[]) {
  return (list || []).reduce((result, item) => {
    const targetImageUrl = getMaxScoreImage(item.works)
    if (targetImageUrl) {
      result.push(targetImageUrl)
    }
    return result
  }, [] as ImageList)
}

function getMaxScoreImage(works: workItem[]) {
  return works.sort((a, b) => b.score - a.score)[0]?.image
}
export default function ZhangjiangV2Preview({ onlyContent }: { onlyContent?: boolean }) {
  useDarkMode()
  const [loading, setLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const [currentCarouselIndex, setCurrentCarouselIndex] = useState(0)
  const [imageList, setImageList] = useState<ImageList>([])
  const [pageConfig, setPageConfig] = useState({
    current: 1,
    pageSize: 20,
  })

  useEffect(() => {
    getImgeList()
    const timer = setInterval(() => {
      getImgeList()
    }, 2000) // 轮询时间 2s

    return () => {
      clearInterval(timer)
    }
  }, [])
  
  useEffect(() => {
    if (imageList.length - currentCarouselIndex < 2) {
      getImgeList(pageConfig.current + 1)
    }
  }, [currentCarouselIndex])

  async function getImgeList(current = pageConfig.current) {
    if (loading || !hasMore) {
      return
    }

    const params = {
      limit: 20,
    }

    try {
      setLoading(true)
      const res = await getAllTasksOfZhangjiangV2(params).finally(() => {
        setLoading(false)
      })
      if (res.success) {
        const { data } = res
        setHasMore((data.current - 1) * data.pageSize + data.list.length < data.total)
        setPageConfig({
          ...pageConfig,
          current: data.current,
        })
        const newestImageList = getImageFromData(data.list ?? [])
        addImageByIndex(newestImageList, currentCarouselIndex)
      } else {
        //
      }
    } catch (e) {
      console.error("error from api: ", e)
    }
  }
  function addImageByIndex(newestImageList: ImageList, index: number) {
    // console.log("接口已返回", index)
    setImageList((preList) => {
      const restList = removeDuplicate(preList, newestImageList)
      if (restList.length) {
        // console.log("新增数据：", restList)
        // console.log("插入到index：", index)
        return preList
          .slice(0, index + 1)
          .concat(restList)
          .concat(preList.slice(index + 1))
      }
      return preList
    })
  }

  function handleChangeCarousel(index: number) {
    setCurrentCarouselIndex(index)
  }
  const content = (
    <div className={`flex justify-center items-center w-screen ${onlyContent ? "" : "h-full"}`}>
      <Carousel
        style={{ width: "auto", height: `${onlyContent ? "50vh": "100vh" }`, maxWidth: "100vw" }}
        autoPlay={{
          interval: 5000,
          hoverToPause: true,
        }}
        indicatorType="never"
        showArrow="hover"
        onChange={handleChangeCarousel}
      >
        {imageList.map((src) => (
          <div key={src} className="relative">
            <img
              src={`${src}/zhangjiang_watermark`}
              className="h-full w-auto object-contain max-w-full"
            />
            {/* <div className="absolute right-[10px] bottom-[60px] flex items-center">
              <img src={ZhangjiangImage} width="220px" />
            </div> */}
          </div>
        ))}
      </Carousel>
    </div>
  )
  return onlyContent ? (
    content
  ) : (
    <TemporaryLayout hideHeader={true} hideFooter={true}>
      {content}
    </TemporaryLayout>
  )
}
