import { getRecordNumberByDomain } from "@/constants"

function Footer() {
  return (
    <div className="w-full flex justify-center text-xs mt-10 mb-2 items-center cursor-pointer">
      <a href="https://beian.miit.gov.cn/#/Integrated/index">{getRecordNumberByDomain()}</a>
      <a
        className="flex  justify-center items-center"
        target="_blank"
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502020083"
      >
        <img className="pl-2 " src="/assets/beian.png" />
        <p className="pl-2 ">沪公网安备 31011502020083号</p>
      </a>
    </div>
  )
}

export default Footer
