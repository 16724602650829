import { Tabs } from "@arco-design/web-react"
import FeedbackForm from "./FeedbackForm/index"
import FeedbackTable from "./FeedbackTable/index"
import { useState } from "react"
import "./feedback.less"
import { isMobileOnly } from "react-device-detect"

const TabPane = Tabs.TabPane

export default function Feedback() {
  const [activeTab, setActiveTab] = useState("account")
  return (
    <>
      <Tabs
        defaultActiveTab="account"
        className={isMobileOnly ? "use-center-feedback-mobile-tabs" : "use-center-feedback-tabs"}
        onChange={(key) => {
          setActiveTab(key)
        }}
      >
        <TabPane key="account" title={<span>账号问题</span>}>
          <FeedbackForm pageType="account" />
        </TabPane>
        <TabPane key="bug" title={<span>BUG反馈</span>}>
          <FeedbackForm pageType="bug" />
        </TabPane>
        <TabPane key="suggestion" title={<span>产品建议</span>}>
          <FeedbackForm pageType="suggestion" />
        </TabPane>
        <TabPane key="log" title={<span>反馈记录</span>}>
          <FeedbackTable activeTab={activeTab} />
        </TabPane>
      </Tabs>
    </>
  )
}
