import { createContext, useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { ReactComponent as IconCreateAlbum } from "@/assets/works/create_albumn.svg"
import { useLocation, useParams } from "react-router-dom"
import InfiniteScroll from "@/components/common/InfiniteScroll"
import * as store from "@/store"
import { ReactComponent as LoadingPuffIcon } from "@/assets/loading_puff.svg"
import LayoutGeneral from "@/components/layout/general"
import AlbumEditorModal, { EditorAlbumParams } from "@/common/AlbumEditorModal"
import Drag from "./Drag"
import AlbumCard from "@/common/AlbumCard"
import { arrayMoveImmutable } from "array-move"
import {
  AlbumCancelLike,
  Albumlike,
  batchDeleteCollection,
  creatAlbum,
  getAlbumDetails,
  getAllListCollection,
  guestGetAllList,
  updateAlbum,
} from "@/services/album"
import { V1Collection } from "@/generated/api/data-contracts"
import { observer } from "mobx-react-lite"
import ConfirmModal from "@/common/ConfirmModal"
import albumStore from "@/store/albumStore"
import { toastError, toastSuccess } from "@/common/Toast"
import ContentSwitcher from "../components/ContentSwitcher"
import SortButton from "../components/SortButton"
import SelectButton from "../components/SelectButton"
import { SortEnum } from "../components/SortButton/constant"
import {
  svipAlbumCount,
  svipSubscribeTypeList,
  tiaProSubscribeTypeList,
  timProAlbumCount,
  vipAlbumCount,
  vipSubscribeTypeList,
} from "@/constants"
import { useVisitStatus } from "../VisitStatusContext"

function Album() {
  const { t } = useTranslation(["translation"])
  const location = useLocation()
  // 访客使用
  const { isVisted } = useVisitStatus()
  const { userId } = useParams()
  const { id } = store.user.get()
  //  当前用户id
  const { subscribedProduct } = store.user.get()
  const [albums, setAlbums] = useState<V1Collection[]>([])
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const myAlbumsCount = albumStore.myAlbumsCount
  const [sortType, setSortType] = useState<SortEnum>(SortEnum.newest)
  const [refresh, setRefresh] = useState(1)
  // const [isManaging, setIsManaging] = useState(false)
  const [confirmVisible, setConfirmVisible] = useState(false)
  const [currentAlbum, setCurrentAlbum] = useState<V1Collection>({})
  const [removeAlbumUIDs, setRemoveAlbumUIDs] = useState<string[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [oprationLoading, setOprationLoading] = useState(false)
  const [confirmConfig, setConfirmConfig] = useState<{
    msg: string
    method: (removeAlbumUIDs: Array<string> | []) => void
  }>({
    msg: "",
    method: () => {},
  })
  const [editorModalVisible, setEditorModalVisible] = useState(false)

  const Loader = () => {
    return (
      <div className="my-20 flex items-center justify-center">
        <LoadingPuffIcon stroke="currentColor" />
      </div>
    )
  }
  const NoMore = () => {
    const { t } = useTranslation()
    return (
      <section className="py-10 flex justify-center items-center">
        <span className="inline-flex items-center text-[#6F7176] text-sm before:mr-[26px] before:bg-[#C9CBD14D]/30 before:w-[138px] before:h-[1px] after:ml-[26px] after:bg-[#C9CBD14D]/30 after:w-[138px] after:h-[1px]">
          {t("worksCenter.noMore")}
        </span>
      </section>
    )
  }
  function srcollToTarget(id: string) {
    const target = document.getElementById(id)
    if (target) {
      window.scrollTo(0, target.offsetTop - 60)
    }
  }

  // const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
  //   const newList = arrayMoveImmutable(albums, oldIndex, newIndex)
  // }
  const editorAlbum = async (params?: EditorAlbumParams, isEditor?: boolean) => {
    setOprationLoading(true)
    if (isEditor) {
      const { success, errorCode } = await updateAlbum({
        collectionUID: currentAlbum.uid,
        ...params,
      })
      if (success) {
        toastSuccess("更新成功")
        setRefresh(refresh + 1)
      } else {
        toastError(t(`errorMessage:${errorCode}` as any))
      }
    } else {
      const { data, success, errorCode } = await creatAlbum({
        intro: "",
        ...params,
      })
      if (success) {
        toastSuccess("创建成功")
        setRefresh(refresh + 1)
      } else {
        toastError(t(`errorMessage:${errorCode}` as any))
      }
    }
    setEditorModalVisible(false)
    setCurrentAlbum({})
    setOprationLoading(false)
  }
  const removeAlbum = async (collectionUIDs: string[]) => {
    setOprationLoading(true)
    const { success, errorCode } = await batchDeleteCollection({ collectionUIDs })
    if (success) {
      toastSuccess("删除成功")
      setRefresh(refresh + 1)
    } else {
      toastError(t(`errorMessage:${errorCode}` as any))
    }
    setOprationLoading(false)
    setEditorModalVisible(false)
    setCurrentAlbum({})
  }
  const cancelCollect = async (collectionUIDs: string[]) => {
    setOprationLoading(true)
    const collectionUID = collectionUIDs[0]
    const { success, errorCode } = await AlbumCancelLike(collectionUID)
    if (success) {
      toastSuccess("取消收藏成功")
      setRefresh(refresh + 1)
    } else {
      toastError(t(`errorMessage:${errorCode}` as any))
    }
    setOprationLoading(false)
  }
  const addCollect = async (collectionUID: string) => {
    setOprationLoading(true)
    const { success, errorCode } = await Albumlike(collectionUID)
    if (success) {
      toastSuccess("收藏成功")
      setRefresh(refresh + 1)
    } else {
      toastError(t(`errorMessage:${errorCode}` as any))
    }
    setOprationLoading(false)
  }
  const confirm = async (removeAlbumUIDs: Array<string>) => {
    setConfirmVisible(false)
    confirmConfig?.method(removeAlbumUIDs)

    // Albumlike("pnXLIGZfN9E2O5WqSp8W0")
  }
  //  是否可以创建相册
  const canCreateAlbum = useMemo(() => {
    let subscribeType = subscribedProduct?.subscribeType || 0
    if (tiaProSubscribeTypeList.includes(subscribeType)) {
      return myAlbumsCount < timProAlbumCount
    } else if (svipSubscribeTypeList.includes(subscribeType)) {
      return myAlbumsCount < svipAlbumCount
    } else if (vipSubscribeTypeList.includes(subscribeType)) {
      return myAlbumsCount < vipAlbumCount
    }
  }, [subscribedProduct, myAlbumsCount])
  const isVip = !!subscribedProduct?.subscribeType

  const loadMore = () => {
    setCurrentPage(currentPage + 1)
    getAllAlbums(currentPage + 1, sortType)
  }
  const getAllAlbums = async (current: number, type: SortEnum) => {
    setLoading(true)
    if (isVisted) {
      const { data } = await guestGetAllList(userId || "", {
        current: current,
        pageSize: 10,
        sort: type,
      })
      if (data?.list) {
        current === 1 ? setAlbums(data?.list) : setAlbums([...albums, ...data?.list])
        setTotal(data?.total || 0)
      }
    } else {
      const { data } = await getAllListCollection({
        current: current,
        pageSize: 10,
        sort: type,
      })
      if (data?.list) {
        current === 1 ? setAlbums(data?.list) : setAlbums([...albums, ...data?.list])
        setTotal(data?.total || 0)
      }
    }

    setLoading(false)
  }
  useEffect(() => {
    getAllAlbums(1, sortType)
    albumStore.getMyAlbums()
  }, [refresh])

  useEffect(() => {
    if (location.hash) {
      srcollToTarget(location.hash.substring(1))
    }
  }, [location.hash])
  useEffect(() => {
    if (location.hash) {
      srcollToTarget(location.hash.substring(1))
    }

    // list.map((item) => {
    //   Albumlike(item)
    // })
  }, [])
  const hasMore = albums.length < total
  return (
    <LayoutGeneral>
      <ContentSwitcher
        actionComponent={
          <>
            <SortButton
              value={sortType}
              onChange={(type) => {
                setSortType(type)
                setAlbums([])
                getAllAlbums(1, type)
              }}
            />
            {/* <SelectButton
              manageBtnText="相册管理"
              isManaging={isManaging}
              onManageClick={() => {
                setIsManaging(true)
              }}
              onExitClick={() => {
                setIsManaging(false)
              }}
            /> */}
          </>
        }
      />
      {oprationLoading && (
        <div className="flex items-center justify-center w-full h-full z-30 absolute top-0 left-0 bg-[#2E2E4A] bg-opacity-70">
          <LoadingPuffIcon />
        </div>
      )}
      <InfiniteScroll
        hasMore={hasMore}
        isLoading={loading}
        loadMore={() => {
          loadMore()
        }}
        loader={<Loader />}
        noMoreElement={<NoMore />}
      >
        <Drag
          sortedHandler={() => {}}
          items={albums}
          boxEleGnerator={(item) => {
            return (
              <AlbumCard
                data={item}
                handleLike={(uid: string) => {
                  addCollect(uid)
                }}
                handleUnlike={(item: string[]) => {
                  setConfirmConfig({ msg: "确认是否取消收藏当前相册", method: cancelCollect })
                  setConfirmVisible(true)
                  setRemoveAlbumUIDs(item)
                }}
              />
            )
          }}
          handleOpen={(item) => {
            setEditorModalVisible(true)
            setCurrentAlbum(item)
          }}
          noDragElement={
            !isVisted && (
              <div
                className="rounded-[6px] bg-[#0C0C1A] flex flex-col items-center justify-center cursor-pointer relative"
                onClick={() => {
                  if (canCreateAlbum) {
                    setEditorModalVisible(true)
                  } else {
                    toastError(t(`errorMessage:${25005}` as any))
                  }
                }}
              >
                <div className="w-full relative -z-10">
                  <AlbumCard data={{}} />
                </div>

                <div className="absolute inset-0 flex items-center flex-col justify-center">
                  <IconCreateAlbum />
                  <div className="text-[16px] text-gray-400 w-full flex justify-center">
                    创建相册
                  </div>
                </div>
              </div>
            )
          }
        />
      </InfiniteScroll>

      <AlbumEditorModal
        initData={currentAlbum}
        isVip={isVip}
        loading={oprationLoading}
        handleSubmit={(data?: EditorAlbumParams, isEditor?: boolean) => {
          editorAlbum(data, isEditor)
        }}
        handleClose={() => {
          setEditorModalVisible(false)
          setCurrentAlbum({})
        }}
        handleDelete={(item) => {
          setConfirmConfig({ msg: "确认是否删除相册", method: removeAlbum })
          setRemoveAlbumUIDs(item)
          setConfirmVisible(true)
        }}
        visible={editorModalVisible}
      />
      <ConfirmModal
        visible={confirmVisible}
        message={confirmConfig.msg}
        handleClose={(isConfirm) => {
          isConfirm ? confirm(removeAlbumUIDs) : setConfirmVisible(false)
        }}
      />
    </LayoutGeneral>
  )
}
export default observer(Album)
