import {
  V1ActivitySubmissionBeiyingRequest,
  V1ActivitySubmissionBeiyingResponse,
} from "@/generated/api/data-contracts"
import { activityApi } from "./api"
// 我的模板
export const submitMission = async (
  params: V1ActivitySubmissionBeiyingRequest
): Promise<V1ActivitySubmissionBeiyingResponse> => {
  const res = await activityApi.activitySubmissionBeiying(params, { format: "json" })
  return res.data
}
