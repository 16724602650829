import { ReactNode, useEffect, useRef } from "react"

interface InfiniteScrollProps {
  loadMore: () => void
  hasMore: boolean
  isLoading: boolean
  loader?: ReactNode
  loaderClassName?: string
  children: ReactNode
  noMoreElement?: ReactNode
  root?: HTMLElement | null
}
const InfiniteScroll = ({
  loadMore,
  hasMore,
  isLoading,
  loader,
  loaderClassName,
  children,
  noMoreElement,
  root,
}: InfiniteScrollProps) => {
  const observeTarget = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore && !isLoading) {
          loadMore()
        }
      },
      {
        root: root,
        rootMargin: "0px",
        threshold: 1,
      }
    )
    if (observeTarget.current) {
      observer.observe(observeTarget.current)
    }
    return () => {
      if (observeTarget.current) {
        observer.unobserve(observeTarget.current)
      }
    }
  }, [hasMore, isLoading, loadMore])

  const loaderCls = isLoading ? "block" : "hidden"

  return (
    <>
      {children}

      {/* Loader */}
      <div className={`${loaderCls} ${loaderClassName}`}>{loader}</div>

      {/* Observe Target */}
      <div className="h-4 w-full flex items-center justify-center">
        {!isLoading && hasMore && <div className="h-[1px] w-[1px]" ref={observeTarget}></div>}
      </div>

      {/* No More */}
      <div>{!hasMore && !isLoading && noMoreElement}</div>
    </>
  )
}

export default InfiniteScroll
