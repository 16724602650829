import Navbar from "@/Navbar"
import {
  useComputed,
  useDarkMode,
  useMobxSearchParams,
  useWindowWidth,
  getColumnCount,
} from "@/utils"

import { ReactComponent as LoadingPuffIcon } from "@/assets/loading_puff.svg"
import React, { useEffect, useMemo, useState } from "react"
import { observable, runInAction } from "mobx"
import { V1ListTasksResponse, V1Task, V1Work } from "@/generated/api/data-contracts"
import InfiniteScroll from "react-infinite-scroller"
import { Masonry } from "masonic"
import HomeCard from "@/pages/Works/HomeCard"
import { observer } from "mobx-react-lite"
import Modal from "@/common/ImgDetailModal/Modal"
import { getListAllTasks } from "@/services/home"
import { api } from "@/services/api"
import { toastError, toastSuccess } from "@/common/Toast"
import { ErrorCode } from "@/i18next"
import { useTranslation } from "react-i18next"
import { ReactComponent as DefaultIcon } from "@/assets/default-icon.svg"

interface ImgActivityProps {
  contributeType: string
  id: string
}

let hasMore = true

const ImgActivity: React.FC<ImgActivityProps> = (props) => {
  useDarkMode()
  const searchParams = useMobxSearchParams()
  const isSearching = !!searchParams.get().get("q")
  const columnCount = getColumnCount(useWindowWidth(), true)

  const [currentTaskIndex, setCurrentTaskIndex] = useState<number>()
  const [currentTask, setCurrentTask] = useState<V1Task>()
  const [modalVisiable, setModalVisiable] = useState<boolean>(false)
  const [taskChangeDisable, setTaskChangeDisable] = useState<"prev" | "next" | "none">("none")

  // 最受欢迎 popular 最新 newest 最高分 highest
  const sort = useMemo(() => observable.box(props?.contributeType || "newest"), [])

  const { t } = useTranslation(["translation", "errorMessage"])

  useEffect(() => {
    return () => {
      hasMore = true
    }
  }, [])

  useEffect(() => {
    if (sort.get() !== props?.contributeType) {
      hasMore = true
      sort.set(props?.contributeType)
    }
  }, [props?.contributeType])

  const [pages, loadPage, uid, list] = useComputed(() => {
    const list = observable.array([] as any)
    const query = searchParams.get().get("q") || "" // reload pages if query update
    const _sort = sort.get()
    const pages = observable.array([] as V1ListTasksResponse[])
    const loadPage = async (page: number) => {
      if (!hasMore) return
      hasMore = false
      const params = {
        current: page,
        pageSize: 50,
        query,
        sort: _sort,
        category: "",
        activityUID: props?.id || "",
      }

      try {
        const data = await getListAllTasks(params)
        const res = data as V1ListTasksResponse
        hasMore =
          ((res.current || 0) - 1) * (res.pageSize || 0) + (res.list?.length || 0) <
          (res.total || 0)
        runInAction(() => {
          pages[page - 1] = data || []
          // 组合成展开的list列表
          const recList: any = []
          pages?.map((page) => {
            page?.list?.map((item) => {
              recList.push(item)
            })
          })

          list[0] = recList
        })
      } catch (error) {}
    }

    return [pages, loadPage, Date.now(), list] as const
  }).get()

  const cardCount = pages?.reduce((r, i) => r + (i?.list?.length || 0), 0)

  const handleTaskChange = (direction: "prev" | "next") => {
    // 违法情况
    if (direction == "prev" && currentTaskIndex! <= 1) {
      setTaskChangeDisable("prev")
      if (currentTaskIndex == 0) return
    } else if (direction == "next" && currentTaskIndex! >= list[0]?.length - 2) {
      setTaskChangeDisable("next")
      if (currentTaskIndex == list[0]?.length - 1) return
    } else {
      setTaskChangeDisable("none")
    }
    const newIndex = currentTaskIndex! + (direction == "prev" ? -1 : 1)
    const result = list?.[0]?.[newIndex!]
    setCurrentTaskIndex(newIndex!)
    setCurrentTask(result)
  }

  const getMasonryItems = () => {
    const masonryItems: {
      task: V1Task
      work: V1Work
    }[] = []

    pages?.map((item) => {
      item?.list?.map((task) => {
        const work_1 = task.works?.[0]
        if (work_1) {
          masonryItems.push({
            task,
            work: work_1,
          })
        }
      })
    })
    return masonryItems
  }

  const defaultDom = () => {
    return (
      <div className="flex flex-col items-center h-[30vh] justify-center ">
        <div className="pb-10">暂无更多作品</div>
        <DefaultIcon width={200} height={200} />
      </div>
    )
  }
  return (
    <section className="relative dark:text-[#fff]">
      <div className={`mobile_content_container `}>
        <section
          data-i="home-container"
          className=" px-0 pt-0 mx-auto  w-full max-w-[1920px]" // xl:w-[1280px] min-[1440px]:w-[1440px] min-[1920px]:w-[1920px]"
        >
          {/* <LogoIcon className=" mt-[185px] mx-auto w-[256px] h-[48px] object-contain md:hidden" /> */}

          <div className="md:bg-[#161623]">
            {/* Works Section */}

            <div className={`md:mx-auto`}>
              <InfiniteScroll
                key={uid}
                pageStart={0}
                loadMore={loadPage}
                hasMore={hasMore}
                loader={
                  <div className="pt-[100px]">
                    <LoadingPuffIcon
                      key={"loading"}
                      className="mx-auto"
                      stroke="currentColor"
                    ></LoadingPuffIcon>
                  </div>
                }
              >
                <Masonry
                  items={getMasonryItems()}
                  render={({ data: { task, work } }) => {
                    return (
                      <div
                        style={{ aspectRatio: task.ratio.replace(":", "/") }}
                        onClick={() => {
                          setCurrentTask(task)
                          const result = list[0].findIndex((item: any) => {
                            return item.id == task.id
                          })
                          if (result == 0) {
                            setTaskChangeDisable("prev")
                          } else if (result == list[0].length - 1) {
                            setTaskChangeDisable("next")
                          } else {
                            setTaskChangeDisable("none")
                          }
                          setCurrentTaskIndex(result)
                          setModalVisiable(true)
                        }}
                      >
                        <HomeCard task={task} work={work} showRegen refJump showOption />
                      </div>
                    )
                  }}
                  columnCount={columnCount}
                  columnGutter={20}
                />
                {cardCount && !hasMore ? (
                  <section className="mt-[22px] flex justify-center items-center pt-20">
                    <span className="inline-flex items-center text-[#6F7176] text-sm before:mr-[26px] before:bg-[#C9CBD14D]/30 before:w-[138px] before:h-[1px] after:ml-[26px] after:bg-[#C9CBD14D]/30 after:w-[138px] after:h-[1px]">
                      没有更多了
                    </span>
                  </section>
                ) : null}
              </InfiniteScroll>

              {!cardCount && (
                <section className="flex flex-col items-center">
                  <p className="my-2.5 text-center text-sm">
                    {isSearching ? "暂未搜索到内容" : defaultDom()}
                  </p>
                </section>
              )}
            </div>
          </div>
        </section>
      </div>

      {currentTask?.works && (
        <Modal
          open={modalVisiable}
          onClose={() => setModalVisiable(false)}
          task={currentTask}
          work={currentTask.works[0]}
          handleTaskChange={handleTaskChange}
          taskChangeDisable={taskChangeDisable}
          fromHomePage={true}
        />
      )}
    </section>
  )
}

export default observer(ImgActivity)
