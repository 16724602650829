import leftArrowIcon from "@/assets/studio/mobile/left_arrow_m.svg"

interface MobileModalHeaderProps {
  title: string
  onGoBack?: () => void
}
const MobileModalHeader = ({ title, onGoBack }: MobileModalHeaderProps) => {
  return (
    <div className="p-[10px]">
      <div className="py-[5px] flex items-center">
        <img
          src={leftArrowIcon}
          alt=""
          className="w-7 h-7 cursor-pointer"
          onClick={() => {
            onGoBack?.()
          }}
        />
        <div className="ml-[2px] text-[18px] text-[#FFFFFFCC]">{title}</div>
      </div>
    </div>
  )
}

export default MobileModalHeader
