import albumStore from "@/store/albumStore"
import { ReactComponent as HeartFilledIcon } from "../../../../../assets/works/heart_filled.svg"
import AlbumLabel from "./AlbumLable"
import { useMemo, useState } from "react"
import { api } from "@/services/api"
import { toastError } from "@/common/Toast"
import { observer } from "mobx-react-lite"
import { V1Work } from "@/generated/api/data-contracts"

interface AlbumSelectProps {
  work: V1Work
  onSelectVisibleChange?: (visible: boolean) => void
  className?: string
  style?: React.CSSProperties
}
const AlbumSelect = observer(
  ({ work, onSelectVisibleChange, className, style }: AlbumSelectProps) => {
    const [selectValue, setSelectValue] = useState("")
    const albums = albumStore.myAlbums || []

    const workId = work?.id

    let selectedAlbum = useMemo(() => {
      const item = albums.find((item) => item.uid === selectValue)
      return item || albums[0]
    }, [selectValue, albums])

    const selectedAlbumId = selectedAlbum?.uid || ""

    const isCollectedBySelectedAlbum = useMemo(() => {
      return selectedAlbum?.works?.some((item) => item.id === workId)
    }, [selectedAlbum?.works?.length, workId])

    const heartIconColor = isCollectedBySelectedAlbum ? "#E93247" : "white"

    const handleCollect = async () => {
      if (!workId || !selectedAlbumId) {
        console.error("workId或selectedAlbumId不存在")
        return
      }

      albumStore.updateWorkOfAlbum(selectedAlbumId, work)

      try {
        let resp = null
        if (isCollectedBySelectedAlbum) {
          // 取消收藏
          resp = await api.unlikeWork(workId, { collectionUID: selectedAlbumId })
        } else {
          // 添加收藏
          resp = await api.likeWork(workId, { collectionUID: selectedAlbumId })
        }
        console.log("resp", resp)
        const respSuccess = resp?.data?.success
        if (respSuccess) {
          albumStore.getMyAlbums()
        } else {
          toastError("操作失败")
        }
      } catch (error) {
        console.error(error)
      }
    }

    return (
      <div
        className={`bg-black bg-opacity-25 rounded flex items-center p-1 gap-x-[2px]`}
        style={style}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        {/* Heart Icon */}
        <div
          className="w-7 h-7 flex items-center justify-center cursor-pointer"
          onClick={handleCollect}
        >
          <HeartFilledIcon fill={heartIconColor} className="w-[18px] object-contain" />
        </div>

        {/* Divider */}
        <div className="w-[1px] h-5 bg-white bg-opacity-20"></div>

        {/* Album Label */}
        <AlbumLabel
          onChange={(albumId) => {
            setSelectValue(albumId)
          }}
          selectedAlbumId={selectedAlbumId}
          onPopoverVisibleChange={(visible) => {
            onSelectVisibleChange?.(visible)
          }}
        />
      </div>
    )
  }
)

export default AlbumSelect
