import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"
import "./styles.less"

// import required modules
import { Mousewheel, Navigation } from "swiper"
import { useEffect, useState } from "react"

const list = [
  {
    img: "/assets/zhangjiang-v2/style-models/yinxiangzhuyi.png",
    title: "印象主义",
    ready: true,
    payload: {
      model: "tongyong-jichu",
      ratio: "16:9",
      textPrompt: "现代城市、梵高风格",
      controlNetUnit1: {
        module: "mlsd",
        weight: 0.8,
      },
    },
  },
  {
    img: "/assets/zhangjiang-v2/style-models/liujinchaxun.png",
    title: "鎏金插画",
    ready: true,
    payload: {
      model: "liujin-chahua-2",
      ratio: "16:9",
      textPrompt: "建筑",
      controlNetUnit1: {
        module: "depth",
        weight: 0.8,
      },
    },
  },
  {
    img: "/assets/zhangjiang-v2/style-models/pingmianchatu.png",
    title: "平面插图",
    ready: true,
    payload: {
      model: "pingmian-chahua",
      ratio: "16:9",
      textPrompt: "平面插图，装饰主义风格",
      controlNetUnit1: {
        module: "mlsd",
        weight: 0.8,
      },
    },
  },
  {
    img: "/assets/zhangjiang-v2/style-models/chaoxianshizhuyi.png",
    title: "超现实主义",
    ready: true,
    payload: {
      model: "tongyong-jichu",
      ratio: "16:9",
      textPrompt: "达芬奇绘制的超现实主义建筑",
      controlNetUnit1: {
        module: "mlsd",
        weight: 0.8,
      },
    },
  },
  {
    img: "/assets/zhangjiang-v2/style-models/yinxiangzhuyi.png",
    title: "印象主义",
    ready: true,
    payload: {
      model: "tongyong-jichu",
      ratio: "16:9",
      textPrompt: "现代城市、梵高风格",
      controlNetUnit1: {
        module: "mlsd",
        weight: 0.8,
      },
    },
  },
  {
    img: "/assets/zhangjiang-v2/style-models/liujinchaxun.png",
    title: "鎏金插画",
    ready: true,
    payload: {
      model: "liujin-chahua-2",
      ratio: "16:9",
      textPrompt: "建筑",
      controlNetUnit1: {
        module: "depth",
        weight: 0.8,
      },
    },
  },
  {
    img: "/assets/zhangjiang-v2/style-models/pingmianchatu.png",
    title: "平面插图",
    ready: true,
    payload: {
      model: "pingmian-chahua",
      ratio: "16:9",
      textPrompt: "平面插图，装饰主义风格",
      controlNetUnit1: {
        module: "mlsd",
        weight: 0.8,
      },
    },
  },
  {
    img: "/assets/zhangjiang-v2/style-models/chaoxianshizhuyi.png",
    title: "超现实主义",
    ready: true,
    payload: {
      model: "tongyong-jichu",
      ratio: "16:9",
      textPrompt: "达芬奇绘制的超现实主义建筑",
      controlNetUnit1: {
        module: "mlsd",
        weight: 0.8,
      },
    },
  },
]

const slidePerView = 5
const StyleModels = ({ onChange }: { onChange: (data: any) => void }) => {
  const [swiperName, setSwiperName] = useState("")
  useEffect(() => {
    setSwiperName("style-models my-swiper-" + Date.now().toString(16))
    onChange?.(list[0]) // todo 后续验证传的值
  }, [])

  const getData = () => {
    setTimeout(() => {
      const ele2 = document?.querySelector(
        `.style-models-div .swiper-wrapper .swiper-slide-active + .swiper-slide`
      ) as HTMLElement
      onChange?.(list[(ele2.tabIndex + list.length - 1) % list.length])
    }, 100)
  }
  // @ts-ignore
  // const swiper = document?.querySelector(".style-models")?.swiper
  // console.log(swiper.activeSlide)

  return (
    <>
      <div className="w-full style-models-div">
        <Swiper
          defaultValue={3}
          slidesPerView={slidePerView}
          spaceBetween={14}
          loop={true}
          loopedSlides={4}
          navigation={true} // 左右切换按钮
          mousewheel={true}
          modules={[Mousewheel, Navigation]}
          className={swiperName}
          observer={true} //修改swiper自己或子元素时，自动初始化swiper
          observeParents={true} //修改swiper的父元素时，自动初始化swiper
          // slidesOffsetBefore={10}
          // slidesOffsetAfter={100}
          centeredSlides={true} //设定为true时，active slide会居中，而不是默认状态下的居左。
          slideToClickedSlide={true} //设置为true则点击slide会过渡到这个slide。
          onActiveIndexChange={() => {
            getData()
          }}
        >
          {list.map((item, index) => {
            return (
              <SwiperSlide tabIndex={index} key={index}>
                <div
                  title={index + ""}
                  className={`swiper-slide-${index} flex items-end relative rounded-xl overflow-hidden shadow-lg shadow-[#d6d6d6]      `}
                >
                  <img className="object-cover" src={item.img} alt="" />
                  <div className="absolute flex items-end justify-center left-0 top-0 h-full text-center w-full bg-[#0000004e]">
                    <span className="w-full py-4 pb-5 shadow-md text-3xl font-[600]">
                      {item.title}
                    </span>
                  </div>
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </>
  )
}

export default StyleModels
