import { useState } from "react"
import MobileBottomModal from "../components/MobileBottomModal"
import ImageSize from "../components/StudioOptions/ImageSize"
import studioPagesStore from "@/store/studioPagesStore"
import sizeIcon_1_1 from "@/assets/studio/mobile/1_1.svg"
import sizeIcon_4_3 from "@/assets/studio/mobile/4_3.svg"
import sizeIcon_3_4 from "@/assets/studio/mobile/3_4.svg"
import sizeIcon_16_9 from "@/assets/studio/mobile/16_9.svg"
import sizeIcon_9_16 from "@/assets/studio/mobile/9_16.svg"
import ActionIcon from "./components/ActionIcon"
import sizeIcon from "@/assets/studio/mobile/size_m.svg"

interface RatioIconProps {
  onClick?: () => void
}
const RatioIcon = ({ onClick }: RatioIconProps) => {
  const { ratio: selectedRatio } = studioPagesStore.store.get()

  const icons = {
    "1:1": sizeIcon_1_1,
    "4:3": sizeIcon_4_3,
    "3:4": sizeIcon_3_4,
    "16:9": sizeIcon_16_9,
    "9:16": sizeIcon_9_16,
  }
  const currentIcon = icons[selectedRatio as keyof typeof icons]

  return <ActionIcon src={currentIcon} onClick={onClick} />
}

const Ratio = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  return (
    <div>
      {/* Modal */}
      {isModalVisible && (
        <MobileBottomModal
          title="图片比例"
          iconSrc={sizeIcon}
          onClose={() => {
            setIsModalVisible(false)
          }}
        >
          <ImageSize />
        </MobileBottomModal>
      )}

      {/* Ratio Icon */}
      <RatioIcon
        onClick={() => {
          setIsModalVisible(true)
        }}
      />
    </div>
  )
}

export default Ratio
