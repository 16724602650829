import { IconClose } from "@arco-design/web-react/icon"
import { isMobileOnly } from "react-device-detect"
import { ReactComponent as AddIcon } from "@/assets/add.svg"
import { ReactComponent as MinuIcon } from "@/assets/minu.svg"
import { ReactComponent as RetrunIcon } from "@/assets/return.svg"
const ConfirmModal = ({
  controlNet2Visible,
  handleClose,
  currentControlNet,
  changeControlNet,
  addOrMinus,
}: {
  controlNet2Visible: boolean
  handleClose: () => void
  currentControlNet: number
  changeControlNet: (index: number) => void
  addOrMinus: (status: string) => void
}) => {
  const controlNetList = [
    { label: "参考1", key: 1 },
    {
      label: "参考2",
      key: 2,
    },
  ]
  return (
    <div className="flex justify-between md:pl-[28px] md:pr-3 md:py-3 py-[20px] items-center px-[20px]">
      <div className="flex gap-[6px]">
        {/*  移动端返回箭头 */}
        {isMobileOnly && (
          <div className="flex justify-center items-center ">
            <RetrunIcon className="cursor-pointer" onClick={() => handleClose()} />
          </div>
        )}
        <div className=" md:text-base text-[18px] md:text-[24px]">ControlNet</div>
      </div>
      {/*  移动端头部 */}
      {isMobileOnly && (
        <div className="flex gap-[10px]">
          {controlNet2Visible && (
            <div className="flex gap-[10px]">
              {controlNetList?.map((item, index) => (
                <div
                  key={item.key}
                  onClick={() => {
                    changeControlNet(item?.key)
                  }}
                  className={`${
                    currentControlNet == item.key ? "bg-[#4C1FFF]" : "bg-[#1C1C28]"
                  } px-[10px] py-[7px] text-white rounded-[4px]`}
                >
                  {item?.label}
                </div>
              ))}
            </div>
          )}
          <div className="bg-[#4C1FFF] w-[36px] h-[36px] rounded-[4px] flex justify-center items-center">
            {controlNet2Visible ? (
              <MinuIcon
                onClick={() => {
                  addOrMinus("minus")
                }}
              />
            ) : (
              <AddIcon
                onClick={() => {
                  addOrMinus("add")
                }}
              />
            )}
          </div>
        </div>
      )}
      {/* 移动端不展示关闭 */}
      {!isMobileOnly && (
        <div className="flex text-lg justify-end items-start ">
          <IconClose className="cursor-pointer" onClick={() => handleClose()} />
        </div>
      )}
    </div>
  )
}

export default ConfirmModal
