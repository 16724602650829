import * as store from "@/store"
import { Modal } from "@arco-design/web-react"
import React, { useState } from "react"
import { Link } from "react-router-dom"

interface CheckAuthProps {
  children: React.ReactNode
  onClick?: () => void
  className?: string
  needLogin?: boolean
}
function CheckAuth({ onClick, children, needLogin, ...props }: CheckAuthProps) {
  const [show, setShow] = useState(false)
  const user = store.user.get()

  const handleClick = () => {
    if (needLogin) {
      if (!user.id) {
        setShow(true)
      } else {
        onClick?.()
      }
    } else {
      if (!user.id && user.visitorCreated) {
        setShow(true)
      } else {
        onClick?.()
      }
    }
  }
  const onCancel = () => {
    setShow(false)
  }
  return (
    <>
      <Modal
        onCancel={onCancel}
        maskClosable={false}
        visible={show}
        modalRender={() => (
          <>
            <div className="w-[400px] py-10 relative mx-auto top-0 inline-block align-middle  bg-[#212134] rounded overflow-hidden">
              <div>
                <p>登录获得更多生成次数</p>
                <p>快去登录/注册，领取更多免费生成次数</p>
                <div className="pt-8">
                  <Link to="/login?statu=login" className="bg-[#4C1FFF] rounded px-7 py-3 ">
                    <span className="">立即登录</span>
                  </Link>
                </div>
              </div>
            </div>
          </>
        )}
      />

      <div onClick={handleClick} {...props}>
        {children}
      </div>
    </>
  )
}

export default CheckAuth
