import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import * as store from "@/store"
import { Avatar } from "@/common/Avatar"
import { Grid } from "@arco-design/web-react"
import { ReactComponent as IconEdit } from "@/assets/edit-circle.svg"
import Card from "@/components/bussiness/userCenter/Card"

import "./accountSetting.less"
import { observer } from "mobx-react-lite"
import { isMobileOnly } from "react-device-detect"
import profileStore from "@/store/profileStore"

const Row = Grid.Row
const Col = Grid.Col

export default observer(function AccountSetting() {
  const { t } = useTranslation(["translation"])
  const { nick, avatar, id, subscribedProduct, phone } = store.user.get()

  const subscribeInfo = useMemo(() => {
    const subscribeType = String(subscribedProduct?.subscribeType)
    return {
      label: subscribedProduct?.title ?? "免费",
      tagLabel: subscribedProduct?.subscribeType
        ? subscribeType.match(/^3\d+/gi)
          ? "续费"
          : "升级"
        : "升级套餐无限生成",
      tagClass: subscribedProduct?.title ? "subscribe-tag-blue" : "subscribe-tag-white",
    }
  }, [subscribedProduct])

  const openProfileEditor = () => {
    profileStore.toggleProfileEditorVisible()
  }

  return (
    <Card title={t("AccountSetting")}>
      <Row
        className="user-center_account-wrapper"
        style={{
          marginBottom: 16,
          display: `${isMobileOnly ? "flex" : ""}`,
          gap: `${isMobileOnly ? "20px" : "0"}`,
        }}
        gutter={isMobileOnly ? 0 : 24}
      >
        <Col flex={isMobileOnly ? "0 0 auto" : "144px"} className="avatar-wrapper text-center">
          <Avatar className={isMobileOnly ? "avatar-mobile-img" : "avatar-img"} URL={avatar} />
          <div className="mt-3">
            <span className="avatar-text cursor-pointer align-middle" onClick={openProfileEditor}>
              编辑头像
            </span>
          </div>
        </Col>
        <Col
          flex={isMobileOnly ? "1" : "auto"}
          className={`${isMobileOnly ? "user-info-mobile-wrapper" : "user-info-wrapper"}`}
        >
          <ul className={`${isMobileOnly ? "user-info-mobile-ul" : "user-info-ul"}`}>
            <li className="user-info-item">
              <label
                className={`${
                  isMobileOnly ? "user-info-item-mobile-label" : "user-info-item-label"
                }`}
              >
                账号名称:{" "}
              </label>
              <span
                className={`${
                  isMobileOnly ? "user-info-item-mobile-value" : "user-info-item-value"
                }`}
              >
                {nick}
              </span>

              {/* Edit icon */}
              <IconEdit
                className="user-edit-icon inline-block cursor-pointer ml-2 w-4 h-4 align-middle"
                onClick={() => {
                  openProfileEditor()
                }}
              />
            </li>
            <li className="user-info-item">
              <label
                className={`${
                  isMobileOnly ? "user-info-item-mobile-label" : "user-info-item-label"
                }`}
              >
                魔法师ID:
              </label>
              <span
                className={`${
                  isMobileOnly ? "user-info-item-mobile-value" : "user-info-item-value"
                }`}
              >
                {id}
              </span>
            </li>
            <li className="user-info-item">
              <label
                className={`${
                  isMobileOnly ? "user-info-item-mobile-label" : "user-info-item-label"
                }`}
              >
                绑定手机:
              </label>
              <span
                className={`${
                  isMobileOnly ? "user-info-item-mobile-value" : "user-info-item-value"
                }`}
              >
                {phone}
              </span>
            </li>
            <li className="user-info-item">
              <label
                className={`${
                  isMobileOnly ? "user-info-item-mobile-label" : "user-info-item-label"
                }`}
              >
                当前方案:
              </label>
              <span
                className={`${
                  isMobileOnly ? "user-info-item-mobile-value" : "user-info-item-value"
                }`}
              >
                {subscribeInfo.label}
              </span>
              {!isMobileOnly && (
                <Link to="/subscription">
                  <span className={subscribeInfo.tagClass}>{subscribeInfo.tagLabel}</span>
                </Link>
              )}
            </li>
            <li className={`${isMobileOnly ? "upgrade-mobile" : "upgrade"}`}>
              {isMobileOnly && (
                <Link to="/subscription">
                  <span className={`${isMobileOnly ? "btn-mobile" : subscribeInfo.tagClass}`}>
                    {subscribeInfo.tagLabel}
                  </span>
                </Link>
              )}
            </li>
          </ul>
        </Col>
      </Row>
    </Card>
  )
})
