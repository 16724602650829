import { Message } from "@arco-design/web-react"

interface ActionIconProps {
  src: string
  onClick?: () => void
  disabled?: boolean
  disabledMessage?: string
}
const ActionIcon = ({ disabled, src, onClick, disabledMessage }: ActionIconProps) => {
  const cursorCls = disabled ? "cursor-not-allowed" : "cursor-pointer"

  return (
    <div
      className={`${cursorCls} flex items-center justify-center`}
      onClick={() => {
        if (disabled) {
          if (disabledMessage) {
            Message.warning(disabledMessage)
          }
          return
        }
        onClick?.()
      }}
    >
      <img className="w-6 h-6 object-cover" src={src} alt="" />
    </div>
  )
}

export default ActionIcon
