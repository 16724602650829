import { useTranslation } from "react-i18next"

const NoMore = () => {
  const { t } = useTranslation(["translation"])
  return (
    <section className="py-10 flex justify-center items-center">
      <span className="inline-flex items-center text-[#6F7176] text-sm before:mr-[26px] before:bg-[#C9CBD14D]/30 before:w-[138px] before:h-[1px] after:ml-[26px] after:bg-[#C9CBD14D]/30 after:w-[138px] after:h-[1px]">
        {t("worksCenter.noMore")}
      </span>
    </section>
  )
}

export default NoMore
