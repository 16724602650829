import React, { ReactNode, useEffect } from "react"
import {
  SortableContainer as sortableContainer,
  SortableElement as sortableElement,
  SortableHandle as sortableHandle,
  SortableElementProps,
  SortableContainerProps,
} from "react-sortable-hoc"
import { observer } from "mobx-react-lite"

import { ReactComponent as EditorIcon } from "@/assets/works/editor.svg"
import { ReactComponent as DrapIcon } from "@/assets/works/drap.svg"
import { V1Collection } from "@/generated/api/data-contracts"
import { useVisitStatus } from "../VisitStatusContext"
interface DragProps {
  noDragElement?: React.ReactNode
  items: V1Collection[]
  boxEleGnerator: (val: any, idx: number) => ReactNode
  handleOpen: (value: any) => void
  sortedHandler: ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => void
}
const Drag = observer(
  ({ items, boxEleGnerator, sortedHandler, handleOpen, noDragElement }: DragProps) => {
    const DragHandle = sortableHandle(() => (
      <div className="ml-[2px]">
        <DrapIcon />
      </div>
    ))
    const Box = ({ value, index }: { value: V1Collection; index: number }) => {
      const { isVisted } = useVisitStatus()
      return (
        <div>
          <div className="group relative">
            {!isVisted && value.isMine && (
              <div className="absolute bg-[#4C1FFF] rounded-[4px] top-[10px] right-[10px] p-[4px] items-center hidden group-hover:flex z-20">
                {/*  收藏的他人相册 不能编辑 */}
                <>
                  <div
                    className="mr-[2px] cursor-pointer"
                    onClick={() => {
                      handleOpen(value)
                    }}
                  >
                    <EditorIcon />
                  </div>
                  {/* <div className="h-[22px] w-[1px] bg-[#ffffff] opacity-10"> </div> */}
                </>
                {/* <div className="cursor-move">
                  <DragHandle />
                </div> */}
              </div>
            )}

            {boxEleGnerator(value, index)}
          </div>
        </div>
      )
    }

    const SortableBox: React.ComponentClass<
      SortableElementProps & {
        value: any
        index: number
      },
      any
    > = sortableElement(({ value, index }: { value: any; index: number }) => (
      <Box value={value} index={index} />
    ))

    const BoxList = ({ items = [] }) => {
      return (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-[20px]">
          {noDragElement}
          {/* 循环渲染元素 */}
          {items.map((value, index) => (
            <SortableBox key={`item-${index}`} index={index} value={value} />
          ))}
        </div>
      )
    }

    const SortableBoxList: React.ComponentClass<
      SortableContainerProps & {
        items: any[]
      },
      any
    > = sortableContainer(BoxList)
    // 指定拖拽区域的引用
    // const dragAreaRef = useRef(null)
    // 拖拽开始时的回调函数
    // const onSortStart = ({ node }: { node: HTMLElement }) => {
    //   console.log(node, dragAreaRef)
    //   // 检查是否在指定拖拽区域内
    //   if (!(dragAreaRef.current as unknown as HTMLElement)?.contains(node)) {
    //     // 不在指定区域内，取消拖拽
    //     return false
    //   }
    // }

    return (
      <div>
        <SortableBoxList
          useDragHandle
          items={items}
          onSortEnd={sortedHandler}
          axis="xy"
          // onSortStart={onSortStart}
        />
      </div>
    )
  }
)

export default Drag
