import React, { useState, useEffect } from "react"
import { ReactComponent as CarouselArrow } from "@/assets/home/carousel_right_button.svg"
import { ReactComponent as CarouselIndicator } from "@/assets/home/carousel_indicator.svg"
import { ReactComponent as CarouselIndicatorSelected } from "@/assets/home/carousel_indicator_selected.svg"
import { useNavigate } from "react-router-dom"
import { isMobileOnly } from "react-device-detect"
import { observer } from "mobx-react-lite"
import activityStore from "@/store/activityStore"

const ActivityCarousel: React.FC = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0)
  const [resetCarousel, setResetCarousel] = useState<boolean>(false) // 重置轮播时间
  const [startX, setStartX] = useState<number>(0)
  const [endX, setEndX] = useState<number>(0)
  const navigate = useNavigate()
  const { activities, carouselImages } = activityStore.store.get()

  useEffect(() => {
    if (!activities.length) {
      activityStore.getActivityList()
    }
  }, [])

  useEffect(() => {
    if (carouselImages.length) {
      const interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) =>
          prevIndex === carouselImages?.length - 1 ? 0 : prevIndex + 1
        )
      }, 5000)
      return () => clearInterval(interval)
    }
  }, [resetCarousel, carouselImages])

  const handleTouchStart = (e: React.TouchEvent) => {
    setStartX(e.touches[0].clientX)
  }

  const handleTouchMove = (e: React.TouchEvent) => {
    setEndX(e.touches[0].clientX)
  }

  const handleTouchEnd = () => {
    if (endX === 0) return // 如果 endX 为 0，不执行切换操作

    const threshold = 50 // 设置滑动触发的阈值，可根据需要调整
    const distance = startX - endX // 计算滑动距离

    if (distance > threshold) {
      // 向左滑动，切换到下一张 Carousel
      if (currentImageIndex === carouselImages.length - 1) return
      setCurrentImageIndex(currentImageIndex + 1)
    } else if (distance < -threshold) {
      // 向右滑动，切换到上一张 Carousel
      if (currentImageIndex === 0) return
      setCurrentImageIndex(currentImageIndex - 1)
    }

    // 重置
    setResetCarousel(!resetCarousel)
    setEndX(0)
    setStartX(0)
  }

  const ActivitySlide = (element: JSX.Element) => {
    return (
      <div
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        className="relative md:rounded-[4px]  md:h-[380px] h-[235px] overflow-hidden"
      >
        {element}
      </div>
    )
  }

  const Carousel = () => {
    let isDefaultActivityImage = false

    if (!activities.length) {
      isDefaultActivityImage = true
    }

    return (
      <div className="w-full md:h-[380px] h-[235px] md:mt-[60px] relative">
        {carouselImages?.map((image, index) => (
          <div
            key={index}
            className="transition-opacity duration-500 ease-in-out absolute top-0 left-0 w-full h-full"
            style={{
              opacity: index === currentImageIndex ? 1 : 0,
            }}
            onClick={() => {
              if (isDefaultActivityImage) return

              const redirectURL = activities[currentImageIndex]?.redirectURL
              if (redirectURL) {
                window.open(redirectURL, "_blank")
                return
              }

              navigate(`/activity?id=${activities[currentImageIndex]?.uid}`)
            }}
          >
            <div
              className="w-full h-full rounded"
              style={{
                cursor: isDefaultActivityImage ? "auto" : "pointer",
                backgroundImage: `url(${image})`,
                backgroundPosition: "center center",
                backgroundSize: "auto 100%",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
          </div>
        ))}

        {/* 切换选择器部分 */}
        <div className="absolute md:bottom-[30px] bottom-[10px] left-1/2 transform -translate-x-1/2 ">
          <div className="flex flex-row items-center">
            {!isMobileOnly && (
              <button
                className="opacity-50 hover:opacity-100 transition-opacity mr-4 transform -scale-x-100"
                onClick={() => {
                  setCurrentImageIndex((prevIndex) =>
                    prevIndex === 0 ? carouselImages.length - 1 : prevIndex - 1
                  )
                  setResetCarousel(!resetCarousel)
                }}
              >
                <CarouselArrow />
              </button>
            )}

            <div className="flex space-x-2">
              {carouselImages.map((_, index) => (
                <div
                  key={index}
                  onClick={() => {
                    setCurrentImageIndex(index)
                    setResetCarousel(!resetCarousel)
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {index === currentImageIndex ? (
                    <CarouselIndicatorSelected />
                  ) : (
                    <CarouselIndicator />
                  )}
                </div>
              ))}
            </div>

            {!isMobileOnly && (
              <button
                className="opacity-50 hover:opacity-100 transition-opacity ml-4"
                onClick={() => {
                  setCurrentImageIndex((prevIndex) =>
                    prevIndex === carouselImages.length - 1 ? 0 : prevIndex + 1
                  )
                  setResetCarousel(!resetCarousel)
                }}
              >
                <CarouselArrow />
              </button>
            )}
          </div>
        </div>
      </div>
    )
  }

  return isMobileOnly ? ActivitySlide(Carousel()) : Carousel()
}

export default observer(ActivityCarousel)
