import { useState, useEffect } from "react"
import { Masonry } from "masonic"

import { useDarkMode, getColumnCount, useWindowWidth } from "@/utils"
import TemplateDetailModal from "@/common/TemplateDetailModal"
import { activtiyMock } from "./activitymock"
import TemplateItem from "@/common/TemplateItem"

type ActivityContentProps = {
  callback: (i: number) => void
}

const activityItems = activtiyMock

const ActivityContent = ({ callback }: ActivityContentProps) => {
  const columnCount = getColumnCount(useWindowWidth(), true)

  return (
    <Masonry
      items={activityItems}
      render={({ index, data }) => {
        return (
          <TemplateItem
            key={data.id}
            template={data as any}
            className="group overflow-hidden h-full aspect-[1/1] items-center rounded-[4px] flex !w-full"
            callback={() => callback(index)}
          />
        )
      }}
      columnCount={columnCount}
      columnGutter={20}
    />
  )
}

const TemplateActivity: React.FC = () => {
  useDarkMode()

  const [taskIndex, setTaskIndex] = useState<number | null>(null)

  const openModal = (i: number) => {
    setTaskIndex(i)
  }

  const closeModal = () => {
    setTaskIndex(null)
  }

  const templateModalVisiable = taskIndex !== null
  return (
    <>
      <ActivityContent callback={(i: number) => openModal(i)} />
      {templateModalVisiable && (
        <TemplateDetailModal defaultTemplateIndex={taskIndex} onClose={closeModal} />
      )}
    </>
  )
}

export default TemplateActivity
