import { getLikeTasks2 } from "@/services/editor"
import worksPagesStore from "@/store/worksPagesStore"
import { Popover } from "@arco-design/web-react"
import { useEffect, useMemo, useRef, useState } from "react"
import { ReactComponent as IconPopular } from "@/assets/popular.svg"
import { ReactComponent as SearchIcon } from "@/assets/search.svg"
import { ReactComponent as DownloadImageIcon } from "@/assets/download_image.svg"
import { ReactComponent as IconCheckCircle } from "@/assets/check-circle.svg"
import { ReactComponent as IconLougout } from "@/assets/logout_dark.svg"
import { ReactComponent as DownloadIcon } from "@/assets/download.svg"
import { ReactComponent as HeartHollowIcon } from "@/assets/heart_hollow.svg"

import ModalScaleImg from "@/common/ImgDetailModal/Modal"
import * as store from "@/store"
import { isMobileOnly } from "react-device-detect"
import { Link, NavLink } from "react-router-dom"
import _ from "lodash"
import { useTranslation } from "react-i18next"
import { toastError, toastInfo, toastSuccess } from "@/common/Toast"
import { ErrorCode } from "@/i18next"
import { api } from "@/services/api"
import { featureDeprecatedAlert, getColumnCount, handleDownloadImgs, useWindowWidth } from "@/utils"
import { observer } from "mobx-react-lite"
import ButtonLoading from "@/common/ButtomLoading"
import WaterfallFlow from "@/components/common/WaterfallFlow"
import { computed } from "mobx"
import { V1Task, V1Work } from "@/generated/api/data-contracts"
import CardOne from "@/components/bussiness/work/generated/CardOne"
import WorkCover from "@/components/bussiness/work/WorkCover"
import templateStore from "@/store/templateStore"

const getlength = (ratio = "1:1") => {
  const [w = 1, h = 1] = ratio.split(":")
  return +h / +w
}
enum SortEnum {
  newest = "newest",
  popular = "popular",
  highest = "highest",
  myFav = "myFav",
}

const sortTypeList: { value: SortEnum; label: string }[] = [
  { value: SortEnum.newest, label: "最新" },
  // { value: SortEnum.highest, label: "最高分" },
  { value: SortEnum.popular, label: "最受欢迎" },
]

type PageConfigType = {
  page: number
  search: string
  sortType: SortEnum
  total: number
}

const PAGE_SIZE = 30

const WorkCollect = () => {
  const { t } = useTranslation(["translation", "errorMessage"])
  const tasks = worksPagesStore.store.get().taskList

  const selectedWorks = worksPagesStore.store.get().selectList
  const { id: currentUserId } = store.user.get()

  const [hasMore, setHasMore] = useState(true)
  const [loading, setLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [currentWork, setCurrentWork] = useState({})
  const [currentTask, setCurrentTask] = useState<V1Task>()
  const [showBatchBar, setShowBatchBar] = useState(false)
  // 分页配置
  const [pageConfig, setPageConfig] = useState<PageConfigType>({
    page: 0,
    search: "",
    sortType: SortEnum.newest,
    total: 0,
  })

  const worksCount = () => tasks?.reduce((r, task) => r + (task?.works?.length || 0), 0)

  const sortText = useMemo(() => {
    const target = sortTypeList.find((item) => item.value === pageConfig.sortType)
    if (target) {
      return target.label
    }
    return ""
  }, [pageConfig.sortType])

  const getData = async (
    page = pageConfig.page,
    search = pageConfig.search,
    sortType = pageConfig.sortType
  ) => {
    try {
      setLoading(true)
      const res = await getLikeTasks2(currentUserId, page, PAGE_SIZE, search, sortType).finally(
        () => {
          setLoading(false)
        }
      )
      if (res.success) {
        const { data } = res
        setHasMore(
          (_.get(data, ["current"], page) - 1) * PAGE_SIZE + _.get(data, ["list", "length"], 0) <
            _.get(data, ["total"], 0)
        )
        const tasks = ((data.list as V1Work[]) || []).map(
          (work) =>
            ({
              ...work.task,
              works: [work],
            } as V1Task)
        )
        if (page === 1) {
          worksPagesStore.pushTaskItems(() => tasks)
        } else {
          // 构造数据
          worksPagesStore.pushTaskItems((list) => [...list, ...tasks])
        }
        setPageConfig({
          page,
          search,
          sortType,
          total: data?.total || 0,
        })
      } else {
        setHasMore(false)
        toastError(t(`errorMessage:${res.errorCode as ErrorCode}`))
      }
    } catch (e) {
      // error
    }
  }

  const checkselectedWorksNumber = () => {
    if (selectedWorks?.length == 0) {
      toastInfo("请先选择作品")
      return false
    }
    return true
  }

  const selectAllWorks = (all: boolean) => {
    if (loading) {
      return
    }
    const newSelectedWorks: string[] = []
    if (all) {
      tasks?.forEach(({ works }) => {
        works?.forEach((w: V1Work) => {
          newSelectedWorks.push(w.id!)
        })
      })
    }

    worksPagesStore.changeSelectList(newSelectedWorks)
  }
  // 批量下载
  const downloadSelectedWorks = async () => {
    if (loading || !checkselectedWorksNumber()) {
      return
    }
    const urls: string[] = []
    tasks?.forEach(({ works }: any) => {
      works?.forEach(({ image, id }: any) => selectedWorks?.includes(id!) && urls.push(image!))
    })
    await handleDownloadImgs(urls)
    setShowBatchBar(false)
  }

  // 批量like / unlike
  const batchLikeOrUnlike = async (liked: boolean) => {
    featureDeprecatedAlert()
    // try {
    //   const { data } = await (liked
    //     ? api.batchLikeWorks({
    //         workIDs: selectedWorks || [],
    //         collectionUID: "JTQltwGbPEG3equutKwrX",
    //       })
    //     : api.batchUnlikeWorks({
    //         workIDs: selectedWorks || [],
    //         collectionUID: "JTQltwGbPEG3equutKwrX",
    //       }))

    //   if (data.success) {
    //     toastSuccess("操作成功")
    //     worksPagesStore.changeTask((tasks) => {
    //       tasks.map((task) => {
    //         const newWorks = [...(task?.works || [])]
    //         task.works?.map((work: V1Work) => {
    //           if (selectedWorks.includes(work.id ?? "")) {
    //             const index = newWorks.findIndex(({ id }) => id === work.id)
    //             newWorks.splice(index, 1)
    //           }
    //         })
    //         task.works = newWorks
    //       })
    //       return [...tasks]
    //     })

    //     cancelBatch()
    //   } else {
    //     toastError(t(`errorMessage:${data.errorCode as ErrorCode}`))
    //   }
    // } catch (e) {
    //   //
    // }
  }

  // 是否展示喜欢 选中的大于1/2的unlike，就会展示like
  const selectShowFav = () => {
    let isFavCount = 0
    tasks.map((task) => {
      task.works?.map((work: V1Work) => {
        if (work.liked && selectedWorks.includes(work?.id || "")) {
          isFavCount += 1
        }
      })
    })

    return isFavCount < selectedWorks.length / 2
  }

  const cancelBatch = () => {
    worksPagesStore.changeSelectList([])
    setShowBatchBar(false)
  }

  const likeOrUnlike = async (liked: boolean, id: string) => {
    featureDeprecatedAlert()
    // const { data } = await (liked
    //   ? api.unlikeWork(id, { collectionUID: "JTQltwGbPEG3equutKwrX" })
    //   : api.likeWork(id, { collectionUID: "JTQltwGbPEG3equutKwrX" }))
    // if (data.success) {
    //   toastSuccess("操作成功")
    //   // 展示修改store的状态
    //   worksPagesStore.changeTask((tasks) => {
    //     tasks.map((task) => {
    //       const newWorks = [...(task?.works || [])]
    //       task.works?.map((work: V1Work) => {
    //         if (work?.id === id) {
    //           const index = newWorks.findIndex(({ id }) => id === work.id)
    //           newWorks.splice(index, 1)
    //         }
    //       })
    //       task.works = newWorks
    //     })
    //     return [...tasks]
    //   })
    //   return true
    // } else {
    //   toastError(t(`errorMessage:${data.errorCode as ErrorCode}`))
    //   return false
    // }
  }

  const items = computed(() =>
    tasks.flatMap((task, taskIndex) => {
      return (
        task.works?.map((work: V1Work, workIndex: number) => ({
          taskIndex,
          workIndex,
          task,
          work,
          // onModalOpen,
          workId: work.id,
          ratio: work.ratio,
          height: getlength(work.ratio),
        })) || []
      )
    })
  )

  useEffect(() => {
    templateStore.fetchTemplates()
    return () => {
      worksPagesStore.init()
    }
  }, [])

  const columnCount = getColumnCount(useWindowWidth())
  const coverRef = useRef({ refreshCover: () => null })

  return (
    <>
      <WorkCover
        // userId={currentUserId}
        isVisitor={false}
        ref={coverRef}
        onSearch={() => {
          getData(1)
        }}
        searchValue={pageConfig.search}
        onSearchInputChange={(val) => {
          setPageConfig({
            ...pageConfig,
            search: val,
          })
        }}
      />
      <div className="w-full lg:min-w-[900px] flex justify-between items-center my-[20px]">
        {/* 顶部 */}
        <div className="lg:ml-0 ml-2 flex items-center lg:w-auto w-full  justify-between">
          <Popover
            position="bl"
            trigger={"hover"}
            color={"#212134"}
            className="work-popover-wrapper"
            content={
              <ul className="p-[5px] bg-[#212134]">
                {sortTypeList.map((item) => {
                  return (
                    <li
                      key={item.value}
                      className="cursor-pointer  text-white px-[10px] py-[5px] bg-transparent hover:bg-[#272739]"
                      onClick={() => getData(1, pageConfig.search, item.value)}
                    >
                      {item.label}
                    </li>
                  )
                })}
              </ul>
            }
          >
            <button className="h-full text-sm rounded md:bg-[#4C1FFF] py-2 px-4">
              <IconPopular className="inline-block align-middle mr-2" />
              {sortText}
            </button>
          </Popover>
          <div className="flex items-center rounded bg-[#0C0C1A] mx-5 p-1">
            <Link
              to="/works"
              className={`px-4 py-1.5 text-sm rounded text-[#ffffff80] cursor-pointer"`}
            >
              生成
            </Link>
            <NavLink
              className={`px-4 py-1.5 text-sm rounded text-[#ffffff80] cursor-pointer aria-[current]:text-white aria-[current]:bg-[#161623] aria-[current]:shadow`}
              to="/works/collect"
            >
              喜欢
            </NavLink>
          </div>
          <div className="hidden md:flex max-w-[33.125rem] lg:w-[400px] w-[300px] mx-[20px] px-5 py-1 items-center  rounded-3xl border border-[#ffffff1a] group shadow-[0_0_10px_rgba(0,0,0,0.08)] placeholder:text-[#ffffff33]">
            <SearchIcon
              className=" fill-[#fff]"
              width={18}
              height={18}
              onClick={() => getData(1)}
            ></SearchIcon>
            <input
              className="flex-1 ml-3 py-1.5 text-sm outline-none bg-transparent border-none placeholder:text-[#6F7176] peer"
              placeholder="输入关键词搜索作品"
              type="text"
              name="q"
              value={pageConfig.search}
              onChange={(e) => {
                setPageConfig({
                  ...pageConfig,
                  search: e.target.value,
                })
              }}
              onKeyDown={(e) => {
                if (e.key.toLowerCase() === "enter") {
                  getData(1)
                }
              }}
            />
          </div>
        </div>
        <div className="hidden md:block">
          {showBatchBar ? (
            <>
              <button
                className="py-2 px-3.5 w-20 text-center text-xs bg-[#111124] rounded hover:bg-[#60676f]"
                onClick={cancelBatch}
              >
                <span>退出</span>
              </button>
              <button
                className="py-2 px-3.5 w-20 text-center text-xs  bg-[#111124] hover:bg-[#60676f] rounded"
                onClick={() => selectAllWorks(worksCount() !== selectedWorks.length)}
              >
                <span>{worksCount() !== selectedWorks.length ? "全选" : "取消全部 "}</span>
              </button>
            </>
          ) : (
            <button
              className="py-2 px-3.5 flex items-center text-sm bg-[#212134] hover:bg-[#4c1fff] rounded"
              onClick={() => setShowBatchBar(true)}
            >
              <DownloadImageIcon className="mr-1" stroke="white" />
              <span>图像管理</span>
            </button>
          )}
        </div>
      </div>
      <ButtonLoading
        getData={() => {
          if (loading || !hasMore) {
            return
          }
          getData(pageConfig.page + 1)
        }}
        total={tasks.length}
        loading={loading}
        hasMore={hasMore}
      >
        <WaterfallFlow
          columnCount={columnCount || (isMobileOnly ? 2 : 5)}
          columnGutter={10}
          items={items.get() as any}
          render={({ task, work }: { task: V1Task; work: V1Work }) => {
            return (
              <div style={{ aspectRatio: work.ratio?.replace(":", "/") }}>
                <CardOne
                  hiddenConfig={{
                    publicOrPrivate: true,
                    deleteTask: true,
                  }}
                  operationHandlers={{
                    likeOrUnlike: (liked: boolean, workId = "") => {
                      likeOrUnlike(liked, workId)
                    },
                    viewBigImage: () => {
                      setModalOpen(true)
                      setCurrentWork(work)
                      setCurrentTask(task)
                    },
                  }}
                  selectList={selectedWorks}
                  onSelect={
                    !showBatchBar
                      ? undefined
                      : (list, id) => {
                          id && worksPagesStore.togglesSelect(id)
                        }
                  }
                  workId={work.id}
                  task={task}
                />
              </div>
            )
          }}
        />
      </ButtonLoading>
      {/* 批量操作区域 */}
      {showBatchBar && (
        <div className="fixed flex items-center bottom-4 left-1/2 bg-[#000000cc] px-3 py-1 text-white rounded-md -translate-x-1/2 z-[100] ">
          <button
            className="bg-[#00000000] grid justify-items-center hover:bg-[#ffffff1a] text-cente text-xs py-2 px-3 rounded "
            onClick={downloadSelectedWorks}
          >
            <div className="h-[28px] w-[28px] flex justify-center items-center">
              <DownloadIcon />
            </div>
            <span className="mt-1">下载</span>
          </button>
          <button
            className="bg-[#00000000] grid justify-items-center hover:bg-[#ffffff1a] text-cente text-xs py-2 px-3 rounded "
            onClick={() => {
              batchLikeOrUnlike(selectShowFav())
            }}
          >
            <div className="h-[28px] w-[28px] flex justify-center items-center">
              <HeartHollowIcon />
            </div>
            <span className="mt-1">{selectShowFav() ? "喜欢" : "取消喜欢"}</span>
          </button>
          <div className="w-px h-8 bg-[#FFFFFF33] mx-3"></div>
          <button
            className="bg-[#00000000] grid justify-items-center hover:bg-[#ffffff1a] text-cente text-xs py-2 px-3 rounded "
            onClick={() => {
              if (worksCount() === selectedWorks.length) {
                return
              }
              selectAllWorks(true)
            }}
          >
            <IconCheckCircle />
            <span className="mt-1">全选({selectedWorks?.length})</span>
          </button>
          <button
            className="bg-[#00000000] grid justify-items-center hover:bg-[#ffffff1a] text-cente text-xs py-2 px-3 rounded "
            onClick={cancelBatch}
          >
            <IconLougout />
            <span className="mt-1">退出</span>
          </button>
        </div>
      )}
      {currentTask && (
        <ModalScaleImg
          open={modalOpen}
          task={currentTask}
          work={currentWork}
          taskChangeDisable="both"
          onClose={() => {
            setModalOpen(false)
          }}
        />
      )}
    </>
  )
}

export default observer(WorkCollect)
