interface ToolBarButtonProps {
  icon: string
  text: string
  onClick?: () => void
}
const ToolBarButton = ({ icon, text, onClick }: ToolBarButtonProps) => {
  return (
    <div
      onClick={onClick}
      className="px-3 py-2 flex flex-col items-center gap-y-1 min-w-[64px] hover:bg-white hover:bg-opacity-10 rounded cursor-pointer"
    >
      <div className="flex items-center justify-center">
        <img src={icon} alt="" className="w-7 h-7 object-contain" />
      </div>
      <div className="text-xs font-sans">{text}</div>
    </div>
  )
}

export default ToolBarButton
