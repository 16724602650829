import { useState } from "react"
import RandomButton from "./RandomButton"
import TextBox from "./TextBox"
import GenerateButton from "./GenerateButton"
import ExpandButton from "./ExpandButton"
import collapseIcon from "@/assets/studio/mobile/collapse.svg"
import templateStore from "@/store/templateStore"
import TemplateName from "./TemplateName"
import TemplatePromptInput from "@/Studio/components/PromptBox/components/TemplatePromptInput.tsx"
import ModifyAllModal from "./ModifyAllModal"

interface RandomButtonWrapperProps {
  expandButtonVisible: boolean
  onExpand: (expanded: boolean) => void
  onRandomClick?: () => void
}
const RandomButtonWrapper = ({
  expandButtonVisible,
  onExpand,
  onRandomClick,
}: RandomButtonWrapperProps) => {
  return (
    <div className="flex flex-col items-center">
      {expandButtonVisible && (
        <ExpandButton
          onClick={() => {
            onExpand(true)
          }}
        />
      )}
      <div className="justify-self-end mt-auto">
        <RandomButton
          onClick={() => {
            onRandomClick?.()
          }}
        />
      </div>
    </div>
  )
}

interface MessageFormProps {
  onPromptChange?: (value: string) => void
  prompt?: string
  onRandomClick?: () => void
  onGenerateClick?: () => void
  templateId?: string
  onTemplateRemoveClick?: () => void
}
const MessageForm = ({
  onPromptChange,
  prompt,
  onRandomClick,
  onGenerateClick,
  templateId,
  onTemplateRemoveClick,
}: MessageFormProps) => {
  const [expanded, setExpanded] = useState(false)
  const [expandButtonVisible, setExpandButtonVisible] = useState(false)
  const [isModifyingAllTemplatePrompt, setIsModifyingAllTemplatePrompt] = useState(false)
  const template = templateStore.getTemplateById(templateId)

  const randomButtonVisible = !expanded && !templateId
  const usingTemplate = !!templateId

  return (
    <>
      {/* Modal of Modifying All Template Prompt */}
      {isModifyingAllTemplatePrompt && (
        <ModifyAllModal
          onClose={() => {
            setIsModifyingAllTemplatePrompt(false)
          }}
          value={prompt}
          onChange={(value) => {
            onPromptChange?.(value)
          }}
          onGenerateClick={() => {
            setIsModifyingAllTemplatePrompt(false)
            onGenerateClick?.()
          }}
        />
      )}

      <div>
        {/* Template Name */}
        {usingTemplate && (
          <TemplateName
            name={template?.name || ""}
            onClose={() => {
              onTemplateRemoveClick?.()
            }}
          />
        )}

        <div
          className={`
            px-2.5 py-2 bg-[#212134] justify-between z-10
            ${expanded ? "block" : "flex"}
          `}
        >
          {/* Random Button */}
          {randomButtonVisible && (
            <RandomButtonWrapper
              onRandomClick={onRandomClick}
              expandButtonVisible={expandButtonVisible}
              onExpand={setExpanded}
            />
          )}

          {/* Collapse Button */}
          {expanded && (
            <div
              className="w-[30px] h-[30px] flex items-center justify-center"
              onClick={() => {
                setExpanded(false)
              }}
            >
              <img src={collapseIcon} className="w-6 h-6" alt="collapse" />
            </div>
          )}

          <div
            className={`
              min-w-0 p-[5px] flex-1 items-center rounded-[20px]
              ${expanded ? "pt-0 mt-[5px]" : "flex bg-[#0C0C1A]"}
              ${expanded || usingTemplate ? "" : "ml-2.5"}
            `}
          >
            <div
              className={`min-w-0 flex-1 flex items-center 
              ${usingTemplate ? "p-0" : "pl-[15px]"}
            `}
            >
              {template ? (
                <TemplatePromptInput
                  prompt={template.prompt}
                  onChange={(val) => {
                    onPromptChange?.(val)
                  }}
                  onModifyAllClick={() => {
                    setIsModifyingAllTemplatePrompt(true)
                  }}
                />
              ) : (
                <TextBox
                  isExpanded={expanded}
                  onOverflowChange={(isOverflow) => {
                    setExpandButtonVisible(isOverflow)
                  }}
                  onChange={(value) => {
                    onPromptChange?.(value)
                  }}
                  value={prompt}
                />
              )}
            </div>

            {/* Generate Button */}
            <div
              className={`
                self-end h-8 flex items-center
                ${expanded ? "justify-end" : ""}
              `}
            >
              <GenerateButton
                onClick={() => {
                  onGenerateClick?.()
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MessageForm
