import { Modal } from "@arco-design/web-react"
import { ReactComponent as TitleIcon } from "@/assets/activities/contribute_title.svg"
import { isMobileOnly } from "react-device-detect"
import submitButton from "@/assets/activities/submit_button.svg"
import disabledButton from "@/assets/activities/disabled_button.svg"
import "./index.less"
import UploadImg from "./UploadImg"
import WorkInput from "./WorkInput"
import InfoTextarea from "./InfoTextarea"
import NameInput from "./NameInput"
import PhoneInput from "./PhoneInput"
import CopyRightCheckbox from "./CopyRightCheckbox"
import { RefObject, useCallback, useEffect, useRef, useState } from "react"
import { submitMission } from "@/services/activities"
import { toastError, toastSuccess } from "@/common/Toast"
interface ContributeModalProps {
  visible: boolean
  handleClose: () => void
}
const FormModal: React.FC<ContributeModalProps> = ({ visible, handleClose }) => {
  const [data, setData] = useState({
    creationName: "",
    creationInfo: "",
    username: "",
    contactInformation: "",
    copyright: false,
    imgList: [],
    activityUID: "",
  })
  const [buttonLoading, setButtonLoading] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const handleSubmit = async () => {
    let params = {
      ...data,
      uploadImages: data.imgList.toString(),
    }
    setButtonLoading(true)
    try {
      const res: any = await submitMission(params)
      if (res?.success) {
        toastSuccess("提交成功")
      } else {
        toastError("提交失败请重试")
      }
      setButtonLoading(false)
      handleClose()
    } catch (error) {
      console.error(error)
      toastError("提交失败请重试")
    }
  }
  useEffect(() => {
    if (
      data.creationName &&
      data.creationInfo &&
      data.username &&
      data.contactInformation &&
      data.copyright &&
      data.imgList.length > 0
    ) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [data])
  return (
    <Modal
      visible={visible}
      style={{
        background: "#FFF",
        width: isMobileOnly ? "100%" : "auto",
        borderRadius: isMobileOnly ? "0px" : "4px",
      }}
      footer={null}
      unmountOnExit={true}
      className={"contribute-modal"}
      onCancel={() => {
        handleClose()
        setDisabled(true)
        setData({
          creationName: "",
          creationInfo: "",
          username: "",
          contactInformation: "",
          copyright: false,
          imgList: [],
          activityUID: "",
        })
      }}
    >
      <div className="md:w-[580px] md:h-[auto] w-full h-[100vh] ">
        <div className="flex justify-center border-b-[1px] border-[#433C5F] p-[20px]">
          <TitleIcon height={32} />
        </div>
        <div className="flex flex-col gap-[10px] mt-[10px] px-[20px]">
          <UploadImg
            onChange={(imgList: any) => {
              setData({
                ...data,
                imgList,
              })
            }}
          />
          <WorkInput
            onChange={(creationName: string) => {
              setData({
                ...data,
                creationName,
              })
            }}
          />
          <InfoTextarea
            onChange={(creationInfo: string) => {
              setData({
                ...data,
                creationInfo,
              })
            }}
          />
          <NameInput
            onChange={(username: string) => {
              setData({
                ...data,
                username,
              })
            }}
          />
          <PhoneInput
            onChange={(contactInformation: string) => {
              setData({
                ...data,
                contactInformation,
              })
            }}
          />
          <CopyRightCheckbox
            onChange={(copyright) => {
              console.log(copyright, "copyright")
              setData({
                ...data,
                copyright,
              })
            }}
          />
          <div className="w-full mt-[20px] mb-[10px] flex justify-center ">
            {disabled ? (
              <img src={disabledButton}></img>
            ) : (
              <img
                src={submitButton}
                className="cursor-pointer "
                onClick={() => {
                  handleSubmit()
                }}
                aria-disabled={buttonLoading}
              ></img>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default FormModal
