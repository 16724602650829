import closeIcon from "@/assets/studio/mobile/close_m.svg"
import { useEffect, useState } from "react"

const ModalTitle = ({
  iconSrc,
  title,
  onClose,
}: {
  iconSrc?: string
  title?: string
  onClose?: () => void
}) => {
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center">
        <img src={iconSrc} alt="" className="w-[24px] h-[24px]" />
        <div className="ml-[5px] text-[#ffffff] text-opacity-80 text-[18px] leading-normal">
          {title}
        </div>
      </div>
      <img
        src={closeIcon}
        alt="Close"
        className="cursor-pointer w-[24px] h-[24px]"
        onClick={() => onClose?.()}
      />
    </div>
  )
}

interface MobileBottomModalProps {
  children: React.ReactNode
  iconSrc?: string
  title?: string
  onClose?: () => void
  titleHidden?: boolean
  modalStyle?: React.CSSProperties
  contentStyle?: React.CSSProperties
  backgroundCloseDisabled?: boolean
}

const MobileBottomModal = ({
  onClose,
  iconSrc,
  title,
  children,
  titleHidden,
  modalStyle,
  contentStyle,
  backgroundCloseDisabled,
}: MobileBottomModalProps) => {
  const [translateYCls, setTranslateYCls] = useState("translate-y-full")

  const handleBackgroundClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (backgroundCloseDisabled) return

    if (e.target === e.currentTarget) {
      onClose?.()
    }
  }

  // content slide from bottom
  useEffect(() => {
    setTimeout(() => {
      setTranslateYCls("")
    }, 0)
  }, [])

  return (
    <div
      className="fixed  top-0 left-0 w-full h-full bg-[#0c0c1a] bg-opacity-80 flex flex-col justify-end z-[10001]"
      onClick={handleBackgroundClick}
    >
      <div
        className={`${translateYCls} flex flex-col max-h-[calc(100%-64px)] transition bg-[#2E2E4A] p-5 pb-10 rounded-tr-3xl rounded-tl-3xl`}
        style={modalStyle}
      >
        {/* Modal Title */}
        {!titleHidden && <ModalTitle iconSrc={iconSrc} title={title} onClose={onClose} />}

        {/* Modal Content */}
        <div
          className={`
            ${titleHidden ? "mt-0" : "mt-5"}
            flex-grow overflow-y-auto
          `}
          style={contentStyle}
        >
          {children}
        </div>
      </div>
    </div>
  )
}

export default MobileBottomModal
