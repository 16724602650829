import { ReactComponent as RightArrowIcon } from "@/assets/studio/right_arrow.svg"
import { Popover } from "@arco-design/web-react"
import "./SelectedModel.less"
import studioPagesStore from "@/store/studioPagesStore"
import { useCallback, useEffect, useState } from "react"
import { filterControlNetModel } from "@/utils"

const PopoverContent = ({
  onClick,
  modelVersion,
  controlNetModule,
}: {
  onClick?: (value: string) => void
  modelVersion: string
  controlNetModule?: string
}) => {
  return (
    <div className="w-[120px] h-auto bg-[#161623] rounded p-[5px]">
      {filterControlNetModel(modelVersion, controlNetModule).map((item) => {
        return (
          <div
            key={item.value}
            onClick={() => {
              onClick?.(item.value)
            }}
            className="text-white text-opacity-50 text-sm leading-6 py-[5px] px-[10px] cursor-pointer hover:bg-white hover:bg-opacity-10 hover:text-opacity-100"
          >
            {item.label}
          </div>
        )
      })}
    </div>
  )
}

interface SelectedModelProps {
  modelName?: string
  onModelSelect?: (value: string) => void
  controlNetModule?: string
}
const SelectedModel = ({ modelName, onModelSelect, controlNetModule }: SelectedModelProps) => {
  const { modelVersion } = studioPagesStore.store.get()

  const [popoverVisible, setPopoverVisible] = useState(false)
  const documentClickHandler = useCallback(() => {
    setPopoverVisible(false)
  }, [])

  useEffect(() => {
    document.addEventListener("click", documentClickHandler)
    return () => {
      document.removeEventListener("click", documentClickHandler)
    }
  }, [])

  return (
    <Popover
      popupVisible={popoverVisible}
      className="selected_model_popover"
      // trigger="click"
      blurToHide
      position="rt"
      content={
        <PopoverContent
          modelVersion={modelVersion}
          controlNetModule={controlNetModule}
          onClick={(val) => {
            onModelSelect?.(val)
            setPopoverVisible(false)
          }}
        />
      }
    >
      <div
        onClick={(e) => {
          setPopoverVisible(true)
          e.nativeEvent.stopPropagation()
        }}
        className="flex items-center justify-between bg-[#0C0C1A] rounded py-1 pl-3 cursor-pointer"
      >
        <div>{modelName}</div>
        <RightArrowIcon />
      </div>
    </Popover>
  )
}

export default SelectedModel
