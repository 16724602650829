import "@arco-design/web-react/dist/css/arco.css"
import { useEffect, useState } from "react"
import { Carousel } from "@arco-design/web-react"
import TemporaryLayout from "@/components/layout/temp"
import { useDarkMode } from "@/utils"
import { api } from "@/services/api"
import ZhangjiangImage from "@/assets/images/zhangjiang-tiamat.png"

interface ListItem {
  works: workItem[]
}
interface workItem {
  image: string
  score: number
}
type ImageList = string[]

function removeDuplicate(originList: ImageList, targetList: ImageList) {
  return targetList.reduce<ImageList>((result, item) => {
    if (!originList.some((ori) => ori === item)) {
      result.push(item)
    }
    return result
  }, [])
}
function getImageFromData(list: ListItem[]) {
  return (list || []).reduce((result, item) => {
    const targetImageUrl = getMaxScoreImage(item.works)
    if (targetImageUrl) {
      result.push(targetImageUrl)
    }
    return result
  }, [] as ImageList)
}

function getMaxScoreImage(works: workItem[]) {
  return works.sort((a, b) => b.score - a.score)[0]?.image
}
let currentCarouselIndex = 0

export default function Preview({ onlyContent }: { onlyContent?: boolean }) {
  useDarkMode()
  const [imageList, setImageList] = useState<ImageList>([])

  useEffect(() => {
    getImgeList()
    const timer = setInterval(() => {
      getImgeList()
    }, 2000) // 轮询时间2s

    return () => {
      clearInterval(timer)
    }
  }, [])

  async function getImgeList() {
    try {
      const params = {
        limit: 20,
      }
      const { data } = await api.listForZhangJiangV1(params)

      if (data.success) {
        const newestImageList = getImageFromData(data.data?.list ?? [])

        addImageByIndex(newestImageList, currentCarouselIndex)
      }
    } catch (e) {
      console.error("error from api: ", e)
    }
  }
  function addImageByIndex(newestImageList: ImageList, index: number) {
    // console.log("接口已返回", index)
    setImageList((preList) => {
      const restList = removeDuplicate(preList, newestImageList)
      if (restList.length) {
        // console.log("新增数据：", restList)
        // console.log("插入到index：", index)
        return preList
          .slice(0, index + 1)
          .concat(restList)
          .concat(preList.slice(index + 1))
      }
      return preList
    })
  }

  function handleChangeCarousel(index: number) {
    currentCarouselIndex = index
  }
  const content = (
    <div className="flex justify-center items-center h-screen w-screen">
      <Carousel
        style={{ width: "auto", height: "100vh", maxWidth: "100vw" }}
        autoPlay={{
          interval: 5000,
          hoverToPause: true,
        }}
        indicatorType="never"
        showArrow="hover"
        onChange={handleChangeCarousel}
      >
        {imageList.map((src) => (
          <div key={src} className="relative">
            <img src={src} className="h-full w-auto object-contain max-w-full" />
            <div className="absolute right-[10px] bottom-0 flex items-center">
              <img src={ZhangjiangImage} width="220px" />
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  )
  return onlyContent ? (
    content
  ) : (
    <TemporaryLayout hideHeader={true} hideFooter={true}>
      {content}
    </TemporaryLayout>
  )
}
