import React, { useRef, useState } from "react"
import { toastInfo } from "@/common/Toast"

interface LongPressCopyProps {
  text: string
  children: React.ReactNode
}
function LongPressCopy({ text, children }: LongPressCopyProps) {
  const [isPressing, setIsPressing] = useState(false)
  let pressTimer = useRef<any>(null)
  const handleMouseDown = () => {
    pressTimer.current = setTimeout(() => {
      setIsPressing(true)
      copyText()
      toastInfo("taskId已复制")
    }, 1000) // 设置长按的时间，这里是1秒
  }
  const handleMouseUp = () => {
    clearTimeout(pressTimer.current)
    setIsPressing(false)
  }

  const copyText = () => {
    const textArea = document.createElement("textarea")
    textArea.value = text
    document.body.appendChild(textArea)
    textArea.select()
    document.execCommand("copy")
    document.body.removeChild(textArea)
  }

  return (
    <span
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      className="flex justify-center items-center flex-col"
    >
      {children}
    </span>
  )
}

export default LongPressCopy
