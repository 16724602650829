import Editor from "@/Studio/Editor"
import TemporaryLayout from "@/components/layout/temp"
import { useDarkMode } from "@/utils"

function StudioTemp() {
  useDarkMode()
  return (
    <TemporaryLayout>
      <Editor></Editor>
    </TemporaryLayout>
  )
}

export default StudioTemp
