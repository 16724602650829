import { useState, useMemo, useEffect } from "react"

import { ReactComponent as CheckSuccess } from "@/assets/check-success.svg"
import { ReactComponent as WorksLoadingIcon } from "@/assets/works-loading.svg"
import { ReactComponent as CopyIcon } from "@/assets/copy2.svg"
import { ReactComponent as RightIcon } from "../assets/right-black-inactive.svg"
import { ReactComponent as HeatIcon } from "@/assets/heat.svg"
import { ReactComponent as CloseIcon } from "@/assets/close2.svg"
import DefaultAvatarSvg from "@/assets/login_default_avatar.svg"

import { V1TemplateCategoryMeta } from "@/generated/api/data-contracts"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { Modal } from "@arco-design/web-react"
import { toastInfo } from "@/common/Toast"
import { getImageURL, isPortraitWork } from "@/utils"
import { isValidJSON } from "@/utils/validate"
import { MODELS_MAP, OTHER_MODEL, CONTROLNETMODELLIST, V5_MODELS } from "@/constants"

import { runInAction, toJS } from "mobx"
import { observer } from "mobx-react-lite"
import { useLocation, useNavigate } from "react-router-dom"
import { isMobileOnly } from "react-device-detect"
import { Carousel } from "@arco-design/web-react"
import templateStore from "@/store/templateStore"
import { V1TemplateData, V1ControlNetSettings } from "@/generated/api/data-contracts"
import { getUserPath } from "@/router/constants"

export type TaskDisableType = "prev" | "next" | "none" | "both"
interface PromptItem {
  label: string
  editable: boolean
}

export default observer(function ({
  onClose,
  defaultTemplateIndex = 0, // 默认展示模版
  onConfirm,
  templatesCenter,
}: {
  onClose: () => void
  defaultTemplateIndex?: number
  onConfirm?: () => void
  templatesCenter?: V1TemplateData[]
}) {
  const navigate = useNavigate()
  const location = useLocation()

  // Get templates & templateCategories from store
  const { templates, templateCategories } = templateStore.store.get()

  const templateModalData = templatesCenter || templates

  const [selectedTemplateIndex, setSelectedTemplateIndex] = useState(defaultTemplateIndex)
  const templateData = templateModalData[selectedTemplateIndex]

  // Visibility of prev/next button
  const taskDisableType = useMemo(() => {
    if (selectedTemplateIndex === 0) return "prev"
    if (selectedTemplateIndex === templateModalData.length - 1) return "next"
    if (templateModalData.length === 1) return "both"
    return "none"
  }, [selectedTemplateIndex, templateModalData])

  // Reset selected template index when default template index changed
  useEffect(() => {
    setSelectedTemplateIndex(defaultTemplateIndex)
  }, [defaultTemplateIndex])

  // Handle prev/next button click
  const handleNext = () => {
    setSelectedWorkIndex(0)
    setSelectedTemplateIndex(selectedTemplateIndex + 1)
  }
  const handlePrev = () => {
    setSelectedWorkIndex(0)
    setSelectedTemplateIndex(selectedTemplateIndex - 1)
  }

  const [selectedWorkIndex, setSelectedWorkIndex] = useState(0)
  const [loadedImgList, setLoadedImgList] = useState<string[]>()
  const [startX, setStartX] = useState<number>(0)
  const [endX, setEndX] = useState<number>(0)

  const modelExample = templateData?.exampleImages || [] // 模版示例

  const currentModel = modelExample?.[selectedWorkIndex] || "" //当前选择模版

  const currentModelSize = templateData?.aspectRatio
  const modelExampleLength = modelExample?.length || 0
  const lastModelExampleIndex = modelExampleLength - 1

  const model = MODELS_MAP.get(templateData?.model || "")

  //描述字段json格式化
  const promptFormat = (str?: string) => {
    if (str && isValidJSON(str)) {
      const data = JSON.parse(str)
      return data?.map((item: PromptItem) => item?.label).join(", ")
    } else {
      return str
    }
  }

  // 模版类型
  const templateCategory = useMemo(() => {
    if (!templateData?.category || templateCategories.length === 0) return ""

    const mateCategory = templateCategories.find(
      (item: V1TemplateCategoryMeta) => item.category === templateData?.category
    )

    return mateCategory?.displayName || ""
  }, [templateCategories, templateData])

  const TemplateImg = () => {
    return (
      <div className="relative">
        <section
          className={`relative ${(() => {
            return "md:w-[700px] md:h-[700px]"
          })()}`}
        >
          <img
            className={`${(() => {
              if (!currentModelSize) return "w-full"
              if (isPortraitWork(currentModelSize)) {
                return "w-auto"
              } else {
                return "w-full"
              }
            })()} h-full rounded`}
            style={{
              visibility:
                currentModel && loadedImgList?.includes(currentModel) ? "visible" : "hidden",
              objectFit: "contain",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            onLoad={(e) => {
              currentModel && setLoadedImgList([currentModel].concat(loadedImgList || []))
              // FIXME 接口清理脏数据后可以移除
              // 接口返回的 size 和 ratio 可能与实际图片信息不一致，导致显示比例不正确
              // https://github.com/TiamatAI/xica-docs/issues/235#issuecomment-1495304268
              runInAction(() => {
                const w = (e.target as HTMLImageElement).naturalWidth
                const h = (e.target as HTMLImageElement).naturalHeight
                // current.size = `${w}x${h}`
                // 这里ratio导致再次生成比例错误 + 代码暂未用到，所以注释了
                // current.ratio = `1:${(h / w).toFixed(2)}`
              })
            }}
            src={getImageURL(currentModel, true)}
            alt=""
          />
        </section>
        <div
          className="absolute z-10 top-0  bg-white right-0 h-full w-full flex flex-col justify-center items-center"
          style={{
            display: currentModel && loadedImgList?.includes(currentModel) ? "none" : "flex",
          }}
        >
          <WorksLoadingIcon />
          <p className="text-xl leading-[31px] mt-[4px] text-[#000000]">作品加载中～</p>
        </div>
      </div>
    )
  }

  // 模版轮播(Mobile)

  const TemplateCarousel = () => {
    const handleTouchStart = (e: React.TouchEvent) => {
      setStartX(e.touches[0].clientX)
    }

    const handleTouchMove = (e: React.TouchEvent) => {
      setEndX(e.touches[0].clientX)
    }

    const handleTouchEnd = () => {
      if (endX === 0) return // 如果endX为0，不执行切换操作

      const threshold = 50 // 设置滑动触发的阈值，可根据需要调整
      const distance = startX - endX // 计算滑动距离

      if (distance > threshold) {
        // 向左滑动，切换到下一张 Carousel
        if (selectedWorkIndex === modelExampleLength - 1) return
        setSelectedWorkIndex(selectedWorkIndex + 1)
      } else if (distance < -threshold) {
        // 向右滑动，切换到上一张 Carousel
        if (selectedWorkIndex === 0) return
        setSelectedWorkIndex(selectedWorkIndex - 1)
      }

      // 重置
      setEndX(0)
      setStartX(0)
    }

    return (
      <div
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        style={{
          width: "100%",
          height: "300px",
        }}
      >
        <Carousel
          showArrow="never"
          currentIndex={selectedWorkIndex}
          className="w-full h-[300px]"
          indicatorClassName="!px-2 !py-1 border-20 flex bg-opacity-30 rounded-[20px] bg-black pointer-events-none"
        >
          {templateData?.exampleImages?.map((image, i) => (
            <img
              key={image}
              src={getImageURL(image, true)}
              className={`transition-opacity duration-300 ${
                i === selectedWorkIndex ? "opacity-100" : "opacity-0"
              }`}
              style={{
                objectFit: "contain",
              }}
            />
          ))}
        </Carousel>
      </div>
    )
  }

  const [useExampleImage, setUseExampleImage] = useState(false)

  const handleConfirmClick = () => {
    const params = new URLSearchParams(location.search)

    // Query Field: templateId
    if (templateData?.id) {
      params.set("templateId", String(templateData?.id))
    }

    // Query Field: referenceImage
    if (useExampleImage) {
      params.set("referenceImage", currentModel)
    }

    // Go to Studio
    const targetUrl = `/studio?${params.toString()}`
    navigate(targetUrl)

    onConfirm?.()

    // Close Modal
    onClose()
  }

  interface controlNetObjType {
    controlNet1: number
    controlNet2: number
  }

  type controlNetObjKey = keyof controlNetObjType

  const controlNetLabel = (type: controlNetObjKey, value?: V1ControlNetSettings) => {
    const controlNetData = value?.units
    if (!value || !controlNetData) return false

    const controlNetObjMap: controlNetObjType = {
      controlNet1: 0,
      controlNet2: 1,
    }

    const index = controlNetObjMap[type]
    return (
      CONTROLNETMODELLIST.find((item) => item.value === controlNetData?.[index]?.module)?.label ||
      ""
    )
  }
  const linkToVisitor = (id: string) => {
    navigate(getUserPath(id))
  }
  return (
    <Modal
      visible
      footer={null}
      closeIcon={null}
      maskStyle={{
        background: "rgba(10, 9, 9, 0.9)",
        backdropFilter: "blur(5px)",
      }}
      onCancel={() => onClose()}
      maskClosable
      style={{
        width: isMobileOnly ? "100%" : "auto",
        padding: isMobileOnly ? "0px" : "6px 10px 6px 10px",
        backgroundColor: "#0c0c18",
      }}
    >
      <div className=" bg-white dark:bg-[#0c0c18]  rounded relative">
        <div className="flex lg:flex-row  flex-col gap-[16px]">
          <section className="flex -mx-5 -mt-6 sm:mx-0 sm:mt-0 justify-center">
            <section className="flex items-center ">
              {isMobileOnly ? TemplateCarousel() : TemplateImg()}
            </section>
          </section>
          <div
            className={`${
              isMobileOnly ? "" : "w-[338px] sm:w-[460px]"
            }  flex-shrink-0 dark:text-white`}
          >
            {/* 模版名称 */}
            <div className="flex items-end">
              <span className="font-sans font-bold leading-9 text-center text-2xl truncate">
                {templateData?.name}
              </span>
            </div>
            <div
              className="flex justify-between items-center pt-5 pb-1 cursor-pointer"
              onClick={() => linkToVisitor(templateData?.userUID || "")}
            >
              <div className="flex items-center">
                <img
                  className="h-8 w-8 border border-[#FFFFFF33] rounded-full object-contain"
                  src={templateData?.userAvatar ? templateData?.userAvatar : DefaultAvatarSvg}
                />
                <label className="pl-2 text-sm cursor-pointer">
                  {templateData?.userNickName && templateData?.userNickName.length > 10
                    ? templateData?.userNickName?.slice(0, 10) + "..."
                    : templateData?.userNickName}
                </label>
              </div>

              <div className="flex">
                <div className="flex  h-[38px] py-[5px] px-[8px] rounded-[4px] sm:bg-[#0000004D]  ">
                  <div className="flex justify-between w-full">
                    <div className="flex items-center">
                      <HeatIcon className="h-[18px] w-[18px]" />
                    </div>
                    <div className="flex ml-3 items-center font-sans text-[14px] font-normal leading-[19px] tracking-normal">
                      {templateData?.heatValue}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* 模型信息 */}
            <section className="mt-[10px] bg-white dark:bg-[#161623] px-3 py-2 rounded flex ">
              <section className="w-[30%]">
                <section className="text-sm text-[#FFFFFF80] leading-[20px]">
                  <span className="text-xs  leading-[20px]">模型</span>
                </section>
                <section className="lg:mt-[4px] text-sm text-[#101010] dark:text-white leading-[20px]">
                  <span className="text-sm  leading-[20px]">{model?.name || OTHER_MODEL}</span>
                </section>
              </section>
              <section className="w-[14%]">
                <section className="text-xs text-[#FFFFFF80] leading-[20px]">图片比例</section>
                <section className="lg:mt-[4px] text-sm text-[#101010] dark:text-white leading-[20px]">
                  {templateData?.aspectRatio}
                </section>
              </section>
              {templateCategory && (
                <section className="w-[18%]">
                  <section className="text-xs text-[#FFFFFF80] leading-[20px]">模版类型</section>
                  <section className="lg:mt-[4px] text-sm text-[#101010] dark:text-white leading-[20px]">
                    {templateCategory}
                  </section>
                </section>
              )}

              {controlNetLabel("controlNet1", templateData?.controlNet) && (
                <section className="w-[22%]">
                  <section className="text-xs text-[#FFFFFF80] leading-[20px]">controlNet1</section>
                  <section className="lg:mt-[4px] text-sm text-[#101010] dark:text-white leading-[20px]">
                    {controlNetLabel("controlNet1", templateData?.controlNet)}
                  </section>
                </section>
              )}
              {controlNetLabel("controlNet2", templateData?.controlNet) && (
                <section className="w-[22%]">
                  <section className="text-xs text-[#FFFFFF80] leading-[20px]">controlNet2</section>
                  <section className="lg:mt-[4px] text-sm text-[#101010] dark:text-white leading-[20px]">
                    {controlNetLabel("controlNet2", templateData?.controlNet)}
                  </section>
                </section>
              )}
            </section>
            {/* 图片描述 */}
            <>
              <section className="mt-[12px] lg:h-max-[254px] overflow-hidden">
                <section className="relative pt-[12px] pb-[48px] lg:h-max-[224px] rounded-b text-sm bg-[#f6f8f9] dark:bg-[#161623] rounded aria-disabled:backdrop-blur-sm">
                  <section className="h-full px-[12px] overflow-y-auto dark:text-white">
                    <section>图片描述：</section>
                    <section className="text-[#FFFFFFCC] text-six-line">
                      {promptFormat(templateData?.prompt) || "暂无描述"}
                    </section>
                  </section>
                  <CopyToClipboard
                    text={promptFormat(templateData?.prompt) || ""}
                    onCopy={() => {
                      toastInfo("已复制")
                    }}
                  >
                    <button className="absolute bottom-[8px] right-[8px] py-[2px] px-[2px] flex items-center bg-transparent hover:bg-[#ffffff1a] rounded">
                      <CopyIcon />
                    </button>
                  </CopyToClipboard>
                </section>
              </section>
              <section className="mt-[10px] mx-auto w-full px-3 py-2 bg-white dark:bg-[#161623] dark:text-[#FFFFFF80] rounded overflow-hidden">
                <section> 介绍内容：</section>
                <section className="text-three-line">{templateData?.intro}</section>
              </section>
              {templateData?.negativePrompt && templateData?.negativePrompt !== "[]" ? (
                <section className="mt-[10px] mx-auto w-full px-3 py-2 bg-white dark:bg-[#161623] dark:text-[#FFFFFF80] rounded overflow-hidden">
                  <section> 不需要元素：</section>
                  <section className="text-three-line">
                    {promptFormat(templateData?.negativePrompt)}
                  </section>
                </section>
              ) : null}
            </>
            {/* 查看大图小头像部分 */}
            {/* 最多展示四个小图宽度为 212px = (50px x 4) + (4px x 3) */}
            {/* 模型示例 */}
            {templateData?.exampleImages?.length! > 1 && (
              <section className="mt-[10px] mx-auto w-full px-3 py-2 bg-white dark:bg-[#161623] rounded overflow-hidden">
                <div className="font-sans text-sm font-normal leading-[22px] tracking-normal text-left mb-2">
                  模板示例
                </div>
                <section
                  className="flex gap-[12px] transition-transform transform-gpu"
                  style={{
                    transform: `translateX(${(() => {
                      // 超过 4 图时的偏移逻辑： https://github.com/TiamatAI/xica-docs/issues/91#issuecomment-1481324991
                      if (modelExampleLength <= 4) return 0
                      /** 一个缩略图的宽度加上间隙宽度 */
                      const d = 54
                      /** 选中最后两张缩略图的偏移量 */
                      if (lastModelExampleIndex - selectedWorkIndex < 2)
                        return -((modelExampleLength - 4) * d)
                      /** 选中超过第二张缩略图的偏移量 */
                      if (selectedWorkIndex > 1) return -((selectedWorkIndex - 1) * d)
                      return 0
                    })()}px)`,
                  }}
                >
                  {templateData?.exampleImages?.map((item, i) => (
                    <button
                      className={`flex-shrink-0 bg-black group space-x-3 rounded
                    ${selectedWorkIndex === i ? "border-main-color" : ""}
                  `}
                      key={item}
                      aria-checked={selectedWorkIndex === i}
                      onClick={() => {
                        setSelectedWorkIndex(i)
                      }}
                    >
                      <img
                        className="h-[50px] w-[50px] object-cover rounded opacity-40 group-aria-checked:opacity-100"
                        src={getImageURL(item, true)}
                      />
                    </button>
                  ))}
                </section>
              </section>
            )}
            {/* 审核未通过原因 */}
            {templateData?.reviewState === "reject" && (
              <section className="mt-[10px] bg-white dark:bg-[#161623] px-3 py-2 rounded flex flex-col">
                <section className="text-sm text-[#FFFFFF80] leading-[20px]">
                  <span className="text-xs  leading-[20px]">审核未通过原因</span>
                </section>
                {templateData?.reviewRejectReason}
              </section>
            )}
            {!V5_MODELS.includes(templateData?.model || "") && (
              <div className="flex items-center gap-1 pt-5">
                <div className="flex">
                  <input
                    className="hidden peer"
                    type="checkbox"
                    id={`work-check-${selectedWorkIndex}`}
                    checked={useExampleImage}
                    onChange={(e) => {
                      setUseExampleImage(e.target.checked)
                    }}
                  />
                  <label
                    htmlFor={`work-check-${selectedWorkIndex}`}
                    className="w-3.5 h-3.5 items-center justify-center cursor-pointer bg-[#1C1C28] rounded-sm  hidden peer-checked:flex"
                  >
                    <CheckSuccess />
                  </label>
                  <label
                    htmlFor={`work-check-${selectedWorkIndex}`}
                    className="w-3.5 h-3.5 cursor-pointer bg-[#1C1C28] rounded-sm block peer-checked:hidden "
                  ></label>
                </div>
                <label htmlFor={`work-check-${selectedWorkIndex}`} className="cursor-pointer">
                  <div className="font-sans text-sm font-normal leading-6 tracking-normal text-left">
                    使用示例图片作为参考图
                  </div>
                </label>
              </div>
            )}
            <div className="flex justify-center mt-12">
              {templateData?.reviewState === "pass" && (
                <button
                  className="fixed  sm:mt-16  sm:static  sm:w-auto  sm:left-0  sm:translate-x-0  left-1/2 transform w-[calc(100%-40px)] bottom-0 mb-7 -translate-x-1/2 whitespace-nowrap px-[55px] py-2.5  text-white bg-[#4c1fff] rounded disabled:brightness-50 hover:brightness-125 transition-[filter] text-sm font-sans font-normal leading-4 text-center "
                  onClick={handleConfirmClick}
                >
                  一键试用
                </button>
              )}
            </div>
          </div>
        </div>

        {taskDisableType === "prev" || taskDisableType === "both" ? null : (
          <button
            className="lg:flex hidden absolute -left-20 bottom-1/2  translate-y-4 mr-[8px] flex-shrink-0 w-[38px] h-[38px] justify-center items-center hover:bg-[#FFFFFF1A] rounded"
            onClick={handlePrev}
          >
            <RightIcon className="rotate-180" />
          </button>
        )}
        {taskDisableType === "next" || taskDisableType === "both" ? null : (
          <button
            className="lg:flex hidden absolute -right-20 bottom-1/2 translate-y-4 ml-[8px] flex-shrink-0 w-[38px] h-[38px] justify-center items-center hover:bg-[#FFFFFF1A] rounded"
            onClick={handleNext}
          >
            <RightIcon />
          </button>
        )}
        <button
          className="absolute z-10 lg:-right-6 -right-5 -top-7  hover:brightness-125"
          onClick={onClose}
        >
          <CloseIcon className="w-[35px] h-[35px]" />
        </button>
      </div>
    </Modal>
  )
})
