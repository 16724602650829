import { Upload } from "@arco-design/web-react"
import { ReactNode, useState } from "react"
import { toastError } from "@/common/Toast"
import { HttpResponse } from "@/generated/api/http-client"
import { ResponseResponseWrapper } from "@/generated/api/data-contracts"
import { LogicError } from "@/types"
import { useTranslation } from "react-i18next"
import { ReactComponent as IconLoading } from "@/assets/loading.svg"

interface Params {
  file: File
}

interface Props {
  accept?: string
  uploadBtn?: ReactNode
  useDefaultLoading?: boolean
  disabled?: boolean
  onUploadFailed?: () => void
  onUploadProgress?: () => void
  onUploadSucess?: (url: string) => void
  requstPromise: (params: Params) => Promise<
    HttpResponse<
      ResponseResponseWrapper & {
        data?:
          | {
              success: boolean
              data: {
                fileUrl: string
              }
            }
          | undefined
      },
      any
    >
  >
}
export default function UploadImageWithCropper({
  uploadBtn,
  accept,
  disabled = false,
  requstPromise,
  onUploadSucess,
  onUploadProgress,
  onUploadFailed,
  useDefaultLoading = true,
}: Props) {
  const { t } = useTranslation(["translation", "errorMessage"])

  const [loadingUpload, setLoadingUpload] = useState(false)

  async function handleUploadFile(file: File) {
    if (!file) {
      return
    }

    onUploadProgress && onUploadProgress()
    const fd = new FormData()
    fd.append("file", file)
    try {
      setLoadingUpload(true)
      const { data } = await requstPromise({ file })
      if (data.success && data.data.fileUrl) {
        onUploadSucess && onUploadSucess(data.data.fileUrl)
      } else {
        toastError("上传失败，请稍后再试或更换图片")
        onUploadFailed && onUploadFailed()
      }
    } catch (error) {
      if (error instanceof LogicError) {
        toastError(t(`errorMessage:${error.code}` as any))
      }
      onUploadFailed && onUploadFailed()
    } finally {
      setLoadingUpload(false)
    }
  }

  const uploadAccept = accept ?? "image/jpg,image/png,image/jpeg"

  return (
    <Upload
      showUploadList={false}
      accept={uploadAccept}
      disabled={disabled}
      customRequest={(options) => handleUploadFile(options.file)}
    >
      {useDefaultLoading && loadingUpload ? (
        <IconLoading className="ml-2 inline-block align-middle animate-spin" />
      ) : (
        uploadBtn ?? <button>上传图片</button>
      )}
    </Upload>
  )
}
