import WorkCover from "@/components/bussiness/visitor/WorkCover"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { VisitorCreate } from "@/components/bussiness/visitor/VisitorCreate"
import { VisitorFav } from "@/components/bussiness/visitor/VisitorFav"
import LayoutGeneral from "@/components/layout/general"
export default function Visitor() {
  const { userId } = useParams()
  const [activeTab, setActiveTab] = useState("create")
  const [search, setSearch] = useState("")
  useEffect(() => {
    setSearch("")
  }, [activeTab])
  return (
    <LayoutGeneral hasHeader={true}>
      {userId && (
        <>
          <WorkCover
            userId={userId}
            isVisitor={true}
            handleSearch={(val) => {
              setSearch(val)
            }}
            activeTab={activeTab}
          />
          <div className="mx-auto md:px-[30px] max-w-[1920px]">
            {activeTab === "create" ? (
              <VisitorCreate userId={userId} setActiveTab={setActiveTab} search={search} />
            ) : (
              <VisitorFav userId={userId} setActiveTab={setActiveTab} search={search}></VisitorFav>
            )}
          </div>
        </>
      )}
    </LayoutGeneral>
  )
}
