import { useEffect, useMemo, useState } from "react"
import { ReactComponent as CloseIcon } from "@/assets/works/close.svg"
import "./index.less"
import { Form, Input, Modal } from "@arco-design/web-react"
import { V1Collection, V1CollectionWork } from "@/generated/api/data-contracts"
import { observer } from "mobx-react-lite"
import { isMobileOnly } from "react-device-detect"
import PrivacyButton from "@/Studio/components/PromptBox/Actions/PrivacyButton"
import { useNavigate } from "react-router-dom"
import ImageModal from "./imageModal"
import { ReactComponent as LoadingPuffIcon } from "@/assets/loading_puff.svg"
import { getImageURL } from "@/utils"
import { albumIsMiss, defaultCoverImg } from "@/constants"
export interface categories {
  label: string
  value: string
}
export interface EditorAlbumParams {
  title: string
  isPrivate: boolean
  coverImg?: string
}
/**
 *  公开不公开切换
 *  编辑相册弹窗
 *
 */
export default observer(function ({
  visible,
  handleSubmit,
  handleDelete,
  initData,
  handleClose,
  loading,
  isVip,
}: {
  initData: V1Collection
  handleSubmit: (data?: EditorAlbumParams, isEditor?: boolean) => void
  handleDelete: (collectionUIDs: Array<string>) => void
  handleClose: () => void
  visible: boolean
  isVip: boolean
  loading: boolean
}) {
  const FormItem = Form.Item
  const navigator = useNavigate()
  const [form] = Form.useForm()
  const { coverImage, works = [], title, uid, isDefault, isDeleted, isMine } = initData
  const [isPrivate, setIsPrivate] = useState<boolean>(false)
  const [showLoading, setShowLoading] = useState<boolean>(false)
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  // true 为编辑   false 创建
  const isEditor = Object.keys(initData)?.length > 0
  const submit = async (coverImage?: string) => {
    // 校验
    await form.validate().then((res) => {
      if (coverImage) {
        res = { ...res, coverImage }
      }
      handleSubmit({ ...res, isPrivate }, isEditor)
    })
  }
  const coverImg = useMemo(() => {
    // 被删除 和 不是自己的私有相册
    if (isDeleted || (isPrivate && !isMine)) {
      return albumIsMiss
    } else if (coverImage) {
      return coverImage
    } else if (works?.[0]) {
      let imgList = works?.filter((item) => item.image).slice(0, 1) || []
      return imgList[0]?.image ? imgList[0]?.image : ""
      // } else {
      //   let imgList = works?.filter((item) => item.image).slice(0, 1) || []
    } else {
      return ""
    }
  }, [works])
  const previewImages = useMemo(() => {
    // 展示公开的前三张图片
    let arr: any = []
    arr = works?.filter((item) => item?.image).slice(0, 3) || []
    while (arr.length < 3) {
      arr.push({ image: "" })
    }
    return arr
  }, [works])
  const hasCanUseImg = works?.filter((item) => item?.image).length > 0
  const Loader = () => {
    return (
      <div className="flex items-center justify-center w-full h-full z-30 absolute top-0 left-0 bg-[#2E2E4A] bg-opacity-70">
        <LoadingPuffIcon />
      </div>
    )
  }
  useEffect(() => {
    form.setFieldsValue({
      title,
    })
    setIsPrivate(initData?.isPrivate || false)
  }, [initData])

  useEffect(() => {
    setShowLoading(loading)
  }, [loading])
  return (
    <>
      <Modal
        visible={visible}
        onOk={() => handleSubmit()}
        onCancel={() => handleClose()}
        className="albumn-editor-modal"
        autoFocus={false}
        unmountOnExit={true}
        footer={null}
        focusLock={true}
        maskStyle={{ backgroundColor: "rgba(0,0,0,0.8)" }}
        style={{
          width: isMobileOnly ? "100%" : "auto",
          padding: "0",
          backgroundColor: "#212134",
          borderRadius: "4px",
          zIndex: 2,
        }}
        closeIcon={null}
      >
        {!isEditor && (
          <div className="flex text-white text-[24px] py-[10px] px-[40px]">创建相册</div>
        )}
        <CloseIcon
          style={{ position: "absolute", right: "10px", top: "10px", cursor: "pointer" }}
          onClick={() => handleClose()}
        />
        <div className="bg-[#212134] flex rounded-[4px]">
          {/*  图片 */}
          {isEditor && (
            <div className="w-[270px]">
              <div className="w-full overflow-hidden rounded-t-[5px]">
                {coverImg ? (
                  <img
                    className="w-full aspect-[1/1] rounded-t-[5px] object-cover group-hover:scale-[1.2] transition-transform transform-gpuansform duration-700 z-9"
                    src={getImageURL(coverImg)}
                  ></img>
                ) : (
                  <div className="w-full aspect-[1/1] bg-[#1C1C28]"></div>
                )}

                {hasCanUseImg && (
                  <div
                    className="absolute w-[104px] h-[42px] bottom-[110px] left-[85px] bg-[#ffffff] bg-opacity-80 z-10 rounded-[4px] flex justify-center items-center text-[16px] cursor-pointer"
                    onClick={() => {
                      setModalVisible(true)
                    }}
                  >
                    选择封面
                  </div>
                )}
              </div>
              <div className="w-full flex gap-[3.5%] mt-[3px] aspect-[3/1]">
                {previewImages?.map((work: V1CollectionWork, index: number) => (
                  <>
                    {work?.image ? (
                      <img
                        src={getImageURL(work.image)}
                        key={index}
                        className={`w-[31%] aspect-[1/1] object-cover ${
                          index === 0 ? "rounded-bl-[5px]" : index === 1 ? "" : "rounded-br-[5px]"
                        } `}
                      ></img>
                    ) : (
                      <div className="w-[31%] aspect-[1/1] bg-[#1C1C28]"></div>
                    )}
                  </>
                ))}
              </div>
            </div>
          )}
          {/* 表单 */}
          <div
            className={`px-[30px]  rounded-[4px] w-[350px] ${
              isEditor ? "py-[40px] " : "py-[0px] px-[40px]"
            }`}
          >
            <Form
              autoComplete="off"
              form={form}
              layout={"vertical"}
              colon={false}
              className="mb-[10px]"
            >
              <FormItem
                label={
                  <div className="flex justify-between w-full items-center">
                    <div>
                      <span className="text-gray-400">相册名称</span>
                      <span className="text-[12px]">(相册名称不能超过五个字符)</span>
                    </div>
                    {/*  默认相册不能删除 */}
                    {!isDefault && isEditor && (
                      <div
                        className="text-[#E93247] text-[12px] hover:underline cursor-pointer"
                        onClick={() => handleDelete([uid || ""])}
                      >
                        删除相册
                      </div>
                    )}
                  </div>
                }
                field="title"
                requiredSymbol={false}
                // help="(相册名称不能超过五个字符)"
                rules={[
                  { required: true, message: "请输入相册名称" },
                  { minLength: 2, message: "相册名称能少于2个字符" },
                ]}
              >
                <Input placeholder="请输入相册名称" maxLength={5} />
              </FormItem>
            </Form>

            <div className="text-gray-400 mb-[10px]">
              设为私有相册(该功能仅对
              <span
                className="text-[#6663FF] underline cursor-pointer"
                onClick={() => {
                  navigator("/subscription")
                }}
              >
                订阅会员
              </span>
              开放){" "}
            </div>

            <PrivacyButton
              onClick={() => {
                setIsPrivate(!isPrivate)
              }}
              isPrivate={isPrivate}
              disabled={!isVip}
            />
            <div
              className={`w-[100%] flex gap-[20px] ${
                isEditor ? " mt-[70px]" : "mb-[20px]  mt-[50px]"
              }`}
            >
              <div
                className="flex-1 rounded-[4px] border-[1px] border-[white] text-[white] flex justify-center py-[4px] cursor-pointer"
                onClick={() => handleClose()}
              >
                取消
              </div>
              <div
                className="flex-1 rounded-[4px] bg-[#4C1FFF] text-[white]  flex justify-center py-[4px] cursor-pointer"
                onClick={() => submit()}
              >
                确定
              </div>
            </div>
          </div>
        </div>
        {showLoading && <Loader />}
      </Modal>
      {modalVisible && (
        <ImageModal
          uid={uid || ""}
          handleSumit={function (imageUrl): void {
            submit(imageUrl)
            setModalVisible(false)
          }}
          handleClose={() => {
            setModalVisible(false)
          }}
          visible={modalVisible}
        />
      )}
    </>
  )
})
