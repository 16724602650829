import unlikeIcon from "../../../../../assets/works/managementToolBar/heart.svg"

import { Popover } from "@arco-design/web-react"
import albumStore from "@/store/albumStore"
import { observer } from "mobx-react-lite"
import ToolBarButton from "../components/TooBarButton"

interface OptionItem {
  label: string
  value: string
}
interface LikeProps {
  albumOptions?: Array<OptionItem>
  handleAlbumSelect?: (albumId: string) => void
  isOnlyDefaultAlbum?: boolean
}
const Like = ({ albumOptions = [], handleAlbumSelect, isOnlyDefaultAlbum }: LikeProps) => {
  const btnText = "收藏"

  if (isOnlyDefaultAlbum) {
    return (
      <ToolBarButton
        icon={unlikeIcon}
        text={btnText}
        onClick={() => {
          handleAlbumSelect?.(albumOptions[0].value)
        }}
      />
    )
  }

  return (
    <Popover
      position="top"
      trigger={"hover"}
      color={"#212134"}
      className="work-popover-wrapper"
      content={
        <ul className="p-[5px] bg-[#212134]">
          {albumOptions.map((o) => {
            return (
              <li
                key={o.value}
                className="cursor-pointer  text-white px-[10px] py-[5px] bg-transparent hover:bg-[#272739]"
                onClick={() => {
                  handleAlbumSelect?.(o.value)
                }}
              >
                {o.label}
              </li>
            )
          })}
        </ul>
      }
    >
      <div>
        <ToolBarButton icon={unlikeIcon} text={btnText} />
      </div>
    </Popover>
  )
}

interface LikeButtonProps {
  isLiked: boolean
  onLikeClick?: (albumId: string) => void
  onUnLikeClick?: () => void
}
const LikeButton = observer(({ isLiked, onLikeClick, onUnLikeClick }: LikeButtonProps) => {
  const myAlbums = albumStore.myAlbums

  const isOnlyDefaultAlbum = myAlbums?.length === 1 && myAlbums[0].isDefault

  const albumOptions = myAlbums?.map((item) => {
    return {
      label: item.title || "默认相册",
      value: item.uid,
    } as OptionItem
  })

  const handleAlbumSelect = (albumId: string | undefined) => {
    if (!albumId) {
      console.error("albumId不存在")
      return
    }

    onLikeClick?.(albumId)
  }

  return isLiked ? (
    <ToolBarButton icon={unlikeIcon} text="取消收藏" onClick={onUnLikeClick} />
  ) : (
    <Like
      albumOptions={albumOptions}
      handleAlbumSelect={handleAlbumSelect}
      isOnlyDefaultAlbum={isOnlyDefaultAlbum}
    />
  )
})

export default LikeButton
