import { useLocation, useNavigate, useParams } from "react-router-dom"
import { GUEST_COTNENT_CATEGORIES, USER_COTNENT_CATEGORIES } from "./constants"
import { useVisitStatus } from "@/pages/Works/VisitStatusContext"

const Categories = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const currentPath = location.pathname
  const { isVisted } = useVisitStatus()
  const { userId = "" } = useParams()
  const handleCategoryClick = (path: string) => {
    navigate(path)
  }
  const tabs = isVisted ? GUEST_COTNENT_CATEGORIES(userId) : USER_COTNENT_CATEGORIES
  return (
    <div className="flex items-center gap-10">
      {tabs.map((c) => {
        const isActive = currentPath.includes(c.path)
        const activeCls = isActive ? "" : "text-opacity-50"
        const hoverCls = isActive ? "" : "hover:text-opacity-70"

        return (
          <div key={c.id} className="relative" onClick={() => handleCategoryClick(c.path)}>
            <div
              className={`text-lg leading-8 font-sans text-white cursor-pointer ${activeCls} ${hoverCls}`}
            >
              {c.label}
            </div>
            {isActive && (
              <div className="absolute w-full -bottom-[9x] flex justify-center">
                <div className="w-4 h-1 bg-[#4C1FFF]"></div>
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default Categories
