import imageManageIcon from "@/assets/works/imageManage.svg"
import ManageAction from "../ManageBtnContainer"
import { useTranslation } from "react-i18next"

interface SelectOptionsProps {
  onExitClick?: () => void
  onSelectAllClick?: () => void
  allSelected?: boolean
  onClearClick?: () => void
}
const SelectOptions = ({
  onExitClick,
  onSelectAllClick,
  allSelected,
  onClearClick,
}: SelectOptionsProps) => {
  const { t } = useTranslation()
  const toggleAllSelected = () => {
    if (allSelected) {
      onClearClick?.()
    } else {
      onSelectAllClick?.()
    }
  }
  const toggleAllSelectedText = allSelected ? t("worksCenter.clearAll") : t("worksCenter.selectAll")
  return (
    <div className="flex items-center gap-x-[2px]">
      <ManageAction isFirst onClick={onExitClick}>
        {t("worksCenter.exit")}
      </ManageAction>
      <ManageAction isLast onClick={toggleAllSelected}>
        {toggleAllSelectedText}
      </ManageAction>
    </div>
  )
}

interface SelectButtonProps {
  isManaging?: boolean
  onManageClick?: () => void
  onSelectAllClick?: () => void
  onClearClick?: () => void
  onExitClick?: () => void
  allSelected?: boolean
  manageBtnText: string
}

const SelectButton = ({
  onManageClick,
  onSelectAllClick,
  onExitClick,
  isManaging,
  allSelected,
  onClearClick,
  manageBtnText,
}: SelectButtonProps) => {
  return (
    <>
      {isManaging ? (
        <SelectOptions
          onExitClick={onExitClick}
          onSelectAllClick={onSelectAllClick}
          allSelected={allSelected}
          onClearClick={onClearClick}
        />
      ) : (
        <ManageAction onClick={onManageClick}>
          <div className="gap-x-1 flex items-center">
            <img src={imageManageIcon} alt="" className="w-4 object-cover" />
            <div className="mr-2 text-base leading-5 font-normal">{manageBtnText}</div>
          </div>
        </ManageAction>
      )}
    </>
  )
}

export default SelectButton
