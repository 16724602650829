import { Message, Modal, Upload } from "@arco-design/web-react"
import { ReactComponent as TitleIcon } from "@/assets/activities/info_title.svg"
import { useImperativeHandle, useState } from "react"
import { useTranslation } from "react-i18next"
interface InfoTextAreaProps {
  // infoRef: React.RefObject<InfoObj>
  onChange?: (info: string) => void
}
const InfoTextArea: React.FC<InfoTextAreaProps> = ({ onChange }) => {
  const [info, setInfo] = useState<string>("")
  const { t } = useTranslation(["translation", "errorMessage"])
  // useImperativeHandle(infoRef, () => ({
  //   info: info,
  //   validate: () => {
  //     if (info.length === 0) {
  //       Message.error("作品信息不能为空")
  //       return false
  //     }
  //     return true
  //   },
  // }))
  return (
    <div className="w-full">
      <div className="flex flex-col">
        <div className="py-[10px]">
          <TitleIcon />
        </div>
        <textarea
          rows={4}
          className="border-[1px] border-[#433C5F] bg-[#FFFFFF] p-[10px] rounded-[4px]  w-full text-[14px] placeholder-[#8F87AD] text-[#8F87AD] md:h-[107px] h-[94px]"
          placeholder="请输入该作品所使用的Ai工具,以及描述词"
          onChange={(e) => {
            // setInfo(e.target.value)
            onChange?.(e.target.value)
          }}
        />
      </div>
    </div>
  )
}

export default InfoTextArea
