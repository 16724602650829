import {
  PATH_USERS,
  PATH_WORKS,
  PATH_WORKS_MY_ALBUMS,
  PATH_WORKS_MY_TEMPLATES,
  PATH_WORKS_MY_WORKS,
} from "@/router/constants"
import { userContentCategory } from "./types"
import { i18n } from "@/i18n/config"

type contentCategoryItem = {
  id: userContentCategory
  label: string
  path: string
}
export const USER_COTNENT_CATEGORIES: contentCategoryItem[] = [
  {
    id: "works",
    label: i18n.t("worksCenter.myWorks"),
    path: `/${PATH_WORKS}/${PATH_WORKS_MY_WORKS}`,
  },
  {
    id: "photos",
    label: i18n.t("worksCenter.myPhotos"),
    path: `/${PATH_WORKS}/${PATH_WORKS_MY_ALBUMS}`,
  },
  {
    id: "templates",
    label: i18n.t("worksCenter.myTemplates"),
    path: `/${PATH_WORKS}/${PATH_WORKS_MY_TEMPLATES}`,
  },
]

export const GUEST_COTNENT_CATEGORIES = (USERID: string) => {
  return [
    {
      id: "works",
      label: i18n.t("worksCenter.guestWorks"),
      path: `/${PATH_USERS}/${USERID}/${PATH_WORKS}/${PATH_WORKS_MY_WORKS}`,
    },
    {
      id: "photos",
      label: i18n.t("worksCenter.guestPhotos"),
      path: `/${PATH_USERS}/${USERID}/${PATH_WORKS}/${PATH_WORKS_MY_ALBUMS}`,
    },
    // {
    //   id: "templates",
    //   label: i18n.t("worksCenter.guestTemplates"),
    //   path: `/${GUEST_PATH}/${USERID}/${PATH_WORKS}/${PATH_WORKS_MY_TEMPLATES}`,
    // },
  ]
}

export const MANAGEMENT_ACTION_TYOES = new Map([])
