import { toastError } from "@/common/Toast"
import { V1Task, V1TaskStatus } from "@/generated/api/data-contracts"
import { useEffect } from "react"
import { Link, redirect } from "react-router-dom"
import { useSearchParams } from "react-router-dom"

const screenWidth =
  window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

const DajiaPreviewPage = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const resultImage = searchParams.get("src")
  const username = searchParams.get("username") || "默认用户"
  const textPrompt = searchParams.get("textPrompt") || "默认咒语"

  useEffect(() => {
    const canvas: any = document.getElementById("myCanvas")

    if (canvas && canvas.getContext) {
      const ctx = canvas.getContext("2d")
      const img = new Image()
      img.crossOrigin = "Anonymous"
      img.src = `${resultImage}/dajiabaoxian_qr`
      ctx.scale(1, 1)
      img.onload = function () {
        // 当图片加载完成时执行以下代码
        const scaleX = canvas.width / img.width // 计算横向缩放比例
        const scaleY = canvas.height / img.height // 计算纵向缩放比例
        ctx.drawImage(img, 0, 0, img.width * scaleX, img.height * scaleY) // 将图片绘制到Canvas中心位置并按比例缩放
        // 添加文字
        ctx.font = "24px sans-serif"
        ctx.fillStyle = "white"
        // ctx.fillText(username, 48, screenWidth * 0.9 + 25)
        ctx.fillText(username, 48, screenWidth * 1 + 25)
        const imgIcon1 = new Image()
        imgIcon1.src = "/assets/dajiaInsurance/user.png"
        imgIcon1.onload = function () {
          ctx.drawImage(imgIcon1, 10, screenWidth * 1, 30, 30)
        }
      }
    } else {
      toastError("浏览器不支持Canvas")
    }
  }, [])

  const handleDownload = () => {
    const canvas: any = document.getElementById("myCanvas")
    const dataURL = canvas.toDataURL("image/png")
    const link = document.createElement("a")
    link.href = dataURL
    link.download = "image.png"
    link.style.display = "none"
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  return (
    <>
      <div className="relative min-h-screen w-full overflow-y-scroll bg-[#FFE7C9] flex flex-col justify-between items-start">
        <div className="relative">
          {/* <img
              className="object-contain w-full h-[56.25vw]"
              src={`${resultImage}/dajiabaoxian_qr`}
              alt="图片生成结果"
            /> */}
          {/* 图片是16:9的 所以高度应该是固定的；因为canvas缩放原因宽高需要两倍，字体也是两倍的 */}
          <canvas
            width={screenWidth * 2}
            height={0.5625 * screenWidth * 2}
            id="myCanvas"
            className="w-[100vw] h-[56.25vw]"
          ></canvas>
          {/* <div id="textArea" className=" text-white absolute bottom-2 left-2">
            <div style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}>{username}</div>
            <div
              style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
              className="flex items-center gap-1 text-sm"
            >
              <img className="w-4 h-4" src="assets/dajiaInsurance/icon-book.png" />
              {textPrompt}
            </div>
          </div> */}
          <div
            onClick={handleDownload}
            className="bg-[#B81C22] flex justify-center  items-center text-[14px] py-[0.4rem] text-white w-full text-center"
          >
            <img className="w-[3vh] h-[3vh]" src="assets/dajiaInsurance/download.png" />
            下载图片
          </div>
        </div>
        <div className="flex-1 mx-auto flex flex-col items-center">
          <img className="my-[3vh] w-[70vw]" src="/assets/dajiaInsurance/dajia-yinfashenghuo.png" />
          <div className="flex flex-col gap-1 text-[#B48732] text-center font-normal font-xl">
            <div>大家的家，致力于成为中国最受信赖的养老服务大专家，</div>
            <div>为长者创造更加安心、快乐的养老环境，</div>
            <div>让“养老”变“享老”。</div>
            <div>在大家，每一位长者都能成为乐活的“大家” </div>
            <div>把银发生活过得更加美好、多彩。</div>
            <div className="font-bold">让大家的家，实现你向往的养老生活！</div>
          </div>
        </div>

        <div className="w-full flex justify-between  gap-6 px-3 py-3">
          <div className="w-1/2 flex justify-start items-center">
            <img className="w-24" src="/assets/dajiaInsurance/dajia-logo.png" />
          </div>
          <div className="w-1/2 flex justify-end items-center gap-6">
            <img className="w-14" src="/assets/dajiaInsurance/qrcode-1.png" />
            <img className="w-14" src="/assets/dajiaInsurance/qrcode-2.png" />
          </div>
        </div>
        <div className="bg-[#99070D] flex justify-between px-4  items-end   text-white pb-2 pt-2 text-sm w-full text-center">
          <div className="text-white text-[0.6rem] ">此AI图像由Tiamat提供技术支持</div>
          <Link
            to="/dajia-landing"
            className="mr-2 rounded-2xl text-xs px-6 py-1 text-[rgba(130,70,27,1)] bg-[#D7AD75]"
          >
            去看看
          </Link>
        </div>
      </div>
    </>
  )
}

export default DajiaPreviewPage
