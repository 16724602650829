interface ManageActionProps {
  children?: React.ReactNode
  onClick?: () => void
  isFirst?: boolean
  isLast?: boolean
}
const ManageAction = ({ children, onClick, isFirst, isLast }: ManageActionProps) => {
  let roundCls = "rounded"
  if (isFirst) {
    roundCls = "rounded-l"
  } else if (isLast) {
    roundCls = "rounded-r"
  }
  return (
    <div
      onClick={onClick}
      className={`h-9 min-w-fit px-[14px] bg-white bg-opacity-5 flex items-center justify-center cursor-pointer hover:bg-opacity-10 ${roundCls}`}
    >
      {children}
    </div>
  )
}

export default ManageAction
