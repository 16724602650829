import { useState } from "react"
import { Link, NavLink } from "react-router-dom"
import { ReactComponent as LogoutIcon } from "../assets/logout.svg"
import { ReactComponent as LogoutDarkIcon } from "../assets/logout_dark.svg"
import { ReactComponent as CameraIcon } from "@/assets/camera.svg"
import { ReactComponent as EditIcon } from "@/assets/edit.svg"
import { ReactComponent as IconUserCenter } from "@/assets/user-center.svg"

import { Avatar } from "./Avatar"
import { V1ProductInfo } from "@/generated/api/data-contracts"

import { SUBSCRIPTIONS, SUBSCRIPTIONSTITLE } from "@/constants"
import profileStore from "@/store/profileStore"

export interface LogoutProps {
  avatar?: string
  nick?: string
  id?: string
  subscriptionPlan?: V1ProductInfo | null
  onLogout?: () => void
}
export const LogoutComponent: React.FC<LogoutProps> = (props) => {
  const { avatar, nick } = props
  const subscriptionTitle = props.subscriptionPlan?.id
    ? SUBSCRIPTIONS[props.subscriptionPlan?.id].title
    : undefined

  const [avatarCameraIconVisible, setAvatarCameraIconVisible] = useState(false)

  const openProfileEditor = () => {
    profileStore.toggleProfileEditorVisible()
  }

  return (
    <div
      className="
    flex-col
    transform
    dark:bg-black
     p-2
    "
    >
      {/* 头像姓名id */}
      <div className="flex flex-row">
        <section
          className=" relative cursor-pointer w-[50px] h-[50px] flex-shrink-0"
          onMouseEnter={() => {
            setAvatarCameraIconVisible(true)
          }}
          onMouseLeave={() => {
            setAvatarCameraIconVisible(false)
          }}
          onClick={openProfileEditor}
        >
          <Avatar URL={avatar} className="w-full h-full" />
          <section
            style={{ visibility: avatarCameraIconVisible ? "visible" : "hidden" }}
            className="flex justify-center items-center absolute left-0 top-0 w-full h-full bg-black/30 rounded-full"
          >
            <CameraIcon />
          </section>
        </section>
        <section className="px-[14px]">
          <section className="flex items-center gap-2 text-[#101010] dark:text-white leading-5 text-[16px]">
            <section
              title={nick}
              className="text-ellipsis overflow-x-hidden h-[20px] leading-[20px]"
            >
              {nick || "请输入昵称"}
            </section>

            {/* Edit Icon */}
            <EditIcon onClick={openProfileEditor} className="cursor-pointer" />
          </section>
          <div className="text-[#101010] dark:text-white whitespace-nowrap leading-5 text-[10px]">
            魔法师编号:{props.id || "5Djnhzg23fuhlSN4d"}
          </div>
        </section>
      </div>
      {/* 开通会员按钮 */}
      <div
        className={`flex flex-row mt-[14px] h-[54px] rounded-md justify-between items-center pl-[14px] pr-[15px]
        bg-gradient-to-r from-[#EDEEFC] to-[#DFD3F4] dark:bg-[url(/src/assets/logout_bg_dark.png)]`}
      >
        <div className="text-[#5A14F0] dark:text-white text-[14px] leading-5">
          {props.subscriptionPlan ? (
            <>
              当前方案: <Link to="/subscription">{subscriptionTitle}</Link>
            </>
          ) : (
            "月卡畅享千张生成"
          )}
        </div>
        {props.subscriptionPlan ? (
          ""
        ) : (
          // subscriptionTitle === SUBSCRIPTIONSTITLE.BEGINNER ? (
          //   <button className="h-[26px] w-[60px] text-black text-[12px] flex items-center justify-center cursor-pointer rounded bg-[#DEFF80] ">
          //     Core
          //   </button>
          // ) : subscriptionTitle === SUBSCRIPTIONSTITLE.STANDARD ? (
          //   <button className="h-[26px] w-[60px] text-black text-[12px] flex items-center justify-center cursor-pointer rounded bg-[#FFCC80] ">
          //     Pro
          //   </button>
          // ) : (
          //   <button className="h-[26px] w-[60px] text-black text-[12px] flex items-center justify-center cursor-pointer rounded bg-[#FFA680] ">
          //     Pro+
          //   </button>
          // )
          <NavLink
            to="/subscription"
            className="h-[26px] w-[60px] text-white leading-5 text-[12px] flex items-center justify-center cursor-pointer rounded
            bg-gradient-to-r from-[#734AFF] dark:from-transparent to-[#5C16F1] dark:to-transparent dark:bg-transparent dark:border
            "
          >
            去开通
          </NavLink>
        )}
      </div>
      {/* 登出 */}

      <Link
        to="/user-center"
        className="mt-2 rounded-md text-[#101010] dark:text-white text-[14px] flex flex-row 
        items-center cursor-pointer leading-3 hover:bg-[rgb(203,199,220)]/20 hover:rounded-[6px];"
      >
        <IconUserCenter className="fill-black ml-[13px] mr-[12px] my-[12px] dark:fill-white" />
        <span>个人中心</span>
      </Link>
      <div
        onClick={props.onLogout}
        className="mt-2 rounded-md text-[#101010] dark:text-white text-[14px] flex flex-row 
        items-center cursor-pointer leading-3 hover:bg-[rgb(203,199,220)]/20 hover:rounded-[6px];"
      >
        <LogoutDarkIcon className="ml-[17px] mr-[12px] my-[12px] hidden dark:block" />
        <LogoutIcon className="ml-[17px] mr-[12px] my-[12px] dark:hidden" />
        退出登录
      </div>
    </div>
  )
}
