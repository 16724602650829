import { Modal } from "@arco-design/web-react"
import { IconClose, IconDelete, IconInfoCircle } from "@arco-design/web-react/icon"
const ConfirmModal = ({
  message,
  handleClose,
  visible,
}: {
  message: string
  handleClose: (isConfirm: boolean) => void
  visible: boolean
}) => {
  return (
    <Modal
      visible={visible}
      unmountOnExit={true}
      modalRender={() => (
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
          <div className="bg-[#212134]  p-[20px] rounded-[4px]">
            <div>
              <IconInfoCircle />
              <span className="ml-[5px]">{message}</span>
            </div>
            <div className="flex gap-[10px] justify-end mt-[20px]">
              <button
                onClick={() => {
                  // copyImage()
                  // setCopyConfirmVisible(false)
                  handleClose(true)
                }}
                className="bg-[#4C1FFF] rounded-[4px] text-[white] px-[15px] py-[8px]"
              >
                确认
              </button>
              <button
                onClick={() => {
                  // setCopyConfirmVisible(false)
                  handleClose(false)
                }}
                className="border-[#FFFFFFCC] border-[1px] rounded-[4px] text-[white] px-[15px] py-[8px]"
              >
                取消
              </button>
            </div>
          </div>
        </div>
      )}
    ></Modal>
  )
}

export default ConfirmModal
