import { ReactElement, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { ReactComponent as TipIcon } from "@/assets/creation/tooltip.svg"
import TaskProgress from "@/common/TaskProgress"
import { Button, Tooltip } from "@arco-design/web-react"
import CoinButton from "@/common/SpiritButton"
import "./taskCenter.less"
import {
  V1DailyCheckInRewardDesc,
  V1ReachTargetRewardDesc,
  V1RegistInvitationRewardDesc,
  V1SubscribeInvitationRewardDesc,
  V1TemplateSubmissionRewardDesc,
} from "@/generated/api/data-contracts"
import { isMobileOnly } from "react-device-detect"
/**
 *
 *  @param text 是否可领取(按钮状态)
 *  @param onClick 点击领取后调用接口
 *  @param data 数据
 */

export default observer(function ({
  title,
  tip = "",
  data,
  loading,
  tooltip,
  progerssShow = true,
  icon = <TipIcon className="md:w-[20px] md:h-[20px] w-[32px] h-[32px]" />,
  showCount = false,
  extra,
  onClick,
  type,
}: {
  tip?: string
  title?: string
  loading?: boolean
  tooltip?: string
  icon?: ReactElement
  extra?: string
  type?: string
  // 模板提交使用展示数量
  showCount?: boolean
  //  每日签到 提交模板 不需要进度条
  progerssShow?: boolean
  data:
    | V1ReachTargetRewardDesc
    | V1RegistInvitationRewardDesc
    | V1SubscribeInvitationRewardDesc
    | V1DailyCheckInRewardDesc
    | V1TemplateSubmissionRewardDesc
  onClick?: () => void
}) {
  const {
    nextTarget = {},
    current = 0,
    receivableTargets = [],
    canReceive,
    receivableCount,
    dailyBonus = {},
    perBonus = {},
  } = data as any
  const [spiritStones, setSpiritStones] = useState(0)
  // 已经到领取最大限额
  const received = current === nextTarget?.target && nextTarget?.target > 0
  // 是否禁用 ( 每日签到、提交模板progerssShow为false,模板熟练度、模板数量、生成次数progerssShow为true )
  const disabled = progerssShow
    ? received || receivableTargets?.length === 0
    : receivableCount == 0 || !canReceive
  useEffect(() => {
    const num =
      nextTarget?.bonus?.coinCount?.count ||
      dailyBonus?.coinCount?.count ||
      perBonus?.coinCount?.count ||
      0
    setSpiritStones(num)
  }, [data])

  return (
    <div className="py-[15px] md:py-[30px] flex task-bar-container flex-initial">
      <div className="flex-1 flex flex-col">
        <div
          className="w-[100%] flex items-center"
          style={{ marginBottom: progerssShow ? "0px" : "0px" }}
        >
          <div className="text-[14px] md:text-[24px] mr-[10px]">{title}</div>
          <CoinButton text={spiritStones} extra={extra} />
        </div>

        <div className={`md:leading-normal ${progerssShow ? "mt-[10px]" : "mt-[10px]"}`}>
          <div className="flex md:text-[14px] text-[12px] text-zinc-500 font-[200] md:font-[400] md:mt-[20px]">
            {received ? "已获得全部奖励" : tip}
            {/* <Tooltip content={tooltip}>{icon}</Tooltip> */}
          </div>
          {progerssShow && <TaskProgress total={nextTarget?.target || 0} current={current} />}
        </div>
        {/* 移动端展示 */}
        {isMobileOnly && showCount && (
          <span className="text-[12px] md:text-[16px] text-[#6663FF] leading-[16px] md:mb-[10px]">
            当前未领取奖励：{receivableCount}
          </span>
        )}
      </div>

      {/*  pc端展示 */}
      <div className="flex flex-col items-center justify-center">
        {!isMobileOnly && showCount && (
          <span className="text-[14px] md:text-[16px] md:text-zinc-600 leading-[16px] mb-[10px] text-[#6663FF]">
            当前未领取奖励：{receivableCount}
          </span>
        )}
        <div className="w-full flex justify-end">
          <Button
            type="primary"
            loading={loading}
            onClick={onClick}
            disabled={disabled}
            color="#4C1FFF"
          >
            {received ? "已领取" : "领取奖励"}
          </Button>
        </div>
      </div>
    </div>
  )
})
