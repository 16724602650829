import { useTranslation } from "react-i18next"
import { Button } from "@arco-design/web-react"
import { useState } from "react"
import Card from "@/components/bussiness/userCenter/Card"

import "./exchange.less"
import { api } from "@/services/api"
import { toastError, toastSuccess } from "@/common/Toast"
import { ErrorCode } from "@/i18next"
import { isMobileOnly } from "react-device-detect"

export default function ExchangeCode({ changeUpdateKey }: { changeUpdateKey: () => void }) {
  const { t } = useTranslation(["translation", "errorMessage"])
  const [code, setCode] = useState("")
  const [loading, setLoading] = useState(false)
  async function handleClickAward() {
    try {
      setLoading(true)
      const { data } = await api.redeemCodeToReward(code).finally(() => {
        setLoading(false)
      })
      if (data.success) {
        toastSuccess(`已领取兑换码奖励，快去使用吧！`)
        changeUpdateKey()
      } else {
        toastError(
          t(`errorMessage:${data.errorCode as ErrorCode}`).replace("折扣码", "兑换码") ||
            data.errorMessage ||
            ""
        )
      }
    } catch (e) {
      toastError("Error" + e)
    }
  }
  return (
    <Card title={t(`UseExchangeCode`)}>
      <div className="exchange-code-wrapper  mb-[15px] md:mb-[0px]">
        <div className="w-3/4 ">
          {!isMobileOnly && <span className="share-link-text">输入兑换码：</span>}
          <input
            value={code}
            onChange={(e) => {
              setCode(e.target.value)
            }}
            className="md:w-4/5 w-full flex-1 lg:ml-5 p-2 text-base text-gray-300  outline-none rounded bg-[#0C0C1A] border-none placeholder:text-[#6F7176] peer"
            type="text"
          />
        </div>
        <div className="w-1/4 text-right">
          <Button
            loading={loading}
            style={{
              minWidth: "83px",
              backgroundColor: "#6663ff",
              marginLeft: isMobileOnly ? "5px" : "",
            }}
            size="large"
            type="primary"
            onClick={() => handleClickAward()}
          >
            兑换
          </Button>
        </div>
      </div>
    </Card>
  )
}
