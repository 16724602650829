import ModelButton from "./ModelButton"
import MoreButton from "./MoreButton"
import PrivacyStatus from "./PrivacyStatus"
import Ratio from "./Ratio"
import SpeedStatus from "./SpeedStatus"
import TemplateButton from "./TemplateButton"

interface MobileHeaderActionsProps {
  isPrivate: boolean
  isFast: boolean
  onPrivacyClick?: () => void
  onSpeedClick?: () => void
  privacyDisabled?: boolean
  speedDisabled?: boolean
  onUploadReferenceImage?: (isEditor?: boolean) => void
  onControlNet?: () => void
  onPromptGenerated?: (prompt: string) => void
  negativePrompt?: string
  onNegativePromptChange?: (text: string) => void
  templateId?: string
  privaceDisabledMessage?: string
}
const MobileHeaderActions = ({
  isPrivate,
  isFast,
  onPrivacyClick,
  onSpeedClick,
  privacyDisabled,
  speedDisabled,
  onUploadReferenceImage,
  onControlNet,
  onPromptGenerated,
  negativePrompt,
  onNegativePromptChange,
  templateId,
  privaceDisabledMessage,
}: MobileHeaderActionsProps) => {
  return (
    <div className="flex items-center gap-x-5 justify-end">
      {/* Privacy Button */}
      <PrivacyStatus
        isPrivate={isPrivate}
        onClick={() => onPrivacyClick?.()}
        disabled={privacyDisabled}
        disabledMessage={privaceDisabledMessage}
      />

      {/* Speed Button */}
      <SpeedStatus isFast={isFast} onClick={() => onSpeedClick?.()} disabled={speedDisabled} />

      {/* Ratio Button */}
      <Ratio />

      {/* Model Button */}
      <ModelButton />

      {/* Template Button */}
      <TemplateButton />

      {/* More Button */}
      <MoreButton
        onUploadReferenceImage={(isEditor) => {
          onUploadReferenceImage && onUploadReferenceImage(isEditor)
        }}
        onControlNet={onControlNet}
        onPromptGenerated={onPromptGenerated}
        negativePrompt={negativePrompt}
        onNegativePromptChange={onNegativePromptChange}
        templateId={templateId}
      />
    </div>
  )
}

export default MobileHeaderActions
