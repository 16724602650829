import { useEffect, useState } from "react"
import { Progress, Modal, Slider, InputNumber } from "@arco-design/web-react"
import { SpecialistWechatButton } from "@/common/SpecialistWechatButton"
import { toastSuccess } from "@/common/Toast"
import { ReactComponent as CheckIcon } from "@/assets/check.svg"
import { ReactComponent as AddIcon } from "@/assets/addIcon.svg"
import { ReactComponent as CloseIcon } from "@/assets/close_black.svg"
import { updateConsumeSupplementPriority } from "@/services/subscription"
import { formatPrice } from "@/utils"
import * as store from "@/store"
import { AddSub } from "./pay"
import "./remainingAmount.less"
import { observer } from "mobx-react-lite"
import { V1ConsumePriority } from "@/generated/api/data-contracts"
import { isMobileOnly } from "react-device-detect"

const RemainingAmount = observer(
  (props: { addPackagePrice?: number; onChange: (_: "month" | "year") => void }) => {
    const [showMealType, setShowMealType] = useState<"month" | "year">("month")
    const onChange = (val: "month" | "year") => {
      setShowMealType(val)
      props.onChange(val)
    }

    useEffect(() => {
      props.onChange(showMealType)
    }, [])

    return (
      <>
        <div className="max-w-[1140px] w-full mx-auto md:mb-4">
          <SwitchSubType addPackagePrice={props.addPackagePrice} />
        </div>
        <div className="relative md:max-w-7xl w-[100%] md:w-auto mx-auto px-[20px] sm:px-6 md:px-8 flex mt-10 mb-4 flex-col ">
          <div className="p-2 bg-[#0C0C1A] rounded-[6px] flex w-[100%] md:w-auto">
            <div
              onClick={() => onChange("month")}
              className={`${
                showMealType === "month" ? "text-inherit bg-[#212134] shadow" : ""
              } hover:cursor-pointer md:px-20 md:py-3 py-[8px] text-base text-[#6F7176] rounded flex-1 text-center`}
            >
              按月订阅
            </div>
            <div
              onClick={() => onChange("year")}
              className={`${
                showMealType === "year" ? "text-inherit bg-[#212134] shadow" : ""
              } hover:cursor-pointer md:px-20 md:py-3 py-[8px] text-base text-[#6F7176] rounded flex-1 text-center`}
            >
              按年订阅
            </div>
          </div>
        </div>
      </>
    )
  }
)

const SwitchSubType = observer((props: { addPackagePrice?: number }) => {
  const user = store.user.get()
  const {
    subscribedProduct,
    // fastGenerationRemainingSeconds,
    // fastGenerationTotalSeconds,
    // oneTimeSupplementRemainingSeconds,
    // oneTimeSupplementTotalSeconds,
    consumePriority,
    // rewardRemainingSeconds,
    // rewardTotalSeconds,
    fastGenerationRemainingCount,
    fastGenerationTotalCount,
    oneTimeSupplementRemainingCount,
    oneTimeSupplementTotalCount,
    rewardRemainingCount,
    rewardTotalCount,
  } = user

  let detailList = [
    {
      title: subscribedProduct?.title || "",
      consumePriority: V1ConsumePriority.ConsumePrioritySubscribe,
      checked: consumePriority == V1ConsumePriority.ConsumePrioritySubscribe,
      balance: fastGenerationRemainingCount || 0,
      total: fastGenerationTotalCount || 0,
    },
    {
      title: "加量包",
      consumePriority: V1ConsumePriority.ConsumePriorityOneTimeSupplement,
      checked: consumePriority == V1ConsumePriority.ConsumePriorityOneTimeSupplement,
      balance: oneTimeSupplementRemainingCount || 0,
      total: oneTimeSupplementTotalCount || 0,
    },
    {
      title: "奖励次数",
      consumePriority: V1ConsumePriority.ConsumePriorityReward,
      checked: consumePriority == V1ConsumePriority.ConsumePriorityReward,
      balance: rewardRemainingCount || 0,
      total: rewardTotalCount || 0,
    },
  ]

  const subscribeCategory = subscribedProduct?.subscribeCategory
  const subscribedProductId = subscribedProduct?.id || NaN

  const onChange = async (val: V1ConsumePriority) => {
    try {
      await updateConsumeSupplementPriority(val)
      toastSuccess("切换成功")
      store.refreshUser()
    } catch (error) {
      //
    }
  }
  // 是否订阅标志，无category 或者无id 有一个命中则为未订阅，反之则订阅
  const isSubscribed = !(!subscribeCategory || !subscribedProductId)
  if (!isSubscribed) {
    if (rewardRemainingCount <= 0) {
      return null
    } else {
      // 未订阅，但是还有剩余的奖励时间时候
      detailList = [
        {
          title: "奖励次数",
          consumePriority: V1ConsumePriority.ConsumePriorityReward,
          checked: true,
          balance: rewardRemainingCount || 0,
          total: rewardTotalCount || 0,
        },
      ]
    }
  }

  if (subscribeCategory === "Advanced") return null
  return (
    <div className="w-full mx-auto mt-6 ">
      <div className="md:bg-[#212134] flex md:p-2 px-[15px] overflow-x-auto">
        {detailList.map((item) =>
          item.balance ? (
            <div
              key={item.title}
              className={`w-full flex md:bg-[#161623]  p-2  md:rounded rounded-[6px] mr-2 min-w-[170px]
                  ${item?.checked ? "bg-[#6663FF]" : "bg-[#1C1C28]"}
                  ${item.balance > 0 ? "cursor-pointer" : "cursor-not-allowed"}`}
              onClick={() => {
                if (isSubscribed) {
                  !item.checked && onChange(item.consumePriority)
                }
              }}
            >
              {/* pc 端展示在左边 */}
              {!isMobileOnly && (
                <div>
                  {!isSubscribed ? null : !item.checked ? (
                    <span className="inline-block w-5 h-5 rounded-xl bg-[#c8cad0b3] mr-2" />
                  ) : (
                    <span className="inline-flex w-5 h-5 rounded-xl bg-[#5A14F0] shadow-sm justify-center items-center mr-2">
                      <CheckIcon />
                    </span>
                  )}
                </div>
              )}

              <div
                className={`w-full md:text-base font-[300] ${
                  isMobileOnly
                    ? "text-[#FFFFFF] text-[14px]"
                    : item.checked
                    ? "text-[#6663FF]"
                    : "text-[#FFFFFF80]"
                } `}
              >
                {subscribeCategory !== "Advanced" ? (
                  isMobileOnly ? (
                    <div className="flex flex-col justify-between h-full">
                      <div className="flex justify-between">
                        {item.title}
                        {isMobileOnly && (
                          <div>
                            {!isSubscribed ? null : !item.checked ? (
                              <span className="inline-block w-5 h-5 rounded-xl bg-[#c8cad0b3]" />
                            ) : (
                              <span className="inline-flex w-5 h-5 rounded-xl bg-[#5A14F0] shadow-sm justify-center items-center ">
                                <CheckIcon />
                              </span>
                            )}
                          </div>
                        )}
                      </div>

                      <span>
                        剩余
                        <span>{item.balance + "次"}</span>
                        {item.checked ? <span>（当前消耗）</span> : ""}
                      </span>
                    </div>
                  ) : (
                    <h2>
                      {item.title}：剩余
                      <span>{item.balance + "次"}</span>
                      {item.checked ? <span>（当前消耗）</span> : ""}
                    </h2>
                  )
                ) : (
                  ""
                )}
                {/* pc 端进度条 */}
                {!isMobileOnly && subscribeCategory !== "Advanced" && (
                  <Progress
                    style={{
                      background: "#000",
                    }}
                    percent={(item.balance / item.total) * 100}
                    size="large"
                    showText={false}
                    color={item.checked ? "#5A14F0" : "#4e4e58"}
                  />
                )}
              </div>
            </div>
          ) : null
        )}
        {isSubscribed && (
          <div className=" flex-shrink-0">
            <RefuelingBag addPackagePrice={props.addPackagePrice} />
          </div>
        )}
      </div>
    </div>
  )
})

const RefuelingBag = ({ addPackagePrice = 270 }: { addPackagePrice?: number }) => {
  const [visible, setVisible] = useState(false)
  const [hour, setHour] = useState(10)
  const [showPayModal, setShowPayModal] = useState(false)

  const getSum = () => {
    if (hour >= 20) {
      return formatPrice((hour * addPackagePrice * 0.88) / 100)
    } else if (hour >= 10) {
      return formatPrice((hour * addPackagePrice * 0.9) / 100)
    }
    return (hour * addPackagePrice) / 100
  }

  return (
    <>
      {showPayModal && (
        <AddSub
          productAmount={hour}
          onCancel={() => {
            setShowPayModal(false)
          }}
        />
      )}
      {/* {isMobileOnly ? (
        <button
          onClick={() => setVisible(true)}
          className="flex justify-center border-[2px] border-[#6663FF] rounded-[6px] p-[20px]  min-w-[170px] text-[#6663FF] leading-[24px]"
        >
          <AddIcon />
          <span className="ml-[5px]">购买加量包</span>
        </button>
      ) : (
        <button
          className="h-full bordder px-3 py-1.5 border border-[#6663FF] rounded cursor-pointer text-[#6663FF] text-base"
          onClick={() => setVisible(true)}
        >
          购买加量包
        </button>
      )} */}

      <Modal
        maskStyle={{
          background: "#00000099",
        }}
        footer={null}
        title={null}
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        autoFocus={false}
        focusLock={true}
        modalRender={() => {
          return (
            <div className="bg-[#212134] mx-auto top-0 inline-block align-middle rounded shadow-2xl md:w-[800px] w-[100%]">
              <div className="flex w-full justify-end px-2 pt-2">
                <CloseIcon className=" hover:cursor-pointer" onClick={() => setVisible(false)} />
              </div>
              <div className="text text-3xl my-4 text-center">购买加量包</div>
              <div className="p-[10px]">
                <div className="text-[#6F7176] text-sm text-center whitespace-pre-line">
                  加量包永久有效，只要有生效的订阅套餐即可和套餐时长切换使用
                </div>

                <div className="py-4 px-32 mt-24">
                  <div className="h-[32px] flex items-center">
                    <span>小时：</span>
                    <Slider
                      className="addbag_slider"
                      value={hour}
                      min={1}
                      max={30}
                      tooltipVisible={true}
                      onChange={(val) => setHour(val as number)}
                    />
                    <InputNumber
                      className="addbag_input_number"
                      value={hour}
                      mode="button"
                      min={1}
                      max={30}
                      onChange={(val) => setHour(val)}
                    />
                  </div>
                  <div className="h-[32px] flex items-center">
                    <span>价格：</span>
                    {hour < 10 ? (
                      <span>¥{getSum()}</span>
                    ) : hour < 20 ? (
                      <>
                        <span className=" text-[#E93247] text-2xl font-[600]">¥{getSum()}</span>
                        <del className="ml-2">
                          <span className="text-[#CBC7DC] text-base">
                            ¥{(hour * addPackagePrice) / 100}
                          </span>
                        </del>
                        <span className=" border font-light ml-2 px-3 border-[#8952FF] rounded-2xl text-[#A174FF] text-sm ">
                          9折
                        </span>
                      </>
                    ) : (
                      <>
                        <span className=" text-[#E93247] text-2xl font-[600]">¥{getSum()}</span>
                        <del className="ml-2">
                          <span className="text-[#CBC7DC] text-base">
                            ¥{(hour * addPackagePrice) / 100}
                          </span>
                        </del>
                        <span className=" border font-light ml-2 px-3 border-[#8952FF] rounded-2xl text-[#A174FF] text-sm ">
                          88折
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <p className="text-[#B3B3B3] text-xs text-center mt-[56px] mb-[32px] whitespace-pre-line">
                购买单价为27元/小时，购买十小时以上立享10%折扣，20小时以上立享12%折扣
              </p>
              <div className="text-center">
                <div className="mb-4 flex md:block px-[10px] md:px-[0]">
                  <button
                    className="h-[40px] md:w-[188px] rounded border md:my-2 md:mr-2 flex-1"
                    onClick={() => setVisible(false)}
                  >
                    取消
                  </button>
                  <button
                    className="ml-2 primary_btn md:w-[188px] h-[40px] text-white text-4 md:mt-4 rounded-[4px] flex-1"
                    onClick={() => {
                      setVisible(false)
                      setShowPayModal(true)
                    }}
                  >
                    立即支付
                  </button>
                </div>
                <SpecialistWechatButton />
              </div>
            </div>
          )
        }}
      />
    </>
  )
}

export default RemainingAmount
