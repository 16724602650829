import { Select } from "@arco-design/web-react"
import PrivacyButton from "./PrivacyButton"
import RandomPrompt from "./RandomPrompt"
import SpeedButton from "./SpeedButton"
import ModelButton from "./PatternButton"
import ModifyControlButton from "./ModifyControlButton"
import "./index.less"
import activityStore from "@/store/activityStore"
import { useEffect } from "react"
import studioPagesStore from "@/store/studioPagesStore"
interface ActionsProps {
  onRandomPromptClick?: () => void
  randomPromptHidden?: boolean
  onPrivacyChange?: () => void
  onPatternChange?: () => void
  isPrivate?: boolean
  privacyDisabled?: boolean
  privacyDisabledMessage?: string
  onSpeedChange?: () => void
  isFast?: boolean
  speedDisabled?: boolean
  onGenerateClick?: () => void
  generateButtonDisabled?: boolean
  modifyAllTemplatePrompt?: boolean
  toggleModifyControl?: () => void
  template?: any
  onActivityChange: (uid: string) => void
  activityId?: string
}
const Actions = ({
  onRandomPromptClick,
  randomPromptHidden,
  onPrivacyChange,
  isPrivate = false,
  privacyDisabled,
  privacyDisabledMessage,
  onSpeedChange,
  isFast = false,
  speedDisabled,
  onGenerateClick,
  generateButtonDisabled,
  modifyAllTemplatePrompt = false,
  toggleModifyControl,
  template,
  onActivityChange,
  activityId,
  onPatternChange,
}: ActionsProps) => {
  const modifyControlButtonVisible = !!template
  const { onGoingActivities } = activityStore.store.get()
  const { model, modelVersion, modelPattern } = studioPagesStore.store.get()

  const isTIMv5 = modelVersion === "TIMv5"
  const activitiesOptions = onGoingActivities?.map((item) => {
    return { label: item.title || "", value: item.uid || "" }
  })
  activitiesOptions.unshift({ label: "未参与活动", value: "" })
  const selectDomId = "actions_select" + new Date().getTime()
  useEffect(() => {
    activityStore.getActivityList()
  }, [])

  return (
    <div className="mt-[10px] flex justify-between items-center flex-wrap gap-y-3 actions">
      {/* Other Actions */}
      <div className="gap-x-0 px-2 md:px-0 w-full md:w-auto justify-between md:justify-start flex items-center md:gap-x-5">
        {/* Random Prompt Button */}
        {/* {!randomPromptHidden && (
          <RandomPrompt
            onClick={() => {
              onRandomPromptClick?.()
            }}
          />
        )} */}

        {modifyControlButtonVisible && (
          <ModifyControlButton
            modifyAll={modifyAllTemplatePrompt}
            onClick={() => {
              toggleModifyControl?.()
            }}
          />
        )}

        {isTIMv5 && (
          <ModelButton
            onClick={onPatternChange}
            modelPattern={modelPattern}
            modelVersion={modelVersion}
            model={model}
            tooltipMessage={privacyDisabledMessage}
          />
        )}
        {/* Privacy Button */}
        <PrivacyButton
          onClick={onPrivacyChange}
          isPrivate={isPrivate}
          disabled={privacyDisabled}
          tooltipMessage={privacyDisabledMessage}
        />

        {/* Speed Button */}
        <SpeedButton onClick={onSpeedChange} isFast={isFast} disabled={speedDisabled} />
      </div>
      <div className="flex gap-[10px]">
        {onGoingActivities?.length ? (
          <div id={selectDomId} className="flex-1 min-w-[200px]">
            <Select
              placeholder="未参与活动"
              getPopupContainer={() => document.getElementById(selectDomId) || document.body}
              onChange={(uid) => onActivityChange(uid)}
              options={activitiesOptions}
              style={{ flex: 1 }}
              allowClear
              value={activityId}
            ></Select>
          </div>
        ) : (
          ""
        )}

        {/* Generate Button */}
        <button
          className="mx-2 md:mx-0 mt-1 md:mt-0 w-full md:w-auto bg-[#6663FF] px-[60px] py-3 rounded text-[16px] leading-5 disabled:cursor-not-allowed disabled:brightness-50"
          onClick={() => {
            onGenerateClick?.()
          }}
          disabled={generateButtonDisabled}
        >
          生成
        </button>
      </div>
    </div>
  )
}

export default Actions
