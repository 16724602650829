import { computed } from "mobx"
import { observer } from "mobx-react-lite"
import { useMemo, useState } from "react"
import { V1Task, V1TaskStatus } from "@/generated/api/data-contracts"
import { getTaskDetail } from "@/services/editor"
import { timestamp } from "@/utils"
import Card, { RegenOptions } from "@/pages/Works/Card"
import StudioModal from "./StudioModal"
import { ReactComponent as ProcessingIcon } from "@/assets/processing.svg"
import { ReactComponent as RegenerateIcon } from "@/assets/studio/mobile/regenerate.svg"
import { toastError, toastSuccess } from "@/common/Toast"
import { ErrorCode } from "@/i18next"
import { api } from "@/services/api"
import { useTranslation } from "react-i18next"
import studioPagesStore from "@/store/studioPagesStore"
import * as store from "@/store"
import TaskInfo from "./components/GenerationHistory/TaskInfo"
import TaskPrompt from "./components/GenerationHistory/TaskPrompt"
import TemplatModal from "@/common/TemplatModal"
import { isMobileOnly } from "react-device-detect"
import { Link } from "react-router-dom"
import { Modal } from "@arco-design/web-react"

const MobileRegenerateButton = ({ onClick }: { onClick?: () => void }) => {
  return (
    <div
      className="max-w-fit mt-5 py-2.5 px-5 flex items-center cursor-auto bg-[#212134] rounded-full"
      onClick={() => {
        onClick?.()
      }}
    >
      <RegenerateIcon className="w-6 h-6 mr-2.5" />
      重新生成
    </div>
  )
}

export default observer(
  ({
    task,
    canCreate = true,
    addTagsPrompt,
  }: {
    task: V1Task
    onRegen?: (_: RegenOptions) => void
    canCreate: boolean
    addTagsPrompt?: (sortingTags: any) => void
  }) => {
    const { t } = useTranslation(["translation", "errorMessage", "randomPrompt"])
    const user = store.user.get()
    const [templateSubmitModalVisible, setTemplateSubmitModalVisible] = useState(false)
    const [linkActivityshow, setLinkActivityshow] = useState<boolean>(false)
    const [isFirstSuccessTaskForActivity, setIsFirstSuccessTaskForActivity] =
      useState<boolean>(false)
    const [activityId, setActivityId] = useState<string>("")
    const taskUpdater = useMemo(() => {
      const every2second = computed(() => {
        return Math.floor(timestamp.get() / 2000)
      })

      const updater = computed(() => {
        if (
          task.status === V1TaskStatus.TaskStatusSuccess ||
          task.status === V1TaskStatus.TaskStatusFailed
        ) {
          return
        }
        every2second.get()
        //  轮询
        getTaskDetail(task.id || "", { format: "json" }).then(({ data }) => {
          const t = data && data.data!
          if (t && (t.status === "success" || t.status === "failed")) {
            studioPagesStore.changeRuningTasksOfId(task.id || "", t)
            //  第一次创建相关活动作品，领取奖励弹框
            if (data.data.activityUID) {
              setLinkActivityshow(true)
              setIsFirstSuccessTaskForActivity(data?.data?.isFirstSuccessTaskForActivity)
              setActivityId(data.data.activityUID)
            }
          }
        })
      })

      return computed(() => {
        updater.get()
        return task
      })
    }, [task])

    const { status, createdAt } = taskUpdater.get()
    let { works = [] } = taskUpdater.get()

    const isRunning = status === V1TaskStatus.TaskStatusRunning
    const isPending = status === V1TaskStatus.TaskStatusPending
    const isProcessing = isRunning || isPending
    if (isProcessing) {
      if (isMobileOnly) return null

      return (
        <div className="my-3 py-20 flex flex-col items-center justify-center">
          <ProcessingIcon />
          <Progress createdAt={createdAt ?? ""}></Progress>
        </div>
      )
    }
    const handleDelete = (flag: boolean, taskid: string, workid: string) => {
      if (flag) {
        if (studioPagesStore.store.get().runingTasks.some((i) => i.id == taskid)) {
          studioPagesStore.changeRuningTasksOfId(taskid || "", {
            ...task,
            works: task?.works?.filter((i) => i.id != workid),
          })
        } else {
          studioPagesStore.changeTaskHistoryOfId(taskid || "", {
            ...task,
            works: task?.works?.filter((i) => i.id != workid),
          })
        }
      }
    }
    const handleSubmitTemplate = () => {
      setTemplateSubmitModalVisible(true)
    }

    const paddingWorksCount = 4 - (works?.length ?? 0)
    if (paddingWorksCount > 0) {
      for (let i = 0; i < paddingWorksCount; i++) {
        if (!works) {
          works = [
            {
              illegal: true,
            },
          ]
        } else {
          works?.push({
            illegal: true,
          })
        }
      }
    }
    const allWorksIllegal = works.every((w) => w.illegal)

    return (
      <div className="py-3 md:py-5 px-5 md:px-[30px]">
        {task && (
          <TemplatModal
            open={templateSubmitModalVisible}
            onClose={() => {
              setTemplateSubmitModalVisible(false)
            }}
            task={task}
          />
        )}

        {/* Task Information */}
        {!isMobileOnly && (
          <TaskInfo
            task={task}
            onRegenerate={(prompt) => {
              addTagsPrompt?.(task)
            }}
            onSubmitTemplate={handleSubmitTemplate}
          />
        )}

        {/* Task Prompt */}
        {!isMobileOnly && <TaskPrompt prompt={task.textPrompt || ""} />}

        <div className="grid grid-cols-2 gap-[5px] md:gap-0 mt-2 md:flex md:space-x-3 w-full md:flex-row flex-col">
          {works?.map((work, i) => (
            <div
              key={i}
              className="tour-studio-tag-card-2 flex-1 md:w-0 w-full"
              style={{
                aspectRatio: allWorksIllegal
                  ? work?.ratio && work?.ratio.replace(":", "/")
                  : void 0,
              }}
            >
              <Card
                showEye={user.canPrivateGeneration}
                showCreate={canCreate}
                onToggleDelete={(flag) => {
                  handleDelete(flag, task.id!, work.id!)
                }}
                key={work.id}
                task={task}
                work={work}
                Modal={StudioModal}
                workIndex={i}
              />
            </div>
          ))}
        </div>

        {isMobileOnly && <TaskPrompt prompt={task.textPrompt || ""} />}

        {isMobileOnly && (
          <MobileRegenerateButton
            onClick={() => {
              addTagsPrompt?.(task)
            }}
          />
        )}
        <Modal
          closable={false}
          onCancel={() => {
            setLinkActivityshow(false)
          }}
          visible={linkActivityshow}
          modalRender={() => (
            <>
              <div className="w-[400px] py-10 relative mx-auto top-0 inline-block align-middle  bg-[#212134] rounded overflow-hidden">
                <div>
                  {isFirstSuccessTaskForActivity ? (
                    <>
                      <p>领取活动奖励</p>
                      <p>已成功参与活动，请前往活动详情领取奖励。</p>
                      <div className="pt-8 ">
                        <Link
                          to={`/activity?id=${activityId}`}
                          className="bg-[#4C1FFF] rounded px-7 py-3 mr-3"
                        >
                          <span className="">领取奖励</span>
                        </Link>
                        <span
                          onClick={() => setLinkActivityshow(false)}
                          className="bg-[#4C1FFF] rounded px-7 py-3 "
                        >
                          <span className="cursor-pointer">我知道了</span>
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <p>参与活动成功</p>
                      <p>已成功参与活动，请前往活动详情查看。</p>
                      <div className="pt-8">
                        <Link
                          to={`/activity?id=${activityId}`}
                          className="bg-[#4C1FFF] rounded px-7 py-3 mr-3"
                        >
                          <span className="">查看活动</span>
                        </Link>
                        <span
                          onClick={() => setLinkActivityshow(false)}
                          className="bg-[#4C1FFF] rounded px-7 py-3 "
                        >
                          <span className="cursor-pointer">我知道了</span>
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        />
      </div>
    )
  }
)

export const Progress = observer(function ({ createdAt }: { createdAt: string }) {
  const progress = useMemo(() => {
    const taskTime = computed(() => Date.parse(createdAt))
    return computed(() => {
      const percent = (Date.now() - taskTime.get()) / 120e3 /* seconds */
      if (percent < 1) {
        timestamp.get()
        return Math.floor(percent * 96)
      } else {
        return 96
      }
    })
  }, [])
  const currentTaskIsHigh = studioPagesStore.store.get().currentTaskIsHigh

  return (
    <>
      <div className="my-4 text-sm text-white">
        {progress.get() < 10
          ? "当前排队人数较多，请稍等一下哦～"
          : currentTaskIsHigh
          ? "正在快速生成中"
          : "正在生成中"}
      </div>
      <div className="mt-4 h-1 w-[13.75rem] bg-[#404040] rounded-full">
        <div
          className="h-full bg-white rounded-full transition-all"
          style={{ width: `${progress.get()}%` }}
        />
      </div>
    </>
  )
})
