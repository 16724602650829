import ActionButton from "./components/ActionButton"
import privateIcon from "@/assets/studio/privacy_private.svg"
import publicIcon from "@/assets/studio/privacy_public.svg"

interface PrivacyButtonProps {
  isPrivate: boolean
  onClick?: () => void
  disabled?: boolean
  tooltipMessage?: string
}
const PrivacyButton = ({ isPrivate, onClick, disabled, tooltipMessage }: PrivacyButtonProps) => {
  const config = {
    public: {
      iconSrc: publicIcon,
      text: "公开",
      buttonBgColorCls: "bg-transparent",
      buttonBorderColorCls: "border-[#fff]",
    },
    private: {
      iconSrc: privateIcon,
      text: "不公开",
      buttonBgColorCls: "bg-[#6663FF]",
      buttonBorderColorCls: "border-[#6663FF]",
    },
  }

  const activeConfig = isPrivate ? config.private : config.public

  return (
    <ActionButton
      disabled={disabled}
      tooltipMessage={tooltipMessage}
      iconSrc={activeConfig.iconSrc}
      text={activeConfig.text}
      buttonBgColorCls={activeConfig.buttonBgColorCls}
      buttonBorderColorCls={activeConfig.buttonBorderColorCls}
      onClick={() => {
        onClick?.()
      }}
    />
  )
}

export default PrivacyButton
