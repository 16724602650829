import ActionButton from "./components/ActionButton"
import userIcon from "@/assets/studio/user.svg"
import inspirationIcon from "@/assets/studio/inspiration.svg"

interface PrivacyButtonProps {
  onClick?: () => void
  disabled?: boolean
  tooltipMessage?: string
  modelVersion?: string
  modelPattern?: any
  model?: string
}
const PatternButton = ({
  onClick,
  disabled,
  tooltipMessage,
  model,
  modelPattern,
}: PrivacyButtonProps) => {
  const isAnime = (model || "").includes("auto-tianime") // 是否为动漫模型

  const type = isAnime ? "art" : "portrait" // 动漫模型为创意模式，写实模型为肖像模式
  const config = {
    public: {
      iconSrc: isAnime ? inspirationIcon : userIcon,
      text: isAnime ? "创意模式" : "肖像模式",
      buttonBgColorCls: "bg-transparent",
      buttonBorderColorCls: "bonrder-[#fff]",
    },
    private: {
      iconSrc: isAnime ? inspirationIcon : userIcon,
      text: isAnime ? "创意模式" : "肖像模式",
      buttonBgColorCls: "bg-[#6663FF]",
      buttonBorderColorCls: "border-[#6663FF]",
    },
  }

  const activeConfig = modelPattern[type] ? config.private : config.public

  return (
    <ActionButton
      disabled={disabled}
      tooltipMessage={tooltipMessage}
      icon={<img src={activeConfig.iconSrc} alt="" className="w-4 h-4 object-cover mr-1" />}
      text={activeConfig.text}
      buttonBgColorCls={activeConfig.buttonBgColorCls}
      buttonBorderColorCls={activeConfig.buttonBorderColorCls}
      onClick={() => {
        onClick?.()
      }}
    />
  )
}

export default PatternButton
