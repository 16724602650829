import React from "react"
import { getImageURL } from "@/utils"
import DefaultAvatarSvg from "@/assets/login_default_avatar.svg"
import { ReactComponent as HeatIcon } from "@/assets/heat.svg"
import { statusBorderColorMap, statusColorMap, statusTextMap } from "@/constants"
import { Tooltip } from "@arco-design/web-react"
import { IconQuestionCircle } from "@arco-design/web-react/icon"
import { V1TemplateReviewState } from "@/generated/api/data-contracts"

interface TemplateItemProps {
  template: {
    id?: number
    exampleImages?: string[]
    userNickName?: string
    heatValue?: number
    userAvatar?: string
    name?: string
    coverImage?: string
    reviewState?: V1TemplateReviewState
    reviewRejectReason?: string
  }
  className?: string
  showAvator?: boolean
  fromWork?: boolean
  callback?: () => void
}

const TemplateItem: React.FC<TemplateItemProps> = ({
  template: {
    id,
    exampleImages = [],
    userNickName,
    heatValue,
    userAvatar,
    name,
    coverImage,
    reviewState,
    reviewRejectReason,
  },
  className,
  callback,
  showAvator = true,
  fromWork = false,
}: TemplateItemProps) => {
  return (
    <section
      key={id}
      onClick={() => {
        callback && callback()
      }}
      className={` group flex-shrink-0 relative aspect-[1/1] 2xl:w-[286px] xl:w-[252px] lg:w-[254px] md:w-[180px] w-[145px] rounded-[4px] overflow-hidden ${
        className || ""
      }`}
    >
      <img
        className="w-full h-full object-cover group-hover:scale-[1.2] transition-transform transform-gpuansform duration-700"
        src={getImageURL(coverImage || exampleImages[0], true)}
        // style={{ objectPosition: 'left top' }}
      />
      <div className="absolute inset-0 flex justify-center items-center text-[14px] text-[#ffffff] cursor-pointer">
        <div className="flex items-end relative w-full h-full top-0  md:pb-[10px] md:px-[10px]">
          <div
            className={`flex bg-black bg-opacity-50 px-[10px] py-[5px] flex-col-reverse  w-full md:px-[12px] md:pt-[6px]  sm:bg-black sm:bg-opacity-50 rounded-[4px] ${
              fromWork ? "" : "md:h-[80px]"
            } `}
          >
            <div className="flex justify-between items-center ">
              {showAvator && (
                <div className="hidden md:flex items-center w-[50%]">
                  <img
                    className="h-7 w-7 border border-[#FFFFFF33] rounded-full object-contain"
                    src={userAvatar || DefaultAvatarSvg}
                  />
                  <div className="pl-[10px] truncate ">{userNickName}</div>
                </div>
              )}
              {/* 作品中心不展示 */}
              {!fromWork && (
                <div className="flex md:h-[38px] h-[12px] py-[3px] md:px-[8px] pr-[4px] rounded-[4px] md:mb-0 mb-1">
                  <div className="flex ">
                    <div className="flex items-center">
                      <HeatIcon className="md:h-[18px]  h-[14px] md:w-[18px] w-[14px] " />
                    </div>
                    <div className="flex items-center ml-2 md:ml-3 font-sans md:text-[14px] text-[12px] font-normal leading-[19px] tracking-normal">
                      {heatValue}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="truncate font-open-sans md:text-2xl  text-[16px] font-bold  leading-33 tracking-normal md:mb-0 mb-1.5">
              {name}
            </div>
          </div>
        </div>
      </div>
      {/*  作品中心我的模板使用 */}
      {fromWork &&
        (reviewRejectReason ? (
          <Tooltip position="top" trigger="hover" content={<span>{reviewRejectReason}</span>}>
            <div
              className={`absolute right-[10px] top-[10px] border-[1px] rounded-[2px] px-[10px] text-[12px] cursor-pointer ${statusColorMap.get(
                reviewState || ""
              )}  ${statusBorderColorMap.get(reviewState || "")}`}
            >
              {statusTextMap?.get(reviewState || "")}
            </div>
          </Tooltip>
        ) : (
          <div
            className={`absolute right-[10px] top-[10px] border-[1px] rounded-[2px] px-[10px] text-[12px] cursor-pointer ${statusColorMap.get(
              reviewState || ""
            )}  ${statusBorderColorMap.get(reviewState || "")}`}
          >
            {statusTextMap?.get(reviewState || "")}
          </div>
        ))}
    </section>
  )
}

export default TemplateItem
