import { useEffect, useState } from "react"
import { ReactComponent as CloseIcon } from "@/assets/creation/close.svg"
import "./index.less"
import { toastError, toastSuccess } from "@/common/Toast"
import { Modal } from "@arco-design/web-react"
import {
  V1CreateTemplateRequest,
  V1Task,
  V1TemplateCategoryMeta,
} from "@/generated/api/data-contracts"
import { observer } from "mobx-react-lite"
import { createTemplate, getTemplateCategories } from "@/services/editor"
import TemplateForm from "./TemplateForm"
import { isMobileOnly } from "react-device-detect"
import { useTranslation } from "react-i18next"
export interface categories {
  label: string
  value: string
}
// interface Task extends V1Task {
//   controlNet: V1ControlNetSettings
// }
export default observer(function ({
  open,
  onClose,
  task,
}: {
  open: boolean
  onClose: () => void
  task: V1Task
}) {
  const [categoryOptions, setCategoryOptions] = useState<Array<categories>>([])
  const { t } = useTranslation(["translation", "errorMessage"])
  const [loading, setLoading] = useState(false)
  const submitTemplate = async (params: V1CreateTemplateRequest) => {
    const { success, errorCode } = await createTemplate(params)
    try {
      if (success) {
        window.scrollTo(0, 0)
        toastSuccess("模板提交成功")
        onClose()
      } else {
        toastError(t(`errorMessage:${errorCode}` as any))
      }
    } catch (e) {
      toastError("Error" + e)
    }
    setLoading(false)
  }
  const getCategories = async () => {
    const {
      data: { categories },
    } = await getTemplateCategories()
    let options: Array<categories> = categories.map((item: V1TemplateCategoryMeta) => ({
      label: item?.displayName,
      value: item?.category,
    }))
    setCategoryOptions([...options, { label: "未选择分类", value: "" }])
  }
  const cancel = () => {
    onClose()
  }
  useEffect(() => {
    open && getCategories()
  }, [open])
  return (
    <Modal
      visible={open}
      footer={null}
      closeIcon={
        <button
          className="md:absolute md:right-[-10px] md:top-[-8px] hover:brightness-125"
          onClick={cancel}
        >
          <CloseIcon className="hidden md:block" />
          <span className="text-[#6663FF] text-sm md:hidden">取消</span>
        </button>
      }
      maskStyle={{
        background: "rgba(10, 9, 9, 0.9)",
        backdropFilter: "blur(5px)",
      }}
      style={{
        width: isMobileOnly ? "100%" : "auto",
        padding: "0",
        backgroundColor: "#212134",
      }}
    >
      <div className="bg-white dark:bg-[#212134] h-auto  rounded relative text-white md:w-[680px] md:h-[790px] md:px-[20px]">
        <div className="flex flex-row justify-center md:justify-start mb-[20px]">
          <span className="text-[32px] md:text-[24px]">模板提交</span>
        </div>
        <TemplateForm
          task={task}
          handleSubmit={(params: V1CreateTemplateRequest) => {
            submitTemplate(params)
          }}
          loading={loading}
          handleLoading={(loadStatu) => {
            setLoading(loadStatu)
          }}
          open={open}
          handleCancel={() => {
            cancel()
          }}
          options={categoryOptions}
        />
      </div>
    </Modal>
  )
})
