import { V1Task, V1Work } from "@/generated/api/data-contracts"
import CardOneOption from "@/components/bussiness/work/generated/CardOneOption"
import PrivacyButton from "./PrivacyButton"
import LikeButton from "./LikeButton"
import RegenerateBtn from "./RegenerateBtn"
import CopyPrompt from "./CopyPrompt"
import ImageRepaint from "./ImageRepaint"

interface PropsProps {
  task: V1Task
  work: V1Work
  hiddenConfig?: Partial<{
    deleteTask?: boolean // 删除task
    publicOrPrivate?: boolean // 私有、公开
    linkOrUnlike?: boolean // 喜欢不喜欢
    imageRepainting?: boolean // 图片重绘
    more?: boolean // 更多。。。
    usedForAvatar?: boolean // 用于图像
    usedForBackground?: boolean // 用户背景
    generateAgain?: boolean // 再次生成
    controlNet?: boolean //controlNet
    // ImageSpeak?: boolean // 图片会说话
    referenceImage?: boolean
    usedFor?: boolean // 用于
    menuCopyBtn?: boolean // 复制按钮
  }>
  operationHandlers?: {
    publicOrPrivate?: (isPrivate: boolean, workId: string) => void
    likeOrUnlike?: (liked: boolean, workId: string) => void
    onWorkDeleted?: (workId: string) => void
  }
  onPopoverVisibleChange?: (visible: boolean) => void
  directCopyBtnVisible?: boolean
}
const Options = ({
  task,
  work,
  hiddenConfig,
  operationHandlers,
  onPopoverVisibleChange,
  directCopyBtnVisible,
}: PropsProps) => {
  return (
    <div
      className={`justify-between rounded p-[10px] flex text-white items-center bg-[#0c0c1ae6]`}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      {!hiddenConfig?.publicOrPrivate && (
        <PrivacyButton
          isPublic={!work.isPrivate}
          onClick={() => operationHandlers?.publicOrPrivate?.(!!work.isPrivate, work.id ?? "")}
        />
      )}

      <div className="flex gap-2 items-center ml-auto">
        {!hiddenConfig?.linkOrUnlike && (
          <LikeButton
            isLiked={work.liked ?? false}
            onClick={() => operationHandlers?.likeOrUnlike?.(!!work.liked, work.id ?? "")}
          />
        )}

        <RegenerateBtn task={task} />

        {directCopyBtnVisible && <CopyPrompt promptText={task.textPrompt} />}

        {!hiddenConfig?.imageRepainting && <ImageRepaint work={work} />}

        {hiddenConfig?.more ? null : (
          <CardOneOption
            hiddenConfig={{
              deleteTask: hiddenConfig?.deleteTask,
              publicOrPrivate: hiddenConfig?.publicOrPrivate,
              usedFor: hiddenConfig?.usedFor,
              copyPrompt: hiddenConfig?.menuCopyBtn,
            }}
            work={work}
            task={task as V1Task}
            handlers={{
              publicOrPrivate: operationHandlers?.publicOrPrivate,
              onWorkDeleted: operationHandlers?.onWorkDeleted,
            }}
            onVisibleChange={onPopoverVisibleChange}
          />
        )}
      </div>
    </div>
  )
}

export default Options
