import { ReactComponent as CopyIcon } from "@/assets/copy2.svg"
import { toastInfo } from "@/common/Toast"
import { Tooltip } from "@arco-design/web-react"
import CopyToClipboard from "react-copy-to-clipboard"

interface CopyPromptProps {
  promptText?: string
}

const CopyPrompt = ({ promptText }: CopyPromptProps) => {
  return (
    <Tooltip content="复制描述语">
      <div className="flex justify-end">
        <CopyToClipboard
          text={promptText ?? ""}
          onCopy={() => {
            toastInfo("已复制")
          }}
        >
          <button className=" hover:bg-[#FFFFFF1A] transition rounded-sm">
            <CopyIcon />
          </button>
        </CopyToClipboard>
      </div>
    </Tooltip>
  )
}

export default CopyPrompt
