import uploadIcon from "@/assets/studio/mobile/upload_m.svg"
import controlNetIcon from "@/assets/studio/mobile/controlnet_m.svg"
import imageTalkIcon from "@/assets/studio/mobile/image_talk_m.svg"
import unwantedElementIcon from "@/assets/studio/mobile/unwanted_element_m.svg"
import CheckAuth from "@/components/common/CheckAuth"

interface MoreOptionsProps {
  onOptionClick?: (id: string) => void
}
export const MoreOptions = ({ onOptionClick }: MoreOptionsProps) => {
  const options = [
    {
      icon: uploadIcon,
      text: "上传参考图",
      id: "uploadReferenceImage",
    },
    // {
    //   icon: imageTalkIcon,
    //   text: "图像会说话",
    //   id: "imageTalk",
    // },
    {
      icon: controlNetIcon,
      text: "ControlNet",
      id: "controlNet",
    },
    {
      icon: unwantedElementIcon,
      text: "不要的元素",
      id: "unwantedElement",
    },
  ]

  return (
    <div>
      {options.map((option, index) => {
        const needCheckAuth = ["imageTalk", "uploadIcon"].includes(option.id)
        const dividerVisible = index !== options.length - 1

        // Wrap with CheckAuth component if needed
        if (needCheckAuth) {
          return (
            <CheckAuth
              key={option.id}
              onClick={() => {
                onOptionClick?.(option.id)
              }}
            >
              <OptionItem option={option} dividerVisible={dividerVisible} />
            </CheckAuth>
          )
        }

        // Normal option
        return (
          <OptionItem
            key={option.id}
            onClick={() => {
              onOptionClick?.(option.id)
            }}
            option={option}
            dividerVisible={dividerVisible}
          />
        )
      })}
    </div>
  )
}

interface OptionItemProps {
  option: {
    icon: string
    text: string
    id: string
  }
  onClick?: () => void
  dividerVisible?: boolean
}
const OptionItem = ({ option, onClick, dividerVisible }: OptionItemProps) => {
  return (
    <div key={option.id} onClick={onClick}>
      <div className="flex items-center justify-center py-[5px] cursor-pointer">
        <img className="w-7 h-7" src={option.icon} alt={option.text} />
        <div className="ml-[5px]">{option.text}</div>
      </div>
      {dividerVisible && <div className="h-[1px] bg-[#FFFFFF0D] my-[10px]"></div>}
    </div>
  )
}
