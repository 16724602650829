import { useTranslation } from "react-i18next"
import Card from "@/components/bussiness/userCenter/Card"
import { Table, TableColumnProps } from "@arco-design/web-react"
import "./award.less"
import { api } from "@/services/api"
import { useEffect, useState } from "react"
import {
  V1Bonus,
  V1ListRewardRecordsResponse,
  V1RewardRecord,
} from "@/generated/api/data-contracts"
import dayjs from "dayjs"
import { toastError } from "@/common/Toast"
import { formatTime } from "@/utils"

export default function AwardCollectionRecord() {
  const { t } = useTranslation(["translation"])
  const [loading, setLoading] = useState(false)
  const [table, setTable] = useState<V1RewardRecord[]>([])
  const [pageConfig, setPageConfig] = useState({
    current: 0,
    pageSize: 10,
    total: 0,
  })

  const columns: TableColumnProps[] = [
    {
      title: "领取时间",
      dataIndex: "receivedAt",
      render(time) {
        return time ? dayjs(time * 1000).format("YYYY-MM-DD HH:mm:ss") : "-"
      },
    },
    {
      title: "领取类型",
      dataIndex: "kind",
      render(kind) {
        if (kind?.includes("BuyNormalCoinCount") || kind?.includes("SpecialCoinCount")) {
          let numbers = kind?.match(/\d+/g)
          kind = kind.replace(numbers, "")
          return `${t(kind)}`
        } else if (kind?.includes("e")) {
          return `${t("UserGenCountReach")}`
        } else if (kind?.includes("TemplateSubmissionReach")) {
          return `${t("TemplateSubmissionReach")}`
        } else {
          return t(kind)
        }
      },
    },
    {
      title: "兑换内容",
      dataIndex: "bonus",
      render(bonus: V1Bonus, record) {
        const {
          addCurrentSubscriptionExpires,
          addPrivateImageExpires,
          addUnlimitedSlowGenerationExpires,
          subscriptionDiscount,
          fastGenerationTime,
          generationCountQuota,
          coinCount,
          fastGenerationCountQuota,
        } = bonus
        return (
          <>
            {addCurrentSubscriptionExpires && (
              <p>
                添加当前订阅的过期时间: {formatTime(addCurrentSubscriptionExpires.seconds || 0)}{" "}
              </p>
            )}
            {addPrivateImageExpires && (
              <p> 添加作品私密的过期时间: {formatTime(addPrivateImageExpires.seconds || 0)} </p>
            )}
            {addUnlimitedSlowGenerationExpires && (
              <p>
                添加无限制标准模式生成过期时间:
                {formatTime(addUnlimitedSlowGenerationExpires.seconds || 0)}
              </p>
            )}
            {subscriptionDiscount && (
              <p>订阅优惠奖励: {(subscriptionDiscount.discount || 0) * 10 + "折"}</p>
            )}
            {/* {fastGenerationTime && (
              <p>快速生成时间: {formatTime(fastGenerationTime.seconds || 0)}</p>
            )} */}
            {/* 模板数量 生成次数 对应灵石 */}
            {generationCountQuota &&
              (record?.kind?.includes("UserGenCountReach") ||
                record?.kind?.includes("TemplateSubmissionReach")) && (
                <p>灵石数量: {generationCountQuota.count} 个</p>
              )}
            {/* 兑换商品 对应次数 */}
            {generationCountQuota &&
              !(
                record?.kind?.includes("UserGenCountReach") ||
                record?.kind?.includes("TemplateSubmissionReach")
              ) && <p>标准生成次数: {generationCountQuota.count} 次</p>}
            {coinCount && <p>灵石数量: {coinCount.count} 个</p>}

            {fastGenerationCountQuota && <p>快速生成次数: {fastGenerationCountQuota.count} 次</p>}
          </>
        )
      },
    },
  ]

  const getTableData = async (current = pageConfig.current) => {
    try {
      setLoading(true)
      const { data } = await api.listRewardRecords({
        current: current,
        pageSize: pageConfig.pageSize,
      })
      if (data.success && data.data) {
        const {
          list = [],
          current = 1,
          pageSize = 10,
          total = 10,
        } = data.data as V1ListRewardRecordsResponse
        // arco Table 的 data 需要一个 key 否则控制台警告
        let listWithKeys = list.map((item, index) => ({ ...item, key: index + 1 }))
        setPageConfig({ current, pageSize, total })
        setTable(listWithKeys || [])
      } else {
        //
      }
    } catch (e) {
      toastError("Error" + e)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getTableData(1)
  }, [])
  return (
    <Card title={t("AwardCollectionRecord")}>
      <div className="award-wrapper">
        <Table
          loading={loading}
          columns={columns}
          className="award-table-col"
          data={table}
          border={false}
          pagination={{
            size: "mini",
            ...pageConfig,
            onChange(pageNumber) {
              getTableData(pageNumber)
            },
          }}
        />
      </div>
    </Card>
  )
}
