import { ReactElement, useMemo, useState } from "react"
import { Modal, Radio } from "@arco-design/web-react"
import { IconClose } from "@arco-design/web-react/icon"
import { api } from "@/services/api"
import { toastError, toastSuccess } from "./Toast"
import { useTranslation } from "react-i18next"
import { UploadCard, UploadItem } from "@/components/common/Upload"

import { ReportReasonList } from "@/constant/index"
import { V1AbuseReportSubmitReq, V1TicketTheme } from "@/generated/api/data-contracts"
import CheckAuth from "@/components/common/CheckAuth"
const Report = ({
  id,
  element,
  type = "content",
}: {
  id?: string
  element?: ReactElement
  type?: "content" | "global"
}) => {
  const { t } = useTranslation(["translation", "errorMessage"])

  const [fileList, setFileList] = useState<UploadItem[]>([])
  const [visible, setVisible] = useState(false)
  const [reason, setReason] = useState<V1TicketTheme>(() => V1TicketTheme.TicketThemeOther)

  const [desc, setDesc] = useState("")
  const closeModal = () => {
    setVisible(false)
  }

  const [isSign, setIsSign] = useState(false)

  const submit = async () => {
    if (type === "content" && !id) {
      return
    }
    if (reason === V1TicketTheme.TicketThemeOther && !desc.length) {
      return toastError("请提供更多的信息有助于举报被快速处理")
    }
    if (isSpecialReason) {
      if (!isSign) {
        return toastError("请先勾选保证声明")
      }
      if (!fileList.length) {
        return toastError("请上传其他证明材料")
      }
    }
    const params: V1AbuseReportSubmitReq = {
      description: desc,
      reasonTag: reason as V1TicketTheme,
    }
    if (id) {
      params.workId = id
    }
    if (isSpecialReason && fileList.length) {
      params.images = fileList.map((item) => item.url ?? "")
    }
    const { data } = await api.abuseReport(params)

    if (data.success) {
      closeModal()
      return toastSuccess("举报已收到～感谢您的支持")
    }
    if (data.errorCode === "11007") {
      return toastError(t(`errorMessage:11007`))
    }
    return toastError(t("NetworkError"))
  }

  const isSpecialReason = useMemo(() => {
    return reason === V1TicketTheme.TicketThemeViolationofRightsAndInterests //侵犯权益
  }, [reason])
  return (
    <>
      <CheckAuth
        needLogin
        onClick={() => {
          setVisible(true)
        }}
      >
        {element}
      </CheckAuth>
      <Modal
        visible={visible}
        footer={null}
        style={{
          zIndex: 1002,
        }}
        maskStyle={{
          zIndex: 1002,
        }}
        wrapStyle={{
          zIndex: 1002,
        }}
        onCancel={closeModal}
        modalRender={() => {
          return (
            <div className="w-[488px] relative mx-auto top-0 inline-block align-middle  bg-[#212134] rounded overflow-hidden">
              <div className="flex justify-between pl-[32px] text-white pr-2 py-3">
                <h1 className=" text-left text-base">
                  {type === "content" ? "内容举报" : "举报投诉"}
                </h1>
                <div className="flex text-lg justify-end  items-center">
                  <IconClose className="cursor-pointer" onClick={() => closeModal()} />
                </div>
              </div>
              <div className="w-full px-[32px]">
                <h2 className="text-white text-left">
                  举报理由<span className="text-[#E93247]">*</span>
                </h2>
                <div className="w-full flex flex-wrap justify-between">
                  {ReportReasonList.map((item) => {
                    return (
                      <button
                        onClick={() => {
                          setReason(item.value)
                        }}
                        key={item.value}
                        className={`w-[32%] my-2 py-3 rounded ${
                          reason === item.value
                            ? "bg-[#6663FF] hover:bg-[#6663FFb1]"
                            : "bg-[#161623] hover:bg-[#161623b1]"
                        } text-white`}
                      >
                        {item.label}
                      </button>
                    )
                  })}
                </div>
              </div>
              <div className="w-full px-[32px] mt-3">
                <div className="f-full flex justify-between mb-2">
                  <h2 className="text-white text-left">
                    举报描述
                    {reason === V1TicketTheme.TicketThemeOther && (
                      <span className="text-[#E93247]">*</span>
                    )}
                  </h2>
                  <span className="text-[#ffffff80]">{desc.length}/200</span>
                </div>
                <textarea
                  className="bg-[#0C0C1A] w-full h-[180px] min-h-[180px] max-h-[180px] p-3 text-white"
                  placeholder="请提供更多的信息有助于举报被快速处理"
                  minLength={200}
                  maxLength={200}
                  onChange={(e) => {
                    setDesc(e.target.value)
                  }}
                />
              </div>
              {isSpecialReason && (
                <>
                  <div className="w-full px-[32px] mt-3 text-left ">
                    <div className="f-full flex justify-between mb-2">
                      <h2 className="text-white text-left">
                        其他证明资料
                        <span className="text-[#E93247]">*</span>
                      </h2>
                    </div>
                    <p className="text-[#878787] leading-[20px] mb-3 whitespace-normal break-words text-xs">
                      1. 上传能够证明投诉厲实的相关资料或照片
                      <br />
                      2.证明材料一般提供原件的彩色扫描件，只能提供复印件扫描件的，需投诉人在复印件上面签章（自然人应该签名署日期，非自然人应盖公章并注明日期）
                      <br />
                      3.
                      若材料涉外的，应按照法律的规定，进行公证转递，并同时提供相应的公证转递材料最多上传5张照片，格式为PNG、JPG、JPEG
                      <br />
                    </p>

                    <UploadCard
                      actions={["delete"]}
                      fileList={fileList}
                      setFileList={setFileList}
                      requstPromise={api.uploadImageForTicket}
                      uploderCSS={{ width: "95px", height: "95px" }}
                      tipTextCss={{ fontSize: "12px" }}
                    />
                  </div>
                  <div className="w-full px-[32px] mt-[30px] text-left text-white">
                    <h2 className="text-sm">保证声明</h2>
                    <p className="leading-5 text-sm  mb-[20px] whitespace-normal break-words">
                      投诉人及其代理人
                      （統称为：声明人）诚意作出如下声明：声明人知晓并确认此次投诉上传提交的所有资料及其所载内容均真实、合法、有效，承诺对Tiamat官方因此作出的所有处理措施承担一切责任。
                    </p>
                    <Radio value={true} onChange={(val) => setIsSign(val)}>
                      <span className="text-[#BCBCBC] ">声明人理解并同意上述保证声明</span>
                    </Radio>
                  </div>
                </>
              )}

              <div className="mt-8 mb-6">
                <button
                  onClick={submit}
                  className="px-12 text-white py-3 rounded bg-[#6663FF] hover:bg-[#6663FFb1]"
                >
                  提交
                </button>
              </div>
            </div>
          )
        }}
      />
    </>
  )
}

export default Report
