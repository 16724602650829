import notAvailable from "@/assets/works/not_available.svg"
import CopyToClipboard from "react-copy-to-clipboard"
import { useTranslation } from "react-i18next"
import LongPressCopy from "@/common/LongPressCopy"
interface NotAvailableItemProps {
  taskId?: string
}
const NotAvailableItem = ({ taskId = "" }: NotAvailableItemProps) => {
  const { t } = useTranslation()
  return (
    <div className="aspect-square relative">
      <div className="absolute inset-0 flex flex-col items-center justify-center">
        <LongPressCopy text={taskId}>
          <img src={notAvailable} alt="" className="w-[97px] h-[97px]" />
          <div className="font-sans text-base text-white text-opacity-50">
            {t("worksCenter.imageNotFound")}
          </div>
        </LongPressCopy>
      </div>
    </div>
  )
}

export default NotAvailableItem
