import { toastError } from "@/common/Toast"
import dayjs from "dayjs"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

const inPeriod = (start: string, end: string) => {
  const now = dayjs()
  const startTime = dayjs(start)
  const endTime = dayjs(end)
  return now.isSameOrAfter(startTime) && now.isSameOrBefore(endTime)
}

const inSensitivePeriod = (toastIfSensitive = false) => {
  // sentive period:
  // 9.17 ~ 9.19
  // 9.30 ~ 10.7

  const currentYear = dayjs().year()

  const periods = [
    {
      start: `${currentYear}-09-17`,
      end: `${currentYear}-09-19`,
    },
    {
      start: `${currentYear}-09-30`,
      end: `${currentYear}-10-07`,
    },
  ]

  const inSensitivePeriod = periods.some((period) => inPeriod(period.start, period.end))
  // const inSensitivePeriod = true

  // toast if sensitive
  if (inSensitivePeriod && toastIfSensitive) {
    toastError("功能维护中")
  }

  return inSensitivePeriod
}

export { inSensitivePeriod }
