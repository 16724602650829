import { Tooltip } from "@arco-design/web-react"
import { ReactComponent as IconHeard } from "@/assets/heart-white.svg"

interface LikeButtonProps {
  isLiked: boolean
  onClick: () => void
}
const LikeButton = ({ isLiked, onClick }: LikeButtonProps) => {
  return (
    <Tooltip content={isLiked ? "切换为不收藏" : "切换为收藏"}>
      <div className="h-[28px] w-[28px] hover:bg-[#FFFFFF1A] rounded-sm flex items-center justify-center ">
        <IconHeard
          fill={isLiked ? "#E93247" : "white"}
          className="transition  cursor-pointer"
          onClick={onClick}
        ></IconHeard>
      </div>
    </Tooltip>
  )
}

export default LikeButton
