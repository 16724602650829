import * as store from "@/store/index"
import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"
import { profileSceneType } from "../types"

const maxLength = 10

interface NameProps {
  onChange?: (value: string) => void
  scene?: profileSceneType
  onInputError?: (error: string | boolean) => void
}
const Name = observer(({ onChange, scene = "modal", onInputError }: NameProps) => {
  const { nick: storeNick } = store.user.get()
  const [value, setValue] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (value === undefined && storeNick) {
      setValue(storeNick)
    }
  }, [storeNick])

  useEffect(() => {
    const hasError = !value || value.length > maxLength
    onInputError && onInputError(hasError)
    onChange && onChange(value?.trim() || "")
  }, [value])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement
    const valueLength = value?.length || 0
    const inputValueLength = target.value.length
    const exceedLength = inputValueLength > maxLength

    // If user input exceeds the max length, do not update the value
    // If user input length is more than the current value length, do not update the value. So user can only delete the value
    if (exceedLength && inputValueLength > valueLength) {
      return
    }
    setValue(target.value)
  }

  const valueLength = value?.length || 0
  const lengthExceeded = valueLength > maxLength

  const styleConfig = {
    modal: {
      titleTextColor: "text-white",
      titleTextOpacity: "text-opacity-50",
      inputWrapperBackgroundColor: "bg-[#161623]",
      lengthTextColor: "text-white",
      inputTextColor: "text-white",
    },
    login: {
      titleTextColor: "text-black",
      titleTextOpacity: "text-opacity-80",
      inputWrapperBackgroundColor: "bg-[#1616231a]",
      lengthTextColor: "text-black",
      inputTextColor: "text-[#101010]",
    },
  }
  const activeStyle = styleConfig[scene]
  const lengthTextColorAndOpacity = lengthExceeded
    ? "text-red-500 text-opacity-80"
    : `${activeStyle.lengthTextColor} text-opacity-20`

  return (
    <div className="mt-11 md:mt-5">
      <div
        className={`text-sm ${activeStyle.titleTextColor} ${activeStyle.titleTextOpacity} font-sans font-normal`}
      >
        昵称:
      </div>
      <div
        className={`mt-2.5 ${activeStyle.inputWrapperBackgroundColor} py-[7px] pr-2 pl-3 md:py-3 px-5 flex items-center rounded`}
      >
        <input
          value={value}
          onChange={handleInputChange}
          type="text"
          className={`bg-transparent flex-1 text-sm leading-[22px] md:text-base md:leading-6 font-sans ${activeStyle.inputTextColor}`}
        />
        <div
          className={`ml-2 text-sm ${lengthTextColorAndOpacity} text-opacity-20 font-sans font-light`}
        >
          {valueLength}/{maxLength}
        </div>
      </div>
    </div>
  )
})

export default Name
