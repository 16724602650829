import { ReactComponent as LoadingPuffIcon } from "@/assets/loading_puff.svg"
import { ReactComponent as CloudUploadIcon } from "@/assets/cloud-upload.svg"
import { ReactComponent as PaintIcon } from "@/assets/paint.svg"
import { ReactComponent as CopyToRIghtIcon } from "@/assets/copy_to_right.svg"
import { ReactComponent as CopyToLeftIcon } from "@/assets/copy_to_left.svg"
import { ReactComponent as CloseIcon } from "@/assets/closeIcon.svg"
import { toastError } from "@/common/Toast"
import { createRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react"
import { Select, Slider, Upload } from "@arco-design/web-react"
import "./ControlNet.less"
import Cropper, { ReactCropperElement } from "react-cropper"
import { observer } from "mobx-react-lite"
import { useTranslation } from "react-i18next"
import { uploadFile } from "@/services/editor"
import DrawingBoard from "@/common/DrawingBoard"
import { isMobileOnly } from "react-device-detect"
import studioPagesStore from "@/store/studioPagesStore"
import { ErrorCode } from "@/i18next"
import { filterControlNetModel } from "@/utils"
export interface ControlNetInit {
  inputImageURL?: string
  module?: string
  weight?: number
}
interface ControlNetUploadProps {
  // 0|1 索引区分两个controlNet
  index: number
  // 裁切比例 要与左侧选中的同步
  // ratio: number
  // 初始值
  init: ControlNetInit
  //  数据改变触发
  handleChange: (ControlNetInit: ControlNetInit) => void
  // + - controlNet2按钮
  button?: React.ReactNode
  //  弹窗开关
  visible?: boolean
  mobilePaintVisible?: boolean
  handleClosePaint: () => void
  className?: string
  copyImage: (imgSrc: string, currentIndex: number) => void
  contolNet1Init?: ControlNetInit
  contolNet2Init?: ControlNetInit
}
export interface useImperativeHandleProps {
  uploadCropImage: () => Promise<any>
  controlNetUploadRef: HTMLInputElement
  imgUrl?: string
}
export const ControlNetUpload = observer(
  (
    {
      index,
      // ratio,
      init,
      button,
      handleChange,
      visible,
      mobilePaintVisible,
      handleClosePaint,
      className,
      copyImage,
      contolNet1Init,
      contolNet2Init,
    }: ControlNetUploadProps,
    ref: any
  ) => {
    const { t } = useTranslation(["translation", "errorMessage"])
    const [imgStatus, setImgStatus] = useState<0 | 1 | 2>(() => 0) // 0 待选择图 1 上传中 2 上传成功
    const [selectDomId] = useState(() => "control-net-modal" + name + new Date().getTime())
    const controlNetUploadRef = useRef(null)
    const [drawingBoardVisible, setDrawingBoardVisible] = useState(false)
    const cropperRef = createRef<ReactCropperElement>() // cropper ref
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const [screenHeight, setScreenHeight] = useState(window.innerHeight)
    const [aspectRatio, setAspectRatio] = useState(1)
    const rotateTo = (item: string) => {
      const [w, h] = item.split(":")
      const rotateNum = +w / +h
      setAspectRatio(rotateNum)
    }
    const ratio = studioPagesStore.store.get().ratio
    const modelVersion = studioPagesStore.store.get().modelVersion
    const isTIMv5 = modelVersion === "TIMv5"

    // 选择完图片进行上传 loading时展示图片
    const [imageUrl, setImageUrl] = useState<string>()

    useImperativeHandle(ref, () => ({
      // 上传裁剪后的图片
      uploadCropImage: cropImage,
      controlNetUploadRef: controlNetUploadRef.current as ReactCropperElement | null,
      imgUrl: imageUrl,
      // 重置裁剪图
    }))
    //  选择图片
    const selectImg = (file?: File) => {
      if (!file) {
        return
      }
      if (file.size / 1048576 > 5) {
        return toastError("单个文件不能超过5M")
      }
      const url = URL.createObjectURL(file)
      setImageUrl(url)
      setImgStatus(2)
    }
    //  清空图片：清空url、cropper销毁图片、改变上传状态
    const removeImage = () => {
      setImageUrl("")
      cropperRef.current?.cropper?.destroy()
      handleChange({ ...init, inputImageURL: "" })
      setImgStatus(0)
    }
    //  创建Promise对象
    const cropImage = () => {
      return new Promise((resolve, reject) => {
        const canvas = cropperRef.current?.cropper.getCroppedCanvas({
          imageSmoothingQuality: "low",
        })
        if (canvas) {
          canvas?.toBlob(
            async (blob) => {
              if (!blob) {
                return
              }
              const file = new File([blob], "image", {
                type: "image/jpeg",
              })
              setImgStatus(1)
              try {
                const { data } = await uploadFile({ file })
                if (data.success && data.data.fileUrl) {
                  resolve(data.data.fileUrl)
                  // handleChange({ ...init, inputImageUrl: data?.data?.fileUrl })
                } else {
                  // data.errorCode
                  toastError(t(`errorMessage:${data.errorCode as ErrorCode}`))
                  // reject("上传失败请重试")
                }
              } catch (error) {
                reject("上传失败请重试")
              }
              setImgStatus(2)
            },
            "image/jpeg",
            0.8
          )
        } else {
          reject("请上传图片")
        }
      })
    }
    //  将网址图片转为本地 blob
    const transNetImg = (url: string) => {
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const url = URL.createObjectURL(blob)
          setImageUrl(url)
        })
    }
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
      setScreenHeight(window.innerHeight)
    }
    useEffect(() => {
      // 监听窗口大小改变事件
      window.addEventListener("resize", handleResize)
      // 组件卸载时清除事件监听
      return () => {
        window.removeEventListener("resize", handleResize)
      }
    }, [])
    useEffect(() => {
      rotateTo(ratio)
    }, [ratio])

    const DrawingBoardHeight = useMemo(() => {
      if (isMobileOnly) {
        if (aspectRatio == 0.5625) {
          return (screenWidth * 0.8) / aspectRatio
        }
        return screenWidth / aspectRatio
      } else {
        return screenHeight * 0.6
      }
    }, [screenWidth, screenHeight, ratio])
    const DrawingBoardWidth = useMemo(() => {
      if (isMobileOnly) {
        if (aspectRatio == 0.5625) {
          return screenWidth * 0.8
        }
        return screenWidth
      } else {
        return screenHeight * 0.6 * aspectRatio
      }
    }, [screenWidth, screenHeight, ratio])
    useEffect(() => {
      // 已有图片
      if (init?.inputImageURL) {
        transNetImg(init?.inputImageURL)
        setImgStatus(2)
      }
    }, [init?.inputImageURL])

    useEffect(() => {
      // 关闭时 移除图片
      visible === false && removeImage()
    }, [visible])

    return (
      <div className={`relative ${className}`}>
        <div className="px-[0] pb-4 w-full">
          <div id={selectDomId} className="control-net-select-mode ">
            <Select
              value={init?.module}
              onChange={(val) => {
                // 更改模型
                handleChange({ ...init, module: val })
              }}
              placeholder="选择模式"
              options={filterControlNetModel(
                modelVersion,
                index === 1 ? contolNet2Init?.module : contolNet1Init?.module
              )}
              getPopupContainer={() => {
                return document.querySelector(`#${selectDomId}`) || document.body
              }}
            />
            {!isMobileOnly && <span>{button}</span>}
          </div>
        </div>
        <div className="pb-4 relative">
          {imgStatus === 2 && (
            <div className="absolute right-[-10px] top-[-10px] z-10" onClick={removeImage}>
              <CloseIcon />
            </div>
          )}
          {imgStatus === 0 && (
            <div className="relative">
              <Upload
                accept="image/*"
                showUploadList={false}
                onChange={(fileList, file) => {
                  selectImg(file.originFile)
                }}
              >
                <div className="trigger md:w-[360px] md:h-[360px] w-[348px] h-[348px] pt-24 rounded border border-[#373747] border-dashed ">
                  <div className="flex justify-center my-1">
                    <CloudUploadIcon className="md:w-[24px] w-[72px] md:h-[24px] h-[72px] fill-white" />
                  </div>
                  <h2>点击/拖拽上传</h2>
                  <p className="text-[#ffffff4d] text-xs">（单个文件不能超过5M）</p>
                </div>
              </Upload>
            </div>
          )}
          {imgStatus !== 0 && (
            <div className="group md:w-[360px] md:h-[360px] w-[348px] h-[348px]  relative overflow-hidden rounded-md">
              <Cropper
                key={`${index}-${aspectRatio}`}
                wheelZoomRatio={1}
                ref={cropperRef}
                style={{
                  height: isMobileOnly ? "348px" : "360px",
                  width: isMobileOnly ? "348px" : "360px",
                }}
                zoomable={false} // 图缩放
                aspectRatio={aspectRatio}
                preview=".img-preview"
                src={imageUrl}
                viewMode={1}
                dragMode="none"
                minCropBoxHeight={100}
                minCropBoxWidth={100}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                guides={true}

                // 拖动裁切框触发
                // cropend={cropImage}
                // cropBoxMovable={imgStatus === 0}
                // cropBoxResizable={imgStatus === 0}
              />
              {imgStatus === 1 && (
                <div className="absolute inset-0 rounded bg-black/80 flex items-center justify-center">
                  <LoadingPuffIcon />
                </div>
              )}
            </div>
          )}
          {/* index 为 0时 controlNet1 复制图片到 controlNet2 */}
          <div className="absolute  bg-[#212134CC] bottom-[30px]  right-[15px] flex gap-[10px] p-[5px] rounded-[4px]">
            <div
              className="rounded-[4px] w-[36px] h-[36px] border-[1px] border-[gray] justify-center items-center hidden md:flex"
              onClick={() => copyImage(imageUrl || "", index)}
            >
              {index === 1 ? <CopyToRIghtIcon /> : <CopyToLeftIcon />}
            </div>
            <div
              className="rounded-[4px] w-[36px] h-[36px] border-[1px] border-[gray]  justify-center items-center hidden md:flex"
              onClick={() => setDrawingBoardVisible(true)}
            >
              <PaintIcon />
            </div>
          </div>
        </div>

        <div className="md:mt-4 flex md:flex-row flex-col">
          <div className="text-sm mr-2 text-left text-white  md:hidden">
            <span>参考权重: {init?.weight}</span>
          </div>
          <div className="text-sm mr-2 text-left text-white hidden md:block">
            <span>参考权重</span>
          </div>
          <div className="flex items-center w-full mt-[10px] mb-[20px]">
            <Slider
              // color: "#6663ff"
              style={{ flex: 1, color: "green" }}
              step={0.01}
              value={init?.weight || 0}
              onChange={(v) => {
                handleChange({ ...init, weight: v as number })
              }}
              min={0.01}
              max={1}
            />
          </div>
          <span className="w-[30px] flex-shrink-0 ml-3 hidden md:block">{init?.weight}</span>
        </div>
        {(mobilePaintVisible || drawingBoardVisible) && (
          <DrawingBoard
            onClose={function (): void {
              setDrawingBoardVisible(false)
              handleClosePaint()
            }}
            index={index}
            width={DrawingBoardWidth}
            height={DrawingBoardHeight}
            handleSumit={(localUrl: string) => {
              setImageUrl(localUrl)
              setImgStatus(2)
              setDrawingBoardVisible(false)
              handleClosePaint()
            }}
          />
        )}
      </div>
    )
  },
  { forwardRef: true }
)
