import { ReactComponent as IconLoading } from "@/assets/question/loading.svg"
import { ReactComponent as IconDescription } from "@/assets/question/description.svg"
import { ReactComponent as IconFeedback } from "@/assets/question/feedback.svg"
import { ReactComponent as IconSuggestion } from "@/assets/question/suggestion.svg"
import { ReactComponent as IconQuestion } from "@/assets/question/question.svg"
import { Link, useLocation, useNavigate } from "react-router-dom"

import Report from "@/common/Report"
import { Popover } from "@arco-design/web-react"
import * as Store from "@/store"
import { isMobileOnly } from "react-device-detect"

export default function Question() {
  const navigate = useNavigate()
  const user = Store.user.get()

  const location = useLocation()
  if (location.pathname === "/studio" && isMobileOnly) {
    return null
  }

  return (
    <>
      <Popover
        trigger="click"
        className="home-modal-popover z-3 home-modal-position"
        position="lb"
        getPopupContainer={() => {
          return document.body
        }}
        content={
          <div className="flex flex-col p-[5px] rounded bg-[#212134] text-white">
            <button
              type="button"
              className="flex w-full border-[#FFFFFF1A] my-[5px] py-[8px] px-[11px] justify-between items-center  hover:bg-[#FFFFFF1A] transition rounded-sm"
              onClick={() => {
                navigate("/user-guide?key=quick-start")
              }}
            >
              快速上手
              <IconLoading className="ml-[5px]" />
            </button>
            <button
              type="button"
              className="flex w-full border-[#FFFFFF1A] my-[5px] py-[8px] px-[11px] justify-between items-center  hover:bg-[#FFFFFF1A] transition rounded-sm"
              onClick={() => {
                navigate("/user-guide?key=description-guide")
              }}
            >
              描述指南
              <IconDescription className="ml-[5px]" />
            </button>
            <button
              type="button"
              className="flex w-full border-[#FFFFFF1A] my-[5px] py-[8px] px-[11px] justify-between items-center  hover:bg-[#FFFFFF1A] transition rounded-sm"
              onClick={() => {
                navigate("/user-center#feedback")
              }}
            >
              意见反馈
              <IconFeedback className="ml-[5px]" />
            </button>
            {user.id ? (
              <Report
                type={"global"}
                element={
                  <button
                    type="button"
                    className="flex w-full justify-between border-[#FFFFFF1A] my-[5px] py-[8px] px-[11px] items-center  hover:bg-[#FFFFFF1A] transition rounded-sm"
                  >
                    举报投诉
                    <IconSuggestion className="ml-[10px]" />
                  </button>
                }
              />
            ) : (
              <>
                <Link to={"/login"}>
                  <button
                    type="button"
                    className="flex w-full justify-between border-[#FFFFFF1A] my-[5px] py-[8px] px-[11px] items-center  hover:bg-[#FFFFFF1A] transition rounded-sm"
                  >
                    举报投诉
                    <IconSuggestion className="ml-[10px]" />
                  </button>
                </Link>
              </>
            )}
            {/* <button
              type="button"
              className="flex w-full justify-between border-[#FFFFFF1A] my-[5px] py-[8px] px-[11px] items-center  hover:bg-[#FFFFFF1A] transition rounded-sm"
              onClick={() => {
                localStorage.removeItem("tour-studio-tag-card")
                localStorage.removeItem("tour-studio")
                toastSuccess("重置成功,请刷重试！")
              }}
            >
              重置新手引导
            </button> */}
          </div>
        }
      >
        <button className="flex justify-center items-center bg-[#111124] transition rounded-sm h-[30px] w-[30px]">
          <IconQuestion className="" />
        </button>
      </Popover>
    </>
  )
}
