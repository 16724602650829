import { getImageURL } from "@/utils"

interface ControlNetItemProps {
  title: string
  description: string
  imgSrc: string
  onClick?: () => void
}
const ControlNetItem = ({ title, description, imgSrc, onClick }: ControlNetItemProps) => {
  return (
    <div
      className="flex justify-between cursor-pointer bg-black bg-opacity-20 hover:bg-opacity-30"
      onClick={() => {
        onClick?.()
      }}
    >
      <img src={imgSrc} alt="" className="w-[120px] h-[80px] object-cover rounded" />
      <div className="flex-1 ml-[10px] flex flex-col py-[10px] pr-[10px]">
        <div className="text-white text-base font-normal leading-tight">{title}</div>
        <div className="text-white text-opacity-50 text-sm font-normal">{description}</div>
      </div>
    </div>
  )
}

export default ControlNetItem
