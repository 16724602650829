import { Upload as ArcoUpload, Modal } from "@arco-design/web-react"
import type { UploadItem } from "@arco-design/web-react/lib/Upload"
import { useState, Dispatch, SetStateAction, CSSProperties } from "react"
import { toastError } from "@/common/Toast"
import { HttpResponse } from "@/generated/api/http-client"
import { ResponseResponseWrapper } from "@/generated/api/data-contracts"
import { LogicError } from "@/types"
import { useTranslation } from "react-i18next"
import { IconEye, IconDelete } from "@arco-design/web-react/icon"
import { ReactComponent as IconLoading } from "@/assets/loading.svg"
import { ReactComponent as IconPlus } from "@/assets/plus.svg"

export type { UploadItem } from "@arco-design/web-react/lib/Upload"

interface Params {
  file: File
}
type Actions = "preview" | "delete"
interface Props {
  requstPromise: (params: Params) => Promise<
    HttpResponse<
      ResponseResponseWrapper & {
        data?:
          | {
              success: boolean
              data: {
                fileUrl: string
              }
            }
          | undefined
      },
      any
    >
  >
  fileLimit?: number
  fileList: UploadItem[]
  uploderCSS?: CSSProperties
  tipTextCss?: CSSProperties
  setFileList: Dispatch<SetStateAction<UploadItem[]>>
  accept?: string
  actions?: Actions[]
}
export function UploadCard(props: Props) {
  const {
    requstPromise,
    fileLimit = 4,
    fileList,
    uploderCSS,
    tipTextCss,
    setFileList,
    actions = ["preview", "delete"],
    accept = "image/jpg,image/png",
  } = props
  const { t } = useTranslation(["translation", "errorMessage"])

  const [loadingUpload, setLoadingUpload] = useState(false)

  async function handleBeforeUpload(file?: File) {
    if (!file) {
      return false
    }
    if (file.size / 1048576 > 2) {
      toastError("单个文件不能超过2M")
      return false
    }

    return true
  }

  async function handleUploadFile(file: File) {
    if (!file) {
      return
    }
    const fd = new FormData()
    fd.append("file", file)
    try {
      setLoadingUpload(true)
      const { data } = await requstPromise({ file })
      if (data.success && data.data.fileUrl) {
        const uploadFile = {
          ...file,
          uid: String(Date.now()),
          url: data.data.fileUrl,
        } as UploadItem

        setFileList((pre) => {
          if (pre) {
            return [...pre, uploadFile]
          } else {
            return [uploadFile]
          }
        })
      } else {
        toastError("上传失败，请稍后再试或更换图片")
      }
    } catch (error) {
      if (error instanceof LogicError) {
        toastError(t(`errorMessage:${error.code}` as any))
      }
    } finally {
      setLoadingUpload(false)
    }
  }

  function renderUploadList(fileList: UploadItem[]) {
    return (
      <>
        {fileList.map((file) => {
          return (
            <div
              className="uploador-upload-item inline-block relative cursor-pointer mr-3 mb-3"
              key={file.uid}
            >
              <img
                src={file.url || (file?.originFile && URL.createObjectURL(file.originFile))}
                style={{
                  width: uploderCSS?.width ?? "144px",
                  height: uploderCSS?.height ?? "144px",
                }}
              />
              {actions?.length ? (
                <div className="uploador-actions absolute flex bg-state-300 cursor-pointer top-0 left-0 opacity-0 hover:opacity-100 bg-[#000000CC]  justify-center items-center h-full w-full">
                  <div>
                    {actions.includes("preview") && (
                      <IconEye style={{ fontSize: 12 }} onClick={() => onPreview(file)} />
                    )}
                    {actions.includes("delete") && (
                      <IconDelete
                        style={{ fontSize: 12, marginLeft: "8px" }}
                        onClick={() => {
                          onRemove(file)
                        }}
                      />
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          )
        })}
      </>
    )
  }
  function onRemove(file: UploadItem) {
    setFileList((preList) => {
      return preList?.filter((item) => item.uid !== file.uid)
    })
  }
  function onPreview(file: UploadItem) {
    Modal.info({
      title: "预览",
      wrapStyle: { zIndex: 1000000 },
      content: (
        <img
          src={file.url || (file?.originFile && URL.createObjectURL(file.originFile))}
          style={{
            maxWidth: "100%",
          }}
        ></img>
      ),
    })
  }
  return (
    <ArcoUpload
      listType="picture-card"
      multiple
      accept={accept}
      limit={fileLimit}
      fileList={fileList}
      onRemove={onRemove}
      onExceedLimit={() => {
        console.log("onExceedLimit")
        toastError(`超过数量限制，请上传少于${fileLimit}张图片`)
      }}
      beforeUpload={handleBeforeUpload}
      renderUploadList={renderUploadList}
      customRequest={(options) => handleUploadFile(options.file)}
      onPreview={onPreview}
    >
      <>
        {fileList.length < fileLimit ? (
          <div className="upload-card-style">
            <div
              className="w-[144px] h-[144px] flex justify-center items-center border border-dashed border-slate-500 rounded"
              style={uploderCSS ?? {}}
            >
              {loadingUpload ? (
                <IconLoading className="ml-2 inline-block align-middle animate-spin" />
              ) : (
                <div className="mt-3">
                  <IconPlus></IconPlus>
                  <p className="text-gray-400 mt-2">{`${fileList.length}/${fileLimit}`}</p>
                </div>
              )}
            </div>
            <p
              className="text-gray-400 mt-2"
              style={tipTextCss ?? {}}
            >{`只能上传jpg/png文件，且不超过2M，最多上传${fileLimit}张`}</p>
          </div>
        ) : (
          <span></span>
        )}
      </>
    </ArcoUpload>
  )
}
