import { Message, Modal, Upload } from "@arco-design/web-react"
import { ReactComponent as TitleIcon } from "@/assets/activities/upload_title.svg"
import { ReactComponent as RefrenceIcon } from "@/assets/activities/refrence.svg"
import { ReactComponent as RemoveImgIcon } from "@/assets/activities/remove_img.svg"
import { isMobileOnly } from "react-device-detect"
import "./index.less"
import { RefObject, useEffect, useImperativeHandle, useMemo, useState } from "react"
import { api } from "@/services/api"
import { UploadItem } from "@arco-design/web-react/es/Upload"
import { toastError } from "@/common/Toast"
import { LogicError } from "@/types"
import { useTranslation } from "react-i18next"
interface UploadImgProps {
  onChange?: (imgList: Array<string>) => void
}
interface imgObj {
  url: string
  uid: string
}
const UploadImg: React.FC<UploadImgProps> = ({ onChange }) => {
  const [imgList, setImgList] = useState<Array<imgObj>>([])
  const [file, setFile] = useState()
  const { t } = useTranslation(["translation", "errorMessage"])
  async function handleUploadFile(file: File) {
    if (file.size / 1024 / 1024 > 5) {
      toastError("文件大小不能超过5MB")
      return
    }
    if (!file) {
      return
    }
    const fd = new FormData()
    fd.append("file", file)
    try {
      const { data } = await api.guestUploadFile({ file })
      if (data.success && data.data.fileUrl) {
        const uploadFile = {
          ...file,
          uid: String(Date.now()),
          url: data.data.fileUrl,
        } as UploadItem
        setImgList((pre: any) => {
          if (pre) {
            onChange?.([...pre, uploadFile].map((item) => item.url))
            return [...pre, uploadFile]
          } else {
            return [uploadFile]
          }
        })
      } else {
        toastError("上传失败，请稍后再试或更换图片")
      }
    } catch (error) {
      if (error instanceof LogicError) {
        toastError(t(`errorMessage:${error.code}` as any))
      }
    } finally {
    }
  }

  return (
    <div className="w-full">
      <div className="flex flex-col">
        <div className="py-[10px]">
          <TitleIcon />
        </div>
        <div className="text-[12px] text-[#8F87AD]">支持 jpg、jpeg、png 格式；最多4张</div>
        <div className="w-full flex gap-[10px]">
          {imgList?.map((file) => (
            <div className="w-[80px] h-[80px] bg-gray-100 rounded-[4px] relative" key={file.uid}>
              <img src={file.url} className="w-full h-full rounded-[4px]" />
              <div
                className="absolute right-0 top-0 rounded-[4px]"
                onClick={() => {
                  setImgList(imgList.filter((item) => item.uid !== file.uid))
                }}
              >
                <RemoveImgIcon style={{ borderTopRightRadius: "4px" }} />
              </div>
            </div>
          ))}
          <Upload
            style={{ width: "80px" }}
            showUploadList={false}
            fileList={file ? [file] : []}
            accept={".jpg, .jpeg, .png"}
            customRequest={(options) => handleUploadFile(options.file)}
          >
            {imgList.length < 4 ? (
              <div className="w-[80px] h-[80px]">
                <RefrenceIcon />
              </div>
            ) : (
              <div></div>
            )}
          </Upload>
        </div>
      </div>
    </div>
  )
}
export default UploadImg
