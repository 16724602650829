import { Message, Modal, Upload } from "@arco-design/web-react"
import { ReactComponent as TitleIcon } from "@/assets/activities/phone_title.svg"
import { useImperativeHandle, useState } from "react"
import { useTranslation } from "react-i18next"
interface PhoneInputProps {
  onChange?: (contactInformation: string) => void
}
const PhoneInput: React.FC<PhoneInputProps> = ({ onChange }) => {
  const { t } = useTranslation(["translation", "errorMessage"])
  // useImperativeHandle(phoneRef, () => ({
  //   phone: phone,
  //   validate: () => {
  //     // 手机号校验
  //     if (!/^1[3-9]\d{9}$/.test(phone)) {
  //       Message.error("请输入正确的手机号码")
  //       return false
  //     }
  //     return true
  //   },
  // }))
  return (
    <div className="w-full">
      <div className="flex flex-col">
        <div className="py-[10px]">
          <TitleIcon />
        </div>
        <input
          className="border-[1px] border-[#433C5F] bg-[#FFFFFF] p-[10px] rounded-[4px] h-[42px] w-full text-[14px] placeholder-[#8F87AD] text-[#8F87AD]"
          placeholder="请输入你的手机号码,以便与你联系"
          onChange={(e) => {
            onChange?.(e.target.value)
          }}
        />
      </div>
    </div>
  )
}

export default PhoneInput
