import { ReactComponent as HomeMenu } from "@/assets/home/menu.svg"
import menuStore from "@/store/menuStore"
import { ReactNode } from "react"

const MobileHeaderMenu = ({ extra }: { extra?: ReactNode }) => {
  return (
    <div className=" flex md:hidden items-center pl-5 pr-5 w-full h-16 min-h-[64px] bg-white dark:bg-black">
      <HomeMenu
        className=" w-5 h-5 mr-5"
        onClick={() => {
          menuStore.openMenu()
        }}
      />
      <div className="flex-1">{extra}</div>
    </div>
  )
}

export default MobileHeaderMenu
