import templateCloseIcon from "@/assets/studio/mobile/template_close.svg"

const TemplateName = ({ name, onClose }: { name: string; onClose: () => void }) => {
  return (
    <div className="bg-[#0C0C1A] px-2.5 py-[3px] flex items-center text-[13px]">
      <div className="flex-1 flex items-center">
        当前模板：
        <div className="text-[#6663FF]">{name}</div>
      </div>
      <img
        src={templateCloseIcon}
        className="w-6 h-6 cursor-pointer"
        alt="close"
        onClick={() => {
          onClose()
        }}
      />
    </div>
  )
}

export default TemplateName
