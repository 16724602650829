import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { ReactComponent as ArrowLeftDarkIcon } from "@/assets/arrow_left_dark.svg"
import { V1TemplateData } from "@/generated/api/data-contracts"
import { useDarkMode } from "@/utils"
import TemplateDetailModal from "@/common/TemplateDetailModal"
import TemplateItem from "@/common/TemplateItem"
import { observer } from "mobx-react-lite"

interface TemplateScrollProps {
  className?: string
  templates: V1TemplateData[]
}

const TemplateScroll: React.FC<TemplateScrollProps> = (props: TemplateScrollProps) => {
  useDarkMode()

  const containerRef = useRef<HTMLElement>(null)
  const scrollRef = useRef<HTMLElement>(null)

  const [scrollX, setScrollX] = useState<number>(0)
  const [maxScrollX, setMaxScrollX] = useState<number>(0)
  const [taskIndex, setTaskIndex] = useState<number | null>(null)
  const { templates } = props || {}
  const templateWidth = 286
  const templateGap = 20
  const scrollDistance = (templateWidth + templateGap) * 2
  const minScrollX = 0

  useEffect(() => {
    setNextScrollX(0)
  }, [maxScrollX])

  const setNextScrollX = (offset: number) => {
    let nextScrollX = scrollX + offset
    if (nextScrollX < minScrollX) nextScrollX = minScrollX
    if (nextScrollX > maxScrollX) nextScrollX = maxScrollX
    if (scrollRef.current) {
      scrollRef.current.scrollTo(nextScrollX, 0)
    }
  }

  const openModal = (i: number) => {
    setTaskIndex(i)
  }

  const closeModal = () => {
    setTaskIndex(null)
  }

  const refresh = useCallback(() => {
    const nextMaxScrollX =
      (templateWidth + templateGap) * (templates?.length || 0) -
      templateGap -
      (containerRef.current?.clientWidth || 0)

    setMaxScrollX(nextMaxScrollX)
  }, [templates])

  useLayoutEffect(() => {
    refresh()
    window.addEventListener("resize", refresh)
    window.addEventListener("orientationchange", refresh)
    const resizeObserver = new ResizeObserver(refresh)
    if (containerRef.current) resizeObserver.observe(containerRef.current)
    if (scrollRef.current) {
      scrollRef.current.addEventListener("scroll", () => {
        if (scrollRef.current) setScrollX(Math.ceil(scrollRef.current.scrollLeft))
      })
    }
    return () => {
      window.removeEventListener("resize", refresh)
      window.removeEventListener("orientationchange", refresh)
      resizeObserver.disconnect()
    }
  }, [refresh])

  const templateModalVisiable = taskIndex !== null
  return (
    <section ref={containerRef} className={`relative ${props.className || ""} `}>
      <section
        ref={scrollRef}
        className="flex  templates-container pb-0 md:bg-inherit md:pb-[20px] w-full overflow-hidden overflow-x-auto border-white border-opacity-10"
      >
        <section className={`flex md:gap-[20px] gap-[8px] transition-transform transform-gpu`}>
          {templates?.map((item: V1TemplateData, i: number) => (
            <TemplateItem
              key={item.id}
              template={item}
              className="flex"
              callback={() => openModal(i)}
            />
          ))}
        </section>
      </section>

      {scrollX <= minScrollX ? null : (
        <button
          onClick={() => {
            setNextScrollX(-scrollDistance)
          }}
          className="hidden md:block absolute z-20 top-0 left-[0px] w-[90px] h-full bg-gradient-to-r from-[#161623] to-transparent"
        >
          <ArrowLeftDarkIcon className="-ml-[15px]" />
        </button>
      )}
      {scrollX >= maxScrollX ? null : (
        <button
          onClick={() => {
            setNextScrollX(scrollDistance)
          }}
          className="hidden md:block absolute z-20 top-0 right-[0px] w-[90px] h-full bg-gradient-to-l from-[#161623] to-transparent"
        >
          <ArrowLeftDarkIcon className="rotate-180 ml-[90px]" />
        </button>
      )}

      {templateModalVisiable && (
        <TemplateDetailModal
          defaultTemplateIndex={taskIndex}
          onClose={closeModal}
          templatesCenter={templates}
        />
      )}
    </section>
  )
}

export default observer(TemplateScroll)
