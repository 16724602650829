import publicIcon from "@/assets/studio/privacy_public.svg"
import privateIcon from "@/assets/studio/privacy_private.svg"
import speedIcon from "@/assets/studio/speed.svg"
import modelIcon from "@/assets/studio/model_select_sm.svg"
import { V1Task, V1TaskPriority } from "@/generated/api/data-contracts"
import { DEFAULT_MODEL, MODELS } from "@/constants"
import { useEffect, useState } from "react"
import getScoringSkills from "@/utils/getScoringSkills"
import CheckAuth from "@/components/common/CheckAuth"

interface StatusProps {
  isPrivate: boolean
  isFastMode: boolean
  model: string
}
const Status = ({ isPrivate, isFastMode, model }: StatusProps) => {
  const privacyIconSrc = isPrivate ? privateIcon : publicIcon
  const privacyOpacity = isPrivate ? "opacity-50" : ""
  const speedOpacity = isFastMode ? "" : "opacity-50"

  return (
    <div className="flex items-center">
      <img src={privacyIconSrc} alt="" className={`w-7 h-7 ${privacyOpacity}`} />
      <img src={speedIcon} alt="" className={`w-7 h-7 ml-[10px] ${speedOpacity}`} />
      <div className="flex items-center ml-[10px]">
        <img src={modelIcon} alt="" className="w-[14px] h-[14px] opacity-50 mr-[5px]" />
        <div className="text-[12px] leading-5 opacity-50">{model}</div>
      </div>
    </div>
  )
}

interface ActionProps {
  children: React.ReactNode
  onClick?: () => void
}
const Action = ({ children, onClick }: ActionProps) => {
  return (
    <div
      onClick={() => onClick?.()}
      className="cursor-pointer text-[#6663FF] border border-[#6663FF] rounded px-[10px] py-[5px]"
    >
      {children}
    </div>
  )
}

interface TaskInfoProps {
  onRegenerate?: (sortingTags: string) => void
  onSubmitTemplate?: () => void
  task: V1Task
}
const TaskInfo = ({ onRegenerate, onSubmitTemplate, task }: TaskInfoProps) => {
  const [scoringTags, setScoringTags] = useState<string>("")

  useEffect(() => {
    if (task && task.genreScore) {
      const getScoringSkillsProps = { ...task.genreScore }
      delete getScoringSkillsProps.sharpnessScore // 质感 信息不展示
      const result = getScoringSkills(getScoringSkillsProps)
      setScoringTags(result.tags.join("，"))
    }
  }, [task])

  const taskPriority = task.priority as V1TaskPriority
  const taskModel = task.model
  const modelName = MODELS.find((model) => model.id === taskModel)?.name || "内测模型"

  return (
    <div className="flex items-center justify-between">
      <Status
        isPrivate={task.isPrivate ?? true}
        isFastMode={taskPriority === V1TaskPriority.TaskPriorityHigh}
        model={modelName}
      />

      {/* Actions */}
      <div className="flex items-center gap-x-[10px]">
        <CheckAuth
          onClick={() => {
            onSubmitTemplate?.()
          }}
        >
          <Action>提交模板</Action>
        </CheckAuth>

        <Action
          onClick={() => {
            onRegenerate?.(scoringTags)
          }}
        >
          再次生成
        </Action>
      </div>
    </div>
  )
}

export default TaskInfo
