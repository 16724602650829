import { Tabs } from "@arco-design/web-react"
import "./index.less"
import { useEffect, useState } from "react"
import { V1ActivityState, V1TemplateData } from "@/generated/api/data-contracts"
import templateStore from "@/store/templateStore"
import { observer } from "mobx-react-lite"
import { ReactComponent as ArrowRightDarkIcon } from "@/assets/arrow_right_dark.svg"
import MobileBottomModal from "../MobileBottomModal"
import OptionItem from "./OptionItem"
import activityStore from "@/store/activityStore"

interface ActivitySelectProps {
  handleChecked: (activity: string) => void
  activtiyId?: string
}

const ActivitySelect = observer(({ handleChecked, activtiyId }: ActivitySelectProps) => {
  const [colspan, setColspan] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const { onGoingActivities } = activityStore.store.get()
  const ongoingActivity = onGoingActivities?.find((item) => item.uid === activtiyId)
  const checkedActivity = {
    label: ongoingActivity?.title || "",
    value: ongoingActivity?.uid || "",
  }
  const activitiesOptions = onGoingActivities?.map((item) => {
    return { label: item.title || "", value: item.uid || "" }
  })
  activitiesOptions.unshift({ label: "未参与活动", value: "" })
  useEffect(() => {
    activityStore.getActivityList()
  }, [])
  const openModal = () => {
    setIsModalVisible(true)
  }
  return (
    <>
      <div
        className="activity_mobile_select"
        onClick={() => {
          setColspan(true)
          openModal()
        }}
      >
        <span>
          {/*  只有开始的活动才展示 */}
          {checkedActivity?.label || "请选择活动"}
        </span>
        <ArrowRightDarkIcon className={`${colspan ? "rotate-90" : ""}`} />
      </div>
      {isModalVisible === true && (
        <MobileBottomModal
          titleHidden
          onClose={() => {
            setIsModalVisible(false)
          }}
          modalStyle={{
            paddingBottom: "20px",
          }}
          contentStyle={{
            marginTop: "0px",
          }}
        >
          <OptionItem
            list={activitiesOptions}
            handleChecked={(activity) => {
              setIsModalVisible(false)
              handleChecked(activity?.value)
              setColspan(false)
            }}
            checked={checkedActivity}
          />
        </MobileBottomModal>
      )}
    </>
  )
})

export default ActivitySelect
