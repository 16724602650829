import { api } from "./api"
import md5 from "crypto-js/md5"
import base64 from "crypto-js/enc-base64"
import { LogicError } from "@/types"
import {
  V1ApplyInviteCodeRequest,
  V1CurrentUserInfo,
  V1RegisterByPhone,
  V1VerifyInviteCodeRequest,
} from "../generated/api/data-contracts"
import loginState from "@/utils/loginState"
/**
 * 发送sms短信
 * @param phone
 */
export const sendSmsCode = async (
  phone: string,
  captchaID: string,
  imageCode: string
): Promise<string | undefined> => {
    const res = await api.getLoginVerificationCode({ phone, captchaID, imageCode })
    if (!res.data.success) {
      throw new LogicError(res.data.errorCode, res.data.errorMessage)
    }
    return res.data.data!.verificationCode
  }
/**
 * 校验验证码
 * @param phone
 */
export const verifyPhoneCode = async (req: {
  phone: string
  verificationCode: string
}): Promise<V1CurrentUserInfo | undefined> => {
  const res = await api.loginByPhone(req)
  if (!res.data.success) {
    throw new LogicError(res.data.errorCode, res.data.errorMessage)
  }
  return res.data.data
}

// 注册
export const registerByPhone = async (
  req: V1RegisterByPhone
): Promise<V1CurrentUserInfo | undefined> => {
  // req.inviteCode = base64.stringify(md5(req.inviteCode))
  const res = await api.registerByPhone(req)
  if (!res.data.success) {
    throw new LogicError(res.data.errorCode, res.data.errorMessage)
  }
  return res.data.data
}

// qc注册
export const loginByPhoneQc = async (req: any): Promise<V1CurrentUserInfo | undefined> => {
  // req.inviteCode = base64.stringify(md5(req.inviteCode))
  const res = await api.loginByPhoneQc(req)
  if (!res.data.success) {
    throw new LogicError(res.data.errorCode, res.data.errorMessage)
  }
  return res.data.data
}

// 验证邀请码
export const verifyInviteCode = async (
  req: V1VerifyInviteCodeRequest
): Promise<V1CurrentUserInfo | undefined> => {
  req.inviteCode = base64.stringify(md5(req.inviteCode))
  const res = await api.verifyInviteCode(req)
  if (!res.data.success) {
    throw new LogicError(res.data.errorCode, res.data.errorMessage)
  }
  return res.data.data
}

// 校验邀请码
export const loginByPhoneWithInviteCode = async (
  inviteCode: string
): Promise<V1CurrentUserInfo | undefined> => {
  // 邀请码需要md5加密
  const code = base64.stringify(md5(inviteCode))
  const res = await api.verifyInviteCode({
    inviteCode: code,
  })
  if (!res.data.success) {
    throw new LogicError(res.data.errorCode, res.data.errorMessage)
  }
  return res.data.data
}

// 申请邀请码applyInviteCode
export const applyInviteCode = async (
  req: V1ApplyInviteCodeRequest
): Promise<V1CurrentUserInfo | undefined> => {
  const res = await api.applyInviteCode(req)
  if (!res.data.success) {
    throw new LogicError(res.data.errorCode, res.data.errorMessage)
  }
  return res.data.data
}

/**
 * 登出
 */
export const logout = async () => {
  try {
    const res = await api.logout()
    if (!res.data.success) {
      throw new LogicError(res.data.errorCode, res.data.errorMessage)
    }
  } catch (err) {
    console.log(err)
  }
}

export const guestCheckHasCreatedTask = async (): Promise<boolean> => {
  try {
    const res = await api.guestCheckHasCreatedTask({ format: "json" }).catch((e) => e)
    return !!res.data.data?.created
  } catch (error) {
    return false
  }
}

export const getCurrentUserInfo = async (): Promise<V1CurrentUserInfo | undefined> => {
  const res = await api.getCurrentUserInfo({ format: "json" }).catch((e) => e)
  return res?.data?.data
}

// 获取用户信息
export const getUserInfo = async () => {
  const currentUserInfo = await getCurrentUserInfo()
  return currentUserInfo
}

const requestGetBasicUserInfo = async () => {
  const res = await api.getUserBasicInfo({ format: "json" }).catch((e) => e)
  return res?.data?.data
}

export const getBasicUserInfo = async () => {
  const basicUserInfo = await requestGetBasicUserInfo()
  const hasBasicUserInfo = !!basicUserInfo
  loginState.change(hasBasicUserInfo)

  if (hasBasicUserInfo) {
    return basicUserInfo
  } else {
    const visitorCreated = await guestCheckHasCreatedTask()
    return { visitorCreated }
  }
}


export const getImageVerifyCode = async (): Promise<any | undefined> => {
  try {
    // @ts-ignore
    const res = await api.generateCaptcha()
    return res.data!.data
  } catch (err) {
    console.log(err)
  }
}
