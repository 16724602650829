import {
  V1CreateOrderResp,
  V1ProductInfo,
  RepoOrder,
  V1ListOrdersResponse,
  V1UpgradePlanInfo,
  V1ConsumePriority,
  V1UpdateConsumePriorityResponse,
  V1CoinRedeemDesc,
  V1GetUserCoinInfoResponse,
  V1RedeemCoinResponse,
} from "@/generated/api/data-contracts"
import { LogicError } from "@/types"
import { api } from "./api"

/**
 * 获取当前套餐信息
 * @returns
 */
export const getSubscriptionList = async (): Promise<V1ProductInfo[] | undefined> => {
  const res = await api.getProductList({ format: "json" })
  if (!res.data.success) {
    throw new LogicError(res.data.errorCode, res.data.errorMessage)
  }
  return res.data.data
}

/**
 *
 * 订阅套餐信息
 * @param productId
 * @returns
 */
export const getPaymentUrl = async (
  productId: number,
  discountCode = "",
  productAmount = 1
): Promise<V1CreateOrderResp | undefined> => {
  const res = await api.subscribeProduct(
    productId,
    { productAmount, discountCode },
    { format: "json" }
  ) // productAmount: 1-普通套餐， n-加量包

  if (!res.data.success) {
    throw new LogicError(res.data.errorCode, res.data.errorMessage)
  }
  return res.data.data
}

/**
 *
 * 获取升级套餐订阅的QRCode
 * @param productId
 * @returns
 */
export const upgradePlan = async (
  productId: number,
  discountCode = ""
): Promise<V1CreateOrderResp | undefined> => {
  const res = await api.upgradePlan(productId, { discountCode }, { format: "json" })
  if (!res.data.success) {
    throw new LogicError(res.data.errorCode, res.data.errorMessage)
  }
  return res.data.data
}

/**
 *
 * 获取升级套餐订阅的详细信息
 * @param productId
 * @returns
 */
export const getUpgradePlanInfo = async (
  productId: number
): Promise<V1UpgradePlanInfo | undefined> => {
  const res = await api.getUpgradePlanInfo(productId, { format: "json" })
  if (!res.data.success) {
    throw new LogicError(res.data.errorCode, res.data.errorMessage)
  }
  return res.data.data
}

/**
 * 根据订单ID获取订单详情
 * @param id
 * @returns
 */
export const getOrderDetail = async (id: string): Promise<RepoOrder> => {
  const res = await api.getOrderById(id, { format: "json" })
  if (!res.data.success) {
    throw new LogicError(res.data.errorCode, res.data.errorMessage)
  }
  return res.data.data
}

/**
 * 根据订单ID获取订单详情
 * @param id
 * @returns
 */
export const getOrderList = async (query: {
  current?: number | undefined
  pageSize?: number | undefined
}): Promise<V1ListOrdersResponse> => {
  const res = await api.getOrderList(query, { format: "json" })
  if (!res.data.success) {
    throw new LogicError(res.data.errorCode, res.data.errorMessage)
  }
  return res.data.data
}

/**
 * 根据订单ID获取订单详情
 * @param id
 * @returns
 */
export const updateConsumeSupplementPriority = async (
  consumePriority: V1ConsumePriority
): Promise<V1UpdateConsumePriorityResponse> => {
  const res = await api.updateConsumePriority(
    {
      consumePriority,
    },
    { format: "json" }
  )
  if (!res.data.success) {
    throw new LogicError(res.data.errorCode, res.data.errorMessage)
  }
  return res.data.data
}

// 查询灵石兑换权益

export const getListCoinRedeems = async (): Promise<V1CoinRedeemDesc[]> => {
  const res = await api.listCoinRedeems({ format: "json" })
  if (!res.data.success) {
    throw new LogicError(res.data.errorCode, res.data.errorMessage)
  }
  return res.data.data
}

// 查询 用户灵石相关信息

export const getUserCoinInfo = async (): Promise<V1GetUserCoinInfoResponse> => {
  const res = await api.getUserCoinInfo({ format: "json" })
  if (!res.data.success) {
    throw new LogicError(res.data.errorCode, res.data.errorMessage)
  }
  return res.data.data
}
interface V1RedeemCoinResponseCustom {
  data?: {
    data: V1RedeemCoinResponse
  }
  success?: boolean
}
// 使用灵石兑换权益
export const getRedeemCoin = async (id: string): Promise<V1RedeemCoinResponseCustom> => {
  const res = await api.redeemCoin(id, { format: "json" })
  if (!res.data.success) {
    throw new LogicError(res.data.errorCode, res.data.errorMessage)
  }
  return res.data
}
