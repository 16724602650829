import { Button, Modal, Tabs } from "@arco-design/web-react"
import Card from "@/components/bussiness/userCenter/Card"
import { toastError, toastSuccess } from "@/common/Toast"
import handleClickCopy from "@/utils/handleClickCopy"
import { useEffect, useState, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { api } from "@/services/api"
import { Link } from "react-router-dom"
import {
  V1DailyCheckInRewardDesc,
  V1DiscountCodeType,
  V1ListRewardsResponse,
  V1RegistInvitationRewardDesc,
  V1SubscribeInvitationRewardDesc,
  V1UserDiscountCodeItem,
  V1UserInviteCodeItem,
} from "@/generated/api/data-contracts"
import * as store from "@/store"
import "./taskCenter.less"
import { ErrorCode } from "@/i18next"
import TabPane from "@arco-design/web-react/es/Tabs/tab-pane"
import CoinButton from "@/common/SpiritButton"
import TaskBar from "./TaskBar"
import {
  receiveDailyCheckInReward,
  receiveTemplateHeatTargetReward,
  receiveTemplateSubmissionCountTargetReward,
  receiveTemplateSubmissionReward,
  receiveUserGenerationCountTargetReward,
} from "@/services/personal"
import { isMobileOnly } from "react-device-detect"
export default function TaskCenter({ changeUpdateKey }: { changeUpdateKey: () => void }) {
  const { t } = useTranslation(["translation"])
  const { subscribedProduct } = store.user.get()
  const [rewardDetails, setRewardDetails] = useState<V1ListRewardsResponse>({})
  const [activeTab, setActiveTab] = useState("daily")
  const [loading, setLoading] = useState({
    dailyLoading: false,
    submitLoading: false,
    heatLoading: false,
    countLoading: false,
    generateLoading: false,
  })
  async function getTaskCenterData() {
    try {
      const { data } = await api.listRewards()
      if (data.success) {
        data.data && setRewardDetails(data.data)
      } else {
        toastError(t(`errorMessage:${data.errorCode}` as any))
      }
    } catch (e) {
      toastError("Error" + e)
    }
  }
  // 共用 toast
  const getToast = async (fn: () => Promise<any>, l: string) => {
    setLoading({ ...loading, [l]: true })
    try {
      const { success, errorCode, errorMessage } = await fn()
      if (success) {
        //  调用任务中心刷新按钮状态
        getTaskCenterData()
        //  刷新导航栏灵石数量
        store.refreshUser()
        toastSuccess("领取成功")
      } else {
        toastError(t(`errorMessage:${errorCode}` as any))
      }
    } catch (e) {
      toastError("Error" + e)
    }
    setLoading({ ...loading, [l]: false })
  }
  useEffect(() => {
    getTaskCenterData()
  }, [])
  const isFree = useMemo(() => {
    return !subscribedProduct?.title
  }, [subscribedProduct])
  return (
    <Card className="task-center-wrapper">
      <Tabs
        defaultActiveTab="daily"
        className={"task-center-tabs"}
        onChange={(key) => {
          setActiveTab(key)
        }}
        extra={
          <>
            {isFree && (
              <Link to="/subscription">
                <span className="text-[#6663FF] border-[1px] border-[#6663FF] rounded-[4px] py-[5px] px-[14px]">
                  升级套餐奖励加成
                </span>
              </Link>
            )}
          </>
        }
      >
        <TabPane
          key="daily"
          title={<span className={isMobileOnly ? "tab-mobile" : "tab"}>每日任务</span>}
        ></TabPane>
        <TabPane
          key="career"
          title={<span className={isMobileOnly ? "tab-mobile" : "tab"}>生涯任务</span>}
        ></TabPane>
      </Tabs>
      {activeTab === "daily" && (
        <>
          <TaskBar
            title="每日签到"
            data={rewardDetails.dailyCheckInReward || {}}
            onClick={() => getToast(receiveDailyCheckInReward, "dailyLoading")}
            loading={loading.dailyLoading}
            progerssShow={false}
            icon={<div></div>}
            tip={"每天进来看看，即可领取20灵石!"}
            type="daily"
          />
          <div className="h-[1px] border-t-[1px] border-dashed border-gray-600 md:my-[10px]"></div>
          <TaskBar
            title="提交模板"
            data={rewardDetails.templateSubmissionReward || {}}
            onClick={() => getToast(receiveTemplateSubmissionReward, "submitLoading")}
            loading={loading.submitLoading}
            progerssShow={false}
            icon={<div></div>}
            tip={"提交优质模版，审核通过后即可获得奖励!"}
            type="submitTemplate"
            showCount={true}
          />
          <div className="h-[1px] border-t-[1px] border-dashed border-gray-600 md:my-[10px]"></div>
          {/* <SubscribeInvitation
            isFree={isFree}
            changeUpdateKey={changeUpdateKey}
            subscribeInvitationReward={rewardDetails.subscribeInvitationReward}
          /> */}
        </>
      )}
      {activeTab === "career" && (
        <>
          <TaskBar
            title="模板热度值"
            data={rewardDetails.templateHeatReachTargetReward || {}}
            onClick={() => getToast(receiveTemplateHeatTargetReward, "heatloading")}
            loading={loading.heatLoading}
            tooltip="可领取快速生成时间哦"
            type="heat"
          />
          <div className="h-[1px] border-t-[1px] border-dashed border-gray-600 mt-[10px]"></div>
          <TaskBar
            title="模板数量"
            data={rewardDetails.templateSubmissionCountReachTargetReward || {}}
            onClick={() => getToast(receiveTemplateSubmissionCountTargetReward, "countLoading")}
            loading={loading.countLoading}
            tooltip="可领取快速生成时间哦"
            type="templateNumber"
          />
          <div className="h-[1px] border-t-[1px] border-dashed border-gray-600 mt-[10px]"></div>
          <TaskBar
            title="生成次数"
            data={rewardDetails.userGenerationCountReachTargetReward || {}}
            loading={loading.generateLoading}
            onClick={() => getToast(receiveUserGenerationCountTargetReward, "generateLoading")}
            tooltip="可领取快速生成时间哦"
            type="generateCount"
          />
        </>
      )}
      {/* 还有可能再次使用 */}
      {/* <DailyCheckIn
        changeUpdateKey={changeUpdateKey}
        isFree={isFree}
        dailyCheckInReward={rewardDetails.dailyCheckInReward}
      />
      <RegistInvitation
        isFree={isFree}
        changeUpdateKey={changeUpdateKey}
        registInvitationReward={rewardDetails.registInvitationReward}
      />
    */}
    </Card>
  )
}

const DailyCheckIn = ({
  isFree,
  dailyCheckInReward,
  changeUpdateKey,
}: {
  isFree: boolean
  changeUpdateKey: () => void
  dailyCheckInReward?: V1DailyCheckInRewardDesc
}) => {
  // daily
  const { t } = useTranslation(["translation"])
  const canReceive = !!dailyCheckInReward?.canReceive

  const [loadingDaily, setLoadingDaily] = useState(false)
  async function receiveDailyAward() {
    try {
      setLoadingDaily(true)
      const { data } = await api.receiveDailyCheckInReward()
      if (data.success) {
        toastSuccess("生成次数已添加")
        changeUpdateKey()
      } else {
        toastError(t(`errorMessage:${data.errorCode}` as any))
      }
    } catch (e) {
      toastError("Error" + e)
    } finally {
      setLoadingDaily(false)
    }
  }
  return (
    <>
      <section className="task-center-row">
        <div className="task-center-row-left">
          <p className="sign-message">
            每日签到 <CoinButton text="+5" />
            <span className="sign-effect">
              {isFree ? "+5次免费生成次数 " : "+2min快速生成时间（～12次)"}
            </span>
          </p>
          <p className="sign-tip">每天进来看看,即可获得更多快速生成{isFree ? "次数" : "时间"}!</p>
        </div>
        <div className="task-center-row-right">
          <Button
            type="primary"
            loading={loadingDaily}
            style={{ minWidth: "83px" }}
            size="large"
            onClick={() => receiveDailyAward()}
            className="rounded-sm px-6 task-center-btn"
            disabled={!canReceive}
          >
            领取奖励
          </Button>
          {!canReceive && <p className="sign-tip mt-3">今日奖励已领取，请明天再来吧</p>}
        </div>
      </section>
    </>
  )
}

const RegistInvitation = ({
  isFree,
  registInvitationReward,
  changeUpdateKey,
}: {
  isFree: boolean
  changeUpdateKey: () => void
  registInvitationReward?: V1RegistInvitationRewardDesc
}) => {
  const { t } = useTranslation(["translation", "errorMessage"])

  const [loading, setloading] = useState(false)
  const [invitationCodeList, setInvitationCodeList] = useState<V1UserInviteCodeItem[]>([])

  const getInvitationCodeList = async () => {
    try {
      const { data } = await api.listUserInviteCodes()
      if (data.success) {
        setInvitationCodeList(data.data?.items || [])
      } else {
        toastError(t(`errorMessage:${data.errorCode as ErrorCode}`))
      }
    } catch (e) {
      toastError("Error" + e)
    }
  }
  useEffect(() => {
    getInvitationCodeList()
  }, [])

  const usedCodeLength = useMemo(() => {
    return invitationCodeList.filter((item) => !!item.usedAt).length
  }, [invitationCodeList])

  async function receiveInvitionAward() {
    try {
      setloading(true)
      const { data } = await api.receiveRegistInvitationReward()
      if (data.success) {
        changeUpdateKey()
        toastSuccess("生成次数已添加")
      } else {
        toastError(data.errorMessage || "")
      }
    } catch (e) {
      toastError("Error" + e)
    } finally {
      setloading(false)
    }
  }

  const canAccessInvitionBtn = (registInvitationReward?.receivableCount || 0) > 0

  return (
    <>
      <section className="task-center-row">
        <div className="task-center-row-left">
          <p className="sign-message">
            邀请好友
            <span className="sign-effect">
              {isFree ? "+20次免费生成次数 " : "+5min快速生成时间（～30次）"}
            </span>
          </p>
          <p className="sign-tip">
            将邀请码分享给你的好友,完成注册后可获得更多快速生成{isFree ? "次数" : "时间"}!
          </p>
          <Button
            className={"mt-3 text-[#6663ff]"}
            type="outline"
            size="small"
            loading={loading}
            onClick={async () => {
              Modal.info({
                className: "task-center-wrapper user-center-task-modal",
                closable: true,
                footer: null,
                title: `我的邀请码${usedCodeLength}/${invitationCodeList.length}`,
                content: (
                  <>
                    {invitationCodeList.map((item) => {
                      return (
                        <div className="share-link" key={item.code}>
                          <span className={`share-link-text select-none `}>{item.code}</span>
                          <Button
                            disabled={!!item?.usedAt}
                            className={"task-center-btn"}
                            type="primary"
                            size="small"
                            onClick={() => handleClickCopy(item.code ?? "")}
                          >
                            复制
                          </Button>
                        </div>
                      )
                    })}
                  </>
                ),
              })
            }}
          >
            查看邀请码
          </Button>
        </div>
        <div className="task-center-row-right">
          <p className="sign-tip mb-2.5">
            当前邀请人数：{usedCodeLength}/{invitationCodeList.length}
          </p>

          <Button
            type="primary"
            loading={loading}
            style={{ minWidth: "83px" }}
            size="large"
            onClick={() => receiveInvitionAward()}
            className="rounded-sm task-center-btn"
            disabled={!canAccessInvitionBtn}
          >
            领取奖励
          </Button>
        </div>
      </section>
    </>
  )
}

const SubscribeInvitation = ({
  isFree,
  subscribeInvitationReward,
  changeUpdateKey,
}: {
  isFree: boolean
  changeUpdateKey: () => void
  subscribeInvitationReward?: V1SubscribeInvitationRewardDesc
}) => {
  const { t } = useTranslation(["translation", "errorMessage"])

  const [loading, setLoading] = useState(false)
  const [subscribeCode, setSubscribeCode] = useState("Subscribe Link")

  const getSubscribeCode = async () => {
    try {
      const { data } = await api.listUserDiscountCodes()
      if (data.success) {
        const countCodeItems = (data?.data?.items as V1UserDiscountCodeItem[]) || []
        const currentCodeItem = countCodeItems.find(
          ({ type }) => type === V1DiscountCodeType.DiscountCodeTypeUserShareSubscription
        )
        setSubscribeCode(currentCodeItem?.code || "")
      } else {
        toastError(t(`errorMessage:${data.errorCode as ErrorCode}`) || data.errorMessage || "")
      }
    } catch (e) {
      toastError("Error" + e)
    }
  }
  useEffect(() => {
    getSubscribeCode()
  }, [])

  // async function receiveSubscribeAward() {
  //   try {
  //     setLoading(true)
  //     const { data } = await api.receiveSubscriptionInvitationReward()
  //     if (data.success) {
  //       changeUpdateKey()
  //       toastSuccess("生成次数已添加")
  //     } else {
  //       toastError(data.errorMessage || "")
  //     }
  //   } catch (e) {
  //     toastError("Error" + e)
  //   } finally {
  //     setLoading(false)
  //   }
  // }

  // const receivableCount = subscribeInvitationReward?.receivableCount || 0
  // const subscribeEXCode = subscribeInvitationReward?.currentCount || 0
  // const totalSubscribeEXCode = subscribeInvitationReward?.maxCount || 0
  // return (
  //   <>
  //     <section className={`${isMobileOnly ? "task-center-row-mobile" : "task-center-row"}`}>
  //       <div className="task-center-row-left">
  //         <p className={isMobileOnly ? "sign-message-mobile" : "sign-message"}>
  //           好友拼单订阅
  //           <span className="sign-effect">
  //             {isFree ? "赠送2天进阶订阅方案 " : "你和你的好友都能得到奖励！"}
  //           </span>
  //         </p>
  //         <p className={`${isMobileOnly ? "sign-tip-mobile" : "sign-tip"}`}>
  //           将折扣码分享给你的好友,完成订阅后可获得更多快速生成{isFree ? "次数" : "时间"}!
  //         </p>
  //         <div className="share-link" style={{ marginTop: isMobileOnly ? "0px" : "10px" }}>
  //           <span className="share-link-text">{subscribeCode}</span>
  //           <Button
  //             type="primary"
  //             style={{ backgroundColor: "#6663ff" }}
  //             size="small"
  //             onClick={() => handleClickCopy(subscribeCode)}
  //           >
  //             复制折扣码
  //           </Button>
  //         </div>
  //       </div>
  //       <div className="task-center-row-right">
  //         <p className={isMobileOnly ? "sign-tip-mobile mt-[5px]" : "sign-tip mb-2.5"}>
  //           {subscribeEXCode
  //             ? `可领取奖励${receivableCount}/${subscribeEXCode}`
  //             : "等待好友订阅中..."}
  //         </p>
  //         <Button
  //           type="primary"
  //           loading={loading}
  //           style={{ minWidth: "83px" }}
  //           size="large"
  //           onClick={() => receiveSubscribeAward()}
  //           className="task-center-btn rounded-sm"
  //           disabled={receivableCount <= 0}
  //         >
  //           领取奖励
  //         </Button>
  //       </div>
  //     </section>
  //   </>
  // )
}
