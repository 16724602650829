import Header from "./Header"
// import Footer from "./Footer"

import type { ReactNode } from "react"

interface Props {
  children?: ReactNode
  hideHeader?: boolean
  hideFooter?: boolean
}
const Footer = () => {
  return (
    <div className="w-full flex justify-center text-lg mt-2 mb-2 items-center cursor-pointer">
      <div>www.tiamat.world</div>
    </div>
  )
}
function TemporaryLayout({ children, hideHeader = false, hideFooter = false }: Props) {
  return (
    <div className="h-[100vh] bg-[#161623] text-white flex flex-col">
      {hideHeader ? null : <Header></Header>}
      <div className={`${hideHeader ? "h-[100vh]" : "h-[88vh]"}`}>{children}</div>
      {hideFooter ? null : <Footer></Footer>}
    </div>
  )
}

export default TemporaryLayout
