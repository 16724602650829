import { Avatar } from "@/common/Avatar"
import { ReactComponent as HotIcon } from "@/assets/studio/hot.svg"
import { ReactComponent as HotMobileIcon } from "@/assets/studio/mobile/hot_m.svg"
import { getImageURL } from "@/utils"

interface LikesComponentProps {
  likes: number
  liked?: boolean
  onClick?: () => void
}
const LikesComponent = ({ likes, liked, onClick }: LikesComponentProps) => {
  return (
    <div
      className="flex items-center h-6 rounded hover:cursor-pointer"
      onClick={(e) => {
        e.stopPropagation()
        onClick && onClick()
      }}
    >
      <HotIcon />
      <div className="text-white text-xs font-normal w-[35px] text-left font-sans">{likes}</div>
    </div>
  )
}

interface MobileItemProps {
  heatValue: number
  title: string
}
const MobileItemInfo = ({ heatValue, title }: MobileItemProps) => {
  return (
    <div className="absolute left-0 top-0 w-full h-full flex md:hidden flex-col justify-between">
      <div className="flex justify-end">
        <div className="flex items-center bg-black bg-opacity-30 px-1 py-[3px] rounded-bl">
          <HotMobileIcon className="w-[18px] h-[18px]" />
          <div className="ml-[2px] text-xs w-10 text-left">{heatValue}</div>
        </div>
      </div>
      <div className="bg-black bg-opacity-50 p-1 text-center text-base font-normal">{title}</div>
    </div>
  )
}

interface TemplateItemProps {
  background: string
  title: string
  avatar: string
  nick: string
  likes: number
  liked?: boolean
  onClick?: () => void
}

const TemplateItem = ({
  background,
  title,
  avatar,
  nick,
  likes,
  liked,
  onClick,
}: TemplateItemProps) => {
  return (
    <div
      className="aspect-square group bg-cover bg-left-top bg-no-repeat overflow-hidden cursor-pointer relative rounded-tl rounded-tr md:rounded flex flex-col justify-end"
      onClick={() => onClick && onClick()}
      style={{
        backgroundImage: `url(${getImageURL(background)})`,
      }}
    >
      <div className="hidden md:block transition-all translate-y-[30px] group-hover:translate-y-0 w-full mt-auto bg-black bg-opacity-50 py-[6px] px-[10px]">
        {/* Template Title */}
        <div className="text-base text-white font-semibold leading-[22px] truncate">{title}</div>

        <div className="flex items-center justify-between mt-[6px]">
          {/* Avatar */}
          <div className="mr-[5px] flex flex-1 justify-between items-center min-w-0">
            <div>
              <Avatar URL={avatar} className="w-[18px] h-[18px] rounded-full" />
            </div>
            <div className="ml-[5px] flex-1 text-white text-xs font-normal truncate">{nick}</div>
          </div>

          {/* Likes */}
          <LikesComponent likes={likes} liked={liked} />
        </div>
      </div>

      {/* Mobile */}
      <MobileItemInfo heatValue={likes} title={title} />
    </div>
  )
}

export default TemplateItem
