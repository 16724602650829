import generateIcon from "@/assets/studio/mobile/generate_m.svg"
import runningGif from "@/assets/studio/mobile/running.gif"
import studioPagesStore from "@/store/studioPagesStore"

export default ({ onClick }: { onClick?: () => void }) => {
  const isProcessing = studioPagesStore.isProcessing.get()

  return (
    <button
      className="w-[30px] h-[30px] ml-1 bg-[#4C1FFF] rounded-full flex items-center justify-center cursor-pointer"
      onClick={() => {
        if (isProcessing) return
        onClick?.()
      }}
    >
      {isProcessing ? (
        <img src={runningGif} className="w-6 h-6" alt="running" />
      ) : (
        <img src={generateIcon} className="w-6 h-6 ml-1" alt="generate" />
      )}
    </button>
  )
}
