import { V1Work } from "@/generated/api/data-contracts"
import { observer } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"
import { apiListBusyTasks } from "@/services/editor"
import { toastInfo } from "@/common/Toast"
import CheckAuth from "@/components/common/CheckAuth"
import studioPagesStore from "@/store/studioPagesStore"

const ImageRepainting = observer(function ({
  buttonContent,
  work,
}: {
  buttonContent: React.ReactNode
  work: V1Work
}) {
  const navigate = useNavigate()

  const onClick = async () => {
    if (!work.id) {
      return
    }

    const { busyTaskCount } = await apiListBusyTasks()
    if (busyTaskCount) {
      return toastInfo("当前有任务正在进行中，请稍后再试～")
    }
    // studioPagesStore.init(({ taskHistory, runingTasks }) => ({ taskHistory, runingTasks }))
    studioPagesStore.setStore({
      workId: work.id,
    })

    if (location.pathname !== "/studio") {
      navigate({
        pathname: "/studio",
      })
    }
  }

  return work.seed && work.seed > 0 && work.id ? (
    <CheckAuth onClick={onClick}>{buttonContent}</CheckAuth>
  ) : null
})

export default ImageRepainting
