import "./index.less"
import { observer } from "mobx-react-lite"
import { ReactComponent as CheckIcon } from "@/assets/checked_green.svg"
interface CheckObj {
  label: string
  value: string
}
interface ActivitySelectProps {
  list: Array<CheckObj>
  checked?: CheckObj
  handleChecked: (checkedValue: any) => void
}
const ActivityOption = observer(({ list, handleChecked, checked }: ActivitySelectProps) => {
  return (
    <div className="option_container">
      {list.length === 0 && <div>暂无活动</div>}
      {list?.map((item: CheckObj, index: number) => (
        <div
          key={item?.value}
          onClick={() => {
            handleChecked(item)
          }}
        >
          <div className="option">
            <span>{item?.label}</span>
            {item.value === checked?.value && <CheckIcon />}
          </div>
          {index < list?.length - 1 && <div className="hr"></div>}
        </div>
      ))}
    </div>
  )
})

export default ActivityOption
