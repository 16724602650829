import studioPagesStore from "@/store/studioPagesStore"
import ReferenceImage from "./ReferenceImage"
import SelectedModel from "./SelectedModel"
import { CONTROLNETMODELLIST } from "@/constants"
import { useEffect, useState } from "react"
interface ControlNetResultProps {
  onUpload?: (mode: Array<string>) => void
  onRemove: (index: number) => void
}
interface unitsNameList {
  moduleName?: string
  module?: string
  inputImageURL?: string
}
const ControlNetResult = ({ onUpload, onRemove }: ControlNetResultProps) => {
  const [current, setCurrent] = useState(0)
  const [controlNetList, setControlNetList] = useState<Array<unitsNameList>>([])
  const { referencelIamgeData } = studioPagesStore.store.get()
  const referencelIamgeHaveDatacontrolNet =
    referencelIamgeData?.type === "controlNet" && referencelIamgeData?.payload?.controlNet?.units
  useEffect(() => {
    if (referencelIamgeData?.type === "controlNet") {
      let unitsList = referencelIamgeData?.payload?.controlNet?.units ?? []
      let unitsNameList = unitsList?.map((units: unitsNameList) => ({
        ...units,
        moduleName: CONTROLNETMODELLIST.find((item) => item.value === units?.module)?.label,
      }))
      setControlNetList(unitsNameList as Array<unitsNameList>)
    }
  }, [referencelIamgeData])
  // Update ControlNet Model
  const handleModelSelect = (model: string) => {
    if (referencelIamgeData?.type === "controlNet") {
      const newData = { ...referencelIamgeData }
      if (newData?.payload?.controlNet?.units?.[current]?.module) {
        newData.payload.controlNet.units[current].module = model
      }
      studioPagesStore.setStore({
        referencelIamgeData: newData,
      })
    }
  }
  // const controlnetModelLabel = CONTROLNETMODELLIST.find((item) => item.value === model)?.label

  if (!controlNetList?.length) return <div className="flex mt-[-10px]"></div>
  return (
    <div>
      <div className="flex gap-[8px] mb-[10px]">
        {/* 只有一个 controlNet 不展示 */}
        {controlNetList?.length > 1 &&
          controlNetList?.map((item, index) => {
            return (
              <div
                key={`${item.module}-${index}`}
                onClick={() => setCurrent(index)}
                className={`${
                  index === current ? "bg-[#4C1FFF] " : "bg-[#212134]"
                } text-white rounded-[4px] px-[12px] py-[4px] flex-[1]`}
              >{`参考${index + 1}`}</div>
            )
          })}
      </div>

      {/* Selected Model */}
      <SelectedModel
        modelName={controlNetList?.[current]?.moduleName ?? ""}
        controlNetModule={current === 1 ? controlNetList?.[0]?.module : controlNetList?.[1]?.module}
        onModelSelect={handleModelSelect}
      />

      {/* Reference Image */}
      <div className="mt-[10px]">
        <ReferenceImage
          onImageClick={() => {
            const modelList = referencelIamgeHaveDatacontrolNet
              ? referencelIamgeData?.payload?.controlNet?.units?.map(
                  (item: unitsNameList) => item.module
                )
              : []
            onUpload?.(modelList as Array<string>)
          }}
          onRemoveClick={(index) => {
            onRemove(index)
            setCurrent(0)
          }}
          imageSrc={controlNetList?.[current]?.inputImageURL ?? ""}
          index={current}
        />
      </div>
      {/*  Add  Reference Image */}
      {/* ts校验 */}
      {referencelIamgeHaveDatacontrolNet &&
        referencelIamgeData?.payload?.controlNet?.units?.length === 1 && (
          <div
            className="border-dashed border-[1px] border-gray-600 rounded-[4px] mt-[10px] p-[10px] w-full flex justify-center font-normal"
            onClick={() => {
              const modelList =
                referencelIamgeData?.payload?.controlNet?.units?.map(
                  (item: unitsNameList) => item.module
                ) || []
              let doubleModelList = [...modelList, ...modelList] as Array<string>
              onUpload?.(doubleModelList)
            }}
          >
            + 增加参考
          </div>
        )}
    </div>
  )
}

export default ControlNetResult
