import { IconClose } from "@arco-design/web-react/icon"
import "./index.less"
import { ProviderProps, PopoverContentProps, useTour } from "@reactour/tour"
import { ReactNode, useCallback } from "react"
import { keys } from "mobx"
import _ from "lodash"

export const TourLayout = ({
  props,
  more,
  localKey,
  children,
}: {
  children: ReactNode
  localKey: string
  more?: string
  props: PopoverContentProps
}) => {
  const { currentStep, steps, setIsOpen, setCurrentStep } = props
  const currentItem = steps[currentStep]

  const isLastStep = currentStep === steps.length - 1
  const close = () => {
    localStorage.setItem(localKey, "true")
    setIsOpen(false)
  }

  return (
    <div
      className={`user-guide-content ${currentItem.position}-arrow bg-[#35353A] text-[#fff] py-4 px-3 rounded-md relative`}
    >
      <IconClose
        onClick={close}
        className="text-xl top-4 right-4 hover:text-[#ffffffc5] cursor-pointer rounded-full absolute"
      />
      <div>{children}</div>
      <div className="mt-4 flex items-center justify-between">
        <div className="text-[#ffffff33]">
          {currentStep + 1}/{steps.length}
        </div>
        <div>
          {more && (
            <a href={more}>
              <button className=" hover:bg-[#00000012] mr-2 px-3 py-2 rounded">了解详情</button>
            </a>
          )}
          {!isLastStep ? (
            <button
              className="bg-[#4C1FFF] px-3 py-2 rounded"
              onClick={() => {
                if (isLastStep) {
                  close()
                } else {
                  setCurrentStep((s: number) => s + 1)
                }
              }}
            >
              下一步
            </button>
          ) : (
            <button className="bg-[#4C1FFF] px-3 py-2 rounded" onClick={close}>
              关闭
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

function ContentComponent(props: PopoverContentProps) {
  const { currentStep, steps } = props
  const content = steps[currentStep].content as (props: PopoverContentProps) => JSX.Element
  return content(props)
}

const root = document.getElementById("root") || document.body

export const tourProviderProps: Omit<ProviderProps, "children"> = {
  steps: [],
  padding: 0,
  defaultOpen: false,
  afterOpen: () => {
    // 清除默认样式， 包裹样式都有TourLayout提供
    const wrapperDOM: HTMLDivElement = document.querySelector(".user-guide") as HTMLDivElement
    wrapperDOM.style.backgroundColor = ""
    wrapperDOM.style.padding = "0"
    wrapperDOM.style.borderRadius = "4px"
    wrapperDOM.style.marginTop = "10px"
    return (root.style.overflow = "hidden")
  },
  beforeClose: () => (root.style.overflow = "auto"),
  showBadge: false,
  showNavigation: false,
  showDots: false,
  ContentComponent,
  className: "user-guide",
  styles: {
    maskArea: (base) => ({ ...base, rx: 5 }),
    maskWrapper: (base) => ({ ...base, color: "rgba(0, 0, 0, 0.8)" }),
  },
  onClickMask: () => {
    //
  },
}

const stepsConfig = {
  "tour-studio": [
    {
      selector: ".tour-studio-1",
      position: "bottom",
      content: (props: PopoverContentProps) => {
        return (
          <>
            <TourLayout
              localKey="tour-studio"
              props={props}
              more="/user-guide?key=description-guide"
            >
              <h2 className="text-xl pr-3">输入描述语</h2>
              <div className="w-full h-40">img</div>
              <div className="text-[#ffffff7e]">
                <div className="">现象你希望得到的画面，并尝试将它描述出来</div>
              </div>
            </TourLayout>
          </>
        )
      },
    },
    {
      selector: ".tour-studio-2",
      position: "bottom",
      content: (props: PopoverContentProps) => (
        <>
          <TourLayout localKey="tour-studio" props={props}>
            <h2 className=" text-xl pr-3">随机描述语</h2>
            <div className="w-full h-40">img</div>
            <div className="text-[#ffffff7e]">
              <div className="">您可以点击此按钮，随机出现描述语，更加快速的进行创作</div>
            </div>
          </TourLayout>
        </>
      ),
    },
    {
      selector: ".tour-studio-3",
      position: "bottom",
      content: (props: PopoverContentProps) => (
        <>
          <TourLayout localKey="tour-studio" props={props} more="/user-guide?key=controlnet">
            <h2 className=" text-xl pr-3">进阶功能</h2>
            <div className="w-full h-40">img</div>
            <div className="text-[#ffffff7e]">
              <p>您可以点击这里，来进行进阶操作</p>
              <p>
                <strong className="text-[#fff]">[图像会说话]</strong> 功能可将图像转化为描述语
              </p>
              <p>
                <strong className="text-[#fff]">[上传参考图]</strong> 功能将基于图像进行生成
              </p>
              <p>
                <strong className="text-[#fff]">[ControlNet]</strong>{" "}
                功能能基于图像本身信息进行更好的生成，比如根据线稿生成完成作品
              </p>
            </div>
          </TourLayout>
        </>
      ),
    },
    {
      selector: ".tour-studio-4",
      position: "bottom",
      content: (props: PopoverContentProps) => (
        <>
          <TourLayout localKey="tour-studio" props={props} more="/user-guide?key=image-proportion">
            <h2 className=" text-xl pr-3">选择图像比例</h2>
            <div className="w-full h-40">img</div>
            <div className="text-[#ffffff7e]">
              此模块可以选择你想要的图像比例请注意，不同图像比例对画面影响是不同的，一般来说，横构图更适合场景图像，竖构图更适合人像
            </div>
          </TourLayout>
        </>
      ),
    },
    {
      selector: ".tour-studio-5",
      position: "bottom",
      content: (props: PopoverContentProps) => (
        <>
          <TourLayout localKey="tour-studio" props={props} more="/user-guide?key=model-version">
            <h2 className=" text-xl pr-3">选择模型</h2>
            <div className="w-full h-40">img</div>
            <div className="text-[#ffffff7e]">
              <p>在这里选择不同的模型，以生成不同类型的类型的作品。</p>
              <p>一般来说，通用基础模型能够适用于大多场景，通用插图模型会让画面更加平面。</p>
            </div>
          </TourLayout>
        </>
      ),
    },
    {
      selector: ".tour-studio-6",
      position: "bottom",
      content: (props: PopoverContentProps) => (
        <>
          <TourLayout localKey="tour-studio" props={props} more="/user-guide?key=quick-start">
            <h2 className=" text-xl pr-3">生成</h2>
            <div className="w-full h-40">img</div>
            <div className="">
              点击 <strong className="text-[#fff]">[生成]</strong> 按钮，生成你的第一幅创作
            </div>
          </TourLayout>
        </>
      ),
    },
  ],
  "tour-studio-tag-card": [
    {
      selector: ".tour-studio-tag-card-1",
      position: "top",
      content: (props: PopoverContentProps) => {
        return (
          <>
            <TourLayout
              localKey="tour-studio-tag-card"
              props={props}
              more="/user-guide?key=description-guide"
            >
              <h2 className="text-xl pr-3">AI生成分</h2>
              <div className="w-full h-40">img</div>
              <div className="text-[#ffffff7e]">
                <p>在每次创作结束后，我的AI评分模型，都会对于你本次创作进行评分。</p>
                <p>期待你的每一次进步！</p>
              </div>
            </TourLayout>
          </>
        )
      },
    },
    {
      selector: ".tour-studio-tag-card-2",
      position: "top",
      content: (props: PopoverContentProps) => {
        return (
          <>
            <TourLayout
              localKey="tour-studio-tag-card"
              props={props}
              more="/user-guide?key=description-guide"
            >
              <h2 className="text-xl pr-3">二次操作</h2>
              <div className="w-full h-40">img</div>
              <div className="text-[#ffffff7e]">
                <p>悬浮在图像上，出现操作弹窗，在这里。</p>
                <p>
                  在这里，你可以用表情来评价这次生成结果，当然！如果遇到满意的作品，可以点击喜欢按钮收藏它。
                </p>
                <p>在这里你还可以进行图像放大和图像重绘等操作，</p>
              </div>
            </TourLayout>
          </>
        )
      },
    },
  ],
}

export const useTourHooks = ({ key }: { key: string }) => {
  const { setSteps, setIsOpen, setCurrentStep } = useTour()
  const steps = useCallback(() => {
    return _.get(stepsConfig, key, [])
  }, [keys])
  const openTour = (index = 0) => {
    // TODO 关闭新手引导
    return

    if (localStorage.getItem(key) !== "true") {
      setSteps?.(steps)
      setCurrentStep(index)
      setTimeout(() => {
        setIsOpen(true)
      }, 0)
    }
  }

  return { openTour }
}
