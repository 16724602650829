import { default as ky, Options } from "ky"
import { observable, flow, computed } from "mobx"
import { useMemo } from "react"
import { HttpResponse } from "../generated/api/http-client"
import { ResponseResponseWrapper, V1RegistSourceV2 } from "../generated/api/data-contracts"
import { UserDataType } from "../types"
import { getBasicUserInfo, getUserInfo } from "../services/login"

/** @deprecated TODO use api */
export const request = async (path: string, options: Options) => {
  const { success, errorMessage, data }: any = await ky(path, {
    prefixUrl: "/v1",
    ...options,
  }).json()
  if (!success) {
    throw new Error(errorMessage)
  }
  return data
}
/** @deprecated TODO use api */
export const get = async (path: string, searchParams?: any) => {
  return await request(path, { method: "get", searchParams })
}
/** @deprecated TODO use api */
export const post = async (path: string, json?: any) => {
  return await request(path, { method: "post", json })
}
const arrayBufferToString = (buffer: any, encoding = "utf-8") => {
  const decoder = new TextDecoder(encoding)
  return decoder.decode(buffer)
}

const userRefresher = observable.box(0)
export const refreshUser = () => userRefresher.set(userRefresher.get() + 1)

export const InitialUserData: UserDataType = {
  status: "pending",
  nick: "",
  avatar: "",
  cover: "", // 背景图
  id: "",
  phone: "",
  createdAt: "",
  coinRemainingCount: 0,
  subscribedProduct: undefined,
  fastGenerationRemainingSeconds: 0,
  fastGenerationRemainingCount: 0,
  fastGenerationTotalSeconds: 0,
  fastGenerationTotalCount: 0,
  freeGenerationRemainingCount: 0,
  freeGenerationTotalCount: 0,
  oneTimeSupplementRemainingSeconds: 0,
  oneTimeSupplementTotalSeconds: 0,
  oneTimeSupplementRemainingCount: 0,
  oneTimeSupplementTotalCount: 0,
  rewardRemainingSeconds: 0,
  rewardTotalSeconds: 0,
  rewardRemainingCount: 0,
  rewardTotalCount: 0,
  consumePriority: "", // 优先加量包还是奖励、套餐内余额
  isZhangjiangActor: false,
  isZhangjiangUser: false,
  visitorCreated: false, // 未登录时访客是否创建过task
  canPrivateGeneration: false, // 是否可以使用隐私模式
  canSlowUnlimitedGeneration: false, // 是否可以标准模式无限制生成
  isZhangjiangV2Actor: false,
  isZhangjiangV2User: false,
  isNoWatermarkUser: false, // 是否是 无水印用户
}

export const userData = observable.box<UserDataType>(InitialUserData)
const userUpdater = computed(() => {
  userRefresher.get()
  flow(function* () {
    try {
      // Fetch basic user info, and update status to "ok"
      const basicUserInfo = yield getBasicUserInfo()
      userData.set({
        ...userData.get(),
        ...basicUserInfo,
        status: "ok",
      })

      // Fetch full user info
      const data = yield getUserInfo()
      if (data) {
        userData.set({
          ...userData.get(),
          ...data,
        })
      }
    } catch (e: any) {
      if (e?.response?.status == "503") {
        const res = e?.response
        const copyRes = res.clone().arrayBuffer()
        copyRes.then((res: any) => {
          const response = JSON.parse(arrayBufferToString(res))
          if (response.errorCode === 99999) {
            userData.set({
              ...userData.get(),
              status: "upkeep",
            })
          }
        })
      } else {
        userData.set({
          ...userData.get(),
          status: "unauthorized",
        })
      }
      throw e
    }
  })()
})

export const user = computed(() => {
  userUpdater.get()
  const user = userData.get()
  return { ...user }
})

/** @deprecated TODO use api */
export const login = flow(function* (phone: string, inviteCode: string) {
  const data = yield post("loginByPhoneWithInviteCode", { phone, inviteCode })
  userData.set({
    ...userData.get(),
    ...data,
    status: "ok",
  })
})

/** @deprecated TODO use typed version */
export const useResource = (getParams: () => any) => {
  return useMemo(() => {
    const value = observable.box({ ready: false } as any)

    const act = flow(function* (path, searchParams) {
      value.set({ ready: false })
      const data = yield get(path, searchParams)
      value.set({ ...(data as object), ready: true })
    })

    const updater = computed(() => {
      const { path, ...searchParams } = getParams()
      act(path, searchParams)
    })

    return computed(() => {
      updater.get()
      return value.get()
    })
  }, [])
}

export const useTypedResource = <T>(
  getResource: () => Promise<HttpResponse<ResponseResponseWrapper & { data?: T }>>
) => {
  return useMemo(() => {
    const value = observable.box({ ready: false } as { ready: false } | (T & { ready: true }))

    const updater = computed(() => {
      const job = getResource()
      flow(function* () {
        value.set({ ready: false })
        const { data } = yield job
        // data.success
        // data.errorMessage
        value.set({ ...data.data!, ready: true })
      })()
    })

    return computed(() => {
      updater.get()
      return value.get()
    })
  }, [])
}

/** @deprecated TODO use api */
export const likeWork = async (id: string, status = false) => {
  if (!status) {
    // 未点赞过，当前为取消
    const data = await request(`works/${id}/like`, { method: "put" })
    return data
  } else {
    const data = await request(`works/${id}/like`, { method: "delete" })
    return data
  }
}

export const hasChangedLikedWorks = observable.box<string[]>([])
export const clearHasChangedWorks = () => {
  hasChangedLikedWorks.set([])
}
export const changeHasChangedLikedWorks = (workId: string) => {
  let index = -1
  hasChangedLikedWorks.get().map((x, xindex) => {
    if (x === workId) {
      index = xindex
    }
  })
  if (index === -1) {
    // not find
    hasChangedLikedWorks.set([...hasChangedLikedWorks.get(), workId])
  } else {
    // delete
    const copy = hasChangedLikedWorks.get()
    copy.splice(index, 1)
    hasChangedLikedWorks.set([...copy])
  }
}

// 类似 ?from=xxxx, 解所有的query 拿到一个from的值
export const fromQuery = observable.box<V1RegistSourceV2>()

export const updateFromQuery = (str: string) => {
  if (!str) return
  const querys = str.split("?")[1]?.split("&")
  querys?.map((query: string) => {
    const seperate = query.split("=")
    if (seperate?.[0] == "source") {
      fromQuery.set(seperate?.[1] as V1RegistSourceV2)
      localStorage.setItem("inviteCode", seperate?.[1])
      console.log("inviteCode", seperate?.[1])
    }
  })
}
