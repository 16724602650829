import React, { useState, useRef, useEffect, useLayoutEffect } from "react"
import ContributeModal from "./components/ContributeModal"
import { useNavigate } from "react-router-dom"
import { shanghaifilmActivityId } from "@/constants"
import title1 from "@/assets/activities/title1.png"
import title2 from "@/assets/activities/title2.png"
import { toastError } from "@/common/Toast"

const PC: React.FC = () => {
  const [activeMenu, setActiveMenu] = useState(0)
  const bannerRef = useRef(null)
  const backgroundRef = useRef<HTMLDivElement>(null)
  const [checked, setChecked] = useState(true)
  const [contributeModalVisible, setContributeModalVisible] = useState(false)
  const [bannerHeight, setBannerHeight] = useState(() => {
    if (bannerRef.current) {
      const img = bannerRef.current as any
      return img.clientHeight
    }
    return 300 // 默认高度
  })

  const [isSticky, setSticky] = useState(false)

  useLayoutEffect(() => {
    if (bannerRef.current) {
      const img = bannerRef.current as any
      img.onload = () => {
        setBannerHeight(() => img.clientHeight)
      }
    }
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      setSticky(window.scrollY > bannerHeight)
    }

    const updateBannerHeight = () => {
      if (bannerRef.current) {
        const img = bannerRef.current as any
        setBannerHeight(() => img.clientHeight)
      }
    }

    window.addEventListener("scroll", handleScroll)
    window.addEventListener("resize", updateBannerHeight)
    return () => {
      window.removeEventListener("scroll", handleScroll)
      window.removeEventListener("resize", updateBannerHeight)
    }
  }, [bannerHeight])

  const navigate = useNavigate()

  const handleMenuClick = (index: number) => {
    setActiveMenu(index)
    // 滚动到对应图的位置
    if (backgroundRef.current) {
      const targetImage = backgroundRef.current.children[index] as HTMLElement
      targetImage.scrollIntoView({ behavior: "smooth", block: "start" })
    }
  }

  const downloadZip = () => {
    if (!checked) {
      toastError("请勾选知晓版权信息及《活动规则条款》")
      return
    }
    const url = "https://cos-xica-prod.tiamat.world/static/activity/shangyingyuan/大圣素材.zip"
    const link = document.createElement("a")
    link.href = url
    link.download = "大圣素材.zip"
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <div>
      <div className=" relative">
        <img
          src={
            "https://cos-xica-prod.tiamat.world/static/activity/shangyingyuan/assets/img/pc_banner.png"
          }
          className="w-full"
          ref={bannerRef}
        ></img>
      </div>
      <div
        style={{
          position: isSticky ? "fixed" : "absolute",
          top: isSticky ? 0 : bannerHeight,
          left: 0,
          right: 0,
          zIndex: 998,
        }}
      >
        {/* 导航菜单 */}
        <div className="w-full relative">
          <img
            src={
              "https://cos-xica-prod.tiamat.world/static/activity/shangyingyuan/assets/img/pc_nav_bg.png"
            }
            className="w-full h-auto"
            alt="导航栏背景图"
          />
          <ul
            className="w-full absolute top-0 left-0 flex justify-center items-center h-full"
            style={{ zIndex: 1, scale: "0.35" }}
          >
            <li className="cursor-pointer" onClick={() => handleMenuClick(0)}>
              <img
                src={
                  "https://cos-xica-prod.tiamat.world/static/activity/shangyingyuan/assets/img/nav_1.png"
                }
                alt="按钮首页"
              />
            </li>
            <li className="cursor-pointer" onClick={() => handleMenuClick(0)}>
              <img
                src={
                  "https://cos-xica-prod.tiamat.world/static/activity/shangyingyuan/assets/img/nav_2.png"
                }
                alt="按钮参赛须知"
              />
            </li>
            <li className="cursor-pointer" onClick={() => handleMenuClick(2)}>
              <img
                src={
                  "https://cos-xica-prod.tiamat.world/static/activity/shangyingyuan/assets/img/nav_3.png"
                }
                alt="按钮设计说明"
              />
            </li>
            <li className="cursor-pointer" onClick={() => handleMenuClick(6)}>
              <img
                src={
                  "https://cos-xica-prod.tiamat.world/static/activity/shangyingyuan/assets/img/nav_4.png"
                }
                alt="按钮奖励"
              />
            </li>
            <li className="cursor-pointer" onClick={() => handleMenuClick(9)}>
              <img
                src={
                  "https://cos-xica-prod.tiamat.world/static/activity/shangyingyuan/assets/img/nav_5.png"
                }
                alt="按钮关于上影元"
              />
            </li>
            <li className="cursor-pointer" onClick={() => handleMenuClick(10)}>
              <img
                src={
                  "https://cos-xica-prod.tiamat.world/static/activity/shangyingyuan/assets/img/nav_6.png"
                }
                alt="按钮关于Tiamat"
              />
            </li>
          </ul>
        </div>
      </div>

      <div ref={backgroundRef} style={{ position: "relative" }}>
        {/* 背景图拼接 */}
        <img
          className="w-full"
          src={
            "https://cos-xica-prod.tiamat.world/static/activity/shangyingyuan/assets/img/pc_p1.jpg"
          }
        ></img>
        <img
          className="w-full"
          src={
            "https://cos-xica-prod.tiamat.world/static/activity/shangyingyuan/assets/img/pc_p2.jpg"
          }
        ></img>

        <div className=" relative">
          <img
            className="w-full"
            src={
              "https://cos-xica-prod.tiamat.world/static/activity/shangyingyuan/assets/img/pc_p3.jpg"
            }
          ></img>
          <img
            style={{
              position: "absolute",
              top: "88%",
              left: "30%",
              width: "20%",
              cursor: "pointer",
            }}
            onClick={downloadZip}
            src={
              "https://cos-xica-prod.tiamat.world/static/activity/shangyingyuan/assets/img/button_3.png"
            }
          ></img>
          <div
            style={{
              position: "absolute",
              top: "97%",
              left: "31.5%",
              width: "38%",
              cursor: "pointer",
            }}
          >
            <label className="custom-checkbox">
              <input
                type="checkbox"
                checked={checked}
                onChange={(e) => {
                  setChecked?.(e.target.checked)
                }}
              />
              <div className="flex">
                <div>
                  <img src={title1} alt="title1" />
                </div>
                <div>
                  <a
                    target="_blank"
                    // className="text-[#6663FF]"
                    href="https://cos-xica-prod.tiamat.world/static/activity/shangyingyuan/活动规则条款.pdf"
                    rel="noreferrer"
                  >
                    <img src={title2} alt="title2" />
                  </a>
                </div>
              </div>
              <span className="checkmark-activtiy"></span>
            </label>
          </div>
        </div>

        <img
          className="w-full"
          src={
            "https://cos-xica-prod.tiamat.world/static/activity/shangyingyuan/assets/img/pc_p4.jpg"
          }
        ></img>
        <img
          className="w-full"
          src={
            "https://cos-xica-prod.tiamat.world/static/activity/shangyingyuan/assets/img/pc_p5.jpg"
          }
        ></img>
        <img
          className="w-full"
          src={
            "https://cos-xica-prod.tiamat.world/static/activity/shangyingyuan/assets/img/pc_p6.jpg"
          }
        ></img>
        <img
          className="w-full"
          src={
            "https://cos-xica-prod.tiamat.world/static/activity/shangyingyuan/assets/img/pc_p7.jpg"
          }
        ></img>
        <img
          className="w-full"
          src={
            "https://cos-xica-prod.tiamat.world/static/activity/shangyingyuan/assets/img/pc_p8.jpg"
          }
        ></img>
        <img
          className="w-full"
          src={
            "https://cos-xica-prod.tiamat.world/static/activity/shangyingyuan/assets/img/pc_p9.jpg"
          }
        ></img>
        <img
          className="w-full"
          src={
            "https://cos-xica-prod.tiamat.world/static/activity/shangyingyuan/assets/img/pc_p10.jpg"
          }
        ></img>
        <div className=" relative">
          <img
            className="w-full"
            src={
              "https://cos-xica-prod.tiamat.world/static/activity/shangyingyuan/assets/img/pc_p11.jpg"
            }
          ></img>
          <img
            style={{
              position: "absolute",
              top: "70%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "20%",
              cursor: "pointer",
            }}
            onClick={() => setContributeModalVisible(true)}
            src={
              "https://cos-xica-prod.tiamat.world/static/activity/shangyingyuan/assets/img/button_1.png"
            }
          ></img>
          <img
            style={{
              position: "absolute",
              top: "85%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "20%",
              cursor: "pointer",
            }}
            onClick={() => navigate(`/activity?id=${shanghaifilmActivityId}`)} //跳转页面
            src={
              "https://cos-xica-prod.tiamat.world/static/activity/shangyingyuan/assets/img/button_2.png"
            }
          ></img>
        </div>

        <div
          className="w-[14%] cursor-pointer"
          style={{
            position: isSticky ? "fixed" : "absolute",
            top: 70,
            right: 0,
            zIndex: 999,
          }}
        >
          {/* 滚动到底部 */}
          <img
            src={
              "https://cos-xica-prod.tiamat.world/static/activity/shangyingyuan/assets/img/button_4.png"
            }
            onClick={() => window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" })}
          ></img>
        </div>
      </div>
      <ContributeModal
        visible={contributeModalVisible}
        handleClose={() => setContributeModalVisible(false)}
      />
    </div>
  )
}

export default PC
