import { CustomError } from "ts-custom-error"
import {
  V1ConsumePriority,
  V1ControlNetSettings,
  V1SubscribedProduct,
  V1Task,
} from "./generated/api/data-contracts"

export type ReferencelIamgeDataType =
  | {
      type: "referencelImage"
      preViewUrl: string
      payload: {
        referPower: number
        referencedImage: string
      }
    }
  | {
      type: "controlNet"
      preViewUrl: string
      payload: { controlNet: V1ControlNetSettings }
    }

export interface TaskParams {
  // models: Array<string>
  model: string
  negativeTextPrompt: string
  ratio: string
  referencedImage: string | null // { tempUrl: string; url: string | null } | null ;
  textPrompt: string
  isPrivate: boolean
  priority: "low" | "high"
}

export interface queryTaskParams {
  current: number
  pageSize: number
  query: string
}

/**
 * 业务用Error.
 */
export class LogicError extends CustomError {
  public constructor(public code?: string, message?: string) {
    super(message)
  }
}

export interface Categories {
  current: number
  list: {
    cover: string
    id: string
    name: string
  }[]
  pageSize: number
  total: number
}

export interface TaskResultParams {
  ready: boolean
  list: {
    id: string
    status: string
    ratio: string
    textPrompt: string
    models: string[]
    works: {
      image: string
      score: number
      liked: boolean
    }[]
  }[]
}

export interface UserDataType {
  status: "pending" | "ok" | "unauthorized" | "upkeep"
  nick: string
  avatar: string
  cover: string
  id: string
  phone: string
  createdAt: string
  subscribedProduct?: V1SubscribedProduct
  // freeQuota: V1FreeQuota | null
  // accounts: V1Account[]
  // currentUserInfo?: V1CurrentUserInfo,
  coinRemainingCount?: number
  fastGenerationRemainingSeconds?: number
  fastGenerationRemainingCount?: number
  fastGenerationTotalSeconds?: number
  fastGenerationTotalCount?: number
  freeGenerationRemainingCount?: number
  oneTimeSupplementRemainingCount?: number
  oneTimeSupplementTotalCount?: number
  freeGenerationTotalCount?: number
  oneTimeSupplementRemainingSeconds?: number
  oneTimeSupplementTotalSeconds?: number
  rewardRemainingCount: number
  rewardTotalCount?: number
  rewardRemainingSeconds: number
  rewardTotalSeconds: number
  consumePriority: V1ConsumePriority | "" // 优先加量包还是奖励、套餐内余额
  isZhangjiangActor?: boolean
  isZhangjiangUser?: boolean
  isZhangjiangV2Actor?: boolean
  isZhangjiangV2User?: boolean
  isDaJiaBaoXianUser?: boolean
  visitorCreated?: boolean
  canPrivateGeneration: boolean
  canSlowUnlimitedGeneration: boolean
  isNoWatermarkUser?: boolean
}

export interface StudioPagesStoreType {
  settingsDataReady?: boolean
  prompt: string
  model: string
  referPower: any
  originalModel: string
  modelVersion: string
  modelPattern: {
    portrait: boolean
    art: boolean
  }
  ratio: string
  negativeTextPrompt: string
  isPrivate: boolean
  isHigh: boolean
  privateGenEditable: boolean
  fastGenEditable: boolean
  currentTaskIsHigh: boolean // 这个值应该是锁死的，当前正在运行/运行结束的任务的优先级
  referencedImage: string
  workId: string
  autogeneration: boolean
  referencelIamgeData?: ReferencelIamgeDataType
  runingTasks: V1Task[]
  taskHistory: V1Task[]
}

export interface TemplatePromptItem {
  label: string
  editable: boolean
}
