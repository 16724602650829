import { useEffect, useState } from "react"
import OptionHeader from "./OptionHeader"

const ContentWrapper = ({ children }: { children: React.ReactNode }) => {
  const [opacityCls, setOpacityCls] = useState("opacity-0")

  useEffect(() => {
    setTimeout(() => {
      setOpacityCls("opacity-100")
    }, 40)
  }, [])

  return (
    <div className={`mt-[10px] ${opacityCls} overflow-hidden transition-all duration-300`}>
      {children}
    </div>
  )
}

interface OptionProps {
  iconSrc: string
  label: string
  expanded?: boolean
  onSelect?: () => void
  children?: React.ReactNode
  actionButton?: React.ReactNode
  resultContent?: React.ReactNode
}

const Option = ({
  iconSrc,
  label,
  expanded,
  onSelect,
  children,
  actionButton,
  resultContent,
}: OptionProps) => {
  const hasResult = !!resultContent
  // if there is resultContent, render it. otherwise render children
  let content
  if (hasResult) {
    content = <div className="mt-[10px]">{resultContent}</div>
  } else if (expanded && children) {
    content = <ContentWrapper>{children}</ContentWrapper>
  }

  return (
    <div className="p-2 bg-transparent rounded md:bg-[#1C1C28] md:p-[10px]">
      <OptionHeader
        iconSrc={iconSrc}
        label={label}
        isExpanded={expanded}
        onClick={onSelect}
        button={actionButton}
        arrowHidden={hasResult}
      />
      {content}
    </div>
  )
}

export default Option
