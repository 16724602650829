import { Input } from "@arco-design/web-react"
import { RefTextAreaType } from "@arco-design/web-react/es/Input/textarea"
import { useEffect, useRef } from "react"

interface TextBoxProps {
  onChange?: (text: string) => void
  onOverflowChange?: (overflow: boolean) => void
  isExpanded?: boolean
  value?: string
}
const TextBox = ({ value, onChange, onOverflowChange, isExpanded }: TextBoxProps) => {
  const textareaRef = useRef<RefTextAreaType>(null)

  const checkOverflow = () => {
    const textarea = textareaRef.current?.dom
    if (textarea) {
      setTimeout(() => {
        const isOverflow = textarea.scrollHeight > textarea.clientHeight
        onOverflowChange?.(isOverflow)
      }, 0)
    }
  }

  const handleInputChange = (val: string) => {
    onChange?.(val)
  }

  useEffect(() => {
    checkOverflow()
  }, [value])

  useEffect(() => {
    textareaRef.current?.focus()
  }, [isExpanded])

  return (
    <Input.TextArea
      onChange={handleInputChange}
      ref={textareaRef}
      autoSize
      style={{
        backgroundColor: "transparent",
        border: "none",
        resize: "none",
        color: "#FFFFFF",
        maxHeight: isExpanded ? "300px" : "100px",
        overflowY: "auto",
        paddingLeft: "0px",
      }}
      value={value}
    />
  )
}

export default TextBox
