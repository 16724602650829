import { useState } from "react"
import { Masonry } from "masonic"

import { useDarkMode } from "@/utils"
import TemplateDetailModal from "@/common/TemplateDetailModal"
import TemplateItem from "@/common/TemplateItem"
import { TEMPLATESTYPE } from "@/constants"
import { V1TemplateData } from "@/generated/api/data-contracts"

type ActivityContentProps = {
  callback: (i: number) => void
  templatesCenter: V1templatesCenter
  activeType: TEMPLATESTYPE
}
type TemplateCenterMobileProps = {
  templatesCenter: V1templatesCenter
  activeType: TEMPLATESTYPE
}

type V1templatesCenter = Record<TEMPLATESTYPE, V1TemplateData[]>

const ActivityContent = ({ callback, templatesCenter, activeType }: ActivityContentProps) => {
  return (
    <Masonry
      items={templatesCenter[activeType]}
      render={({ index, data }) => {
        return (
          <TemplateItem
            key={data.id}
            template={data}
            className="group overflow-hidden h-full aspect-[1/1] items-center rounded-[4px] flex w-full"
            callback={() => callback(index)}
          />
        )
      }}
      columnCount={2}
      columnGutter={20}
    />
  )
}

const TemplateCenterMobile = ({ templatesCenter, activeType }: TemplateCenterMobileProps) => {
  useDarkMode()

  const [taskIndex, setTaskIndex] = useState<number | null>(null)

  const openModal = (i: number) => {
    setTaskIndex(i)
  }

  const closeModal = () => {
    setTaskIndex(null)
  }

  const templateModalVisiable = taskIndex !== null
  return (
    <>
      <ActivityContent
        callback={(i: number) => openModal(i)}
        templatesCenter={templatesCenter}
        activeType={activeType}
      />
      {templateModalVisiable && (
        <TemplateDetailModal
          defaultTemplateIndex={taskIndex}
          onClose={closeModal}
          templatesCenter={templatesCenter[activeType]}
        />
      )}
    </>
  )
}

export default TemplateCenterMobile
