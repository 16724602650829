import ActionButton from "./components/ActionButton"

interface ModifyControlButtonProps {
  modifyAll?: boolean
  onClick?: () => void
}
const ModifyControlButton = ({ modifyAll, onClick }: ModifyControlButtonProps) => {
  const config = {
    all: {
      text: "全部修改描述语",
    },
    part: {
      text: "部分修改描述语",
    },
  }
  const activeConfig = modifyAll ? config.part : config.all

  return (
    <ActionButton
      text={activeConfig.text}
      buttonBgColorCls="bg-transparent"
      buttonBorderColorCls="border-[#fff]"
      onClick={() => {
        onClick?.()
      }}
    />
  )
}

export default ModifyControlButton
