import { ReactNode, useEffect, useMemo, useRef, useState } from "react"
import { ReactComponent as LoveIcon } from "@/assets/works/love.svg"
import { ReactComponent as PublicIcon } from "@/assets/works/public.svg"
import { ReactComponent as PrivateIcon } from "@/assets/works/private.svg"
import { ReactComponent as EditorIcon } from "@/assets/works/editor.svg"
import { ReactComponent as DrapIcon } from "@/assets/works/drap.svg"
import { ReactComponent as CloseIcon } from "@/assets/works/close.svg"
import { ReactComponent as LoadingPuffIcon } from "@/assets/loading_puff.svg"
import Masonry from "@mui/lab/Masonry"
// import InfiniteScroll from "react-infinite-scroller"
import "./index.less"
import { toastError, toastSuccess } from "@/common/Toast"
import { Button, Form, Input, Modal } from "@arco-design/web-react"
import {
  V1CreateTemplateRequest,
  V1ListTasksResponse,
  V1Task,
  V1TemplateCategoryMeta,
  V1Work,
} from "@/generated/api/data-contracts"
import { observer } from "mobx-react-lite"
import { createTemplate, getTemplateCategories } from "@/services/editor"
import { isMobileOnly } from "react-device-detect"
import { useTranslation } from "react-i18next"
import {
  formatLoveNum,
  formatSpiritStonesNum,
  getColumnCount,
  getImageURL,
  useComputed,
  useWindowWidth,
} from "@/utils"
import { ErrorCode } from "@/i18next"
import PrivacyButton from "@/Studio/components/PromptBox/Actions/PrivacyButton"
import { useNavigate } from "react-router-dom"
import { observable, runInAction } from "mobx"
import { getListAllTasks } from "@/services/home"
import HomeCard from "@/pages/Works/HomeCard"
import { api } from "@/services/api"
import InfiniteScroll from "@/components/common/InfiniteScroll"
import { getAlbumDetails } from "@/services/album"
export interface categories {
  label: string
  value: string
}
/**
 *  公开不公开切换
 *  编辑相册弹窗
 *
 */
export default observer(function ({
  visible,
  handleSumit,
  handleClose,
  uid,
}: {
  uid: string
  handleSumit: (image?: string) => void
  handleClose: () => void
  visible: boolean
}) {
  const { t } = useTranslation(["translation", "errorMessage"])
  const [total, setTotal] = useState(0)
  const [dataList, setDataList] = useState<Array<any>>([])
  const [hasMore, setHasMore] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [selectImg, setSelectImg] = useState<any>({})
  async function loadPage(pageNo: number) {
    setIsLoading(true)
    try {
      const params = {
        current: pageNo,
        pageSize: 50,
        // highest | newest | popular
        sort: "newest",
        collectionUID: uid,
      }
      const { data, success, errorCode } = await getAlbumDetails(uid, params)
      if (success) {
        setTotal(data?.total || 0)
        let newDataList = [...dataList, ...(data?.collection?.works || [])]
        setDataList(newDataList)
        setHasMore(newDataList.length < total)
      } else {
        toastError(t(`errorMessage:${errorCode}` as any))
      }
    } catch (e) {
      console.error("error", e)
    }
    setIsLoading(false)
  }

  const NoMore = () => {
    return (
      <section className="py-10 flex justify-center items-center">
        <span className="inline-flex items-center text-[#6F7176] text-sm before:mr-[26px] before:bg-[#C9CBD14D]/30 before:w-[138px] before:h-[1px] after:ml-[26px] after:bg-[#C9CBD14D]/30 after:w-[138px] after:h-[1px]">
          没有更多了
        </span>
      </section>
    )
  }

  useEffect(() => {
    loadPage(1)
  }, [])

  // disabled scroll of html
  useEffect(() => {
    document.documentElement.style.overflow = "hidden"
    return () => {
      document.documentElement.style.overflow = "auto"
    }
  }, [])

  const scrollContainerRef = useRef<HTMLDivElement>(null)

  return (
    <Modal
      visible={visible}
      wrapClassName="albumn-details"
      autoFocus={true}
      unmountOnExit={true}
      footer={null}
      focusLock={true}
      maskStyle={{ backgroundColor: "rgba(0,0,0,0.8)" }}
      style={{
        width: isMobileOnly ? "100%" : "auto",
        height: "100vh",
      }}
      closeIcon={null}
      modalRender={() => (
        <div
          ref={scrollContainerRef}
          className={` ${
            visible ? "block" : "hidden"
          } fixed w-full z-1002 top-0 overflow-auto inset-0  py-[70px] h-full`}
        >
          <InfiniteScroll
            root={scrollContainerRef.current}
            hasMore={hasMore}
            isLoading={isLoading}
            loadMore={() => {
              loadPage(currentPage + 1)
              setCurrentPage(currentPage + 1)
            }}
            loader={
              <LoadingPuffIcon
                key={"loading"}
                className="my-20 mx-auto"
                stroke="currentColor"
              ></LoadingPuffIcon>
            }
            noMoreElement={<NoMore />}
          >
            <Masonry columns={{ xs: 3, sm: 4, lg: 6 }} spacing={2} className="px-[70px]">
              {dataList?.map((item, index) => (
                <div
                  key={item.id}
                  style={{ aspectRatio: item?.ratio.replace(":", "/") }}
                  className="w-full relative"
                >
                  {item.id == selectImg?.id && (
                    <div
                      className="absolute top-0 border-[2px] border-[#6663FF] z-20 w-full rounded-t-[4px]"
                      style={{ aspectRatio: item?.ratio.replace(":", "/") }}
                    ></div>
                  )}
                  <img
                    className="w-auto h-full object-cover rounded select-none ---"
                    src={getImageURL(item?.image)}
                    onClick={() => {
                      setSelectImg(item)
                    }}
                    alt=""
                  />
                </div>
              ))}
            </Masonry>
          </InfiniteScroll>
          <div className="fixed bottom-0 w-full  py-[20px] bg-[#2e2e4a] bg-opacity-70 backdrop-filter backdrop-blur-[10px] flex justify-center gap-[20px]  z-30">
            <div
              className="w-[156px]  rounded-[4px]  border-[1px] border-[white] text-[white] flex justify-center py-[10px] cursor-pointer"
              onClick={() => handleClose()}
            >
              取消
            </div>
            <div
              className="w-[156px] rounded-[4px] bg-[#4C1FFF] text-[white]  flex justify-center  py-[10px]  cursor-pointer"
              onClick={() => {
                if (selectImg?.image) {
                  handleSumit(selectImg.image)
                } else {
                  toastError("请选择所要设置的封皮")
                }
              }}
            >
              确定
            </div>
          </div>
        </div>
      )}
    ></Modal>
  )
})
