import { ErrorCode } from "@/i18next"
import {
  getOrderDetail,
  getPaymentUrl,
  getUpgradePlanInfo,
  upgradePlan,
} from "@/services/subscription"
import { LogicError } from "@/types"
import { Button, Modal } from "@arco-design/web-react"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { ReactComponent as CheckIcon } from "@/assets/check.svg"
import { ReactComponent as CloseIcon } from "@/assets/close_black.svg"
import { ReactComponent as LoadingPuffIcon } from "@/assets/loading_puff.svg"
import { ReactComponent as RefreshIcon } from "@/assets/refresh.svg"
import { ReactComponent as WechatIcon } from "@/assets/wechat.svg"
import { ReactComponent as CustomerServiceIcon } from "@/assets/customer_service.svg"
import paySuccessPng from "@/assets/subscription_pay_success.png"
import payFailPng from "@/assets/subscription_pay_fail.png"
import specialistwechatURL from "@/assets/specialistwechat.png"
import ProfessionalCommunityJpg from "@/assets/professional-community.jpg"
import DefaultAvatarSvg from "@/assets/login_default_avatar.svg"

import * as store from "@/store"
import { SUBSCRIPTIONS } from "@/constants"
import { V1CreateOrderResp, V1UpgradePlanInfo } from "@/generated/api/data-contracts"
import { toastError } from "@/common/Toast"
import { formatPrice } from "@/utils"
import { api } from "@/services/api"
import { IconClose } from "@arco-design/web-react/icon"
import shopModalStore from "@/store/shopModalStore"

const PaySuccess = ({ onCancel }: { onCancel?: () => void }) => {
  const navigate = useNavigate()
  const [status, setStatus] = useState(0)
  // Community Customer
  const changeStatus = (val: number) => {
    if (val === status) {
      setStatus(0)
    } else {
      setStatus(val)
    }
  }
  // 支付成功
  const handleClickSuccess = () => {
    navigate("/studio")
    onCancel?.()
    shopModalStore.closeShopModal()
  }
  return (
    <>
      <h1 className="font-[500]">支付成功</h1>
      <p className="text-[#6F7176]">已完成支付，快去创作吧</p>
      {status === 1 ? (
        <>
          <div>
            <img className="mx-auto w-[180px]" src={ProfessionalCommunityJpg} alt="" />
          </div>
        </>
      ) : status === 2 ? (
        <>
          <div>
            <img className="mx-auto w-[180px]" src={specialistwechatURL} alt="" />
          </div>
        </>
      ) : (
        <>
          <div>
            <img className="mx-auto w-[180px]" src={paySuccessPng} alt="" />
          </div>
        </>
      )}
      <div className="mt-6">
        <button
          onClick={handleClickSuccess}
          className="mx-auto w-[176px] h-[40px] bg-gradient-to-r from-[#734AFF] to-[#5C16F1] rounded-[4px] text-white text-[14px] leading-5 font-normal"
        >
          开始创作
        </button>
      </div>
      <div className="flex items-baseline justify-between px-10 pt-8 my-[24px] border border-dashed border-x-0 border-b-0 border-[#ffffff0d]">
        {/* <button
          className={`${
            status === 1 ? "bg-[#cac7dc33]" : ""
          } w-full flex justify-center p-[12px] rounded items-center hover:bg-[#cac7dc1d]`}
          onClick={() => changeStatus(1)}
        >
          <WechatIcon className="fill-[#6F7176] mr-2" />
          加入专业社区
        </button> */}
        <button
          className={`${
            status === 2 ? "bg-[#cac7dc33]" : ""
          } w-full flex justify-center p-[12px] rounded items-center hover:bg-[#cac7dc1d]`}
          onClick={() => {
            changeStatus(2)
          }}
        >
          <CustomerServiceIcon className="mr-2" />
          联系客服
        </button>
      </div>
    </>
  )
}

const PayFail = ({ repayment }: { repayment: () => void }) => {
  const [status, setStatus] = useState(0)
  // Community Customer
  const changeStatus = (val: number) => {
    if (val === status) {
      setStatus(0)
    } else {
      setStatus(val)
    }
  }
  return (
    <>
      <h1 className="font-[500]">很遗憾，支付失败</h1>
      <p className="text-[#6F7176]">支付失败，请重新尝试支付</p>
      {status === 1 ? (
        <>
          <div>
            <img className="mx-auto w-[180px]" src={ProfessionalCommunityJpg} alt="" />
          </div>
        </>
      ) : status === 2 ? (
        <>
          <div>
            <img className="mx-auto w-[180px]" src={specialistwechatURL} alt="" />
          </div>
        </>
      ) : (
        <>
          <div>
            <img className="mx-auto w-[180px]" src={payFailPng} alt="" />
          </div>
        </>
      )}
      <div className="mt-6">
        <button
          onClick={repayment}
          className="mx-auto w-[176px] h-[40px] bg-gradient-to-r from-[#734AFF] to-[#5C16F1] rounded-[4px] text-white text-[14px] leading-5 font-normal"
        >
          重新支付
        </button>
      </div>
      <div className="flex items-baseline justify-between px-10 pt-8 my-[24px] border border-dashed border-x-0 border-b-0 border-[#ffffff0d]">
        <button
          className={`${
            status === 1 ? "bg-[#cac7dc33]" : ""
          } w-full flex justify-center p-[12px] rounded items-center hover:bg-[#cac7dc1d]`}
          onClick={() => changeStatus(1)}
        >
          <WechatIcon className="fill-[#6F7176] mr-2" />
          加入专业社区
        </button>
        <button
          className={`${
            status === 2 ? "bg-[#cac7dc33]" : ""
          } w-full flex justify-center p-[12px] rounded items-center hover:bg-[#cac7dc1d]`}
          onClick={() => {
            changeStatus(2)
          }}
        >
          <CustomerServiceIcon className="mr-2" />
          联系客服
        </button>
      </div>
    </>
  )
}

const SubscribePay111 = ({
  productId,
  productAmount,
  orderStatusChange,
  getDetail,
  type,
  cb,
  onCancel,
}: {
  cb?: () => void
  onCancel?: () => void
  type?: "month" | "year" | "none"
  productId: number
  productAmount: number
  orderStatusChange?: (status: number) => void
  getDetail: (
    productId: number,
    discountCode?: string,
    productAmount?: number
  ) => Promise<V1CreateOrderResp | undefined>
}) => {
  const { t } = useTranslation(["translation", "errorMessage"])
  const user = store.user.get()
  const { shopModalIsOpen } = shopModalStore.store.get()

  const [discountCodeDetail, setDiscountCodeDetail] = useState({
    code: "",
    checked: false,
    discountValue: 0,
  })

  const [qrcode, setQrcode] = useState<{
    loading?: boolean
    base64?: string
    err?: string
    orderId?: string
    totalFee?: number
    // timeId?: number // 这个值设置一直无效，所以使用全局INterval代替
    orderStatus: number //0 获取支付信息 1 待支付；-1 订单过期关闭  20, 支付成功；30支付失败
    createdTime?: number // 创建开始的时间戳
  }>()

  useEffect(() => {
    orderStatusChange?.(qrcode?.orderStatus || 0)
  }, [qrcode?.orderStatus])

  const getProductDetail = async () => {
    try {
      setQrcode({
        loading: true,
        totalFee: 0,
        orderStatus: 0,
      })
      clearInterval(globalInterval)
      const res = await getDetail(
        productId,
        discountCodeDetail.checked ? discountCodeDetail.code : "",
        productAmount
      )
      if (res) {
        setQrcode({
          totalFee: res.totalFee || 0,
          base64: `data:image/png;base64,${res.qrImage}`,
          loading: false,
          orderId: res.orderId,
          orderStatus: 1,
          createdTime: new Date().getTime(),
        })
        setQrcodeTimeout(false)
      }
    } catch (e) {
      if (e instanceof LogicError) {
        setQrcode({
          err: t(`errorMessage:${e.code as ErrorCode}`) || t("NetworkError"),
          totalFee: 0,
          orderStatus: 0,
        })
      }
    }
  }

  const [qrcodeTimeOut, setQrcodeTimeout] = useState<boolean>(false)

  const updateOrderStatus = async () => {
    if (!qrcode?.orderId) {
      return
    }
    const currentTime = new Date().getTime()
    const waitTimeMax = 1000 * 60 * 15 // 最多等待15分钟,否则二维码过期
    if (qrcode?.createdTime && currentTime - qrcode?.createdTime! > waitTimeMax) {
      setQrcodeTimeout(true)
      clearInterval(globalInterval)
    }

    try {
      const res = await getOrderDetail(qrcode.orderId)
      if (res.orderStatus && res.orderStatus == qrcode.orderStatus) {
        setQrcode({
          ...qrcode,
          orderStatus: res.orderStatus,
        })
      }
      if (res.orderStatus === 20) {
        store.refreshUser()
        cb && cb()
        clearInterval(globalInterval)
        setQrcode({
          ...qrcode,
          orderStatus: res.orderStatus,
        })
      }
      // 测试
      // setQrcode({
      //   ...qrcode,
      //   orderStatus: 20,
      // })
    } catch (error) {
      clearInterval(globalInterval)
      setQrcode({
        ...qrcode,
        orderStatus: 30,
      })
    }
  }

  // 监听是否购买成功
  let globalInterval: any = 0
  useEffect(() => {
    const i = setInterval(updateOrderStatus, 1000 * 5)
    globalInterval = i
    setQrcode({
      orderStatus: 0,
      ...qrcode,
    })
    return () => clearInterval(i)
  }, [qrcode?.orderId])

  useEffect(() => {
    getProductDetail()
  }, [productId, productAmount, discountCodeDetail.checked])

  const getSubscriptionDiscount = async () => {
    const { data } = await api.getSubscriptionDiscount(discountCodeDetail.code)
    if (data.success) {
      setDiscountCodeDetail({
        ...discountCodeDetail,
        checked: true,
        discountValue: (data?.data?.discountValue || 0) * 10,
      })
    } else {
      toastError(t(`errorMessage:${data.errorCode as ErrorCode}`) || data.errorMessage || "")
    }
  }

  // const [showTip, setShowTip] = useState(false)

  const [agree, setAgree] = useState(false)
  const [agreeModal, setAgreeModal] = useState(false)
  return (
    <>
      {[-1, 30].includes(qrcode?.orderStatus || 0) ? (
        <PayFail
          repayment={() => {
            getProductDetail()
          }}
        />
      ) : qrcode?.orderStatus === 20 ? (
        <PaySuccess onCancel={onCancel} />
      ) : (
        <>
          <div className="mx-auto w-52 h-52 border-t border-[#0000]">
            {qrcode?.loading ? (
              <>
                <div className="mx-auto w-[208px] h-[208px] rounded bg-[#00000014] flex items-center justify-center">
                  <LoadingPuffIcon />
                </div>
              </>
            ) : (
              <>
                {qrcode?.err ? (
                  <>
                    <div
                      onClick={() => getProductDetail()}
                      className=" cursor-pointer m-4 mx-auto  w-40 h-40 flex justify-center flex-wrap flex-col items-center inset-0 rounded bg-[#00000014]"
                    >
                      <div className="f-full">{qrcode?.err}</div>
                      <div>点击重试</div>
                      <p>
                        <RefreshIcon />
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="relative">
                      <img
                        className={`w-[208px] h-[208px] rounded
                      ${qrcodeTimeOut || !agree ? "blur-lg" : ""}
                    `}
                        src={qrcode?.base64}
                        alt=""
                      />
                      {qrcodeTimeOut && (
                        <div
                          className="w-full h-full absolute top-0 left-0 flex justify-center items-center cursor-pointer"
                          onClick={getProductDetail}
                        >
                          二维码已过期
                          <br /> <br />
                          请点击刷新
                        </div>
                      )}
                      {!agree && (
                        <div
                          className="w-full h-full absolute top-0 left-0 flex justify-center items-center cursor-pointer"
                          onClick={getProductDetail}
                        >
                          请勾选《Tiamat 用户协议》《隐私条款》和
                          {shopModalIsOpen ? "《灵石充值协议》" : "《会员服务协议》"}
                        </div>
                      )}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <p className="mb-2 text-sm font-[500] text-center mt-3">微信扫码支付</p>
          {/* {type === "none" ? (
            ""
          ) : (
            <div className="bg-[#0C0C1A] mx-7 my-4 p-2 rounded flex items-center">
              {discountCodeDetail.checked && (
                <span className="text-[#E93247] py-1 mr-1">
                  {discountCodeDetail.discountValue}折优惠
                </span>
              )}
              <input
                value={discountCodeDetail.code}
                readOnly={discountCodeDetail.checked}
                type="text"
                onChange={(e) => {
                  setDiscountCodeDetail({
                    checked: false,
                    code: e.target.value,
                    discountValue: 0,
                  })
                }}
                placeholder="点击输入折扣码"
                className="w-full bg-transparent pr-2"
              />
              {discountCodeDetail.checked ? (
                <button
                  className="p-1 rounded-full bg-[#ffffff34]"
                  onClick={() => {
                    setDiscountCodeDetail({
                      discountValue: 0,
                      checked: false,
                      code: "",
                    })
                  }}
                >
                  <IconClose />
                </button>
              ) : (
                <button
                  onClick={getSubscriptionDiscount}
                  className=" flex-shrink-0 px-3 py-1 bg-[#6663FF] text-[#fff] rounded text-sm"
                >
                  确认
                </button>
              )}
            </div>
          )} */}
          <div className="flex justify-between px-8 py-8 my-[24px] border border-dashed border-x-0 border-[#ffffff0d]">
            <div className="flex">
              <img
                className=" w-10 h-10 rounded-full"
                src={user.avatar || DefaultAvatarSvg}
                alt=""
              />
              <div className="mx-2 flex flex-col justify-between">
                <span className="text-[#6F7176] text-xs text-left">魔法师</span>
                <span className=" font-normal">{user.nick}</span>
              </div>
            </div>
            <div className="flex items-end">
              <span>支付金额：</span>
              <span className="text-[#E93247] text-[28px] leading-[1] font-[500]">
                {formatPrice((qrcode?.totalFee || 0) / 100)}
              </span>
              <span>元</span>
            </div>
          </div>
          <div>
            <p className="text-center text-[#CBC7DC] flex justify-center items-center">
              <span
                onClick={() => {
                  // if (agree === true) {
                  // 将要取消
                  // setAgreeModal(true)
                  // }
                  setAgree(!agree)
                }}
                className={`inline-flex w-4 h-4 rounded-xl cursor-pointer ${
                  agree ? "bg-[#5A14F0]" : ""
                } border border-[#5A14F0] shadow-sm justify-center items-center mr-2`}
              >
                {agree ? <CheckIcon className="" /> : ""}
              </span>
              <span className="whitespace-normal">
                阅读并同意
                <a
                  target="_blank"
                  className="text-[#6663FF]"
                  href="https://cos-xica-prod.tiamat.world/assets/Tiamat%E7%94%A8%E6%88%B7%E5%8D%8F%E8%AE%AE.pdf"
                  rel="noreferrer"
                >
                  《Tiamat 用户协议》
                </a>
                <a
                  target="_blank"
                  className="text-[#6663FF]"
                  href="https://cos-xica-prod.tiamat.world/assets/Tiamat%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%96-20240123-V1.pdf"
                  rel="noreferrer"
                >
                  《隐私条款》
                </a>
                和
                {shopModalIsOpen ? (
                  <a
                    target="_blank"
                    className="text-[#6663FF]"
                    href="https://cos-xica-prod.tiamat.world/assets/Tiamat%E7%81%B5%E7%9F%B3%E5%85%85%E5%80%BC%E5%8D%8F%E8%AE%AE-20231120-V1.pdf"
                    rel="noreferrer"
                  >
                    《灵石充值协议》
                  </a>
                ) : (
                  <a
                    target="_blank"
                    className="text-[#6663FF]"
                    href="https://cos-xica-prod.tiamat.world/assets/Tiamat%E4%BC%9A%E5%91%98%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE-20231120-V1.pdf"
                    rel="noreferrer"
                  >
                    《会员服务协议》
                  </a>
                )}
              </span>
            </p>
          </div>
          <Modal
            visible={agreeModal}
            footer={null}
            closeIcon={null}
            maskStyle={{
              background: "rgba(10, 9, 9, 0.9)",
            }}
            style={{
              width: "auto",
              padding: "0",
              backgroundColor: "#0c0c18",
            }}
            maskClosable
            onCancel={() => {
              setAgree(true)
              setAgreeModal(false)
            }}
          >
            <div className=" text-white p-[16px] rounded w-[360px]">
              <div>请确认勾选《Tiamat 用户协议》和《隐私条款》</div>
              <div className="mt-[28px] float-right">
                <Button
                  type="primary"
                  className={"flex-1 ml-[8px]"}
                  onClick={() => {
                    setAgreeModal(false)
                    setAgree(true)
                  }}
                >
                  确定
                </Button>
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  )
}

export const SubscribePay = (props: {
  type: "month" | "year" | "none"
  productId: number
  onCancel?: () => void
  title?: string
  cb?: () => void
}) => {
  const [showMealType, setShowMealType] = useState<"month" | "year" | "none">(() => props.type)
  const [productId, setProductId] = useState(() => props.productId)

  const onChange = (val: "month" | "year") => {
    if (showMealType === val) return
    setShowMealType(val)

    if (val === "month") {
      setProductId(productId - 1)
    } else {
      setProductId(productId + 1)
    }
  }

  const title =
    props.title ||
    `${SUBSCRIPTIONS[productId]?.title}·${showMealType === "month" ? "包月" : "包年"}`

  // const [title, setTitle] = useState(props.defalutTitle)
  // useEffect(() => {
  //   if (props.defalutTitle) return
  //   setTitle(`${SUBSCRIPTIONS[productId]?.title}·${showMealType === "month" ? "包月" : "包年"}`)
  // }, [productId])
  const [orderStatus, setOrderstatus] = useState(0)

  return (
    <>
      <Modal
        visible={true}
        maskStyle={{
          background: "#00000099",
        }}
        onCancel={() => props.onCancel?.()}
        modalRender={() => {
          return (
            <div className="mx-auto top-0 inline-block align-middle rounded shadow-2xl bg-[#212134] w-full  sm:w-[460px]">
              <div className="flex w-full justify-end px-2 pt-2">
                <CloseIcon
                  className="fill-[rgba(255,255,255,0.5)] hover:cursor-pointer"
                  onClick={() => props.onCancel?.()}
                />
              </div>
              <>
                <div className=" pb-[16px]">
                  {[0, 1].includes(orderStatus) ? (
                    <>
                      <h1 className="font-[500]">{title}</h1>
                      {props?.type === "none" ? (
                        ""
                      ) : (
                        <div className="relative mx-auto flex justify-center mt-[42px] mb-[24px] ">
                          <div className="relative p-[6px] bg-[#0C0C1A] rounded-[6px] flex">
                            {/* <SavingIcon className="absolute bottom-[42px] right-[0]" /> */}
                            <div
                              onClick={() => onChange("month")}
                              className={`${
                                showMealType === "month" ? "text-inherit bg-[#212134] shadow" : ""
                              } hover:cursor-pointer px-[22px] py-[6px] text-sm text-[#6F7176] rounded`}
                            >
                              按月订阅
                            </div>
                            <div
                              onClick={() => onChange("year")}
                              className={`${
                                showMealType === "year" ? "text-inherit bg-[#212134] shadow" : ""
                              } hover:cursor-pointer px-[22px] py-[6px] text-sm text-[#6F7176] rounded`}
                            >
                              按年订阅
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                  <SubscribePay111
                    cb={props?.cb}
                    type={props?.type}
                    getDetail={getPaymentUrl}
                    productId={productId}
                    productAmount={1}
                    orderStatusChange={(val) => setOrderstatus(val)}
                    onCancel={() => props.onCancel?.()}
                  />
                </div>
              </>
            </div>
          )
        }}
      />
    </>
  )
}

export const UpdateSubscribeConfirm = (props: { productId: number; onCancel?: () => void }) => {
  const [confirm, setConfirm] = useState(false)
  const { t } = useTranslation(["translation", "errorMessage"])
  const [upgradeInfo, setUpgradeInfo] = useState<V1UpgradePlanInfo>()

  // getUpgradePlanInfo
  const getUpgradeInfo = async () => {
    try {
      const res = await getUpgradePlanInfo(props.productId)
      if (res) {
        setUpgradeInfo(res)
      }
    } catch (e) {
      if (e instanceof LogicError) {
        toastError(t(`errorMessage:${e.code as ErrorCode}`))
      }
    }
  }

  useEffect(() => {
    getUpgradeInfo()
  }, [])

  return (
    <>
      <Modal
        visible={true}
        maskStyle={{
          background: "#00000099",
        }}
        onCancel={() => props.onCancel?.()}
        modalRender={() => {
          return (
            <div className="mx-auto top-0 inline-block align-middle rounded shadow-2xl bg-[#212134] w-full sm:w-[460px]">
              <div className="flex w-full justify-end px-2 pt-2">
                <CloseIcon className=" hover:cursor-pointer" onClick={() => props.onCancel?.()} />
              </div>
              {confirm ? (
                <div className="pb-[16px]">
                  <h1 className="font-[500]">订阅方案升级</h1>
                  <SubscribePay111
                    productId={props.productId}
                    productAmount={1}
                    getDetail={upgradePlan}
                  />
                </div>
              ) : (
                <div className="px-[50px] pb-[16px]">
                  <h1 className="font-[500]">订阅方案升级</h1>
                  <p className="text-[#6F7176]  whitespace-pre-wrap">
                    您当前订阅方案内有剩余的生成次数将计算入新的方案折扣中，方案外获取的生成次数不计入计算。
                  </p>
                  <div className="my-[20px] p-[20px] rounded-xl bg-[#c9cbd11a]">
                    <div className="flex w-full justify-between">
                      <div className=" text-left">
                        <h2 className=" text-base font-[500]">{upgradeInfo?.newProduct?.title}</h2>
                        <p className="text-xs text-[#6F7176]">方案价格</p>
                      </div>
                      <div className="font-[600] text-base text-[#179A6F]">
                        ¥{formatPrice((upgradeInfo?.newProduct?.price || 0) / 100)}
                      </div>
                    </div>
                    <div className="my-[20px] border border-dashed border-x-0 border-b-0 border-[#ffffff0d]" />
                    <div className="flex w-full justify-between">
                      <div className=" text-left">
                        <h2 className=" text-base font-[500]">
                          {upgradeInfo?.currentProduct?.title}
                        </h2>
                        <p className="text-xs text-[#6F7176]">未使用次数</p>
                      </div>
                      <div className="font-[600] text-base ">
                        -¥{formatPrice((upgradeInfo?.deductibleAmount || 0) / 100)}
                      </div>
                    </div>
                  </div>
                  <div className="px-[20px] text-end">
                    <p>
                      小计：{" "}
                      <span className="font-[600] text-base ">
                        -¥{formatPrice((upgradeInfo?.deductibleAmount || 0) / 100)}
                      </span>
                    </p>
                  </div>
                  <div className="my-[20px] border border-dashed border-x-0 border-b-0 border-[#ffffff0d]" />
                  <div className="px-[20px] text-end">
                    <p>
                      支付金额：
                      <span className="font-[400] text-2xl text-[#E93247]">
                        ¥{formatPrice((upgradeInfo?.shouldPayAmount || 0) / 100)}
                      </span>
                    </p>
                  </div>
                  <div className="flex px-[20px] my-[20px]">
                    <button
                      className="w-[90%] mr-3 py-[12px] border border-[#FFFFFF80] rounded-md"
                      onClick={() => props.onCancel?.()}
                    >
                      取消
                    </button>
                    <button
                      onClick={() => setConfirm(true)}
                      className="w-[90%] py-[12px]  text-white rounded-md bg-gradient-to-r from-[#734AFF] to-[#5C16F1]"
                    >
                      确认更改
                    </button>
                  </div>
                </div>
              )}
            </div>
          )
        }}
      />
    </>
  )
}

export const AddSub = (props: { onCancel?: () => void; productAmount: number }) => {
  const [orderStatus, setOrderstatus] = useState(0)

  return (
    <>
      <Modal
        visible={true}
        maskStyle={{
          background: "#00000099",
        }}
        onCancel={() => props.onCancel?.()}
        modalRender={() => {
          return (
            <div className="mx-auto top-0 inline-block align-middle rounded shadow-2xl bg-[#212134] w-full  sm:w-[460px]">
              <div className="flex w-full justify-end px-2 pt-2">
                <CloseIcon className=" hover:cursor-pointer" onClick={() => props.onCancel?.()} />
              </div>
              <>
                <div className=" pb-[16px]">
                  {[0, 1].includes(orderStatus) ? (
                    <>
                      <h1 className="font-[500]">加量包</h1>
                    </>
                  ) : null}
                  <SubscribePay111
                    getDetail={getPaymentUrl}
                    productId={40}
                    productAmount={props.productAmount}
                    orderStatusChange={(val) => setOrderstatus(val)}
                  />
                </div>
              </>
            </div>
          )
        }}
      />
    </>
  )
}
