import { useState, useMemo, useEffect } from "react"

import { ReactComponent as CheckmarkIcon } from "@/assets/checkmark.svg"
import { ReactComponent as DownloadIcon } from "@/assets/download.svg"
import { ReactComponent as HeartWhiteIcon } from "@/assets/heart-white-2.svg"
import { ReactComponent as HeartRedIcon } from "@/assets/heart-red-2.svg"
import { ReactComponent as MagicIcon } from "@/assets/magic2.svg"
import { ReactComponent as CopyIcon } from "@/assets/copy2.svg"
import DefaultAvatarSvg from "@/assets/login_default_avatar.svg"

import DefaultModal from "@/common/ImgDetailModal/Modal"
import { handleDownload } from "@/utils"
import { getImageURL } from "@/utils"
import { V1Task, V1Work } from "@/generated/api/data-contracts"
import { runInAction } from "mobx"
import { IllegalWork } from "@/common/IllegalWork"
import { ErrorImage } from "@/common/ErrorImage"
import { Tooltip } from "@arco-design/web-react"
import { getTime } from "@/utils/friendlyDate"
import CopyToClipboard from "react-copy-to-clipboard"
import { toastInfo } from "@/common/Toast"
import PictureOption from "@/common/PictureOption"
import GenerateAgainTask from "@/Studio/components/GenerateAgainTask"
import CheckAuth from "@/components/common/CheckAuth"
import * as store from "../../store"
import { Link } from "react-router-dom"
import AlbumSelect from "./WorksTab/components/AlbumSelect"

export interface RegenOptions {
  textPrompt: string
  negativeTextPrompt: string
  model: string
  ratio: string
}
export default function HomeCard({
  work,
  task,
  onToggle,
  showRegen = false,
  showDownload = false,
  showOption = false,
  showCopy = true,
  selectedAllWorks,
  onRegen,
  linkOrUnlike,
}: {
  task: V1Task
  work: V1Work
  selectedAllWorks?: string[] | null
  onToggle?: (list: { id: string; checked: boolean }[]) => void
  ModalV2?: typeof DefaultModal
  refJump?: boolean
  showRegen?: boolean
  showDetail?: boolean
  showDownload?: boolean
  showOption?: boolean
  showCopy?: boolean
  onRegen?: (_: RegenOptions) => void
  linkOrUnlike?: (liked: boolean, id: string) => Promise<boolean>
}) {
  const [isErrorImage, setIsErrorImage] = useState(false)
  // const [modalOpen, setModalOpen] = useState(false)
  const [loadedImgList, setLoadedImgList] = useState<string[]>()
  const [popVisible, setPopVisible] = useState(false)
  const [optionVisiable, setOptionVisiable] = useState(false)
  const handleOptionEnterChange = (visible: boolean) => {
    setOptionVisiable(visible)
    if (!visible) {
      setPopVisible(false)
    }
  }
  const showReCreate = useMemo(() => {
    return !!task?.textPrompt
  }, [task])
  return (
    <>
      {work.illegal ? (
        // 违法图片
        <IllegalWork id={work.id} URL={work.image} taskId={task?.id} />
      ) : isErrorImage ? (
        <ErrorImage id={work.id} URL={work.image} taskId={task?.id} />
      ) : (
        // 合法图片
        <div
          className="relative h-full bg-[#676565] rounded cursor-pointer group"
          style={{ minHeight: "10vh" }}
          onMouseEnter={() => {
            setPopVisible(true)
          }}
          onMouseLeave={() => {
            !optionVisiable && setPopVisible(false)
          }}
        >
          {/* Work Image */}
          <img
            className="w-full h-full object-cover rounded select-none ---"
            src={getImageURL(work.image || "", true)}
            alt=""
            style={{
              visibility: work.image && loadedImgList?.includes(work.image) ? "visible" : "hidden",
            }}
            onLoad={(e) => {
              work.image && setLoadedImgList([work.image].concat(loadedImgList || []))
              // FIXME 接口清理脏数据后可以移除
              // 接口返回的 size 和 ratio 可能与实际图片信息不一致，导致显示比例不正确
              // https://github.com/TiamatAI/xica-docs/issues/235#issuecomment-1495304268
              runInAction(() => {
                const w = (e.target as HTMLImageElement).naturalWidth
                const h = (e.target as HTMLImageElement).naturalHeight
                work.size = `${w}x${h}`
                // 这里ratio导致再次生成比例错误 + 代码暂未用到，所以注释了
                // work.ratio = `1:${(h / w).toFixed(2)}`
              })
            }}
            onError={() => setIsErrorImage(true)}
          />

          {/* Collect to Album */}
          <div className="absolute top-2.5 right-2.5 hidden group-hover:block">
            <AlbumSelect work={work} />
          </div>

          {/* Loading Indicator */}
          <div
            className="absolute loading-indicator-primary z-5 top-0  right-0 h-full w-full flex flex-col justify-center items-center"
            style={{
              display: work.image && loadedImgList?.includes(work.image) ? "none" : "flex",
            }}
          >
            <div
              className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-neutral-100 motion-reduce:animate-[spin_1.5s_linear_infinite]"
              role="status"
            >
              <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Loading...
              </span>
            </div>
          </div>

          {/* Checkmark */}
          {onToggle && (
            <div className="absolute top-2.5 right-2.5">
              <input
                className="hidden peer"
                type="checkbox"
                id={`work-check-${work.id}`}
                checked={selectedAllWorks?.includes(work.id!)}
                onChange={({ currentTarget }) => {
                  onToggle?.([{ id: work.id!, checked: currentTarget.checked }])
                }}
              />
              <label
                htmlFor={`work-check-${work.id}`}
                className="w-5 h-5 items-center justify-center cursor-pointer bg-[#5A14F0] rounded hidden peer-checked:flex"
              >
                <CheckmarkIcon />
              </label>
              <label
                htmlFor={`work-check-${work.id}`}
                className="w-5 h-5 cursor-pointer bg-[#C9CBD1]/70 rounded block peer-checked:hidden"
              ></label>
            </div>
          )}

          {/* Pop */}
          <div hidden={!!onToggle}>
            <div
              className={`relative home-transition only:w-full flex justify-center 
              ${popVisible ? "visible opacity-100 top-0" : "invisible opacity-0 top-10"}`}
            >
              <div className="w-[96%] m-auto h-full absolute cursor-pointer top-0 ">
                <div className="bg-[#000000CC] w-full rounded absolute bottom-2">
                  <div>
                    <div
                      className="flex justify-around items-end px-3"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div className="text-white flex relative my-2">
                        <div className="absolute bottom-0">
                          {task.user?.avatar ? (
                            <img
                              className="h-10 w-10 border border-[#FFFFFF33] rounded-full object-contain"
                              src={task.user?.avatar!}
                            />
                          ) : (
                            <img
                              className="h-10 w-10 border border-[#FFFFFF33] rounded-full object-contain"
                              src={DefaultAvatarSvg}
                            />
                          )}
                        </div>
                        <label className="pl-12 text-[16px]">
                          {task.user?.nick?.length! > 10
                            ? task.user?.nick?.slice(0, 10) + "..."
                            : task.user?.nick}
                        </label>
                      </div>
                      {/* </Link> */}
                      <div className="ml-auto text-[#6663FF] rounded self-center">
                        <BigScore score={task.score!} />
                      </div>
                    </div>
                    <div
                      className={`text-[#FFFFFF99] px-3 text-sm leading-6
                      ${
                        parseInt(work.ratio?.split(":")[0] || "") /
                          parseInt(work.ratio?.split(":")[1] || "") >
                        1.5
                          ? "truncate"
                          : "text-three-line"
                      }
                    `}
                    >
                      {task?.textPrompt}
                    </div>
                  </div>
                  <div
                    className="px-3 pb-1 flex justify-between items-center"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div className="text-[#FFFFFF4D] ">
                      {getTime(new Date(task.createdAt!).getTime())}
                    </div>
                    <div className="flex items-end  text-white ">
                      {showRegen && (
                        <Tooltip content="再次生成">
                          <div>
                            {onRegen ? (
                              <CheckAuth
                                className="hover:bg-[#FFFFFF1A] transition rounded-sm"
                                onClick={() => {
                                  onRegen &&
                                    onRegen({
                                      textPrompt: task?.textPrompt || "",
                                      negativeTextPrompt: task?.negativeTextPrompt || "",
                                      model: task.model || "",
                                      ratio: work.ratio || "",
                                    })
                                }}
                              >
                                <MagicIcon />
                              </CheckAuth>
                            ) : showReCreate ? (
                              <GenerateAgainTask
                                task={task}
                                buttonContent={
                                  <>
                                    <div className="hover:bg-[#FFFFFF1A] transition rounded-sm">
                                      <MagicIcon />
                                    </div>
                                  </>
                                }
                              />
                            ) : null}
                          </div>
                        </Tooltip>
                      )}

                      <Tooltip content="复制描述语">
                        <div className="flex justify-end">
                          <CopyToClipboard
                            text={task?.textPrompt}
                            onCopy={() => {
                              toastInfo("已复制")
                            }}
                          >
                            {showCopy && (
                              <button className=" hover:bg-[#FFFFFF1A] transition rounded-sm">
                                <CopyIcon />
                              </button>
                            )}
                          </CopyToClipboard>
                        </div>
                      </Tooltip>

                      {showDownload && (
                        <Tooltip content="下载图像">
                          <button
                            onClick={() => handleDownload(work.image!)}
                            className="hover:bg-[#FFFFFF1A] transition rounded-sm"
                          >
                            <DownloadIcon />
                          </button>
                        </Tooltip>
                      )}
                      {showOption && (
                        <>
                          <PictureOption
                            task={task}
                            current={work}
                            showReCreate={showReCreate}
                            handleOptionEnterChange={handleOptionEnterChange}
                            // handleOptionClose={handleOptionClose}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <ModalV2
            open={modalOpen}
            task={task}
            work={work}
            onClose={() => setModalOpen(false)}
          ></ModalV2> */}
        </div>
      )}
    </>
  )
}

function BigScore({ score }: { score: number }) {
  const scoreStr = String(score.toFixed(1)).split(".")
  const first = scoreStr.length > 0 ? scoreStr[0] : null
  const second = scoreStr.length > 1 ? scoreStr[1] : null
  return (
    <div className="font-bold italic flex items-end">
      <div className="text-[24px] leading-[24px]">{first} </div>
      <div className="leading-4">{second ? `.${second}` : ""}</div>
    </div>
  )
}
