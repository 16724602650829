import { useState, useMemo } from "react"
import { Button, Popover } from "@arco-design/web-react"
import { ReactComponent as IconPopular } from "@/assets/popular.svg"
import { ReactComponent as SearchIcon } from "@/assets/search.svg"
import { isMobileOnly } from "react-device-detect"

export interface ISearchParams {
  sort: string
  query: string
}
type SortType = "newest" | "popular" | "highest" | "myFav"

export function SearchGroup({
  onSearch,
  onChangeTab,
  defaultTab = "create",
}: {
  defaultTab?: "create" | "fav"
  onSearch: (params: ISearchParams) => void
  onChangeTab?: (activeTab: string) => void
}) {
  const [visible, setVisible] = useState(false)
  const [query, setQuery] = useState("") // 搜索参数

  const [sortType, setSortType] = useState<SortType>("newest")
  const sortTypeList: { value: SortType; label: string }[] = [
    { value: "newest", label: "最新" },
    // { value: "highest", label: "最高分" },
    { value: "popular", label: "最受欢迎" },
  ]
  const sortText = useMemo(() => {
    const target = sortTypeList.find((item) => item.value === sortType)
    if (target) {
      return target.label
    }
    return ""
  }, [sortType])

  function changeParamsSort(sortType: SortType) {
    setSortType(sortType)
    setVisible(false)
    onSearch({
      // 切换排序依据的时候，触发父组件的种类切换
      sort: sortType,
      query,
    })
  }
  const [activeTab, setActiveTab] = useState<"create" | "fav">(defaultTab)
  return (
    <div className="flex items-center justify-between md:justify-start">
      <Popover
        title={null}
        popupVisible={visible}
        position="bl"
        trigger={"hover"}
        color={"#212134"}
        className="work-popover-wrapper"
        onVisibleChange={setVisible}
        content={
          <ul className="p-[5px] bg-[#212134]">
            {sortTypeList.map((item) => {
              return (
                <li
                  key={item.value}
                  className="cursor-pointer text-base text-white px-[10px] py-[5px] bg-transparent hover:bg-[#272739]"
                  onClick={() => changeParamsSort(item.value)}
                >
                  {item.label}
                </li>
              )
            })}
          </ul>
        }
      >
        <Button className="h-full" type="primary">
          <IconPopular className="inline-block align-middle" />
          {sortText}
        </Button>
      </Popover>
      {/* <div className="flex items-center rounded bg-[#0C0C1A] border border-[#04040b] md:ml-[20px] p-1">
        <div
          style={
            activeTab === "create"
              ? {
                  color: "#fff",
                  backgroundColor: "#161623",
                }
              : {}
          }
          className={"cursor-pointer px-4 py-1.5 text-sm  rounded text-[#ffffff80]"}
          onClick={() => {
            setActiveTab("create")
            onChangeTab && onChangeTab("create")
          }}
        >
          生成
        </div>
        <div
          onClick={() => {
            setActiveTab("fav")
            onChangeTab && onChangeTab("fav")
          }}
          style={
            activeTab === "fav"
              ? {
                  color: "#fff",
                  backgroundColor: "#161623",
                }
              : {}
          }
          className={"cursor-pointer px-4 py-1.5 text-sm  rounded text-[#ffffff80]"}
        >
          喜欢
        </div>
      </div> */}
      {!isMobileOnly && (
        <div className="max-w-[33.125rem] w-[400px] mx-[20px] px-3 pr-2.5 flex items-center  rounded-3xl border border-[#5B5B5B] group shadow-[0_0_10px_rgba(0,0,0,0.08)]">
          <SearchIcon
            width={18}
            height={18}
            className=" fill-[#fff]"
            onClick={() =>
              onSearch({
                sort: sortType,
                query,
              })
            }
          ></SearchIcon>
          <input
            className="flex-1 ml-3 py-1.5 text-base outline-none bg-transparent border-none placeholder:text-[#6F7176] peer"
            placeholder="输入关键词搜索作品"
            type="text"
            name="q"
            // defaultValue={query}
            value={query}
            onChange={(e) => {
              setQuery(e.target.value)
            }}
            onKeyDown={(e) => {
              if (e.key.toLowerCase() === "enter") {
                onSearch({
                  sort: sortType,
                  query,
                })
              }
            }}
          />
        </div>
      )}
    </div>
  )
}
