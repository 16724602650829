import { V1Task } from "@/generated/api/data-contracts"

const TaskProgress = ({ task }: { task: V1Task }) => {
  const total = new Date().getTime() - new Date(task?.createdAt || "").getTime()
  const x = total / (total + 60000) || 0
  return (
    <div className="relative w-[45vw] rounded-full h-1  bg-[#D80C24]">
      <div
        className="h-1  bg-slate-600 top-0 right-0 absolute rounded-r-full"
        style={{
          width: (1 - x) * 100 + "%",
        }}
      ></div>
    </div>
  )
}

export default TaskProgress
