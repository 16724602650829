import speedIcon from "@/assets/studio/speed.svg"
import * as store from "@/store"
import ActionButton from "./components/ActionButton"
import { useMemo } from "react"
import studioPagesStore from "@/store/studioPagesStore"

interface SpeedButtonProps {
  isFast: boolean
  onClick?: () => void
  disabled?: boolean
}
const SpeedButton = ({ isFast, onClick, disabled }: SpeedButtonProps) => {
  const { settingsDataReady } = studioPagesStore.store.get()

  const {
    id: userId,
    subscribedProduct, //是否订阅
    freeGenerationRemainingCount = 0,
    oneTimeSupplementRemainingCount = 0,
    rewardRemainingCount = 0,
    fastGenerationRemainingCount = 0,
  } = store.user.get()

  const count = {
    standardMode: freeGenerationRemainingCount,
    fastMode: oneTimeSupplementRemainingCount + rewardRemainingCount + fastGenerationRemainingCount,
  }

  const config = {
    slow: {
      buttonBgColorCls: "bg-transparent",
      buttonBorderColorCls: "border-[#fff]",
      text: "标准模式",
    },
    fast: {
      buttonBgColorCls: "bg-[#6663FF]",
      buttonBorderColorCls: "border-[#6663FF]",
      text: "快速模式",
    },
  }
  const activeConfig = isFast ? config.fast : config.slow
  const leftCount = count[isFast ? "fastMode" : "standardMode"]

  const leftCountFeatureAccessible = useMemo(() => {
    // Visitor
    const isVisitor = !userId

    // Standard Subscribe and Standard Mode
    const standardSubscribeAndStandardMode =
      subscribedProduct?.subscribeCategory === "Standard" && !isFast

    // Advanced Subscribe
    const isAdvancedSubscribe = subscribedProduct?.subscribeCategory === "Advanced"

    const disabled = isVisitor || standardSubscribeAndStandardMode || isAdvancedSubscribe
    return !disabled
  }, [userId, subscribedProduct, isFast])

  return (
    <div className="flex items-center bg-[#0C0C1A] rounded-full min-w-fit">
      <ActionButton
        iconSrc={speedIcon}
        text={activeConfig.text}
        buttonBgColorCls={activeConfig.buttonBgColorCls}
        buttonBorderColorCls={activeConfig.buttonBorderColorCls}
        onClick={() => {
          onClick?.()
        }}
        disabled={disabled}
      />

      {/* Left count */}
      {leftCountFeatureAccessible && settingsDataReady && (
        <div className="px-3 text-white text-opacity-50 text-sm min-w-fit">
          剩余次数：{leftCount}
        </div>
      )}
    </div>
  )
}

export default SpeedButton
