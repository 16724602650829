import { getAllTasks } from "@/services/editor"
import worksPagesStore from "@/store/worksPagesStore"
import { Button, Modal, Popover } from "@arco-design/web-react"
import { useEffect, useMemo, useRef, useState } from "react"
import { ReactComponent as EmptyIcon } from "@/assets/empty.svg"
import { ReactComponent as LoadingPuffIcon } from "@/assets/loading_puff.svg"
import { ReactComponent as IconPopular } from "@/assets/popular.svg"
import { ReactComponent as SearchIcon } from "@/assets/search.svg"
import { ReactComponent as DownloadImageIcon } from "@/assets/download_image.svg"
import { ReactComponent as IconCheckCircle } from "@/assets/check-circle.svg"
import { ReactComponent as IconLougout } from "@/assets/logout_dark.svg"
import { ReactComponent as MyWork } from "@/assets/my_work.svg"
import { ReactComponent as MyTemplate } from "@/assets/my_template.svg"
import { ReactComponent as DownloadIcon } from "@/assets/download.svg"
import { ReactComponent as DeleteIcon } from "@/assets/delete.svg"
import { ReactComponent as IconEyeOpen } from "@/assets/eye.svg"
import { ReactComponent as IconEyeClose } from "@/assets/eye-close.svg"
import { ReactComponent as HeartHollowIcon } from "@/assets/heart_hollow.svg"
import { featureDeprecatedAlert, getColumnCount, useWindowWidth } from "@/utils"
import * as store from "@/store"
import { Link, NavLink } from "react-router-dom"
import _ from "lodash"
import { useTranslation } from "react-i18next"
import { toastError, toastInfo, toastSuccess } from "@/common/Toast"
import { ErrorCode } from "@/i18next"
import GeneratedTaskCard from "@/components/bussiness/work/generated/GeneratedTaskCard"
import { api } from "@/services/api"
import { handleDownloadImgs } from "@/utils"
import { observer } from "mobx-react-lite"
import ButtonLoading from "@/common/ButtomLoading"
import WorkCover from "@/components/bussiness/work/WorkCover"
import WorksHeader from "./WorksHeader"
import { getMyTemplates } from "@/services/personal"
import { V1TemplateData } from "@/generated/api/data-contracts"
import TemplateItem from "@/common/TemplateItem"
import { isMobileOnly } from "react-device-detect"
import { Masonry } from "masonic"
import templateStore, { formatTemplatesModel } from "@/store/templateStore"
import TemplateDetailModal from "@/common/TemplateDetailModal"

enum SortEnum {
  newest = "newest",
  popular = "popular",
  highest = "highest",
  myFav = "myFav",
}

const sortTypeList: { value: SortEnum; label: string }[] = [
  { value: SortEnum.newest, label: "最新" },
  // { value: SortEnum.highest, label: "最高分" },
  { value: SortEnum.popular, label: "最受欢迎" },
]

type PageConfigType = {
  page: number
  search: string
  sortType: SortEnum
  total: number
}

const PAGE_SIZE = 10

const WorkGenerated = () => {
  const { t } = useTranslation(["translation", "errorMessage"])
  const tasks = worksPagesStore.store.get().taskList
  const selectedWorks = worksPagesStore.store.get().selectList
  const { canPrivateGeneration } = store.user.get()
  const [hasMore, setHasMore] = useState(true)
  const [loading, setLoading] = useState(false)
  const [loadingTemplate, setLoadingTemplate] = useState(false)
  const [templates, setTemplates] = useState<V1TemplateData[]>([])
  const [showBatchBar, setShowBatchBar] = useState(false)

  // 当前是我的作品还是我的模板
  const [currentIswork, setCurrentIswork] = useState<boolean>(true)
  // 分页配置
  const [pageConfig, setPageConfig] = useState<PageConfigType>({
    page: 0,
    search: "",
    sortType: SortEnum.newest,
    total: 0,
  })
  const worksCount = () => tasks?.reduce((r, task) => r + (task?.works?.length || 0), 0)
  const columnCount = getColumnCount(useWindowWidth(), true)

  const sortText = useMemo(() => {
    const target = sortTypeList.find((item) => item.value === pageConfig.sortType)
    if (target) {
      return target.label
    }
    return ""
  }, [pageConfig.sortType])

  useEffect(() => {
    templateStore.fetchTemplates()
    return () => {
      worksPagesStore.init()
    }
  }, [])

  useEffect(() => {
    !currentIswork && getTemplates()
    // 查看作品
  }, [currentIswork])
  const getData = async (
    page = pageConfig.page,
    search = pageConfig.search,
    sortType = pageConfig.sortType
  ) => {
    try {
      setLoading(true)
      const res = await getAllTasks(page, PAGE_SIZE, search, sortType).finally(() => {
        setLoading(false)
      })

      if (res.success) {
        const { data } = res
        setHasMore(
          (_.get(data, ["current"], page) - 1) * PAGE_SIZE + _.get(data, ["list", "length"], 0) <
            _.get(data, ["total"], 0)
        )
        if (page === 1) {
          worksPagesStore.pushTaskItems(() => data?.list || [])
        } else {
          worksPagesStore.pushTaskItems((list) => [...list, ...(data?.list || [])])
        }

        setPageConfig({
          page,
          search,
          sortType,
          total: data?.total || 0,
        })
      } else {
        toastError(t(`errorMessage:${res.errorCode as ErrorCode}`))
        setHasMore(false)
      }
    } catch (e) {
      // error
    }
  }

  const checkselectedWorksNumber = () => {
    if (selectedWorks?.length == 0) {
      toastInfo("请先选择作品")
      return false
    }
    return true
  }

  const selectAllWorks = (all: boolean) => {
    if (loading) {
      return
    }
    const newSelectedWorks: string[] = []
    if (all) {
      tasks?.forEach(({ works }) => {
        works?.forEach(({ id }) => newSelectedWorks.push(id || ""))
      })
    }

    worksPagesStore.changeSelectList(newSelectedWorks)
  }
  // 批量下载
  const downloadSelectedWorks = async () => {
    if (loading || !checkselectedWorksNumber()) {
      return
    }
    const urls: string[] = []
    tasks?.forEach(({ works }: any) => {
      works?.forEach(({ image, id }: any) => selectedWorks?.includes(id!) && urls.push(image!))
    })
    await handleDownloadImgs(urls)
    setShowBatchBar(false)
  }

  // 批量like / unlike
  const batchLikeOrUnlike = async (liked: boolean) => {
    featureDeprecatedAlert()
    // try {
    //   const { data } = await (liked
    //     ? api.batchLikeWorks({
    //         workIDs: selectedWorks || [],
    //         collectionUID: "JTQltwGbPEG3equutKwrX",
    //       })
    //     : api.batchUnlikeWorks({
    //         workIDs: selectedWorks || [],
    //         collectionUID: "JTQltwGbPEG3equutKwrX",
    //       }))

    //   if (data.success) {
    //     toastSuccess("操作成功")
    //     // worksPagesStore.changeTask((tasks) => {
    //     tasks.map((task, taskIndex) => {
    //       task.works?.map((work, workIndex) => {
    //         if (selectedWorks.includes(work.id ?? "")) {
    //           worksPagesStore.changeTaskItemWork(taskIndex, workIndex, {
    //             ...work,
    //             liked,
    //           })
    //         }
    //       })
    //     })
    //     //   return [...tasks]
    //     // })

    //     cancelBatch()
    //   } else {
    //     toastError(t(`errorMessage:${data.errorCode as ErrorCode}`))
    //   }
    // } catch (e) {
    //   //
    // }
  }
  //  我的模板
  const getTemplates = async () => {
    setLoadingTemplate(true)
    try {
      const { data, success, errorCode } = await getMyTemplates({ pageSize: 100, page: 1 })
      const formatedList = formatTemplatesModel(data?.templates || []) || []
      setTemplates(formatedList)
      // setTotal(data?.total || 0)
      if (!success) {
        toastError(t(`errorMessage:${errorCode}` as any))
      }
    } catch (e) {
      toastError("Error" + e)
    }
    setLoadingTemplate(false)
  }
  // 批量删除
  const deleteSelectedWorks = () => {
    if (!checkselectedWorksNumber()) return
    const modal = Modal.info({
      modalRender: () => {
        return (
          <div className="fixed top-0 bottom-0 left-0 right-0">
            <div className="bg-black text-white p-[16px] rounded absolute left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%] w-[360px]">
              <DeleteIcon className="inline-block w-[38px] h-[38px] mb-[10px]" />
              <h3 className="text-base leading-[20px] my-[8px] ">作品将被删除~</h3>
              <p className="text-sm leading-[20px]">此操作不能撤销</p>
              <div className="mt-[28px] flex justify-between">
                <Button type="outline" className={"flex-1 mr-[8px]"} onClick={() => modal.close()}>
                  关闭
                </Button>
                <Button
                  type="primary"
                  className={"flex-1 ml-[8px]"}
                  onClick={() => {
                    api
                      .batchDeleteWorks(selectedWorks)
                      .then(({ data }) => {
                        if (data.success) {
                          modal.close()
                          toastInfo("操作成功")
                          worksPagesStore.deleteWork(selectedWorks)
                          cancelBatch()
                        } else {
                          toastError(t(`errorMessage:${data.errorCode}` as any))
                        }
                      })
                      .catch(() => {
                        toastInfo("操作失败")
                      })
                  }}
                >
                  删除
                </Button>
              </div>
            </div>
          </div>
        )
      },
    })
  }

  // 批量公开隐藏
  const batchSetPrivateWorks = (isPrivate = false) => {
    Modal.confirm({
      title: `提示`,
      content: `已选中的所有作品将${isPrivate ? "隐藏" : "公开"}`,
      wrapClassName: "arco-modal-dark-wrapper",
      style: { width: "350px" },
      okButtonProps: {
        status: "warning",
      },
      onOk: () => {
        api
          .batchSetWorksIsPrivate({
            isPrivate: isPrivate,
            workIDs: selectedWorks || [],
          })
          .then(({ data }) => {
            if (data.success) {
              toastInfo("操作成功")
              tasks.map((task, taskIndex) => {
                task.works?.map((work, workIndex) => {
                  if (selectedWorks.includes(work.id ?? "")) {
                    worksPagesStore.changeTaskItemWork(taskIndex, workIndex, {
                      ...work,
                      isPrivate,
                    })
                  }
                })
              })
              cancelBatch()
            } else {
              toastError(t(`errorMessage:${data.errorCode as ErrorCode}`))
            }
          })
          .catch(() => {
            toastInfo("操作失败")
          })
      },
    })
  }

  // 是否展示喜欢 选中的大于1/2的unlike，就会展示like
  const selectShowFav = () => {
    let isFavCount = 0
    tasks.map((task) => {
      task.works?.map((work) => {
        if (work.liked && selectedWorks.includes(work?.id || "")) {
          isFavCount += 1
        }
      })
    })

    return isFavCount < selectedWorks.length / 2
  }

  // 是否展示私有 选中的大于1/2的公开，就会展示私有
  const selectShowPrivate = () => {
    let isPrivateCount = 0
    tasks.map((task) => {
      task.works?.map((work) => {
        if (work.isPrivate && selectedWorks.includes(work?.id || "")) {
          isPrivateCount += 1
        }
      })
    })

    return isPrivateCount < selectedWorks.length / 2
  }

  const cancelBatch = () => {
    worksPagesStore.changeSelectList([])
    setShowBatchBar(false)
  }
  const { id: currentUserId } = store.user.get()
  const [taskIndex, setTaskIndex] = useState<number | null>(null)
  const coverRef = useRef({ refreshCover: () => null })
  const templateModalVisiable = taskIndex !== null
  const openModal = (i: number) => {
    setTaskIndex(i)
  }
  const { templatesCenter, templateCategories } = templateStore.store.get()
  const closeModal = () => {
    setTaskIndex(null)
  }

  return (
    <>
      <WorkCover
        // userId={currentUserId}
        isVisitor={false}
        ref={coverRef}
        onSearch={() => {
          getData(1)
        }}
        searchValue={pageConfig.search}
        onSearchInputChange={(val) => {
          setPageConfig({
            ...pageConfig,
            search: val,
          })
        }}
        workOrTemplateText={currentIswork ? "我的模板" : "我的作品"}
        changeWorkOrTemplate={() => {
          setCurrentIswork(!currentIswork)
          setPageConfig({
            page: 0,
            search: "",
            sortType: SortEnum.newest,
            total: 0,
          })
        }}
      />
      <div className="w-full lg:min-w-[900px] flex justify-between items-center my-[20px]">
        {/* 顶部 */}
        <div className="lg:ml-0 ml-2 flex items-center lg:w-auto w-full  justify-between">
          <Popover
            position="bl"
            trigger={"hover"}
            color={"#212134"}
            className="work-popover-wrapper"
            content={
              <ul className="p-[5px] bg-[#212134]">
                {sortTypeList.map((item) => {
                  return (
                    <li
                      key={item.value}
                      className="cursor-pointer  text-white px-[10px] py-[5px] bg-transparent hover:bg-[#272739]"
                      onClick={() => getData(1, pageConfig.search, item.value)}
                    >
                      {item.label}
                    </li>
                  )
                })}
              </ul>
            }
          >
            <button className="h-full text-sm rounded md:bg-[#4C1FFF] py-2 px-4">
              <IconPopular className="inline-block align-middle mr-2" />
              {sortText}
            </button>
          </Popover>
          {currentIswork && (
            <>
              <div className="flex items-center rounded bg-[#0C0C1A] mx-5 p-1">
                <NavLink
                  to="/works"
                  className={`px-4 py-1.5 text-sm rounded text-[#ffffff80] cursor-pointer text-white bg-[#161623] shadow"`}
                >
                  生成
                </NavLink>
                <Link
                  className={`px-4 py-1.5 text-sm rounded text-[#ffffff80] cursor-pointer aria-[current]:text-white aria-[current]:bg-[#161623] aria-[current]:shadow`}
                  to="/works/collect"
                >
                  喜欢
                </Link>
              </div>
              <div className="hidden md:block">
                {showBatchBar ? (
                  <>
                    <button
                      className="py-2 px-3.5 w-20 text-center text-xs bg-[#111124] rounded hover:bg-[#60676f]"
                      onClick={cancelBatch}
                    >
                      <span>退出</span>
                    </button>
                    <button
                      className="py-2 px-3.5 w-20 text-center text-xs  bg-[#111124] hover:bg-[#60676f] rounded"
                      onClick={() => selectAllWorks(worksCount() !== selectedWorks.length)}
                    >
                      <span>{worksCount() !== selectedWorks.length ? "全选" : "取消全部 "}</span>
                    </button>
                  </>
                ) : (
                  <button
                    className="py-2 px-3.5 flex items-center text-sm bg-[#212134] hover:bg-[#4c1fff] rounded"
                    onClick={() => setShowBatchBar(true)}
                  >
                    <DownloadImageIcon className="mr-1" stroke="white" />
                    <span>图像管理</span>
                  </button>
                )}
              </div>
              <div className="hidden md:flex max-w-[33.125rem] lg:w-[400px] w-[300px] mx-[20px] px-5 py-1 items-center  rounded-3xl border border-[#ffffff1a] group shadow-[0_0_10px_rgba(0,0,0,0.08)] placeholder:text-[#ffffff33]">
                <SearchIcon
                  className=" fill-[#fff]"
                  width={18}
                  height={18}
                  onClick={() => getData(1)}
                ></SearchIcon>
                <input
                  className="flex-1 ml-3 py-1.5 text-sm outline-none bg-transparent border-none placeholder:text-[#6F7176] peer"
                  placeholder="输入关键词搜索作品"
                  type="text"
                  name="q"
                  value={pageConfig.search}
                  onChange={(e) => {
                    setPageConfig({
                      ...pageConfig,
                      search: e.target.value,
                    })
                  }}
                  onKeyDown={(e) => {
                    if (e.key.toLowerCase() === "enter") {
                      getData(1)
                    }
                  }}
                />
              </div>
            </>
          )}
        </div>
        <div>
          {/* Pc 端 */}
          {!isMobileOnly && (
            <button
              className="py-2 px-3.5 text-center text-xs bg-[#111124] rounded hover:bg-[#4c1fff] mr-[5px] flex"
              onClick={() => {
                setCurrentIswork(!currentIswork)
                setPageConfig({
                  page: 0,
                  search: "",
                  sortType: SortEnum.newest,
                  total: 0,
                })
              }}
            >
              <span>{currentIswork ? <MyWork /> : <MyTemplate />}</span>
              <span>{currentIswork ? "我的模板" : "我的作品"}</span>
            </button>
          )}
        </div>
      </div>
      {/* 区分我的模板和我的作品 */}
      {currentIswork && (
        <ButtonLoading
          getData={() => {
            if (loading || !hasMore) {
              return
            }
            getData(pageConfig.page + 1)
          }}
          total={tasks.length}
          loading={loading}
          hasMore={hasMore}
        >
          {tasks?.map((task, taskIndex) => {
            return task.works?.length ? (
              <div key={task.id} id={`generated-task-${taskIndex}`}>
                <GeneratedTaskCard showSelect={showBatchBar} taskIndex={taskIndex} task={task} />
              </div>
            ) : null
          })}
        </ButtonLoading>
      )}
      {!currentIswork &&
        (loadingTemplate ? (
          <LoadingPuffIcon
            key={"loading"}
            className="my-20 mx-auto"
            stroke="currentColor"
          ></LoadingPuffIcon>
        ) : templates?.length > 0 ? (
          <div className="px-[20px]">
            <Masonry
              items={templates}
              render={({ index, data }) => {
                return (
                  <div
                    onClick={() => {
                      openModal(index)
                    }}
                  >
                    <TemplateItem
                      key={data.id}
                      template={data}
                      className="group overflow-hidden h-full aspect-[1/1] items-center rounded-[4px] flex !w-full"
                      showAvator={false}
                      fromWork={true}
                    />
                  </div>
                )
              }}
              columnCount={columnCount}
              columnGutter={20}
            />
          </div>
        ) : (
          <div>
            <EmptyIcon className="mt-20 mx-auto"></EmptyIcon>
            <div className="mt-2.5 text-center text-sm">暂无作品</div>
          </div>
        ))}
      {templateModalVisiable && (
        <TemplateDetailModal
          defaultTemplateIndex={taskIndex}
          onClose={closeModal}
          templatesCenter={templates}
        />
      )}
      {/* 批量操作区域 */}
      {showBatchBar && (
        <div className="fixed flex items-center bottom-4 left-1/2 bg-[#000000cc] px-3 py-1 text-white rounded-md -translate-x-1/2 z-[100] ">
          <button
            className="bg-[#00000000] grid justify-items-center hover:bg-[#ffffff1a] text-cente text-xs py-2 px-3 rounded "
            onClick={downloadSelectedWorks}
          >
            <div className="h-[28px] w-[28px] flex justify-center items-center">
              <DownloadIcon />
            </div>
            <span className="mt-1">下载</span>
          </button>
          <button
            className="bg-[#00000000] grid justify-items-center hover:bg-[#ffffff1a] text-cente text-xs py-2 px-3 rounded "
            onClick={() => {
              batchLikeOrUnlike(selectShowFav())
            }}
          >
            <div className="h-[28px] w-[28px] flex justify-center items-center">
              <HeartHollowIcon />
            </div>
            <span className="mt-1">{selectShowFav() ? "喜欢" : "取消喜欢"}</span>
          </button>

          <button
            className="bg-[#00000000] grid justify-items-center hover:bg-[#ffffff1a] text-cente text-xs py-2 px-3 rounded "
            onClick={deleteSelectedWorks}
          >
            <div className="h-[28px] w-[28px] flex justify-center items-center">
              <DeleteIcon />
            </div>
            <span className="mt-1">删除</span>
          </button>

          {canPrivateGeneration && (
            <button
              className="bg-[#00000000] grid justify-items-center hover:bg-[#ffffff1a] text-cente text-xs py-2 px-3 rounded "
              onClick={() => batchSetPrivateWorks(selectShowPrivate())}
            >
              {selectShowPrivate() ? <IconEyeClose /> : <IconEyeOpen />}
              <span className="mt-1">{selectShowPrivate() ? "取消公开" : "公开"}</span>
            </button>
          )}
          <div className="w-px h-8 bg-[#FFFFFF33] mx-3"></div>
          <button
            className="bg-[#00000000] grid justify-items-center hover:bg-[#ffffff1a] text-cente text-xs py-2 px-3 rounded "
            onClick={() => {
              if (worksCount() === selectedWorks.length) {
                return
              }
              selectAllWorks(true)
            }}
          >
            <IconCheckCircle />
            <span className="mt-1">全选({selectedWorks?.length})</span>
          </button>
          <button
            className="bg-[#00000000] grid justify-items-center hover:bg-[#ffffff1a] text-cente text-xs py-2 px-3 rounded "
            onClick={cancelBatch}
          >
            <IconLougout />
            <span className="mt-1">退出</span>
          </button>
        </div>
      )}
    </>
  )
}

export default observer(WorkGenerated)
