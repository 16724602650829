import { Message, Modal, Upload } from "@arco-design/web-react"
import { ReactComponent as TitleIcon } from "@/assets/activities/name_title.svg"
import { useImperativeHandle, useState } from "react"
import { useTranslation } from "react-i18next"
interface NameInputProps {
  // nameRef: React.RefObject<NameObj>
  onChange?: (username: string) => void
}
const NameInput: React.FC<NameInputProps> = ({ onChange }) => {
  const { t } = useTranslation(["translation", "errorMessage"])
  // useImperativeHandle(nameRef, () => ({
  //   name: name,
  //   validate: () => {
  //     if (name.length === 0) {
  //       Message.error("请填写你的名字")
  //       return false
  //     }
  //     return true
  //   },
  // }))
  return (
    <div className="w-full">
      <div className="flex flex-col">
        <div className="py-[10px]">
          <TitleIcon />
        </div>
        <input
          className="border-[1px] border-[#433C5F] bg-[#FFFFFF] p-[10px] rounded-[4px] h-[42px] w-full text-[14px] placeholder-[#8F87AD] text-[#8F87AD]"
          placeholder="请输入你的昵称"
          onChange={(e) => {
            onChange?.(e.target.value)
          }}
        />
      </div>
    </div>
  )
}

export default NameInput
