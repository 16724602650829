import { ReactComponent as ProgressIcon } from "@/assets/zhangjiang-task-progress.svg"
import { V1Task } from "@/generated/api/data-contracts"

const TaskProgress = ({ task }: { task: V1Task }) => {
  const total = new Date().getTime() - new Date(task.createdAt || "").getTime()
  const x = total / (total + 60000)

  return (
    <div className="w-full pt-16 flex items-end justify-center pb-8">
    <div >
      <div className="flex justify-center">
        <ProgressIcon className="w-[10vw] h-[20vw]" />
      </div>
      <div className="flex justify-center">
        <h2 className="text-xl bg-clip-text text-transparent bg-gradient-to-r from-[#1FA2FF] via-[#12D8FA] to-[#A6FFCB]">
          生成即将开始... {(x * 100).toFixed(2)}%
        </h2>
      </div>
      <div className="flex justify-center my-10">
        <div className=" relative w-[45vw] rounded-full h-2  bg-gradient-to-r from-[#1FA2FF] via-[#12D8FA] to-[#A6FFCB]">
          <div
            className="h-2 bg-[#404040] top-0 right-0 absolute rounded-r-full"
            style={{
              width: (1 - x) * 100 + "%",
            }}
          ></div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default TaskProgress
