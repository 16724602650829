import _ from "lodash"
import { ReactNode, useEffect, useMemo, useRef, useState } from "react"

const getRatio = (ratio = "1:1") => {
  const [w = 1, h = 1] = ratio.split(":")
  return +h / +w
}

const findMinLength = (
  col: {
    list: ItemType[]
    count: number
  }[]
) => {
  let i = 0
  col.forEach((item, index) => {
    if ((item?.count || 0) < (col[i]?.count || 0)) {
      i = index
    }
  })

  return i
}

const getCol = (columnCount: number, items: ItemType[]) => {
  const col = new Array(columnCount)

  items.forEach((item, index) => {
    const minLengIndex = findMinLength([...col])
    const colItem = _.get(col, minLengIndex, {
      list: [],
      count: 0,
    })
    colItem.list.push({
      ...item,
      x: index,
    })
    colItem.count += getRatio(item.ratio)
    _.set(col, minLengIndex, colItem)
  })
  return col
}

type ItemType = {
  ratio: string
  [_: string]: any
}

const WaterfallFlow = ({
  items,
  columnCount,
  render,
  columnGutter,
}: {
  render: (item: any) => ReactNode
  items: ItemType[]
  columnCount: number
  columnGutter: number
}) => {
  const [flows, setFlows] = useState<
    {
      list: ItemType[]
      length: number
    }[]
  >()
  useEffect(() => {
    setFlows(getCol(columnCount, items))
  }, [items, columnCount])

  const wrapRef = useRef<HTMLDivElement>(null)
  const w = useMemo(() => {
    return ((wrapRef.current?.offsetWidth || 0) - (columnCount - 1) * columnGutter) / columnCount
  }, [wrapRef.current])
  // console.log(w)

  return (
    <div
      ref={wrapRef}
      className="flex mx-5 md:w-full md:mx-0"
      // style={{
      //   gap: `${columnGutter || 0}px`,
      // }}
    >
      {flows?.map(({ list }, flowIndex) => (
        <div
          key={flowIndex}
          className=" flex-shrink-0  flex-grow-0"
          style={{
            width: `${(1 / columnCount) * 100}%`,
          }}
        >
          {list.map((item, index) => (
            <div
              key={index}
              style={{
                marginBottom: (columnGutter || 0) + "px",
                width: w - columnGutter / 2 + "px",
                height: w * (getRatio(item.ratio) || 1) + "px",
              }}
            >
              <WaterfallFlowBox item={item} render={render} />
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

const WaterfallFlowBox = ({ item, render }: { item: ItemType; render: any }) => {
  const [show, setShow] = useState(false)
  const ref = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setTimeout(() => {
            setShow(true)
          }, 250)
          observer.unobserve(entry.target) // 停止观察
        }
      })
    })

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current)
      }
    }
  }, [])
  return (
    <div ref={ref} className="w-full h-full">
      {show ? (
        render?.(item)
      ) : (
        <div className="bg-[#ffffff2b] w-full h-full flex justify-center items-center rounded-md">
          <div className=" text-center text-[#ffffff33]">
            <h1>Tiamat</h1>
            <p>loading</p>
          </div>
        </div>
      )}
    </div>
  )
}

export default WaterfallFlow
