import speedFastIcon from "@/assets/studio/mobile/speed_fast_m.svg"
import speedNormalIcon from "@/assets/studio/mobile/speed_normal_m.svg"
import ActionIcon from "./components/ActionIcon"

interface SpeedStatusProps {
  isFast: boolean
  onClick?: () => void
  disabled?: boolean
}
const SpeedStatus = ({ disabled, isFast, onClick }: SpeedStatusProps) => {
  const iconSrc = isFast ? speedFastIcon : speedNormalIcon
  return <ActionIcon src={iconSrc} onClick={() => onClick?.()} disabled={disabled} />
}

export default SpeedStatus
