// import tongyongJichuURL from "./assets/models/tongyong-jichu.jpg"
// import tongyongXieshiURL from "./assets/models/tongyong-xieshi.jpg"
// import jueseLihuiURL from "./assets/models/juese-lihui.jpg"
// import guangyingZengqiangURL from "./assets/models/guangying-zengqiang.jpg"
// import guangyingZengqiangThumbnailURL from "./assets/models/guangying-zengqiang_thumbnail.jpg"
// import gainianChangjingURL from "./assets/models/gainian-changjing.jpg"
// import shoubanShejiURL from "./assets/models/shouban-sheji.jpg"
// import pingmianChahuaURL from "./assets/models/pingmian-chahua.jpg"
// import pingmianChahuaThumbnailURL from "./assets/models/pingmian-chahua_thumbnail.jpg"
// import liujinChahuaURL from "./assets/models/liujin-chahua.jpg"
// import liujinChahuaThumbnailURL from "./assets/models/liujin-chahua_thumbnail.jpg"

import tongyongChahuCoverURL from "./assets/models/tongyong-chatu/cover.png"
import tongyongChahuThumbnailURL from "./assets/models/tongyong-chatu/thumbnail.png"
import pingmianChahuaCoverURL from "./assets/models/pingmian-chahua/cover.png"
import pingmianChahuaThumbnailURL from "./assets/models/pingmian-chahua/thumbnail.png"
import shoubaoShejiCoverURL from "./assets/models/shouban-sheji/cover.png"
import shoubaoShejiThumbnailURL from "./assets/models/shouban-sheji/thumbnail.png"
import tongyongJichuCoverURL from "./assets/models/tongyong-jichu/cover.png"
import tongyongJichuThumbnailURL from "./assets/models/tongyong-jichu/thumbnail.png"
import tongyongXieshiCoverURL from "./assets/models/tongyong-xieshi/cover.png"
import tongyongXieshiThumbnailURL from "./assets/models/tongyong-xieshi/thumbnail.png"
import gainianChangjingCoverURL from "./assets/models/gainian-changjing/cover.png"
import gainianChangjingThumbnailURL from "./assets/models/gainian-changjing/thumbnail.png"
import guangyingZengqiangCoverURL from "./assets/models/guangying-zengqiang/cover.png"
import guangyingZengqiangThumbnailURL from "./assets/models/guangying-zengqiang/thumbnail.png"
import liujinChahuaCoverURL from "./assets/models/liujin-chahua/cover.png"
import liujinChahuaThumbnailURL from "./assets/models/liujin-chahua/thumbnail.png"
import liujinChahuaCover2URL from "./assets/models/liujin-chahua/cover-2.png"
import liujinChahuaThumbnail2URL from "./assets/models/liujin-chahua/thumbnail-2.png"
import dongmanFenggeCoverURL from "./assets/models/dongman-fengge/cover.png"
import dongmanFenggeThumbnailURL from "./assets/models/dongman-fengge/thumbnail.png"
import keaiXiantiaoCoverURL from "./assets/models/keai-xiantiao/cover.png"
import keaiXiantiaoThumbnailURL from "./assets/models/keai-xiantiao/thumbnail.png"
import guofengGuochaoCoverURL from "./assets/models/guofeng-guochao/cover.png"
import guofengGuochaoThumbnailURL from "./assets/models/guofeng-guochao/thumbnail.png"
import scribbleImg from "@/assets/controlnet/scribble.png"
import mlsdImg from "@/assets/controlnet/mlsd.png"
import openposeImg from "@/assets/controlnet/openpose.png"
import depthImg from "@/assets/controlnet/depth.png"
import cannnyImg from "@/assets/controlnet/canny.png"
import wechatqrcodeImg from "@/assets/wechatqrcode.jpeg"
import ctrlNetBrightness from "./assets/controlnet/ctrlNet-brightness.jpg"
import ctrlNetDw from "./assets/controlnet/ctrlNet-dw-openpose-full.jpg"
import ctrlNetInvert from "./assets/controlnet/ctrlNet-invert.jpg"
import ctrlNetLight from "./assets/controlnet/ctrlNet-light.jpg"
import ctrlNetLineart from "./assets/controlnet/ctrlNet-lineart.jpg"
import ctrlNetScribble from "./assets/controlnet/ctrlNet-scribble-pidinet.jpg"
import ctrlNetShuffle from "./assets/controlnet/ctrlNet-shuffle.jpg"

export const communityQrCodeURL = "https://cos-xica-prod.tiamat.world/assets/join-qr.jpeg"
export const fanbookQrCodeURL = "https://cos-xica-prod.tiamat.world/assets/fanbook-qr.jpeg"
export const wechatQrCodeURL = wechatqrcodeImg

export const newActivityStartTime = 1701916637

export const shanghaifilmActivityId = "7LVNRTfCU1voK0gxy4qga"
/**
 * 模型列表
 * 数据来源： https://tiamat-ai.feishu.cn/docx/QKFUdPVkroEtHEx22bEcrndDnfh
 */
export const MODELS: readonly ModelV2[] = Object.freeze([
  {
    name: "鎏金插图 V2.0",
    id: "liujin-chahua-2",
    vid: "liujin-chahua",
    version: "2.0",
    cover: liujinChahuaCover2URL,
    thumbnail: liujinChahuaThumbnail2URL,
    group: "联名",
    tags: ["升级"],
  },
  // {
  //   name: "鎏金插图 V1.0",
  //   id: "liujin-chahua",
  //   vid: "liujin-chahua",
  //   version: "1.0",
  //   cover: liujinChahuaCoverURL,
  //   thumbnail: liujinChahuaThumbnailURL,
  //   group: "联名",
  //   tags: ["HOT"],
  // },
  {
    name: "通用插图",
    id: "tongyong-chatu",
    cover: tongyongChahuCoverURL,
    thumbnail: tongyongChahuThumbnailURL,
    group: "联名",
    tags: ["HOT"],
  },
  {
    name: "平面插图",
    id: "pingmian-chahua",
    cover: pingmianChahuaCoverURL,
    thumbnail: pingmianChahuaThumbnailURL,
    group: "其他",
    tags: ["升级"],
  },
  {
    name: "手办设计",
    id: "shouban-sheji",
    cover: shoubaoShejiCoverURL,
    thumbnail: shoubaoShejiThumbnailURL,
    group: "其他",
    tags: ["升级"],
  },
  // {
  //   name: "角色立绘",
  //   id: "juese-lihui",
  //   cover: jueseLihuiURL,
  //   group: "其他",
  //   tags: ["HOT"],
  // },
  {
    name: "通用基础",
    id: "tongyong-jichu",
    cover: tongyongJichuCoverURL,
    thumbnail: tongyongJichuThumbnailURL,
    group: "其他",
    tags: [],
  },
  {
    name: "TIMv4-动漫",
    id: "DongManFengGe",
    cover: dongmanFenggeCoverURL,
    thumbnail: dongmanFenggeThumbnailURL,
    group: "其他",
    tags: [],
  },

  // {
  //   name: "通用写实",
  //   id: "tongyong-xieshi",
  //   cover: tongyongXieshiCoverURL,
  //   thumbnail: tongyongXieshiThumbnailURL,
  //   group: "其他",
  //   tags: [],
  // },
  // 通用写实1已经下掉，换为2名字不变
  {
    name: "TIMv4-写实",
    id: "TongYongXieShi2",
    cover: tongyongXieshiCoverURL,
    thumbnail: tongyongXieshiThumbnailURL,
    group: "其他",
    tags: [],
  },
  {
    name: "TIMv4-动漫",
    id: "auto-dragon-year",
    // id: "DongManFengGe",
    cover: tongyongXieshiCoverURL,
    thumbnail: tongyongXieshiThumbnailURL,
    group: "其他",
    tags: [],
  },
  // {
  //   name: "概念场景",
  //   id: "gainian-changjing",
  //   cover: gainianChangjingCoverURL,
  //   thumbnail: gainianChangjingThumbnailURL,
  //   group: "其他",
  //   tags: [],
  // },

  {
    name: "国风国潮",
    id: "GuoFengGuoChao",
    cover: guofengGuochaoCoverURL,
    thumbnail: guofengGuochaoThumbnailURL,
    group: "其他",
    tags: [],
  },
  {
    name: "可爱线条",
    id: "KeAiXianTiao",
    cover: keaiXiantiaoCoverURL,
    thumbnail: keaiXiantiaoThumbnailURL,
    group: "其他",
    tags: [],
  },
  {
    name: "概念场景",
    id: "gainian-changjing",
    cover: gainianChangjingCoverURL,
    thumbnail: gainianChangjingThumbnailURL,
    group: "其他",
    tags: [],
  },
  {
    name: "光影增强",
    id: "guangying-zengqiang",
    cover: guangyingZengqiangCoverURL,
    thumbnail: guangyingZengqiangThumbnailURL,
    group: "其他",
    tags: [],
  },
  {
    name: "TIMv5-动漫",
    id: "auto-tianime-default",
    cover: dongmanFenggeCoverURL,
    thumbnail: dongmanFenggeThumbnailURL,
    group: "其他",
    tags: [],
  },
  {
    name: "TIMv5-写实",
    id: "auto-tiality-default",
    cover: tongyongXieshiCoverURL,
    thumbnail: tongyongXieshiThumbnailURL,
    group: "其他",
    tags: [],
  },
  {
    name: "TIMv5-动漫-创意", // 动漫模型下的创意模式
    id: "auto-tianime-art",
    cover: dongmanFenggeCoverURL,
    thumbnail: dongmanFenggeThumbnailURL,
    group: "其他",
    tags: [],
  },
  {
    name: "TIMv5-写实-肖像", // 写实模型下的肖像模式
    id: "auto-tiality-portrait",
    cover: tongyongXieshiCoverURL,
    thumbnail: tongyongXieshiThumbnailURL,
    group: "其他",
    tags: [],
  },
])

export const V5_MODELS = [
  "auto-tianime-default",
  "auto-tiality-default",
  "auto-tianime-art",
  "auto-tiality-portrait",
]
export interface ModelV2 {
  name: string
  /** 与后端接口约定的 id */
  id: string
  /** 不同版本的模型 id 不同，vid 相同 */
  vid?: string
  version?: string
  cover: string
  thumbnail: string
  group: string
  tags: string[]
}

export const MODELS_MAP = Object.freeze(new Map(MODELS.map((model) => [model.id, model])))

export const DEFAULT_MODEL = Object.freeze(MODELS_MAP.get("auto-tianime-default") || MODELS[0])
// 映射不到的内测模型 统一 改为 其他模型
export const OTHER_MODEL = "其他模型"
export const WECHATID = "Heyuanna21"

export enum TEMPLATESTYPE {
  Recommand = "recommand",
  Hot = "hot",
  Character = "character",
  Other = "other",
}

export enum SUBSCRIPTIONSTITLE {
  BEGINNER = "VIP",
  STANDARD = "SVIP",
  ADVANCED = "Tia Pro",
}

export const SUBSCRIPTIONS: {
  [key: number]: {
    index: number // 用户区分用户订阅的之前等级和之后等级，对升级和降级做区别
    title: string
    desc: string[]
  }
} = {
  // 月
  // 所有订阅方案暂时注释 "一般商业条款"
  10: {
    index: 1,
    title: "VIP",
    desc: [
      "作品是公开的",
      "每月100次快速生成次数",
      "每月300次标准生成次数",
      "免费创建3个相册",
      "每个相册内可保存500张图片",
    ],
  },
  20: {
    index: 2,
    title: "SVIP",
    desc: [
      "作品是私密的",
      "每月500次快速生成次数",
      "每月无上限标准生成次数",
      "免费创建5个相册",
      "每个相册内可保存1000张图片",
    ],
  },
  30: {
    index: 3,
    title: "Tia Pro",
    desc: [
      "作品是私密的",
      "每月无上限快速生成次数",
      "每月无上限标准生成次数",
      "免费创建10个相册",
      "每个相册内可保存5000张图片",
    ],
  },
  // 连续包月
  12: {
    index: 4,
    title: "VIP",
    desc: [
      "作品是公开的",
      "每月100次快速生成次数",
      "每月300次标准生成次数",
      "免费创建3个相册",
      "每个相册内可保存500张图片",
    ],
  },
  22: {
    index: 5,
    title: "SVIP",
    desc: [
      "作品是私密的",
      "每月500次快速生成次数",
      "每月无上限标准生成次数",
      "免费创建5个相册",
      "每个相册内可保存1000张图片",
    ],
  },
  32: {
    index: 6,
    title: "Tia Pro",
    desc: [
      "作品是私密的",
      "每月无上限快速生成次数",
      "每月无上限标准生成次数",
      "免费创建10个相册",
      "每个相册内可保存5000张图片",
    ],
  },
  // 年
  11: {
    index: 7,
    title: "VIP",
    desc: [
      "作品是公开的",
      "每月100次快速生成次数",
      "每月300次标准生成次数",
      "免费创建3个相册",
      "每个相册内可保存500张图片",
    ],
  },
  21: {
    index: 8,
    title: "SVIP",
    desc: [
      "作品是私密的",
      "每月500次快速生成次数",
      "每月无上限标准生成次数",
      "免费创建5个相册",
      "每个相册内可保存1000张图片",
    ],
  },
  31: {
    index: 9,
    title: "Tia Pro",
    desc: [
      "作品是私密的",
      "每月无上限快速生成次数",
      "每月无上限标准生成次数",
      "免费创建10个相册",
      "每个相册内可保存5000张图片",
    ],
  },
}

export const MONTHSUBSCRIPTIONS = [
  {
    index: 1,
    subscribeType: 10,
    title: "VIP",
    desc: [
      "作品是公开的",
      "每月100次快速生成次数",
      "每月300次标准生成次数",
      "免费创建3个相册",
      "每个相册内可保存500张图片",
    ],
  },
  {
    index: 2,
    subscribeType: 20,
    title: "SVIP",
    hotText: "最受欢迎",
    desc: [
      "作品是私密的",
      "每月500次快速生成次数",
      "每月无上限标准生成次数",
      "免费创建5个相册",
      "每个相册内可保存1000张图片",
    ],
  },
  {
    index: 3,
    subscribeType: 30,
    title: "Tia Pro",
    hotText: "最受欢迎",
    desc: [
      "作品是私密的",
      "每月无上限快速生成次数",
      "每月无上限标准生成次数",
      "免费创建10个相册",
      "每个相册内可保存5000张图片",
    ],
  },
]

export const YEARSUBSCRIPTIONS = [
  {
    index: 7,
    subscribeType: 11,
    title: "VIP",
    desc: [
      "作品是公开的",
      "每月100次快速生成次数",
      "每月300次标准生成次数",
      "免费创建3个相册",
      "每个相册内可保存500张图片",
    ],
  },
  {
    index: 8,
    subscribeType: 21,
    title: "SVIP",
    hotText: "最受欢迎",
    desc: [
      "作品是私密的",
      "每月500次快速生成次数",
      "每月无上限标准生成次数",
      "免费创建5个相册",
      "每个相册内可保存1000张图片",
    ],
  },
  {
    index: 9,
    subscribeType: 31,
    title: "Tia Pro",
    hotText: "最受欢迎",
    desc: [
      "作品是私密的",
      "每月无上限快速生成次数",
      "每月无上限标准生成次数",
      "免费创建10个相册",
      "每个相册内可保存5000张图片",
    ],
  },
]
export const CONTROLNETV5MODELLIST = [
  {
    value: "canny",
    img: scribbleImg,
    description: "根据图片线稿生成完整图像",
    label: "线稿生成",
  },
  {
    value: "depth",
    img: depthImg,
    description: "根据底图深度生成完整图像",
    label: "深度识别",
  },
  {
    value: "openpose",
    img: openposeImg,
    description: "根据底图人物姿态生成完整图像",
    label: "姿态识别",
  },
]
export const CONTROLNETMODELLIST = [
  {
    value: "canny",
    img: scribbleImg,
    description: "根据图片线稿生成完整图像",
    label: "线稿生成",
  },
  {
    value: "lineart",
    img: ctrlNetLineart,
    description: "根据真实世界图像生成完整图像",
    label: "写实线稿",
  },
  {
    value: "invert",
    img: ctrlNetInvert,
    description: "根据白底黑线图生成完整图像",
    label: "黑白线性",
  },
  {
    value: "hed",
    img: cannnyImg,
    description: "根据底图边缘生成类似图像",
    label: "边缘检测",
  },
  {
    value: "mlsd",
    img: mlsdImg,
    description: "根据底图生成建筑场景完整图像",
    label: "建筑设计",
  },
  {
    value: "openpose",
    img: openposeImg,
    description: "根据底图人物姿态生成完整图像",
    label: "姿态识别",
  },
  {
    value: "dw_openpose_full",
    img: ctrlNetDw,
    description: "根据人体图像进行精准识别（含脸部手部）",
    label: "人体识别",
  },
  {
    value: "scribble_pidinet",
    img: ctrlNetScribble,
    description: "根据简单线条生成细节更为丰富的图像",
    label: "涂鸦效果",
  },

  {
    value: "depth",
    img: depthImg,
    description: "根据底图深度生成完整图像",
    label: "深度识别",
  },
  // {
  //   value: "monster_v2",
  //   img: depthImg,
  //   description: "根据底图黑白结构变化生成图像",
  //   label: "黑白生成",
  // },
  {
    value: "brightness",
    img: ctrlNetBrightness,
    description: "根据底图的明暗对明暗调整生成图像",
    label: "明暗变化",
  },
  {
    value: "shuffle",
    img: ctrlNetShuffle,
    description: "根据底图色彩生成大致相同的图像",
    label: "打乱色彩",
  },
  {
    value: "light",
    img: ctrlNetLight,
    description: "根据底图光影变化生成图像",
    label: "光影基础",
  },

  // { value: "scribble", label: "scribble" },
  // { value: "fake_scribble", label: "fake_scribble" },
  // { value: "openpose_hand", label: "openpose_hand" },
  // { value: "segmentation", label: "segmentation" },
  // { value: "normal_map", label: "normal_map" },
  // { value: "binary", label: "binary" },
  // { value: "color", label: "color" },
  // { value: "clip_vision", label: "clip_vision" },
  // { value: "pidinet", label: "pidinet" },
  // { value: "none", label: "none" },
]

export const statusTextMap = new Map([
  ["pass", " 已通过"],
  ["reject", "审核未通过"],
  ["pending", "审核中"],
])
export const statusColorMap = new Map([
  ["pass", "text-[#30D468]"],
  ["reject", "text-[#E93247]"],
  ["pending", "text-[#EB7219]"],
])

export const statusBorderColorMap = new Map([
  ["pass", "border-[#30D468]"],
  ["reject", "border-[#E93247]"],
  ["pending", "border-[#EB7219]"],
])

export const vipSubscribeTypeList = [10, 11, 12]
export const svipSubscribeTypeList = [20, 21, 22]
export const tiaProSubscribeTypeList = [30, 31, 32]

export const vipAlbumCount = 3
export const svipAlbumCount = 5
export const timProAlbumCount = 10

// 10入门单月 11入门包年 12入门连续包月 20标准包月 21标准包年 22标准连续包月 30进阶包月 31进阶包年 32进阶连续包月 40联名模型单月

export const albumIsMiss =
  "https://cos-xica-prod.tiamat.world/user/4nCFIIqa8jJTZzZfoF0dG/referencedimage/ykJYzt4Qk5YqKxieEmOhr.jpg"
export const imgIsMiss =
  "https://cos-xica-prod.tiamat.world/user/4nCFIIqa8jJTZzZfoF0dG/referencedimage/HEx47nZO1WNp8t2HPIKTU.jpg"
export const defaultCoverImg =
  "https://cos-xica-prod.tiamat.world/user/4nCFIIqa8jJTZzZfoF0dG/referencedimage/q0DXjbSTI5AxCO0wROMa3.jpg"

// js 获取当前域名
export const getRecordNumberByDomain = () => {
  const domain = window.location.host
  console.log(domain)
  if (domain.includes("tiamatai.cn")) {
    return "沪ICP备2021035530号-3"
  } else if (domain.includes("tiamatai.world")) {
    return "沪ICP备2021035530号-2"
  } else {
    return "沪ICP备2021035530号-4"
  }
}
