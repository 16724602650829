import * as store from "@/store/index"
import cameraIcon from "@/assets/profileEditor/camera.svg"
import { observer } from "mobx-react-lite"
import { useEffect, useRef, useState } from "react"
import { toastError } from "@/common/Toast"
import { api } from "@/services/api"
import { useTranslation } from "react-i18next"
import { ReactComponent as LoadingPuffIcon } from "@/assets/loading_puff.svg"
import { profileSceneType } from "../types"

const CameraLayer = ({ onClick }: { onClick?: () => void }) => {
  return (
    <div
      onClick={() => {
        onClick && onClick()
      }}
      className="absolute top-0 left-0 w-full h-full flex items-center justify-center rounded-full md:hover:bg-black/30 cursor-pointer"
    >
      <img src={cameraIcon} alt="" className="w-[100px] h-[100px] object-contain" />
    </div>
  )
}

const LoadingLayer = () => {
  return (
    <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center rounded-full bg-black/30">
      <LoadingPuffIcon className="w-10 h-10 animate-spin" />
    </div>
  )
}

interface AvatarProps {
  onChange?: (avatarUrl: string) => void
  scene?: profileSceneType
}
const Avatar = observer(({ onChange, scene = "modal" }: AvatarProps) => {
  const { t } = useTranslation()
  const { avatar: storeAvatar } = store.user.get()
  const avatarImageRef = useRef<HTMLInputElement>(null)
  const [avatarUrl, setAvatarUrl] = useState<string | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (avatarUrl === undefined && storeAvatar) {
      setAvatarUrl(storeAvatar)
    }
  }, [storeAvatar])

  useEffect(() => {
    onChange && onChange(avatarUrl || "")
  }, [avatarUrl])

  const handleCameraIconClick = () => {
    avatarImageRef.current?.click()
  }

  const uploadAvatar = async (file: File) => {
    setIsLoading(true)
    try {
      const resp = await api.uploadFile({ file })
      const fileUrl = resp.data?.data?.fileUrl
      if (!fileUrl) {
        const errorCode = resp.data?.errorCode
        if (errorCode) {
          toastError(t(`errorMessage:${errorCode}` as any))
        } else {
          toastError("上传失败")
        }
      } else {
        setAvatarUrl(fileUrl)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setTimeout(() => {
        setIsLoading(false)
      }, 500)
    }
  }

  const handleFileInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (!file) {
      return
    }

    // Check file size
    const M2 = 2 * 1024 * 1024
    if (file.size > M2) {
      return toastError("上传头像大小不能超过2MB～")
    }

    // Upload image
    uploadAvatar(file)
  }

  const styleConfig = {
    modal: {
      textColor: "text-white",
      imageContainerWidth: "w-[200px]",
      imageContainerHeight: "h-[200px]",
    },
    login: {
      textColor: "text-black",
      imageContainerWidth: "w-[180px]",
      imageContainerHeight: "h-[180px]",
    },
  }
  const activeStyle = styleConfig[scene]

  return (
    <div className="flex flex-col items-center">
      <div
        className={`${activeStyle.imageContainerWidth} ${activeStyle.imageContainerHeight} relative group bg-slate-100 rounded-full`}
      >
        <img
          src={avatarUrl}
          alt=""
          className="w-full h-full object-cover rounded-full hidden"
          onLoad={(e) => {
            const target = e.target as HTMLImageElement
            target.classList.remove("hidden")
          }}
        />

        {/* File Input */}
        <input
          type="file"
          className="hidden"
          ref={avatarImageRef}
          onChange={handleFileInputChange}
        />

        {isLoading ? <LoadingLayer /> : <CameraLayer onClick={handleCameraIconClick} />}
      </div>
      <div className={`mt-5 md:mt-2.5 text-xs ${activeStyle.textColor} text-opacity-50 font-sans`}>
        点击修改头像
      </div>
    </div>
  )
})

export default Avatar
