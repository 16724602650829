import ZhangjiangImage from "@/assets/images/zhangjiang-tiamat.png"
function Header() {
  return (
    <div className="w-full py-1 bg-black line-height justify-center flex items-center">
      <img src={ZhangjiangImage} width="210px" />
    </div>
  )
}

export default Header
