import {
  V1CreateImagePromptResponse,
  V1CreateTaskForGuestRequest,
  V1CreateTaskRequest,
  V1CreateTaskResponse,
  V1CreateImagePromptRequest,
  V1ImagePromptResp,
  V1ListBusyTaskResponse,
  V1ListWorkResponse,
  V1ReGenerateRequest,
  V1CreateTemplateRequest,
} from "@/generated/api/data-contracts"
import { LogicError } from "@/types"
import { api, apiV2 } from "./api"
import loginState from "@/utils/loginState"
import { RequestParams } from "@/generated/api/http-client"

export const apiListBusyTasks = async (): Promise<V1ListBusyTaskResponse> => {
  const logined = loginState.get()
  if (!logined) {
    // 访客这个没权限，然后
    return {
      busyTaskCount: 0,
    }
  }
  try {
    const {
      data: { data },
    } = await api.listBusyTasks({ format: "json" })
    return data
  } catch (error) {
    return {}
  }
}

export const apiCreateImagePrompt = async (
  req: V1CreateImagePromptRequest
): Promise<V1CreateImagePromptResponse | undefined> => {
  const logined = loginState.get()

  const res = await (logined
    ? api.createImagePrompt(req, { format: "json" })
    : api.guestCreateImagePrompt(req, { format: "json" }))
  // if (!res.data.success) {
  //   throw new LogicError(res.data.errorCode, res.data.errorMessage)
  // }
  return res.data as any
}

export const apigetImagePrompt = async (id: string): Promise<V1ImagePromptResp | undefined> => {
  const logined = loginState.get()

  const res = await (logined
    ? api.getImagePrompt(id, { format: "json" })
    : api.guestGetImagePrompt(id, { format: "json" }))
  if (!res.data.success) {
    throw new LogicError(res.data.errorCode, res.data.errorMessage)
  }
  return res.data.data
}

export const createTask = async (
  req: V1CreateTaskRequest
): Promise<V1CreateTaskResponse | undefined> => {
  const logined = loginState.get()
  const res = await (logined
    ? api.createTask(req, { format: "json" })
    : api.guestCreateTask(req, { format: "json" }))
  if (!res.data.success) {
    throw new LogicError(res.data.errorCode, res.data.errorMessage)
  }
  return res.data.data
}

export const createV2Task = async (
  req: V1CreateTaskRequest
): Promise<V1CreateTaskResponse | undefined> => {
  try {
    const res = await apiV2.v2TaskCreate(req, { format: "json" })
    return res.data.data
  } catch (e: any) {
    throw new LogicError(e.error.errorCode, e.error.errorMessage)
  }
}

// 访客创建task
export const apiCreateTaskForGuest = async (
  req: V1CreateTaskForGuestRequest
): Promise<V1CreateTaskResponse | undefined> => {
  const res = await api.guestCreateTask(req, { format: "json" })
  if (!res.data.success) {
    throw new LogicError(res.data.errorCode, res.data.errorMessage)
  }
  return res.data.data
}

//  重绘
export const apiRegenerateTask = async (
  req: V1ReGenerateRequest
): Promise<V1CreateTaskResponse | undefined> => {
  const res = await api.regenerate(req, { format: "json" })
  if (!res.data.success) {
    throw new LogicError(res.data.errorCode, res.data.errorMessage)
  }
  return res.data.data
}
//  访客重绘
export const guestApiRegenerateTask = async (
  req: V1ReGenerateRequest
): Promise<V1CreateTaskResponse | undefined> => {
  const res = await api.guestRegenerate(req, { format: "json" })
  if (!res.data.success) {
    throw new LogicError(res.data.errorCode, res.data.errorMessage)
  }
  return res.data.data
}

export const getAllTasks = async (
  current: number,
  pageSize: number,
  query?: string,
  sort?: string
) => {
  // @ts-ignore
  const res = await api.listMyTaskGroups({ current, pageSize, query, sort }, { format: "json" })
  return res.data
}

export const getLikeWorks = async (
  userId: string,
  current: number,
  pageSize: number,
  query: string,
  sort?: string
) => {
  const res = await api.listLikeWorksByUserId(userId, {
    current,
    pageSize,
    query,
    sort,
    isCurrentUser: true,
  })
  return res.data
}

export const getLikeTasks = async (
  userId: string,
  current: number,
  pageSize: number,
  query: string,
  sort?: string
) => {
  const res = await api.listLikeWorksByUserId(userId, {
    current,
    pageSize,
    query,
    sort,
    isCurrentUser: true,
  })
  if (!res.data.success) {
    throw new LogicError(res.data.errorCode, res.data.errorMessage)
  }
  /** 收藏接口更新后，把新的数据结构转成旧的数据结构，因为收藏作品和全部作品的逻辑是耦合的 */
  const data: V1ListWorkResponse = res.data.data
  if (data?.list) data.list = data.list.map((work) => ({ ...work.task, works: [work] }))
  return res.data.data
}
export const getLikeTasks2 = async (
  userId: string,
  current: number,
  pageSize: number,
  query: string,
  sort?: string
) => {
  const res = await api.listLikeWorksByUserId(userId, {
    current,
    pageSize,
    query,
    sort,
    isCurrentUser: true,
  })
  return res.data
}

export const getTaskDetail = async (id: string, params: RequestParams = {}) => {
  const logined = loginState.get()
  return logined ? api.getTask(id, params) : api.guestGetTask(id, params)
}

export const uploadFile = (data: any, params: RequestParams = {}) => {
  const logined = loginState.get()
  return logined ? api.uploadFile(data, params) : api.guestUploadFile(data, params)
}

export const uploadImageForPrompt = (data: any, params: RequestParams = {}) => {
  const logined = loginState.get()
  return logined
    ? api.uploadImageForPrompt(data, params)
    : api.guestUploadImageForPrompt(data, params)
}

export const updateStudioSettings = (data: any, params: RequestParams = {}) => {
  const logined = loginState.get()
  return logined ? api.updateStudioSettings(data, params) : null
}

export const getAllTasksOfZhangjiangV2 = async (query?: { limit?: number }) => {
  const res = await api.listForZhangJiangV2(query, { format: "json" })

  return res.data
}

export const getAllTasksOfZhangjiangV1 = async (query?: { limit?: number }) => {
  const res = await api.listForZhangJiangV1(query, { format: "json" })
  return res.data
}
//  获取模板分类
export const getTemplateCategories = async () => {
  const res = await api.listTemplateCategories({ format: "json" })
  return res.data
}
//  创建模板
export const createTemplate = async (data: V1CreateTemplateRequest, params: RequestParams = {}) => {
  const res = await api.createTemplate(data, { format: "json" })
  return res?.data
}
