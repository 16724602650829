import { ReactComponent as RecommendationIcon } from "@/assets/studio/template_recommendation.svg"
import { ReactComponent as ArrowDownIcon } from "@/assets/studio/arrow_down.svg"

const Title = ({ isExpanded }: { isExpanded?: boolean }) => {
  const rotateCls = isExpanded ? "" : "-rotate-90"

  return (
    <div className="ml-2 flex-1 flex items-center truncate">
      <div className="text-[16px] mr-[10px] flex-1">模板推荐</div>
      <ArrowDownIcon className={`${rotateCls} w-7 h-7 object-cover`} />
    </div>
  )
}

interface RecommendationHeaderProps {
  onClick?: () => void
  iconOnly?: boolean
  isExpanded?: boolean
}

const RecommendationHeader = ({ onClick, iconOnly, isExpanded }: RecommendationHeaderProps) => {
  return (
    <div
      className="flex items-center cursor-pointer"
      onClick={() => {
        onClick && onClick()
      }}
    >
      {/* RecommendationIcon */}
      <RecommendationIcon className="w-7 h-7 object-cover" />

      {/* Title */}
      {!iconOnly && <Title isExpanded={isExpanded} />}
    </div>
  )
}

export default RecommendationHeader
