import { ReactComponent as Icon } from "@/assets/studio_warning.svg"
export interface WarnningModalProps {
  onClose: () => void
}

export const WarnningModal: React.FC<WarnningModalProps> = (props) => {
  return (
    <div
      id="defaultModal"
      className="fixed top-0 left-0 right-0 z-[999] w-full p-4 bg-black bg-opacity-80
      overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full
      flex flex-col justify-center items-center"
    >
      <Icon />
      <div className="text-white text-[16px] leading-5 mb-2 mt-[9px]">关键词违禁！！</div>
      <div className="w-[290px] text-sm text-white">
        禁止使用反对宪法确定的基本原则、危害国家安全、
        <span className="text-[rgb(233,50,71)]">暴力、色情、政治、宗教、恐怖主义、极端主义</span>
        、歪曲、丑化、亵渎、否定英雄烈士实际和精神等词语；一旦超过三次违禁操作，将永久剥夺使用权限，并予以
        <span className="text-[rgb(233,50,71)]">法律追责</span>。
      </div>
      <button onClick={props.onClose} className="primary_btn w-[150px] h-[36px] mt-7">
        确定
      </button>
    </div>
  )
}
