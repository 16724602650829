import { useState } from "react"
import { IconDown } from "@arco-design/web-react/icon"
import { COMMONQUESTIONS, CUSTOMIZATIONDESCRIBE } from "@/constant"

export const EnterpriceComponent = () => {
  return (
    <div className="flex flex-col items-center mt-[48px]">
      <div className="text-[#ffffff] text-[34px] leading-[48px] font-normal mb-24">企业定制</div>
      <div className="text-[#ffffff] text-[16px] leading-[20px] mt-[14px] mb-[82px]"></div>

      <div className="flex md:flex-row flex-col gap-[22px] mb-[22px]">
        {CUSTOMIZATIONDESCRIBE[0].map((item) => {
          return (
            <div
              key={item.title}
              className="flex h-[154px] lg:w-[410px] w-full border-2 border-[#ffffff0b] rounded-md flex-col justify-start items-start relative pt-[56px] pl-[28px] pb-[20px] pr-[28px]"
            >
              <img
                className="absolute -top-[60px] right-[22px] h-[150px] px-[8px] py-[9px]"
                src={item.imgURL}
              />
              <div className="text-[18px] leading-[25px] mb-[6px]">{item.title}</div>
              <div className="text-[#6F7176] text-[14px] leading-5">{item.desc}</div>
            </div>
          )
        })}
      </div>
      <div className="flex flex-row md:gap-[22px] md:flex-nowrap flex-wrap md:justify-start justify-around">
        {CUSTOMIZATIONDESCRIBE[1].map((item) => {
          return (
            <div
              key={item.title}
              className="flex flex-col justify-start items-center pt-[26px] border-2 border-[#ffffff0b] rounded-md h-[176px] lg:w-[194px] w-[160px]  md:basis-auto basis-2/5"
            >
              <img src={item.imgURL} className="h-[60px]" />
              <div className="mt-[10px] mb-[2px] text-[#ffffff] text-[16px] leading-5 font-normal">
                {item.title}
              </div>
              <div
                className="text-[#6F7176] text-center text-[14px] leading-5 font-normal md:px-0 px-3 "
                dangerouslySetInnerHTML={{ __html: item.desc }}
              />
            </div>
          )
        })}
      </div>

      <a
        href="https://wenjuan.feishu.cn/m?t=sPd5AQJUXHKi-vxxz"
        target="_blank"
        rel="noopener noreferrer"
      >
        <button className="primary_btn w-[204px] h-[44px] mt-[48px] mb-[14px] rounded-md">
          联系我们
        </button>
      </a>
      <div className="text-[#ffffff] text-[14px] font-normal leading-5">
        获取个性化演示和定制方案
      </div>

      <div className="mt-[76px] mb-[30px] text-[26px] leading-[38px]">常见问题</div>
      <div className="w-full flex flex-col items-center">
        {COMMONQUESTIONS.map((i) => (
          <AccordionComponent key={i.title} title={i.title} detail={i.detail} />
        ))}
      </div>
      <div className="mt-[101px]" />
    </div>
  )
}
interface AccordionProps {
  title: string
  detail: string
}
const AccordionComponent: React.FC<AccordionProps> = (props) => {
  const [collpase, setCollapase] = useState<boolean>(true)
  const toggle = () => {
    setCollapase(!collpase)
  }
  return (
    <>
      <button
        type="button"
        className="mt-[12px] flex pl-[22px] items-center justify-between lg:w-[940px] w-[80%] h-[50px] bg-[#212134] font-normal text-[#ffffff] text-[16px] leading-5 rounded-[4px]"
        onClick={toggle}
      >
        <span className="text-left">{props.title}</span>
        <IconDown className={`${!collpase && "rotate-180"} mr-[12px]`} />
      </button>
      <div className={`${collpase && "hidden"} lg:w-[940px] w-[80%] bg-[#1a1a2a]`}>
        <div className="px-[22px] pt-[10px] pb-[20px] text-[#6F7176] text-[14px] leading-[22px] font-normal">
          {props.detail}
        </div>
      </div>
    </>
  )
}
