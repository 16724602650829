import loginState from "@/utils/loginState"
import { api } from "./api"
import {
  V1ListTasksResponse,
  V1ListReleasedTemplatesResponse,
  V1ListTemplateCategoriesResponse,
  ResponseShowType,
  V1GetURLSchemeResponse,
  V1GetActivityResponse,
  V1ListActivitiesForHomePageResponse,
  V1GetActivityUserStatusResponse,
  V1ReceiveActivityRewardRequest,
} from "@/generated/api/data-contracts"
import { LogicError } from "@/types"

export const getListAllTasks = async (query?: {
  /** current */
  current?: number
  /** pageSize */
  pageSize?: number
  /** query by prompt text */
  query?: string
  /** sort by highest, newest, popular; if not set, default is newest */
  sort?: string
  /** filter by categoryUID */
  category?: string
  /** filter by model */
  model?: string
  /** filter by has ReferencedImage */
  // hasReferencedImage?: boolean // 前端没有用到
}): Promise<V1ListTasksResponse> => {
  const logined = loginState.get()

  const res = await (logined
    ? api.listAllTasks(query, { format: "json" })
    : api.guestListAllTasks(query, { format: "json" }))

  return res?.data?.data
}

interface V1ListReleasedTemplatesResponseCustom {
  data?: V1ListReleasedTemplatesResponse
  errorCode?: string
  errorMessage?: string
  host?: string
  showType?: ResponseShowType
  success?: boolean
}

interface V1ListTemplateCategoriesResponseCustom {
  data?: V1ListTemplateCategoriesResponse
  errorCode?: string
  errorMessage?: string
  host?: string
  showType?: ResponseShowType
  success?: boolean
}

export interface V1GetURLSchemeResponseCustom {
  data?: V1GetURLSchemeResponse
  errorCode?: string
  errorMessage?: string
  host?: string
  showType?: ResponseShowType
  success?: boolean
}

export interface V1GetActivityResponseCustom {
  data?: V1ListActivitiesForHomePageResponse
  errorCode?: string
  errorMessage?: string
  host?: string
  showType?: ResponseShowType
  success?: boolean
}

export interface V1ListActivitiesForHomePageResponseCustom {
  data?: V1GetActivityResponse
  errorCode?: string
  errorMessage?: string
  host?: string
  showType?: ResponseShowType
  success?: boolean
}
export interface V1GetActivityUserStatusResponseCustom {
  data?: V1GetActivityUserStatusResponse
  errorCode?: string
  errorMessage?: string
  host?: string
  showType?: ResponseShowType
  success?: boolean
}

export const getTemplatesForHomePage = async (query: {
  /** page */
  page: number
  /** page size */
  pageSize: number
  isRecommand?: boolean
  model?: string
}): Promise<V1ListReleasedTemplatesResponseCustom> => {
  const res = await api.listTemplatesForHomePage(query, { format: "json" })
  return res?.data
}

export const getTemplateCategories = async (): Promise<V1ListTemplateCategoriesResponseCustom> => {
  const res = await api.listTemplateCategories({ format: "json" })
  return res?.data
}

export const getUrlScheme = async (params: {
  params: string
  path: string
}): Promise<V1GetURLSchemeResponseCustom> => {
  const res = await api.getUrlScheme(params, { format: "json" })
  return res?.data
}
// 查询活动信息

export const getActivityList = async (): Promise<V1GetActivityResponseCustom> => {
  const res = await api.listActivitiesForHomePage({ format: "json" })
  return res?.data
}

// 查询活动详情
export const getActivityDetail = async (
  activityUid: string
): Promise<V1ListActivitiesForHomePageResponseCustom> => {
  const res = await api.getActivity(activityUid, { format: "json" })
  return res?.data
}

// 查询领取奖励状态
export const getActivityStatus = async (
  activityUid: string
): Promise<V1GetActivityUserStatusResponseCustom> => {
  const res = await api.getUserStatus(activityUid, { format: "json" })
  return res?.data
}

// 领取奖励
export const getActivityReceive = async (
  params: V1ReceiveActivityRewardRequest
): Promise<V1GetActivityUserStatusResponseCustom> => {
  const res = await api.receiveActivityReward(params, { format: "json" })
  return res?.data
}

// 查询模板详情
export const getTemplateDetail = async (templateID: string) => {
  const res = await api.getTemplateForHomePage(templateID, { format: "json" })
  return res?.data
}

// 查询活动模板
export async function getActivityTemplateList(params?: { [key: string]: any }) {
  const res = await api.listForUserActivityTemplate(
    { q: JSON.stringify(params) },
    { format: "json" }
  )
  return res?.data
}

// 活动模板生成任务
export async function getActivityTemplateTask(params: { [key: string]: any }) {
  const res = await api.createTaskWithActivityTemplate(params, { format: "json" })
  if (!res.data.success) {
    throw new LogicError(res.data.errorCode, res.data.errorMessage)
  }
  return res?.data
}
