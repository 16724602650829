import LayoutGeneral from "@/components/layout/general"
import { useState } from "react"

import { ReactComponent as ForbiddenIcon } from "@/assets/forbidden.svg"

const ImageUnavailable = () => {
  const [loading, setLoading] = useState<boolean>(true)

  return (
   <div className="w-full  absolute inset-0 flex flex-col justify-center items-center">
        <ForbiddenIcon />
        <div>图片不见了</div>
        {/* NOT AVAILABLE */}
   </div>
  )
}

export default ImageUnavailable
