import { useState, useEffect, createRef, useRef } from "react"
import { ReactComponent as EyeIcon } from "@/assets/creation/eye.svg"
import { ReactComponent as LoadingPuffIcon } from "@/assets/loading_puff.svg"
import { ReactComponent as EyeCloseIcon } from "@/assets/creation/eyeclose.svg"
import { ReactComponent as CheckIcon } from "@/assets/check_radio.svg"
import { ReactComponent as UnCheckIcon } from "@/assets/uncheck-radio.svg"
import { ReactComponent as TootipIcon } from "@/assets/creation/tooltip.svg"
import { Button, Form, Input, Select, Image, Tooltip } from "@arco-design/web-react"
import { getImageURL, splitToTag } from "@/utils"
import {
  V1ControlNetSettings,
  V1CreateTemplateRequest,
  V1Task,
} from "@/generated/api/data-contracts"
import { CONTROLNETMODELLIST, MODELS_MAP } from "@/constants"
import { observer } from "mobx-react-lite"
import { CheckObj, TagInput } from "../TagInput"
import { isMobileOnly } from "react-device-detect"
import Cropper, { ReactCropperElement } from "react-cropper"
import "./index.less"
import { categories } from "."
import { IconCheck, IconPlus } from "@arco-design/web-react/icon"
import { uploadFile } from "@/services/editor"
import { toastError } from "../Toast"
const FormItem = Form.Item
interface configObj {
  [key: string]: string
}
// image, checked
interface exampleImgObj {
  image: string
  checked: boolean
  id: string
}
export default observer(function ({
  task,
  handleSubmit,
  handleCancel,
  open,
  options,
  loading,
  handleLoading,
}: {
  task: V1Task
  handleSubmit: (params: V1CreateTemplateRequest) => void
  open: boolean
  handleCancel: () => void
  options: Array<categories>
  loading: boolean
  handleLoading: (loadStatu: boolean) => void
}) {
  const [form] = Form.useForm()
  const currentImgRef = useRef<ReactCropperElement>(null)
  // 图片描述标签
  const [imgDescribeList, setImgDescribeList] = useState<Array<CheckObj>>([])
  // 不公开的描述标签(从图片描述标签添加而来)
  const [privateDescribeList, setPrivateDescribeList] = useState<Array<CheckObj>>([])
  // 不需要的元素标签
  const [negativeList, setNegativeList] = useState<Array<CheckObj>>([])
  // 示例图片
  const [exmpaleImgList, setExmpaleImgList] = useState<Array<exampleImgObj>>([])

  // 选中的示例图片
  const [checkedExmpaleImgList, setCheckedExmpaleImgList] = useState<Array<exampleImgObj>>([])
  const [coverImg, setCoverImg] = useState("")
  const [currentCoverId, setCurrentCoverId] = useState<string>("")
  const [categoryLabel, setCategoryLabel] = useState("")
  const [controlNetObj, setControlNetObj] = useState<V1ControlNetSettings>({})
  const [configObj, setConfigObj] = useState<configObj>({
    model: "",
    aspectRatio: "",
    category: "",
    controlNet1: "",
    controlNet2: "",
  })
  // 为了 id 唯一性
  const [selectDomId] = useState(() => "templat_classification_select" + new Date().getTime())
  const configObjTextMap = new Map([
    ["model", "模型"],
    ["aspectRatio", "图片比例"],
    ["category", "使用分类"],
    ["controlNet1", "ControlNet1"],
    ["controlNet2", "ControlNet2"],
  ])
  //  裁切展示值
  const ratioList = [
    {
      width: 1,
      height: 1,
    },
    {
      width: 4,
      height: 3,
    },
    {
      width: 3,
      height: 4,
    },
    {
      width: 16,
      height: 9,
    },
    {
      width: 9,
      height: 16,
    },
  ]
  //  当前选中的rotio
  const [checkedRatio, setCheckedRatio] = useState("1:1")
  const [ratio, setRatio] = useState<number>(1)
  // 裁切组件需要的值
  const [aspectRatio, setAspectRatio] = useState<number>(1) // 裁剪区域的比例
  const ratioPx = [
    [100, 100],
    [100, 75],
    [75, 100],
    [100, 56],
    [56, 100],
  ]
  const rotateTo = (item: string) => {
    const [w, h] = item.split(":")
    const rotate = +w / +h
    setAspectRatio(rotate)
  }
  const cropperRef = createRef<ReactCropperElement>() // cropper ref
  const submit = async () => {
    // 校验
    const res = await form.validate()
    handleLoading(true)
    try {
      const canvas = cropperRef.current?.cropper.getCroppedCanvas({
        imageSmoothingQuality: "low",
      })
      if (canvas) {
        canvas?.toBlob(
          async (blob) => {
            if (!blob) {
              return
            }
            const file = new File([blob], "image", {
              type: "image/jpeg",
            })

            try {
              const { data } = await uploadFile({ file })
              if (data.success && data.data.fileUrl) {
                let exampleWorkIDs = exmpaleImgList
                  .filter((item) => item.checked === true)
                  .map((item) => item.id)
                handleSubmit({
                  ...res,
                  ...configObj,
                  exampleWorkIDs,
                  coverImage: data.data.fileUrl,
                  controlNet: controlNetObj,
                })
              } else {
                toastError("上传失败请重试")
              }
            } catch (error) {
              toastError("上传失败请重试")
            }
            // setImgStatus(2)
          },
          "image/jpeg",
          0.8
        )
        // setLoading(false)
      } else {
        toastError("请上传图片")
      }
    } catch (error) {}
  }

  const cancel = () => {
    form.resetFields()
    // 图片描述 不公开的描述 不需要的元素
    setImgDescribeList([])
    setPrivateDescribeList([])
    setNegativeList([])
    setConfigObj({
      model: "",
      aspectRatio: "",
      category: "",
      controlNet1: "",
      controlNet2: "",
    })
    handleCancel()
  }
  //  将网址图片转为本地 blob（ 解决网络图片裁剪后闪烁问题 ）
  const transNetImg = (url: string) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob)
        setCoverImg(url)
      })
  }

  useEffect(() => {
    const { textPrompt, negativeTextPrompt, model = "", works = [], controlNet = {} } = task
    setControlNetObj(controlNet)
    // 图片描述标签列表
    let promptTagsList = splitToTag(textPrompt).map((item, index) => ({
      label: item,
      editable: index === 0 ? true : false,
    }))
    setImgDescribeList(promptTagsList)
    form.setFieldValue("prompt", JSON.stringify(promptTagsList))
    //  不需要的描述
    let negativePromptList = negativeTextPrompt
      ? splitToTag(negativeTextPrompt).map((item) => ({
          label: item,
          editable: true,
        }))
      : []
    form.setFieldValue("negativePrompt", JSON.stringify(negativePromptList))
    setNegativeList(negativePromptList)

    //  默认配置
    setConfigObj({
      model: model || "",
      aspectRatio: works[0]?.ratio || "",
      category: "",
      controlNet1:
        CONTROLNETMODELLIST.find((item) => item.value === controlNet?.units?.[0]?.module)?.label ||
        "",
      controlNet2:
        CONTROLNETMODELLIST.find((item) => item.value === controlNet?.units?.[1]?.module)?.label ||
        "",
    })
    let imgList: exampleImgObj[] =
      works
        ?.filter((item) => item?.image)
        .map((item) => ({ image: item.image || "", checked: false, id: item?.id || "" })) || []
    setExmpaleImgList(imgList)
    //  关闭后重置表单内容
    open === false ? cancel() : ""
  }, [open])
  useEffect(() => {
    const checkedList = exmpaleImgList?.filter((item) => item.checked === true) || []
    const length = checkedList?.length
    const currentIsInList = checkedList?.filter((item) => item.id == currentCoverId)?.length > 0
    if (length < 1) {
      // coverImg 裁切需要图片  currentCoverId  提交需要的图片id
      setCoverImg("")
      setCurrentCoverId("")
    }
    //  选中的示例图片中没有了当前封面图
    if (!currentIsInList) {
      setCurrentCoverId(checkedList[0]?.id || "")
      setCoverImg(checkedList[0]?.image || "")
    }
  }, [exmpaleImgList])
  return (
    <div className={`templat-modal-form-wrapper w-full`}>
      {/* <div>
            <LoadingPuffIcon
              key={"loading"}
              className="my-20 mx-auto absolute top-[40%] left-[40%]"
              stroke="currentColor"
            ></LoadingPuffIcon>
       </div> */}

      <Form
        autoComplete="off"
        form={form}
        layout={isMobileOnly ? "vertical" : "horizontal"}
        className={`md:overflow-y-auto md:max-h-[640px] w-screen ${
          isMobileOnly ? "template-mdoal-mobile-form" : ""
        }`}
        colon={!isMobileOnly}
      >
        <FormItem
          label="模板名称"
          field="name"
          rules={[{ required: true, message: "请输入模板名称" }]}
        >
          <Input placeholder="请输入模板名称" />
        </FormItem>
        <FormItem
          label="模板介绍"
          field="intro"
          rules={[
            { required: true, message: "模板介绍为必填项" },
            { minLength: 10, message: "模板介绍不能少于10个字符" },
            { maxLength: 1000, message: "模板介绍不能超过1000个字符" },
          ]}
        >
          <Input placeholder="请用一句话介绍你的模板吧" />
        </FormItem>
        <FormItem label="默认配置">
          <div className="md:bg-[#161623] md:h-[64px] md:px-[15px] md:py-[10px] mt-[10px] flex h-[47px]">
            {Object.keys(configObj).map((item: string) => {
              if (configObj[item])
                return (
                  <div className="h-[44px] flex flex-col mr-[40px]" key={item}>
                    <div className="leading-[22px] text-zinc-500 md:text-[12px] text-[10px]">
                      {configObjTextMap.get(item)}
                    </div>
                    <div className=" leading-[22px] text-zinc-200 md:text-[14px] text-[16px]">
                      {item == "model" &&
                        (MODELS_MAP.get(configObj[item] || "")?.name || "其他模型")}
                      {item == "aspectRatio" && configObj[item]}
                      {item == "category" && categoryLabel}
                      {item == "controlNet1" && configObj[item]}
                      {item == "controlNet2" && configObj[item]}
                    </div>
                  </div>
                )
            })}
          </div>
        </FormItem>
        <FormItem
          label="图片描述"
          rules={[
            { required: true },
            {
              validator(value, callback) {
                let jsonVal = JSON.parse(value) || []
                let editableNum = jsonVal.filter((item: CheckObj) => item.editable === true).length
                if (editableNum < 1) {
                  callback("可编辑项数量至少1个")
                }
              },
            },
          ]}
          field="prompt"
          tooltip={{
            icon: isMobileOnly ? (
              <Tooltip content="可选定默认允许编辑项，不可修改描述文字，可编辑项数量至少1个">
                <TootipIcon className="inline-block text-[12px] w-[12px] h-[12px] ml-[5px]" />
              </Tooltip>
            ) : (
              <span></span>
            ),
          }}
        >
          <TagInput
            tagsList={imgDescribeList}
            icon={<EyeIcon />}
            tipInfo="可选定默认允许编辑项，不可修改描述文字，可编辑项数量至少1个"
            removeHandle={(newTags: Array<CheckObj>, associationTags: CheckObj) => {
              setImgDescribeList(newTags)
              let newPrivateList = [...privateDescribeList, associationTags]
              setPrivateDescribeList(newPrivateList)
              form.setFieldValue("prompt", JSON.stringify(newTags))
              form.setFieldValue("privatePrompt", JSON.stringify(newPrivateList))
            }}
            editableHandle={(editableList) => {
              setImgDescribeList(editableList)
              form.setFieldValue("prompt", JSON.stringify(editableList))
            }}
          />
        </FormItem>
        <FormItem
          label="不需要的元素"
          field="negativePrompt"
          tooltip={{
            icon: isMobileOnly ? (
              <Tooltip content="仅可选定默认允许编辑项">
                <TootipIcon className="inline-block text-[12px] w-[12px] h-[12px] ml-[5px]" />
              </Tooltip>
            ) : (
              <span></span>
            ),
          }}
        >
          <TagInput
            tagsList={negativeList}
            icon={<div className="w-2"></div>}
            tipInfo="仅可选定默认允许编辑项"
            editableHandle={(editableList) => {
              setNegativeList(editableList)
              const negativePrompt = editableList?.length ? JSON.stringify(editableList) : ""
              form.setFieldValue("negativePrompt", negativePrompt)
            }}
          />
        </FormItem>
        <FormItem
          label="不公开的描述"
          field="privatePrompt"
          tooltip={{
            icon: isMobileOnly ? (
              <Tooltip content="用户在图片描述中所选项会自动进入该区域，最多5个">
                <TootipIcon className="inline-block text-[12px] w-[12px] h-[12px] ml-[5px]" />
              </Tooltip>
            ) : (
              <span></span>
            ),
          }}
          rules={[
            {
              validator(value = "[]", callback) {
                let length = privateDescribeList?.length
                if (length > 5) {
                  callback("不公开的描述，最多可选择5个")
                }
              },
            },
          ]}
        >
          <TagInput
            tagsList={privateDescribeList}
            icon={<EyeCloseIcon />}
            tipInfo="用户在图片描述中所选项会自动进入该区域，最多5个"
            removeHandle={(newTags: Array<CheckObj>, associationTags: CheckObj) => {
              setPrivateDescribeList(newTags)
              setImgDescribeList([...imgDescribeList, associationTags])
            }}
            isPrivate={true}
          />
        </FormItem>
        {/* <FormItem
          label="使用技巧"
          field="tips"
          rules={[
            { required: true, message: "使用技巧为必填项，且不能少于十个字，不能多于1000字" },
            {
              minLength: 10,
              maxLength: 1000,
              message: "使用技巧为必填项，且不能少于十个字，不能多于1000字",
            },
          ]}
        >
          <Input
            placeholder="请输入模板的使用技巧,例如不同元素的作用点,以及设计思路等"
            maxLength={1000}
          />
        </FormItem> */}
        <FormItem
          label="使用分类"
          rules={[{ required: true, message: "请选择使用分类" }]}
          field="category"
        >
          <div id={selectDomId}>
            <Select
              placeholder="未选择分类"
              getPopupContainer={() => document.getElementById(selectDomId) || document.body}
              onChange={(category) => {
                form.setFieldValue("category", category)
                setConfigObj({ ...configObj, category })
                let obj: categories[] = options?.filter((item) => item?.value === category)
                setCategoryLabel(obj[0]?.label)
              }}
              options={options}
              value={configObj.category}
              allowClear
            ></Select>
          </div>
        </FormItem>
        <Form.Item
          label="示例图片"
          field="exampleWorkIDs"
          required={true}
          rules={[
            {
              validator(value, callback) {
                let checkedLength = exmpaleImgList.filter((item) => item.checked === true).length
                if (checkedLength < 1) {
                  callback("请选择至少一张示例图片")
                }
                if (checkedLength > 4) {
                  callback(" 最多可选4张示例图片")
                }
              },
            },
          ]}
        >
          {/* <div className="md:bg-[#161623] md:mt-[0px] mt-[20px] text-white w-full rounded-[4px] max-w-[510px] overflow-x-scroll text-ellipsis flex whitespace-nowrap gap-[5px]"> */}
          <div className="flex overflow-x-auto gap-[3px] p-[5px] bg-[#161623] mt-[15px] md:mt-[0]">
            {exmpaleImgList?.map((obj: exampleImgObj, index: number) => (
              <div
                key={obj?.image}
                className="relative shrink-0 min-w-0"
                ref={currentImgRef}
                //  选中示例图片
                onClick={() => {
                  let temp = [...exmpaleImgList]
                  temp[index].checked = !temp[index].checked
                  setExmpaleImgList(temp)
                  //  第一次选中示例图片同时添加到封面
                  if (!currentCoverId) {
                    transNetImg(obj?.image)
                    setCurrentCoverId(obj?.id)
                  }
                }}
              >
                <img
                  key={index}
                  src={getImageURL(obj?.image || "")}
                  height={120}
                  alt={`lamp${index + 1}`}
                  className="h-[120px] rounded-[6px] cursor-pointer object-contain"
                ></img>
                {obj?.checked && (
                  <div
                    className="absolute top-0 right-0 rounded-bl-[8px] rounded-tr-[10px] w-[25px] h-[18px] bg-[#6663FF] border-[2px] border-[#4C1FFF] flex justify-center items-center"
                    onClick={() => {
                      let imgList = exmpaleImgList.filter((item) => item.id !== obj.id)
                      setExmpaleImgList(imgList)
                    }}
                  >
                    <IconCheck />
                  </div>
                )}
                <div
                  className={`absolute ${
                    obj?.checked
                      ? "border-[#4C1FFF] border-[2px] rounded-[6px] w-full h-full top-[0]"
                      : ""
                  }`}
                ></div>
                <div
                  className={`absolute left-[4px] bottom-[4px]`}
                  onClick={(e) => {
                    transNetImg(obj?.image)
                    setCurrentCoverId(obj?.id)
                    e?.stopPropagation()
                  }}
                >
                  {/* id:通过id找到 checked：选中的示例图 */}
                  {exmpaleImgList?.filter((exm) => exm.id === obj?.id && exm.checked)?.length > 0 &&
                    (currentCoverId === obj?.id ? <CheckIcon /> : <UnCheckIcon />)}
                </div>
              </div>
            ))}
          </div>
        </Form.Item>
        <Form.Item
          label="封面图"
          field="coverImg"
          required
          rules={[
            {
              validator(value, callback) {
                if (!coverImg) {
                  callback("请选择封面图")
                }
              },
            },
          ]}
        >
          {!currentCoverId ? (
            <div className="inset-0 rounded bg-black/80 flex items-center justify-center w-[160px] h-[120px] mt-[15px] md:mt-[0]">
              <IconPlus />
              请选择封面图
            </div>
          ) : (
            <div className="relative w-[200px]">
              <Cropper
                key={`${coverImg}-${aspectRatio}`}
                wheelZoomRatio={1}
                ref={cropperRef}
                style={{
                  width: currentImgRef?.current?.getBoundingClientRect().width,
                  height: currentImgRef?.current?.getBoundingClientRect().height,
                }}
                zoomable={false} // 图缩放
                aspectRatio={aspectRatio}
                preview=".img-preview"
                src={coverImg}
                viewMode={1}
                dragMode="none"
                minCropBoxHeight={100}
                minCropBoxWidth={100}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                guides={true}
                // 拖动裁切框触发
                // cropend={cropImage}
                // cropBoxMovable={imgStatus === 0}
                // cropBoxResizable={imgStatus === 0}
              />
              {loading && (
                <div className="absolute  top-[0] left-[0] inset-0 rounded bg-black/80 flex items-center justify-center">
                  <LoadingPuffIcon />
                </div>
              )}
            </div>
          )}
          {/* 裁切比例切换 */}
          {/* <div className="flex space-x-2 justify-around mt-[20px] md:w-[400px]">
            {ratioList.map((ratio, i) => {
              const ratioStr = `${ratio.width}:${ratio.height}`
              return (
                <RatioItem
                  onClick={() => {
                    rotateTo(ratioStr)
                    setCheckedRatio(ratioStr)
                  }}
                  width={ratio.width}
                  height={ratio.height}
                  key={ratio.width}
                  active={ratioStr === checkedRatio}
                />
              )
            })}
          </div> */}
        </Form.Item>
      </Form>
      {/* <div className="mb-[10px] text-[#878787] text-center mt-[10px]">
        审核通过后即可获得100个金币奖励
      </div> */}
      <div className="flex justify-center mt-[40px]">
        {!isMobileOnly && (
          <Button
            type="outline"
            className="w-[120px] h-[36px] mr-[10px] text-[#fff]"
            style={{ color: "#fff", border: "1px solid rgba(255,255,255,0.18)" }}
            onClick={async () => {
              cancel()
            }}
          >
            取消
          </Button>
        )}
        <Button
          type="primary"
          className="md:w-[120px] md:h-[36px] w-[100%] md:py-[10px]"
          onClick={submit}
          style={{ height: isMobileOnly ? "50px" : "", fontSize: isMobileOnly ? "20px" : "" }}
          loading={loading}
        >
          提交
        </Button>
      </div>
      <div className="text-center text-xs mt-2 opacity-60">允许其他用户使用所有参考图片</div>
    </div>
  )
})
