import Header from "./Header"
import Footer from "./Footer"

import type { ReactNode } from "react"
import "./layout.less"
import { isMobileOnly } from "react-device-detect"

interface Props {
  children?: ReactNode
}
function LayoutUserCenter({ children }: Props) {
  return (
    <>
      <Header></Header>
      <div className="layout-user-center" style={{ padding: isMobileOnly ? "0px" : "" }}>
        {children}
      </div>
      <Footer></Footer>
    </>
  )
}

export default LayoutUserCenter
