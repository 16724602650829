// 这里的状态用于接口切换使用， mobx里的状态共组件使用
const loginState = {
  change(state: boolean) {
    localStorage.setItem("login-state", state ? "true" : "false")
  },
  get() {
    return localStorage.getItem("login-state") === "true"
  },
}

export default loginState
