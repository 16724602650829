import dayjs from "dayjs"

/*
   1天内：以「小时」标注，例：1h前、2h前2. 
   超过1天、小于7天：以「天数」标注，例：1天前、2天前3. 
   超过7天：以「具体时间标注」，精确到 yyyy.mm.dd，例：2023.03.27
   违法数据： 有脏数据，2023年之前的统一算作 2023.03.22
  */
export function getTime(stp?: number) {
  // 3600000 1h
  const hour1 = 3600000
  const day1 = hour1 * 24
  const year1 = day1 * 365
  const sendTime = dayjs(stp)
  const todayTime = dayjs()
  const todayLineTime = dayjs(todayTime.format("YYYY-MM-DD"))
  if (sendTime.valueOf() >= todayLineTime.valueOf()) {
    const hour = (todayTime.valueOf() - sendTime.valueOf()) / hour1
    if (hour < 1) {
      return "刚刚"
    }
    return `${hour.toFixed(0)}小时前`
  } else if (sendTime.valueOf() >= todayLineTime.valueOf() - day1 * 7) {
    let hour = (todayLineTime.valueOf() - sendTime.valueOf()) / day1 + 1
    return `${hour.toFixed(0)}天前`
  } else if (sendTime.valueOf() / year1 < 53) { // 检测2023年之前，有可能有0.01.01脏数据
    const fakeDate = "2023.03.22"
    return dayjs(fakeDate).format("YYYY.MM.DD")
  }
  return dayjs(stp).format("YYYY.MM.DD")
}
