import { createContext, useContext } from "react"

const VisitStatusContext = createContext({
  isVisted: false,
  userId: "",
})

export default VisitStatusContext

export const useVisitStatus = () => {
  return useContext(VisitStatusContext)
}
