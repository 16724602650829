import { MODELS } from "@/constants"
import { observer } from "mobx-react-lite"
import { ReactComponent as SelectedIcon } from "@/assets/selected.svg"
import * as store from "@/store"
interface ModalSelectUIProps {
  modelId: string
  modelVersionId?: string
  onClick: (model: string) => void
  className?: string
  modelVersionClick?: (modelVersion: string) => void
  isActivity?: boolean
}

const ModalSelectUI = (props: ModalSelectUIProps) => {
  const {
    className,
    modelId,
    modelVersionId = "TIMv5",
    onClick,
    modelVersionClick,
    isActivity = false,
  } = props || {}
  const { id } = store.user.get()
  const allowIds = [
    "p4rk8HuvK_1dnD0W0TDxx",
    "p4rk8HuvK_1dnD0W0TDxx",
    "3YLYLvOq4xdPi025F6py6",
    "t11OW_xpCbbtYoGM8Abao",
    "O9PfE7tTXhDEWrX8GHUJ5",
    "O9PfE7tTXhDEWrX8GHUJ5",
    "r4fvoL5VG8TX0KK8XvZWY",
  ]
  const isTIMv5 = modelVersionId === "TIMv5" && !isActivity
  const showModels = isTIMv5
    ? ["auto-tianime-default", "auto-tiality-default"]
    : allowIds.includes(id)
    ? ["DongManFengGe", "TongYongXieShi2", "auto-dragon-year"]
    : ["DongManFengGe", "TongYongXieShi2"]

  const modelVersion = ["TIMv5", "TIMv4"]

  const filterModels = () => {
    return MODELS?.filter((item) => showModels.includes(item?.id))
  }

  const ItemBorder = ({ active }: { active?: boolean }) => {
    return (
      <div
        className={`absolute top-0 left-0 w-full h-full border-2 border-solid rounded ${
          active ? "border-[#6663FF]" : "border-transparent"
        }`}
      ></div>
    )
  }

  const comparePrefix = (str1: string, str2: string) => {
    const extractPrefix = (str: string) => str.split("-").slice(0, 2).join("-")
    return extractPrefix(str1) === extractPrefix(str2) ? str1 : undefined
  }

  return (
    <div
      className={`  ${
        className
          ? className
          : "max-h-none bg-none p-0 rounded  md:bg-[#161623] md:p-[10px]   md:max-h-[380px] overflow-y-auto scrollbar-hidden"
      } `}
    >
      {!isActivity && (
        <div className="flex gap-3 mb-3">
          {modelVersion.map((item) => {
            const isActive = modelVersionId ? item === modelVersionId : item === "TIMv5"
            return (
              <div
                onClick={() => {
                  modelVersionClick && modelVersionClick?.(item)

                  const model = item === "TIMv5" ? "auto-tianime-default" : "DongManFengGe"
                  onClick && onClick?.(model)
                }}
                key={item}
                className="flex items-start gap-2.5 rounded px-2 py-0.5 cursor-pointer "
                style={{
                  backgroundColor: isActive ? "#4c1fff" : "#1c1c28",
                }}
              >
                {item}
              </div>
            )
          })}
        </div>
      )}
      <div className="grid grid-cols-2 md:grid-cols-2 md:gap-[10px]  gap-[5px]">
        {filterModels()?.map((item) => {
          let isActive
          if (modelVersionId === "TIMv5") {
            isActive = item.id === comparePrefix(item.id, modelId)
          } else {
            isActive =
              item.id === modelId || (!showModels.includes(modelId) && item.id === showModels[0])
          }

          return (
            <div
              key={item.id}
              className="relative rounded cursor-pointer overflow-hidden"
              onClick={() => {
                onClick && onClick?.(item.id)
              }}
            >
              <div className="flex items-center justify-center h-[65px] bg-[#1C1C28] w-full text-center font-pingfong font-normal text-base leading-5">
                {item.id == "auto-dragon-year" ? "龙年模型" : item?.name?.split("-")[1]}
              </div>

              {isActive ? (
                <div className="absolute bottom-[0px] right-[0px]">
                  <SelectedIcon />
                </div>
              ) : (
                ""
              )}
              <ItemBorder active={isActive} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default observer(ModalSelectUI)
