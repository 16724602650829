import LayoutUserCenter from "@/components/layout/userCenter"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { ReactComponent as IconAccount } from "@/assets/account.svg"
import { ReactComponent as IconTask } from "@/assets/task.svg"
import { ReactComponent as IconTime } from "@/assets/time.svg"
import { ReactComponent as IconExchangeCode } from "@/assets/exchange-code.svg"
import { ReactComponent as IconPopop } from "@/assets/popop.svg"
import { ReactComponent as HomeMenu } from "@/assets/home/menu.svg"
import { ReactComponent as IconModel } from "@/assets/minetemplate.svg"
import AwardCollectionRecord from "@/components/bussiness/userCenter/AwardCollectionRecord"
import TaskCenter from "@/components/bussiness/userCenter/TaskCenter"
import AccountSetting from "@/components/bussiness/userCenter/AccountSetting"
import UseExchangeCode from "@/components/bussiness/userCenter/UseExchangeCode"
import Feedback from "@/components/bussiness/userCenter/Feedback"
import { useLocation } from "react-router-dom"
import "./useCenter.less"
import menuStore from "@/store/menuStore"
import * as store from "@/store"
import LayoutGeneral from "@/components/layout/general"
import MyTemplate from "@/components/bussiness/userCenter/MyTemplate"
import { isMobileOnly } from "react-device-detect"
import { Search } from "../HomeV2/Search"

function UserCenter() {
  const { t } = useTranslation(["translation"])

  const location = useLocation()

  const menus = [
    {
      name: "accountSetting",
      label: t("AccountSetting"),
      icon: <IconAccount />,
    },
    {
      name: "taskCenter",
      label: t("TaskCenter"),
      icon: <IconTask />,
    },
    // {
    //   name: "myTemplate",
    //   label: t("MyTemplate"),
    //   icon: <IconModel />,
    // },
    {
      name: "useRedemptionCode",
      label: t("UseExchangeCode"),
      icon: <IconExchangeCode />,
    },
    {
      name: "awardCollectionRecord",
      label: t("AwardCollectionRecord"),
      icon: <IconTime />,
    },
    {
      name: "feedback",
      label: t("Feedback"),
      icon: <IconPopop />,
    },
  ]

  useEffect(() => {
    if (location.hash) {
      srcollToTarget(location.hash.substring(1))
    }
  }, [location.hash])
  useEffect(() => {
    if (location.hash) {
      srcollToTarget(location.hash.substring(1))
    }
  }, [])
  const [activeMenu, setActiveMenu] = useState(
    location.hash ? location.hash.substring(1) : "accountSetting"
  )

  function srcollToTarget(id: string) {
    const target = document.getElementById(id)
    if (target) {
      window.scrollTo(0, target.offsetTop - 60)
    }
  }

  const [updateKey, setUpdateKey] = useState(1)
  const changeUpdateKey = () => {
    store.refreshUser()
    setUpdateKey(updateKey + 1)
  }
  return (
    <LayoutGeneral>
      <LayoutUserCenter>
        <section className=" px-5 pt-5 flex items-center md:hidden text-[16px]">
          <HomeMenu
            className=" w-5 h-5 mr-5"
            onClick={() => {
              menuStore.openMenu()
            }}
          />
          个人中心
        </section>
        <div className="user-center-wrapper">
          <div className="left-menu hidden md:block">
            <h3 className="menu-title">{t("UserCenter")} </h3>
            <ul className="menu-wrapper">
              {menus.map((item) => {
                return (
                  <li
                    className={activeMenu === item.name ? " menu-item is-active" : " menu-item"}
                    key={item.name}
                    onClick={() => {
                      location.hash = `#${item.name}`
                      setActiveMenu(item.name)
                    }}
                  >
                    {item.icon}
                    {item.label}
                  </li>
                )
              })}
            </ul>
          </div>
          {/* 移动端隐藏左侧菜单  left-hiden-box适配华为pad */}
          <div className={`left-hiden-box ${isMobileOnly ? "hidden" : ""}`}></div>
          <div
            className="right-content"
            style={{ padding: isMobileOnly ? "0px" : "", width: isMobileOnly ? "100%" : "auto" }}
          >
            <div id="accountSetting">
              <AccountSetting />
            </div>
            <div id="taskCenter">
              <TaskCenter key={updateKey} changeUpdateKey={changeUpdateKey} />
            </div>
            <div id="useRedemptionCode">
              <UseExchangeCode key={updateKey} changeUpdateKey={changeUpdateKey} />
            </div>
            {/* <div id="myTemplate">
              <MyTemplate />
            </div> */}
            <div id="awardCollectionRecord">
              <AwardCollectionRecord key={updateKey} />
            </div>
            <div id="feedback">
              <Feedback />
            </div>
          </div>
        </div>
      </LayoutUserCenter>
    </LayoutGeneral>
  )
}

export default UserCenter
