import UploadImageWithCropper from "@/components/common/UploadImageWithCropper"
import { useEffect, useState, forwardRef, useImperativeHandle } from "react"
import type { Ref } from "react"
import { NavLink } from "react-router-dom"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
// component
import { Avatar } from "@/common/Avatar"
import * as store from "@/store"
import menuStore from "@/store/menuStore"
// icon
import { ReactComponent as MobileSearchIcon } from "@/assets/search/search.svg"
import { ReactComponent as HomeMenu } from "@/assets/home/menu.svg"
import { ReactComponent as IconSwitch } from "@/assets/switch.svg"
import { ReactComponent as IconUploadLoading } from "@/assets/loading-dash.svg" // TODO
import { ReactComponent as IconUploadFailed } from "@/assets/failed.svg"
import { ReactComponent as IconUploadSuccess } from "@/assets/check-success.svg"
import { ReactComponent as IconClothes } from "@/assets/clothes.svg"
import { toastError, toastSuccess } from "@/common/Toast"
import { api } from "@/services/api"

import { LogicError } from "@/types"

interface Props {
  isVisitor?: boolean
  userId: string
  handleSearch?: (val: string) => void
  activeTab?: string
}
type UploadStatusType = "pending" | "failed" | "success" | "none"
interface IRef {
  refreshCover: () => void
}
function WorkCover({ isVisitor = false, userId, handleSearch, activeTab }: Props, ref: Ref<IRef>) {
  const { t } = useTranslation(["translation", "errorMessage"])

  const [totalWork, setTotalWork] = useState(0)
  const [nick, setNick] = useState("")
  const [avatar, setAvatar] = useState("")
  const [createdAt, setCreatedAt] = useState("")
  const [bgUrl, setBgUrl] = useState("") // Cover壁纸
  const [uploadStatus, setUploadStatus] = useState<UploadStatusType>("none")
  const selfUserId = store.user.get().id // 用户自己的id
  const [searchValue, setSearchValue] = useState("")
  useEffect(() => {
    getUserInfo()
  }, [userId])
  useEffect(() => {
    setSearchValue("")
  }, [activeTab])
  function getUserInfo() {
    if (isVisitor && userId) {
      getUserInfoByUserId(userId)
    } else {
      getUserInfoByUserId(userId, { isCurrentUser: true })
    }
  }
  useImperativeHandle(ref, () => {
    return {
      refreshCover: () => {
        getUserInfo()
      },
    }
  })
  //  获取用户数据
  async function getUserInfoByUserId(userId: string, params?: { isCurrentUser?: boolean }) {
    try {
      if (!userId) {
        return toastError("not user id")
      }
      const { data } = await api.getUserProfile(userId, params)
      if (data.success) {
        setTotalWork(data.data?.workCount ?? 0)
        setBgUrl(data.data.cover ?? "")
        setNick(data.data.nick ?? "")
        setAvatar(data.data.avatar ?? "")
        setCreatedAt(data.data.createdAt ?? "")
      }
    } catch (e) {
      if (e instanceof LogicError) {
        toastError(t(`errorMessage:${e.code}` as any))
      }
    }
  }

  //  更新用户壁纸
  async function updateUserInfoCover(bgSrc: string) {
    try {
      const { data } = await api.updateUserInfo({
        cover: bgSrc,
      })
      if (data.success) {
        toastSuccess("更新壁纸成功")
      }
    } catch (e) {
      if (e instanceof LogicError) {
        toastError(t(`errorMessage:${e.code}` as any))
      }
    }
  }
  return (
    <>
      {/* for mobile */}
      <div
        className={`md:hidden p-5
          relative after:content-[''] after:absolute after:top-0 after:left-0 after:w-full
          after:h-full after:pb-[17.77%] after:bg-[#16162380] after:z-0 block
        `}
        style={{
          backgroundImage: `url(${bgUrl})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "50% 50%",
        }}
      >
        <div className=" z-10 relative flex items-center">
          <HomeMenu
            className=" w-5 h-5 mr-5"
            onClick={() => {
              menuStore.openMenu()
            }}
          />
          <div className="bg-gray-900 pl-1 bg-opacity-80 flex-1 max-w-[33.125rem] lg:w-[400px] py-1 flex items-center  rounded-3xl border border-[#ffffff1a] group shadow-[0_0_10px_rgba(0,0,0,0.08)] placeholder:text-[#ffffff33]">
            <MobileSearchIcon
              className=" md:hidden my-px w-[18px] h-[18px] md:w-4 md:h-4"
              // onClick={() => onSearch && onSearch()}
            />

            <input
              className=" h-[30px] text-base flex-1 ml-1 outline-none bg-transparent border-none placeholder:text-[#6F7176] peer"
              placeholder="输入关键词搜索作品"
              type="text"
              name="q"
              value={searchValue || ""}
              onChange={(e) => {
                setSearchValue(e.target.value)
              }}
              onKeyDown={(e) => {
                if (e.key.toLowerCase() === "enter") {
                  handleSearch && handleSearch(searchValue)
                }
              }}
            />
          </div>
        </div>

        <div className="mt-[30px] flex items-center justify-between relative z-10">
          <div className=" flex items-center">
            <Avatar URL={avatar} className=" w-[60px] h-[60px]" />
            <div className=" ml-[10px]">
              <div className="text-white text-[24px] truncate leading-7 whitespace-nowrap overflow-hidden text-ellipsis max-w-[180px]">
                {nick}
              </div>
              <div className="">
                <span className="text-[12px] text-white text-opacity-80">作品 {totalWork}</span>
                <span className="ml-[10px] text-[8px] text-white text-opacity-80">|</span>
                <span className="ml-[10px] text-[12px] text-white text-opacity-80">
                  自 {dayjs(createdAt ?? "").format("YYYY-MM-DD")}
                </span>
              </div>
            </div>
          </div>

          {/* <button
            className="bg-[#4C1FFF] text-[14px] px-[10px] py-[8px] rounded-[4px]"
            onClick={changeWorkOrTemplate}
          >
            {workOrTemplateText}
          </button> */}
        </div>
      </div>
      {/* for desktop */}
      <div
        className={`relative hidden
      after:content-[''] after:absolute after:top-0 after:left-0 after:w-full 
      after:h-full after:pb-[17.77%] after:bg-[#16162380] after:z-0 md:block`}
        style={{
          backgroundImage: `url(${bgUrl})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: "100%",
          paddingBottom: "17.77%",
          backgroundPosition: "50% 50%",
        }}
      >
        <div className="absolute top-[24px] right-[50px] flex items-center z-10">
          {!isVisitor && (
            <UploadImageWithCropper
              requstPromise={api.updateCover}
              disabled={uploadStatus === "pending"}
              onUploadProgress={() => {
                setUploadStatus("pending")
              }}
              onUploadFailed={() => {
                setUploadStatus("failed")
              }}
              onUploadSucess={(fileUrl) => {
                setUploadStatus("success")
                setBgUrl(fileUrl)
                updateUserInfoCover(fileUrl)
                setTimeout(() => {
                  setUploadStatus("none")
                }, 3000)
              }}
              useDefaultLoading={false}
              uploadBtn={
                <div className="cursor-pointer h-[28px] w-[28px] justify-center items-center  bg-[#ffffff1a] flex rounded">
                  {uploadStatus === "none" ? (
                    <IconClothes />
                  ) : uploadStatus === "failed" ? (
                    <IconUploadFailed />
                  ) : uploadStatus === "success" ? (
                    <IconUploadSuccess />
                  ) : (
                    <IconUploadLoading />
                  )}
                </div>
              }
            />
          )}
          {selfUserId == userId && (
            <NavLink
              className="cursor-pointer px-2 py-1 ml-[10px] bg-[#ffffff1a] text-sm inline-block rounded"
              to={!isVisitor ? `/visitor/${userId || "66666"}` : "/works"}
              end
            >
              <IconSwitch className="inline-block mr-1" />

              <span>{isVisitor ? "自己" : "访客"}</span>
            </NavLink>
          )}
        </div>
        <div className="z-10 text-center absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2">
          <Avatar
            URL={avatar}
            className="md:w-[60px] md:h-[60px] lg:w-[90px] lg:h-[90px] xl:w-[120px] xl:h-[120px] mx-auto mt-[30px]"
          ></Avatar>
          <h3 className="md:text-xl lg:text-2xl xl:text-3xl text-white mt-4 mx-auto">{nick}</h3>
          <p className="mx-auto  text-sm mt-2">
            <span className="text-slate-300">作品 {totalWork}</span>
            <span className="text-gray-300 ml-4">
              自 {dayjs(createdAt ?? "").format("YYYY-MM-DD")}
            </span>
          </p>
        </div>
      </div>
    </>
  )
}

export default forwardRef(WorkCover)
