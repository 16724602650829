import { ReactNode } from "react"
import "./card.less"
import { isMobileOnly } from "react-device-detect"
interface Props {
  title?: string
  children: ReactNode
  headerSlot?: ReactNode
  className?: string
}
function Card(props: Props) {
  return (
    <div
      className={`${
        isMobileOnly ? "user-center_card_mobile-wrapper" : "user-center_card-wrapper"
      } ${props.className}`}
    >
      {props.title && (
        <div className="card-header text-lg relative">
          {props.title && <span>{props.title}</span>}
          {props.headerSlot}
        </div>
      )}
      {props.children}
    </div>
  )
}

export default Card
