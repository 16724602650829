import { useRef, useState } from "react"
import { ReactComponent as ImageIcon } from "@/assets/image.svg"
import { ReactComponent as HeartWhiteIcon } from "@/assets/heart-white-2.svg"
import { ReactComponent as HeartRedIcon } from "@/assets/heart-red-2.svg"
import { ReactComponent as RightIconInActive } from "../assets/right-black-inactive.svg"
import { ReactComponent as RepaintIcon } from "../assets/repaint.svg"
import { ReactComponent as ControlNetIcon } from "@/assets/control-net.svg"
import * as store from "@/store"
import { ReactComponent as DangerIcon } from "../assets/danger.svg"

import { handleDownload } from "@/utils"
import { V1Task, V1Work } from "@/generated/api/data-contracts"
import Report from "@/common/Report"
import { Popover } from "@arco-design/web-react"
import { ReactComponent as DownloadIcon } from "@/assets/download.svg"
import { ReactComponent as OptionsIcon } from "@/assets/options.svg"
import { ReactComponent as MagicIcon } from "../assets/magic2.svg"
import { ReactComponent as PicturesSeakIcon } from "@/assets/picture-speak.svg"
import { ImageSpeakInOtherPage } from "@/Studio/components/ImageSpeak"
import ImageRepainting from "@/Studio/components/ImageRepainting"
import { ControlNetInOtherPage } from "@/Studio/components/ControlNet/ControlNet"
import { UploadReferenceImageInOtherPage } from "@/Studio/components/UploadreferenceImage"
import GenerateAgainTask from "@/Studio/components/GenerateAgainTask"
import CheckAuth from "@/components/common/CheckAuth"
import { toJS } from "mobx"

export default function PictureOptiion({
  current,
  task,
  currLike,
  handleLike,
  handleOptionEnterChange,
  showReCreate = true,
  iconStyleCls,
}: //   handleOptionClose,
{
  current: V1Work
  task: V1Task
  currLike?: boolean
  handleLike?: () => void
  handleOptionEnterChange?: (_: boolean) => void
  showReCreate?: boolean
  iconStyleCls?: string
  //   handleOptionClose?: (option: boolean) => void
}) {
  const [selectedOption, setSelectedOption] = useState("")
  const [selectedIcon, setSelectedIcon] = useState("")
  const childPopverRef = useRef(null)
  const userInfo = store.user.get()
  return (
    <Popover
      trigger="click"
      className="home-modal-popover  home-modal-position childPopoverContainer"
      position="rt"
      ref={childPopverRef}
      style={{ zIndex: 1002 }}
      getPopupContainer={() => {
        return document.getElementById("PictureOptionCon") || document.body
      }}
      onVisibleChange={(visible: boolean) => {
        if (!visible) {
          setSelectedIcon("")
          handleOptionEnterChange && handleOptionEnterChange(false)
        }
      }}
      content={
        <div
          className="flex flex-col p-2 rounded bg-[#212134] text-white  relative"
          onClick={() => handleOptionEnterChange && handleOptionEnterChange(true)}
        >
          <Popover
            trigger="click"
            className="home-modal-popover home-modal-second "
            position="rt"
            style={{ zIndex: 1003 }}
            getPopupContainer={() => {
              return document.body
            }}
            content={
              <div className="w-[130px] flex flex-col p-2 rounded bg-[#212134] text-white">
                <ControlNetInOtherPage
                  sourceImage={current.image || ""}
                  buttonContent={
                    <>
                      <div className=" cursor-pointer flex py-0.5 items-center w-full  justify-between pl-1.5 ml-auto hover:bg-[#FFFFFF1A] rounded-sm">
                        ControlNet
                        <ControlNetIcon />
                      </div>
                    </>
                  }
                />
                <ImageRepainting
                  work={current}
                  buttonContent={
                    <div className=" cursor-pointer flex py-0.5 items-center w-full  justify-between pl-1.5 ml-auto hover:bg-[#FFFFFF1A] rounded-sm">
                      图像重绘
                      <RepaintIcon />
                    </div>
                  }
                />
                {showReCreate && (
                  <GenerateAgainTask
                    task={task}
                    buttonContent={
                      <div className="cursor-pointer flex w-full py-0.5 justify-between items-center pl-1.5 ml-auto hover:bg-[#FFFFFF1A] transition rounded-sm">
                        再次生成 <MagicIcon />
                      </div>
                    }
                  />
                )}
                {/* <ImageSpeakInOtherPage
                  sourceUrl={current.image || ""}
                  buttonContent={
                    <>
                      <div className="cursor-pointer flex w-full py-0.5 justify-between items-center pl-1.5 ml-auto hover:bg-[#FFFFFF1A] transition rounded-sm">
                        图像会说话
                        <PicturesSeakIcon />
                      </div>
                    </>
                  }
                /> */}
                <UploadReferenceImageInOtherPage
                  sourceImage={current.image || ""}
                  buttonContent={
                    <>
                      <div className=" cursor-pointer flex w-full py-0.5 justify-between items-center pl-1.5 ml-auto hover:bg-[#FFFFFF1A] transition rounded-sm">
                        作为参考图
                        <ImageIcon className="w-[27px]" />
                      </div>
                    </>
                  }
                />
              </div>
            }
          >
            <button
              type="button"
              onClick={() => setSelectedOption("reuse")}
              className={`flex w-full mr-4 justify-between pl-1.5 items-center ml-auto hover:bg-[#FFFFFF1A] transition rounded-sm
                            ${selectedOption == "reuse" ? "bg-[#FFFFFF1A]" : ""}
                          `}
            >
              再次使用
              <RightIconInActive width={30} height={30} />
            </button>
          </Popover>

          <button
            type="button"
            onClick={() => {
              handleDownload(current.image!)
              setSelectedOption("save")
            }}
            className={`flex w-full mr-4 justify-between p-1.5 ml-auto hover:bg-[#FFFFFF1A] transition rounded-sm
                        ${selectedOption == "save" ? "bg-[#FFFFFF1A]" : ""}
                        `}
          >
            保存图像
            <DownloadIcon />
          </button>
          {userInfo?.isNoWatermarkUser && (
            <>
              <button
                type="button"
                onClick={() => {
                  handleDownload(current.image!, false)
                  setSelectedOption("saveHD")
                }}
                className={`flex w-full mr-4 justify-between p-1.5 ml-auto hover:bg-[#FFFFFF1A] transition rounded-sm
                        ${selectedOption == "saveHD" ? "bg-[#FFFFFF1A]" : ""}
                        `}
              >
                保存高清图像
                <DownloadIcon />
              </button>
            </>
          )}
          <hr className="border-[#FFFFFF1A] my-2" />

          <Report
            id={current.id}
            element={
              <button
                type="button"
                onClick={() => {
                  setSelectedOption("report")
                }}
                className={`flex w-full mr-4 justify-between p-1.5 ml-auto hover:bg-[#E93247] transition rounded-sm 
                            ${selectedOption == "report" ? "bg-[#E93247]" : ""}
                            `}
              >
                举报内容 <DangerIcon />
              </button>
            }
          />
        </div>
      }
    >
      <button
        type="button"
        onClick={() => setSelectedIcon("option")}
        className={`hover:bg-[#FFFFFF1A] transition rounded-sm
                    ${selectedIcon == "option" ? "bg-[#FFFFFF1A]" : ""}
                    `}
      >
        <OptionsIcon className={iconStyleCls} />
      </button>
    </Popover>
  )
}
