import React, { useState, useEffect, useRef } from "react"
import { useDarkMode } from "@/utils"
import Navbar from "@/Navbar"
import { isMobileOnly } from "react-device-detect"
import TemplateCenterMobile from "./TemplateCenterMobile"
import TemplateScroll from "@/common/TemplateScroll"
import MobileHeaderMenu from "@/components/layout/temp/MobileHeaderMenu"
import TemplateDetailModal from "@/common/TemplateDetailModal"
import { V1TemplateData } from "@/generated/api/data-contracts"
import templateStore from "@/store/templateStore"
import { observer } from "mobx-react-lite"
import TemplateItem from "@/common/TemplateItem"
import { TEMPLATESTYPE, getRecordNumberByDomain } from "@/constants"
import { ReactComponent as SearchIcon } from "@/assets/search.svg"

interface TemplateCategory {
  categoryName: string
  params: Record<string, boolean | string>
  type: TEMPLATESTYPE
}
interface SearchTemplateCategory {
  categoryName: string
  type: string
}

const categories: TemplateCategory[] = [
  {
    categoryName: "编辑推荐",
    params: { isRecommand: true },
    type: TEMPLATESTYPE.Recommand,
  },
  {
    categoryName: "热门推荐",
    params: {},
    type: TEMPLATESTYPE.Hot,
  },
  {
    categoryName: "人物",
    params: { category: "character" },
    type: TEMPLATESTYPE.Character,
  },
  {
    categoryName: "其他分类",
    params: { category: "other" },
    type: TEMPLATESTYPE.Other,
  },
]

const SearchCategories: SearchTemplateCategory[] = [
  {
    categoryName: "搜索结果",
    type: "searchResult",
  },
]
interface TemplateCategoryItemProps {
  item: string
  isActive: boolean
  onClick: () => void
}

const TemplateCategoryItem: React.FC<TemplateCategoryItemProps> = ({ item, isActive, onClick }) => (
  <section
    onClick={onClick}
    className="md:pt-0 flex flex-col items-center justify-center min-w-max"
  >
    <div
      className={`min-w-max text-base leading-7 ${
        isActive ? "text-[#ffffff]" : "text-[#ffffff]/50"
      }`}
    >
      {item}
    </div>
    <div className={`mt-1 w-8 h-[3px] ${isActive ? "bg-[#4C1FFF]" : "transparent"}`}></div>
  </section>
)

interface MobileTemplateCenterProps {
  onActiveChange: (value: TEMPLATESTYPE) => void
  activeType: TEMPLATESTYPE
  templatesCenter: Record<TEMPLATESTYPE, V1TemplateData[]>
}

const MobileTemplateCenter: React.FC<MobileTemplateCenterProps> = ({
  onActiveChange,
  activeType,
  templatesCenter,
}) => {
  return (
    <>
      <div className="fixed z-10 w-full">
        <MobileHeaderMenu
          extra={
            <div className="flex gap-[20px]">
              {categories.map((item) => (
                <TemplateCategoryItem
                  key={item.type}
                  item={item.categoryName}
                  isActive={activeType === item.type}
                  onClick={() => {
                    onActiveChange(item.type)
                  }}
                />
              ))}
            </div>
          }
        />
      </div>
      <section className="pb-[20px] pt-[85px] px-[20px]">
        <TemplateCenterMobile templatesCenter={templatesCenter} activeType={activeType} />
      </section>
    </>
  )
}

const DesktopTemplateCenter: React.FC<{
  templatesCenter: Record<TEMPLATESTYPE, V1TemplateData[]>
  openModal: (i: number) => void
  searchTemplates: V1TemplateData[]
  onSearch: (v: string) => void
  searchDefalutValue: string
}> = ({ templatesCenter, openModal, onSearch, searchTemplates, searchDefalutValue }) => {
  const [searchValue, setSearchValue] = useState<string>(searchDefalutValue)
  const [isSearch, setIsSearch] = useState<boolean>(false)

  useEffect(() => {
    if (searchTemplates?.length) {
      setIsSearch(true)
    }
  }, [])

  const recommandDom = (data: TemplateCategory) => {
    return (
      <div className="pt-4 pb-4">
        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4 ">
          {templatesCenter[data.type]?.map((item: V1TemplateData, i: number) => (
            <TemplateItem
              key={item.id}
              template={item}
              className="flex !w-auto"
              callback={() => openModal(i)}
            />
          ))}
        </div>
      </div>
    )
  }

  const searchResultDom = () => {
    return (
      <div className="pt-4 pb-4">
        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4 ">
          {searchTemplates?.map((item: V1TemplateData, i: number) => (
            <TemplateItem
              key={item.id}
              template={item}
              className="flex !w-auto"
              callback={() => openModal(i)}
            />
          ))}
        </div>
      </div>
    )
  }

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value

    setSearchValue(e.target.value)
    if (!value) {
      setIsSearch(false)
    }
  }

  return (
    <div className="md:mt-[105px] md:px-[50px] px-[10px] max-w-[1920px]">
      <div className="flex w-full justify-center">
        <div className="hidden md:flex max-w-[42.5rem] w-[50%] mx-[20px] px-5 py-1 items-center  rounded-3xl border border-[#ffffff1a] group shadow-[0_0_10px_rgba(0,0,0,0.08)] placeholder:text-[#ffffff33]">
          <SearchIcon
            className=" fill-[#fff]"
            width={18}
            height={18}
            onClick={() => onSearch(searchValue)}
          ></SearchIcon>
          <input
            className="flex-1 ml-3 py-1.5 text-sm outline-none bg-transparent border-none placeholder:text-[#6F7176] peer"
            placeholder="输入名称搜索模板"
            type="text"
            value={searchValue}
            onChange={handleSearchInputChange}
            onKeyDown={(e) => {
              if (e.key.toLowerCase() === "enter") {
                onSearch(searchValue)
              }
            }}
          />
        </div>
      </div>

      {isSearch
        ? SearchCategories.map((item) => (
            <div key={item?.type}>
              <div className="font-open-sans text-[32px] font-normal">{item?.categoryName}</div>
              {searchResultDom()}
            </div>
          ))
        : categories.map((item) => (
            <div key={item?.type}>
              <div className="font-open-sans text-[32px] font-normal">{item?.categoryName}</div>
              {item.type === "recommand" ? (
                recommandDom(item)
              ) : (
                <TemplateScroll className="md:mt-[20px]" templates={templatesCenter[item.type]} />
              )}
            </div>
          ))}
    </div>
  )
}

export const TemplateCenter: React.FC = observer(() => {
  useDarkMode()

  const [activeType, setActiveType] = useState<TEMPLATESTYPE>(TEMPLATESTYPE.Recommand)
  const [taskIndex, setTaskIndex] = useState<number | null>(null)
  const [searchDefalutValue, setSearchDefalutValue] = useState<string>("")

  const handleActiveChange = (value: TEMPLATESTYPE) => {
    setActiveType(value)
  }
  const { templatesCenter, templateCategories, searchTemplates } = templateStore.store.get()

  useEffect(() => {
    categories.map((item) => {
      templateStore.fetchTemplatesCenter({ params: item.params, type: item.type })
    })

    if (!templateCategories.length) {
      templateStore.fetchTemplateCategories()
    }
  }, [])

  const openModal = (i: number) => {
    setTaskIndex(i)
  }

  const handleSearch = (v: string) => {
    setSearchDefalutValue(v)
    templateStore.fetchSearchTemplates({ name: v })
  }

  const templateModalVisiable = taskIndex !== null

  const TemplateCenterRender: React.FC = () => {
    if (isMobileOnly) {
      return (
        <MobileTemplateCenter
          onActiveChange={handleActiveChange}
          activeType={activeType}
          templatesCenter={templatesCenter}
        />
      )
    }
    return (
      <DesktopTemplateCenter
        templatesCenter={templatesCenter}
        searchTemplates={searchTemplates}
        openModal={openModal}
        searchDefalutValue={searchDefalutValue}
        onSearch={handleSearch}
      />
    )
  }

  const closeModal = () => {
    setTaskIndex(null)
  }

  return (
    <>
      <Navbar />
      <TemplateCenterRender />
      {templateModalVisiable && (
        <TemplateDetailModal
          defaultTemplateIndex={taskIndex}
          onClose={closeModal}
          templatesCenter={
            searchTemplates.length ? searchTemplates : templatesCenter[TEMPLATESTYPE.Recommand]
          }
        />
      )}
      <div className="flex-col md:flex-row mt-auto w-full flex justify-center text-xs py-3 items-center cursor-pointer">
        <a href="https://beian.miit.gov.cn/#/Integrated/index">{getRecordNumberByDomain()}</a>
        <a
          className="mt-1 md:mt-0 flex justify-center items-center"
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502020083"
        >
          <img className="pl-2" src="/assets/beian.png" />
          <p className="pl-2">沪公网安备 31011502020083号</p>
        </a>
      </div>
    </>
  )
})
