import { Tooltip, TooltipProps } from "@arco-design/web-react"
import specialistwechatURL from "@/assets/specialistwechat.png"
import { ReactComponent as WechatIcon } from "@/assets/wechat.svg"
import { ReactNode } from "react"

export function SpecialistWechatButton(props: SpecialistWechatButtonProps) {
  return null
  const {
    text = (
      <>
        <WechatIcon className=" fill-[#6F7176] inline-block mr-3" />
        如有疑问，请点击此处添加客服微信
      </>
    ),
    tooltipProps,
  } = props
  return (
    <Tooltip
      {...{
        trigger: "click",
        position: "bottom",
        color: "rgba(77, 77, 77)",
        ...tooltipProps,
      }}
      content={
        <section className="flex flex-col items-center px-[14px] pt-[10px] pb-[4px]">
          <img src={specialistwechatURL} alt="客服微信二维码" className="w-[120px]" />
          <p className="mt-[10px] text-[14px] text-center">打开微信扫描二维码</p>
        </section>
      }
    >
      <button className="p-[8px] text-[14px] leading-[20px] text-[#6F7176]  rounded-[2px]">
        {text}
      </button>
    </Tooltip>
  )
}

export interface SpecialistWechatButtonProps {
  text?: string | ReactNode
  tooltipProps?: TooltipProps
}
