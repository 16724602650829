const isPhone = (phone: string): boolean => {
  return /^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(phone)
}

const isNumber = (v: string): boolean => {
  return /^\d+$/.test(v)
}

const isValidJSON = (str: string) => {
  try {
    JSON.parse(str)
    return true
  } catch (e) {
    return false
  }
}

export { isPhone, isNumber, isValidJSON }
