// works page

import { V1Task, V1Work } from "@/generated/api/data-contracts"
import { observable, runInAction } from "mobx"
import _ from "lodash"

const store = observable.box<{
  taskList: V1Task[]
  selectList: string[]
  likeList: V1Work[]
}>({
  taskList: [],
  selectList: [],
  likeList: [],
})

const worksPagesStore = {
  store,
  // 初始化store
  init: () => {
    // 默认参数放在这里
    store.set({
      taskList: [],
      selectList: [],
      likeList: [],
    })
  },
  changeLikeList: (fn: (_: V1Work[]) => V1Work[]) => {
    const { likeList, ...other } = store.get()
    store.set({
      ...other,
      likeList: fn(likeList),
    })
  },
  pushTaskItems: (fn: (_: V1Task[]) => V1Task[]) => {
    const { taskList, ...other } = store.get()
    store.set({
      ...other,
      taskList: fn(taskList),
    })
  },
  deleteWork: (ids: string[]) => {
    const { taskList, ...other } = store.get()
    const newTasks = taskList.map((task) => {
      const newWorks = [...(task?.works || [])]
      task.works?.map((work) => {
        if (ids.includes(work.id || "")) {
          const index = newWorks.findIndex(({ id }) => id === work.id)
          newWorks?.splice(index, 1)
        }
      })
      return {
        ...task,
        works: newWorks,
      }
    })
    store.set({
      ...other,
      taskList: newTasks.filter(({ works }) => works?.length),
    })
  },

  changeTask: (dataOrFn: ((_: V1Task[]) => V1Task[]) | V1Task[] = []) => {
    const { taskList, ...other } = store.get()
    let data: V1Task[] = []
    if (_.isFunction(dataOrFn)) {
      data = dataOrFn?.(taskList)
    } else {
      data = dataOrFn
    }
    store.set({
      ...other,
      taskList: data,
    })
  },
  changeTaskItemWork: (
    taskIndex: number,
    workIndex: number,
    dataOrFn: ((_: V1Work) => Partial<V1Work>) | Partial<V1Work> = {}
  ) => {
    const { taskList, ...other } = store.get()
    const item = _.get(taskList, `[${taskIndex}].works.[${workIndex}]`, {})
    let data: Partial<V1Work> = {}
    if (_.isFunction(dataOrFn)) {
      data = dataOrFn?.(item)
    } else {
      data = dataOrFn
    }
    _.set(taskList, `[${taskIndex}].works.[${workIndex}]`, data)
    store.set({
      ...other,
      taskList,
    })
  },
  togglesSelect(workId: string) {
    const { selectList, ...other } = store.get()
    const index = selectList.findIndex((i) => i === workId)

    if (index >= 0) {
      selectList.splice(index, 1)
    } else {
      selectList.push(workId)
    }

    store.set({
      ...other,
      selectList,
    })
  },
  selectAll: () => {
    const { selectList, ...other } = store.get()
    selectList
    store.set({
      ...other,
      selectList: [],
    })
  },
  changeSelectList: (selectList: string[]) => {
    const { taskList, likeList } = store.get()
    runInAction(() => {
      store.set({
        likeList,
        taskList,
        selectList,
      })
    })
  },
}
export default worksPagesStore
