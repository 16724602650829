import defaultAvatarURL from "@/assets/login_default_avatar.svg"

export function Avatar(props: AvatarProps) {
  const { URL, className } = props
  return (
    <img
      className={`w-10 h-10 rounded-full ${className || ""} object-cover`}
      src={URL || defaultAvatarURL}
      alt="头像"
    />
  )
}

export interface AvatarProps {
  URL?: string
  className?: string
}
