import { useRef } from "react"
import { Outlet } from "react-router-dom"
import WorkCover from "@/components/bussiness/work/WorkCover"
import * as store from "@/store"
import { useDarkMode } from "@/utils"
import "./work.less"
import "@/styles/reset-arco.less"
import LayoutGeneral from "@/components/layout/general"
import { observer } from "mobx-react-lite"
import { MOBILE_CONTAINER_CLS } from "@/store/menuStore"
import { getRecordNumberByDomain } from "@/constants"

const WorkLauyout = observer(() => {
  useDarkMode()
  const { id: currentUserId } = store.user.get()

  const coverRef = useRef({ refreshCover: () => null })

  return (
    <LayoutGeneral hasHeader={true} className={MOBILE_CONTAINER_CLS}>
      {/* 头像壁纸 */}
      {/* <WorkCover userId={currentUserId} isVisitor={false} ref={coverRef} /> */}
      <div
        // className="mx-auto md:px-[30px] max-w-[1920px]"
        style={{
          minHeight: "calc(100vh - 68px)",
        }}
      >
        <Outlet />
      </div>
      <div className="w-full flex justify-center text-xs mt-10 mb-2 items-center cursor-pointer">
        <a href="https://beian.miit.gov.cn/#/Integrated/index">{getRecordNumberByDomain()}</a>
        <a
          className="flex  justify-center items-center"
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502020083"
        >
          <img className="pl-2 " src="/assets/beian.png" />
          <p className="pl-2 ">沪公网安备 31011502020083号</p>
        </a>
      </div>
    </LayoutGeneral>
  )
})

export default WorkLauyout
