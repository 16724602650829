import moreIcon from "@/assets/studio/mobile/more_m.svg"
import { useRef, useState } from "react"
import ActionIcon from "./components/ActionIcon"
import MobileBottomModal from "../components/MobileBottomModal"
import { ImageSpeakInStudioPage } from "../components/ImageSpeak"
import * as store from "@/store"
import { V1TaskPriority } from "@/generated/api/data-contracts"
import studioPagesStore from "@/store/studioPagesStore"
import { MoreOptions } from "./MoreOptions"
import MobileUnwantedModal from "./MobileUnwantedModal"

interface MoreButtonProps {
  onUploadReferenceImage?: (isEditor?: boolean) => void
  onControlNet?: () => void
  onPromptGenerated?: (prompt: string) => void
  negativePrompt?: string
  onNegativePromptChange?: (text: string) => void
  templateId?: string
}
const MoreButton = ({
  onUploadReferenceImage,
  onControlNet,
  onPromptGenerated,
  negativePrompt,
  onNegativePromptChange,
  templateId,
}: MoreButtonProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [unwantedModalVisible, setUnwantedModalVisible] = useState(false)
  const { id: userId } = store.user.get()
  const { isHigh } = studioPagesStore.store.get()
  const imageSpeakModalRef = useRef<any>(null)
  const referencelIamgeData = studioPagesStore.store.get().referencelIamgeData
  const handleOptionClick = (id: string) => {
    switch (id) {
      case "uploadReferenceImage":
        referencelIamgeData ? onUploadReferenceImage?.(true) : onUploadReferenceImage?.()
        break
      case "imageTalk":
        imageSpeakModalRef.current?.open()
        break
      case "controlNet":
        onControlNet?.()
        break
      case "unwantedElement":
        setUnwantedModalVisible(true)
        break
    }
    setIsModalVisible(false)
  }

  return (
    <div>
      {/* More Options Modal */}
      {isModalVisible && (
        <MobileBottomModal
          titleHidden
          onClose={() => {
            setIsModalVisible(false)
          }}
          modalStyle={{
            paddingBottom: "20px",
          }}
          contentStyle={{
            marginTop: "0px",
          }}
        >
          <MoreOptions onOptionClick={handleOptionClick} />
        </MobileBottomModal>
      )}

      {/* More Button */}
      <ActionIcon
        src={moreIcon}
        onClick={() => {
          setIsModalVisible(true)
        }}
      />

      {/* Image Speak Modal */}
      <ImageSpeakInStudioPage
        ref={imageSpeakModalRef}
        priority={
          !userId
            ? V1TaskPriority.TaskPriorityLow
            : isHigh
            ? V1TaskPriority.TaskPriorityHigh
            : V1TaskPriority.TaskPriorityLow
        }
        addImageSpeakPrompt={onPromptGenerated}
      />

      {/* Unwanted Element Modal */}
      {unwantedModalVisible && (
        <MobileUnwantedModal
          onClose={() => {
            setUnwantedModalVisible(false)
          }}
          negativePrompt={negativePrompt}
          onChange={onNegativePromptChange}
          onConfirm={() => {
            setUnwantedModalVisible(false)
          }}
          templateId={templateId}
        />
      )}
    </div>
  )
}

export default MoreButton
