import studioPagesStore from "@/store/studioPagesStore"
import RuningTask from "@/Studio/RuningTask"
import { RegenOptions } from "@/pages/Works/Card"
import { ReactComponent as PendingIcon } from "@/assets/pending.svg"
import { isMobileOnly } from "react-device-detect"
import { useState } from "react"
import { setCookieForMobile } from "@/utils"

const EmptyIcon = () => {
  const [clickCount, setClickCount] = useState(0)
  const [timer, setTimer] = useState<any>(null)

  // 3 clicks to set cookie for mobile
  const handleClick = () => {
    setClickCount((count) => count + 1)
    if (clickCount === 2) {
      setClickCount(0)
      clearTimeout(timer)
      const isDevEnv = process.env.NODE_ENV === "development"
      if (isDevEnv) {
        setCookieForMobile()
      }
    } else {
      const timer = setTimeout(() => {
        setClickCount(0)
      }, 500)
      setTimer(timer)
    }
  }

  return (
    <div className="pt-52 flex-1 md:flex-initial py-0 my-0 md:py-20 flex flex-col items-center">
      <PendingIcon onClick={handleClick} />
      <div className="my-4 text-sm text-[#6F7176]">快来施法吧～</div>
    </div>
  )
}
interface GenerationHistoryProps {
  onRegen?: (_: RegenOptions) => void
  canCreate: boolean
  addTagsPrompt: (_: string) => void
}
const GenerationHistory = ({ onRegen, canCreate, addTagsPrompt }: GenerationHistoryProps) => {
  const { runingTasks, taskHistory } = studioPagesStore.store.get()
  let allTasks = [...runingTasks, ...taskHistory]
  if (isMobileOnly) {
    allTasks = allTasks.reverse()
  }
  return (
    <div className="w-full md:w-auto flex flex-col gap-y-[10px]">
      {/* Empty Icon */}
      {!allTasks.length && <EmptyIcon />}

      {/* Tasks */}
      {allTasks.map((task) => (
        <RuningTask
          key={task.id}
          addTagsPrompt={addTagsPrompt}
          onRegen={onRegen}
          task={task}
          canCreate={canCreate}
        />
      ))}
    </div>
  )
}

export default GenerationHistory
