import CheckAuth from "@/components/common/CheckAuth"
import { V1ProductInfo } from "@/generated/api/data-contracts"
import { formatPrice } from "@/utils"
import shopImg1 from "@/assets/shopModal/shop1.png"
import shopImg2 from "@/assets/shopModal/shop2.png"
import shopImg3 from "@/assets/shopModal/shop3.png"
import shopImg4 from "@/assets/shopModal/shop4.png"
import shopImg5 from "@/assets/shopModal/shop5.png"
import { isMobileOnly } from "react-device-detect"

import _ from "lodash"

interface ShopProductData {
  [subscribeType: number]: V1ProductInfo
}

const newPreferenceObj = {
  index: 6,
  subscribeType: 54,
  title: "灵石 x60",
  imgUrl: shopImg1,
  discount: "",
  discountNum: "",
}

const SHOPLIST = [
  {
    subscribeType: 50,
    title: "灵石 x80",
    imgUrl: shopImg2,
    discount: "",
    discountNum: "",
  },
  {
    subscribeType: 51,
    title: "灵石 x200",
    imgUrl: shopImg3,
    discount: "96折",
    discountNum: 12.5,
  },
  {
    subscribeType: 52,
    title: "灵石 x500",
    imgUrl: shopImg4,
    discount: "9折",
    discountNum: 31,
  },
  {
    subscribeType: 53,
    title: "灵石 x1200",
    imgUrl: shopImg5,
    discount: "8折",
    discountNum: 75,
  },
]

const ShopProductList = ({
  userId,
  shopProductData,
  openPayModal,
  cb,
  hasSuccessPaidOrder,
}: {
  userId?: string
  shopProductData: ShopProductData
  openPayModal: (v: boolean) => void
  cb: (v: V1ProductInfo) => void
  hasSuccessPaidOrder?: boolean
}) => {
  let isShowPreference = false
  // 登录后根据接口返回是否首次消费判断 未登录默认未消费

  if (userId) {
    isShowPreference = typeof hasSuccessPaidOrder === "undefined" ? false : !hasSuccessPaidOrder
  } else {
    isShowPreference = true
  }

  let NEWSHOPLIST = SHOPLIST
  if (isShowPreference) {
    NEWSHOPLIST = [newPreferenceObj, ...SHOPLIST]
  }
  return (
    <div className={`grid ${isMobileOnly ? "grid-cols-2" : "grid-cols-5"} gap-4 `}>
      {NEWSHOPLIST.map((item) => {
        const { price, subscribeCategory } =
          (shopProductData[item.subscribeType] as V1ProductInfo) || {}

        const isPreference = isShowPreference && subscribeCategory === "Special"
        return (
          <div key={item.subscribeType} className="flex flex-col">
            <div className="relative">
              <img className="rounded-t w-full h-32" src={item?.imgUrl} alt="商店图片" />
              {!isPreference && (
                <div
                  style={{
                    clipPath: "polygon(28% 0px, 69% 0px, 98% 100%, 0% 97%)",
                  }}
                  className="clip-path-trapezoid absolute top-[9px] left-[-23px] w-25 bg-[#E93247] transform -rotate-45 py-1.5 px-6"
                >
                  <p className="text-xs text-white mx-auto">新人特惠</p>
                </div>
              )}
            </div>
            <div className="bg-[#161623] px-[10px] py-[10px] rounded-b">
              <div className="text-base font-normal">{item?.title}</div>
              <div className="flex justify-between items-center">
                <div className="flex items-end">
                  <span className="text-[#E93247]">
                    ¥
                    <span className="text-xl font-semibold">
                      {price ? formatPrice(price / 100) : ""}
                    </span>
                  </span>
                  &nbsp;&nbsp;
                  {/* {item?.discount ? (
                    <span className="opacity-20 flex items-center line-through">
                      ¥<span className="text-xs font-semibold">{item?.discountNum}</span>
                    </span>
                  ) : (
                    ""
                  )} */}
                </div>

                {item?.discount ? (
                  <div className="flex text-xs text-[#E93247] px-1 rounded-sm border border-[#E93247] max-h-5">
                    {item?.discount}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <CheckAuth
                needLogin={true}
                onClick={_.debounce(() => {
                  openPayModal(true)
                  cb && cb(shopProductData[item.subscribeType])
                }, 300)}
              >
                <div className="bg-[#EB7219] flex justify-center items-center py-1 rounded mt-[10px] cursor-pointer">
                  购买
                </div>
              </CheckAuth>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default ShopProductList
