import { Button, Modal } from "@arco-design/web-react"
import { ReactComponent as DeleteIcon } from "@/assets/delete.svg"

interface DeleteConfirmProps {
  visible: boolean
  onCancel: () => void
  onOk: () => void
}
const DeleteConfirm: React.FC<DeleteConfirmProps> = ({ visible, onCancel, onOk }) => {
  return (
    <Modal
      visible={visible}
      unmountOnExit
      modalRender={(modal) => {
        return (
          <div className="fixed top-0 bottom-0 left-0 right-0">
            <div className="bg-black text-white p-[16px] rounded absolute left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%] w-[360px]">
              <DeleteIcon className="inline-block w-[38px] h-[38px] mb-[10px]" />
              <h3 className="text-base leading-[20px] my-[8px] ">作品将被删除~</h3>
              <p className="text-sm leading-[20px]">此操作不能撤销</p>
              <div className="mt-[28px] flex justify-between">
                <Button type="outline" className={"flex-1 mr-[8px]"} onClick={onCancel}>
                  关闭
                </Button>
                <Button type="primary" className={"flex-1 ml-[8px]"} onClick={onOk}>
                  删除
                </Button>
              </div>
            </div>
          </div>
        )
      }}
    >
      <p>确认删除该作品？</p>
    </Modal>
  )
}

export default DeleteConfirm
