import { ReactNode, useEffect, useMemo, useRef, useState } from "react"
import { ReactComponent as LoveIcon } from "@/assets/works/love.svg"
import { ReactComponent as PublicIcon } from "@/assets/works/public.svg"
import { ReactComponent as PrivateIcon } from "@/assets/works/private.svg"
import { ReactComponent as EditorIcon } from "@/assets/works/editor.svg"
import { ReactComponent as DrapIcon } from "@/assets/works/drap.svg"
// import { ReactComponent as DrapIcon } from "@/assets/works/drap.svg"
import "./index.less"
import { toastError, toastSuccess } from "@/common/Toast"
import { V1Collection, V1CollectionWork } from "@/generated/api/data-contracts"
import { observer } from "mobx-react-lite"
import { createTemplate, getTemplateCategories } from "@/services/editor"
import { isMobileOnly } from "react-device-detect"
import { useTranslation } from "react-i18next"
import { formatLoveNum, formatSpiritStonesNum, getImageURL } from "@/utils"
import { useNavigate, useParams } from "react-router-dom"
import { PATH_WORKS_MY_ALBUMS, getUserPath } from "@/router/constants"
import { toJS } from "mobx"
import { useVisitStatus } from "@/pages/Works/VisitStatusContext"
import { albumIsMiss, imgIsMiss, defaultCoverImg } from "@/constants"
export interface categories {
  label: string
  value: string
}
/**
 *  公开不公开切换
 *  编辑相册弹窗
 *
 */
export default observer(function ({
  data,
  handleUnlike,
  handleLike,
}: // isMine,
{
  data: V1Collection
  handleUnlike?: (uids: Array<string>) => void
  handleLike?: (uid: string) => void
  // isMine: boolean
}) {
  const {
    coverImage,
    works,
    isPrivate = false,
    isDeleted,
    likeCount,
    uid,
    title,
    like,
    isMine,
    user,
  } = data
  const domPrivateTextHref = useRef(null)
  const navigate = useNavigate()
  const { isVisted } = useVisitStatus()
  const { userId } = useParams()

  const linToDetail = () => {
    let url = isVisted
      ? `${getUserPath(userId || "")}/${PATH_WORKS_MY_ALBUMS}/${uid}`
      : isMine
      ? `/${PATH_WORKS_MY_ALBUMS}/${uid}`
      : `${getUserPath(user?.id || "")}/${PATH_WORKS_MY_ALBUMS}/${uid}`
    // 没有被删除才可以跳转到详情
    isDeleted
      ? toastError("该相册已被删除")
      : works?.length
      ? navigate(url)
      : toastError("该相册暂无作品")
  }
  const PrivateTextMap = new Map([
    [true, "不公开"],
    [false, "公开"],
  ])
  const PrivateIconMap = new Map([
    [true, <PrivateIcon />],
    [false, <PublicIcon />],
  ])
  const coverImg = useMemo(() => {
    // 被删除 和 不是自己的私有相册
    if (isDeleted || (isPrivate && !isMine)) {
      return "albumIsMiss"
    } else if (coverImage) {
      return coverImage
    } else if (works?.[0]) {
      let imgList = works?.filter((item) => item.image).slice(0, 1) || []
      return imgList[0]?.image ? imgList[0]?.image : ""
    } else {
      return ""
    }
  }, [works])
  const previewImage = useMemo(() => {
    // 展示公开的前三张图片
    let arr: any = []
    arr = works?.filter((item) => item?.image).slice(0, 3) || []
    while (arr.length < 3) {
      arr.push({ image: "" })
    }
    return arr
  }, [works])
  const albumTitle = useMemo(() => {
    // 收藏的
    if (!isMine && isPrivate) {
      return "该相册已被设为私密相册"
    } else if (!isMine && isDeleted) {
      return "该相册已被删除"
    } else {
      return title
    }
  }, [title, isMine, isPrivate, isDeleted])
  return (
    <div className={"rounded-[6px] p-[5px]  relative  bg-[#0C0C1A] group-hover:bg-[#2E2E4A]"}>
      <>
        {/* 遮罩层 */}
        <div
          className="absolute inset-0 bg-[#2E2E4A] bg-opacity-30 z-10  rounded-[6px] cursor-pointer hidden group-hover:block"
          onClick={() => linToDetail()}
        ></div>
        {/*  编辑和拖拽按钮 */}
        <div className="w-full overflow-hidden rounded-t-[5px]">
          {!isMine && (
            <div
              className="absolute left-[10px] top-[10px] z-[11] flex items-center cursor-pointer"
              onClick={() => {
                navigate(getUserPath(user?.id || ""))
              }}
            >
              <img src={user?.avatar} className="rounded-[50%] w-[24px]"></img>
              <span className="text-[12px] ml-[8px]">{user?.nick}</span>
            </div>
          )}

          {/* 封面图 */}
          {coverImg ? (
            <img
              className="w-full aspect-[1/1] rounded-t-[5px] object-cover group-hover:scale-[1.2] transition-transform transform-gpuansform duration-700 z-9 "
              src={coverImg === "albumIsMiss" ? albumIsMiss : getImageURL(coverImg)}
            ></img>
          ) : (
            <div className="w-full aspect-[1/1] rounded-t-[5px] bg-[#1C1C28]"></div>
          )}
        </div>
        {/* 预览三张图 */}
        <div className="w-full flex gap-[3.5%] mt-[3px] aspect-[3/1]">
          {previewImage?.map((work: V1CollectionWork, index: number) => (
            <>
              {work.image ? (
                <img
                  key={index}
                  src={getImageURL(work.image)}
                  className={`w-[31%] aspect-[1/1] object-cover ${
                    index === 0 ? "rounded-bl-[5px]" : index === 1 ? "" : "rounded-br-[5px]"
                  } `}
                ></img>
              ) : (
                <div className="bg-[#1C1C28] w-[31%] aspect-[1/1]"></div>
              )}
            </>
          ))}
        </div>
        {/* 相册名称 */}
        <div className="px-[10px] py-[5px]">
          <div
            className="text-[white] text-[18px] w-full mt-[8px] cursor-pointer h-[27px]"
            onClick={() => linToDetail()}
          >
            {albumTitle}
          </div>
          <div className="flex justify-between mt-[4px] max-h-[28px]">
            <div className="flex items-center flex-1 min-w-0">
              <LoveIcon />
              <div className="text-gray-400 text-[14px]">{formatLoveNum(likeCount || 0)}</div>
            </div>
            {/* 收藏的 */}
            {!isMine && (
              <div
                className={`flex relative w-[68px] items-center justify-center   rounded-[4px] group/collect group-hover:bg-[white] group-hover:bg-opacity-5 z-50 cursor-pointer`}
              >
                <div
                  className={`rounded-[4px] px-[8px] w-full  h-full flex text-[12px] justify-center items-center group-hover/collect:bg-opacity-20  group-hover/collect:bg-[white]`}
                  onClick={() => {
                    like
                      ? handleUnlike && handleUnlike([uid || ""])
                      : handleLike && handleLike(uid || "")
                  }}
                >
                  <div className="hidden group-hover/collect:block">
                    {like ? "取消收藏" : "添加收藏"}
                  </div>
                  <div className="block group-hover/collect:hidden">
                    {like ? "已收藏" : "未收藏"}
                  </div>
                </div>
              </div>
            )}
            {/* 我的相册 */}
            {isMine && (
              <div className="flex items-center" ref={domPrivateTextHref}>
                {PrivateIconMap.get(isPrivate)}
                <span className="text-gray-400 text-[14px]">{PrivateTextMap.get(isPrivate)}</span>
              </div>
            )}
          </div>
        </div>
      </>
    </div>
  )
})
