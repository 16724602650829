import profileStore from "@/store/profileStore"
import { Modal, Spin } from "@arco-design/web-react"
import { observer } from "mobx-react-lite"
import closeIcon from "@/assets/profileEditor/close.svg"
import leftArrowIcon from "@/assets/profileEditor/left-arrow.svg"
import "./index.less"
import Avatar from "./Avatar"
import Name from "./Name"
import Tips from "./Tips"
import { useState } from "react"
import { api } from "@/services/api"
import { toastError, toastSuccess } from "@/common/Toast"
import { useTranslation } from "react-i18next"
import * as store from "@/store"
import Footer from "./Footer"

const Editor = observer(() => {
  const modalVisible = profileStore.profileEditorVisible
  const [name, setName] = useState("")
  const [nameError, setNameError] = useState<string | boolean>(false)
  const [avatarUrl, setAvatarUrl] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()

  const handleClose = () => {
    profileStore.toggleProfileEditorVisible()
  }

  const handlConfirmClick = async () => {
    setIsLoading(true)
    try {
      const resp = await api.updateUserInfo({ nick: name, avatar: avatarUrl })
      if (resp.data.success) {
        profileStore.toggleProfileEditorVisible()
        store.refreshUser()
        toastSuccess("修改成功")
      } else {
        const errorCode = resp.data?.errorCode
        if (errorCode) {
          toastError(t(`errorMessage:${errorCode}` as any))
        } else {
          toastError("修改失败")
        }
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const confirmButtonDisabled = !!nameError || !avatarUrl

  return (
    <Modal
      unmountOnExit
      visible={modalVisible}
      onCancel={handleClose}
      maskStyle={{
        backgroundColor: "rgba(0,0,0,0.8)",
      }}
      footer={null}
      style={{
        backgroundColor: "#212134",
        padding: 0,
      }}
      closeIcon={null}
      className="profile_editor_modal"
    >
      <Spin loading={isLoading} block className="modal_spin">
        <div className="pb-10 min-h-screen md:min-h-fit">
          {/* Header */}
          <div className="flex py-5 pl-[15px] pr-5 md:py-2.5 md:pl-10 md:pr-2.5 ">
            <img
              src={leftArrowIcon}
              onClick={handleClose}
              className="cursor-pointer w-7 h-7 md:hidden"
            />
            <div className="flex-1 py-0 md:py-2.5">
              <div className="text-white text-opacity-80 text-lg md:text-2xl font-sans">
                编辑资料
              </div>
            </div>
            <img
              src={closeIcon}
              onClick={handleClose}
              className="cursor-pointer w-7 h-7 hidden md:block"
            />
          </div>

          <div className="px-5 md:px-10">
            {/* User Avatar */}
            <Avatar onChange={(avatarUrl) => setAvatarUrl(avatarUrl)} />

            {/* User Name */}
            <Name
              onChange={(name) => {
                setName(name)
              }}
              onInputError={(error) => {
                setNameError(error)
              }}
            />

            {/* Tips */}
            {/* <Tips /> */}
          </div>

          {/* Footer */}
          <Footer
            onCancel={handleClose}
            onConfirm={handlConfirmClick}
            confirmButtonDisabled={confirmButtonDisabled}
          />
        </div>
      </Spin>
    </Modal>
  )
})

export default Editor
