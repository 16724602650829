import expandIcon from "@/assets/studio/mobile/expand.svg"

export default ({ onClick }: { onClick?: () => void }) => {
  return (
    <div
      className="w-[30px] h-[30px] flex items-center justify-center cursor-pointer"
      onClick={() => {
        onClick?.()
      }}
    >
      <img src={expandIcon} className="w-6 h-6" alt="expand" />
    </div>
  )
}
