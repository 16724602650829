import { formatPrice } from "@/utils"
import { ReactElement, useState } from "react"
import { observer } from "mobx-react-lite"
import { ReactComponent as GoodIcon } from "@/assets/subscription_good.svg"
import * as store from "@/store"
import { Link } from "react-router-dom"
import { SUBSCRIPTIONS } from "@/constants"
import dayjs from "dayjs"
import { SubscribePay, UpdateSubscribeConfirm } from "./pay"
// import { Statistic } from "@arco-design/web-react"
import "./planCard.less"
import CheckAuth from "@/components/common/CheckAuth"

interface PlanCardProps {
  id?: number
  subscribeType: number
  price: number
  badge?: ReactElement
  btnTxt?: string
  onClick?: () => void
  isMonthly?: boolean
}

const getPackageDetail = (id: number) => {
  return SUBSCRIPTIONS[id] || {}
}

const PlanCard: React.FC<PlanCardProps> = (props) => {
  const { subscribedProduct } = store.user.get()

  const subscribedProductId = subscribedProduct?.id || 0

  const subscribedProductIndex = subscribedProductId
    ? getPackageDetail(subscribedProductId).index
    : 0

  const { index, title, desc } = getPackageDetail(props.subscribeType)
  const subscribed = subscribedProductIndex === index

  let isDisabledCard = false

  // 按月订阅
  if (props.isMonthly) {
    // 当前订阅方案是月
    if (subscribedProductIndex <= 3) {
      isDisabledCard = subscribedProductIndex > index
    } else {
      isDisabledCard = true
    }
  } else {
    // 当前订阅方案是年
    if (subscribedProductIndex >= 7) {
      isDisabledCard = subscribedProductIndex > index
    } else {
      isDisabledCard = subscribedProductIndex + 6 > index
    }
  }
  const getCardWapperClasses = () => {
    return subscribedProductId
      ? subscribed
        ? ""
        : isDisabledCard
        ? "pointer-events-none opacity-40"
        : ""
      : "0"
  }

  const [showPayModal, setShowPayModal] = useState(false)
  const uploadProduct = async () => {
    setShowPayModal(true)
  }

  const subProduct = () => {
    setShowPayModal(true)
  }

  const getExpiredTime = () => {
    return dayjs((subscribedProduct?.expiredAt || 0) * 1000).format("YYYY.MM.DD")
  }

  return (
    <>
      {showPayModal ? (
        subscribedProductId ? (
          subscribedProductIndex < index ? (
            <UpdateSubscribeConfirm
              productId={props.id || 0}
              onCancel={() => setShowPayModal(false)}
            />
          ) : (
            ""
          )
        ) : (
          <SubscribePay
            productId={props.id || 0}
            type={props.isMonthly ? "month" : "year"}
            onCancel={() => setShowPayModal(false)}
          />
        )
      ) : null}
      <fieldset
        className={`bg-[#212134] flex relative flex-col justify-start items-center md:m-2 my-[20px] mx-[15px] lg:w-[368px]  md:min-h-[422px] p-2.5 pb-8 ${getCardWapperClasses()}`}
      >
        <div className="w-full p-2.5 pl-8  text-[#ffffff33] text-[36px] font-[300]">{title}</div>
        {props.badge ? (
          <div className="absolute top-[2.5] right-2.5 bg-[#E93247] text-[12px] text-white font-normal leading-5 rounded-md px-[8px] py-[3px] text-center">
            {props.badge}
          </div>
        ) : null}
        <div className="h-[100px]">
          {subscribedProductId ? (
            <>
              {subscribed ? (
                <p className="md:mt-8">
                  <span className="text-[34px] leading-[30px] font-bold  text-[] bg-[linear-gradient(90deg, #5DEFA4 -5.97%, #529BFF 49.21%, #6342FD 105.97%)]">
                    当前方案
                  </span>

                  <p className="text-sm text-[#6F7176] mt-[6px] text-center">
                    {getExpiredTime()}到期
                  </p>
                </p>
              ) : (
                <>
                  <>
                    <p className="md:mt-8">
                      <span className="mr-1 text-[14px] leading-5 text-[#ffffff]">¥</span>
                      <span
                        className="text-[48px] font-[900] leading-[30px] text-[#ffffff]"
                        style={{
                          fontFamily: "DIN Alternate",
                        }}
                      >
                        {props.isMonthly
                          ? formatPrice(props.price / 100)
                          : formatPrice(props.price / 100 / 12)}
                      </span>
                      <span className="ml-1 text-[14px] text-[#ffffff]">/月</span>
                    </p>
                    {props.isMonthly ? null : (
                      <p className="text-sm text-[#6F7176] mt-[6px]">
                        合计¥{formatPrice(props.price / 100)}/年
                      </p>
                    )}
                  </>
                </>
              )}
            </>
          ) : (
            <>
              {typeof props.price === "number" ? (
                <>
                  <p className="md:mt-8">
                    <span className="mr-1 text-[14px] leading-5 text-[#ffffff]">¥</span>
                    {/* <Statistic
                      value={props.isMonthly ? props.price / 100 : props.price / 100 / 12}
                      countUp
                      className={"subscription_pice"}
                      precision={2}
                      countDuration={400}
                    /> */}
                    <span
                      className="text-[48px] font-[900] leading-[30px] text-[#ffffff]"
                      style={{
                        fontFamily: "DIN Alternate",
                      }}
                    >
                      {props.isMonthly
                        ? formatPrice(props.price / 100)
                        : formatPrice(props.price / 100 / 12)}
                    </span>
                    <span className="ml-1 text-[14px] text-[#ffffff]">/月</span>
                  </p>
                  {props.isMonthly ? null : (
                    <p className="text-sm text-[#6F7176] mt-[6px]">
                      合计¥{formatPrice(props.price / 100)}/年
                    </p>
                  )}
                </>
              ) : (
                props.price
              )}
            </>
          )}
        </div>
        <div className="w-full px-8">
          {subscribedProductId ? (
            subscribed ? (
              <Link to="/studio">
                <button className="w-full text-base border border-[#5c16f1] text-[#5c16f1] h-[40px] text-4 mt-4 rounded">
                  开始创作
                </button>
              </Link>
            ) : isDisabledCard ? (
              <Link to="/studio">
                <button className=" border-[#ffffff33] bg-[#ffffff33]  rounded line w-full h-[40px] text-4 mt-4">
                  不适用
                </button>
              </Link>
            ) : (
              <button
                onClick={uploadProduct}
                className="primary_btn w-full h-[40px] text-white text-4 mt-4 rounded"
              >
                升级方案
              </button>
            )
          ) : (
            <CheckAuth needLogin onClick={subProduct}>
              <button className="primary_btn w-full h-[40px] text-white text-4 mt-4 rounded">
                开始吧
              </button>
            </CheckAuth>
          )}

          <hr className="my-8 h-1 w-full border-[#ffffff0c]" />
          <div className={`${subscribed ? "font-[500]" : ""} w-full`}>
            {desc?.map((detail, idx) => (
              <div
                key={idx}
                className="flew flex row items-center self-start text-[14px] text-[#ffffffcc] leading-[18px] mb-5"
              >
                <GoodIcon className="mr-[10px]" />
                {detail}
              </div>
            ))}
          </div>
        </div>
      </fieldset>
    </>
  )
}

export default PlanCard
