import React, { useState, useMemo } from "react"
import { Popover } from "@arco-design/web-react"
import { ReactComponent as SortingIcon } from "@/assets/sorting.svg"
import { ReactComponent as HomeSortIcon } from "@/assets/home/sort.svg"

interface SortType {
  label: string
  value: string
}

interface SortPopoverProps {
  sortTypeList: SortType[]
  defaultSort: string
  onSelect: (selectedSort: string) => void
  buttonClassName: string
}

const SortPopover: React.FC<SortPopoverProps> = ({
  sortTypeList,
  defaultSort,
  onSelect,
  buttonClassName,
}) => {
  const [selectedValue, setSelectedValue] = useState(defaultSort)

  const handleSortSelect = (sortValue: string) => {
    setSelectedValue(sortValue)
    onSelect(sortValue)
  }

  const selectedName = useMemo(() => {
    const selectedSort = sortTypeList.find((item) => item.value === selectedValue)
    return selectedSort ? selectedSort.label : ""
  }, [selectedValue, sortTypeList])

  return (
    <Popover
      position="bl"
      trigger="hover"
      color="#212134"
      className="work-popover-wrapper"
      content={
        <ul className="p-[5px] bg-[#212134] rounded">
          {sortTypeList.map((item) => (
            <li
              key={item.value}
              onClick={() => handleSortSelect(item.value)}
              className={`cursor-pointer text-white px-[10px] py-[5px] bg-transparent ${
                selectedValue === item.value ? "bg-[#272739]" : ""
              }`}
            >
              {item.label}
            </li>
          ))}
        </ul>
      }
    >
      <button
        className={`${buttonClassName} h-full flex items-center text-sm rounded md:bg-[#4C1FFF] py-2 px-4`}
      >
        <HomeSortIcon className="md:hidden w-3 h-3 mr-2" />
        <SortingIcon className="hidden md:block mr-2" />
        {selectedName}
      </button>
    </Popover>
  )
}

export default SortPopover
