import LayoutGeneral from "@/components/layout/general"
import { MOBILE_CONTAINER_CLS } from "@/store/menuStore"
import AlbumWaterFall from "./AlbumWaterFall"
import AlbumImageDetail from "./ImageDetail"
import { useState } from "react"
import { V1Task, V1Work } from "@/generated/api/data-contracts"

import { ReactComponent as LoadingPuffIcon } from "@/assets/loading_puff.svg"
const AlbumDetail = () => {
  const [work, setWork] = useState<V1Work>()
  // const [task,setTask] = useState<V1Task>()
  const [loading, setLoading] = useState<boolean>(true)

  return (
    <LayoutGeneral hasHeader={true} className={MOBILE_CONTAINER_CLS}>
      <div className="flex justify-center items-center ">
        <div className="pt-5 2xl:px-[70px] xl:px-[40px] max-w-[1920px] flex  w-full gap-[40px]">
          <AlbumWaterFall
            onGetWork={(work: V1Work) => setWork(work)}
            onLoadingAll={(bool) => setLoading(bool)}
            // onGetTask={(task: V1Task) => setTask(task)}
          />
          {loading ? (
            <div className="max-w-[670px] min-w-[500px] w-full my-20 flex items-center justify-center">
              <LoadingPuffIcon stroke="currentColor" />
            </div>
          ) : (
            <AlbumImageDetail work={work!} />
          )}
        </div>
      </div>
    </LayoutGeneral>
  )
}

export default AlbumDetail
