export const activtiyMock = [
    {
      "id": 346,
      "userUID": "O9PfE7tTXhDEWrX8GHUJ5",
      "userNickName": "vtia",
      "userAvatar": "https://cos-xica-prod.tiamat.world/user/t11OW_xpCbbtYoGM8Abao/avatar/vFg8O3or7BgHjYyJedSE8.jpg",
      "name": "多巴胺头像",
      "intro": "在活动中广受好评的多巴胺头像模板被我们迭代升级后进行了保留，霓虹色画风的多巴胺头像以明亮、鲜艳色调，通过视觉上的冲击，唤起大家内心的愉悦感和兴奋情绪。高能量、时尚感的创新模板，辅助你生成好看的人像。",
      "model": "DongManFengGe",
      "aspectRatio": "3:4",
      "prompt": "[{\"label\":\"1个女孩，太阳镜，黑色头发，粉色背景\",\"editable\":true},{\"label\":\"多巴胺风格，单人，肖像\",\"editable\":false}]",
      "negativePrompt": "",
      "privatePrompt": "******",
      "category": "",
      "exampleImages": [
        "https://cos-xica-dev.tiamat.world/user/O9PfE7tTXhDEWrX8GHUJ5/createdimage/Z0vKwFVLPnEjsvk2pEoIj.png"
      ],
      "reviewState": "pass",
      "reviewRejectReason": "",
      "reviewedAt": 1693458395,
      "reviewer": "******",
      "releaseState": "released",
      "heatValue": 4794,
      "cumUsedUserCount": 2,
      "cumUsedCount": 4,
      "createdAt": 1693458342
    },
    {
      "id": 338,
      "userUID": "O9PfE7tTXhDEWrX8GHUJ5",
      "userNickName": "vtia",
      "userAvatar": "https://cos-xica-prod.tiamat.world/user/t11OW_xpCbbtYoGM8Abao/avatar/vFg8O3or7BgHjYyJedSE8.jpg",
      "name": "姐姐头像",
      "intro": "“姐姐头像”模板为您呈现一种充满魅力和自信的二次元绘画风格的轻熟女性人像。通过精致的人像描绘，搭配各类时尚的元素，创造出一个高雅、优雅且独立的女性形象。适用于定向创作成熟女性的二次元画风。",
      "model": "DongManFengGe",
      "aspectRatio": "3:4",
      "prompt": "[{\"label\":\"1个女孩，耳环，眼镜\",\"editable\":true},{\"label\":\"精致主义，模特视角，简单背景，单人\",\"editable\":false}]",
      "negativePrompt": "",
      "privatePrompt": "******",
      "category": "",
      "exampleImages": [
        "https://cos-xica-dev.tiamat.world/user/O9PfE7tTXhDEWrX8GHUJ5/createdimage/57FI7WINyXUheJlb3Jd0l.png"
      ],
      "reviewState": "pass",
      "reviewRejectReason": "",
      "reviewedAt": 1693458428,
      "reviewer": "******",
      "releaseState": "released",
      "heatValue": 4590,
      "cumUsedUserCount": 1,
      "cumUsedCount": 1,
      "createdAt": 1693458342
    },
    {
      "id": 345,
      "userUID": "O9PfE7tTXhDEWrX8GHUJ5",
      "userNickName": "vtia",
      "userAvatar": "https://cos-xica-prod.tiamat.world/user/t11OW_xpCbbtYoGM8Abao/avatar/vFg8O3or7BgHjYyJedSE8.jpg",
      "name": "艺术少女",
      "intro": "\"艺术少女\"的灵感来源于儿童时代对色彩的独特感知和创造力的天马行空。通过模拟蜡笔的独特笔触质感，来辅助生成充满童真与趣味的少女模型。每一笔每一画都仿佛是用真实的蜡笔亲手绘制而成。适用于动漫风格的女性人物绘制。",
      "model": "DongManFengGe",
      "aspectRatio": "3:4",
      "prompt": "[{\"label\":\"1个女孩，蓝色衣服，黑色头发\",\"editable\":true},{\"label\":\"蜡笔风格，简单背景，单人\",\"editable\":false}]",
      "negativePrompt": "",
      "privatePrompt": "******",
      "category": "",
      "exampleImages": [
        "https://cos-xica-dev.tiamat.world/user/O9PfE7tTXhDEWrX8GHUJ5/createdimage/bjkcRgwOwUyzME8wvD3H0.png"
      ],
      "reviewState": "pass",
      "reviewRejectReason": "",
      "reviewedAt": 1693458397,
      "reviewer": "******",
      "releaseState": "released",
      "heatValue": 4080,
      "cumUsedUserCount": 0,
      "cumUsedCount": 0,
      "createdAt": 1693458342
    },
    {
      "id": 343,
      "userUID": "O9PfE7tTXhDEWrX8GHUJ5",
      "userNickName": "vtia",
      "userAvatar": "https://cos-xica-prod.tiamat.world/user/t11OW_xpCbbtYoGM8Abao/avatar/vFg8O3or7BgHjYyJedSE8.jpg",
      "name": "潮流涂鸦",
      "intro": "”潮流涂鸦“的灵感源于涂鸦艺术，以其轻松、随性的绘画方式辅助绘画。通过简约的线条和大量色块的随意涂抹，创造出一种别具魅力的视觉效果。它适用于各种创作场景，是一款十分泛用且高质量的模型，可独立使用也可作为辅助结合其他模型使用。",
      "model": "DongManFengGe",
      "aspectRatio": "3:4",
      "prompt": "[{\"label\":\"1个女孩，全身像，猫，运动鞋\",\"editable\":true},{\"label\":\"涂鸦风格，单人\",\"editable\":false}]",
      "negativePrompt": "",
      "privatePrompt": "******",
      "category": "",
      "exampleImages": [
        "https://cos-xica-dev.tiamat.world/user/O9PfE7tTXhDEWrX8GHUJ5/createdimage/HkGmH217Eddb5rqh6cgEm.png"
      ],
      "reviewState": "pass",
      "reviewRejectReason": "",
      "reviewedAt": 1693458436,
      "reviewer": "******",
      "releaseState": "released",
      "heatValue": 3978,
      "cumUsedUserCount": 1,
      "cumUsedCount": 1,
      "createdAt": 1693458342
    },
    {
      "id": 344,
      "userUID": "O9PfE7tTXhDEWrX8GHUJ5",
      "userNickName": "vtia",
      "userAvatar": "https://cos-xica-prod.tiamat.world/user/t11OW_xpCbbtYoGM8Abao/avatar/vFg8O3or7BgHjYyJedSE8.jpg",
      "name": "Q版可爱头像",
      "intro": "“Q版可爱头像”模板是一款Q版插画风格的头像模板，用少女心爆棚的绘画风格打造充满可爱和元气的视觉世界。简洁的线条搭配可爱的元素，创造充满童趣和活力的萌版头像。适用于可爱风的头像设计，也可作为插画元素。",
      "model": "DongManFengGe",
      "aspectRatio": "3:4",
      "prompt": "[{\"label\":\"1个女孩，粉色衣服，黑色头发\",\"editable\":true},{\"label\":\"可爱插图，简单背景，单人\",\"editable\":false}]",
      "negativePrompt": "",
      "privatePrompt": "******",
      "category": "",
      "exampleImages": [
        "https://cos-xica-dev.tiamat.world/user/O9PfE7tTXhDEWrX8GHUJ5/createdimage/dyCtR2o2v5UxJ3W6hs1dN.png"
      ],
      "reviewState": "pass",
      "reviewRejectReason": "",
      "reviewedAt": 1693458441,
      "reviewer": "******",
      "releaseState": "released",
      "heatValue": 3978,
      "cumUsedUserCount": 0,
      "cumUsedCount": 0,
      "createdAt": 1693458342
    },
    {
      "id": 342,
      "userUID": "O9PfE7tTXhDEWrX8GHUJ5",
      "userNickName": "vtia",
      "userAvatar": "https://cos-xica-prod.tiamat.world/user/t11OW_xpCbbtYoGM8Abao/avatar/vFg8O3or7BgHjYyJedSE8.jpg",
      "name": "呆萌头像",
      "intro": "“呆萌头像”模仿儿童绘画的简单而欢快的线条，以及大胆而丰富的色彩运用，从而创造一种儿童彩绘本般的艺术风格，强调线条的清晰轮廓和色彩的鲜明饱满。可以生成可爱、活泼、令人愉悦的的艺术作品。适用于插图、卡通角色和简单的场景。",
      "model": "DongManFengGe",
      "aspectRatio": "1:1",
      "prompt": "[{\"label\":\"1个女孩\",\"editable\":true},{\"label\":\"矢量风格\",\"editable\":false}]",
      "negativePrompt": "",
      "privatePrompt": "******",
      "category": "",
      "exampleImages": [
        "https://cos-xica-dev.tiamat.world/user/O9PfE7tTXhDEWrX8GHUJ5/createdimage/nooJacJUAMxUFaKkjUJO7.png"
      ],
      "reviewState": "pass",
      "reviewRejectReason": "",
      "reviewedAt": 1693458372,
      "reviewer": "******",
      "releaseState": "released",
      "heatValue": 3774,
      "cumUsedUserCount": 0,
      "cumUsedCount": 0,
      "createdAt": 1693458342
    },
    {
      "id": 341,
      "userUID": "O9PfE7tTXhDEWrX8GHUJ5",
      "userNickName": "vtia",
      "userAvatar": "https://cos-xica-prod.tiamat.world/user/t11OW_xpCbbtYoGM8Abao/avatar/vFg8O3or7BgHjYyJedSE8.jpg",
      "name": "二次元漫画风｜国风元气少年",
      "intro": "“功夫少年头像”模板想要复刻展现中华传统武术文化，通过刻画习武少年的元气和自信，生成富有动感的唐风少年，展现武术文化的魅力与少年人的英气活力。适用于国风元气少女的刻画。",
      "model": "DongManFengGe",
      "aspectRatio": "3:4",
      "prompt": "[{\"label\":\"1个女孩，火焰特效，蓝色背景，黑色长发\",\"editable\":true},{\"label\":\"唐风，身穿汉服，水墨风格，战斗姿态，单人\",\"editable\":false}]",
      "negativePrompt": "",
      "privatePrompt": "******",
      "category": "",
      "exampleImages": [
        "https://cos-xica-dev.tiamat.world/user/O9PfE7tTXhDEWrX8GHUJ5/createdimage/BqxG023YqS3Fbfbg6MpH5.png"
      ],
      "reviewState": "pass",
      "reviewRejectReason": "",
      "reviewedAt": 1693458421,
      "reviewer": "******",
      "releaseState": "released",
      "heatValue": 3672,
      "cumUsedUserCount": 0,
      "cumUsedCount": 0,
      "createdAt": 1693458342
    },
    {
      "id": 354,
      "userUID": "O9PfE7tTXhDEWrX8GHUJ5",
      "userNickName": "vtia",
      "userAvatar": "https://cos-xica-prod.tiamat.world/user/t11OW_xpCbbtYoGM8Abao/avatar/vFg8O3or7BgHjYyJedSE8.jpg",
      "name": "梦想中的家",
      "intro": "你期望有个怎样的家？是中式古典、现代极简、欧式尊贵.....在该模板下，都可以创造一个属于你的小家！",
      "model": "TongYongXieShi2",
      "aspectRatio": "4:3",
      "prompt": "[{\"label\":\"现代极简风格，客厅，银灰色\",\"editable\":true},{\"label\":\"家，全景，真实，温馨\",\"editable\":false}]",
      "negativePrompt": "",
      "privatePrompt": "******",
      "category": "",
      "exampleImages": [
        "https://cos-xica-dev.tiamat.world/user/O9PfE7tTXhDEWrX8GHUJ5/createdimage/Rl9Bpipul8NxIfJfdL2c1.png"
      ],
      "reviewState": "pass",
      "reviewRejectReason": "",
      "reviewedAt": 1693458361,
      "reviewer": "******",
      "releaseState": "released",
      "heatValue": 3672,
      "cumUsedUserCount": 0,
      "cumUsedCount": 0,
      "createdAt": 1693458342
    },
    {
      "id": 340,
      "userUID": "O9PfE7tTXhDEWrX8GHUJ5",
      "userNickName": "vtia",
      "userAvatar": "https://cos-xica-prod.tiamat.world/user/t11OW_xpCbbtYoGM8Abao/avatar/vFg8O3or7BgHjYyJedSE8.jpg",
      "name": "日系清新插图",
      "intro": "“小清新”模板主打冷色系的小清新、甜美滋润的风格，营造充满冷静和宁静的视觉世界。灵感源自于甜美的糖水色彩，以冷色调为基础，呈现出一种清新、宁静的感觉。通过冷色系的明亮和柔和的效果，打造宁静而温馨的视觉享受。",
      "model": "DongManFengGe",
      "aspectRatio": "3:4",
      "prompt": "[{\"label\":\"1个女孩，云和风背景，微笑\",\"editable\":true},{\"label\":\"小清新，单人\",\"editable\":false}]",
      "negativePrompt": "",
      "privatePrompt": "******",
      "category": "",
      "exampleImages": [
        "https://cos-xica-dev.tiamat.world/user/O9PfE7tTXhDEWrX8GHUJ5/createdimage/HymZQZsRpgLavojE0waDx.png"
      ],
      "reviewState": "pass",
      "reviewRejectReason": "",
      "reviewedAt": 1693458412,
      "reviewer": "******",
      "releaseState": "released",
      "heatValue": 3570,
      "cumUsedUserCount": 0,
      "cumUsedCount": 0,
      "createdAt": 1693458342
    },
    {
      "id": 353,
      "userUID": "O9PfE7tTXhDEWrX8GHUJ5",
      "userNickName": "vtia",
      "userAvatar": "https://cos-xica-prod.tiamat.world/user/t11OW_xpCbbtYoGM8Abao/avatar/vFg8O3or7BgHjYyJedSE8.jpg",
      "name": "末日避难所",
      "intro": "/",
      "model": "TongYongXieShi2",
      "aspectRatio": "4:3",
      "prompt": "[{\"label\":\"凌乱的床，电脑\",\"editable\":true},{\"label\":\"山洞避难所, 昏暗的灯光,不真实的，卡通，游戏CG\",\"editable\":false}]",
      "negativePrompt": "window,sunshine",
      "privatePrompt": "******",
      "category": "",
      "exampleImages": [
        "https://cos-xica-dev.tiamat.world/user/O9PfE7tTXhDEWrX8GHUJ5/createdimage/GlVjkFgct3wZ6KZxAKJ5O.png"
      ],
      "reviewState": "pass",
      "reviewRejectReason": "",
      "reviewedAt": 1693458363,
      "reviewer": "******",
      "releaseState": "released",
      "heatValue": 3570,
      "cumUsedUserCount": 0,
      "cumUsedCount": 0,
      "createdAt": 1693458342
    },
    {
      "id": 339,
      "userUID": "O9PfE7tTXhDEWrX8GHUJ5",
      "userNickName": "vtia",
      "userAvatar": "https://cos-xica-prod.tiamat.world/user/t11OW_xpCbbtYoGM8Abao/avatar/vFg8O3or7BgHjYyJedSE8.jpg",
      "name": "氛围感色彩少女｜灰色系",
      "intro": "“灰发小妹头像”源于独特的时尚审美，是奶奶灰发色曾风靡一时的见证。凸显极简风格和性冷淡风的灰色衬托女性的韵味，创造出神秘、独特的女性头像风格。专用于灰发女性的人像生成。",
      "model": "DongManFengGe",
      "aspectRatio": "3:4",
      "prompt": "[{\"label\":\"微笑，黑色衬衫\",\"editable\":true},{\"label\":\"色彩女孩，单人\",\"editable\":false}]",
      "negativePrompt": "",
      "privatePrompt": "******",
      "category": "",
      "exampleImages": [
        "https://cos-xica-dev.tiamat.world/user/O9PfE7tTXhDEWrX8GHUJ5/createdimage/AMK0V3NTltpxOXmFsYGb8.png"
      ],
      "reviewState": "pass",
      "reviewRejectReason": "",
      "reviewedAt": 1693458405,
      "reviewer": "******",
      "releaseState": "released",
      "heatValue": 3468,
      "cumUsedUserCount": 0,
      "cumUsedCount": 0,
      "createdAt": 1693458342
    },
    {
      "id": 352,
      "userUID": "O9PfE7tTXhDEWrX8GHUJ5",
      "userNickName": "vtia",
      "userAvatar": "https://cos-xica-prod.tiamat.world/user/t11OW_xpCbbtYoGM8Abao/avatar/vFg8O3or7BgHjYyJedSE8.jpg",
      "name": "世外桃源",
      "intro": "/",
      "model": "TongYongXieShi2",
      "aspectRatio": "16:9",
      "prompt": "[{\"label\":\"岩石，带积雪的高山，树林\",\"editable\":true},{\"label\":\"自然，天空，户外\",\"editable\":false}]",
      "negativePrompt": "",
      "privatePrompt": "******",
      "category": "场景",
      "exampleImages": [
        "https://cos-xica-dev.tiamat.world/user/O9PfE7tTXhDEWrX8GHUJ5/createdimage/TFnf5UnHmamNnyPB0b7u0.png"
      ],
      "reviewState": "pass",
      "reviewRejectReason": "",
      "reviewedAt": 1693458382,
      "reviewer": "******",
      "releaseState": "released",
      "heatValue": 3468,
      "cumUsedUserCount": 0,
      "cumUsedCount": 0,
      "createdAt": 1693458342
    },
    {
      "id": 337,
      "userUID": "O9PfE7tTXhDEWrX8GHUJ5",
      "userNickName": "vtia",
      "userAvatar": "https://cos-xica-prod.tiamat.world/user/t11OW_xpCbbtYoGM8Abao/avatar/vFg8O3or7BgHjYyJedSE8.jpg",
      "name": "卡通人像",
      "intro": "“卡通人像”模板的设计灵感来源于卡通艺术，通过动漫画风的配色方案，为人像注入可爱满满的元气与活力。作为动漫化的人像模板，可以辅助绝大多数的角色设计和人像创建需求。",
      "model": "DongManFengGe",
      "aspectRatio": "3:4",
      "prompt": "[{\"label\":\"1个女孩，卷发，工作服\",\"editable\":true},{\"label\":\"卡通风格\",\"editable\":false}]",
      "negativePrompt": "",
      "privatePrompt": "******",
      "category": "",
      "exampleImages": [
        "https://cos-xica-dev.tiamat.world/user/O9PfE7tTXhDEWrX8GHUJ5/createdimage/gFwnfPn4BjJrxvnPoB2Xi.png"
      ],
      "reviewState": "pass",
      "reviewRejectReason": "",
      "reviewedAt": 1693458447,
      "reviewer": "******",
      "releaseState": "released",
      "heatValue": 3366,
      "cumUsedUserCount": 0,
      "cumUsedCount": 0,
      "createdAt": 1693458342
    },
    {
      "id": 351,
      "userUID": "O9PfE7tTXhDEWrX8GHUJ5",
      "userNickName": "vtia",
      "userAvatar": "https://cos-xica-prod.tiamat.world/user/t11OW_xpCbbtYoGM8Abao/avatar/vFg8O3or7BgHjYyJedSE8.jpg",
      "name": "机械装甲",
      "intro": "科技感爆棚的机械装甲来啦！未来的电子人是不是让人很期待，快来试试看吧！",
      "model": "TongYongXieShi2",
      "aspectRatio": "9:16",
      "prompt": "[{\"label\":\"一个男子，红色眼睛\",\"editable\":true},{\"label\":\"废旧工厂，机械感，科技感，未来科技\",\"editable\":false}]",
      "negativePrompt": "(worst quality, low quality:1.4),",
      "privatePrompt": "******",
      "category": "人物",
      "exampleImages": [
        "https://cos-xica-dev.tiamat.world/user/O9PfE7tTXhDEWrX8GHUJ5/createdimage/5XDwaWc8MF2zjKgWK81rO.png"
      ],
      "reviewState": "pass",
      "reviewRejectReason": "",
      "reviewedAt": 1693458384,
      "reviewer": "******",
      "releaseState": "released",
      "heatValue": 3366,
      "cumUsedUserCount": 0,
      "cumUsedCount": 0,
      "createdAt": 1693458342
    },
    {
      "id": 336,
      "userUID": "O9PfE7tTXhDEWrX8GHUJ5",
      "userNickName": "vtia",
      "userAvatar": "https://cos-xica-prod.tiamat.world/user/t11OW_xpCbbtYoGM8Abao/avatar/vFg8O3or7BgHjYyJedSE8.jpg",
      "name": "童话故事",
      "intro": "“童话故事”是一款清新而迷人的小清新风格模板。通过使用柔和的色彩和光影效果，营造出一个充满幻想和浪漫的世界。推荐使用天空、城堡、鲜花等童话元素，可以辅助构筑出梦幻般的艺术作品”",
      "model": "DongManFengGe",
      "aspectRatio": "3:4",
      "prompt": "[{\"label\":\"1个女孩，多云的天空，水中倒影，很多花\",\"editable\":true},{\"label\":\"梦幻色彩，户外\",\"editable\":false}]",
      "negativePrompt": "",
      "privatePrompt": "******",
      "category": "",
      "exampleImages": [
        "https://cos-xica-dev.tiamat.world/user/O9PfE7tTXhDEWrX8GHUJ5/createdimage/c9s6qPv8P5o6amWlUL5xG.png"
      ],
      "reviewState": "pass",
      "reviewRejectReason": "",
      "reviewedAt": 1693458453,
      "reviewer": "******",
      "releaseState": "released",
      "heatValue": 3264,
      "cumUsedUserCount": 0,
      "cumUsedCount": 0,
      "createdAt": 1693458342
    },
    {
      "id": 349,
      "userUID": "O9PfE7tTXhDEWrX8GHUJ5",
      "userNickName": "vtia",
      "userAvatar": "https://cos-xica-prod.tiamat.world/user/t11OW_xpCbbtYoGM8Abao/avatar/vFg8O3or7BgHjYyJedSE8.jpg",
      "name": "高级审美｜厚涂色彩港风",
      "intro": "“时尚御姐头像”模板源自于复古港漫风格的独特魅力。其生成的人像十分注重脸型轮廓的精致表现，带着满满的复古港漫的既视感，仅需简单的角色描述加上背景描述就可以生成好看的人像图片。这是一款专用性极强的人像生成模板。尤其适合御姐系角色的生成。",
      "model": "DongManFengGe",
      "aspectRatio": "3:4",
      "prompt": "[{\"label\":\"1个女孩，时尚模特，珠宝首饰，波浪发\",\"editable\":true},{\"label\":\"港漫风格，优雅气质，牛仔镜头，水彩，单人\",\"editable\":false}]",
      "negativePrompt": "",
      "privatePrompt": "******",
      "category": "",
      "exampleImages": [
        "https://cos-xica-dev.tiamat.world/user/O9PfE7tTXhDEWrX8GHUJ5/createdimage/CVkQpsc0QeKNQn0MmK4Po.png"
      ],
      "reviewState": "pass",
      "reviewRejectReason": "",
      "reviewedAt": 1693458388,
      "reviewer": "******",
      "releaseState": "released",
      "heatValue": 3264,
      "cumUsedUserCount": 0,
      "cumUsedCount": 0,
      "createdAt": 1693458342
    },
    {
      "id": 334,
      "userUID": "O9PfE7tTXhDEWrX8GHUJ5",
      "userNickName": "vtia",
      "userAvatar": "https://cos-xica-prod.tiamat.world/user/t11OW_xpCbbtYoGM8Abao/avatar/vFg8O3or7BgHjYyJedSE8.jpg",
      "name": "酷炫动漫头像",
      "intro": "\"炫酷动感头像“采用动感设计，炫酷的动感元素为动漫人物赋予全新的生机与活力。打造一个栩栩如生的虚拟角色，同时保留鲜明的动漫风格，为你捕捉动漫人物的个性与活力。张力无限，适用于绝大多数角色设计场景。",
      "model": "DongManFengGe",
      "aspectRatio": "3:4",
      "prompt": "[{\"label\":\"1个男人，蓝色主题，凉鞋，日式服装，武器\",\"editable\":true},{\"label\":\"炫酷动漫，单人\",\"editable\":false}]",
      "negativePrompt": "",
      "privatePrompt": "******",
      "category": "",
      "exampleImages": [
        "https://cos-xica-dev.tiamat.world/user/O9PfE7tTXhDEWrX8GHUJ5/createdimage/OuXCN3tZMKaR0X1rIvk3C.png"
      ],
      "reviewState": "pass",
      "reviewRejectReason": "",
      "reviewedAt": 1693458469,
      "reviewer": "******",
      "releaseState": "released",
      "heatValue": 3162,
      "cumUsedUserCount": 1,
      "cumUsedCount": 1,
      "createdAt": 1693458342
    },
    {
      "id": 348,
      "userUID": "O9PfE7tTXhDEWrX8GHUJ5",
      "userNickName": "vtia",
      "userAvatar": "https://cos-xica-prod.tiamat.world/user/t11OW_xpCbbtYoGM8Abao/avatar/vFg8O3or7BgHjYyJedSE8.jpg",
      "name": "小红书爆火头像｜女v1.0",
      "intro": "“复古头像”模板希望复刻90年代经典动漫的视觉元素，在动漫风格的人像设计中强调鲜明的线条和饱和的色彩。无论是在复古怀旧还是寻求独特表达，这款模板都可以辅助人像生成，是一款十分强大的动漫怀旧风人像生成模板。",
      "model": "DongManFengGe",
      "aspectRatio": "3:4",
      "prompt": "[{\"label\":\"1个女孩，黑色长卷发，耳环\",\"editable\":true},{\"label\":\"90s风格，单人，简单背景\",\"editable\":false}]",
      "negativePrompt": "",
      "privatePrompt": "******",
      "category": "",
      "exampleImages": [
        "https://cos-xica-dev.tiamat.world/user/O9PfE7tTXhDEWrX8GHUJ5/createdimage/KSQgfydw9cpQC57FMnt4F.png"
      ],
      "reviewState": "pass",
      "reviewRejectReason": "",
      "reviewedAt": 1693458390,
      "reviewer": "******",
      "releaseState": "released",
      "heatValue": 3162,
      "cumUsedUserCount": 0,
      "cumUsedCount": 0,
      "createdAt": 1693458342
    },
    {
      "id": 347,
      "userUID": "O9PfE7tTXhDEWrX8GHUJ5",
      "userNickName": "vtia",
      "userAvatar": "https://cos-xica-prod.tiamat.world/user/t11OW_xpCbbtYoGM8Abao/avatar/vFg8O3or7BgHjYyJedSE8.jpg",
      "name": "最美的风景",
      "intro": "“最美的风景”通过模拟油画的笔触和色彩效果，可以辅助复刻自然的宁静与壮美，呈现出画布上创作的自然风景杰作，注重细节，充满了生动的美感。本模型专用于风景优化的生成，不适用于人像的生成。",
      "model": "DongManFengGe",
      "aspectRatio": "3:4",
      "prompt": "[{\"label\":\"晴天，小路，欧式建筑\",\"editable\":true},{\"label\":\"风景油画，城市，户外，白云\",\"editable\":false}]",
      "negativePrompt": "",
      "privatePrompt": "******",
      "category": "",
      "exampleImages": [
        "https://cos-xica-dev.tiamat.world/user/O9PfE7tTXhDEWrX8GHUJ5/createdimage/LfoWL7nsIDho50hVxGW5A.png"
      ],
      "reviewState": "pass",
      "reviewRejectReason": "",
      "reviewedAt": 1693458393,
      "reviewer": "******",
      "releaseState": "released",
      "heatValue": 3060,
      "cumUsedUserCount": 0,
      "cumUsedCount": 0,
      "createdAt": 1693458342
    },
    {
      "id": 287,
      "userUID": "r4fvoL5VG8TX0KK8XvZWY",
      "userNickName": "乌龙金桂",
      "userAvatar": "https://cos-xica-dev.tiamat.world/user/r4fvoL5VG8TX0KK8XvZWY/avatar/juyZC1773iDJVdlajzHRd.png",
      "name": " 孙悟空",
      "intro": "这是日本动漫的孙悟空",
      "model": "tongyong-jichu",
      "aspectRatio": "1:1",
      "prompt": "[{\"label\":\"孙悟空\",\"editable\":true}]",
      "negativePrompt": "",
      "privatePrompt": "******",
      "category": "character",
      "exampleImages": [
        "https://cos-xica-dev.tiamat.world/user/r4fvoL5VG8TX0KK8XvZWY/createdimage/oo2E2gAl2PPHJdoe5FWcT.png",
        "https://cos-xica-dev.tiamat.world/user/r4fvoL5VG8TX0KK8XvZWY/createdimage/6FrnVK4n6Ivf2eKrN6aRa.png"
      ],
      "reviewState": "pass",
      "reviewRejectReason": "",
      "reviewedAt": 1693202557,
      "reviewer": "******",
      "releaseState": "released",
      "heatValue": 101,
      "cumUsedUserCount": 1,
      "cumUsedCount": 2,
      "createdAt": 1693202529
    },
    {
      "id": 350,
      "userUID": "O9PfE7tTXhDEWrX8GHUJ5",
      "userNickName": "vtia",
      "userAvatar": "https://cos-xica-prod.tiamat.world/user/t11OW_xpCbbtYoGM8Abao/avatar/vFg8O3or7BgHjYyJedSE8.jpg",
      "name": "全网首发｜氛围感插图头像",
      "intro": "“niji风格头像”模板可以辅助生成色彩鲜艳、明快活泼的动漫风人像，采用了充满活力和创意的动漫风格，通过独特的配色和元素来打造年轻、时尚以及独特的个性人物。尤其适合生成酷炫痞帅的少年形象。当然同样支持元气少女的生成。",
      "model": "DongManFengGe",
      "aspectRatio": "3:4",
      "prompt": "[{\"label\":\"1个女孩，粉色背景\",\"editable\":true},{\"label\":\"炫酷动漫，光影，锐利的眼睛，单人\",\"editable\":false}]",
      "negativePrompt": "",
      "privatePrompt": "******",
      "category": "",
      "exampleImages": [
        "https://cos-xica-dev.tiamat.world/user/O9PfE7tTXhDEWrX8GHUJ5/createdimage/4QL9lbZoIY7VWFppBWQyI.png"
      ],
      "reviewState": "pass",
      "reviewRejectReason": "",
      "reviewedAt": 1693458386,
      "reviewer": "******",
      "releaseState": "released",
      "heatValue": 101,
      "cumUsedUserCount": 1,
      "cumUsedCount": 1,
      "createdAt": 1693458342
    },
    {
      "id": 281,
      "userUID": "gmP7xsi01J3lmp8Ytmzsm",
      "userNickName": "哈利波特",
      "userAvatar": "https://cos-xica-dev.tiamat.world/user/gmP7xsi01J3lmp8Ytmzsm/avatar/wZaYUQmocThevC5x5lRc1.jpg",
      "name": "谁是哈利",
      "intro": "大难临头的男孩",
      "model": "tongyong-jichu",
      "aspectRatio": "1:1",
      "prompt": "[{\"label\":\"“大难临头的男孩”的彩色照片\",\"editable\":true},{\"label\":\"他一头乱蓬蓬的黑发\",\"editable\":true},{\"label\":\"戴着一副圆眼镜。他额头上闪电形的伤疤依稀可见。他翠绿的眼睛里充满了决心和勇气。他那鲜红的格兰芬多长袍在霍格沃茨城堡的黑暗背景下显得格外显眼。这张照片捕捉到了哈利的动作\",\"editable\":true},{\"label\":\"他的魔杖牢牢地握在手里\",\"editable\":true},{\"label\":\"准备施咒。这个场景充满了魔法和冒险的感觉\",\"editable\":false},{\"label\":\"围绕着他的是一缕一缕的能量。当哈利与黑暗势力作斗争时\",\"editable\":true},{\"label\":\"气氛充满了危险和希望。使用的相机是一台老式35mm胶片相机\",\"editable\":false},{\"label\":\"以丰富的色彩和细腻的细节捕捉场景。使用的胶片是柯达Portra\",\"editable\":true},{\"label\":\"以其温暖的色调和准确的肤色而闻名。镜头的选择是佳能EF\",\"editable\":false},{\"label\":\"允许较浅的景深和对哈利的脸的清晰聚焦。摄影师熟练地运用框架和构图等技术来创造引人注目的图像。这一幕让人想起电影杰作\",\"editable\":true},{\"label\":\"受到克里斯托弗·诺兰、摄影师罗杰·迪金斯、著名时装设计师亚历山大·麦昆和有远见的摄影师安妮·莱博维茨等导演的影响\",\"editable\":true},{\"label\":\"400\",\"editable\":false}]",
      "negativePrompt": "",
      "privatePrompt": "******",
      "category": "character",
      "exampleImages": [
        "https://cos-xica-dev.tiamat.world/user/gmP7xsi01J3lmp8Ytmzsm/createdimage/WiICdPHny0y1Lb8D0c4Wf.png",
        "https://cos-xica-dev.tiamat.world/user/gmP7xsi01J3lmp8Ytmzsm/createdimage/cC5ejci4HWgUYiGPC0N4W.png",
        "https://cos-xica-dev.tiamat.world/user/gmP7xsi01J3lmp8Ytmzsm/createdimage/31sqcm9PSR4r4HCDYb3Hz.png"
      ],
      "reviewState": "pass",
      "reviewRejectReason": "",
      "reviewedAt": 1692956542,
      "reviewer": "******",
      "releaseState": "released",
      "heatValue": 0,
      "cumUsedUserCount": 0,
      "cumUsedCount": 0,
      "createdAt": 1692847425
    },
    {
      "id": 282,
      "userUID": "1hQijrJ44MpMKfhG0VqzD",
      "userNickName": "65BLJjZg61QV6oCdtucKd",
      "userAvatar": "https://cos-xica-dev.tiamat.world/user/1hQijrJ44MpMKfhG0VqzD/createdimage/nQvJsdGyksiwUhNwTlPS3.png",
      "name": "查现在v",
      "intro": "定时",
      "model": "tongyong-jichu",
      "aspectRatio": "1:1",
      "prompt": "[{\"label\":\"大煞风景哦那我企鹅多少分呢哦问的\",\"editable\":true},{\"label\":\"i为\",\"editable\":true}]",
      "negativePrompt": "",
      "privatePrompt": "******",
      "category": "character",
      "exampleImages": [
        "https://cos-xica-dev.tiamat.world/user/1hQijrJ44MpMKfhG0VqzD/createdimage/MkNRLXKlP3OUEyc4Z8Iau.png",
        "https://cos-xica-dev.tiamat.world/user/1hQijrJ44MpMKfhG0VqzD/createdimage/KJEnEz1wDXJUWAgx4jnIU.png",
        "https://cos-xica-dev.tiamat.world/user/1hQijrJ44MpMKfhG0VqzD/createdimage/4IAXa39NpULjKA6vxLGNa.png",
        "https://cos-xica-dev.tiamat.world/user/1hQijrJ44MpMKfhG0VqzD/createdimage/nRyFDBq40hQjnBPdtgYrU.png"
      ],
      "reviewState": "pass",
      "reviewRejectReason": "",
      "reviewedAt": 1693360969,
      "reviewer": "******",
      "releaseState": "released",
      "heatValue": 0,
      "cumUsedUserCount": 0,
      "cumUsedCount": 0,
      "createdAt": 1692953813
    },
    {
      "id": 284,
      "userUID": "1hQijrJ44MpMKfhG0VqzD",
      "userNickName": "65BLJjZg61QV6oCdtucKd",
      "userAvatar": "https://cos-xica-dev.tiamat.world/user/1hQijrJ44MpMKfhG0VqzD/createdimage/nQvJsdGyksiwUhNwTlPS3.png",
      "name": "大师傅",
      "intro": "介绍",
      "model": "tongyong-jichu",
      "aspectRatio": "1:1",
      "prompt": "[{\"label\":\"大煞风景哦那我企鹅多少分呢哦问的\",\"editable\":false},{\"label\":\"i为\",\"editable\":true}]",
      "negativePrompt": "",
      "privatePrompt": "******",
      "category": "character",
      "exampleImages": [
        "https://cos-xica-dev.tiamat.world/user/1hQijrJ44MpMKfhG0VqzD/createdimage/MkNRLXKlP3OUEyc4Z8Iau.png",
        "https://cos-xica-dev.tiamat.world/user/1hQijrJ44MpMKfhG0VqzD/createdimage/KJEnEz1wDXJUWAgx4jnIU.png",
        "https://cos-xica-dev.tiamat.world/user/1hQijrJ44MpMKfhG0VqzD/createdimage/4IAXa39NpULjKA6vxLGNa.png"
      ],
      "reviewState": "pass",
      "reviewRejectReason": "",
      "reviewedAt": 1692957048,
      "reviewer": "******",
      "releaseState": "released",
      "heatValue": 0,
      "cumUsedUserCount": 0,
      "cumUsedCount": 0,
      "createdAt": 1692954852
    },
    {
      "id": 286,
      "userUID": "gmP7xsi01J3lmp8Ytmzsm",
      "userNickName": "哈利波特",
      "userAvatar": "https://cos-xica-dev.tiamat.world/user/gmP7xsi01J3lmp8Ytmzsm/avatar/wZaYUQmocThevC5x5lRc1.jpg",
      "name": "永恒",
      "intro": "所有计时装置都可以称为计时仪器",
      "model": "shouban-sheji",
      "aspectRatio": "1:1",
      "prompt": "[{\"label\":\"一个古老的钟表\",\"editable\":true},{\"label\":\"复杂的黄铜细节\",\"editable\":true},{\"label\":\"风化的铜绿。它的指针\",\"editable\":true},{\"label\":\"精心蚀刻\",\"editable\":true},{\"label\":\"优雅地围绕钟面移动\",\"editable\":true},{\"label\":\"精确地标记时间。优雅的衬线字体数字\",\"editable\":true}]",
      "negativePrompt": "",
      "privatePrompt": "******",
      "category": "object",
      "exampleImages": [
        "https://cos-xica-dev.tiamat.world/user/gmP7xsi01J3lmp8Ytmzsm/createdimage/ahA343UQ7gpMFSCRXsE7K.png"
      ],
      "reviewState": "pass",
      "reviewRejectReason": "",
      "reviewedAt": 1693193746,
      "reviewer": "******",
      "releaseState": "released",
      "heatValue": 0,
      "cumUsedUserCount": 0,
      "cumUsedCount": 2,
      "createdAt": 1693193308
    },
    {
      "id": 310,
      "userUID": "RHzlrKFAkkGPoQY0AUhc5",
      "userNickName": "2ZCXIkiTW8gkroiQlGNAw",
      "userAvatar": "",
      "name": "首页模版测试",
      "intro": "test111111",
      "model": "tongyong-jichu",
      "aspectRatio": "16:9",
      "prompt": "[{\"label\":\"一组\",\"editable\":true},{\"label\":\"2090\",\"editable\":false},{\"label\":\"年生产的新款\",\"editable\":false},{\"label\":\"Beats\",\"editable\":false},{\"label\":\"by\",\"editable\":false},{\"label\":\"Dr.\",\"editable\":false},{\"label\":\"Dre\",\"editable\":false},{\"label\":\"耳机模型\",\"editable\":false},{\"label\":\"采用钻石涂层图案\",\"editable\":false},{\"label\":\"极致细节、分辨率和生动的色彩呈现\",\"editable\":false},{\"label\":\"采用\",\"editable\":false},{\"label\":\"8K\",\"editable\":false},{\"label\":\"渲染技术\",\"editable\":false},{\"label\":\"带来非凡的视觉享受\",\"editable\":false},{\"label\":\"艺术风格：科技感十足的耳机设计。\",\"editable\":false}]",
      "negativePrompt": "",
      "privatePrompt": "******",
      "category": "others",
      "exampleImages": [
        "https://cos-xica-dev.tiamat.world/user/RHzlrKFAkkGPoQY0AUhc5/createdimage/sEI5c2RvdEG1HLlh27oj7.png"
      ],
      "reviewState": "pass",
      "reviewRejectReason": "",
      "reviewedAt": 1693383164,
      "reviewer": "******",
      "releaseState": "released",
      "heatValue": 0,
      "cumUsedUserCount": 0,
      "cumUsedCount": 0,
      "createdAt": 1693383127
    },
    {
      "id": 311,
      "userUID": "RHzlrKFAkkGPoQY0AUhc5",
      "userNickName": "2ZCXIkiTW8gkroiQlGNAw",
      "userAvatar": "",
      "name": "模版测试",
      "intro": "test1111111",
      "model": "tongyong-jichu",
      "aspectRatio": "1:1",
      "prompt": "[{\"label\":\"一组\",\"editable\":true},{\"label\":\"2090\",\"editable\":false},{\"label\":\"年生产的新款\",\"editable\":false},{\"label\":\"Beats\",\"editable\":false},{\"label\":\"by\",\"editable\":false},{\"label\":\"Dr.\",\"editable\":false},{\"label\":\"Dre\",\"editable\":false},{\"label\":\"耳机模型\",\"editable\":false},{\"label\":\"采用钻石涂层图案\",\"editable\":false},{\"label\":\"极致细节、分辨率和生动的色彩呈现\",\"editable\":false},{\"label\":\"采用\",\"editable\":false},{\"label\":\"8K\",\"editable\":false},{\"label\":\"渲染技术\",\"editable\":false},{\"label\":\"带来非凡的视觉享受\",\"editable\":false},{\"label\":\"艺术风格：科技感十足的耳机设计。\",\"editable\":false}]",
      "negativePrompt": "",
      "privatePrompt": "******",
      "category": "character",
      "exampleImages": [
        "https://cos-xica-dev.tiamat.world/user/RHzlrKFAkkGPoQY0AUhc5/createdimage/fNyS1lIUeso0PqAvMGwM9.png",
        "https://cos-xica-dev.tiamat.world/user/RHzlrKFAkkGPoQY0AUhc5/createdimage/sEI5c2RvdEG1HLlh27oj7.png"
      ],
      "reviewState": "pass",
      "reviewRejectReason": "",
      "reviewedAt": 1693384041,
      "reviewer": "******",
      "releaseState": "released",
      "heatValue": 0,
      "cumUsedUserCount": 0,
      "cumUsedCount": 0,
      "createdAt": 1693384004
    },
    {
      "id": 312,
      "userUID": "RHzlrKFAkkGPoQY0AUhc5",
      "userNickName": "2ZCXIkiTW8gkroiQlGNAw",
      "userAvatar": "",
      "name": "测试9:！6",
      "intro": "test1111111",
      "model": "tongyong-jichu",
      "aspectRatio": "9:16",
      "prompt": "[{\"label\":\"这是一个夏日的早晨\",\"editable\":true},{\"label\":\"一棵白色玉兰花树盛开在古老的中国建筑物背景下。背景被柔和的模糊处理\",\"editable\":false},{\"label\":\"让观者的目光聚焦在花朵上。阳光透过树枝和叶子照射在花朵上\",\"editable\":false},{\"label\":\"让它们看起来更加生动。整个场景采用超细节的数字画作制作\",\"editable\":false},{\"label\":\"图像质量达到512k的超高清晰度\",\"editable\":false},{\"label\":\"是高清晰度的杰作。\",\"editable\":false}]",
      "negativePrompt": "",
      "privatePrompt": "******",
      "category": "character",
      "exampleImages": [
        "https://cos-xica-dev.tiamat.world/user/RHzlrKFAkkGPoQY0AUhc5/createdimage/euu5sxxv7W281EDXZQDLZ.png"
      ],
      "reviewState": "pass",
      "reviewRejectReason": "",
      "reviewedAt": 1693385715,
      "reviewer": "******",
      "releaseState": "released",
      "heatValue": 0,
      "cumUsedUserCount": 0,
      "cumUsedCount": 0,
      "createdAt": 1693385701
    },
    {
      "id": 359,
      "userUID": "r4fvoL5VG8TX0KK8XvZWY",
      "userNickName": "乌龙金桂",
      "userAvatar": "https://cos-xica-dev.tiamat.world/user/r4fvoL5VG8TX0KK8XvZWY/avatar/juyZC1773iDJVdlajzHRd.png",
      "name": "玩具士兵",
      "intro": "玩具士兵儿时玩具蓝色的",
      "model": "shouban-sheji",
      "aspectRatio": "1:1",
      "prompt": "[{\"label\":\"玩具士兵\",\"editable\":true}]",
      "negativePrompt": "[{\"label\":\"树\",\"editable\":true},{\"label\":\"阳光\",\"editable\":false},{\"label\":\"土地\",\"editable\":true}]",
      "privatePrompt": "******",
      "category": "character",
      "exampleImages": [
        "https://cos-xica-dev.tiamat.world/user/r4fvoL5VG8TX0KK8XvZWY/createdimage/ZAlGMJGlxGmgv4XLJ9yoT.png"
      ],
      "reviewState": "pass",
      "reviewRejectReason": "",
      "reviewedAt": 1693464535,
      "reviewer": "******",
      "releaseState": "released",
      "heatValue": 0,
      "cumUsedUserCount": 0,
      "cumUsedCount": 0,
      "createdAt": 1693464383
    }
  ]