import * as store from "@/store"
import { ReactComponent as LoadingPuffIcon } from "@/assets/loading_puff.svg"
import { ReactComponent as CloudUploadIcon } from "@/assets/cloud-upload.svg"
import { toastError, toastInfo } from "@/common/Toast"
import { MutableRefObject, forwardRef, useEffect, useImperativeHandle, useState } from "react"
import { Modal, Upload } from "@arco-design/web-react"
import { IconClose, IconDelete } from "@arco-design/web-react/icon"
import {
  V1ControlNetSettings,
  V1ImagePromptResp,
  V1TaskPriority,
  V1TaskStatus,
} from "@/generated/api/data-contracts"
import CopyToClipboard from "react-copy-to-clipboard"
import { ReactComponent as CopyIcon } from "@/assets/copy.svg"
import { ReactComponent as ProcessingIcon } from "@/assets/processing.svg"
import {
  apiCreateImagePrompt,
  apiListBusyTasks,
  apigetImagePrompt,
  uploadImageForPrompt,
} from "@/services/editor"
import { LogicError } from "@/types"
import { useTranslation } from "react-i18next"
import { observer } from "mobx-react-lite"
import { Link, useNavigate } from "react-router-dom"
import CheckAuth from "@/components/common/CheckAuth"
import studioPagesStore from "@/store/studioPagesStore"
import { isMobileOnly } from "react-device-detect"
import MobileModalHeader from "./MobileModalHeader"

// 0 待选择图 1 上传中 2 上传失败, 3 上传成功 4图片分析中 5 分析成功
enum ImageStatus {
  WaitSelectImage = 0,
  UploadingImage = 1,
  UploadImageFaild = 2,
  UploadImageSuccess = 3,
  AnalyseingImage = 4,
  AnalyseSuccess = 5,
}

export const ImageSpeakInStudioPage = forwardRef(
  (
    {
      buttonContent,
      addImageSpeakPrompt,
      priority,
      sourceUrl,
    }: {
      priority?: V1TaskPriority
      sourceUrl?: string
      addImageSpeakPrompt?: (_: string) => void
      buttonContent?: React.ReactNode
      event?: MutableRefObject<{ onOpen: () => void }>
      onChange?: (
        _:
          | {
              type: "referencelImage"
              preViewUrl: string
              payload: {
                referPower: number
                referencedImage: string
              }
            }
          | {
              type: "controlNet"
              preViewUrl: string
              payload: { controlNet: V1ControlNetSettings }
            }
      ) => void
    },
    ref
  ) => {
    const isHigh = priority === "high"
    const { t } = useTranslation(["translation", "errorMessage"])
    const [visible, setVisible] = useState(false)
    const [sourseFile, setSourseFile] = useState<File>()
    const [imgUrl, setImageUrl] = useState(() => sourceUrl || "")
    const [imagePrompt, setImagePrompt] = useState<V1ImagePromptResp>()
    const [imgStatus, setState] = useState<ImageStatus>(() =>
      sourceUrl ? ImageStatus.UploadImageSuccess : ImageStatus.WaitSelectImage
    )
    const {
      id: userId,
      visitorCreated,
      fastGenerationRemainingSeconds,
      oneTimeSupplementRemainingSeconds,
      freeGenerationRemainingCount,
      subscribedProduct,
    } = store.user.get()

    const closeModal = () => {
      if ([1, 4].includes(imgStatus)) {
        return
      }
      setVisible(false)
    }

    const removeImage = () => {
      setSourseFile(undefined)
      setImageUrl("")
      setImagePrompt(undefined)
      setState(ImageStatus.WaitSelectImage)
    }

    const selectImg = (file?: File) => {
      if (!file) {
        return
      }
      if (file.size / 1048576 > 5) {
        return toastError("单个文件不能超过5M")
      }

      const url = URL.createObjectURL(file)
      setImageUrl(url)
      setSourseFile(file)
      getImgUrl(file)
    }

    const getImgUrl = async (file = sourseFile) => {
      if (!file) {
        return
      }
      setState(ImageStatus.UploadingImage)
      const fd = new FormData()
      fd.append("file", file)
      try {
        const { data } = await uploadImageForPrompt({ file, format: "json" })
        if (data?.data && data?.data?.fileUrl) {
          setImageUrl(data?.data?.fileUrl)
          setState(ImageStatus.UploadImageSuccess)
        } else {
          setState(ImageStatus.UploadImageFaild)
          toastError(t(`errorMessage:${data.errorCode}` as any))
        }
      } catch (e) {
        console.log(e, "上传图像 error")
      }
    }

    const onKeyDownCtrlN = (oEvent: KeyboardEvent) => {
      const nKeyCode = oEvent.keyCode
      const bCtrlKeyCode = oEvent.ctrlKey || oEvent.metaKey

      if (nKeyCode === 72 && bCtrlKeyCode) {
        setVisible(true)
      }
    }

    useEffect(() => {
      document.addEventListener("keydown", onKeyDownCtrlN)
      return () => {
        document.removeEventListener("keydown", onKeyDownCtrlN)
      }
    }, [])

    const createImagePrompt = async () => {
      if (imgStatus < ImageStatus.UploadImageSuccess) {
        return
      }
      if (!userId) {
        // 免费用户只能生成一次
        if (visitorCreated) {
          return setShowRegisterModel(true)
        }
      } else {
        // 付费用户
        const { busyTaskCount } = await apiListBusyTasks()
        if (busyTaskCount) {
          return toastInfo("当前有任务正在生成")
        }
      }
      try {
        setImagePrompt(undefined)
        const data = await apiCreateImagePrompt({
          inputImageURL: imgUrl,
          priority: userId ? priority : V1TaskPriority.TaskPriorityLow, // 免费用户用慢速
        }).catch((e) => e)

        if (data.success) {
          if (data?.data?.id) {
            await getImagePrompt(data?.data?.id)
            setState(ImageStatus.AnalyseingImage)
          }
        } else {
          toastError(t(`errorMessage:${data.errorCode}` as any))
        }
      } catch (e) {
        toastError(e instanceof Error ? e.message : "unknown error")
      }
    }

    const getImagePrompt = async (id: string) => {
      const intervalId = setInterval(() => {
        apigetImagePrompt(id)
          .then((res) => {
            if (res?.status === V1TaskStatus.TaskStatusSuccess) {
              clearInterval(intervalId)
              store.refreshUser() // 描述成功后
              studioPagesStore.updateSettings()
              setState(ImageStatus.AnalyseSuccess)
            }
            setImagePrompt(res)
          })
          .catch((e) => {
            if (e instanceof LogicError) {
              toastError(t(`errorMessage:${e.code}` as any) || t("NetworkError"))
            } else {
              toastError(e instanceof Error ? e.message : "unknown error")
            }
          })
      }, 500)
    }
    const [showRegisterModel, setShowRegisterModel] = useState(false)

    const onCancel = () => {
      setShowRegisterModel(false)
    }
    // const createImagePrompt = () => {
    //   if (imgStatus < ImageStatus.UploadImageSuccess) {
    //     return
    //   }
    //   createImagePrompt()
    // }

    useImperativeHandle(ref, () => ({
      open: () => {
        setVisible(true)
      },
    }))

    return (
      <>
        <CheckAuth className="flex items-center  text-sm" onClick={() => setVisible(true)}>
          {buttonContent}
        </CheckAuth>
        <Modal
          onCancel={onCancel}
          visible={showRegisterModel}
          footer={null}
          title={null}
          modalRender={() => {
            return (
              <div className=" arco-modal bg-white dark:bg-black px-4 py-4 rounded">
                <div className="py-6 text-center">
                  <div className=" text-sm">注册获得更多生成次数</div>
                  <div className=" text-sm">快去注册，领取更多免费生成次数</div>
                  <Link to="/login/apply">
                    <button className="primary_btn w-[200px] h-[40px] my-6 ">申请资格</button>
                  </Link>
                </div>
              </div>
            )
          }}
        ></Modal>
        <Modal
          style={
            {
              // zIndex: 2000,
            }
          }
          maskStyle={{
            // zIndex: 2000,
            backgroundColor: "#000000ba",
          }}
          wrapStyle={
            {
              // zIndex: 2000,
            }
          }
          unmountOnExit={false}
          visible={visible}
          onCancel={closeModal}
          modalRender={() => {
            return (
              <div className="min-h-screen md:min-h-fit w-full md:w-[360px] relative mx-auto top-0 inline-block align-middle  bg-[#212134] rounded overflow-hidden">
                {/* Modal Header */}
                {isMobileOnly ? (
                  <MobileModalHeader title="图像会说话" onGoBack={closeModal} />
                ) : (
                  <div className="flex justify-between pl-[28px]  pr-3 py-3">
                    <h1 className=" text-left text-base">图像会说话</h1>
                    <div className="flex text-lg justify-end items-start ">
                      <IconClose className="cursor-pointer" onClick={() => closeModal()} />
                    </div>
                  </div>
                )}

                {/* Modal Content */}
                <div className="relative flex md:block flex-col h-[calc(100vh-80px)] md:h-auto">
                  {imgStatus === ImageStatus.AnalyseingImage ? (
                    <>
                      <ProgressBar
                        createdAt={imagePrompt?.createdAt}
                        imagePromptStatus={imagePrompt?.status}
                      />
                    </>
                  ) : null}
                  <div className="flex-1 pb-0 px-0 md:px-[28px] md:pb-4">
                    {imgStatus === ImageStatus.WaitSelectImage ? (
                      <Upload
                        accept="image/*"
                        showUploadList={false}
                        onChange={(fileList, file) => {
                          selectImg(file.originFile)
                        }}
                      >
                        <div className="trigger w-[304px] h-[304px] pt-24 rounded border border-[#373747] border-dashed ">
                          <div className="flex justify-center my-1">
                            <CloudUploadIcon className="w-[24px] h-[24px] fill-white" />
                          </div>
                          <h2>点击/拖拽上传</h2>
                          <p className="text-[#ffffff4d] text-xs">（单个文件不能超过5M）</p>
                        </div>
                      </Upload>
                    ) : (
                      <div className="group w-full md:w-[304px] h-full md:h-[304px] relative overflow-hidden rounded-md">
                        {/* Uploaded Image */}
                        <img
                          className="w-full h-full object-contain bg-[#0C0C1A] md:bg-inherit"
                          src={imgUrl}
                          alt=""
                        />

                        {/* Image Prompt for Mobile */}
                        {imagePrompt?.status === V1TaskStatus.TaskStatusSuccess && isMobileOnly && (
                          <div className="absolute bottom-0 whitespace-normal text-left p-5 rounded-tl rounded-tr bg-[#0C0C1ACC]">
                            {imagePrompt.textPrompt}
                          </div>
                        )}

                        {imgStatus === ImageStatus.UploadingImage ? (
                          <div className="absolute inset-0 rounded bg-black/80 flex items-center justify-center">
                            <LoadingPuffIcon />
                          </div>
                        ) : imgStatus === ImageStatus.UploadImageFaild ? (
                          <>
                            <div className="absolute inset-0 rounded bg-black/80 flex  justify-center items-center flex-wrap">
                              <div>
                                <div
                                  className="text-xs cursor-pointer "
                                  onClick={() => {
                                    getImgUrl()
                                  }}
                                >
                                  重新上传
                                </div>
                                <div className="mt-4 cursor-pointer">
                                  <IconDelete onClick={removeImage} />
                                </div>
                              </div>
                            </div>
                          </>
                        ) : null}
                      </div>
                    )}
                  </div>

                  {/* Image Prompt for Desktop */}
                  {imagePrompt?.status === V1TaskStatus.TaskStatusSuccess && !isMobileOnly && (
                    <div className=" px-[28px] flex ">
                      <div className=" relative p-2 bg-[#0C0C1A] w-full min-h-[100px] max-h-[320px] overflow-auto text-left rounded-md  whitespace-pre-line">
                        {imagePrompt.textPrompt}
                        <CopyToClipboard
                          text={imagePrompt.textPrompt || ""}
                          onCopy={() => {
                            toastInfo("已复制")
                          }}
                        >
                          <button className="hover:bg-[#85858584] p-1 rounded absolute right-3 bottom-2.5 flex items-center">
                            <CopyIcon className="mr-1 "></CopyIcon>
                          </button>
                        </CopyToClipboard>
                      </div>
                    </div>
                  )}

                  <div className="justify-between gap-x-2.5 md:gap-x-4 px-5 mt-4 pb-8 md:px-[28px] flex md:justify-center">
                    {imagePrompt?.status === V1TaskStatus.TaskStatusSuccess ? (
                      <>
                        {!sourceUrl ? (
                          <button
                            className={`flex-1 md:flex-initial w-auto border border-[#c9cbd14d] mr-0 md:mr-4 text-sm rounded  md:w-[120px] py-2`}
                            onClick={removeImage}
                          >
                            重新上传
                          </button>
                        ) : null}
                        <button
                          className={`flex-1 md:flex-initial w-auto bg-[#734aff] text-sm rounded  md:w-[120px] py-2`}
                          onClick={async () => {
                            if (!userId) {
                              // 免费用户只能一次，给出弹窗提示
                              setShowRegisterModel(true)
                              store.refreshUser() // 刷新create接口
                              return
                            }
                            const { busyTaskCount } = await apiListBusyTasks()
                            if (busyTaskCount) {
                              return toastInfo("当前有任务正在生成")
                            }
                            addImageSpeakPrompt?.(imagePrompt.textPrompt || "")
                            closeModal()
                          }}
                        >
                          生成试试
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className={`flex-1 md:flex-initial w-auto border border-[#c9cbd14d] text-sm rounded  md:w-[120px] py-2`}
                          onClick={closeModal}
                        >
                          取消
                        </button>
                        <button
                          className={`${
                            [3, 5].includes(imgStatus)
                              ? "bg-[#734aff]"
                              : "bg-[#4C1FFF] text-[#c9c9c9] cursor-not-allowed"
                          } flex-1 md:flex-initial text-sm rounded w-auto md:w-[120px] py-2`}
                          onClick={() => {
                            if ([3, 5].includes(imgStatus)) {
                              createImagePrompt()
                            }
                          }}
                        >
                          开始描述
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )
          }}
        />
      </>
    )
  }
)

// export ImageSpeakInStudioPage

const ProgressBar = ({
  createdAt,
  imagePromptStatus,
}: {
  createdAt?: string
  imagePromptStatus?: V1TaskStatus
}) => {
  const [progress, setProgress] = useState(100)

  useEffect(() => {
    if (
      imagePromptStatus === V1TaskStatus.TaskStatusFailed ||
      imagePromptStatus === V1TaskStatus.TaskStatusSuccess
    ) {
      setTimeout(() => {
        setProgress(0)
      }, 500)
    } else {
      setProgress(progress - progress / 10)
    }
  }, [createdAt, imagePromptStatus])

  return (
    <>
      <div className=" z-10 w-full h-full absolute flex flex-wrap justify-center items-center">
        <div className="py-6 px-2 rounded-md flex bg-[#000000cb] flex-wrap justify-center items-center w-[260px]">
          <ProcessingIcon />
          <p className="w-full">图片分析中{`${+(100 - progress).toFixed(2)}%`}</p>
          <div className=" mt-4 h-1 w-full bg-[#ffffff32] rounded-full">
            <div
              className="h-full bg-white rounded-full transition-all"
              style={{ width: `${100 - progress}%` }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export const ImageSpeakInOtherPage = observer(function ({
  buttonContent,
  priority,
  sourceUrl,
}: {
  priority?: V1TaskPriority
  buttonContent: React.ReactNode
  sourceUrl: string
}) {
  const navigate = useNavigate()

  const addImageSpeakPrompt = (prompt: string) => {
    studioPagesStore.init(({ taskHistory, runingTasks }) => ({ taskHistory, runingTasks }))
    studioPagesStore.setStore({
      prompt,
      autogeneration: true,
    })
    navigate({
      pathname: "/studio",
    })
  }
  return (
    <>
      <ImageSpeakInStudioPage
        priority={priority}
        sourceUrl={sourceUrl}
        buttonContent={buttonContent}
        addImageSpeakPrompt={addImageSpeakPrompt}
      />
    </>
  )
})
