import { ReactComponent as SpiritIcon } from "@/assets/spirit-stones.svg"
import { observer } from "mobx-react-lite"

export default observer(function ({
  text,
  onClick,
  extra,
}: {
  text: string | number
  onClick?: () => void
  extra?: string
}) {
  return (
    <button
      className="bg-[#6663FF] flex rounded-[50px] py-[4px] px-3 gap-[5px] items-center justify-between min-w-[60px]"
      onClick={onClick}
    >
      {extra ? extra : <SpiritIcon />}
      <span className="leading-[20px]">{text}</span>
    </button>
  )
})
