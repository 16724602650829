import { ReportReasonList } from "@/constant/index"
import { V1TicketTheme } from "@/generated/api/data-contracts"

interface CategoryMap {
  [key: string]: {
    name: string
    themes: { value: V1TicketTheme; label: string }[]
  }
}
export const CategoryThemeMap: CategoryMap = {
  account: {
    name: "账号管理",
    themes: [
      {
        value: V1TicketTheme.TicketThemeAccountRelated,
        label: "账号相关",
      },
      {
        value: V1TicketTheme.TicketThemeSubscribeRelated,
        label: "订阅相关",
      },
    ],
  },
  suggestion: {
    name: "产品建议",
    themes: [
      {
        value: V1TicketTheme.TicketThemeComplaintRelated,
        label: "吐槽(功能不好用)",
      },
      {
        value: V1TicketTheme.TicketThemeAdviceRelated,
        label: "建议(我有新需求)",
      },
    ],
  },
  bug: {
    name: "BUG反馈",
    themes: [
      {
        value: V1TicketTheme.TicketThemeCreationRelated,
        label: "创作相关",
      },
      {
        value: V1TicketTheme.TicketThemeDataRelated,
        label: "数据相关",
      },
      {
        value: V1TicketTheme.TicketThemeSystemRelated,
        label: "系统相关",
      },
    ],
  },
  report: {
    name: "内容举报",
    themes: ReportReasonList,
  },
}

export const StatusMap = {
  pending: "等待中",
  processing: " 处理中",
  completed: "已解决",
}
