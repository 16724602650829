import { Message, Modal, Upload } from "@arco-design/web-react"
import { ReactComponent as TitleIcon } from "@/assets/activities/work_title.svg"
import "./index.less"
interface WorkInputProps {
  onChange?: (creationName: string) => void
}
const WorkInput: React.FC<WorkInputProps> = ({ onChange }) => {
  return (
    <div className="w-full">
      <div className="flex flex-col">
        <div className="py-[10px]">
          <TitleIcon />
        </div>
        <input
          className="border-[1px] border-[#433C5F] bg-[#FFFFFF] p-[10px] rounded-[4px] h-[42px] w-full text-[14px] placeholder-[#8F87AD] text-[#8F87AD]"
          placeholder="请输入该作品的名称"
          onChange={(e) => {
            onChange?.(e.target.value)
          }}
        />
      </div>
    </div>
  )
}

export default WorkInput
