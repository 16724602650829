import { ReactComponent as ArrowDownIcon } from "@/assets/studio/arrow_down.svg"
import CheckAuth from "@/components/common/CheckAuth"

interface OptionHeaderProps {
  iconSrc: string
  label: string
  isExpanded?: boolean
  onClick?: () => void
  button?: React.ReactNode
  arrowHidden?: boolean
}

const OptionHeader = ({
  iconSrc,
  label,
  isExpanded,
  onClick,
  button,
  arrowHidden,
}: OptionHeaderProps) => {
  const hasButton = !!button
  const arrowVisible = !arrowHidden && !hasButton
  return hasButton ? (
    <div
      className={`flex items-center justify-between
    ${hasButton ? "cursor-default" : "cursor-pointer"}
  `}
    >
      <div className="mr-[10px] flex items-center flex-1">
        <img src={iconSrc} className="w-7 h-7 object-cover" />
        <div className="ml-2 text-white text-base font-normal leading-tight">{label}</div>
      </div>
      {button}
    </div>
  ) : (
    <CheckAuth>
      <div
        className={`flex items-center justify-between
        ${hasButton ? "cursor-default" : "cursor-pointer"}
      `}
        onClick={() => {
          onClick && onClick()
        }}
      >
        <div className="mr-[10px] flex items-center flex-1">
          <img src={iconSrc} className="w-7 h-7 object-cover" />
          <div className="ml-2 text-white text-base font-normal leading-tight">{label}</div>
        </div>
        {arrowVisible && (
          <ArrowDownIcon
            className={`w-7 h-7 object-cover ${isExpanded ? "" : "-rotate-90"} transition-all`}
          />
        )}
      </div>
    </CheckAuth>
  )
}

export default OptionHeader
