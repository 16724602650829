import { V1Task, V1Work } from "@/generated/api/data-contracts"
import CopyToClipboard from "react-copy-to-clipboard"
import { ReactComponent as CopyIcon } from "@/assets/copy.svg"
import { ReactComponent as ModelIcon } from "@/assets/model.svg"
import { ReactComponent as Right2Icon } from "@/assets/right2.svg"
import { ReactComponent as ScoreIcon } from "@/assets/score_s.svg"
import { ReactComponent as UploadIcon } from "@/assets/creation/upload.svg"
import { ReactComponent as ReloadIcon } from "@/assets/creation/reload.svg"
import { ReactComponent as CheckmarkIcon } from "@/assets/checkmark.svg"
import { ReactComponent as CheckPartialIcon } from "@/assets/check_partial.svg"
import { toastError, toastInfo, toastSuccess } from "@/common/Toast"
import { MODELS_MAP, OTHER_MODEL } from "@/constants"
import GenerateAgainTask from "@/Studio/components/GenerateAgainTask"
import GenerateSubmitTemplate from "@/Studio/components/GenerateSubmitTemplate"
import { useTranslation } from "react-i18next"
import { useSnapCarousel } from "react-snap-carousel"
import { observer } from "mobx-react-lite"
import { useEffect, useMemo, useRef, useState } from "react"
import worksPagesStore from "@/store/worksPagesStore"
import ModalScaleImg from "@/common/ImgDetailModal/Modal"
import { ErrorCode } from "@/i18next"
import { api } from "@/services/api"
import CardOne from "./CardOne"
import * as store from "@/store"
import { isMobileOnly } from "react-device-detect"
import templateStore from "@/store/templateStore"
import { toJS } from "mobx"
import { featureDeprecatedAlert } from "@/utils"

const getRatio = (ratio = "1:1") => {
  const [w = 1, h = 1] = ratio.split(":")
  return +w / +h
}

const getMaxHeight = (works: V1Work[] | undefined) => {
  let ratio = getRatio()
  works?.map((work) => {
    const currentRatio = getRatio(work.ratio)
    if (currentRatio < ratio) {
      ratio = currentRatio
    }
  })
  if (ratio < 1 / 1.1) {
    ratio = 1 / 1.1
  }
  return ratio
}

function getHighestScore(works: number[] = []) {
  if (!Array.isArray(works)) return 0
  return works.reduce((max, score) => {
    if (max < score) max = score
    return max
  }, 0)
}

type PropsType = {
  showSelect: boolean
  task: V1Task
  taskIndex: number
}

const GeneratedTaskCard = ({ showSelect, task, taskIndex }: PropsType) => {
  const score = getHighestScore(task?.works?.map((item) => item?.score ?? 0) || [])
  const template = templateStore.getTemplateById(task?.templateID ?? 0)
  const onToggle = showSelect
    ? (list: string[], workId?: string) => {
        if (workId) {
          worksPagesStore.togglesSelect(workId)
        } else {
          worksPagesStore.changeSelectList(list)
        }
      }
    : undefined

  return (
    <>
      <div
        key={task.id}
        className="p-[20px] mb-4 flex bg-[#0C0C1A] rounded-md md:flex-row flex-col-reverse relative"
      >
        {/* 描述语, 评分等 */}
        <div className="mr-0 mt-1 md:mt-0 lg:w-[330px] w-54 shrink-0 md:mr-4 flex md:flex-col flex-row  md:bg-inherit bg-[#212134] md:p-0 p-4 rounded-lg ">
          <div className="md:w-full md:h-full">
            {/* 评语+复制文字部分 */}
            <div className="flex md:flex-row flex-col justify-between items-center ">
              {/* <div>
               <span className="hidden md:inline-block text-3xl text-[32px] text-white font-[num]">
                {score?.toFixed ? score?.toFixed(2) : "暂无"}
              </span> 
              <ScoreIcon className="hidden ml-2 md:inline-block" />
              <span className="hidden md:inline text-sm text-[#6F7176] md:ml-[6.8px]">
                当前最高分
              </span>
            </div> */}
              {/* pc端下展示的所用模型 */}
              <div className="hidden md:flex mb-[12px]">
                <ModelIcon className="mr-1"></ModelIcon>
                <span className="text-xs text-[#6F7176] whitespace-nowrap">
                  {MODELS_MAP.get(task.model || "")?.name || OTHER_MODEL}
                </span>
              </div>
              {/* 移动端下展示的所用模型 */}
              <div className="hidden">
                <ModelIcon className="mr-1"></ModelIcon>
                <span className="text-xs text-[#6F7176]">
                  {MODELS_MAP.get(task.model || "")?.name || OTHER_MODEL}
                </span>
              </div>
              <CheckTaskAllImages task={task} onToggle={onToggle} />
            </div>

            {/* prompt移动端下不展示 */}
            <div
              className={`hidden md:flex flex-col px-4 py-3 bg-[#181823] rounded flex-1 relative w-[330px]  ${
                template?.coverImage ? "max-h-[106px]" : "max-h-[230px]"
              }`}
            >
              <div className="text-sm text-[#ffffff]/80 break-all overflow-auto  min-h-[50px] top-[12px] bottom-[36px] text-clip">
                {task.textPrompt}
              </div>
              <div className="flex justify-end">
                <CopyToClipboard
                  text={task.textPrompt || ""}
                  onCopy={() => {
                    toastInfo("已复制")
                  }}
                >
                  <button className=" right-3 lg:bottom-2.5  bottom-1 flex items-center mt-[10px]">
                    <CopyIcon className="mr-1" stroke="#808087"></CopyIcon>
                    <span className="text-xs text-[#6F7176] hover:text-[#fff]">复制文字</span>
                  </button>
                </CopyToClipboard>
              </div>
            </div>
            {/*  模板封皮  移动端不展示*/}
            {template && !isMobileOnly && (
              <div className="h-[80px] bg-[#000] rounded-[4px] flex mt-[10px]">
                <img
                  src={template?.coverImage || template?.exampleImages?.[0]}
                  className="w-[80px] h-[80px] object-cover rounded-[4px]"
                ></img>
                <div className="ml-[10px] pt-[6px]">
                  <div className="text-gray-500 text-[12px]">当前使用模版：</div>
                  <div className="text-white font-[16px]">{template?.name}</div>
                </div>
              </div>
            )}
          </div>
          {/* 模型+按钮部分 */}
          <div className="md:mt-3 flex md:flex-row  md:items-end  justify-start md:justify-end  w-full md:w-auto lg:w-[330px] w-54">
            <div className="flex justify-between gap-x-[10px]">
              {!template?.coverImage && (
                <GenerateSubmitTemplate
                  task={task}
                  buttonContent={
                    <>
                      <div className="cursor-pointer md:h-auto flex items-center  md:ml-auto mr-auto shrink-0 px-[16.5px] md:pl-[5px] md:pr-[10px] py-[6px] md:py-[1px] border border-[#6663FF] text-[#6663FF] font-normal rounded">
                        <UploadIcon className="hidden md:inline-flex" />
                        <span className="text-[14px] md:text-[16px]">提交模板</span>
                      </div>
                    </>
                  }
                />
              )}
              {/* 移动端展示，pc端下不展示 */}
              <CopyToClipboard
                text={task.textPrompt || ""}
                onCopy={() => {
                  toastInfo("已复制")
                }}
              >
                <div className="md:hidden flex items-center cursor-pointer md:shrink-0 px-5 py-1  border border-[#6663FF] text-[#6663FF] rounded font-normal text-[14px]">
                  复制文字
                </div>
              </CopyToClipboard>
              <GenerateAgainTask
                task={task}
                buttonContent={
                  <>
                    <div className="cursor-pointer md:h-auto flex items-center md:ml-auto mr-auto shrink-0 px-[16.5px] md:pl-[5px] md:pr-[10px] py-[6px] md:py-[2px] border border-[#6663FF] text-[#6663FF] rounded">
                      <ReloadIcon className="hidden md:inline-flex" />
                      <span className="text-[14px] md:text-[16px]">再次生成</span>
                    </div>
                  </>
                }
              />
            </div>
          </div>
        </div>
        {/* works图片 */}
        <WorkImages taskIndex={taskIndex} task={task} onToggle={onToggle}></WorkImages>
      </div>
    </>
  )
}

// 左侧全选task内全部图片
const CheckTaskAllImages = observer(
  ({
    task,
    onToggle,
  }: {
    task: V1Task
    onToggle?: (list: string[], workId?: string) => void
    setCardHeight?: (height: number) => void
  }) => {
    const [selectedWorks, setSelectedWorks] = useState<string[]>([])
    const selectList = worksPagesStore.store.get().selectList

    useEffect(() => {
      const newSelectedWorks: string[] = []
      task.works?.forEach((work) => {
        selectList?.includes(work.id || "") && newSelectedWorks.push(work.id || "")
      })
      setSelectedWorks(newSelectedWorks)
    }, [selectList.length])

    const onSelect = () => {
      const list: string[] = []
      if (selectedWorks.length < (task.works?.length || 0)) {
        task.works?.forEach(({ id }) => {
          id && list.push(id)
        })
        list.push(...selectList)
      } else {
        selectList.map((i) => {
          const index = task.works?.findIndex(({ id }) => id === i)

          if (index === -1) {
            list.push(i)
          }
        })
      }

      onToggle?.(Array.from(new Set(list)))
    }
    return (
      <div className="flex items-end">
        {onToggle && (
          <div className="mr-1">
            {selectedWorks.length > 0 ? (
              <label
                className="w-5 h-5 items-center justify-center cursor-pointer bg-[#5A14F0] rounded flex"
                onClick={onSelect}
              >
                {selectedWorks.length < (task.works?.length || 0) ? (
                  <CheckPartialIcon />
                ) : (
                  <CheckmarkIcon />
                )}
              </label>
            ) : (
              <label
                className="w-5 h-5 cursor-pointer bg-[#C9CBD1]/70 rounded block "
                onClick={onSelect}
              ></label>
            )}
          </div>
        )}
        <span className="hidden md:inline text-sm text-[#6F7176]">
          {onToggle ? `已选 ${selectedWorks?.length} 张` : `共 ${task.works?.length || 0} 张`}
        </span>
      </div>
    )
  }
)

// work 图片展示区域
const WorkImages = observer(
  ({
    task,
    onToggle,
    taskIndex,
  }: {
    taskIndex: number
    task: V1Task
    onToggle?: (list: string[], workId?: string) => void
    setCardHeight?: (height: number) => void
  }) => {
    const { t } = useTranslation(["translation", "errorMessage", "randomPrompt"])
    const selectList = worksPagesStore.store.get().selectList
    const user = store.user.get()

    const [modalOpen, setModalOpen] = useState(false)
    const [currentWork, setCurrentWork] = useState({})
    const { scrollRef, pages, activePageIndex, prev, next } = useSnapCarousel()
    const likeOrUnlike = async (liked: boolean, id: string, workIndex: number, work: V1Work) => {
      featureDeprecatedAlert()
      // const { data } = await (liked
      //   ? api.unlikeWork(id, { collectionUID: "JTQltwGbPEG3equutKwrX" })
      //   : api.likeWork(id, { collectionUID: "JTQltwGbPEG3equutKwrX" }))
      // if (data.success) {
      //   toastSuccess("操作成功")
      //   // 展示修改store的状态
      //   worksPagesStore.changeTaskItemWork(taskIndex, workIndex, {
      //     ...work,
      //     liked: !liked,
      //   })
      //   // refresh()
      //   return true
      // } else {
      //   toastError(t(`errorMessage:${data.errorCode as ErrorCode}`))
      //   return false
      // }
    }

    const publicOrPrivate = async (
      isPrivate: boolean,
      id?: string,
      workIndex?: number,
      work?: V1Work
    ) => {
      try {
        const { data } = await api.setWorkIsPrivate(id ?? "", {
          isPrivate: !isPrivate,
        })
        if (data.success) {
          toastSuccess("切换成功")
          worksPagesStore.changeTaskItemWork(taskIndex, workIndex || 0, {
            ...work,
            isPrivate: !isPrivate,
          })
        } else {
          toastError(t(`errorMessage:${data.errorCode as ErrorCode}`))
        }
      } catch (e) {
        toastError("操作失败")
      }
    }

    const wrapRef = useRef<HTMLDivElement>(null)
    const w = useMemo(() => {
      return wrapRef.current?.offsetWidth || 0
    }, [wrapRef.current])

    return (
      <div className="min-w-0 relative w-full" ref={wrapRef}>
        <button
          type="button"
          onClick={prev}
          className={`absolute left-0 inset-y-0 my-auto h-fit px-2 py-2 bg-black/40 flex items-center rounded z-[1] ${
            activePageIndex === 0 ? "hidden" : ""
          }`}
        >
          <Right2Icon className="rotate-180" />
        </button>
        <button
          type="button"
          onClick={next}
          className={`absolute right-0 inset-y-0 my-auto h-fit px-2 py-2 bg-black/40 flex items-center rounded z-[1] ${
            activePageIndex === pages.length - 1 ? "hidden" : ""
          }`}
        >
          <Right2Icon className="rotate-0" />
        </button>
        <div className="flex overflow-x-auto h-full" ref={scrollRef}>
          {task.works?.map((work, workIndex) => {
            // 因为新布局变高 原来是 0.21
            let height = (w * 0.218) / getMaxHeight(task.works)
            if (isMobileOnly) {
              height = (w - 5 * 3) / 4
            }
            return (
              <div
                id={`generated-taskcard-task${taskIndex}-work${workIndex}`}
                key={work.id}
                className="mr-[5px] md:mr-0 shrink-0 w-[21%] md:ml-[1.2%] h-full snap-start"
                style={{
                  width: "auto",
                  // 因为新布局变高 原来是 0.21
                  minWidth: w * 0.218 * getRatio(work.ratio) + "px",
                  height: height + "px",
                }}
              >
                <CardOne
                  hiddenConfig={{
                    publicOrPrivate: !user.canPrivateGeneration,
                  }}
                  operationHandlers={{
                    likeOrUnlike: (liked: boolean, workId = "") => {
                      return likeOrUnlike(liked, workId, workIndex, work)
                    },
                    viewBigImage: () => {
                      setModalOpen(true)
                      setCurrentWork(work)
                    },
                    publicOrPrivate: (isPrivate, id) =>
                      publicOrPrivate(isPrivate, id, workIndex, work),
                  }}
                  selectList={selectList}
                  onSelect={onToggle}
                  workId={work.id}
                  task={task}
                />
              </div>
            )
          })}
        </div>

        <ModalScaleImg
          open={modalOpen}
          task={task}
          work={currentWork}
          taskChangeDisable="both"
          onClose={() => {
            setModalOpen(false)
          }}
        />
      </div>
    )
  }
)

export default observer(GeneratedTaskCard)
