import { V1Task, V1TaskStatus } from "@/generated/api/data-contracts"
import QRCode from "qrcode.react"

import Animation from "./animation"
const PreviewPage = ({
  task,
  handleDone,
  resultImage,
  textPrompt,
  username,
}: {
  task: V1Task
  handleDone: () => void
  resultImage: string
  textPrompt: string
  username: string
}) => {
  const screenHeight =
    window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight

  const minTextPrompt = textPrompt.length > 20 ? textPrompt.slice(0, 20) + "..." : textPrompt
  return (
    <>
      {resultImage ? (
        <div className="h-[100vh] overflow-hidden relative flex justify-center items-center">
          <div className="">
            <img
              className="w-[100vw] object-contain"
              src={`${resultImage}`}
              // /dajiabaoxian_qr`}
              alt=""
            />
            <div className="z-20 absolute bottom-4 right-4 flex flex-col items-end">
              <div className="text-right text-[4vh] text-white mb-2">
                AI画出美好未来，大家助力实现您的梦想
              </div>
              <div>
                <img className={`h-[5vh]`} src="/assets/dajiaInsurance/markable.png" />
              </div>
            </div>
            <div className=" z-20 absolute flex items-end bottom-[2vh] left-[2vh]">
              <div className="bg-white rounded-[1vh] p-[1vh] pb-[0.5vh]">
                <QRCode
                  id="qrCode"
                  value={`${location.origin}/dajia-mobie-preview?src=${resultImage}&username=${username}&textPrompt=${minTextPrompt}`}
                  // size={screenHeight/8} // 二维码的大小
                  size={300} // 二维码的大小
                  fgColor="#000000" // 二维码的颜色
                  style={{
                    margin: "auto",
                    backgroundColor: "white",
                  }}
                />
                <div className="bg-white text-center text-black text-[1.5vh]">
                  微信扫一扫下载图片
                </div>
              </div>

              <div className="ml-[2vh] ">
                <div
                  className="flex gap-[1vh] font-bold items-center  rounded-[1vh]  mb-[0.5vh] text-[2.4vh]"
                  style={{ letterSpacing: "0.1rem" }}
                >
                  <img className="w-[3vh] h-[3vh]" src="assets/dajiaInsurance/user.png" />
                  {username}
                </div>
                <div className="flex gap-[1vh] items-center  rounded-[1vh] text-[2.4vh] ">
                  <img className="w-[3vh] h-[3vh]" src="assets/dajiaInsurance/icon-book.png" />
                  <span className=" font-normal text-[2.4vh]" style={{ letterSpacing: "0.1rem" }}>
                    {minTextPrompt}
                  </span>
                </div>
              </div>
            </div>
            <Animation animationStop />
          </div>
        </div>
      ) : (
        <>loading</>
      )}
    </>
  )
}

export default PreviewPage
