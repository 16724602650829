import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"
import "./styles.less"

// import required modules
import { Mousewheel, Navigation } from "swiper"
import { useEffect, useState } from "react"

const list = [
  {
    path: "/assets/zhangjiang-v2/1.png",
  },
  {
    path: "/assets/zhangjiang-v2/2.png",
  },
  {
    path: "/assets/zhangjiang-v2/3.png",
  },
  {
    path: "/assets/zhangjiang-v2/4.png",
  },
  {
    path: "/assets/zhangjiang-v2/5.png",
  },
  {
    path: "/assets/zhangjiang-v2/1.png",
  },
  {
    path: "/assets/zhangjiang-v2/2.png",
  },
  {
    path: "/assets/zhangjiang-v2/3.png",
  },
  {
    path: "/assets/zhangjiang-v2/4.png",
  },
  {
    path: "/assets/zhangjiang-v2/5.png",
  },
]
const SecondCreationImage = ({ onChange }: { onChange: (data: any) => void }) => {
  const [swiperName, setSwiperName] = useState("")
  useEffect(() => {
    setSwiperName(" my-swiper-" + Date.now().toString(16))
    onChange?.(list[0]) // todo 
  }, [])

  const getData = () => {
    setTimeout(() => {
      // const ele = document?.querySelector(
      //   `.${swiperName} .swiper-wrapper .swiper-slide-next + .swiper-slide`
      // ) as HTMLElement
      // const index = +(ele?.dataset?.swiperSlideIndex || 0)
      // onChange?.(list[index])
      const ele2 = document?.querySelector(
        `.second-creation-image-div .swiper-wrapper .swiper-slide-active + .swiper-slide`
      ) as HTMLElement
      onChange?.(list[(ele2.tabIndex + list.length - 1) % list.length])

    }, 100)
  }
  return (
    <>
      <div className="w-full second-creation-image-div">
        <Swiper
          defaultValue={1}
          slidesPerView={2}
          spaceBetween={20}
          loop={true}
          loopedSlides={3}
          navigation={false}
          mousewheel={true}
          modules={[Mousewheel, Navigation]}
          onActiveIndexChange={() => getData()}
          className={swiperName}
          // centeredSlides={true} //设定为true时，active slide会居中，而不是默认状态下的居左。
          slideToClickedSlide={true} //设置为true则点击slide会过渡到这个slide。
        >
          {list.map((item, index) => {
            return (
              <SwiperSlide tabIndex={index}  key={index}>
                <div className="w-full h-full relative  overflow-hidden shadow-lg shadow-[#d6d6d6]">
                  <img src={item.path} alt="" />
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </>
  )
}

export default SecondCreationImage
