import { useVisitStatus } from "../../VisitStatusContext"
import { useTasksDispatch, useTasksManagement } from "../WorksContext"
import ImageItem from "./ImageItem"
import NotAvailableItem from "./NotAvailableItem"
import { V1Task, V1Work } from "@/generated/api/data-contracts"
interface ImageGroupProps {
  works: V1Work[] | undefined
  task: V1Task
}
const WORKS_COUNT_LIMIT = 4

const ImageGroup = ({ works = [], task }: ImageGroupProps) => {
  const notAvailableCount = WORKS_COUNT_LIMIT - works.length
  const myTasksDispatch = useTasksDispatch()
  const { isManaging, selectedWorkIds, selectedWorkIdsDispatch } = useTasksManagement()
  const { isVisted } = useVisitStatus()

  const handleWorkUpdated = (work: V1Work) => {
    myTasksDispatch({
      type: "UPDATE_WORK",
      payload: {
        ...work,
      },
    })
  }

  const toggleWorkSelection = (workId: string, isSelected: boolean) => {
    selectedWorkIdsDispatch({
      type: isSelected ? "REMOVE" : "ADD",
      payload: workId,
    })
  }

  const handleTaskUpdated = (task: V1Task) => {
    myTasksDispatch({
      type: "UPDATE_TASK",
      payload: {
        ...task,
      },
    })
  }

  return (
    <div className="aspect-square">
      <div className="absolute inset-0 bg-[#0C0C1A] p-[5px] grid grid-cols-2 gap-[5px] rounded-md">
        {/* Works items */}
        {works.map((w, index) => {
          const isWorkSelected = selectedWorkIds?.includes(w.id ?? "") ?? false

          return (
            <div className="aspect-square" key={index}>
              <div className="absolute inset-0">
                <ImageItem
                  task={task}
                  work={w}
                  key={index}
                  onWorkUpdated={handleWorkUpdated}
                  isManaging={isManaging}
                  isSelected={isWorkSelected}
                  onSelectLayerClick={() => toggleWorkSelection(w.id ?? "", isWorkSelected)}
                  isVisited={isVisted}
                  onTaskUpdated={handleTaskUpdated}
                />
              </div>
            </div>
          )
        })}

        {/* Not available items */}
        {Array.from({ length: notAvailableCount }).map((_, index) => (
          <NotAvailableItem key={index} taskId={task?.id} />
        ))}
      </div>
    </div>
  )
}

export default ImageGroup
