import { V1Collection, V1Work } from "@/generated/api/data-contracts"
import { getListMyCollection, getAllListCollection } from "@/services/album"
import { flow, makeAutoObservable, runInAction } from "mobx"

interface userCollectionInfo {
  collection_count_created: number
  collection_count_liked: number
  hasDefaultCollection: boolean
}
class AlbumnStore {
  // 我的相册
  myAlbums: V1Collection[] = []
  // 我的相册数量
  myAlbumsCount = 0
  constructor() {
    makeAutoObservable(this)
    this.getMyAlbums()
  }
  // 我创建的相册（如果没有默认相册,则会创建一个默认相册）
  getMyAlbums = flow(function* (this: AlbumnStore) {
    // 暂时取100条
    try {
      const { data } = yield getListMyCollection({ current: 1, pageSize: 100, sort: "newest" })
      runInAction(() => {
        this.myAlbums = data?.list || []
        this.myAlbumsCount = data?.total || 0
      })
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  })

  updateWorkOfAlbum = (albumUid: string, work: V1Work) => {
    const albumWorks = this.myAlbums.find((item) => item.uid === albumUid)?.works
    if (!albumWorks) return
    const index = albumWorks.findIndex((item) => item.id === work.id)
    if (index === -1) {
      albumWorks.push(work)
    } else {
      albumWorks.splice(index, 1)
    }
  }
}

export default new AlbumnStore()
