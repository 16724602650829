import { useEffect } from "react"
import { createPortal } from "react-dom"

import { V1Task, V1Work } from "@/generated/api/data-contracts"
import { getImageURL, handleDownload } from "@/utils"

import { ReactComponent as CloseIcon } from "@/assets/close.svg"
import { ReactComponent as DownloadIcon } from "@/assets/download.svg"
import { ReactComponent as Right2Icon } from "@/assets/right2.svg"
import { MODELS_MAP, OTHER_MODEL } from "@/constants"

export default function Modal({
  open,
  onClose,
  task,
  work,
}: {
  open: boolean
  task: V1Task
  work: V1Work
  onClose: () => void
}) {
  useEffect(() => {
    // TODO multiple modal?
    if (open) {
      document.body.classList.add("overflow-hidden")
    } else {
      document.body.classList.remove("overflow-hidden")
    }
  }, [open])

  const ratio = (task as V1Task).ratio || work.ratio || "1:1"
  const model = MODELS_MAP.get(task.model || "")

  return open
    ? createPortal(
        <>
          <div className="fixed inset-0 bg-black/90 z-10" onClick={onClose}></div>

          <div className="fixed inset-y-20 inset-x-0 flex z-10" role="dialog">
            <div className="flex-1 min-w-[5%] flex items-center justify-center" onClick={onClose}>
              <button
                className="todo-hidden p-2 bg-white/20 rounded active:brightness-110"
                onClick={(evt) => {
                  evt.stopPropagation()
                }}
              >
                <Right2Icon />
              </button>
            </div>
            <div
              className="max-w-[60%] flex items-center h-full"
              style={{ aspectRatio: ratio.replace(":", "/") }}
            >
              <div className="w-full flex" style={{ aspectRatio: ratio.replace(":", "/") }}>
                <img className="h-auto flex-1" src={getImageURL(work.image)} alt="" />
                <div className="w-[120px] flex-none flex flex-col justify-between pl-[10px]">
                  <button className="active:brightness-110" onClick={onClose}>
                    <CloseIcon />
                  </button>
                  <div className="">
                    <dl className="">
                      <dt className="mt-4 text-sm text-white/80">模型</dt>
                      <dd className="mt-2 text-base sm:text-sm text-white">
                        {model?.name || OTHER_MODEL}
                      </dd>

                      <hr className="my-4 w-4 border-white/30" />

                      <dt className="mt-4 text-sm text-white/80">图片尺寸</dt>
                      <dd className="mt-2 text-base  sm:text-sm text-white">
                        {
                          // FIXME 预留字段，接口添加此字段后可以移除 @ts-ignore
                          // @ts-ignore
                          work.size || "-"
                        }
                      </dd>
                    </dl>
                    <button
                      onClick={() => handleDownload(work.image!)}
                      className="mt-8 px-4 py-3 w-max flex items-center text-sm text-white bg-white/40 rounded opacity-70 hover:opacity-100 transition-opacity"
                    >
                      <DownloadIcon width={18} height={18} stroke="currentColor" />
                      <span className="ml-2">下载</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-1 min-w-[5%]" onClick={onClose}></div>
          </div>
        </>,
        document.body
      )
    : null
}
