import sortedIcon from "@/assets/works/sorted.svg"
import ManageAction from "../ManageBtnContainer"
import { Popover } from "@arco-design/web-react"
import { SortEnum } from "./constant"

const sortTypeList: { value: SortEnum; label: string }[] = [
  { value: SortEnum.newest, label: "最新" },
  // { value: SortEnum.highest, label: "最高分" },
  { value: SortEnum.popular, label: "最受欢迎" },
]

interface SortButtonProps {
  onChange: (sortType: SortEnum) => void
  value: SortEnum
}

const SortButton = ({ onChange, value }: SortButtonProps) => {
  const sortText = sortTypeList.find((item) => item.value === value)?.label

  return (
    <Popover
      position="bl"
      trigger={"hover"}
      color={"#212134"}
      className="work-popover-wrapper"
      content={
        <ul className="p-[5px] bg-[#212134]">
          {sortTypeList.map((item) => {
            return (
              <li
                key={item.value}
                className="cursor-pointer  text-white px-[10px] py-[5px] bg-transparent hover:bg-[#272739]"
                onClick={() => {
                  onChange?.(item.value)
                }}
              >
                {item.label}
              </li>
            )
          })}
        </ul>
      }
    >
      <div>
        <ManageAction>
          <div className="min-w-fit flex justify-center items-center gap-x-1">
            <img src={sortedIcon} alt="" className="w-3 object-contain" />
            <div className="text-base leading-5 font-normal">{sortText}</div>
          </div>
        </ManageAction>
      </div>
    </Popover>
  )
}

export default SortButton
