import { TemplatePromptItem } from "@/types"
import { isValidJSON } from "./validate"

const getTemplatePromptArray = (templatePrompt: string) => {
  return templatePrompt
    ? isValidJSON(templatePrompt)
      ? JSON.parse(templatePrompt)
      : [
          {
            label: templatePrompt,
            editable: true,
          },
        ]
    : []
}

const convertTemplatePromptArrayToNormalPrompt = (promptArray: any) => {
  return promptArray
    .map((item: TemplatePromptItem) => {
      return item.label
    })
    .join(", ")
}

const getNormalPromptByTemplatePromptJSON = (templatePromptJSON: string | undefined) => {
  if (!templatePromptJSON) return ""

  const promptArray = getTemplatePromptArray(templatePromptJSON)
  return convertTemplatePromptArrayToNormalPrompt(promptArray)
}

export {
  getTemplatePromptArray,
  convertTemplatePromptArrayToNormalPrompt,
  getNormalPromptByTemplatePromptJSON,
}
