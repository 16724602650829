import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { Modal, Table, TableColumnProps } from "@arco-design/web-react"
import { useTranslation } from "react-i18next"
import { IconClose } from "@arco-design/web-react/icon"
import { toastError } from "@/common/Toast"
import { ReactComponent as ArrowRightIcon } from "@/assets/arrow-right.svg"
import { getOrderList } from "@/services/subscription"
import { V1Order } from "@/generated/api/data-contracts"
import { LogicError } from "@/types"
import * as store from "@/store"
import "./buyHistory.less"

const BuyHistory = () => {
  const columns: TableColumnProps[] = [
    {
      title: "内容",
      align: "center",
      width: 150,
      dataIndex: "orderTitle",
      headerCellStyle: {
        backgroundColor: "transparent",
        color: "#fff",
        fontSize: 14,
        borderBottom: "2px solid #4d4d5d",
      },
      bodyCellStyle: {
        backgroundColor: "transparent",
        color: "#ffffff80",
        borderBottom: "1px solid #2c2c3e",
      },
    },
    {
      title: "购买时间",
      align: "center",
      width: 120,
      dataIndex: "startAt",
      headerCellStyle: {
        backgroundColor: "transparent",
        color: "#fff",
        fontSize: 14,
        borderBottom: "2px solid #4d4d5d",
      },
      bodyCellStyle: {
        backgroundColor: "transparent",
        color: "#ffffff80",
        borderBottom: "1px solid #2c2c3e",
      },
      render(col) {
        const time = col ? dayjs(col * 1000).format("YYYY-MM-DD") : "-"
        return time
      },
    },
    {
      title: "到期时间",
      align: "center",
      width: 120,
      dataIndex: "expiredAt",
      headerCellStyle: {
        backgroundColor: "transparent",
        color: "#fff",
        fontSize: 14,
        borderBottom: "2px solid #4d4d5d",
      },
      bodyCellStyle: {
        backgroundColor: "transparent",
        color: "#ffffff80",
        borderBottom: "1px solid #2c2c3e",
      },
      render(col) {
        const time = col === -1 ? "♾️" : col ? dayjs(col * 1000).format("YYYY-MM-DD") : "-"
        return time
      },
    },
    {
      title: "付款金额",
      align: "center",
      width: 100,
      dataIndex: "totalFee",
      headerCellStyle: {
        backgroundColor: "transparent",
        color: "#fff",
        fontSize: 14,
        borderBottom: "2px solid #4d4d5d",
      },
      bodyCellStyle: {
        backgroundColor: "transparent",
        color: "#ffffff80",
        borderBottom: "1px solid #2c2c3e",
      },
      render(col) {
        return col / 100
      },
    },
    {
      title: "付款方式",
      align: "center",
      width: 120,
      dataIndex: "paymentType",
      headerCellStyle: {
        backgroundColor: "transparent",
        color: "#fff",
        fontSize: 14,
        borderBottom: "2px solid #4d4d5d",
      },
      bodyCellStyle: {
        backgroundColor: "transparent",
        color: "#ffffff80",
        borderBottom: "1px solid #2c2c3e",
      },
      render(col) {
        const payType: {
          [key: number]: string
        } = {
          1: "微信支付",
        }
        return <>{payType[col]}</>
      },
    },
    {
      title: "订单号",
      align: "center",
      width: 220,
      dataIndex: "orderId",
      headerCellStyle: {
        backgroundColor: "transparent",
        color: "#fff",
        fontSize: 14,
        borderBottom: "2px solid #4d4d5d",
      },
      bodyCellStyle: {
        backgroundColor: "transparent",
        color: "#ffffff80",
        borderBottom: "1px solid #2c2c3e",
      },
      render(col) {
        return <>{col}</>
      },
    },
    {
      title: "",
      align: "center",
      dataIndex: "orderId",
      width: 100,
      headerCellStyle: {
        backgroundColor: "transparent",
        color: "#fff",
        fontSize: 14,
        borderBottom: "2px solid #4d4d5d",
      },
      bodyCellStyle: {
        backgroundColor: "transparent",
        color: "#ffffff80",
        borderBottom: "1px solid #2c2c3e",
      },
      render(orderId) {
        return (
          <>
            <a
              target="_blank"
              href={`https://tiamat-x.feishu.cn/share/base/form/shrcnnZbjFjmkPOeQPOs1M6BzJc/?prefill_%E8%AE%A2%E5%8D%95%E5%8F%B7=${orderId}`}
              className="text-[#8952FF]"
              rel="noreferrer"
            >
              发票服务
            </a>
          </>
        )
      },
    },
  ]
  const user = store.user.get()
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [orderList, setOrderList] = useState<V1Order[]>()
  const { t } = useTranslation(["translation", "errorMessage"])

  const getTableDetail = async () => {
    try {
      setLoading(true)
      const data = await getOrderList({ current: 1, pageSize: 1000 }).finally(() => {
        setLoading(false)
      })

      setOrderList(data.list)
    } catch (e) {
      if (e instanceof LogicError) {
        toastError(t(`errorMessage:${e.code}` as any))
      }
    }
  }

  useEffect(() => {
    visible && getTableDetail()
  }, [visible])
  return user.id ? (
    <>
      <span onClick={() => setVisible(true)} className="text-[#6663FF] text-base font-medium mr-2">
        订阅购买记录
      </span>
      <ArrowRightIcon className="text-[#6663FF] fill-current" />
      <Modal
        maskStyle={{
          background: "#0C0C1A",
        }}
        closeIcon={
          <span className="text-[#ffffff] hover:text-[#ffffffe3] text-[18px]">
            <IconClose />
          </span>
        }
        footer={null}
        title={null}
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        autoFocus={false}
        focusLock={true}
        style={{
          background: "#212134",
          width: "1000px",
        }}
      >
        <div className="text text-3xl my-4 text-center text-[#ffffff]">订阅购买记录</div>
        <Table
          className="subscription-history-table"
          border={false}
          style={{
            background: "#212134",
          }}
          loading={loading}
          columns={columns}
          data={orderList}
          pagination={false}
          scroll={{ y: 500 }}
        />
      </Modal>
    </>
  ) : null
}

export default BuyHistory
