import { useEffect, useState } from "react"

const list = [
  {
    img: "/assets/dajiaInsurance/chahua.png",
    title: "治愈插画",
    ready: true,
    payload: {
      model: "guangying-zengqiang",
      ratio: "16:9",
      textPrompt: [
        "概念场景设计，(",
        ")，best quality, ultra-detailed, masterpiece, finely detail, highres, 8k wallpaper,5000K temperature,soft lighting,details, shiny, sparkly,dreamy and fantasy,dreamy，吉卜力工作室，动漫艺术风格",
      ],
      negtiveTextPrompt:
        "man,girl,wonan,boy,EasyNegative, (painting by bad-artist-anime:0.9), (painting by bad-artist:0.9), watermark, text, error, blurry, jpeg artifacts, cropped, worst quality, low quality, normal quality, jpeg artifacts, signature, watermark, username, artist name, (worst quality, low quality:1.4), bad anatomy, EasyNegative ,bad_prompt_version2, nsfw",
    },
  },
  {
    img: "/assets/dajiaInsurance/jishi.png",
    title: "纪实质感",
    ready: true,
    payload: {
      model: "tongyong-jichu",
      ratio: "16:9",
      textPrompt: ["真实场景，写实摄影，(", ")，4k，疯狂的细节，真实打光"],
      negtiveTextPrompt: "",
    },
  },
]

const StyleModels = ({ onChange }: { onChange: (data: any) => void }) => {
  useEffect(() => {
    onChange?.(list[0])
  }, [])
  const [isSelect, setIsSelect] = useState<"chahua" | "xieshi">("chahua")

  return (
    <>
      <div className="w-full mt-5">
        <div className={` gap-[5vh] flex items-center justify-center`}>
          <img
            className="h-[30vh] object-cover rounded-[2vh] box-content"
            style={{ border: `${isSelect == "chahua" ? "0.4vh solid #D80C24" : ""}` }}
            onClick={() => {
              setIsSelect("chahua")
              onChange(list[0])
            }}
            src="/assets/dajiaInsurance/chahua.png"
            alt="治愈插画"
          />
          <img
            className="h-[30vh] object-cover rounded-[2vh] box-content"
            style={{ border: `${isSelect == "xieshi" ? "0.4vh solid #D80C24" : ""}` }}
            src="/assets/dajiaInsurance/jishi.png"
            onClick={() => {
              setIsSelect("xieshi")
              onChange(list[1])
            }}
            alt="写实风格"
          />
        </div>
      </div>
    </>
  )
}

export default StyleModels
